const $ = require("jquery");
const {translation, formatDateSeparators, getValidFormatDate} = require("../generalFunctions");

let banksFR;
let banksES;
let allBanks;
$(document).on('click', '.find-all-banks, .js-openpopin-bank', function () {
  $('.loader-popin').css('display', 'block');
  if (window.location.pathname === "/freelance/fournisseur/paiement") {
    $('.loader').fadeIn(200);
  }
  findBanks();
})


if (window.location.pathname === "/freelance/fournisseur/paiement") {
  let vars = {};
  window.location.href.replace( location.hash, '' ).replace(/[?&]+([^=&]+)=?([^&]*)?/gi,
    function( m, key, value ) {
      vars[key] = value;
    });
  let payment = vars['paiement'];

  if (payment !== null && payment !== "undefined") {
    findBanks();
  }
}

function findBanks () {
  let ES = "ES"
  $.when(
    $.ajax({
      url: "/bridge/find_all_banks",
      type: "GET",
      processData: false,
      success: function (val) {
        let v = JSON.parse(val);
        if (v.status === 'success') {
          banksFR = v.datas;
          banksFR.forEach(function (element) {
            let name = element.name;
            let countryCode = element.country_code;
            let id = element.id;
            let url = element.resource_uri;

            let bank = $("<p>", {
              text: name,
              id: "bankAutoFR" + id,
              class: "js-bankAuto"
            });
            $(bank).attr({
              "data-country": countryCode,
              "data-url": url,
              "data-id": id
            });
            $(bank).on('click', function (e) {
              $('.js-type-bank').val(name);
              $('.js-autocompleteBank').hide();
              $('.js-type-bank').attr('data-id', id)
              $('.js-type-bank').attr('data-country', countryCode)
              $('.js-type-bank').trigger("input");
            });
            $(bank).hide();
            $('.js-autocompleteBank').append(bank)
          })
        }
        else {
          banksFR = [];
          $('.loader').fadeOut(200);
          $('.loader-popin').fadeOut(200);
          $('.st-blocFormError1').removeClass('d-none').addClass('d-flex');
          $(".st-blocFormError1 .js-errorMsg").text(v.message);
        }
      },
      error : function (XMLHttpRequest, textStatus, errorThrown)  {
        banksFR = [];
      }
    }),
    // $.ajax({
    //   url: "/find_banks_es",
    //   type: "POST",
    //   data: {countryCode: ES},
    //   success: function (val) {
    //     let v = JSON.parse(val);
    //     if (v.status === 'success') {
    //       banksES = v.datas;
    //       banksES.forEach(function (element) {
    //         let name = element.fullname;
    //         let countryCode = element.countryISO;
    //         let id = element.connectorID;
    //
    //         let bank = $("<p>", {
    //           text: name,
    //           id: "bankAutoES"+ id,
    //           class: "js-bankAuto"
    //         });
    //         $(bank).attr({
    //           "data-country": countryCode,
    //           "data-connector": id
    //         })
    //         $(bank).on('click', function (e) {
    //           e.stopPropagation();
    //           $('.js-type-bank').val(name);
    //           $('.js-autocompleteBank').hide();
    //           $('.js-type-bank').attr('data-id', id);
    //           $('.js-type-bank').attr('data-country', countryCode);
    //         });
    //         $(bank).hide();
    //         $('.js-autocompleteBank').append(bank)
    //       })
    //     }
    //     else {
    //       banksES = [];
    //       $('.loader-popin').fadeOut(200);
    //       $('.loader').fadeOut(200);
    //       $('.st-blocFormError1').removeClass('d-none').addClass('d-flex');
    //       $(".st-blocFormError1 .js-errorMsg").text(v.message);
    //     }
    //   },
    //   error : function (XMLHttpRequest, textStatus, errorThrown)  {
    //     banksES = [];
    //   }
    // })
  ).then(function() {
    if (!banksFR) {
      $('.st-blocFormError1').removeClass('d-none').addClass('d-flex');
      $(".st-blocFormError1 .js-errorMsg").text(v.message);
    }
    else {
      allBanks = banksFR
    }

    $('.loader-popin').css('display', 'none');
    if (window.location.pathname === "/freelance/fournisseur/paiement") {
      $('.loader').fadeOut(200);
    }
  }).catch(function(err) {
    $('.loader').fadeOut(200);
    $('.loader-popin').fadeOut(200);
    $('.st-blocFormError1').removeClass('d-none').addClass('d-flex');
    $(".st-blocFormError1 .js-errorMsg").text(v.message);
  })
}

$(".js-type-bank").on('input change', function (e)  {
  let value = $(this).val();
  let id;
  allBanks.forEach(function (element) {
    id = element.connectorID ? "ES"+element.connectorID : "FR"+element.id;
    let bank = $('#'+"bankAuto" + id);
    if ((typeof element.fullname !== "undefined" && ((element.fullname.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')).includes(value.toLowerCase()) || element.fullname.toLowerCase().includes(value.toLowerCase()) )) ||
      (typeof element.name !== "undefined" && ((element.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')).includes(value.toLowerCase()) || element.name.toLowerCase().includes(value.toLowerCase()))  && value !== null)) {
      bank.show();
    }
    else {
      bank.hide();
    }
  });
  $('.js-autocompleteBank').show();
  $(document).on("click", function () {
    $('.js-autocompleteBank').hide();
  });
});

$(document).on('click', '.js-payment-bank', function(e) {
  $('.popin').animate({scrollTop: '0px'}, 300);
  $(".js-errorDelayedDate").hide();
  $(this).addClass("st-no-btn-disabled");
  // if ($(e.target).closest('.popin-payment-bank').find(".js-wayselected.selected").hasClass('one-clic-payment')) {
  //   let promiseReturn = $.get('/bridge/find_all_banks');
  //   promiseReturn.then(function(val) {
  //     let v = JSON.parse(val);
  //     if (v.status === 'success') {
  //       for (let i=0; i < v.banks.length; i++) {
  //         $('.select-bank').append('<option value="'+ v.banks[i].id +'"> '+ v.banks[i].name +' </option>')
  //       }
  //     }
  //   })
  //   $('.payment-delayed-content').hide();
  //   $('.popin-payment-bank .popin-title').text(translation('Paiement sécurisé par virement bancaire'));
  //   $('.js-bankConnexion').removeClass('d-none');
  //   $('.js-chooseWayToPay').addClass('d-none');
  //   $('.js-backToWaySelection').removeClass('d-none');
  //   $(".js-backPopinBank").addClass("d-none");
  //   $('.js-payment-bank').addClass('call-bridge');
  //   $('.js-payment-bank').removeClass('js-payment-bank');
  //   $('.js-backToWaySelection').on('click', () => {
  //     //$(".st-payment-bank").removeClass("st-no-btn-disabled");
  //     $('.js-bankConnexion').addClass('d-none');
  //     $('.js-chooseWayToPay').removeClass('d-none');
  //     $('.js-backToWaySelection').addClass('d-none');
  //     $(".js-backPopinBank").removeClass("d-none");
  //     $('.call-bridge').addClass('js-payment-bank');
  //     $('.call-bridge').removeClass('call-bridge');
  //     $('.popin-payment-bank .popin-title').text(translation('Paiement de la facture'));
  //   })
  let pathname = window.location.pathname;
  e.preventDefault();
  const isMultipayment = $(e.target).attr('data-ismultipayment') === '1';
  $(".loader-popin").fadeIn(200);
  $('.popin').css('overflow', 'hidden');
  let bankId = $('.bankselect.selected').data('bankid');
  let delayedDate = formatDateSeparators($(e.target).closest('.popin-payment-bank').find('.delayed-date:visible').val());
  let route = $(this).data('route') + '?bankId=' + bankId;
  let checked = $(e.target).closest('.popin-payment-bank').find('.js-show-payafter:visible').is(':checked');
  let delayedDateTime;
  if (delayedDate) {
    delayedDateTime = new Date(getValidFormatDate(delayedDate, "-")).getTime();
  }
  if (pathname === '/freelance/') {
    route += '&home=true';
  }
  if (checked) {
    if (delayedDateTime > new Date().getTime()) {
      route += '&delayedDate='+delayedDate;
    }
  }
  // if ($('#js-multipayment-tab').hasClass('st-multipayment-tab-selected') && !($('#js-multipayment-tab').closest('.js-withMultipayment').hasClass('d-none'))) {
  //   route += '&multipaymentSelected=1';
  // }
  if ($(".st-Bloc3xMulti").is(":visible")) {
    route += '&multipaymentSelected=1';
  }
  if (checked && delayedDateTime <= new Date().getTime()) {
    $(".loader-popin").fadeOut(200);
    $(".js-errorDelayedDate").text("Veuillez choisir une date ultérieure").show();
    $(".popin").css("overflow", "auto");
    $(this).removeClass("st-no-btn-disabled");
  }
  else {
    $(".js-errorDelayedDate").hide();
    $(".loader-popin").fadeIn(200);
    window.location.href = route;
  }
});
