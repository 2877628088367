import { popinPaymentBystep, step1, step3bis2 } from "../classes/loadingClasses/loadingPopinClass";

$('.bank-details').on('click', function () {
  if (!$(this).parent('.bank').hasClass('st-default-bank')) {
    let bankElement = $(this).parent('.bank');
    let previousDefault = $('.st-default-bank');
    let id = bankElement.attr('data-id');
    let promiseReturn = $.post('/freelance/mon_profil/compte_bancaire/'+ id +'/default', {
      bankId : id
    });
    promiseReturn.then(function (val) {
      let v = JSON.parse(val);
      if (v.status === 'success') {
        bankElement.addClass('st-default-bank');
        bankElement.find('.js-default-bank-text').removeClass('d-none');
        previousDefault.removeClass('st-default-bank');
        previousDefault.find('.js-default-bank-text').addClass('d-none');
      }
    });
  }
})

export function setBankToDefault() {
  $(document).on('click', '.js-paymentPopinSelectAccount', function() {
    let bankId;
    if ($('.js-page-efacture').hasClass('page-efacture')) {
      bankId = $('.popin-send-later .js-wayselected.selected').data('bankid');
    } else {
      bankId = $('.popin-payment-bystep .js-wayselected.selected').data('bankid');
    }
    let promiseReturn = $.post('/freelance/mon_profil/compte_bancaire/'+ bankId +'/default', { bankId : bankId });
    promiseReturn.then(function (val) {
      let v = JSON.parse(val);
      if (v.status === 'success') {
        if ($('.popin-send-later .js-payment-popin-select-account').hasClass('js-efacture-send-later-popin')) {
          $('.popin-send-later .js-send-later-efacture-step-1').addClass('d-none');
          $('.popin-send-later .js-send-later-efacture-step-2').removeClass('d-none');
        } else {
          $('.js-payment-select-account').addClass('d-none');
          $('.js-payment-popin-mail-personalization').removeClass('d-none');
        }
        popinPaymentBystep.goNext(false);
        popinPaymentBystep.deleteStep(step3bis2);
      } else {
        location.reload();
      }
    }).catch(function(e) {
      location.reload();
    });
  })
}
$('.js-payment-popin-select-account').on('click', (e) => {
  $(this).prop('disabled', true);
  let bankId;
  if ($('.js-page-efacture').hasClass('page-efacture')) {
    bankId = $('.popin-send-later .js-wayselected.selected').data('bankid');
  } else {
    bankId = $('.popin-payment-bystep .js-wayselected.selected').data('bankid');
  }
  let promiseReturn = $.post('/freelance/mon_profil/compte_bancaire/'+ bankId +'/default', { bankId : bankId });
  promiseReturn.then(function (val) {
    let v = JSON.parse(val);
    if (v.status === 'success') {
      if ($('.popin-send-later .js-payment-popin-select-account').hasClass('js-efacture-send-later-popin')) {
        $('.popin-send-later .js-send-later-efacture-step-1').addClass('d-none');
        $('.popin-send-later .js-send-later-efacture-step-2').removeClass('d-none');
      } else {
        $('.js-payment-select-account').addClass('d-none');
        $('.js-payment-popin-mail-personalization').removeClass('d-none');
      }
    } else {
      location.reload();
    }
  }).catch(function(e) {
    location.reload();
  });
})