import { formatDateSeparators, isAdult, translation } from '../../generalFunctions.js';

$('.js-newUboSubmit').on('click', function() {
  const thFirstName = $('#new_ubo_thFirstName').val();
  const thLastName = $('#new_ubo_thLastName').val();
  const thAddress = $('#new_ubo_thAddress').val();
  const thCity = $('#new_ubo_thCity').val();
  const thPostalCode = $('#new_ubo_thPostalCode').val();
  const thCountry = $('.new_ubo_thCountry').val();
  const thNationality = $('.new_ubo_thNationality').val();
  const thBirthday = formatDateSeparators($('#new_ubo_thBirthday').val());
  const thBirthCountry = $('.new_ubo_thBirthCountry').val();
  const thBirthCity = $('#new_ubo_thBirthCity').val();
  let uboId = null;

  if ($("#new_ubo_thFirstName").attr("data-id")) {
    uboId = $("#new_ubo_thFirstName").attr("data-id");
  }

  if (!thFirstName || !thLastName || !thAddress || !thCity || !thPostalCode || !thCountry || !thNationality || !thBirthday || !thBirthCountry || !thBirthCity) {
    // SHOW MAIN MESSAGE ERROR
    $('.js-uboErrors').show(0, function() {
      $(this).text(translation('Tous les champs'));
    });
    // ADD RED OUTLINE RIGHT AFTER FIRST SUBMIT
    ["#new_ubo_thFirstName", "#new_ubo_thLastName", "#new_ubo_thAddress", "#new_ubo_thCity", "#new_ubo_thPostalCode", ".new_ubo_thCountry", ".new_ubo_thNationality", "#new_ubo_thBirthday", ".new_ubo_thBirthCountry", "#new_ubo_thBirthCity"].forEach(function(element) {
      if (element === ".new_ubo_thCountry" || element === ".new_ubo_thNationality" || element === ".new_ubo_thBirthCountry") {
        if ($(element).val()) {
            $(element).parent().removeClass('error');
          } else {
            $(element).parent().addClass('error');
          }
      } else if (!$(element).val()) {
        $(element).addClass('error');
      }
    })
    // MANAGE RED OUTLINE ON CHANGE
    $("#new_ubo_thFirstName, #new_ubo_thLastName, #new_ubo_thAddress, #new_ubo_thCity, #new_ubo_thPostalCode, .new_ubo_thCountry, .new_ubo_thNationality, #new_ubo_thBirthday, .new_ubo_thBirthCountry, #new_ubo_thBirthCity").on('input', function(e) {
      if ($(this).attr('class').includes("new_ubo_thCountry") || $(this).attr('class').includes("new_ubo_thNationality") || $(this).attr('class').includes("new_ubo_thBirthCountry")) {
        if ($(this).val()) {
          $(this).parent().removeClass('error');
        } else {
          $(this).parent().addClass('error');
        }
      } else if ($(this).val()) {
        $(this).removeClass('error');
      } else {
        $(this).addClass('error');
      }
      if (formUboWellFilled()) {
        $('.js-uboErrors').hide();
      }
    })
  } else if(!isAdult($("#new_ubo_thBirthday").val())) {
    $("#new_ubo_thBirthday").addClass('error');
    $('.js-uboErrors').show(0, function() {
      $(this).text(translation("bénéficiaire majeur"));
    });
  } else {
    const promiseReturn = $.post("/freelance/mon_profil/entreprise/kyc/ubo/form", {uboId: uboId, firstname: thFirstName, lastname: thLastName, thAddress: thAddress, thCity: thCity, thPostalCode: thPostalCode, thCountry: thCountry, nationality: thNationality, birthday: thBirthday, birthCountry: thBirthCountry, birthCity: thBirthCity});
    promiseReturn.then(function(val) {
      let v = JSON.parse(val);
      if (v.status === "failed") {
        $('.js-uboErrors').show(0, function() {
          $(this).text(v.message);
        });
      } else if (v.status === 'success') {
        document.location.reload();
      }
    })
  }
})

function formUboWellFilled() {
  let filled = true;
  ["#new_ubo_thFirstName", "#new_ubo_thLastName", "#new_ubo_thAddress", "#new_ubo_thCity", "#new_ubo_thPostalCode", ".new_ubo_thCountry", ".new_ubo_thNationality", "#new_ubo_thBirthday", ".new_ubo_thBirthCountry", "#new_ubo_thBirthCity"].forEach(function(singleID) {
    if (filled === true) {
      filled = $(singleID).val() ? true : false;
    }
  })
  return filled;
}