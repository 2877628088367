import {
    convertPrice,
    convertStringToNumber, getCardBrand,
    getEncryptedEmail,
    numberDaysDelate, openPopin,
    translation
} from "../generalFunctions";
import $ from "jquery";
import {validCreditCard} from "../components/verificationFront";

$(() => {
    if ($(".msg-flash div").length > 0) {
        $(".msg-flash").css("display", "block");
    }
    else {
        $(".msg-flash").css("display", "none");
    }

    if ($("body").data("currentpage") === "payment_offline") {
        $(".msg-flash").css({"padding": "0", "top": "10px"});
    }

    // Function which copy the phone number on click
    $(".js-iconPhone").on("click", () => {
        $(".st-copiedPhoneNumber").css("display", "block");
        let getPhone = $(".js-iconPhone").data("getphone");
        let temp = $("<input>");
        $("body").append(temp);
        temp.val(getPhone).trigger("select");
        document.execCommand("copy");
        temp.remove();
        setTimeout(() => {
            $(".st-copiedPhoneNumber").css("display", "none");
        }, 4000);
    });

    // Function wich copy the iban on click
    $(".js-div-bankInfos-iban").on("click", () => {
        $(".st-copiedIban").css("display", "block");
        let valueIban = $(".js-div-bankInfos-iban p:nth-child(1)").text();
        let temp = $("<input>");
        $("body").append(temp);
        temp.val(valueIban).trigger("select");
        document.execCommand("copy");
        temp.remove();
        setTimeout(() => {
            $(".st-copiedIban").css("display", "none");
        }, 4000);
    });

    let arrayOfId = [];

    $(".js-checkboxOffline, #total").prop("checked", true);
    $(".js-checkboxOffline, #total").attr("checked", true);
    $(".content-to-pay > .content-item").each(function() {
        let getId = $(this).data("payment-id");
        arrayOfId.push(getId);
    });

    window.changeStateCheckbox = function (id) {
        let getPrice = convertStringToNumber($("#js-price" + id).text());
        let getOldPrice = convertStringToNumber($("#js-oldprice" + id).text())
        let getEscomptePrice = convertStringToNumber($("#js-escomptePrice" + id).text());
        let getTotal = convertStringToNumber($(".js-total").text());
        let removePriceToTotal;
        let addPriceToTotal;

        if ($("#payment4").is(":checked")) {
            checkIsValid3xOfCharge();
        }

        if ($("#js-checkbox" + id).attr('checked')) {
            $("#js-checkbox" + id).removeAttr('checked');

            let indexId = arrayOfId.indexOf(id);
            if (indexId !== -1) {
                arrayOfId.splice(indexId, 1);
            }

            if ($("#js-escomptePrice" + id).length > 0) {
                removePriceToTotal = getTotal - getEscomptePrice;
            }
            else {
                removePriceToTotal = getTotal - getPrice;
            }
            if (getTotal < getPrice || getTotal < getEscomptePrice) {
                $(".js-total, .js-totalBtnPayed").text("0,00 €");
            }
            else if (removePriceToTotal === 0) {
                $(".js-total, .js-totalBtnPayed").text(convertPrice(removePriceToTotal));
                $(".payment-out .virement").css("background-color", "#ABB0AF").css("cursor", "initial").removeClass("js-openpopin").css('color', 'white');
            }
            else {
                $(".js-total, .js-totalBtnPayed").text(convertPrice(removePriceToTotal));
                $('.js-to-facility').data('price', convertPrice(removePriceToTotal));
            }
        }
        else {
            $("#js-checkbox" + id).attr("checked", true);

            let indexId = arrayOfId.indexOf(id);
            if (indexId === -1) {
                arrayOfId.push(id);
            }

            if ($("#js-escomptePrice" + id).length > 0) {
                addPriceToTotal = getTotal + getEscomptePrice;
            }
            else {
                addPriceToTotal = getTotal + getPrice;
            }
            $(".js-total, .js-totalBtnPayed").text(convertPrice(addPriceToTotal));
            $('.js-to-facility').data('price', convertPrice(addPriceToTotal));
            $(".payment-out .virement").css("background-color", "").css("cursor", "").addClass("js-openpopin").css('color', '');
        }

        let total = $(".js-total").text().replace(",", "").replace("€", "").replaceAll(" ", "");

        if ($("#payment2").is(":checked")) {
            if (total > 100000) {
                if ($(".st-divBtnPayed").has(".st-bankCardCTA").length === 0) {
                    addBankCardCTA();
                }
                $(".js-openpopin-payment-offline").css({"background-color": "#ABB0AF", "cursor": "initial", 'color': 'white'}).removeClass("js-openpopin");
                $(".js-openpopin-payment-offline").on("mouseenter", function() {
                    $(".st-bankCardCTA").show();
                }).on("mouseleave", function() {
                    $(".st-bankCardCTA").hide();
                });
            }
            else {
                $(".js-openpopin-payment-offline").css({"background-color": "", "cursor": "", 'color': ''}).addClass("js-openpopin js-createCard"); // add "js-createCard"
                $(".st-bankCardCTA").remove();
            }
        }
        if ($("#payment3").is(":checked")) {
            if (total > 2000000) {
                if ($(".st-divBtnPayed").has(".st-prelevementCTA").length === 0) {
                    addPrevelementCTA();
                }
                $(".js-openpopin-payment-offline").css({"background-color": "#ABB0AF", "cursor": "initial", 'color': 'white'}).removeClass("js-openpopin");
                $(".js-openpopin-payment-offline").on("mouseover", function() {
                    $(".st-prelevementCTA").show();
                }).on("mouseout", function() {
                    $(".st-prelevementCTA").hide();
                });
            }
            else {
                let hasMandate = $(".payment-out").attr("data-hasmandate");
                if (hasMandate === "true") {
                    $('.js-directDebitWithMandate').addClass("hasMandate");
                    $(".js-openpopin-payment-offline").data("target", ".popin-payingWithMandate");
                    $(".st-BlocMensuality").hide();
                    let mandateIban = $(".payment-out").attr("data-mandateiban");
                    let getLastSeven = mandateIban.substring(mandateIban.length - 7);
                    let addSpaceNumber = getLastSeven.match(/.{1,4}/g);
                    if (addSpaceNumber !== null) {
                        $(".st-ibanMandate").text("XXXX XXXX XXXX XXXX XXXX " + addSpaceNumber.join(" "));
                    }
                }
                else {
                    $(".js-openpopin-payment-offline").data("target", ".popin-bankDebit");
                }
                $(".st-prelevementCTA").remove();
                $(".js-openpopin-payment-offline").css({"background-color": "", "cursor": "", 'color': ''}).addClass("js-openpopin"); 
            }
        }
    }

    $(".company-payed").on("click", () => {
        $(".st-paymentOfflineBloc").hide();
        $(".payedSelected").removeClass("d-none");
        $(".topaySelected").addClass("d-none");
        $('.security-offline-mobile').hide();
        $('.discovery-offline-mobile').removeClass("d-none");
        $(".st-blocIconLock").hide();
        $('.st-blocSubscriptionOfflineMobile').css('margin-bottom', "150px");
    });

    $(".company-to-pay").on("click", () => {
        $(".st-blocIconLock").show();
        $(".topaySelected").removeClass("d-none");
        $(".payedSelected").addClass("d-none");
        $('.security-offline-mobile').css("display", "block");
        $('.discovery-offline-mobile').addClass("d-none");
        $('.st-blocSubscriptionOfflineMobile').css('margin-bottom', "220px");

        if ($(".content-to-pay .content-item").length > 0) {
            $(".st-paymentOfflineBloc").show();
        }
    });

    // We fill the iban 
    if (window.location.pathname === "/payer_mes_fournisseurs") {
        let userId = $(".js-div-bankInfos").data("userid");
        let promiseReturn = $.post("/getDefaultIban", {userId: userId});
        promiseReturn.then((val) => { 
            let v = JSON.parse(val);
            if (v.status === 'success') {
                if (v.iban) {
                    $(".js-setIban").text(v.iban);
                }
            }
        });
    }

    // We define global variable which get email and fournisseur from URL
    let vars = {};
    window.location.href.replace( location.hash, '' ).replace(/[?&]+([^=&]+)=?([^&]*)?/gi,
        function( m, key, value ) {
            vars[key] = value;
        });
    let email = vars['email'];
    let fournisseur = vars['fournisseur'];

    // We create a message if we haven't bill to pay
    if ($(".content-to-pay .content-item").length < 1) {
        let message = translation("Bonne nouvelle");
        if (window.location.pathname === '/approbation'){
            message = translation("Aucune approbation");
        }
        let emptyBillToPay = $("<div>", {
            class: "st-emptyBillToPay",
            html: "<p>" + message + "</p>"
        });
        $(".content-to-pay").append(emptyBillToPay);
        $(".st-paymentOfflineBloc").hide();
    }

    $(document).on('click', '.js-createCard', function(e) {
        openPopin('.popin-card-form');
    });

    $(".js-submitCard").on("click", function(e) {
        $('.loader-popin').fadeIn();
        let getAmount = $(".js-total").text();
        let convertAmount = convertStringToNumber(getAmount);

        let javaEnabled = navigator.javaEnabled();
        let language = navigator.language;
        let javascriptEnabled = true;
        let colorDepth = screen.colorDepth;
        let screenHeight = screen.height;
        let screenWidth = screen.width;
        let timeZoneOffset = new Date().getTimezoneOffset();
        let cardNumber = $('.js-cardNumber').val();
        let cardExpirationDate = $('.js-addingSlash').val().replace("/", "");
        let cardCvx = $("input[name='cardCvx']").val();
        let cardType = getCardBrand(cardNumber);

        let promiseReturn = $.post("/payer_mes_fournisseurs/cb", {
            'cardType': cardType
        });
        promiseReturn.then(function(val) {
            let v = JSON.parse(val);
            if (v.status === 'success') {
                let cardId = v.id;
                let promiseReturn2 = $.post(v.url, {
                    "accessKeyRef": v.AccessKey,
                    "data": v.PreregistrationData,
                    "cardNumber": cardNumber,
                    "cardExpirationDate": cardExpirationDate,
                    "cardCvx": cardCvx
                });
                promiseReturn2.then(function (val) {
                    let data = val.substring(5);
                    let promiseReturn3 = $.post('/payer_mes_fournisseurs/update_card', {
                        "cardId": cardId,
                        "data": data
                    })
                    promiseReturn3.then(function (val) {
                        let w = JSON.parse(val);
                        if (w.status === "success") {
                            let finalCardId = w.id;
                            let promiseReturn4 = $.post("/payer_mes_fournisseurs/payin_card", {
                                "cardId": finalCardId,
                                "redirectUrl" : window.location.href,
                                "amount": convertAmount,
                                'payments': arrayOfId,
                                'javaEnabled': javaEnabled,
                                'javascriptEnabled': javascriptEnabled,
                                'colorDepth': colorDepth,
                                'screenHeight': screenHeight,
                                'screenWidth': screenWidth,
                                'timeZoneOffset': timeZoneOffset,
                                'language' : language,
                                'cardType': cardType
                            });
                            promiseReturn4.then(function (val) {
                                let x = JSON.parse(val);
                                if (x.status === "success") {
                                    window.location.href = x.redirectUrl;
                                }
                                else {
                                    let error = x.message;
                                    $('.loader-popin').fadeOut();
                                    $('.js-submitCard').removeClass('st-no-btn-disabled');
                                    $(".st-blocPopinError").addClass("d-flex").addClass('fs10').removeClass("d-none");
                                    $('.errorMsgOffline').html(error);
                                }
                            })
                        }
                        else {
                            let error = x.message;
                            $('.loader-popin').fadeOut();
                            $('.js-submitCard').removeClass('st-no-btn-disabled');
                            $(".st-blocPopinError").addClass("d-flex").addClass('fs10').removeClass("d-none");
                            $('.errorMsgOffline').html(error);
                        }
                    })
                })
            }
            else {
                if (v.message) {
                    $('.st-blocPopinError').removeClass('d-none').addClass('d-flex').addClass('fs10');
                    $('.errorMsgOffline').show();
                    $('.errorMsgOffline').html(v.message);
                    $('.loader-popin').fadeOut();
                }
            }
            $(e.target).removeClass('st-no-btn-disabled');
        });
    });

    // $(".card-form-action").on("submit", function(e) {
    //     let withoutSlash = $(".js-addingSlash").val().replace("/", "");
    //     let actuallyYear = new Date().getFullYear().toString().substr(2, 3);
    //     let actuallyMonth = new Date().getMonth() + 1;
    //     let year = withoutSlash.substr(2);
    //     let month = withoutSlash.substr(0,2);
    //     if (year == actuallyYear && month < actuallyMonth) {
    //         $('.st-blocPopinError').removeClass('d-none').addClass('d-flex');
    //         $('.errorMsgOffline').html(translation("Carte expirée")).show();
    //         return false;
    //     }
    //
    //     $(".js-addingSlash").val(withoutSlash);
    //     $(".backdrop").hide();
    //     $(".backdrop-fix").hide();
    //     $(".popin-wrap").hide();
    //     $(".popin").hide();
    //     $(".popin-errors").hide();
    //     $(".popin-error").remove();
    // });

    $(document).on('click', '.js-bankwire-offline', function(e) {
        $(e.target).addClass('st-no-btn-disabled');
        let getAmount = $(".js-total").text();
        let convertAmount = convertStringToNumber(getAmount);
        let getIban = $(".js-setIban").text();
        let input = $('.js-type-bank');
        let redirectRoute = window.location.href;
        $(".js-bankwire-offline").addClass("st-no-btn-disabled");


        let route = "/payer_mes_fournisseurs/virement";

        if (input.attr('data-country') === 'ES') {
            route = "/payer_mes_fournisseurs/virement_exthand";
        }

        let promiseReturn2 = $.post(route, {
          "bankId": input.attr("data-id"),
          "bankOwner": $('.bank-owner').val(),
          "email": email,
          "fournisseur": fournisseur,
          "amount": convertAmount,
          "iban": getIban,
          "redirectRoute": redirectRoute,
          'payments': arrayOfId
        });
        promiseReturn2.then(function(val) {
          let v = JSON.parse(val);
          if (v.status === 'success') {
              window.location.href = v.url;
          }
          else {
            $(".js-bankwire-offline").removeClass("st-no-btn-disabled");
            if (v.message) {
                $('.st-blocPopinError').removeClass('d-none').addClass('d-flex');
                $('.errorMsgOffline').html(v.message).show();
            }
          }
        });
        promiseReturn2.catch(function(err) {
          // window.location.reload();
        });
    });

    $(".content-to-pay .st-atoutSoan").each(function() {
        let getDateWithEscompte = $(this).find(".st-calculEscompte").attr("data-sendtonow");
        let getDaysWithEscompte = numberDaysDelate(getDateWithEscompte, true);
        if (getDaysWithEscompte > 1) {
            $(this).find(".st-calculEscompte span").text(" " + numberDaysDelate(getDateWithEscompte, true) + " " + translation("jours"));
        }
        else {
            $(this).find(".st-calculEscompte span").text(" " + numberDaysDelate(getDateWithEscompte, true) + " " + translation("jour"));
        }
    });

    if ($("body").attr("data-currentpage") === "payment_offline") {
        $("body").css("background-color", "#F8F8F8");
    }

    $(".st-crossPopinError").on("click", function() {
        $(".st-blocPopinError, .st-blocFormError1, .st-blocFormError2").removeClass("d-flex").addClass("d-none");
    });

    $(".js-addingSlash").on('input keydown keyup', function(e) {
        let val = $(this).val();
        let pattern = /\/[0-9]{2}/;
        let value = val.replace(/[^0-9.,/]/g, '');
        let actuallyYear = new Date().getFullYear().toString().substr(2, 3);
        let actuallyMonth = new Date().getMonth() + 1;
    
        if (val.length === 2 && e.key !== "Backspace" && e.key !== undefined && e.key !== "/" && e.key !== "Shift") {
            value = value + "/";
        }
        else if (val.length > 1 && val.length < 5 && e.key !== "Backspace") {
            if (value > 12) {
                value = 12 + "/";
            }
        }
        else if (val.length > 4) {
            let result = value.match(pattern).join().replace("/", "");
            if (Number(result) < actuallyYear) {
                value = value.replace(pattern, "/" + actuallyYear);
            }
        }
        $(this).val(value);
    });

    $(".js-cardNumber").on("input", function() {
        let val = $(this).val();
        let value = val.replace(/[^0-9]/g, '');
        if (validCreditCard(value)) {
            $(this).removeClass("error");
            checkAllInputsPopin(".popin-card-form", ".st-submitCard", true);
        }
        else {
            $(this).addClass("error");
            checkAllInputsPopin(".popin-card-form", ".st-submitCard", false, translation("Veuillez entrez un numéro de carte valide."));
        }
        $(this).val(value);
    });

    $(".popin-bankwire-infos .valid-front").on("input", function() {
        checkAllInputsPopin(".popin-bankwire-infos", ".js-bankwire-offline", true);
    });

    $(".popin-card-form .valid-front").on("input", function() {
        checkAllInputsPopin(".popin-card-form", ".st-submitCard", true);
    });

    $(".popin-bankDebit .valid-front").on("input", function() {
        checkAllInputsPopin(".popin-bankDebit", ".js-payingDirectDebit", true);
    });

    $(".popin-paying-3xFreeOfCharge .valid-front").on("input", function() {
        checkAllInputsPopin(".popin-paying-3xFreeOfCharge", ".js-paying3x", true);
    });

    $(".popin-payingWithMandate .valid-front").on("input", function() {
        checkAllInputsPopin(".popin-payingWithMandate", ".js-directDebitWithMandate", true);
    });

    $("#payment1").attr("checked", true);
    $("#payment1").prop("checked", true);

    $("#payment1").on("click", () => {
        $('.js-openpopin-payment-offline').addClass('find-all-banks');
        $(".st-bankCardCTA, .st-3xFreeOfChargeCTA, .st-prelevementCTA").remove();
        $(".js-openpopin-payment-offline").css({"background-color": "", "cursor": "", 'color': ''}).addClass("js-openpopin");
        $(".js-openpopin-payment-offline").data("target", ".popin-bankwire-infos");
    });

    $("#payment2").on("click", () => {
        $(".st-3xFreeOfChargeCTA, .st-prelevementCTA").remove();
        $(".js-openpopin-payment-offline").css({"background-color": "", "cursor": "", 'color': ''}).addClass("js-openpopin");
        $(".js-openpopin-payment-offline").data("target", ".popin-card-form");
        $(".st-bankCardCTA, .st-3xFreeOfChargeCTA").hide();
        let total = $(".js-total").text().replace(",", "").replace("€", "").replaceAll(" ", "");
        if (total > 100000) {
            addBankCardCTA();
            $(".js-openpopin-payment-offline").css({"background-color": "#ABB0AF", "cursor": "initial", 'color': 'white'}).removeClass("js-openpopin");
            $(".js-openpopin-payment-offline").on("mouseover", function() {
                $(".st-bankCardCTA").show();
            }).on("mouseout", function() {
                $(".st-bankCardCTA").hide();
            });
        }
        else {
            $(".js-openpopin-payment-offline").addClass('js-createCard')
        }
    });

    $("#payment3").on("click", () => {
        let hasMandate = $(".payment-out").attr("data-hasmandate");
        $(".st-bankCardCTA, .st-3xFreeOfChargeCTA").remove();
        $(".js-openpopin-payment-offline").css({"background-color": "", "cursor": "", 'color': ''}).addClass("js-openpopin");
        let getCompanyName = $(".payment-out").data("companyname");
        $(".js-getCompanyName").val(getCompanyName);

        let total = $(".js-total").text().replace(",", "").replace("€", "").replaceAll(" ", "");
        if (total > 2000000) {
            addPrevelementCTA();
            $(".js-openpopin-payment-offline").css({"background-color": "#ABB0AF", "cursor": "initial", 'color': 'white'}).removeClass("js-openpopin");
            $(".js-openpopin-payment-offline").on("mouseover", function() {
                $(".st-prelevementCTA").show();
            }).on("mouseout", function() {
                $(".st-prelevementCTA").hide();
            });
        }
        else {
            if (hasMandate === "true") {
                $('.js-directDebitWithMandate').addClass("hasMandate");
                $(".js-openpopin-payment-offline").data("target", ".popin-payingWithMandate");
                $(".st-BlocMensuality").hide();
                let mandateIban = $(".payment-out").attr("data-mandateiban");
                let getLastSeven = mandateIban.substring(mandateIban.length - 7);
                let addSpaceNumber = getLastSeven.match(/.{1,4}/g);
                if (addSpaceNumber !== null) {
                    $(".st-ibanMandate").text("XXXX XXXX XXXX XXXX XXXX " + addSpaceNumber.join(" "));
                }
            }
            else {
                $(".js-openpopin-payment-offline").data("target", ".popin-bankDebit");
            }
        }
    });

    $("#payment4").on("click", () => {
        let hasMandate = $(".payment-out").attr("data-hasmandate");
        $(".st-bankCardCTA").remove();
        $(".js-openpopin-payment-offline").css({"background-color": "", "cursor": "", 'color': ''}).addClass("js-openpopin js-openpopin-bank").removeClass("st-isHover");
        checkIsValid3xOfCharge();
        let getCompanyName = $(".payment-out").data("companyname");
        $(".js-getCompanyName").val(getCompanyName);

        if (hasMandate === "true") {
            $('.js-directDebitWithMandate').addClass("hasMandate");
            $(".js-openpopin-payment-offline").data("target", ".popin-payingWithMandate");
            $(".st-BlocMensuality").show();
            let mandateIban = $(".payment-out").attr("data-mandateiban");
            let getLastSeven = mandateIban.substring(mandateIban.length - 7);
            let addSpaceNumber = getLastSeven.match(/.{1,4}/g);
            if (addSpaceNumber !== null) {
                $(".st-ibanMandate").text("XXXX XXXX XXXX XXXX XXXX " + addSpaceNumber.join(" "));
            }
        }
        else {
            $(".js-openpopin-payment-offline").data("target", ".popin-paying-3xFreeOfCharge");
        }

    });

    $("#payment1, #payment2, #payment3, #payment4").on("click", () => {
        $('.st-blocPopinError').addClass('d-none').removeClass("d-flex");
        let total = $(".js-total").text().replace(",", "").replace("€", "").replaceAll(" ", "");
        if (total === "000") {
            $(".js-openpopin-payment-offline").css({"background-color": "#ABB0AF", "cursor": "initial", 'color': 'white'}).removeClass("js-openpopin");
        }
    });

    $("#payment1, #payment3, #payment4").on("click", () => {
        $(".js-openpopin-payment-offline").removeClass('js-createCard');
    });
    $("#payment1, #payment2, #payment4").on("click", () => {
        $(".js-directDebitWithMandate").removeClass('hasMandate');
    });
    $("#payment2, #payment3, #payment4").on("click", () => {
        $(".js-openpopin-payment-offline").removeClass('find-all-banks');
    });

    $(".js-sendAuthenticationCode").on("click", () => {
        $('.loader-popin').fadeIn();
        $('.js-sendAuthBtn').addClass('js-directDebitWithMandate ');
        $('.js-sendNewCode').addClass('sendForPayment');
        let payments = "";
        for (let i = 0; i < arrayOfId.length; i++) {
            payments += arrayOfId[i]+"-";
        }

        let promiseReturn = $.post('/payer_mes_fournisseurs/authentification', {
            "email": email,
            "fournisseur": fournisseur,
            "payments": payments,
            "newCode": "false"
        })

        promiseReturn.then(function (val) {
            let v = JSON.parse(val);
            if (v.status === "success") {
                let encryptedEmail = getEncryptedEmail(v.email);

                $('.js-cryptedEmail').html(encryptedEmail);
                $('.js-sendNewCode').attr('data-email', encryptedEmail);
                $('.popin-wrap.popin-payingWithMandate').hide();
                $('.popin-wrap.popin-authenticate').show();
                $('.loader-popin').fadeOut();
            }
            else if(v.status === "authenticated") {
                let btn = $('.js-directDebitWithMandate');
                btn.attr('data-authenticated', true);
                btn.trigger('click');
            }
            else {
                window.location.href = v.url;
            }
        });
    });

    $(document).on('click', '.sendForPayment', function () {
        $('.loader-popin').fadeIn();

        let promiseReturn = $.post('/payer_mes_fournisseurs/authentification', {
            "newCode": true,
            "email": email
        })
        promiseReturn.then(function (val) {
            let v = JSON.parse(val);
            if (v.status === "success") {
                let encryptedEmail = getEncryptedEmail(v.email);
                $('.js-noCodeReceived').html("Un nouveau code vient d'être envoyé à " + encryptedEmail + ". Toujours pas reçu ? Contactez nous à support@soan-solutions.io")
                $('.loader-popin').fadeOut();
            }
            else if(v.status === "authenticated") {
                let btn = $('#js-directDebitWithMandate');
                btn.attr('data-authenticated', true);
                btn.trigger('click');
            }
            else {
                window.location.href = v.url;
            }
        })
    })
    $('.st-crossInfosMessage').on('click', function () {
        $('.st-infoPopin').removeClass('d-flex').addClass('d-none');
    })
    $(document).on('click', '#js-directDebitWithMandate', () => {
        if (window.location.pathname.includes("/payer_mes_fournisseurs")) {
            $('.loader-popin').fadeIn();
            $(this).addClass('st-no-btn-disabled');
            let getAmount = $(".js-total").text();
            let convertAmount = convertStringToNumber(getAmount);
            let payments = "";
            let isAuthenticated = $(".js-directDebitWithMandate").attr('data-authenticated');
            for (let i = 0; i < arrayOfId.length; i++) {
                payments += arrayOfId[i] + "-";
            }

            let isMultiPayment = false;

            $(".content-to-pay .content-item").each(function () {
                let checkbox = $(this).find(".js-checkboxOffline");
                if (checkbox.is(":checked") && $(this).attr("data-multipayment") === "1") {
                    isMultiPayment = true;
                }
            });

            let code = $('.js-authenticationInput').val()

            let promiseReturn = $.post('/payer_mes_fournisseurs/mandate_pay', {
                "isAuthenticated": isAuthenticated,
                "code": code,
                "email": email,
                "fournisseur": fournisseur,
                "amount": convertAmount,
                "payments": payments,
                "isMultiPayment": isMultiPayment
            })

            promiseReturn.then(function (val) {
                let v = JSON.parse(val);
                if (v.status === "success" || (v.status === "failed" && v.url)) {
                    window.location.href = v.url
                } else {
                    $('.loader-popin').fadeOut();
                    $('.st-blocPopinError').removeClass('d-none').addClass('d-flex');
                    $('.errorMsgOffline').html(v.message).show();
                    $(this).removeClass('st-no-btn-disabled');
                }
            })
        }
    });

    $('.js-authenticationInput').on('input', function () {
        let btnPayment = $('.js-directDebitWithMandate');
        let btnApproval = $('.js-approve');
        if ($(this).val().length === 6) {
            btnPayment.removeClass('st-no-btn-disabled');
            btnApproval.removeClass('st-no-btn-disabled');
        }
        else {
            if (!btnPayment.hasClass('st-no-btn-disabled')) {
                btnPayment.addClass('st-no-btn-disabled')
            }
            if (!btnApproval.hasClass('st-no-btn-disabled')) {
                btnApproval.addClass('st-no-btn-disabled')
            }
        }
    })

    $(".js-payingDirectDebit").on("click", function() {
        directDebitRequest(".js-debit-bankOwner", ".js-debit-bankOwnerAddress", ".js-debit-bankOwnerCity",".js-debit-bankOwnerPostalCode",
        ".js-debit-bankOwnerCountry", ".js-debit-iban", ".js-debit-firstname", ".js-debit-lastname", true, this);
    });

    $(".js-paying3x").on("click", function() {
        directDebitRequest(".js-3x-bankOwner", ".js-3x-bankOwnerAddress", ".js-3x-bankOwnerCity", ".js-3x-bankOwnerPostalCode",
        ".js-3x-bankOwnerCountry", ".js-3x-iban", ".js-3x-firstname", ".js-3x-lastname", false, this);
    });

    window.hidingBloc3x = (id) => {
        $("#js-bloc3x" + id).removeClass("d-flex").addClass("d-none");
    }

    $(".st-helpIcon").on("mouseover", function() {
        $(".st-cryptoMsg").show();
    }).on("mouseout", function() {
        $(".st-cryptoMsg").hide();
    });

    $(".content-to-pay .content-item").each(function() {
        if ($(this).attr("data-multipayment") === "1") {
            $(".st-is3xExist").show();
        }
    });
    
    if ($(".content-to-pay .content-item").length === 1) {
        $(".st-3xOffline").removeClass("d-flex").addClass("d-none");
    }

    function directDebitRequest(bankOwnerData, bankOwnerAddressData, bankOwnerCityData, bankOwnerPostalCodeData, bankOwnerCountryData, ibanData, firstnameData, lastnameData, directDebit, button) {
        let getAmount = $(".js-total").text();
        let convertAmount = convertStringToNumber(getAmount);
        let bankOwner = $(bankOwnerData).val();
        let bankOwnerAddress = $(bankOwnerAddressData).val();
        let bankOwnerCity = $(bankOwnerCityData).val();
        let bankOwnerPostalCode = $(bankOwnerPostalCodeData).val();
        let bankOwnerCountry = $(bankOwnerCountryData).val();
        let iban = $(ibanData).val();
        let firstname = $(firstnameData).val();
        let lastname = $(lastnameData).val();
        let isMultiPayment = false;
        let isDirectDebit = directDebit;
        $(button).addClass("st-no-btn-disabled");

        $(".content-to-pay .content-item").each(function() {
            let checkbox = $(this).find(".js-checkboxOffline");
            if (checkbox.is(":checked") && $(this).attr("data-multipayment") === "1" && !isDirectDebit) {
                isMultiPayment = true;
            }
        });

        let promiseReturn = $.post("/payer_mes_fournisseurs/prelevement", {
            "email": email,
            "fournisseur": fournisseur,
            "amount": convertAmount,
            "payments": arrayOfId,
            "bankOwner": bankOwner,
            "bankOwnerAddress": bankOwnerAddress,
            "bankOwnerCity": bankOwnerCity,
            "bankOwnerPostalCode": bankOwnerPostalCode,
            "bankOwnerCountry": bankOwnerCountry,
            "iban": iban,
            "firstname": firstname,
            "lastname": lastname,
            "isMultiPayment": isMultiPayment
        });
        promiseReturn.then((val) => {
            let v = JSON.parse(val);
            if (v.status === 'success') {
                window.location.href = v.redirectUrl;
            }
            else {
                $(".popin").scrollTop(0);
                let message = v.message;
                $('.st-blocPopinError').removeClass('d-none').addClass('d-flex');
                $('.errorMsgOffline').html(message);
                $(button).removeClass("st-no-btn-disabled");
            }
        });
    }

    const checkAllInputsPopin = (container, button, verif, msgError = translation("Veuillez renseigner tous les champs")) => {
        let emptyInputs = false;
        $(container + " .js-handleInputsValidation input").each(function() {
            if ($(this).val() === "") {
                emptyInputs = true;
            }
        });
        
        if (!emptyInputs && verif) {
            $(button).removeClass("st-no-btn-disabled");
            $(this).removeClass("error");
            $(".st-SEPA").css("margin-bottom", "50px");
            $(container + " .st-blocPopinError").addClass("d-none").removeClass("d-flex");
        }
        else {
            $(button).addClass("st-no-btn-disabled");
            $(this).addClass("error");
            $(".st-SEPA").css("margin-bottom", "10px");
            $(container + " .errorMsgOffline").text(msgError).show();
            $(container + " .st-blocPopinError").addClass("d-flex").removeClass("d-none");   
        }
    }

    const checkIsValid3xOfCharge = () => {
        setTimeout(() => {
            let countBill = 0;
            let arrayPaymentsChecked = [];
            let is3xFreeOfCharge = false;
            let is3xSelected = false;
            let discount;
            let discountLimit;
            let amount;
            $(".st-3xFreeOfChargeCTA").remove();

            $(".content-to-pay .content-item").each(function() {
                let checkbox = $(this).find(".js-checkboxOffline");
                let getDataIdPayment = $(this).attr("data-payment-id");
                if(checkbox.is(":checked")) {
                    countBill++;
                    arrayPaymentsChecked.push(getDataIdPayment);
                }
            });
            for (let idPayment of arrayPaymentsChecked) {
                if ($("#" + idPayment).attr("data-multipayment") === "1") {
                    if (countBill === 1) {
                        is3xFreeOfCharge = true;
                        discount = $("#" + idPayment).attr("data-discount");
                        discountLimit = $("#" + idPayment).attr("data-discountLimit");
                        amount = $("#" + idPayment).attr("data-amount");
                    }
                    is3xSelected = true;
                }
            }
            if (countBill > 1) {
                if (is3xSelected) {
                    let message = translation("Sélectionnez une seule facture");
                    add3xFreeOfChargeCTA(message);
                }
                else {
                    let message = translation("Ces factures ne sont pas éligibles au 3x sans frais");
                    add3xFreeOfChargeCTA(message);
                }

                $(".js-openpopin-payment-offline").css({"background-color": "#ABB0AF", "cursor": "initial", 'color': 'white'}).removeClass("js-openpopin js-openpopin-bank");
                $(".js-openpopin-payment-offline").on("mouseover", function() {
                    $(".st-3xFreeOfChargeCTA").show();
                }).on("mouseout", function() {
                    $(".st-3xFreeOfChargeCTA").hide();
                });
            }
            else if (!is3xFreeOfCharge) {
                let message = translation("Cette facture n'est pas éligible au 3x sans frais")
                add3xFreeOfChargeCTA(message);

                $(".js-openpopin-payment-offline").css({"background-color": "#ABB0AF", "cursor": "initial", 'color': 'white'}).removeClass("js-openpopin js-openpopin-bank");
                $(".js-openpopin-payment-offline").on("mouseover", function() {
                    $(".st-3xFreeOfChargeCTA").show();
                }).on("mouseout", function() {
                    $(".st-3xFreeOfChargeCTA").hide();
                });
            }
            else {
                $(".js-openpopin-payment-offline").css({"background-color": "", "cursor": "", 'color': ''}).addClass("js-openpopin js-openpopin-bank");
                if (discount === undefined) {
                    discount = null;
                }
                $(".js-openpopin-payment-offline").attr("data-ismultipayment", "1").attr("data-discount", discount).attr("data-discountlimit", discountLimit).attr("data-amount", amount);
                $(".st-3xFreeOfChargeCTA").remove();
            }
        }, 0);
    }

    const addPrevelementCTA = () => {
        $(".st-divBtnPayed").append('<div class="st-prelevementCTA fs12">' + translation('20 000 € maximum par prélèvement') +'</div>');
    }

    const addBankCardCTA = () => {
        $(".st-divBtnPayed").append('<div class="st-bankCardCTA fs12">' + translation('1 000 € maximum par carte') +'</div>');
    }

    const add3xFreeOfChargeCTA = (message) => {
        $(".st-divBtnPayed").append('<div class="st-3xFreeOfChargeCTA fs12"><p>' +message + '</p></div>');
    }
});
