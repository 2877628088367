import {getUrlParams, openPopin} from "../../generalFunctions";
import $ from "jquery";
let dataLayer = window.dataLayer || [];

if (window.location.pathname === "/") {
    dataLayer.push({
        'event': 'view_login'
    })

    const begin_login = (method) => {
        dataLayer.push({
            'event': 'begin_login',
            'method': method
        });
    };

    $(document).on('click', '.googleconnect, .linkdinconnect', function () {
        let method;

        $(this).hasClass('linkdinconnect') ? method = "linkedin" : method = "google";

        begin_login(method);
    });

    let flag = false;

    $(document).on('input', '#inputThEmail, #inputPassword', function () {
        if (!flag) {
            begin_login("email");
            flag = true;
        }

    })
}
if (window.location.pathname === "/" && (getUrlParams(window.location.href)["site"]=== "connexion" || getUrlParams(window.location.href)["site"] === "conexion")) {
    $('#fromWebSite').val('true');
}

if (window.location.pathname === "/" && (getUrlParams(window.location.href)["site"]=== "activated")) {
    dataLayer.push({
        'event': 'sign_up',
        'method': 'email',
    })
}

if (window.location.pathname === "/inscription") {

    //data layers for sign up events when user comes from website
    dataLayer.push({
        'event': 'view_sign_up'
    })

    const begin_signup = (method) => {
        dataLayer.push({
            'event': 'begin_sign_up',
            'method': method
        });
    };

    $(document).on('click', '.googleconnect, .linkdinconnect', function () {
        let method;

        $(this).hasClass('linkdinconnect') ? method = "linkedin" : method = "google";

        begin_signup(method);
    });

    let flag = false;

    $(document).on('input', '#usermail, #userpassword', function () {
        if (!flag) {
            begin_signup("email");
            flag = true;
        }
    })

}

if (window.location.pathname === "/" && (getUrlParams(window.location.href)["popinPlanedPayment"]=== "1")) {
    setTimeout(() => {
        openPopin('.popin-paymentIncoming');
    }, 1000);
}

$('.btn-signup-step1').on('click', (e) => {
    $(e.target).addClass('st-no-btn-disabled');
    $('#userPhonenumber').removeClass('error');
    const email = $('.signup-step1 #usermail').val();
    const password = $('.signup-step1 #userpassword').val();
    let lbp;

    if ($("body").hasClass('lbp-page')) {
        lbp = "true";
    }

    let fromWebsite = "false";

    if (window.location.pathname === "/inscription" && (getUrlParams(window.location.href)["sitio"] === "inscripcion" || getUrlParams(window.location.href)["site"] === "inscription")) {
        fromWebsite = "true";
    }

    let response = $.post("/inscription/step1", {email: email, password: password, fromWebsite: fromWebsite, lbp: lbp});
    response.then(function(val) {
        let v = JSON.parse(val);
        if (v.status === 'ok') {
            $('.st-login-container').addClass('st-height-439px');
            $('.cerror').text('');
            $('.signup-step1').addClass('d-none');
            $('.signup-step2').removeClass('d-none');
            $('.btn-signup-step2').attr('data-userId', v.userId)
            dataLayer.push({
                'event': 'view_personal_details',
                'method': 'email'
            })
        } else {
            $(".st-blocFormError1").removeClass("d-none").removeClass("mb0").addClass("d-flex");
            $('.js-errorMsg').html(v.message).show();
            $('.st-login-container').addClass('h584');
            $('#usermail').addClass('error');
        }
        $(e.target).removeClass('st-no-btn-disabled');
    })
})
$('.st-crossPopinError').on('click', function () {
    $('.st-login-container').removeClass('h584');
})

$('.signup-step2 #userFirstname, .signup-step2 #userLastname').on('input', function () {
    let deleteDoubleQuote = $(this).val().replace('"', '').replace("'", '');
    $(this).val(deleteDoubleQuote);
});

$('.btn-signup-step2').on('click', (e) => {
    $(e.target).addClass('st-no-btn-disabled');
    const firstname = $('.signup-step2 #userFirstname').val();
    const lastname = $('.signup-step2 #userLastname').val()
    const phoneNumber = $('.signup-step2 #userPhonenumber').val();
    const userId = $(e.target).attr('data-userid');

    let response = $.post("/inscription/step2", {firstname: firstname, lastname: lastname, phoneNumber: phoneNumber, userId: userId});
    response.then(function(val) {
        let {status, message} = JSON.parse(val);
        if (status === 'ok') {
            dataLayer.push({
                'event': 'submit_personal_details',
                'method': 'email'
            })
            if ($(e.target).hasClass('js-userPresent')) {
                window.location.replace('/freelance/');
            } else {
                $("#js-activatePopin-userMail").text($('.signup-step1 #usermail').val());
                $(".activatepopin").show();            
            }
        } else {
            $('.cerror').text(message);
        }
        $(e.target).removeClass('st-no-btn-disabled');
    })
})

$(document).on('click', '.close-activation', function(e) {
    $(".activatepopin").remove();
    window.location.replace('/');
});

$(document).on('click', '.btn-lbp', function () {
    $("body").addClass('lbp-page');
})