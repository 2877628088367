import {
    dateConversion,
    getValidFormatDate,
    extractFirstNumberOfString,
    numberWithSpacesWithComa,
    translation,
    differenceDate, divideNumberByThree
} from "../generalFunctions";
import { get } from "core-js/fn/reflect";
import $ from "jquery";

export function projectPayment() {
    $('.backdrop').hide();
    $('.popin').hide();
    $(".popin-wrap").hide();

    $(document).on('click', '.call-bridge', function(e) {
        $(this).addClass('st-no-btn-disabled');
        let projectId = $(this).attr("data-project");
        let paymentId = $(this).attr("data-payment");
        let route = "/virement";
        let input = $('.js-type-bank');
        let id = input.attr('data-id');

        if (input.attr('data-country') === 'ES') {
            route = "/virement_exthand";
        }

        let bankOwner = $(this).closest('.popin-actions').closest('.popin-wrap').find('.bank-owner').val();

        let promiseReturn = $.post("/freelance/projet/" + projectId + "/paiement/" + paymentId + route, {
            bankId: id,
            bankOwner: bankOwner 
        });

        promiseReturn.then((val) => {
            let v = JSON.parse(val);
            if (v.status === 'success') {
                $('.st-blocFormError1').removeClass("d-flex").addClass("d-none");
                window.location.href = v.url;
            } else {
                if (v.message) {
                    $('.st-blocFormError1').removeClass('d-none').addClass("d-flex");
                    $('.js-errorMsg').html(v.message).show();
                    $(this).removeClass('st-no-btn-disabled');
                }
            }
        });

        promiseReturn.catch(function(err) {
            // window.location.reload();
        });
    });

        // Manage step popin with offer escompte and 3 times free of charge
    let paymentValue;
    let firstPaymentValue;
    let arrayPriceTTC = [];
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    let getBillDate;
    let getDaysDelay;

    // Divide payment by three
    function divideNumberByThree(paymentValue, price1, price2, price3) {
        let divider = 3;
        let resultDivider = (paymentValue / divider) * 100;
        let roundResult = Number(resultDivider.toFixed(2));
        let getFirstDecimal = resultDivider.toFixed(1).split(".")[1];
        if ((paymentValue * 100) % divider === 0) {
            firstPaymentValue = roundResult / 100;
            $(price1).text(numberWithSpacesWithComa(firstPaymentValue) + " €");
            $(price2).text(numberWithSpacesWithComa(firstPaymentValue) + " €");
            $(price3).text(numberWithSpacesWithComa(firstPaymentValue) + " €");
        }
        else if (getFirstDecimal < 5) {
            firstPaymentValue = (roundResult / 100);
            $(price1).text(numberWithSpacesWithComa(firstPaymentValue) + " €");
            $(price2).text(numberWithSpacesWithComa(firstPaymentValue) + " €");
            $(price3).text(numberWithSpacesWithComa(firstPaymentValue + 0.01) + " €");
        }
        else {
            firstPaymentValue = (roundResult / 100) - 0.01;
            $(price1).text(numberWithSpacesWithComa(firstPaymentValue) + " €");
            $(price2).text(numberWithSpacesWithComa((roundResult / 100)) + " €");
            $(price3).text(numberWithSpacesWithComa((roundResult / 100)) + " €");
        }
    }

    $(".js-getTotalTTC").on("click", function() {
        let getClassBillDate;
        let getClassDaysDelay;
        if ($(this).attr("data-importbill")) {
            paymentValue = $(".js-valid-ttc").val().replace(",", ".");
            if (paymentValue > 60000) {
                $(".checked3xLabel, .st-blocMonthlyPayment").hide();
                $(".st-error3x").show();
            }
            else {
                $(".checked3xLabel").show();
                $(".st-error3x").hide();
            }
            getBillDate = $(".my-bills-date-send").val();
            getClassBillDate = ".my-bills-date-send";
            getDaysDelay = $(".my-bills-delay").val();
            getClassDaysDelay = ".my-bills-delay";
            divideNumberByThree(paymentValue, ".js-monthyPaymentPrice1Escompte", ".js-monthyPaymentPrice2", ".js-monthyPaymentPrice3");
            $(".js-escompteSelectChoice div").each(function() {
                let getEscompte = $(this).find(".js-valueEscompte").text();
                let numberEscompte = extractFirstNumberOfString(getEscompte);
                let priceDiscount = (paymentValue * numberEscompte) / 100;
                $(this).find(".js-reducePriceEscompte").text("(" + numberWithSpacesWithComa(priceDiscount) + " €)");
            });
            $(".js-payment-mail-sum").text(numberWithSpacesWithComa(paymentValue));
            manageDueDate(getClassBillDate, getClassDaysDelay);

            showingCorrectData(paymentValue, firstPaymentValue, getDaysDelay);
        }
        else {
            getBillDate = $(".bills-date-send").val();
            getClassBillDate = ".bills-date-send";
            getDaysDelay = $(".bills-delay").val();
            getClassDaysDelay = ".bills-delay";
            $(".billselm-ttc").each(function() {
                let getPrice = $(this).text().replace("€", "").replace(",", ".").replace(/\s+/g, "");
                arrayPriceTTC.push(Number(getPrice));
            });
            paymentValue = arrayPriceTTC.reduce(reducer);
            divideNumberByThree(paymentValue, ".js-monthyPaymentPrice1Escompte", ".js-monthyPaymentPrice2", ".js-monthyPaymentPrice3");
            $(".js-escompteSelectChoice div").each(function() {
                let getEscompte = $(this).find(".js-valueEscompte").text();
                let numberEscompte = extractFirstNumberOfString(getEscompte);
                let priceDiscount = (paymentValue * numberEscompte) / 100;
                $(this).find(".js-reducePriceEscompte").text("(" + numberWithSpacesWithComa(priceDiscount) + " €)");
            });
            $('.js-payment-mail-sum').text(numberWithSpacesWithComa(paymentValue));
            manageDueDate(getClassBillDate, getClassDaysDelay);

            if (paymentValue > 60000) {
                $(".checked3xLabel, .st-blocMonthlyPayment").hide();
                $(".st-error3x").show();
            }
            else {
                $(".checked3xLabel").show();
                $(".st-error3x").hide();
            }
            arrayPriceTTC = [];

            showingCorrectData(paymentValue, firstPaymentValue, getDaysDelay);
        }
    });

    const showingCorrectData = (paymentValue, firstPaymentValue, getDaysDelay) => {
        if ($(".st-blocEscompteInput").hasClass("customActive")) {
            let getValueEscompte = Number($(".js-getNumberEscompteInput").val());
            let calculDiscount = (paymentValue * getValueEscompte) / 100;
            $(".js-getPriceEscompteInput").text(numberWithSpacesWithComa(calculDiscount));
            $(".js-monthyPaymentPrice1Escompte").text(numberWithSpacesWithComa((firstPaymentValue - calculDiscount)) + " €");
            $(".billWithEscompte, .js-escompteTotalMail").text(numberWithSpacesWithComa((paymentValue - calculDiscount)) + " €");
            if (getValueEscompte > 0) {
                $(".js-monthyPaymentPrice1").text(numberWithSpacesWithComa(firstPaymentValue) + " €").show();
                $(".js-monthyPaymentWithEscompte, .blocBillWithEscompte").show();
            }
            else {
                $(".js-monthyPaymentWithEscompte, .js-monthyPaymentPrice1").hide();
            }
        }
        else {
            if ($(".js-defaultEscompteChoice").text() !== translation("Sélectionnez")) {
                let getEscompteNumber = $(".js-defaultEscompteChoice .js-valueEscompte").text();
                let calculDiscount = (paymentValue * extractFirstNumberOfString(getEscompteNumber)) / 100;
                $(".js-defaultEscompteChoice .js-reducePriceEscompte").text("(" + numberWithSpacesWithComa(calculDiscount) + " €)");
                $(".billWithEscompte, .js-escompteTotalMail").text(numberWithSpacesWithComa((paymentValue - calculDiscount)) + " €");
                $(".js-monthyPaymentPrice1Escompte").text(numberWithSpacesWithComa((firstPaymentValue - calculDiscount)) + " €");
                $(".js-monthyPaymentPrice1").text(numberWithSpacesWithComa(firstPaymentValue) + " €").show();
                $(".js-monthyPaymentWithEscompte, .blocBillWithEscompte").show();
            }
        }
        if ($(".st-blocEscompteInputDate").hasClass("customActive")) {
            let numberDays = $(".js-getDateEscompteInput").val();
            if (numberDays > getDaysDelay) {
                numberDays = getDaysDelay;
                $(".js-getDateEscompteInput").val(getDaysDelay);
            }
            showingDateDiscount(numberDays);
        }
        else {
            if ($(".js-defaultEscompteDateChoice").text() !== translation("Sélectionnez")) {
                let numberDays = $(".js-defaultEscompteDateChoice .js-valueEscompteDate").text()[0];
                showingDateDiscount(numberDays);
            }
        }
    }

    const getConvertedDateDiscount = (numberDays) => {
        let timeOfDays = numberDays * 86400000;
        let validBillDate = new Date().getTime();
        let newTimeWithDays = validBillDate + timeOfDays;

        return dateConversion(newTimeWithDays)
    }

    const showingDateDiscount = (numberDays) => {
        $(".blocBillWithEscompteDate").show();
        $(".billWithEscompteDate").text(getConvertedDateDiscount(numberDays));
    }

    const manageDueDate = (classDate, classDelay) => {
        const day = $(classDate).val().split('/')[0];
        const month = $(classDate).val().split('/')[1] - 1;
        const year = $(classDate).val().split('/')[2];
        const datePaymentEdited = new Date(year, month, day);
        let addingDays = $(classDelay).val() * 86400000;
        let newDatePaymentEdited = new Date(datePaymentEdited.getTime() + addingDays);
        $('.js-payment-mail-date').text(`${("0"+newDatePaymentEdited.getDate()).slice(-2)}/${("0"+(newDatePaymentEdited.getMonth()+1)).slice(-2)}/${newDatePaymentEdited.getFullYear()}`);
    }

    // event click on show more and reduce
    function showMoreAndReduce(targetClick, showingElement, hidingElement) {
        $(targetClick).on("click", () => {
            $(showingElement).show();
            $(hidingElement).hide();
        });
    }

    showMoreAndReduce(".js-lessInfoEscompte", ".js-blocMoreInfoEscompte", ".js-contentMoreInfoEscompte");
    showMoreAndReduce(".js-moreInfoEscompte", ".js-contentMoreInfoEscompte", ".js-blocMoreInfoEscompte");
    showMoreAndReduce(".js-lessInfo3x", ".js-blocMoreInfo3x", ".js-contentMoreInfo3x");
    showMoreAndReduce(".js-moreInfo3x", ".js-contentMoreInfo3x", ".js-blocMoreInfo3x");

    // Event click to simulate select click
    $(".js-selectEmoticone").on("click", (e) => {
        $(".js-escompteSelectChoice").toggle();
        e.stopPropagation();
        $(window).on("click", function() {
            $(".js-escompteSelectChoice").hide();
        });
    });

    $(".js-selectDateIcon").on("click", (e) => {
        $(".js-escompteSelectDateChoice").toggle();
        e.stopPropagation();
        $(window).on("click", function() {
            $(".js-escompteSelectDateChoice").hide();
        });
    });

    // Manage custom Select
    function filledChoicesCustomSelect(choice, selected, defaultValue, blocChoices, typeSelect, custom = false) {
        $(choice).on("click", () => {
            if (custom) {
                $(defaultValue).hide();
                $(selected).css("display", "flex");
            }
            else {
                $(defaultValue).html($(selected).html());
            }
            $(blocChoices).hide();
            let getEscompteNumber;
            let calculDiscount;
            if (typeSelect === "number") {
                if (custom) {
                    getEscompteNumber = $(".js-getNumberEscompteInput").val();
                    calculDiscount = (paymentValue * getEscompteNumber) / 100;
                    $(".st-blocEscompteInput").addClass("customActive");
                    $(".js-getNumberEscompteInput").trigger("focus");
                }
                else { 
                    getEscompteNumber = $(".js-defaultEscompteChoice .js-valueEscompte").text();
                    calculDiscount = (paymentValue * extractFirstNumberOfString(getEscompteNumber)) / 100;
                }
                if (calculDiscount > 0) {
                    $(".js-monthyPaymentPrice1").text(numberWithSpacesWithComa(firstPaymentValue) + " €").show();
                    $(".js-monthyPaymentWithEscompte, .blocBillWithEscompte").show();
                }
                else {
                    $(".js-monthyPaymentWithEscompte, .js-monthyPaymentPrice1").hide();
                }

                // Update mail discount info
                $(".js-discountRateMail").text(getEscompteNumber.toString().replace("-", ""));
                $(".billWithEscompte, .js-escompteTotalMail").text(numberWithSpacesWithComa((paymentValue - calculDiscount)) + " €");
                $(".js-escomptePriceMail").text(numberWithSpacesWithComa(calculDiscount) + " €");

                $(".js-monthyPaymentPrice1Escompte").text(numberWithSpacesWithComa((firstPaymentValue - calculDiscount)) + " €");
                $(".blocBillWithEscompte").show();

                validFrontButton(custom, ".st-blocEscompteInputDate", ".js-getDateEscompteInput", ".js-defaultEscompteDateChoice");
            }
            else if (typeSelect === "date") {
                let numberDays;
                if (custom) {
                    numberDays = $(".js-getDateEscompteInput").val();
                    $(".st-blocEscompteInputDate").addClass("customActive");
                    $(".js-getDateEscompteInput").trigger("focus");
                }
                else {
                    numberDays = $(".js-defaultEscompteDateChoice .js-valueEscompteDate").text()[0];
                }
                showingDateDiscount(numberDays);

                // Update mail discount info
                $(".js-escompteDaysMail").text(numberDays);
                $(".js-discountDueDate").text(getConvertedDateDiscount(numberDays))

                validFrontButton(custom, ".st-blocEscompteInput", ".js-getNumberEscompteInput", ".js-defaultEscompteChoice");
            }
        });
    }

    filledChoicesCustomSelect(".js-escompte1", "#js-escompte1", ".js-defaultEscompteChoice", ".js-escompteSelectChoice", "number");
    filledChoicesCustomSelect(".js-escompte2", "#js-escompte2", ".js-defaultEscompteChoice", ".js-escompteSelectChoice", "number");
    filledChoicesCustomSelect(".js-escompte3", "#js-escompte3", ".js-defaultEscompteChoice", ".js-escompteSelectChoice", "number");
    filledChoicesCustomSelect(".js-chooseMyDiscount", ".st-blocEscompteInput", "#escompteSelect", ".js-escompteSelectChoice", "number", true);
    filledChoicesCustomSelect(".js-escompteDate1", "#js-escompteDate1", ".js-defaultEscompteDateChoice", ".js-escompteSelectDateChoice", "date");
    filledChoicesCustomSelect(".js-escompteDate2", "#js-escompteDate2", ".js-defaultEscompteDateChoice", ".js-escompteSelectDateChoice", "date");
    filledChoicesCustomSelect(".js-escompteDate3", "#js-escompteDate3", ".js-defaultEscompteDateChoice", ".js-escompteSelectDateChoice", "date");
    filledChoicesCustomSelect(".js-chooseMyDiscountDate", ".st-blocEscompteInputDate", "#escompteSelectDate", ".js-escompteSelectDateChoice", "date", true);

    const validFrontButton = (custom, blocInput, input, selectValue) => {
        if (!custom) {
            if ($(blocInput).hasClass("customActive")) {
                if ($(input).val() === "") {
                    $("#js-stepActionNext3").addClass("st-no-btn-disabled");
                }
                else {
                    $("#js-stepActionNext3").removeClass("st-no-btn-disabled");
                }
            }
            else {
                if ($(selectValue).text() === translation("Sélectionnez")) {
                    $("#js-stepActionNext3").addClass("st-no-btn-disabled");
                }
                else { 
                    $("#js-stepActionNext3").removeClass("st-no-btn-disabled");
                }
            }
        }
        else {
            $("#js-stepActionNext3").addClass("st-no-btn-disabled");
        }
    }

    // Manage custom escompte with input
    $(".js-getNumberEscompteInput").on("input", function() {
        let getValueEscompte;
        let calculDiscount;
        let valueInput = $(this).val();
        let valueInputClean = valueInput.replace(".", "").replace(",", "");
        $(this).val(valueInputClean);
        if (isNaN(Number(valueInputClean)) || Number(valueInputClean) === 0) {
            getValueEscompte = 0;
            $(this).val("");
            $("#js-stepActionNext3").addClass("st-no-btn-disabled");
        }
        else if (Number(valueInputClean) > 30) {
            $(this).val(30);
            getValueEscompte = 30;
            if ($(".st-blocEscompteInputDate").hasClass("customActive")) {
                if (Number($(".js-getDateEscompteInput").val()) > 0) {
                    $("#js-stepActionNext3").removeClass("st-no-btn-disabled");
                }
            }
            else {
                if($(".js-defaultEscompteDateChoice").text() !== translation("Sélectionnez")) {
                    $("#js-stepActionNext3").removeClass("st-no-btn-disabled");
                }
            }
        }
        else {
            getValueEscompte = Number(valueInputClean);
            if ($(".st-blocEscompteInputDate").hasClass("customActive")) {
                if (Number($(".js-getDateEscompteInput").val()) > 0) {
                    $("#js-stepActionNext3").removeClass("st-no-btn-disabled");
                }
            }
            else {
                if($(".js-defaultEscompteDateChoice").text() !== translation("Sélectionnez")) {
                    $("#js-stepActionNext3").removeClass("st-no-btn-disabled");
                }
            }
        }
        calculDiscount = (paymentValue * getValueEscompte) / 100;
        $(".js-getPriceEscompteInput").text(numberWithSpacesWithComa(calculDiscount) + " €");

        // Update mail discount info
        $(".js-escomptePriceMail").text(numberWithSpacesWithComa(calculDiscount) + " €");

        $(".js-monthyPaymentPrice1Escompte").text(numberWithSpacesWithComa((firstPaymentValue - calculDiscount)) + " €");
        $(".billWithEscompte, .js-escompteTotalMail").text(numberWithSpacesWithComa((paymentValue - calculDiscount)) + " €");

        if (getValueEscompte > 0) {
            $(".js-monthyPaymentPrice1").text(numberWithSpacesWithComa(firstPaymentValue) + " €").show();
            $(".js-monthyPaymentWithEscompte, .blocBillWithEscompte").show();
        }
        else {
            $(".js-monthyPaymentWithEscompte, .js-monthyPaymentPrice1").hide();
        }
    });

    // Manage custom escompte Date with input
    $(".js-getDateEscompteInput").on("input", function() {
        let getValueDays;
        let valueInput = $(this).val();
        let valueInputClean = valueInput.replace(".", "").replace(",", "");
        $(this).val(valueInputClean);
        if (isNaN(Number(valueInputClean)) || Number(valueInputClean) === 0) {
            getValueDays = 0;
            $(this).val("");
            $("#js-stepActionNext3").addClass("st-no-btn-disabled");
            $(".st-blocEscompteInputDate p").text(translation("Jour"));
        }
        else if (Number(valueInputClean) > Number(getDaysDelay)) {
            $(this).val(getDaysDelay);
            getValueDays = Number(getDaysDelay);
            if ($(".st-blocEscompteInput").hasClass("customActive")) {
                if (Number($(".js-getNumberEscompteInput").val()) > 0) {
                    $("#js-stepActionNext3").removeClass("st-no-btn-disabled");
                }
            }
            else {
                if($(".js-defaultEscompteChoice").text() !== translation("Sélectionnez")) {
                    $("#js-stepActionNext3").removeClass("st-no-btn-disabled");
                }
            }
        }
        else {
            getValueDays = Number(valueInputClean);
            if (getValueDays > 1) {
                $(".st-blocEscompteInputDate p").text(translation("Jours"));
            }
            else {
                $(".st-blocEscompteInputDate p").text(translation("Jour"));
            }
            
            if ($(".st-blocEscompteInput").hasClass("customActive")) {
                if (Number($(".js-getNumberEscompteInput").val()) > 0) {
                    $("#js-stepActionNext3").removeClass("st-no-btn-disabled");
                }
            }
            else {
                if($(".js-defaultEscompteChoice").text() !== translation("Sélectionnez")) {
                    $("#js-stepActionNext3").removeClass("st-no-btn-disabled");
                }
            }
        }
        let timeOfDays = getValueDays * 86400000;
        let validBillFormat = getValidFormatDate(getBillDate, "/");
        let validBillDate = new Date (validBillFormat).getTime();
        let newTimeWithDays = validBillDate + timeOfDays;
        $(".billWithEscompteDate").text(dateConversion(newTimeWithDays));
        $(".js-escompteDaysMail").text(getValueDays);
    });

    $(".st-blocEscompteInput").on("click", function() {
        $(".js-getNumberEscompteInput").trigger("focus");
    });

    // Manage custom email content according to selected options. 

    function updateMailTextarea() {
        let isDiscountChecked = $(".checkedEscompte").is(":checked")
        let is3xChecked = $(".checked3x").is(":checked")
        let textAreaMail = $('.mail-msg .trumbowyg-editor')

        let getBillNumber = $(".my-bills-number").val()
        let getCurrentUser = $(".email-wrapper").first().data("user")
        
        $('input.js-payment-mail-object').val(translation("Facture n°") + getBillNumber + " - " + getCurrentUser + ' ' + translation('vous a envoyé une demande de paiement'))
        $('.js-billNumber').each(function() {
            $(this).text(getBillNumber);
        })
        
        // Default
        if(!isDiscountChecked && !is3xChecked) {
            textAreaMail.html($('.js-mailCustomDefault').html())
        }
        // 3x + Discount
        else if(isDiscountChecked && is3xChecked) {
            textAreaMail.html($('.js-mailCustomDiscount3x').html())
        }
        // Discount
        else if(isDiscountChecked) {
            textAreaMail.html($('.js-mailCustomDiscount').html())
        }
        // 3x
        else {
            textAreaMail.html($('.js-mailCustom3x').html())
        }
    }

    $('#js-stepActionNext3').on('click', function() {
        if(!$(this).hasClass('st-no-btn-disabled')) {
            updateMailTextarea()
        }

    })

    // Manage checked click 

    $(".checkedEscompte").prop("checked", true);
    $(".checkedEscompte").attr("checked", true);

    $(".checkedEscompteLabel").on("click", function() {
        if ($(".checkedEscompte").is(":checked")) {
            $(".checkedEscompte").prop("checked", false);
            $(".checkedEscompte").attr("checked", false);
            $("#js-stepActionNext3").removeClass("st-no-btn-disabled");
            $(".st-blocSelectEscompte, .js-monthyPaymentPrice1, .js-monthyPaymentWithEscompte, .blocBillWithEscompte, .blocBillWithEscompteDate").hide();
            $(".js-monthyPaymentPrice1Escompte").text(numberWithSpacesWithComa(firstPaymentValue) + " €");
            $(".js-defaultEscompteChoice, .js-defaultEscompteDateChoice").html("<strong>" + translation("Sélectionnez") + "</strong>");
            $(".st-blocEscompteInput, .st-blocEscompteInputDate").hide();
            $("#escompteSelect, #escompteSelectDate").show();
            $(".st-blocEscompteInput, .st-blocEscompteInputDate").removeClass("customActive");
            $(".js-getNumberEscompteInput, .js-getDateEscompteInput").val("");
            $(".js-getPriceEscompteInput").text("0,00 €");
            $(".js-blocEscompteMail").hide();
        }
        else {
            $(".checkedEscompte").prop("checked", true);
            $(".checkedEscompte").attr("checked", true);
            $(".st-blocSelectEscompte").show();
            $("#js-stepActionNext3").addClass("st-no-btn-disabled");
            $(".js-blocEscompteMail").show();
        }
        $(".checkedEscompte").trigger("click");
    });

    $(".checked3xLabel").on("click", function() {
        if ($(".checked3x").is(":checked")) {
            $(".st-blocMonthlyPayment").hide();
            $(".checked3x").prop("checked", false);
            $(".checked3x").attr("checked", false);
            $(".js-bloc3xMail").hide();
        }
        else {
            $(".st-blocMonthlyPayment").show();
            $(".checked3x").prop("checked", true);
            $(".checked3x").attr("checked", true);
            $(".js-bloc3xMail").show();
        }
        $(".checked3x").trigger("click");
    });

    $(document).on('click', '#payment_sendlater', function() {
        $('.if-payment-later').slideDown();
    });

    $(document).on('click', '#payment_sendnow', function() {
        $('.if-payment-later').slideUp();
    });

    $('#js-mailCustomInput').attr('checked', true)
    $('#js-mailCustomInput').prop('checked', true)
    $(document).on('click', '.js-mailCustomLabel', function () {
        if ($('#js-mailCustomInput').is(':checked')) {
            $('.asking-payment-mail').hide()
            $('.js-send-payment-facture').removeClass('js-stepActionNext')
        }
        else {
            $('#js-mailCustomInput').attr('checked', true)
            $('#js-mailCustomInput').attr('checked', true)
            $('.js-send-payment-facture').addClass('js-stepActionNext')
            $('.asking-payment-mail').show()
        }
    });
};