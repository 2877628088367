// KYC Form : @Route("freelance/mon_profil/portefeuille_en_ligne/kyc")
$('.js-kyc-form-btn').on("click", function(e) {
	$(e.target).addClass('st-no-btn-disabled');
    let formData = new FormData(); 

    let articles = document.querySelector('#kyc-form-statuts');
    if (articles && articles.files[0]) {
        formData.append("articles", articles.files[0]);
    }

    let identity = document.querySelector('#kyc-form-identity');
    if (identity && identity.files[0]) {
        formData.append("identity", identity.files[0]);
    }

    let register = document.querySelector('#kyc-form-kbis');
    if (register && register.files[0]) {
        formData.append("register", register.files[0]);
    }

    let submitMethod = 'POST';
    $.ajax({
        url: "/freelance/mon_profil/entreprise/kyc/form",
        data: formData,
        processData: false,
        contentType: false,
        type: submitMethod,
        success: function(val){
            let v = JSON.parse(val);
            if (v.status === 'success') {
                window.location.reload();
            } else if (v.status === 'failed') {
				$(e.target).removeClass('st-no-btn-disabled');
                $('#js-kycErrors').html(v.message);
                $('.alert-success').hide();
                $('#js-kycErrors').show();
                $(".soancontainer").animate(
                    {
                        scrollTop: $("#js-kycErrors").offset().top
                    }, 300
                );
            }
        },
        error: function(err) {
            window.location.reload();
        }
    });
})

$('.js-kycChooseFileInput').on('input', (e) => {
    if (e.target.files[0].size && e.target.files[0].size > 7340032) {
        $(e.target).val(null);
        $(e.target).closest('.js-kycFileLoadedArea').find('.js-kycFileLoadedName').text('');
        $('.errors').text("Les fichiers téléchargés ne doivent pas dépasser 7M0").show();

    } else if (e.target.files[0].size) {
        let filename = e.target.files[0].name;
        if (filename.length > 20) {
            filename = filename.substring(0, 17) + "..."
        } 
        $(e.target).closest('.js-kycFileLoadedArea').find('.js-kycFileLoadedName').text(filename);
        $('.errors').text('').hide();
    }
})