import translation_fr from "../newScripts/translations/translation_fr";
import translation_es from "../newScripts/translations/translation_es";
import translation_de from "../newScripts/translations/translation_de";

export let srcLang = $("body").attr("data-currentlang");
export let currentPage = $("body").attr("data-currentpage");

// Function to translate dynamic text with locale 
export function translation(key) {
    if (srcLang === "fr" && translation_fr[key] !== undefined) {
        return translation_fr[key];
    }
    else if (srcLang === "es" && translation_es[key] !== undefined) {
        if (translation_es[key] === "") {
            return translation_fr[key];
        }
        else {
            return translation_es[key];
        }
    }
    else if (srcLang === "de" && translation_de[key] !== undefined) {
        if (translation_de[key] === "") {
            return translation_fr[key];
        }
        else {
            return translation_de[key];
        }
    }
    else {
        if (translation_fr[key] !== undefined) {
            return translation_fr[key];
        }
        else {
            return "";
        }
    }
}

// FUNCTION TO FORMAT DATE FROM "01/01/2020" to "01-01-2020"
export const formatDateSeparators = (dateStr) => {
    if (/^[0-9]{2}[\/]{1}[0-9]{2}[\/]{1}[0-9]{4}$/.test(dateStr)) {
        let dateSplitted = dateStr.split("/");
        return `${dateSplitted[0]}-${dateSplitted[1]}-${dateSplitted[2]}`;
    } else {
        console.error("function formatDateSeparators use the wrong way");
        return dateStr;
    }
}

// FUNCTION THAT MANAGE ERRORS (add red outline and error message on top) AFTER SUBMITTING A FORM 
// ENTRY HAS TO BE ARRAY OF STRINGS (string is a class. for example : ".classname")
export function handleErrorsAfterSubmit(arrayOfClasses) {
  arrayOfClasses.forEach(function(singleClass) {
    $(singleClass).on('input', function() {
      if (this.value === "") {
        $(this).addClass('error'); 
      } else {
        $(this).removeClass('error');
      }
      if (isAllFilled(arrayOfClasses)) {
        $('.popin-step-error').hide();
      } else {
        $('.popin-step-error').show();
      }
    })  
  })
}

export function stringToDate(date){

  let elem = date.split('/');
  let jour = elem[0];
  let mois = elem[1];
  let annee = elem[2];

  let dateFormated =  annee+"-"+mois+"-"+jour;
  return dateFormated;

}


// ENTRY DATESTRING HAS TO BE "22-09-1991" OR "22/09/1991"
export function isAdult(dateString) {
    const dateStr = dateString.replace(/-/g, '/');
    const dayNumber = parseInt(dateStr.split("/")[0]);
    const monthNumber = parseInt(dateStr.split("/")[1]);
    const yearNumber = parseInt(dateStr.split("/")[2]);
    const today = new Date();
    let age = today.getFullYear() - yearNumber;
    if ((today.getMonth() + 1) < monthNumber || ((today.getMonth() + 1) === monthNumber && today.getDate() < dayNumber)) {
        age--;
    }
    if (age > 17) {
        return true;
    } else if (age < 18) {
        return false;
    } else {
        console.error("FUNCTION ISADULT() USED THE WRONG WAY");
    }
}

// Hide popin
export const hidePopin = () => {
    $(".loader-popin").fadeOut(1000);
    $('.popin').css('overflow', 'auto');
    $(".backdrop").hide();
    $(".backdrop-fix").hide();
    $(".popin-wrap").hide();
    $(".popin").hide();
}

// TOGGLE LATERAL MENU ON ACCOUNT PAGES
export const toggleLateralMenu = () => {
    if ($(".vertical-menu").hasClass('mobileshow')) {
        $(".vertical-menu").removeClass('mobileshow');
        $(".soancontainer.bck1.padded").show();
    }
    else {
        $(".vertical-menu").addClass('mobileshow');
        $(".soancontainer.bck1.padded").hide();
    }
}

function isAllFilled(arrayOfClasses) {
    const arrayOfValues = [];
    arrayOfClasses.forEach(function(singleClass) { 
        arrayOfValues.push($(singleClass).val()); 
    })
    if (arrayOfValues.includes("")) {
        return false;
    } else {
        return true;
    }
}

export function dateConversion(date) {
	let newDate;
	if (date === null) {
		newDate = new Date();
	}
	else if (typeof date === "number") {
		newDate = new Date(date);
	}
	else{
		newDate = new Date(date.toString().replace(" ", "T"));
	}
	let y = newDate.getFullYear();
	let m = newDate.getMonth() + 1;
	let d = newDate.getDate();

	if (m < 10) {
		m = "0" + m;
	}
	if (d < 10) {
		d = "0" + d;
	}
	return d + "/" + m + "/" + y;
}

export function getValidFormatDate(date, splitter) {
    if (date === null) {
        return null;
    } else {
        let arrayDate = date.split(splitter);
        let year = arrayDate[2];
        let month = arrayDate[1];
        let days = arrayDate[0];
        return year + "-" + month + "-" + days;
    }
}

// Difference between two dates (today - date)
export function differenceDate(date) {
    let today = new Date();
    let todayTime = today.getTime();
    let dueDate = new Date(date);
    let dueDateTime = dueDate.getTime();
    return todayTime - dueDateTime;
}

// Calcul number days of a date
export function numberDaysDelate(date, positif = false) {
    let result = differenceDate(date);
    if (positif) {
        return Math.abs(Math.floor(result / 86400000));
    }
    else {
        return Math.floor(result / 86400000);
    }
}

// convert Price number to showing in string
export function convertPrice(priceData) {
	let price = Number.parseFloat(priceData/100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	let priceStr = "" + price;
	return priceStr.replace(".", ",") + " €";
}

export function convertStringToNumber(string) {
    return parseInt(string.replace(" ", "").replace(",", ""));
};

// get Iban and set Iban in container
export function getAndSetIban(userId, container, amount) {
    $('.js-loaderGif').fadeIn(200);
    let promiseReturn = $.post("/getDefaultIban", {userId: userId});
    promiseReturn.then((val) => {
        $('.js-loaderGif').fadeOut(200);
        $(".st-contentOneClicPayment").show();  
        let v = JSON.parse(val);
        if (v.status === 'success') {
            if (v.iban) {
                $(".one-clic-payment .js-checkMarkWaySelected").attr("src", "/build/images/check_mark.svg").show();
                $(".one-clic-payment .js-amountFinalPayment").show().removeClass("csecondary-3");
                $(".one-clic-payment").addClass("js-wayselected");
                $(container).text(v.iban);
                $(".st-errorMsgWarningVirement").hide();
            }
        }
        else {
            $(".one-clic-payment .js-amountFinalPayment").show().addClass("csecondary-3");
            $(".one-clic-payment .js-checkMarkWaySelected").attr("src", "/build/images/lock.svg");
            $(".bank-list .js-checkMarkWaySelected").attr("src", "/build/images/check_mark.svg");
            $(".one-clic-payment").removeClass("js-wayselected").css("cursor", "initial");
            $(".st-errorMsgWarningVirement").show();
            $(".st-msgCarbone").hide();
            $(".bank-list .js-wayselected:nth-child(1)").addClass("selected");
            $(".bank-list .js-wayselected:nth-child(1) .js-checkMarkWaySelected").removeClass("d-none");
            if (amount > 2000000 || $(".bank-list div").length < 1) {
                $(".payment-delayed-content").hide();
                $(".st-errorMsgBanking").show();
                $(".js-disabledBtn").css({"background-color": "#AAABAF", "color": "white", "cursor": "initial"}).removeClass("js-payment-bank btn-primary");
            }
            else {
                $(".payment-delayed-content").show();
                $(".st-errorMsgBanking").hide();
                $(".js-disabledBtn").addClass("js-payment-bank btn-primary");
            }
        }
    });
}

// Get number of a string
export function extractFirstNumberOfString(getEscompteNumber) {
    return getEscompteNumber.match(/(\d+)/)[0];
}

// ADD SPACE EVERY THREE NUMBERS
export function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function numberWithSpacesWithComa(x, decimal = 2) {
    let addingDecimal = Number(x).toFixed(decimal);
    return addingDecimal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".", ",");
}

// CHECK VALID PASSWORD ON LOGIN PAGE
export function checkvalidpassword() {
    let pwdConfirm = $("[id$='registration_plainPassword_second']").val();
    let pwd = $("[id$='registration_plainPassword_first']").val();
    if (pwd) {
        if (pwdConfirm === pwd) {
        if ($('.pwdok-coche').length <= 0)
            $(".plainpassword .form-group").append("<div class='pwdok pwdok-coche'><img src='/build/images/coche.svg' height='20' width='20' /></div>");
        if ($('.pwdok-cross').length > 0)
            $('.pwdok-cross').remove();
        $('.plainpassword .invalid-feedback').remove();
        $('.plainpassword input').removeClass('is-invalid');
        $(".js-validate-confirm").removeClass("border-red");
        } else {
        if ($('.pwdok-cross').length <= 0)
            $(".plainpassword .form-group").append("<div class='pwdok pwdok-cross'><img src='/build/images/closered.svg' height='15' width='15' /></div>");
        if ($('.pwdok-coche').length > 0)
            $('.pwdok-coche').remove();
        $(".js-validate-confirm").addClass("border-red");
        }
    } else {
        $('.pwdok-coche').remove();
        $(".js-validate-confirm").removeClass("border-red");
        $('.pwdok-cross').remove();
    }
};


export function checkvalidpasswordDynamically() {
    $(document).on('input', 'input[type="password"].js-validate', function() {
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        var value = $(this).val();
          //hasDigit
          if (!(/[0-9]/).test(value)) {
            $('.password-check .nbr').removeClass('cgreenvalidate');
            $('.password-check .nbr img').addClass('d-none');
          } else {
            $('.password-check .nbr').addClass('cgreenvalidate');
            $('.password-check .nbr img').removeClass('d-none');
          }
          //hasLower
          if (!(/[a-z]/).test(value)) {
            $('.password-check .min').removeClass('cgreenvalidate');
            $('.password-check .min img').addClass('d-none');
          } else {
            $('.password-check .min').addClass('cgreenvalidate');
            $('.password-check .min img').removeClass('d-none');
          }
          //hasUpper
          if (!(/[A-Z]/).test(value)) {
            $('.password-check .maj').removeClass('cgreenvalidate');
            $('.password-check .maj img').addClass('d-none');
          } else {
            $('.password-check .maj').addClass('cgreenvalidate');
            $('.password-check .maj img').removeClass('d-none');
          }
          //length limit
          if (value.length < 8) {
            $('.password-check .nbrletter').removeClass('cgreenvalidate');
            $('.password-check .nbrletter img').addClass('d-none');
          } else {
            $('.password-check .nbrletter').addClass('cgreenvalidate');
            $('.password-check .nbrletter img').removeClass('d-none');
          }
    
          checkvalidpassword();
    
      });
}

export function divideNumberByThree(paymentValue, price1, price2, price3) {
    let firstPaymentValue;
    let divider = 3;
    let resultDivider = (paymentValue / divider) * 100;
    let roundResult = Number(resultDivider.toFixed(2));
    let getFirstDecimal = resultDivider.toFixed(1).split(".")[1];
    if ((paymentValue * 100) % divider === 0) {
        firstPaymentValue = roundResult / 100;
        $(price1).text(numberWithSpacesWithComa(firstPaymentValue) + " €");
        $(price2).text(numberWithSpacesWithComa(firstPaymentValue) + " €");
        $(price3).text(numberWithSpacesWithComa(firstPaymentValue) + " €");
    }
    else if (getFirstDecimal < 5) {
        firstPaymentValue = (roundResult / 100) + 0.01;
        $(price1).text(numberWithSpacesWithComa(roundResult / 100) + " €");
        $(price2).text(numberWithSpacesWithComa(roundResult / 100) + " €");
        $(price3).text(numberWithSpacesWithComa(firstPaymentValue) + " €");
    }
    else {
        firstPaymentValue = (roundResult / 100) - 0.01;
        $(price1).text(numberWithSpacesWithComa(firstPaymentValue) + " €");
        $(price2).text(numberWithSpacesWithComa(roundResult / 100) + " €");
        $(price3).text(numberWithSpacesWithComa(roundResult / 100) + " €");
    }
}

export function getEncryptedEmail(email) {
  const splited = email.split('@')

  const name = splited[0];
  const domain = splited[1];

  const domainBegining = domain.substring(0,2);
  const domainMiddle = domain.substring(2, domain.length -2);
  const domainEnd = domain.substring(domain.length -2)

  const begining = name.substring(0,2);
  const end = name.substring(2, name.length);
  let crypted = '';
  let domainCrypted = "";

  for (let i=0; i < end.length; i++){
    crypted = crypted + '*';
  }

  for (let i= 0; i < domainMiddle.length; i++) {
    domainCrypted = domainCrypted + '*';
  }

  let completeEmail = begining + crypted + '@' + domainBegining + domainCrypted + domainEnd;
  return completeEmail;
}

export const openPopin = (popinName) => {
    $('.popin, .backdrop').show();
    $(".popin-wrap").hide();
    $(popinName).show();
}

export const closePopin = () => {
    $(".backdrop").hide();
    $(".backdrop-fix").hide();
    $(".backdropstepstart").hide();
    $(".popin").hide();
    $(".popin-wrap").hide();
    $(".popin-errors").hide();
    $(".popin-error").remove();
    $(".errorMsgOffline").hide();
    $(".st-blocPopinError").removeClass("d-flex").addClass("d-none");
}

export const scrollBottomElem = (element) => {
    $(element).scrollTop($(element)[0].scrollHeight);
}

export const hideSelectContentWithOutsideClic = (event, selectContent) => {
    event.stopPropagation();
    $(window).on("click", function() {
        $(selectContent).hide();
    });
}

export function delay(callback, ms) {
    var timer = 0;
    return function () {
        var context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
        callback.apply(context, args);
        }, ms || 0);
    };
}

export function scrollHeightToValue(value) {
    let actualHeight = document.documentElement.scrollTop;
    window.scrollTo(0, (actualHeight - value));
}

export function createThumbnailFromPDF(pdf) {
    if (pdf) {
        let arrayPdf = pdf.split("/");
        let getFileName = arrayPdf.at(-1);
        if (getFileName !== pdf) {
            let previewName = "/preview_" + getFileName.replace("pdf", "png"); 
            arrayPdf.pop();
            return arrayPdf.join("/") + previewName;
        }
    }
    return "/build/images/icon_pdf-default.svg";
}

export function getRealHeightElement(className) {
    let height = $(className).height();
    let marginTop = Number($(className).css("margin-top").replace("px", ""));
    let marginBottom = Number($(className).css("margin-bottom").replace("px", ""));
    let paddingTop = Number($(className).css("padding-top").replace("px", ""));
    let paddingBottom = Number($(className).css("padding-bottom").replace("px", ""));
    let realHeight = height + marginTop + marginBottom + paddingTop + paddingBottom;
    return realHeight;
}

export function getPrefixMonth(monthNumber) {
    if (monthNumber) {
        switch (monthNumber) {
            case "01":
                return translation("jan");
            case "02":
                return translation("feb");
            case "03":
                return translation("mar");
            case "04":
                return translation("avr");
            case "05":
                return translation("mai");
            case "06":
                return translation("jui");
            case "07":
                return translation("juil");
            case "08":
                return translation("aou");
            case "09":
                return translation("sep");
            case "10":
                return translation("oct");
            case "11":
                return translation("nov");
            case "12":
                return translation("dec");
        }
    }
}

//Get all the "get params" in url
export function getUrlParams(url) {
  let vars = {};
  url.replace( location.hash, '' ).replace(/[?&]+([^=&]+)=?([^&]*)?/gi,
    function( m, key, value ) {
      vars[key] = value;
    });
  return vars;
}

//Return the type of the CB
export function getCardBrand(cardNumber) {
  let rules = [
    {
      "type": "CB_VISA_MASTERCARD",
      "regex": /^4[0-9]{6,}$/
    },
    {
      "type": "CB_VISA_MASTERCARD",
      "regex": /^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/,
    },
    {
      "type": "AMEX",
      "regex": /^3[47][0-9]{5,}$/
    }
  ];

  //CB_VISA_MASTERCARD is the default type
  let type = "CB_VISA_MASTERCARD";

  rules.forEach(function (elm) {
    if (elm["regex"].test(cardNumber)) {
      type = elm["type"];
      return type;
    }
  });

  return type;
}

$('.js-disabled-on-click').on('click', (e) => {
    $(e.target).addClass('st-no-btn-disabled');
});

$(".js-deleteFlashMsg").on("click", function() {
    $(this).remove();
});

const showFlashMessages = () => {
    let messages = JSON.parse(sessionStorage.getItem("messages"));

    if (messages) {
        messages.forEach((message, index) => {
            if (messages[messages.length -1].text === message.text) {
                $(".msg-flash-react").hide();
                sessionStorage.removeItem("messages");
            }

            let iconInfo, titleInfo;
            if (message.type === "information") {
                iconInfo = "icon_info-black.svg";
                titleInfo = "Information";
            }
            else if (message.type === "warning") {
                iconInfo = "icon_info-warning.svg";
                titleInfo = "Attention";
            }
            else if (message.type === "error") {
                iconInfo = "icon_info-error.svg";
                titleInfo = "Erreur";
            }
            else {
                iconInfo = "icon_green-check.svg";
                titleInfo = "Succès";
            }

            $(".msg-flash-react").append(`
                <div id="flash-${index}" class="${message.type}">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex">
                            <img class='mr10 ml-10' src="/build/images/icons/${iconInfo}" alt="icone information type message" width="20px" height="20px"/>
                            <h3 class='fs18 fw600'>${titleInfo}</h3>
                        </div>
                        <img class='cpointer' src="/build/images/icons/icon_cross_darkGreen.svg" alt="icone suppression" width="20px" height="20px"/>
                    </div>
                    <p class='fs14 mb0 csecondary-3 ml20'>${message.text}</p>
                </div>
            `).show();
            $("#flash-"+ index).on("click", function() {
                $(this).remove();
            });
            setTimeout(() =>{ 
                $(".msg-flash-react div").first("").remove();
            }, 3000 + (50 * message.text.length));
        });
    }
}

showFlashMessages();


