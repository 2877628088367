import { formatDateSeparators, translation } from '../generalFunctions';
$('.generate-invoice').on('click', function() {
  $('.js-error-message').html('')
  let date = formatDateSeparators($('#date').val());
  let amount = $('#amount').val();
  let offerId = $('#offerId').val();
  let country = $('#country').val();
  let city = $('#city').val();
  let postalCode = $('#postalCode').val();
  let address = $('#address').val();
  let businessName = $('#businessName').val();
  let firstname = $('#firstname').val();
  let lastname = $('#lastname').val();
  let invoiceNumber = $('#invoiceNumber').val();
  let subTag = $('#subTag').val();
  let promiseReturn = $.post("/freelance/admin/invoice_generate_form", {amount: amount, offerId: offerId, country:country, city: city, postalCode: postalCode, address: address, businessName: businessName, firstname: firstname, lastname: lastname, invoiceNumber:invoiceNumber, subTag: subTag, date: date});
  promiseReturn.then((val) => { 
      let v = JSON.parse(val);
      if (v.status === 'success') {
          if (v.filename) {
            let invoice = $("#invoice");
            invoice.attr("href", "");
            let href = invoice.attr("data-href");
            invoice.attr("href", href + "/" + v.filename);
            $('#filename').html(v.filename);
            $('#result').html(translation("Facture générée"));
          }
      }
      else {
          if (v.message) {
            $(".js-error-message").html(v.message);
          }
      }
  });
});

function capitalizeFirstLetter(string) {
  return string.toString().charAt(0).toUpperCase() + string.slice(1);
}

$('.download-all-from-date').on('click', function() {
    let date = formatDateSeparators($('#dateStart').val());
    window.location.href = "/freelance/admin/generate_zip?date="+date;
});
