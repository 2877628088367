import ApexCharts from 'apexcharts';

$(document).on('click', '.js-get-stats', function () {
  $('.error-promo').addClass('d-none').html('');
  let promiseReturn = $.post('/freelance/admin/marketing/stats', {"offerTag": "LIBERTY", "startDate": $('#start_date').val(), "endDate": $('#end_date').val()});
  promiseReturn.then(function (val) {
    let v = JSON.parse(val);
    if (v.status === "success") {
      let dataArray = [];
      let categoryArray = [];
      Object.entries(v.datas).forEach(([key, value]) => {
        dataArray.push(value);
        categoryArray.push(key);
      });
      var options = {
        series: [{
        data: dataArray//apexcharts-tooltip
      }],
        chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: categoryArray,
      }
      };

      var chart = new ApexCharts(document.querySelector("#chart"), options);
      chart.render();
    }
    else {
      let error = v.message;
      $('.error-promo').removeClass('d-none').html(error);
    }
  })
})
