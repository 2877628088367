import { translation } from '../../generalFunctions'

let globalObject = {};
const months = ['#january', '#february', '#march', '#april', '#may', '#june', '#july', '#august', '#september', '#october', '#november', '#december'];
let currentYear = parseInt($('.js-currentYear').text());

$('.js-openPopinDefineGoal').on('click', () => {
    $('.js-add-goal-btn').removeClass("st-no-btn-disabled");
    $(".loader-popin").fadeIn(200);
    let promiseReturn = $.get("/freelance/find_goals");
    promiseReturn.then(function(val) {
        $(".loader-popin").fadeOut(200);
        let v = JSON.parse(val);
        if (v.status === 'success') {
            $(".st-home-dashboard-container").animate({ scrollTop: 0 }, 300);
            const years = [];
            v.goals.forEach((goal) => {
                if (!years.includes(goal.date.split('-')[0])) {
                    years.push(goal.date.split('-')[0]);
                }
            })
            years.forEach((year) => {
               let object = {};
                v.goals.forEach((goal) => {
                    if (goal.date.split("-")[0] === year) {
                        const month = goal.month;
                        const amount = parseInt(goal.amount) / 100;
                        object[month] = amount;
                    }
                })
                globalObject[year] = object;
            })
        }
        if (typeof globalObject !== 'undefined' && Object.keys(globalObject).length > 0) {
            for (const [key, value] of Object.entries(globalObject[parseInt($('.js-currentYear').text())])) {
                if (value == "0" || value == "" || value == null) {
                    $(`*[data-month-number="${key}"]`).val("");
                } else {
                    $(`*[data-month-number="${key}"]`).val(parseInt(value));
                }
            }
        }
    });
})

months.forEach((month) => {
    $(month).on("input", (e) => {
        let yearObject = {};
        months.forEach((elem) => {
            if ($(elem).val() === "") {
                let month = $(elem).data('month-number');
                let goal = null;
                yearObject[month] = goal;
            } else if ($(elem).val() && !/[^0-9.,]/g.test($(elem).val())) {  
                let month = $(elem).data('month-number');
                let goal = $(elem).val();
                yearObject[month] = goal;
            }
        })
        globalObject[currentYear] = yearObject;
        for (let element in globalObject[currentYear]) {
            if (globalObject[currentYear][element] == "") {
                delete globalObject[currentYear][element];
            }
        }
        for (let element in globalObject) {
            if ($.isEmptyObject(globalObject[element])) {
                delete globalObject[element];
            }
        }
    })
})

$('.js-leftArrowDate, .js-rightArrowDate').on('click', (e) => {
    if ($(e.target).hasClass("js-leftArrowDate")) {
        currentYear -= 1;
        $('.js-currentYear').text(currentYear);
    } else if ($(e.target).hasClass("js-rightArrowDate")) {
        currentYear += 1;
        $('.js-currentYear').text(currentYear);
    }
    if (globalObject[$('.js-currentYear').text()]) {
        for (const [key, value] of Object.entries(globalObject[$('.js-currentYear').text()])) {
            if (value == "0" || value == "" || value == null) {
                $(`*[data-month-number="${key}"]`).val("");
            } else {
                $(`*[data-month-number="${key}"]`).val(parseInt(value));
            }
        }
    } else {
        months.forEach((month) => {
            $(month).val("");
        })
    }
})

$('.js-add-goal-btn').on('click', (e) => {
    if ($.isEmptyObject(globalObject)) {
        $('.popin-goal-step-error').html(translation('aucun objectif'));
        $('.popin-goal-step-error').show();
    } else {
        $('.js-add-goal-btn').addClass("st-no-btn-disabled");
        $('.popin-goal-step-error').hide();
        let promiseReturn = $.post("/freelance/add_goal", {goals: globalObject});
        promiseReturn.then(function(val) {
            let v = JSON.parse(val);
            if (v.status === 'success') {
                window.location.reload();
                $(".st-home-dashboard-container").animate({ scrollTop: 0 }, 300);
            } else {
                $('.popin-goal-step-error').html(v.message);
                $('.popin-goal-step-error').show();
            }
        });
    
        promiseReturn.catch(function(err) {
            $('.popin-goal-step-error').html(translation("Erreur survenue"));
            $('.popin-goal-step-error').show();
        });
    }
})