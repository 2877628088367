$(document).on('click', '.js-search-invoice-data', function(){
    $('.loader').show();
    const email = $('#userEmail').val();
    const id = $('#invoiceId').val();
    const type = $('#invoiceType').val();

    const url = '/freelance/adm/get_invoice_or_quotation';
    const submitMethod = "POST";
    const formData = new FormData();
    formData.append("userEmail", email);
    formData.append("invoiceId", id);
    formData.append("invoiceType", type);

    $.ajax({
        url: url,
        data: formData,
        processData: false,
        contentType: false,
        type: submitMethod,
        success: function(val){
            $('.loader').hide();
            let v = JSON.parse(val);
            if (v.status === 'success') {
                const { datas }  = v;
                const { user, invoiceContent, file } = datas;
                const { infos } = invoiceContent;
                if (!file) {
                    $('#infoMessage').show();
                    $('#infoMessage').text('Aucun PDF existant dans ' + (type === "INVOICE" ? 'cette facture.' : 'ce devis.'));
                }
                else {
                    $('#infoMessage').hide();
                    const filePath = file.path.replace("/app/soanback/public", "").replace("/app/public", "")

                    $('#invoiceInfosTable').show();

                    $('#invoiceIdInTable').text(datas.id);
                    $('#invoiceNumber').text(invoiceContent.number);
                    $('#invoiceDocType').text(invoiceContent.type);
                    $('#invoiceStatus').text(invoiceContent.status);
                    $('#invoiceUserId').text(user.id);
                    $('#invoiceUser').text(user.username);
                    $('#invoiceDestCompany').text(invoiceContent.destCompanyName);
                    $('#invoiceAmountHT').text(Math.round(infos.amountHT)/100 + ' €');
                    $('#invoiceAmountTTC').text(Math.round(infos.amountTTC)/100 + ' €');
                    if (filePath) {
                        $('#invoiceFile').html('<a class="a-white" href="' + filePath + '">' + invoiceContent.fileName + '</a>');
                        $('#generateNewInvoicePDFBtn').show();
                    }
                }

            }
            else {
                const { message }  = v;
                $('#invoiceInfosTable').hide();
                $('#infoMessage').show();
                $('#infoMessage').text(message ? message : "Aucun resultat.");
            }
        },
        error: function(err) {
            $('.loader').hide();
            $('#invoiceInfosTable').hide();
            $('#infoMessage').show();
            $('#infoMessage').text('Une erreur est survenue : ' + err);
        }
    });
});

$(document).on('click', '.js-generate-new-invoice-pdf', function(){
    $('.loader').show();
    const userId = $('#invoiceUserId').text();
    console.log("userId => ", userId);
    const invoiceId = $('#invoiceId').val();
    const invoiceType = $('#invoiceType').val();

    const url = `/freelance/generate_pdf_from_invoice/${userId}/${invoiceType}/${invoiceId}`;
    const submitMethod = "GET";

    $.ajax({
        url: url,
        processData: false,
        contentType: false,
        type: submitMethod,
        success: function(val){
            $('.loader').hide();
            let v = JSON.parse(val);
            if (v.status === 'success') {
                const { datas }  = v;
                const { invoiceContent, file } = datas;
                
                if (!file) {
                    $('#infoMessage').show();
                    $('#infoMessage').text('Aucun PDF existant dans ' + (invoiceType === "INVOICE" ? 'cette facture.' : 'ce devis.'));
                }
                else {
                    $('#infoMessage').hide();
                    const filePath = file.path.replace("/app/soanback/public", "").replace("/app/public", "");

                    if (filePath) {
                        $('#invoiceFile').html('<a class="a-white" href="' + filePath + '">' + invoiceContent.fileName + '</a>');
                        $('#generateNewInvoicePDFBtn').show();
                    }
                }
            }
            else {
                const { message }  = v;
                $('#invoiceInfosTable').hide();
                $('#infoMessage').show();
                $('#infoMessage').text(message ? message : "Aucun resultat.");
            }
        },
        error: function(err) {
            $('.loader').hide();
            $('#invoiceInfosTable').hide();
            $('#infoMessage').show();
            $('#infoMessage').text('Une erreur est survenue : ' + err);
        }
    });
});


