import {translation} from "../generalFunctions";
$('.generate-signature').on('click', function() {
  $('.error-signature').html('')
  let phone = $('#phone').val();
  let name = $('#name').val();
  let job = $('#job').val();
  let hubspot = $('#hubspot').val()
  if (name.length == 0 || job.length == 0 || phone.length == 0) {
    $('.error-signature').html(translation('Signature complète'));
  } else {
    name = capitalizeTheFirstLetterOfEachWord(name);
    job = capitalizeFirstLetter(job);
    phone = phone.toString().replace(/\B(?=(\d{2})+(?!\d))/g, " ");
    if (hubspot.length > 20 ) {
      $('.hubspot').show();
      $('.hubspot').addClass('hubspot-visible');
      $('.hubspot-link').attr('href', hubspot);
    } else if (hubspot.length < 20 && hubspot.length > 0) {
      $('.error-signature').html(translation('Vérifiez votre lien hubspot'));
    } else if (hubspot.length == 0 && $('.hubspot').hasClass('hubspot-visible')) {
      $('.hubspot').hide();
      $('.hubspot-link').attr('href', '');
    }
    $('.js-set-name').html(name);
    $('.js-set-job').html(job);
    $('.js-set-phone').html(phone);
    $('.signature-container').removeClass('d-none');
    $('.copy-signature').removeClass('d-none');
    $('.btn-container').removeClass('d-none');
  }
});

function capitalizeFirstLetter(string) {
  return string.toString().charAt(0).toUpperCase() + string.slice(1);
}
function capitalizeTheFirstLetterOfEachWord(words) {
  var separateWord = words.toLowerCase().split(' ');
  for (var i = 0; i < separateWord.length; i++) {
    separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
      separateWord[i].substring(1);
  }
  return separateWord.join(' ');
}
