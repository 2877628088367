import {translation} from "../newScripts/generalFunctions";
if (window.location.pathname === '/freelance/' || window.location.pathname === '/freelance/finance_transactions') {
  if (window.location.pathname === '/freelance/finance_transactions') {
    let vars = {};
    window.location.href.replace(location.hash, '').replace(/[?&]+([^=&]+)=?([^&]*)?/gi,
      function (m, key, value) {
        vars[key] = value;
      });
    let objectif = vars['objectif'];

    if (objectif == 'true') {
      setTimeout(() => {
        $('.js-openPopinDefineGoal').click();
      }, 1500);
    }
  }

  let request = $.ajax({
    url: '/freelance/find_stats_home',
    type: 'GET'
  });
  getChartStats(request);
  let actualYear = new Date().getFullYear();
  $('.js-period-year').val(actualYear);
}
$('.js-send-startingDate').on('click', function () {

  if ($('.js-period-year').val() < 2010) {
    let actualYear = new Date().getFullYear();
    $('.js-period-year').val(actualYear);
  }

  let startingDate = $('.js-period-year').val() + '-' + $('.starting-date').val();
  let request = $.ajax({
    url: '/freelance/find_stats_home',
    type: 'POST',
    data: {startingDate: startingDate}
  });

  getChartStats(request);

  $(".backdrop").hide();
  $(".backdrop-fix").hide();
  $(".backdropstepstart").hide();
  $(".popin").hide();
  $(".popin-wrap").hide();
  $(".popin-errors").hide();
  $(".popin-error").remove();
});

$('#chartFilter').change( function () {
  let projectId = $(this).val();
  let request = $.ajax({
    url: '/freelance/find_stats_home',
    type: 'POST',
    data: {projectId: projectId}
  });

  getChartStats(request);
})

function getChartStats(request) {
  request.then(function (val) {
    let v = JSON.parse(val);
    if (v.status === 'success') {
      $('.graph').addClass('active');
      let allValues = [];
      let turnover = [];
      let debited = [];
      let currentTurnover;
      let currentDebited;

      let firstYear = getStartingYear(v.data[0].startingMonth);
      let firstmonth = (v.data[0].startingMonth).substr(5);

      $('.js-period-year').val(firstYear);
      $('.js-starting-month').val($('.js-starting-month option[value="'+ firstmonth +'"]').val());

      $('.bars').html("");
      $('.js-choose-period').html((getStartingMonth(v.data[0].startingMonth)) + ' ' + getStartingYear(v.data[0].startingMonth) + ' - ' + (getStartingMonth(v.data[11].startingMonth)) + ' ' + getStartingYear(v.data[11].startingMonth));

      for (let i = 0; i < v.data.length; i++) {
        let startingMonth = getStartingMonth(v.data[i].startingMonth);
        let startingYear = getStartingYear(v.data[i].startingMonth);
        let debitedAmount = (v.data[i].debitedAmount) / 100;
        let creditedAmount = (v.data[i].creditedAmount) / 100;
        let creditedWaitingAmount = (v.data[i].creditedWaitingAmount) / 100;
        let goal = (v.data[i].goal) / 100;

        allValues.push(creditedAmount, debitedAmount, creditedWaitingAmount, goal);
        turnover.push(creditedAmount);
        debited.push(debitedAmount)

        const gain = turnover => turnover.reduce((a, b) => a + b, 0);
        currentTurnover = gain(turnover);
        currentTurnover = Math.round(currentTurnover * 100) / 100;

        const debt = debited => debited.reduce((a, b) => a + b, 0);
        currentDebited = debt(debited);
        currentDebited = Math.round(currentDebited * 100) / 100;


        if (!($('.month[data-month=' + startingMonth + ']')).length) {
          $('.bars').append("<div class='month' data-month='" + startingMonth + "' data-year='"+ startingYear+"'>");
        }
        $('.month[data-month=' + startingMonth + ']').append("<div class='bar st-sortie' data-num='"+i+"' data-type='Sortie' data-value='" + debitedAmount + "'><div class='position-relative'><span class='tooltip-legend'>" + translation("Sortie") + "<br> " + translation("Montant") + " : " + numberWithSpaces(debitedAmount.toString().replace('.', ',')) + " € TTC <br> " + translation("Montant cumulé") + " : " + numberWithSpaces(currentDebited.toString().replace('.', ',')) + " € TTC</span></div></div>");
        $('.month[data-month=' + startingMonth + ']').append("<div class='bar st-entree' data-num='"+i+"' data-type='Encaissé' data-value='" + creditedAmount + "'><div class='position-relative'><span class='tooltip-legend'>" + translation("Encaissé") + " <br> "+ translation("Montant") + " : " + numberWithSpaces(creditedAmount.toString().replace('.', ',')) + " € TTC<br> " + translation("Montant cumulé") + " : " + numberWithSpaces(currentTurnover.toString().replace('.',',')) + " € TTC</span></div></div>");
        $('.month[data-month=' + startingMonth + ']').append("<div class='bar st-facturee' data-num='"+i+"' data-type='Facturé' data-value='" + creditedWaitingAmount + "'><div class='position-relative'><span class='tooltip-legend'>" + translation("Facturation") + " <br> " + translation("Montant") + " : " + numberWithSpaces(creditedWaitingAmount.toString().replace('.',',')) + " € TTC</span></div></div>");
        $('.month[data-month=' + startingMonth + ']').append("<div class='st-goal' data-num='"+i+"' data-type='Objectif' data-value='" + goal + "'><div class='position-relative'><span class='tooltip-legend'>" + translation("Objectif de CA") + "<br> " + translation("Montant") + " : " + numberWithSpaces(goal.toString().replace('.','.')) + " € TTC</span></div></div>");
      }

      let today = new Date();
      let todayMonth = today.getMonth();
      let todayYear = today.getFullYear();

      todayMonth = parseInt(todayMonth) + 1
      if (todayMonth.toString().length < 2) {
        todayMonth = '0' + todayMonth;
        todayMonth = getStartingMonth(todayMonth)
      }

      $('.month').each(function () {
        if ($(this).attr('data-month') == todayMonth && $(this).attr('data-year') == todayYear) {
          $(this).addClass('actualMonth');
        }
      })

      //Calculate sum of turnover for period
      const add = turnover => turnover.reduce((a, b) => a + b, 0);
      let sumTurnover = add(turnover);
      sumTurnover = Math.round(sumTurnover * 100) / 100;
      sumTurnover = sumTurnover.toString();
      sumTurnover = sumTurnover.replace('.', ',');
      $('.js-total-turnover').text(" " + numberWithSpaces(sumTurnover) + ' €');

      let valueMax = Math.max(...allValues);
      if (valueMax == 0) {
        $('.bar').each(function () {
          $(this).css({
            'height': '0%'
          })
        })
        $('.st-goal').each(function () {
          $(this).css({
            'bottom': '0%'
          })
        })
        $('.val-4').html('15 k€');
        $('.val-3').html('10 k€');
        $('.val-2').html('5 k€');
      } else {
        let maxScaleNumber = scaleNumber(valueMax);
        let scaleNumber2 = Math.ceil(maxScaleNumber / 3)
        let scaleNumber3 = Math.ceil(maxScaleNumber / 3) * 2

        $('.val-4').html(formatNumber(maxScaleNumber));
        $('.val-2').html(formatNumber(scaleNumber2));
        $('.val-3').html(formatNumber(scaleNumber3));

        $('.bar').each(function () {
          let elHeight = $(this).attr('data-value');
          if (elHeight == maxScaleNumber) {
            $(this).css({
              'height': '100%',
            })
          } else {
            let height = (elHeight * 100) / maxScaleNumber
            $(this).css({
              'height': height + '%',
            })
          }
          if ($(this).attr('data-num') > 6 ) {
            $(this).addClass('tooltip-left')
          }
        })
        $('.st-goal').each(function () {
          let goalHeight = $(this).attr('data-value')
          let bottom = (goalHeight * 100) / maxScaleNumber
          $(this).css({
            'bottom': bottom + '%',
          });
          if ($(this).attr('data-num') > 6 ) {
            $(this).addClass('tooltip-left')
          }
        });
      }

      $('.bar, .st-goal').mouseover(function () {
        if ($(this).hasClass('st-sortie')) {
          $(this).css({
            'z-index': '9'
          })
        } else if ($(this).hasClass('st-entree')) {
          $(this).css({
            'z-index': '9'
          })
          $(this).parents('.month').find('.st-goal').css({
            'z-index': '9'
          })
        } else if ($(this).hasClass('st-facturee')) {
          $(this).css({
            'z-index': '8'
          })
          $(this).parents('.month').find('.st-goal, .st-entree').css({
            'z-index': '9'
          })
        } else if ($(this).hasClass('st-goal')) {
          $(this).css({
            'z-index': '9'
          })
        }
      })

      $('.bar, .st-goal').mouseleave(function () {
        if ($(this).hasClass('st-sortie')) {
          $(this).css({
            'z-index': '1'
          })
        } else if ($(this).hasClass('st-entree')) {
          $(this).css({
            'z-index': '1'
          })
          $(this).parents('.month').find('.st-goal').css({
            'z-index': '1'
          })
        } else if ($(this).hasClass('st-facturee')) {
          $(this).css({
            'z-index': '0'
          })
          $(this).parents('.month').find('.st-goal, .st-entree').css({
            'z-index': '1'
          })
        } else if ($(this).hasClass('st-goal')) {
          $(this).css({
            'z-index': '1'
          })
        }
      })
      if (window.matchMedia('(max-width: 578px)').matches) {
        $('.bar, .st-goal').mouseover(function () {
          if ($(this).attr('data-type') == 'Sortie'){
            $('.responsive-tooltip').html("<div> " + $(this).attr('data-type') + "</div><div>Montant : " + numberWithSpaces($(this).attr('data-value').toString().replace('.', ',')) + " € TTC</div><div>Montant cumulé : "+ numberWithSpaces(currentDebited.toString().replace('.', ',')) +" TTC</div>");
            $('.responsive-tooltip').css({
              'opacity': '1'
            })
          } else if ($(this).attr('data-type') == 'Encaissé') {
            $('.responsive-tooltip').html("<div> " + $(this).attr('data-type') + "</div><div>Montant : " + numberWithSpaces($(this).attr('data-value').toString().replace('.', ',')) + " € TTC" +
              "</div><div>Montant cumulé : "+ numberWithSpaces(currentTurnover.toString().replace('.', ',')) +" TTC</div>");
            $('.responsive-tooltip').css({
              'opacity': '1'
            })
          } else if ($(this).attr('data-type') == 'Objectif' || $(this).attr('data-type') == "Facturé") {
            $('.responsive-tooltip').html("<div> " + $(this).attr('data-type') + "</div><div>Montant : " + numberWithSpaces($(this).attr('data-value').toString().replace('.', ',')) + " € TTC</div></div>");
            $('.responsive-tooltip').css({
              'opacity': '1'
            })
          }
        })
        $('.bar, .st-goal').mouseleave(function () {
          $('.responsive-tooltip').html('');
          $('.responsive-tooltip').css({
            'opacity': '0'
          })
        })


      }
    } else {
      $('.popin-step-error').val(translation('Erreur survenue'));
    }
  })
}

$(document).on('click', '.legend-out, .span-out', function () {
  $('.st-sortie').toggleClass('d-none');
  $('.legend-out').toggleClass('border-out');
})
$(document).on('click', '.legend-in, .span-in', function () {
  $('.st-entree').toggleClass('d-none');
  $('.legend-in').toggleClass('border-in');
})
$(document).on('click', '.legend-billed, .span-billed', function () {
  $('.st-facturee').toggleClass('d-none');
  $('.legend-billed').toggleClass('border-billed');
})
$(document).on('click', '.legend-goals, .span-goals', function () {
  $('.st-goal').toggleClass('d-none');
  $('.legend-goals').toggleClass('border-goals');
})

function getStartingMonth(date) {
  let monthValue;
  let monthArray = {
    "01": translation("jan"),
    "02": translation("fev"),
    "03": translation("mar"),
    "04": translation("avr"),
    "05": translation("mai"),
    "06": translation("jui"),
    "07": translation("juil"),
    "08": translation("aou"),
    "09": translation("sep"),
    "10": translation("oct"),
    "11": translation("nov"),
    "12": translation("dec")
  };

  let monthNumber = date;
  if (date.toString().length > 2) {
    monthNumber = date.substr(5);
  }

  for ( let key in monthArray ) {
    if (monthNumber == key ) {
      monthValue = monthArray[key]
    }
  }
  return monthValue;
}
function scaleNumber(number) {
  let t = parseInt(number)/3;
  let q = Math.pow(10, Math.ceil(Math.log10(t)) - 1)
  let max = (parseInt(t/q)+1)*q*3;

  return max;
}
function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
function formatNumber(number) {
  let numberFormated;
  if (number > 999 && number < 1000000) {
    numberFormated = number/1000;
    numberFormated = numberFormated.toString() + " k€";
  } else if (number > 999999) {
    numberFormated = number / 1000000;
    numberFormated = numberFormated.toString() + " M€";
  }
  return numberFormated;
}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
function getStartingYear(date) {
  let year = date.substr(0, 4);
  return year
}