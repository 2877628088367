$(document).on('click', '.generate-promo', function () {
  $('.error-promo').addClass('d-none').html('');
  let promiseReturn = $.post('/freelance/admin/create_sub_promo', {
    "tag": $('#tag').val(),
    "price": $('#price').val(),
    "freeDays": $('#freeDays').val(),
    "text": $('#text').val(),
    "nbPayinNeeded": $('#payins').val(),
    "limit": $('#limit').val(),
    "nbPayment": $('#nbPayment').val(),
    "isFree": ($("#ISFREE").is(':checked') ? 1 : 0),
    "featureAccess": getBitmask()
  })

  promiseReturn.then(function (val) {
    let v = JSON.parse(val);
    if (v.status === "success") {
      window.location.reload();
    }
    else {
      let error = v.message;
      $('.error-promo').removeClass('d-none').html(error);
    }
  })
})

$(document).on('click', '.js-remove-promo', function () {
  $('.error-promo').addClass('d-none').html('');
  let id = $(this).attr("data-id");
  let promiseReturn = $.get('/freelance/admin/remove_sub_promo/'+id);

  promiseReturn.then(function (val) {
    let v = JSON.parse(val);
    if (v.status === "success") {
      window.location.reload();
    }
    else {
      let error = v.message;
      $('.error-promo').removeClass('d-none').html(error);
    }
  })
})

function getBitmask() {
  let mask = 0;
  mask += ($("#PAYMENT").is(':checked') ? (1 << 0) : 0);
  mask += ($("#PAYMENT_LINK").is(':checked') ? (1 << 1) : 0);
  mask += ($("#PAYMENT_REPORTING").is(':checked') ? (1 << 2) : 0);
  mask += ($("#PAYMENT_SUPPLIER").is(':checked') ? (1 << 3) : 0);
  mask += ($("#PAYMENT_SUPPLIER_PLAN").is(':checked') ? (1 << 4) : 0);
  mask += ($("#INVOICE").is(':checked') ? (1 << 5) : 0);
  mask += ($("#INVOICE_QUOTATION_GENERATION").is(':checked') ? (1 << 6) : 0);
  mask += ($("#INVOICE_OCR").is(':checked') ? (1 << 7) : 0);
  mask += ($("#CONTACT").is(':checked') ? (1 << 8) : 0);
  mask += ($("#SIGN").is(':checked') ? (1 << 9) : 0);
  mask += ($("#RECALL").is(':checked') ? (1 << 10) : 0);
  mask += ($("#RECALL_TEMPLATE").is(':checked') ? (1 << 11) : 0);
  mask += ($("#RECOVERY").is(':checked') ? (1 << 12) : 0);
  mask += ($("#LATE_NOTIFICATION").is(':checked') ? (1 << 13) : 0);
  mask += ($("#REPORTING").is(':checked') ? (1 << 14) : 0);
  mask += ($("#OBJECTIVE").is(':checked') ? (1 << 15) : 0);
  mask += ($("#STATS").is(':checked') ? (1 << 16) : 0);
  mask += ($("#EXPORT").is(':checked') ? (1 << 17) : 0);
  mask += ($("#ACCOUNTING").is(':checked') ? (1 << 18) : 0);
  mask += ($("#ACCOUNTING_REPORTING").is(':checked') ? (1 << 19) : 0);
  mask += ($("#SUPPORT_VIDEO").is(':checked') ? (1 << 20) : 0);
  mask += ($("#SUPPORT_EXPERT").is(':checked') ? (1 << 21) : 0);
  mask += ($("#SUPPORT").is(':checked') ? (1 << 22) : 0);
  return mask;
}
