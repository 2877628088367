import {getEncryptedEmail, translation} from "../generalFunctions";
import $ from "jquery";

if (window.location.pathname !== "/payer_mes_fournisseurs") {
    $(() => {
        if ($(".msg-flash div").length > 0) {
            $(".msg-flash").css("display", "block");
        }
        else {
            $(".msg-flash").css("display", "none");
        }

        if ($("body").data("currentpage") === "offline_approval") {
            $(".msg-flash").css({"padding": "0", "top": "10px"});
        }

        // Function which copy the phone number on click
        $(".js-iconPhone").on("click", () => {
            $(".st-copiedPhoneNumber").css("display", "block");
            let getPhone = $(".js-iconPhone").data("getphone");
            let temp = $("<input>");
            $("body").append(temp);
            temp.val(getPhone).trigger("select");
            document.execCommand("copy");
            temp.remove();
            setTimeout(() => {
                $(".st-copiedPhoneNumber").css("display", "none");
            }, 4000);
        });

        let arrayOfId = [];

        $(".js-checkboxOffline").prop("checked", true);
        $(".js-checkboxOffline").attr("checked", true);

        $(".content-to-pay .content-item").each(function() {
            let getId = $(this).data("approval-id");
            arrayOfId.push(getId);
        });

        window.changeStateCheckbox = function (id) {
            if ($("#js-checkbox" + id).attr('checked')) {
                $("#js-checkbox" + id).removeAttr('checked');

                let indexId = arrayOfId.indexOf(id);
                if (indexId !== -1) {
                    arrayOfId.splice(indexId, 1);
                }
            }
            else {
                $("#js-checkbox" + id).attr("checked", true);

                let indexId = arrayOfId.indexOf(id);
                if (indexId === -1) {
                    arrayOfId.push(id);
                }
            }
            if (arrayOfId.length === 0) {
                $('.st-refuse-approbation, .st-give-approbation').removeClass('js-approve').removeClass('cpointer').removeClass('btn-primary').addClass('st-approval-disabled')
            }
            else if (!$('.st-give-approbation').hasClass('js-approve')) {
                $('.st-refuse-approbation, .st-give-approbation').addClass('js-approve').addClass('cpointer').removeClass('st-approval-disabled')
                $('.st-give-approbation').addClass('btn-primary');
            }
        }

        $(".company-payed").on("click", () => {
            $(".st-paymentOfflineBloc").hide();
            $(".st-blocIconLock").hide();
        });

        $(".company-to-pay").on("click", () => {
            $(".st-blocIconLock").show();
            if ($(".content-to-pay .content-item").length > 0) {
                $(".st-paymentOfflineBloc").show();
            }
        });

        // We define global variable which get email and fournisseur from URL
        let vars = {};
        window.location.href.replace( location.hash, '' ).replace(/[?&]+([^=&]+)=?([^&]*)?/gi,
          function( m, key, value ) {
              vars[key] = value;
          });
        let email = vars['email'];
        let payeur = vars['payeur'];


        if ($("body").attr("data-currentpage") === "offline_approval") {
            $("body").css("background-color", "#F8F8F8");
        }

        $(".st-crossPopinError").on("click", function() {
            $(".st-blocPopinError, .st-blocFormError1, .st-blocFormError2, .st-blocFormError3, .st-approval-errors, .st-blocFormErrorImport").removeClass("d-flex").addClass("d-none").attr("data-actif", "false");
        });

        window.hidingBloc3x = (id) => {
            $("#js-bloc3x" + id).removeClass("d-flex").addClass("d-none");
        }

        $(".st-helpIcon").on("mouseover", function() {
            $(".st-cryptoMsg").show();
        }).on("mouseout", function() {
            $(".st-cryptoMsg").hide();
        });

        $(document).on('click', '.js-approve', function () {
            let approval = $(this).data("choice");
            let isAuthenticated = $(".js-approve").attr('data-authenticated');
            let code = $('.js-authenticationInput').val()

            $(this).addClass('st-no-btn-disabled');
            let promiseReturn = $.post("/approbations/approve", {
                code: code,
                isAuthenticated: isAuthenticated,
                email: email,
                payeur: payeur,
                approvals: arrayOfId,
                approval: approval
            });

            promiseReturn.then(function(val) {
                let v = JSON.parse(val);
                if (v.status === 'success') {
                    window.location.reload();
                }
                else {
                    $(this).removeClass('st-no-btn-disabled');
                    $('.st-blocPopinError').removeClass('d-none').addClass('d-flex');
                    $('.errorMsgOffline').html(v.message).show();
                }
            });
        });

        $('.js-approvalAuth').on('click', function () {
            $('.loader-popin').fadeIn();
            let approval  = $(this).data("choice");
            let choice = $(this).attr('data-choice');
            $('.js-sendAuthBtn').addClass('js-approve');
            $('.js-sendAuthBtn').attr('data-choice', choice);
            $('.js-sendNewCode').addClass('sendForApproval');

            let promiseReturn = $.post('/approbation/authentification', {
                "email": email,
                "payeur": payeur,
                "approval": approval,
                'approvals': arrayOfId,
                "newCode": "false"
            })

            promiseReturn.then(function (val) {
                let v = JSON.parse(val);
                if (v.status === "success") {
                    $('.loader-popin').fadeOut();
                    let encryptedEmail = getEncryptedEmail(v.email);
                    $('.js-cryptedEmail').html(encryptedEmail);
                    $('.js-sendNewCode').attr('data-email', encryptedEmail);
                }
                else if (v.status === "authenticated") {
                    let btn = $('.js-approve');
                    btn.attr('data-authenticated', true);
                    btn.trigger('click');
                }
                else {
                    window.location.href = v.url;
                }
            })
        })

        $('.sendForApproval').on('click', function () {
            $('.loader-popin').fadeIn();
            let promiseReturn = $.post('/approbation/authentification', {
                "newCode": "true",
                "email": email
            })
            promiseReturn.then(function (val) {
                let v = JSON.parse(val);
                if (v.status === "success") {
                    let encryptedEmail = getEncryptedEmail(v.email);
                    $('.js-noCodeReceived').html("Un nouveau code vient d'être envoyé à " + encryptedEmail + ". Toujours pas reçu ? Contactez nous à support@soan-solutions.io")
                    $('.loader-popin').fadeOut();
                }
                else if(v.status === "authenticated") {
                    let btn = $('.js-directDebitWithMandate');
                    btn.attr('data-authenticated', true);
                    btn.trigger('click');
                }
                else {
                    window.location.href = v.url;
                }
            })
        })

        $('.input-comment').on('input', function () {
            let id = $(this).attr("data-id");
            if ($(this).val().trim().length > 0) {
                $('.js-store-comment-'+id).removeClass('d-none');
            }
            else {
                if (!$('.js-comment-'+id).hasClass('editing')) {
                    $('.js-store-comment-' + id).addClass('d-none');
                }
            }
        })
        $('.js-send-comment').on('click', function () {
            let id = $(this).attr('data-id');
            let comment = $('.js-comment-'+id).val().trim();
            let promiseReturn = $.post("/approbation/"+id+"/comment", {
                comment: comment,
                email: email,
                payeur: payeur
            })
            promiseReturn.then(function (val) {
                let v = JSON.parse(val);
                if (v.status === 'success') {
                    if (comment.length === 0) {
                        $('.js-store-comment-'+id).addClass('d-none');
                    } else {
                        $('.js-comment-'+id).addClass('d-none');
                        $('.js-create-comment-'+id).addClass('d-none');
                        $('.js-store-comment-'+id).addClass('d-none');
                        $('.js-comment-stored-'+id).removeClass('d-none').html(comment);
                        $('.js-edit-comment-'+id).removeClass('d-none');
                    }
                }
                else {
                    $('.st-approval-errors').removeClass('d-none').addClass('d-flex');
                    $('.js-errorMsg').html(translation("Erreur lors de l'ajout du commentaire"));
                }
            })
        })
        $('.js-edit-comment-icon').on('click', function () {
            let id = $(this).attr('data-id');
            let comment = $('.js-comment-stored-'+id).text();
            let textarea = $('.js-comment-'+id);
            $('.js-comment-stored-'+id).addClass('d-none');
            $('.js-edit-comment-'+id).addClass('d-none');
            (textarea).removeClass('d-none');
            (textarea).addClass('editing');
            (textarea).val(comment);
            $('.js-create-comment-'+id).removeClass('d-none');
            $('.js-store-comment-'+id).removeClass('d-none');
        })
        $('.js-approval-done').on('click', function () {
            $('.st-approval-buttons-bloc').hide();
        })
        $('.js-approval-waiting').on('click', function () {
            $('.st-approval-buttons-bloc').show();
        })
        $('.js-iconPhone-approval').on('click', function () {
            let id = $(this).attr('data-approval');
            let phone = $(this).attr('data-getphone');
            $('.js-phone-'+id).html(translation('Numéro copié !'));
            setTimeout( () => {
                $('.js-phone-'+id).html(phone);
            }, 2000)
        })
    });
}
