var count = 0;

$(function()
{
  var config = buildConfig();
  $(document).on('change', '#csvCatalog', function() {
    $('#csvCatalog').parse({
      config: config
    });
  });
});

function buildConfig()
{
	return {
		delimiter: ",",
		header: false,
		dynamicTyping: false,
		skipEmptyLines: true,
		preview: 0,
		step: stepFn,
		worker: false,
		complete: completeFn,
		download: true
	};
}

function stepFn(results, parser)
{
  console.log(count);
	if (results && results.data) {
    parser.pause();
    if (count == 0) {
      count++;
      parser.resume();
      return ;
    }
    let promiseReturn;
    if ($('#select-type').val() == "Catalogue") {
      promiseReturn = $.post("/freelance/admin/"+$('.js-autocomplete-adm-user').data('id')+"/import_catalog_line", {isProduct: results.data[0], title: results.data[1], price: results.data[2], taxe: results.data[3], description: results.data[4], unite: results.data[5], reference: results.data[6]});
    }
    else if ($('#select-type').val() == "Fournisseur") {
      promiseReturn = $.post("/freelance/admin/"+$('.js-autocomplete-adm-user').data('id')+"/import_contact_line", {isCompany: results.data[0], name: results.data[1], email: results.data[2], siret: results.data[3], contactFirstname: results.data[4], contactLastname: results.data[5], contactEmail: results.data[6], mobile: results.data[8], phone: results.data[7], addressName: results.data[9], address: results.data[10], addressAdditional: results.data[11], postalCode: results.data[12], city: results.data[13], country: results.data[14], address2Name: results.data[15], address2: results.data[16], address2Additional: results.data[17], postalCode2: results.data[18], city2: results.data[19], country2: results.data[20], iban: results.data[21], notes: results.data[22], account: results.data[23], tags: results.data[24], desc: results.data[25], isSupplier: 'S'});
    }
    else if ($('#select-type').val() == "Client") {
      promiseReturn = $.post("/freelance/admin/"+$('.js-autocomplete-adm-user').data('id')+"/import_contact_line", {isCompany: results.data[0], name: results.data[1], email: results.data[2], siret: results.data[3], contactFirstname: results.data[4], contactLastname: results.data[5], contactEmail: results.data[6], mobile: results.data[8], phone: results.data[7], addressName: results.data[9], address: results.data[10], addressAdditional: results.data[11], postalCode: results.data[12], city: results.data[13], country: results.data[14], address2Name: results.data[15], address2: results.data[16], address2Additional: results.data[17], postalCode2: results.data[18], city2: results.data[19], country2: results.data[20], iban: results.data[21], notes: results.data[22], account: results.data[23], tags: results.data[24], desc: results.data[25], isSupplier: 'C'});
    }
    else if ($('#select-type').val() == "User") {
      promiseReturn = $.post("/freelance/admin/import_user_line", {email: results.data[5], firstname: results.data[4], lastname: results.data[3], companyName: results.data[0], phoneNumber: results.data[7], CUUID: results.data[9]});
    }
    else {
      parser.resume();
      return ;
    }
    promiseReturn.then(function(val) {
      let v = JSON.parse(val);
      if (v.status === 'success') {
        let line = "<div class='line'>Element : <span class='line-name'>"+results.data[1]+"</span></div>";
        $('.result-content').append(line);
      }
      else {
        let line = "<div class='line cerror'><span class='line-name'>Une erreur est survenue pour => Element: "+results.data[1]+"</span> <span class='line-password'>Erreur : "+v.msg+"</span></div>";
        $('.result-content').append(line);
      }
      $('.result-container').scrollTop(50000);
      parser.resume();
      return ;
    });

    promiseReturn.catch(function(err) {
      parser.resume();
    });
  }
}

function completeFn()
{
  console.log('ok');
}

$(document).on('click', '.user-item', function() {
  let id = $(this).data('id');
  let email = $(this).find('.user-email').text();
  $('.js-autocomplete-adm-user').val(email);
  $('.block-select').show();
  $('.js-autocomplete-adm-user').attr('data-id', id);
  $('.autocomplete-dist-user').html('');
});

$(document).on('input', '.js-autocomplete-adm-user', function() {
  $('.autocomplete-dist-user').html('');
  var val = $(this).val();
  $(this).removeAttr('data-id');
  let autocompleteArray = $('.js-autocomplete-adm-user').data('userlist');
  if (val) {
    var matching = $.grep(autocompleteArray, function(v) {
      if ((v.email && v.email.toLowerCase().includes(val.toLowerCase()))) {
        return true;
      }
      return false;
    });
    if (matching.length > 0) {
      let choice = "";
      $.each(matching, function(i, v) {
        choice += "<div class='user-item cpointer' data-id='" + v.id + "'><div class='user-email font-weight-bold'>" + v.email + "</div></div>";
      });
      $('.autocomplete-dist-user').html(choice);
    }
    else {
      $('.autocomplete-dist-user').html('');
    }
  }
  else {
    $('.autocomplete-dist-user').html('');
  }
});

$('#select-type').on('change', function() {
  if ($(this).val() == 'Paiement') {
    $('.block-csv-file').hide();
    $('.block-pdf-file').show();
  }
  else {
    $('.block-pdf-file').hide();
    $('.block-csv-file').show();
  }
});

$(document).on('input', '#csvCatalog, #invoices', function() {
  $('.result-container').show();
});

$('#invoices').on('change', function(e) {
  e.preventDefault();
  var url = "/freelance/admin/"+$('.js-autocomplete-adm-user').data('id')+"/import_invoice";
  var files = e.currentTarget.files;

  files.forEach(function(entry) {
    if (entry instanceof File) {
      var fileForm = new FormData()
      fileForm.append('invoices', entry)
      fileForm.append('status', $('.js-select-status').val())

      $.ajax({
        url: url,
        type: 'post',
        data: fileForm,
        success: function(response) {
          let v = JSON.parse(response);
          if (v.status === 'success') {
            let line = "<div class='line'>Element : <span class='line-name'>"+entry.name+"</span></div>";
            $('.result-content').append(line);
          }
          else {
            let line = "<div class='line cerror'><span class='line-name'>Une erreur est survenue pour => Element: "+entry.name+"</span> <span class='line-password'>Erreur : "+v.msg+"</span></div>";
            $('.result-content').append(line);
          }
          //$('.result-container').scrollTop(50000);
          return ;
        },
        cache: false,
        contentType: false,
        processData: false
      })
    }
  })
})
