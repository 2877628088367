
$("#js-siegeFR").attr("checked", true);

$("#js-siegeOther").on("click", function() {
    $(".company-block-form, .company-block-actions, .st-titleStatut").hide();
    $(".st-OtherActif").show();
});

$("#js-siegeFR").on("click", function() {
    $(".st-statusFR").show();
    $(".st-statusES, .st-OtherActif").hide();
    $(".company-block-form, .company-block-actions, .st-titleStatut").show();
});

$("#js-siegeES").on("click", function() {
    $(".st-statusES").show();
    $(".st-statusFR, .st-OtherActif").hide();
    $(".company-block-form, .company-block-actions, .st-titleStatut").show();
});

$('#js-txtLabelCheckBox').on('click', function () {
    $('#checkboxAutoComplete').trigger("click");
});

$('#checkboxAutoComplete').on('click', function() {
    if (this.checked) {
        const userFirstName = $('#company_infos_thLRFirstname').attr('data-firstname');// const userLastName = $('.thLRLastname').data('lastname');
        const userEmail = $('#company_infos_thLREmail').attr('data-email');
        const userLastName = $('#company_infos_thLRLastname').attr('data-lastname');// const userLastName = $('.thLRLastname').data('lastname');
        const userCountry = $('#company_infos_thLRAddress_thCountry').attr('data-country');
        const userNationality = $('#company_infos_thLRNationality').attr('data-nationality');
        const userBirthday = $('#company_infos_thLRBirthday').attr('data-birthday');
        const userBirthCity = $('#company_infos_thLRBirthCity').attr('data-cityofbirth');
        const userAddress = $('#company_infos_thLRAddress_thAddress').attr('data-address');
        const userCity = $('#company_infos_thLRAddress_thCity').attr("data-city");
        const userState = $('#company_infos_thLRAddress_thState').attr("data-state");
        const userPostalCode = $('#company_infos_thLRAddress_thPostalCode').attr("data-postcode");

        $("#company_infos_thLRFirstname").val(userFirstName);
        $("#company_infos_thLRLastname").val(userLastName);
        $("#company_infos_thLREmail").val(userEmail);
        $('#company_infos_thLRBirthday').val(userBirthday);
        $("#company_infos_thLRNationality").val(userNationality);
        $("#company_infos_thLRAddress_thAddress").val(userAddress);
        $('#company_infos_thLRAddress_thPostalCode').val(userPostalCode);
        $('#company_infos_thLRAddress_thCity').val(userCity);
        $('#company_infos_thLRBirthCity').val(userBirthCity);
        $('#company_infos_thLRAddress_thState').val(userState);
        $('#company_infos_thLRAddress_thCountry').val(userCountry);
    } else {
        $("#company_infos_thLRFirstname").val("");
        $("#company_infos_thLRLastname").val("");
        $("#company_infos_thLREmail").val("");
        $('#company_infos_thLRBirthday').val("");
        $("#company_infos_thLRNationality").val("");
        $("#company_infos_thLRAddress_thAddress").val("");
        $('#company_infos_thLRAddress_thPostalCode').val("");
        $('#company_infos_thLRAddress_thCity').val("");
        $('#company_infos_thLRBirthCity').val("");
        $('#company_infos_thLRAddress_thState').val("");
        $('#company_infos_thLRAddress_thCountry').val("");
    }
});