if (window.location.pathname === '/freelance/demande_de_paiement') {
  $(document).on('click', '.js-no-info-redirect', function () {
    $('.backdrop').addClass('redirectAddInfo');
  })
  $(document).on('click', '.redirectAddInfo, .popin-additionnalPayment .popin-close', function() {
    window.location.href = '/freelance';
  })
  setTimeout(() => {
    $(".st-dash-new-payment").click();
  }, 1000)
}