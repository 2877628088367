import { toggleLateralMenu } from '../generalFunctions.js';

if ($(window).width() <= 767) {
    $(".new-project-create").on("click", function() {
        $(this).css("display", "none");
    });

    $(".popin-wrap.newproject .popin-close").on("click", function() {
        $(".new-project-create").css("display", "block");
    });
}

// Change height of rounded picto if he has round avatar element
if ($(window).width() <= 1480) {
    $(".js-accountReach .rounded-picto").has(".round-avatar").css("height", "30px");
}

$('.js-clickMenuDropdown').on('click', (e) => {
    if (($('body').hasClass('account-page')) && ($(window).width() <= 767)) {
        toggleLateralMenu();
    } else {
        document.querySelector('.js-dropdown-menu').classList.toggle('display-none');
        e.stopPropagation();
        $(document).on('click', (e) => {
            document.querySelector('.js-dropdown-menu').classList.add('display-none');
        })
    }
    if (!$('.st-notificationBell').hasClass('display-none')) {
        $('.st-notificationBell').addClass('display-none');
    }
})
$('.js-iconBell').on('click', (e) => {
    document.querySelector('.st-notificationBell').classList.toggle('display-none');
    if (!$('.js-dropdown-menu').hasClass('display-none')) {
        $('.js-dropdown-menu').addClass('display-none');
    }
    e.stopPropagation();
    $(document).on('click', (e) => {
        document.querySelector('.st-notificationBell').classList.add('display-none');
    })
})

$(".js-changeLocale").on("click", function(e) {
    $(".st-locales").toggle();
    e.stopPropagation();
    $(window).on("click", function() {
        $(".st-locales").hide();
    });
});

