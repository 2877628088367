import {
  formatDateSeparators,
  numberWithSpaces,
  handleErrorsAfterSubmit,
  extractFirstNumberOfString,
  translation,
  dateConversion, getUrlParams
} from '../../generalFunctions';
import { checkAllInputs, inputFrontValidation } from '../../frontendValidations';
import { popinPaymentBystep } from "../../classes/loadingClasses/loadingPopinClass";
import $ from "jquery";
let invoiceFileSaved = null;

// COPY PAYMENT LINK
export function copyLink() {

  $(document).on("click", ".js-copy-payment-link-popin", () => {
    let elem = $(".js-payment-link-popin");
    let icon = $('.js-iconCopy-popin');
    let link = elem.text();
    let temp = $("<input>");
    $("body").append(temp);
    temp.val(link).trigger("select");
    document.execCommand("copy");
    temp.remove();
    elem.html(translation('Lien copié'));
    icon.hide();
    setTimeout(() => {
      elem.html(link);
      icon.show();
    }, 2000);
  });
}

// ASK PAYMENT FORM : FACTURES : CREER SA E-FACTURE
export function paymentByStepEfacture() {
  $('.popin').animate({scrollTop: '0px'}, 300);
  $('.popin-step-error').html('');
  $('.popin-step-error').hide();
  let isValidObj = checkPaymentInfo();
  $('.accordeon-head').removeClass('active');
    $('.loader-popin').fadeIn(200);


    let idProject = $(".bills-project").data('id') ? $(".bills-project").data('id') : $(".js-getIdProject").attr('data-idproject');
    let billsCompanyName = $('.bills-society-name').val();
    let billsCompanyAddress = $('.bills-society-addr').val();
    let billsCompanyCp = $('.bills-society-cp').val();
    let billsCompanyCity = $('.bills-society-city').val();
    let billsCompanyCountry = $('.bills-society-country').val();
  
    let billsElm = [];
    $('.js-createbills-elements').each(function(idx, elm) {
      let billsElmTitle = $(elm).find('.billselm-title').text();
      let billsElmDate = formatDateSeparators($(elm).find('.billselm-date').text());
      let billsElmDesc = $(elm).find('.billselm-desc').text();
      if (!billsElmDesc || billsElmDesc.replace(/\s/g, "") === '') {
        billsElmDesc = null;
      }
      let billsElmUnit = $(elm).find('.billselm-unit').text().replace(',', '.');
      let billsElmHt = $(elm).find('.billselm-ht').text().replace(',', '.');
      let billsElmTva = $(elm).find('.billselm-tva').text().replace(',', '.');
      let unitType = $(elm).find('.billselm-unit-type').text().replace(',', '.');
      let discount = $(elm).find('.billselm-discount').text().replace(',', '.');
      let irpf = $(elm).find('.billselm-irpf').text().replace(',', '.');
      billsElm.push({title: billsElmTitle, date: billsElmDate, desc: billsElmDesc, unit: billsElmUnit, price: billsElmHt, tva: billsElmTva, unitType: unitType, irpf: irpf, discount: discount});
    });
    let isDiscountable = $('#js-switchEscompteInput').is(':checked');
    let discountRate = null;
    let discountDaysLimit = null;
    let discountTotal = null;
    if (isDiscountable) {
      if ($(".st-blocEscompteInputDate").hasClass("customActive")) {
        discountDaysLimit = $(".js-getDateEscompteInput").val();
      } else {
        discountDaysLimit = $(".js-defaultEscompteDateChoice .js-valueEscompteDate").text();
      }
      if($(".st-blocEscompteInput").hasClass("customActive")) {
        discountRate = $(".js-getNumberEscompteInput").val();
        discountTotal = $(".js-getPriceEscompteInput").text();
      } else {
        let getEscompte = $(".js-defaultEscompteChoice .js-valueEscompte").text();
        discountRate = extractFirstNumberOfString(getEscompte);
        discountTotal = $(".js-defaultEscompteChoice .js-reducePriceEscompte").text().replace("(", "").replace(")", "");
      }
    }
    let email;
    if ($('.js-autocomplete-dest').val().includes('@')) {
      email = $('.js-autocomplete-dest').val();
    }
    else {
      email = $('.js-autocomplete-dest').attr('data-id');
    }
    let billsNumber = $('.bills-nbr').val();
    let billsDelay = $('.bills-delay').val();
    let billsRef = $('.bills-ref').val();
    let billsDate = formatDateSeparators($('.bills-date-send').val());
    let billsPenality = $('.bills-penality').val();
    let billsDamage = $('.bills-damage').val();
    let legalNotice = $('.bills-mentions').val();
    let logo = logoLoaded && $('#payment_logo_personalization').is(':checked') ? logoLoaded : null;
    const formData = new FormData();
    formData.append('companyPC', billsCompanyCp);
    formData.append('companyName', billsCompanyName);
    formData.append('companyCity', billsCompanyCity);
    formData.append('companyCountry', billsCompanyCountry);
    formData.append('reference', billsRef);
    formData.append('companyAddress', billsCompanyAddress);
    formData.append('billsElm', JSON.stringify(billsElm));
    formData.append('billsDate', billsDate);
    formData.append('billsDelay', billsDelay);
    formData.append('billsPenality', billsPenality);
    formData.append('billsDamage', billsDamage);
    formData.append('billsNumber', billsNumber);
    formData.append('discountRate', discountRate);
    formData.append('discountDaysLimit', discountDaysLimit);
    formData.append('legalNotice', legalNotice);
    formData.append('isDiscountable', isDiscountable);
    formData.append(logo, logo);
    if ($('.tva-ic').length) {
      let tvaIc = $('.tva-ic').val();
      formData.append('tvaIc', tvaIc);
    }

    $.ajax({
      url: "/freelance/generer_lien_paiement",
      type: 'POST',
      data: {email: email, idProject: idProject},
      success: function (val) {
        let v = JSON.parse(val);
        if (v.status === "success") {
          let url = v.url;
          $('.js-payment-link-popin').html(url);
          formData.append("url", url);
          $.ajax({
            url: "/freelance/projet/"+idProject+"/efacture/nouvelle_facture/creation/recap",
            type: 'POST',
            data: formData,
            processData: false,
            contentType: false,
            success: function (val) {
              $('.loader-popin').fadeOut(200);
              $('.popin').css('overflow', 'auto');
              $('.recap').html('');
              let v = JSON.parse(val);
              if (v.status === 'failed') {
                $('.popin-step-error').html(v.message);
                $('.popin-step-error').show();
                $('.sstep-'+v.accordion +' .accordeon-content').show();
                popinPaymentBystep.goPrev();
              } else if (v.status === 'success') {
                let preview = '<div class="preview-bills"> <a target="_blank" href="'+v.pdf+'"><img src="'+v.preview+'" width="300" /></a></div>';
                let idEfacture = v.efactureId;
                if (v.urlDiscountedInvoice) {
                  $('.discountedInvoiceLink').attr('href', v.urlDiscountedInvoice)
                }
                $('.js-paymentbystep-sendlater').data('idefacture', idEfacture);
                $(".js-blocPopinStep3").hide();
                $('.popin-payment-bystep .js-containerBillPrevisu').html("");
                $('.popin-payment-bystep .js-containerBillPrevisu').append(preview);
                $('.popin-step-error').hide();
                popinPaymentBystep.goNext(false);
              } else {
                window.location.reload();
              }
            }
          })
        }
        else {
          window.location.reload();
        }
      }
    })

  $('.js-disabled').removeClass('st-no-btn-disabled');
};

export function generatePaymentLink() {
  let idProject = $(".bills-project").data('id') ? $(".bills-project").data('id') : $(".js-getIdProject").attr('data-idproject');
  let email;
  if ($('.js-autocomplete-dest').val().includes('@')) {
    email = $('.js-autocomplete-dest').val();
  }
  else {
    email = $('.js-autocomplete-dest').attr('data-id');
  }

  let promiseReturn = $.post("/freelance/generer_lien_paiement", {
    email: email,
    idProject: idProject
  });
  promiseReturn.then((val) => {
    let v = JSON.parse(val);
    if (v.status === "success") {
      popinPaymentBystep.goNext(false);
      $('.js-payment-link-popin').html(v.url);
    }
  })
}

$(document).on('change input', '.bills-elm-ht', function() {
  let rslt =  /^-?\d+([\,\.]\d+)?(?=$| )/.test($('.bills-elm-ht').val());
  $('.bills-elm-tva').prop("disabled", false);
  if (($('.bills-elm-ht').val() !== "") && (rslt)) {
    $('.hterror').remove();
    $('.bills-elm-ht').removeClass('error');
  } else if (($('.bills-elm-ht').val() !== "") && (!rslt)) {
    $('.bills-elm-ht').addClass('error');
    if ($('.elmerror.hterror').length <= 0) {
      $('.bills-elm-ht').next().after('<div class="cerror elmerror hterror">' + translation('Nombre invalide') + '</div>');
    }
  } else {
    $('.bills-elm-ht').addClass('error');
    if ($('.elmerror.hterror').length <= 0) {
      $('.bills-elm-ht').next().after('<div class="cerror elmerror hterror">' + translation('Veuillez remplir ce champ') + '</div>');
    }
  }
})

// ASK PAYMENT FORM : FACTURES : CREER SA E-FACTURE STEP TWO (éléments de la facture) 
$(document).on('click', '.js-btn-add-elm', function() {
  let title = $('.bills-elm-title').val();
  let date = $('.bills-elm-daterea').val();
  let desc = $(".bills-elm-desc").val();
  let unit = $('.bills-elm-unit').val();
  let ht = $('.bills-elm-ht').val();
  let tva = $('.bills-elm-tva').val();
  let rslt = $('.elm-total-ttc').text();
  let unitType = $('.bills-elm-unit-type').val();
  let irpf = $('.bills-elm-irpf').val();
  let discount = $('.bills-elm-discount').val();

  if (!irpf) {
    irpf = 0;
  }

  if (title && date && /^-?\d+([\,\.]\d+)?(?=$| )/.test(ht) && /^\d+([\,\.]\d+)?(?=$| )/.test(tva)) {

    if (unit.length && /^\d+([\,\.]\d+)?(?=$| )/.test(unit)) {
      unit =  unit.replace(',', '.');
      unit = Math.round(unit * 100 )/ 100;
    }
    else {
      unit = 1;
    }

    tva = tva.replace(',', '.');
    ht = ht.replace(',', '.');
    tva = Math.round(tva * 100 )/ 100;
    ht = Math.round(ht * 100 )/ 100;
    discount = Math.round(discount * 100 )/ 100;

    $('.elm-errors').text('');
    let idpopin = $('.popinelement-id').data('id');
    if (idpopin) {
      $('.idpopin-'+idpopin).find('.billselm-title').html(title);
      $('.idpopin-'+idpopin).find('.billselm-date').html(date);
      $('.idpopin-'+idpopin).find('.billselm-desc').html(desc);
      $('.idpopin-'+idpopin).find('.billselm-unit').html(unit);
      $('.idpopin-'+idpopin).find('.billselm-ht').html(ht);
      $('.idpopin-'+idpopin).find('.billselm-tva').html(tva);
      $('.idpopin-'+idpopin).find('.billselm-ttc').html(rslt);
      $('.idpopin-'+idpopin).find('.billselm-unit-type').html(unitType);
      $('.idpopin-'+idpopin).find('.billselm-discount').html(discount);
      $('.idpopin-'+idpopin).find('.billselm-irpf').html(irpf);
    } else {
      let id = Math.floor(Math.random() * (100000000000 - 1)) + 1;
      let elm = "<div class='js-createbills-elements idpopin-"+id+"'>"
              + "<div class='d-flex justify-content-between align-items-center'><div class='billselm-title'>"+title+"</div><div class='d-flex'><span class='billselm-ttc'>"+rslt+"</span> TTC</div><div class='js-duplicate-billselm'><img src='/build/images/copypaste.svg' alt=''></div><div class='js-edit-billselm-payment ml-3' data-id='"+id+"'><img src='/build/images/icon_edit_gris.svg' /></div><div class='js-delete-bills-elm ml-3'><img src='/build/images/icon_cross_blue.svg' /></div></div>"
              + "<div class='billselm-desc d-none'>" + desc + "</div>"
              + "<div class='billselm-date d-none'>"+date+"</div>"
              + "<div class='d-none'><div><span class='billselm-unit'>"+unit+"</span><span class='billselm-unit-type'>"+unitType+"</span><span class='billselm-discount'>"+discount+"</span><span class='ml-2 mr-2'>x</span><span class='billselm-ht'>"+ht+"</span><span class='billselm-irpf'>"+irpf+"</span><span class='ml-2 mr-2'>+</span>" + translation('TVA') + " (<span class='billselm-tva'>"+tva+"</span>%)</div></div>"
              + "</div>";

      if ($('.elm-empty .noelm').length) {
        $('.elm-empty').hide();
      }
      $(".elm-list").append(elm);
    }

    $('.add-elm').slideUp();
    let discountRate = $('.discount-percent').val();
    let amount = document.getElementsByClassName('billselm-ttc');
    let billsAmount = 0;
    $(amount).each(function(id, elm) {
      let txt = $(elm).text();
      let am = txt.replace('€', '');
      let amo = am.replace(/\s/g, '');
      billsAmount += parseFloat(amo);
    });
    let val = ((parseFloat(billsAmount) * discountRate) / 100);
    $('.discount-total').val(val);
  } else {
    if (!title) {
      $('.bills-elm-title').addClass('error');
      if ($('.elmerror.titleerror').length <= 0) {
        $('.bills-elm-title').after('<div class="cerror elmerror titleerror">' + translation('Veuillez remplir ce champ') + '</div>');
      }
    }

    if (!/^\d+([\,\.]\d+)?(?=$| )/.test(unit) && unit.length > 0) {
      $('.bills-elm-unit').addClass('error');
      if ($('.elmerror.uniterror').length <= 0) {
        $('.bills-elm-unit').after('<div class="cerror elmerror uniterror">' + translation('Nombre invalide') + '</div>');
      }
    }

    if (!date) {
      $('.bills-elm-daterea').addClass('error');
      if ($('.elmerror.dateerror').length <= 0) {
        $('.bills-elm-daterea').after('<div class="cerror elmerror dateerror">' + translation() + '</div>');
      }
    }

    if (!ht) {
      $('.bills-elm-ht').addClass('error');
      if ($('.elmerror.hterror').length <= 0) {
        $('.bills-elm-ht').next().after('<div class="cerror elmerror hterror">' + translation() + '</div>');
      }
    } else if (!/^-?\d+([\,\.]\d+)?(?=$| )/.test(ht)) {
      $('.bills-elm-ht').addClass('error');
      if ($('.elmerror.hterror').length <= 0) {
        $('.bills-elm-ht').next().after('<div class="cerror elmerror hterror">' + translation('Nombre invalide') + '</div>');
      }
    }

    if (!tva) {
      $('.bills-elm-tva').addClass('error');
      if ($('.elmerror.tvaerror').length <= 0) {
        $('.bills-elm-tva').next().after('<div class="cerror elmerror tvaerror">' + translation() + '</div>');
      }
    } else if (!/^\d+([\,\.]\d+)?(?=$| )/.test(tva)) {
      $('.bills-elm-tva').addClass('error');
      if ($('.elmerror.tvaerror').length <= 0) {
        $('.bills-elm-tva').next().after('<div class="cerror elmerror tvaerror">' + translation('Nombre invalide') + '</div>');
      }
    }

    $('.bills-elm-title').on('keyup keydown change', function() {
      if ($('.bills-elm-title').val() !== "") {
        $('.titleerror').remove();
        $('.bills-elm-title').removeClass('error');
      } else {
        $('.bills-elm-title').addClass('error'); 
        if ($('.elmerror.titleerror').length <= 0) {
          $('.bills-elm-title').after('<div class="cerror elmerror titleerror">' + translation() + '</div>');
        }
      }
    })

    $('.bills-elm-daterea').on('keyup keydown change', function(e) {
      if ($('.bills-elm-daterea').val() !== "") {
        $('.dateerror').remove();
        $('.bills-elm-daterea').removeClass('error');
      } else {
        $('.bills-elm-daterea').addClass('error'); 
        if ($('.elmerror.dateerror').length <= 0) {
          $('.bills-elm-daterea').next().after('<div class="cerror elmerror dateerror">' + translation() + '</div>');
        }
      }
    })

    $('.bills-elm-unit').on('keyup keydown change', function() {
      let rslt =  /^\d+([\,\.]\d+)?(?=$| )/.test($('.bills-elm-unit').val());
      if (($('.bills-elm-unit').val() !== "") && (rslt)) {
        $('.uniterror').remove();
        $('.bills-elm-unit').removeClass('error');
      } else if (($('.bills-elm-unit').val() !== "") && (!rslt)) {
        $('.bills-elm-unit').addClass('error');
        if ($('.elmerror.uniterror').length <= 0) {
          $('.bills-elm-unit').after('<div class="cerror elmerror uniterror">' + translation('Nombre invalide') + '</div>');
        }
      } else {
        $('.bills-elm-unit').addClass('error');
        if ($('.elmerror.uniterror').length <= 0) {
          $('.bills-elm-unit').after('<div class="cerror elmerror uniterror">' + translation() + '</div>');
        }
      }
    })

    $('.bills-elm-tva').on('keyup keydown change', function() {
      let rslt =  /^\d+([\,\.]\d+)?(?=$| )/.test($('.bills-elm-tva').val());
      if (($('.bills-elm-tva').val() !== "") && (rslt)) {
        $('.tvaerror').remove();
        $('.bills-elm-tva').removeClass('error');
      } else if (($('.bills-elm-tva').val() !== "") && (!rslt)) {
        $('.bills-elm-tva').addClass('error');
        if ($('.elmerror.tvaerror').length <= 0) {
          $('.bills-elm-tva').next().after('<div class="cerror elmerror tvaerror">' + translation('Nombre invalide') + '</div>');
        }
      } else {
        $('.bills-elm-tva').addClass('error');
        if ($('.elmerror.tvaerror').length <= 0) {
          $('.bills-elm-tva').next().after('<div class="cerror elmerror tvaerror">' + translation() + '</div>');
        }
      }
    })
  }
});

$(document).on('click', '.js-restore-legal-notices', function() {
  let latePenalty = $('.bills-penality').val();
  let penaltyAmount = $('.bills-damage').val();
  let invoiceDate = $('.bills-date-send').val()
  let delay = $('.bills-delay').val();
  if ($(this).attr('data-country') === 'ES') {
    $('#legalNotices').val("Sus datos de carácter personal han sido recogidos de acuerdo con lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de los mismos.\n Le ponemos en conocimiento que estos datos se encuentran almacenados en un fichero propiedad de la empresa SOAN. De acuerdo con la Ley anterior, tiene derecho a ejercer los derechos de acceso, rectificación, cancelación, limitación, oposición y portabilidad de manera gratuita mediante correo electrónico a: hola@soan-solutions.io")
  }
  else {
    $('#legalNotices').val("Cette facture a été émise le " + invoiceDate +". Celle-ci doit être réglée sous " + delay + " jour(s) à compter de cette date. Passé ce délai, une pénalité de retard de " + latePenalty + "% sera appliquée, ainsi qu'une indemnité forfaitaire de " + penaltyAmount + " € due au titre des frais de recouvrement.");
  }
})

$(document).on('input', '.phonemask', function() {
  let val = $(this).val();
  let value = val.replace(/[^0-9+-]/g, '');
  $(this).val(value);
})

$(document).on('input', '.amountmask', function() {
  let val = $(this).val();
  let value = val.replace(/[^0-9.,]/g, '');
  $(this).val(value);
})

$(document).on('input', '.amountmaskneg', function() {
  let val = $(this).val();
  let value = val.replace(/[^0-9.,-]/g, '');
  $(this).val(value);
})

// ASK PAYMENT FORM : FACTURES : CREER SA E-FACTURE STEP THREE (envoyer plus tard) 
export function sendPaymentEfacture() {
  $(document).on('click', '.js-send-payment-efacture', function(e) {
    $('.popin-step-error').children("div:first").remove();
    $('.popin-step-error').hide();
    $('.loader-popin').fadeIn(200);
    $('.popin').css('overflow', 'hidden');
    let delayedInvoice = null;
    let errors = 0;
    // FOR MAIL CONTENT AND OBJECT VERIFICATIONS
    let emailBody;
    if ($(".checked3x").is(":checked")) {
      emailBody = $('.trumbowyg-editor').html();
    }
    else {
      $('.trumbowyg-editor .js-bloc3xMail').remove();
      emailBody = $('.trumbowyg-editor').html();
    }
    if ($(".checkedEscompte").is(":checked")) {
      emailBody = $('.trumbowyg-editor').html();
    }
    else {
      $(".trumbowyg-editor .js-blocEscompteMail").remove();
      emailBody = $('.trumbowyg-editor').html();
    }
    const emailObject = $('.js-payment-mail-object').val();
    let div = document.createElement("div");
    div.innerHTML = emailBody;
    let contentWhitoutTag = div.textContent || div.innerText || "";
    $('.popin').animate({scrollTop: '0px'}, 300);
    if ($('#js-demandLater').is(':checked')) {
      let sendLater = ($('.js-createdLater').val()).split("/");
      sendLater = new Date(sendLater[2], sendLater[1] - 1, sendLater[0]);
      if (isNaN(sendLater)) {
        $('.popin-step-error').append('<div class="error-sendlater">' + translation('Veuillez renseigner une date') + '</div>');
        $('.popin-step-error').show();
        $('.js-createdLater').addClass('error');
        handleErrorsAfterSubmit([".js-createdLater"]);
        errors = 1;
      } else if (sendLater <= new Date()) {
        $('.popin-step-error').append('<div class="error-sendlater">' + translation('date ultérieure') + '</div>');
        $('.popin-step-error').show();
        $('.js-createdLater').addClass('error');
        handleErrorsAfterSubmit([".js-createdLater"]);
        errors = 1;
      } else {
        delayedInvoice = formatDateSeparators($('.js-createdLater').val());
        errors = 0;
      }
    }
    if (contentWhitoutTag.trim() === "" || !emailObject) {
      $('.popin-step-error').append('<div class="error-sendlater">' + translation('objet et message obligatoires') + '</div>');
      $('.popin-step-error').show();  
    }
    if ($('.popin-step-error').children().length > 0 || errors == 1) {
      $('.popin-step-error').html(translation('date ultérieure'));
      $('.popin-step-error').show();
      $('.loader-popin').fadeOut(200);
      $('.popin').css('overflow', 'auto');
    } else {
      let projectId = $(".bills-project").data('id') ? $(".bills-project").data('id') : $(this).data('idproject');;
      let efactureId = $('.js-paymentbystep-sendlater').data('idefacture');
      $('.js-bills-relaunch').data('projectid', projectId);
      let promiseReturn = $.post("/freelance/projet/"+projectId+"/efacture/"+efactureId+"/enregistrer");
      promiseReturn.then(function(val) {
        let v = JSON.parse(val);
        if (v.status === 'failed') {
          $('.popin-step-error').html(v.message);
          $('.popin-step-error').show();
          $('.soancontainer').animate({ scrollTop: $(".errors").offset().top }, 1000);
          $('.loader-popin').fadeOut(200);
          $('.popin').css('overflow', 'auto');
        } else {
          let titleFacture = $(".popin-payment-bystep .bills-subject").val();
          let dateSendLater = $(".js-createdLater").val();
          if ($('#js-demandLater').is(':checked')) {
            $('.payment-confirmation-mesage').html(translation('Facture') + " <strong>" + titleFacture + "</strong> " + translation('bien programmés et sera envoyée le') + "<strong> " + dateSendLater + "</strong>");
          } else {
            $('.payment-confirmation-mesage').html(translation('Facture') + " <strong>" + titleFacture + "</strong> " + translation("a bien été envoyée") + " !");
          }
          let sendMail = "true";
          if (!$('#js-mailCustomInput').is(":checked")) {
            sendMail = "false"
          }

          let copyMail;
          if (!$('#js-mailCopyInput').is(":checked")){
            copyMail = "false";
          }else{
            copyMail = "true";
          }

          let billsUser = $('.js-autocomplete-dest').attr('data-id') || $('.js-autocomplete-dest').val();
          let billsSubject = $('.bills-subject').val();
          let isMultiPayment = $("#js-switch3xInput").is(":checked");
          let promiseReturn = $.post('/freelance/projet/'+projectId+'/nouveau_paiement/efacture/'+efactureId, {emailObject: emailObject, emailBody: emailBody, sender: billsUser, delayedInvoice: delayedInvoice, subject: billsSubject, isMultiPayment: isMultiPayment, sendMail: sendMail, copyMail: copyMail});
          promiseReturn.then(function(val) {
            let v = JSON.parse(val);
            if (v.status === 'failed') {
              $('.popin-step-error').html(v.message);
              $('.popin-step-error').show();
              $('.loader-popin').fadeOut(200);
              $('.popin').css('overflow', 'auto');
              if (v.step == 1) {
                $('.popin-step-content-3').removeClass('active');
                $('.popin-step-content-1').addClass('active');
                $('.step-header-3').removeClass('active');
                $('.step-header-1').addClass('active');
                $('.step-header-1').removeClass('valid');
              } else if (v.step == 3) {
                $('.popin-step-content-2').addClass('active');
                $('.popin-step-content-3').removeClass('active');
                $('.js-paymentbystep-back-step2').removeClass('back-efacture-payment');
                $('.step-header-2').addClass('active');
                $('.step-header-3').removeClass('active');
                $('.js-paymentbystep-sendfacture').removeClass('js-send-payment-efacture');
                $('.step-header-2').removeClass('valid');
                $('.create-bills').hide();
                $('.recap-content').show();
                $('.popin-step-error').hide();
              }
              popinPaymentBystep.goPrev();
            } else {
              if (sendMail === "false") {
                return(
                  window.location.href = "/freelance/projet/" + projectId + "/paiement/" + v.paymentId + "/relance"
                )
              }
              $('.popin-step-content-4').addClass('active');
              $('.popin-payment-bystep .popin-step-header').hide();
              $('.popin-payment-bystep .popin-title').hide();
              $('.popin-payment-bystep hr').hide();
              $('.popin-payment-bystep .popin-close-flex').hide();
              $('.popin-step-content-3').removeClass('active');
              $('.js-bills-relaunch').data('paymentid', v.paymentId);
              $('.backdrop').removeClass('js-active-sureuback');
              $('.loader-popin').fadeOut(200);
              $('.popin').css('overflow', 'auto');
              popinPaymentBystep.goNext(false);
            }
            });
    
            promiseReturn.catch(function(err) {
              window.location.reload();
            });
        }
      });
  
      promiseReturn.catch(function(err) {
        window.location.reload();
      });
    }
    e.stopImmediatePropagation();
  });
}

export function sendPaymentFacture() {
  $(document).on('click', '.js-send-payment-facture', function(e) {
    $('.popin-step-error').children("div:first").remove();
    $('.popin-step-error').hide();
    // FOR SENDLATER VERIFICATIONS
    // let sendLater = formatDateSeparators($('.js-date-sendlater').val());
    // const dateObjectSendLater = new Date(parseInt(sendLater.split('-')[2]), parseInt(sendLater.split('-')[1]) - 1, parseInt(sendLater.split('-')[0]));
    // FOR MAIL CONTENT AND OBJECT VERIFICATIONS
    let emailBody;
    if ($(".checked3x").is(":checked")) {
      emailBody = $('.trumbowyg-editor').html();
    }
    else {
      $('.trumbowyg-editor .js-bloc3xMail').remove();
      emailBody = $('.trumbowyg-editor').html();
    }
    if ($(".checkedEscompte").is(":checked")) {
      emailBody = $('.trumbowyg-editor').html();
    }
    else {
      $(".trumbowyg-editor .js-blocEscompteMail").remove();
      emailBody = $('.trumbowyg-editor').html();
    }
    const emailObject = $('.js-payment-mail-object').val();
    let div = document.createElement("div");
    div.innerHTML = emailBody;
    let contentWhitoutTag = div.textContent || div.innerText || "";
    let showSendLater = $('.js-show-sendlater').is(':checked');
    $('.popin').animate({scrollTop: '0px'}, 300);
    // if (dateObjectSendLater <= new Date() && showSendLater) {
    //   if (!$('.error-sendlater').length) {
    //     $('.popin-step-error').append('<div class="error-sendlater">' + translation('date ultérieure') + '</div>');
    //     $('.popin-step-error').show();
    //   }
    // } else if
    if (contentWhitoutTag.trim() === "" || !emailBody) {
      $('.popin-step-error').append('<div class="error-sendlater">' + translation('objet et message obligatoires') + '</div>');
      $('.popin-step-error').show();  
    } else {
      $(".loader-popin").fadeIn();
      $('.popin').css('overflow', 'hidden');
      let formData = new FormData();
      let submitMethod = 'POST';
      formData.append("emailObject", emailObject);
      formData.append("emailBody", emailBody);
      let invoice = invoiceFileSaved;
      formData.append("bills", invoice[0]);
      let ref = $('.my-bills-ref').val();
      formData.append('ref', ref);
      let invoiceDate = formatDateSeparators($('.my-bills-date-send').val());
      formData.append("invoiceDate", invoiceDate);
      let daysLimit = $('.my-bills-delay').val();
      formData.append("daysLimit", daysLimit);
      let projectId = $(".bills-project").data('id') ? $(".bills-project").data('id') : $(this).data('idproject');
      $('.js-bills-relaunch').data('projectid', projectId);
      formData.append("projectId", projectId);
      let amount = $('.my-bills-amount').val();
      formData.append("amount", amount);
      let amountHT = $('.my-bills-amountHT').val();
      formData.append("amountHT", amountHT);
      let subject = $('.bills-subject').val();
      formData.append("subject", subject);
      let sender = $('.js-autocomplete-dest').attr('data-id') || $('.js-autocomplete-dest').val();
      formData.append("sender", sender);
      let sendMail = "true";

      if (!$('#js-mailCustomInput').is(":checked")) {
        sendMail = "false"
      }
      formData.append("sendMail", sendMail);

      let copyMailEl = $('#js-mailCopyInput');
      let copyMail;

      if (!copyMailEl.is(":checked")){
        copyMail = "false";
      }else{
        copyMail = "true";
      }

      formData.append("copyMail", copyMail);

      let discount = $("#js-switchEscompteInput").is(":checked");
      let discountRate;
      let discountLimit;
      if ($(".st-blocEscompteInput").hasClass("customActive")) {
          discountRate = $(".js-getNumberEscompteInput").val();
      }
      else {
        let getEscompteNumber = $(".js-defaultEscompteChoice .js-valueEscompte").text();
        discountRate = getEscompteNumber.match(/(\d+)/);
      }
      if ($(".st-blocEscompteInputDate").hasClass("customActive")) {
        discountLimit = $(".js-getDateEscompteInput").val();
      }
      else {
        discountLimit = $(".js-defaultEscompteDateChoice span").text()[0];
      }

      if (discount) {
        formData.append('discountRate', discountRate);
        formData.append('discountLimitDay', discountLimit);
      }
      let delayedInvoice = formatDateSeparators($('.js-createdLater').val());
      let isLater = $('#js-demandLater').is(':checked');
      if (isLater) {
        formData.append("delayedInvoice", delayedInvoice);
      }
      let companyName = $('.my-bills-companyname').val();
      formData.append('companyName', companyName);
      let billsNumber = $('.my-bills-number').val();
      formData.append('billsNumber', billsNumber);
      let isMultiPayment = $("#js-switch3xInput").is(":checked");
      formData.append("isMultiPayment", isMultiPayment);
      
      $.ajax({
        url:'/freelance/projet/'+projectId+'/nouveau_paiement/facture',
        data: formData,
        processData: false,
        contentType: false,
        type: submitMethod,
        success: function(val){
          let v = JSON.parse(val);
          if (v.status === 'success') {
            if (sendMail === "false") {
              return(
                window.location.href = "/freelance/projet/" + projectId + "/paiement/" + v.paymentId + "/relance"
              )
            }
            $(".loader-popin").fadeOut("1000");
            $('.popin').css('overflow', 'auto');
            $('.popin-step-content-4').addClass('active');
            $('.backdrop').removeClass('js-active-sureuback');
            $('.popin-payment-bystep .popin-step-header').hide();
            $('.popin-payment-bystep .popin-title').hide();
            $('.popin-payment-bystep hr').hide();
            $('.popin-payment-bystep .popin-close-flex').hide();
            $('.popin-step-content-3').removeClass('active');
            $('.js-bills-relaunch').data('paymentid', v.paymentId);
            let titleFacture = $(".popin-payment-bystep .bills-subject").val();
            let dateSendLater = $(".js-createdLater").val();
            if (isLater) {
              $('.payment-confirmation-mesage').html(translation('Facture') + " <strong>" + titleFacture + "</strong> " + translation('bien programmés et sera envoyée le') + " <strong> " + dateSendLater + "</strong>");
            }
            else {
              $('.payment-confirmation-mesage').html(translation('Facture') + " <strong>" + titleFacture + "</strong> " + translation('a bien été envoyée') + " !");
            }
            popinPaymentBystep.goNext(false);
          }
          else {
            $(".loader-popin").fadeOut("1000");
            $('.popin').css('overflow', 'auto');
            $('.popin-step-error').show();
            $('.popin-step-error').html(v.message);
            if (v.step == 1) {
              $('.popin-step-content-3').removeClass('active');
              $('.popin-step-content-1').addClass('active');
              $('.step-header-3').removeClass('active');
              $('.step-header-1').addClass('active');
              $('.step-header-1').removeClass('valid');
            } else if (v.step == 2 ) {
              if (v.isEfacture == false) {
                $('.popin-step-content-3').removeClass('active');
                  $('.step-header-3').removeClass('active');
                  $('.popin-step-content-2').addClass('active');
                  $('.js-paymentbystep-back-step2').removeClass('back-facture');
                  $('.js-paymentbystep-sendfacture').remove('js-send-payment-facture');
                  $('.step-menu-facture').addClass('active');
                  $('.step-header-2').addClass('active');
                  $('.step-header-2').removeClass('valid');
                  $('.step-menu-efacture').removeClass('active');
                  $('.menu-facture').addClass('active');
                  $('.menu-efacture').removeClass('active');
              }
            }
            popinPaymentBystep.goPrev();
            popinPaymentBystep.goPrev();
          }
        },
        error: function(err) {
          window.location.reload();
        }
      });
    }
    e.stopImmediatePropagation();
  });
}

// MANAGE DRAG AND DROP + LOADING FOR BILLS
export function dragAndDrop() {
  setTimeout(() => {
    if ($('#dropzone-payment').length) {
      let droppedFiles = false;
    
      $('#dropzone-payment').on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
        e.preventDefault();
        e.stopPropagation();
      })
      .on('dragover dragenter', function() {
        $('#dropzone-payment').addClass('is-dragover');
      })
      .on('dragleave dragend drop', function() {
        $('#dropzone-payment').removeClass('is-dragover');
      })
      .on('drop', function(e) {
        ocr(e, e.originalEvent.dataTransfer.files);
      });
    }
  }, 0)
}

const ocr = (e, files) => {
  $('.loader-popin').fadeIn();
  e.preventDefault();

  let data = new FormData();
  let invoiceNumber;
  let totalHt;
  let totalTtc;
  let currency;
  let due_date;
  let invoiceDate;

  if (files.length > 0) {
    data.append("document", files[0], files[0].name);
    let reader = new FileReader();
    reader.readAsBinaryString(files[0]);
    reader.onloadend = function(){
      let count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;

      if (count <= 5 ) {
        $.ajax({
          type: "POST",
          beforeSend: function (request) {
            request.setRequestHeader("Authorization", "Token 47145103e698afbbb1133701cbed0e17")
          },
          url: "https://api.mindee.net/v1/products/mindee/invoices/v2/predict",
          data: data,
          processData: false,
          contentType: false,
          timeout: 7500,
          success: function (val) {
            let document = val.document.inference.prediction;

            invoiceNumber = document.invoice_number.value ? document.invoice_number.value : null;
            totalHt = document.total_excl.value ? document.total_excl.value : null ;
            totalTtc = document.total_incl.value ? document.total_incl.value : null;
            currency = document.locale.currency ? document.locale.currency : null;
            due_date = document.due_date.value ? document.due_date.value : null;
            invoiceDate = document.date.value ? document.date.value : null;

            $('.my-bills-number').val(invoiceNumber);
            $('.my-bills-amountHT').val(totalHt);
            $('.my-bills-amount').val(totalTtc);
            $('.my-bills-date-send').val(dateConversion(invoiceDate));

            let fileName = files[0].name;
            if (fileName.split(".")[fileName.split(".").length - 1] === "pdf") {
              $('.elmerror.fileformaterror').remove();
              $('.js-drop').hide();
              $('.drop-file-uploaded').show();
              let newFileUploaded = `<div class="drop-file">
              <div class="drop-file-icon"><img src="/build/images/mini_folder.svg" alt=" " height="40" width="30" /></div>
              <div class="drop-file-name">${fileName}</div>
              <div class="drop-file-delete"><img src="/build/images/crossred.svg" height="20" width="20" /></div>
              </div>`;
              $('.drop-file-uploaded').append(newFileUploaded);
              invoiceFileSaved = files;
              inputFrontValidation();
            } else {
              if ($('.elmerror.fileformaterror').length <= 0) {
                $('.js-drop').after('<div class="cerror elmerror fileformaterror">' + translation('Le fichier doit être au format PDF') + '</div>');
              }
            }
            const inputs = $('.menu-facture.active').find('.input-front-validation');
            const button = $('.js-submit-efacture');
            checkAllInputs(inputs, button);
            $('.loader-popin').fadeOut();
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            let fileName = files[0].name;
            if (fileName.split(".")[fileName.split(".").length - 1] === "pdf") {
              $('.elmerror.fileformaterror').remove();
              $('.js-drop').hide();
              $('.drop-file-uploaded').show();
              let newFileUploaded = `<div class="drop-file">
              <div class="drop-file-icon"><img src="/build/images/mini_folder.svg" alt=" " height="40" width="30" /></div>
              <div class="drop-file-name">${fileName}</div>
              <div class="drop-file-delete"><img src="/build/images/crossred.svg" height="20" width="20" /></div>
              </div>`;
              $('.drop-file-uploaded').append(newFileUploaded);
              invoiceFileSaved = files;
              inputFrontValidation();
            } else {
              if ($('.elmerror.fileformaterror').length <= 0) {
                $('.js-drop').after('<div class="cerror elmerror fileformaterror">' + translation('Le fichier doit être au format PDF') + '</div>');
              }
            }
            const inputs = $('.menu-facture.active').find('.input-front-validation');
            const button = $('.js-submit-efacture');
            checkAllInputs(inputs, button);
            $('.loader-popin').fadeOut();
          }
        });
      }
      else {
        let fileName = files[0].name;
        if (fileName.split(".")[fileName.split(".").length - 1] === "pdf") {
          $('.elmerror.fileformaterror').remove();
          $('.js-drop').hide();
          $('.drop-file-uploaded').show();
          let newFileUploaded = `<div class="drop-file">
              <div class="drop-file-icon"><img src="/build/images/mini_folder.svg" alt=" " height="40" width="30" /></div>
              <div class="drop-file-name">${fileName}</div>
              <div class="drop-file-delete"><img src="/build/images/crossred.svg" height="20" width="20" /></div>
              </div>`;
          $('.drop-file-uploaded').append(newFileUploaded);
          invoiceFileSaved = files;
          inputFrontValidation();
        } else {
          if ($('.elmerror.fileformaterror').length <= 0) {
            $('.js-drop').after('<div class="cerror elmerror fileformaterror">' + translation('Le fichier doit être au format PDF') + '</div>');
          }
        }
        const inputs = $('.menu-facture.active').find('.input-front-validation');
        const button = $('.js-submit-efacture');
        checkAllInputs(inputs, button);
        $('.loader-popin').fadeOut();
      }
    }


  }
  else {
    $('.js-error-message').html(translation("Erreur import facture"));
    $('.loader-popin').fadeOut();
  }
}
$(document).on('change', '#dropzone-payment .file-facture', function(e) {
  ocr(e, e.target.files);
});

$(document).on('click', '.drop-file-delete', function(e) {
  $('.js-drop').show();
  $('.drop-file-uploaded').hide();
  $('#dropzone-payment .file-facture').val('');
  invoiceFileSaved = null;
  $('.drop-file-uploaded').html('');
  $('.file-facture').val('');
  const inputs = $('.menu-facture.active').find('.input-front-validation');
  const button = $('.js-paymentbystep-efacture-gostep3');
  checkAllInputs(inputs, button);
});


// MANAGE TO CANCEL PREVIOUS EVENT LISTENER WHEN USER CLICK ON "ANNULER" OR '+' WHEN ADDING ELEMENT
$('.js-btn-del-elm').on('click', function() {
  $('.bills-elm-title').off();
  $('.bills-elm-unit').off();
  $('.bills-elm-ht').off();
  $('.bills-elm-tva').off();
})

$('.js-show-add-elm img').on('click', function() {
  $('.bills-elm-title').off();
  $('.bills-elm-unit').off();
  $('.bills-elm-ht').off();
  $('.bills-elm-tva').off();
})


// FUNCTIONS FOR ASK PAYMENT FORM
function checkPaymentInfo() {
  let isValidateStep1 = checkStepOne();
  let isValidateStep2 = checkStepTwo();
  let isValidateStep3 = checkStepThree();
  let isValidateStep4 = checkStepFour();
  let isValid = isValidateStep1.isValid && isValidateStep2.isValid && isValidateStep3.isValid && isValidateStep4.isValid;
  return {isValid: isValid, step1: isValidateStep1, step2: isValidateStep2, step3: isValidateStep3, step4: isValidateStep4}
}


function checkStepOne() {
  let companyName = $('.bills-society-name').val();
  let companyAddr = $('.bills-society-addr').val();
  let companyCp = $('.bills-society-cp').val();
  let companyCity = $('.bills-society-city').val();
  let companyCountry = $('.bills-society-country').val();
  let isValid = companyName && companyAddr && companyCp && companyCity && companyCountry;  
  if (!companyName) {
    $('.bills-society-name').addClass('error');
  } else {
    $('.bills-society-name').removeClass('error');
  }
  if (!companyAddr) {
    $('.bills-society-addr').addClass('error');
  } else {
    $('.bills-society-addr').removeClass('error');
  } 
  if (!companyCp) {
    $('.bills-society-cp').addClass('error');
  } else {
    $('.bills-society-cp').removeClass('error');
  } 
  if (!companyCity) {
    $('.bills-society-city').addClass('error');
  } else {
    $('.bills-society-city').removeClass('error');
  } 
  if (!companyCountry) {
    $('.bills-society-country').addClass('error');
  } else {
    $('.bills-society-country').removeClass('error');
  } 
  return {isValid: isValid, companyName: companyName, companyAddr: companyAddr, companyCp: companyCp, companyCity: companyCity, companyCountry: companyCountry};
}


function checkStepTwo() {
  let elm = $('.js-createbills-elements').length;
  let isValid = elm > 0 ? true : false;
  return {isValid: isValid, elm: elm};
}


function checkStepThree() {
  let billsNbr = $('.bills-nbr').val();
  let billsDelay = $('.bills-delay').val();
  let billsDate = formatDateSeparators($('.bills-date-send').val());
  if (!billsNbr) {
    $('.bills-nbr').addClass('error');
  } else {
    $('.bills-nbr').removeClass('error');
  } 
  if (!billsDelay) {
    $('.bills-delay').addClass('error');
  } else {
    $('.bills-delay').removeClass('error');
  } 
  if (!billsDate) {
    $('.bills-date-send').addClass('error');
  } else {
    $('.bills-date-send').removeClass('error');
  }

  let isValid = billsNbr && billsDelay && billsDate;
  return {isValid: isValid, billsNbr: billsNbr, billsDelay: billsDelay, billsDate: billsDate };
}


function checkStepFour() {
  let billsPenality = $('.bills-penality').val();
  if (!billsPenality) {
    $('.bills-penality').addClass('error');
  } else {
    $('.bills-penality').removeClass('error');
  }
  let billsDamage = $('.bills-damage').val();
  if (!billsDamage) {
    $('.bills-damage').addClass('error');
  } else {
    $('.bills-damage').removeClass('error');
  }
  let billsMentions = $('.bills-mentions').val();
  let isValid = billsPenality && billsDamage;
  return {isValid: isValid, billsPenality: billsPenality, billsDamage: billsDamage, billsMentions: billsMentions};
}


// E-FACTURE
$(".js-btn-create-preview").on("click", function() {
  $('.popin').animate({scrollTop: '0px'}, 300);
  $('.popin-step-error').html("");
  $('.popin-step-error').show();

  let isValidObj = checkPaymentInfo();
  $('.accordeon-head').removeClass('active');

  let billsCompanyName = $('.bills-society-name').val();
  let billsCompanyAddress = $('.bills-society-addr').val();
  let billsCompanyCp = $('.bills-society-cp').val();
  let billsCompanyCity = $('.bills-society-city').val();
  let billsCompanyCountry = $('.bills-society-country').val();
  let billsNumber = $('.bills-nbr').val();
  let billsDelay = $('.bills-delay').val();
  let billsRef = $('.bills-ref').val();
  let billsDate = formatDateSeparators($('.bills-date-send').val());
  let billsPenality = $('.bills-penality').val();
  let billsDamage = $('.bills-damage').val();

  $('.loader-popin').fadeIn(200);
  let idProject = $(this).data('idproject');

  let billsElm = [];
    $('.js-createbills-elements').each(function(idx, elm) {
      let billsElmTitle = $(elm).find('.billselm-title').text();
      let billsElmDate = formatDateSeparators($(elm).find('.billselm-date').text());
      let billsElmDesc = $(elm).find('.billselm-desc').text();
      if (!billsElmDesc || billsElmDesc.replace(/\s/g, "") === '') {
        billsElmDesc = null;
      }
      let billsElmUnit = $(elm).find('.billselm-unit').text().replace(',', '.');
      let billsElmHt = $(elm).find('.billselm-ht').text().replace(',', '.');
      let billsElmTva = $(elm).find('.billselm-tva').text().replace(',', '.');
      let unitType = $(elm).find('.billselm-unit-type').text().replace(',', '.');
      let discount = $(elm).find('.billselm-discount').text().replace(',', '.');
      let irpf = $(elm).find('.billselm-irpf').text().replace(',', '.');
      billsElm.push({title: billsElmTitle, date: billsElmDate, desc: billsElmDesc, unit: billsElmUnit, price: billsElmHt, tva: billsElmTva, unitType: unitType, irpf: irpf, discount: discount});
    });
    let isDiscountable = $('#payment_billsdiscount').is(':checked');
    let discountRate = null;
    let discountDaysLimit = null;
    let discountTotal = null;
  if (isDiscountable) {
    discountRate = $('.discount-percent').val();
    discountDaysLimit = $('.discount-days').val();
    discountTotal = $('.discount-total').val();
  }

  let legalNotice = $('.bills-mentions').val();
  let logo = logoLoaded && $('#payment_logo_personalization').is(':checked') ? logoLoaded : null;

  const formData = new FormData()
  formData.append('companyPC', billsCompanyCp);
  formData.append('companyName', billsCompanyName);
  formData.append('companyCity', billsCompanyCity);
  formData.append('companyCountry', billsCompanyCountry);
  formData.append('reference', billsRef);
  formData.append('companyAddress', billsCompanyAddress);
  formData.append('billsElm', JSON.stringify(billsElm));
  formData.append('billsDate', billsDate);
  formData.append('billsDelay', billsDelay);
  formData.append('billsPenality', billsPenality);
  formData.append('billsDamage', billsDamage);
  formData.append('billsNumber', billsNumber);
  formData.append('discountRate', discountRate);
  formData.append('discountDaysLimit', discountDaysLimit);
  formData.append('legalNotice', legalNotice);
  formData.append('isDiscountable', isDiscountable);
  formData.append("qrCode", "false");
  formData.append(logo, logo);

  if ($('.tva-ic').length) {
    let tvaIc = $('.tva-ic').val();
    formData.append('tvaIc', tvaIc);
  }

  let promiseReturn = $.ajax("/freelance/projet/"+idProject+"/efacture/nouvelle_facture/creation/recap", {
    method: "POST",
    data: formData,
    processData: false,
    contentType: false
  })

  promiseReturn.then(function(val) {
    $('.loader-popin').fadeOut(200);
    $('.recap').html('');
    let v = JSON.parse(val);
    if (v.status === 'failed') {
      $('.popin-step-error').html(v.message);
      $('.popin-step-error').show();
      $('.sstep-'+v.accordion +' .accordeon-content').show();
    } else if (v.status === 'success') {
      let preview = '<div class="preview-bills"> <a target="_blank" href="'+v.pdf+'"><img src="'+v.preview+'" width="300" /></a></div>';
      let idEfacture = v.efactureId;
      $(".js-btn-create-preview").data('idefacture', idEfacture);
      $('.recap').append(preview);
      $('.recap-content').show();
      $('.popin-efacture .popin-content').hide();
    } else {
      // window.location.reload();
    }
  });

  promiseReturn.catch(function(err) {
    // window.location.reload();
  });
});

if (getUrlParams(window.location.href)["toPay"]) {
  let id = getUrlParams(window.location.href)["toPay"];
  setTimeout(() => {
    $('[data-paymentid="'+id+'"]').trigger("click");
  }, 500);
}
// MANAGE ACCORDEONS (change black color to green when well filled) ON FACTURES : CREER SA E-FACTURE
$(document).on('blur', '.bills-society-name, .bills-society-addr, .bills-society-cp, .bills-society-city, .bills-society-country', function() {
  let isValidObj = checkStepOne();
  if (isValidObj.isValid) {
    $('.sstep-1').addClass('valid');
    $('.sstep-1.valid .accordeon-img img').attr('src', '/build/images/icon_dropdown_green.svg');
  }
  else {
    $('.sstep-1').removeClass('valid');
    $('.sstep-1 .accordeon-img img').attr('src', '/build/images/icon_dropdown.svg');
  }
});

$(document).on('DOMSubtreeModified', '.elm-list', function(){
  let isValidObj = checkStepTwo();
  if (isValidObj.isValid) {
    $('.sstep-2').addClass('valid');
    $('.sstep-2.valid .accordeon-img img').attr('src', '/build/images/icon_dropdown_green.svg');
  } else {
    $('.sstep-2').removeClass('valid');
    $('.sstep-2 .accordeon-img img').attr('src', '/build/images/icon_dropdown.svg');
  }
});

$(document).on('blur', '.bills-nbr, .bills-delay, .bills-ref, .bills-date-send', function() {
  let isValidObj = checkStepThree();
  if (isValidObj.isValid) {
    $('.sstep-3').addClass('valid');
    $('.sstep-3.valid .accordeon-img img').attr('src', '/build/images/icon_dropdown_green.svg');
  } else {
    $('.sstep-3').removeClass('valid');
    $('.sstep-3 .accordeon-img img').attr('src', '/build/images/icon_dropdown.svg');
  }
});

$(document).on('blur', '.bills-penality, .bills-damage, .bills-mentions', function() {
  let isValidObj = checkStepFour();
  if (isValidObj.isValid) {
    $('.sstep-4').addClass('valid');
    $('.sstep-4.valid .accordeon-img img').attr('src', '/build/images/icon_dropdown_green.svg');
  } else {
    $('.sstep-4').removeClass('valid');
    $('.sstep-4 .accordeon-img img').attr('src', '/build/images/icon_dropdown.svg');
  }
});

// AUTOCOMPLETE PAYMENT POPIN "DESTINATAIRE"
$(document).on('input', '.js-autocomplete-dest', function() {
  var val = $(this).val();
  let autocompleteArray = $(this).data('senders-list');
  $(this).removeAttr('data-id');
  if (val) {
    if (!Array.isArray(autocompleteArray)) {
      autocompleteArray = JSON.parse(autocompleteArray);
    }
    var matching = $.grep(autocompleteArray, function(v) {
      if ((v.name && v.name.toLowerCase().includes(val.toLowerCase())) || (v.email && v.email.toLowerCase().includes(val.toLowerCase())) || (v.usedName && v.usedName.toLowerCase().includes(val.toLowerCase()))) {
        return true;
      }
      return false;
    });
    if (matching) {
      let choice = "";
      $.each(matching, function(i, v) {
        if (v.name != null && v.img != null) {
          choice += "<div class='dest-item' data-info='" + JSON.stringify(v) + "' data-id='" + v.email + "'><div class='dest-img mr-2'>" + v.img + "</div><div class='dest-info'><div class='dest-name font-weight-bold'>" + v.name + "</div><div class='dest-email'>" + v.email + "</div></div></div>";
        } else {
          choice += "<div class='dest-item'><div class='dest-info'><div class='dest-email font-weight-bold'>" + v.email + "</div></div></div>";
          }
      });

      $('.autocomplete-dist').html(choice);
    }
    else {
      $('.autocomplete-dist').html('');
    }
  } else {
    $('.autocomplete-dist').html('');
  }
});  

// AUTOCOMPLETE PAYMENT POPIN "DOSSIER"
$(document).on('input', '.js-autocomplete-project', function() {
  $('.js-create-project').css('display','block');
  $('.js-create-project').addClass('cpointer');
  $('.popin-step-error').html = "";
  $('.next-step-popin-content-step1').css('display','none');
  $('.autocomplete-dist-project').html('');
  var val = $(this).val();
  $('.autocomplete-dist').html('');
  $('.js-autocomplete-dest').val('');
  $(this).removeAttr('data-id');
  let autocompleteArray = $('.js-autocomplete-project').data('projectslist');
  if (val) {
    var matching = $.grep(autocompleteArray, function(v) {
      if ((v.name && v.name.toLowerCase().includes(val.toLowerCase()))) {
        return true;
      }
      return false;
    });
    if (matching.length > 0) {
      let choice = "";
      $.each(matching, function(i, v) {
        choice += "<div class='project-item' data-id='" + v.projectId + "'><div class='project-info'><div class='project-name font-weight-bold'>" + v.name + "</div></div></div>";
      });
      $('.autocomplete-dist-project').html(choice);
    }
    else {
      $('.autocomplete-dist-project').html('');
    }
  } else {
    $('.autocomplete-dist-project').html('');
  }
});

$(document).on('click', '.js-create-project', function() {
  if ($(this).hasClass('cpointer')) {
    $(this).removeClass('cpointer');
    let projectName = $('.bills-project').val();
    let promiseReturn = $.post("/freelance/nouveau_projet", {projectName: projectName});
    promiseReturn.then(function(val) {
      let v = JSON.parse(val);
      if (v.status === 'failed') {
        $('.popin-step-error').html(v.message);
        $('.popin-step-error').show();
        $('.soancontainer').animate({ scrollTop: $(".errors").offset().top }, 1000);
      } else {
        $('.bills-project').data('id', v.projectId);
        $('.next-step-popin-content-step1').css('display','block');
        $('.js-create-project').css('display','none');
      }
      $("js-create-project").addClass('cpointer');
    });
  }
});

// MANAGE EFACTURE PERSONALIZATION
let logoLoaded;

$(document).on('change', '#payment_logo_personalization', function() {
  if ($(this).is(':checked')) {
    $('.if-logo-personalization').slideDown();
  } else {
    $('.if-logo-personalization').slideUp();
  }
});

setTimeout(() => {
  $('.js-add-logo-personalization-div').on('click', () => {
    $('.js-add-logo-personalization-input').trigger('click');
  })

  $('.js-add-logo-personalization-input').on('change', (e) => {
    if ($(e.target).prop('files')[0].name.split(".")[$(e.target).prop('files')[0].name.split(".").length - 1] === "png" ||
        $(e.target).prop('files')[0].name.split(".")[$(e.target).prop('files')[0].name.split(".").length - 1] === "jpg") {
      const formData = new FormData();
      logoLoaded = $(e.target).prop('files')[0];
      formData.append('addPicture', logoLoaded);
      $.ajax("/freelance/mon_profil/nouveau_logo", {
        method: "POST",
        data: formData,
        processData: false,
        contentType: false,
        success(val) {
          let v = JSON.parse(val);
          if (v.status === 'success') {
            $('.cerror').text('');
            $('.cerror').addClass('d-none');
            $('.js-add-logo-personalization-div, .st-personalization-load-zone').addClass('d-none');
            if ($('.timeline-relaunch .st-blocAddingLogoMail').has(".if-logo-personalization").length) {
              let getLogo = v.thLogo;
              let getExtractSrcLogo = getLogo.substring(getLogo.indexOf('/companyFiles'));
              let divImgMail = $("<p>", {class: "js-logoCompanyMail"});
              let imgMail = $("<img>", {
                src: getExtractSrcLogo,
                alt: "logoCompany",
                height: "80px",
                width: "80px",
                class: "js-logoCompanyMail"
              });
              $(divImgMail).append(imgMail);
              $(".timeline-relaunch").data("logocompany", getExtractSrcLogo);
              $(".timeline-relaunch .trumbowyg-editor").append(divImgMail);
              $(".timeline-mail .st-blocAddingLogoMail").css("height", "initial");
              $(".timeline-mail .st-blocAddingLogoMail label").css("margin-top", "auto");
              $(".timeline-mail .st-blocAddingLogoMail div:nth-child(2)").css("margin-top", "initial");
              $(".timeline-mail .st-personalization-load-zone").css({"margin-left": "initial", "width": "initial", "margin-top": "initial"});
            }
            else {
              $(".st-validationLogoCompany").show();
              $(".msg-flash").html(translation("Votre logo d'entreprise a bien été intégré.")).addClass("container alert alert-success").css({"color": "white", "z-index": "10000", "top": "54px"}).show();
              setTimeout(function(){ 
                $(".msg-flash").text("").css("color", "initial").removeClass("container alert alert-success").hide();
              }, 6000);
              // let div = document.createElement("div");
              // $(div).css('background-image' ,`url(${window.URL.createObjectURL(logoLoaded)})`);
              // div.className = "avatar d-flex mt-3 st-height-90px st-width-90px st-background-cover js-add-logo-personalization-div";
              // $('.js-logoLoadedToPersonalized').children().remove();
              // $('.js-logoLoadedToPersonalized').append(div);
              // $(div).append('<div class="m-auto st-padding-3-8px btn white-btn">' + translation('Changer') + '</div>');
              // $('.js-add-logo-personalization-div').on('click', () => {
              //   $('.js-add-logo-personalization-input').trigger('click');
              // });    
            }    
          } else { 
            $('.cerror-upload-logo').text(v.message);
            $('.cerror-upload-logo').removeClass('d-none');
          }     
        },
        error() {
          $('.cerror-upload-logo').text(translation("Votre photo semble incorrecte"));
          $('.cerror-upload-logo').removeClass('d-none');
        }
      })
    } else {
      $('.cerror-upload-logo').text(translation('logo jpg ou png'));
      $('.cerror-upload-logo').removeClass('d-none');
    }
  })
}, 3000)



