let switching = false;

document.querySelectorAll('.js-flip-card').forEach((elem) => {
    elem.addEventListener('click', (e) => {
        $(e.target).closest('.js-card-dashboard').find('.js-close-cross-1').addClass('d-none');
        $(e.target).closest('.js-card-dashboard').find('.js-close-cross-2').removeClass('d-none');
        flipCard(e.target.closest('.js-card-dashboard'));
        e.stopImmediatePropagation();
    })
})

const flipCard = (card) => {
    if (switching) {
        return false;
    }
    switching = true;
    $(card).toggleClass('is-switched');
    window.setTimeout(function () {
        $(card).children().children().toggleClass('is-active');
        switching = false;
    }, 250)
    $(document).on('click', (e) => {
        document.querySelectorAll('.js-flip-card').forEach((elem) => {
            $(elem).closest('.js-card-dashboard').removeClass('is-switched');
            $(elem).closest('.js-card-dashboard').find('.js-close-cross-1').removeClass('d-none');
            $(elem).closest('.js-card-dashboard').find('.js-close-cross-2').addClass('d-none');
        })
    })
}

$('.js-delete-flip-card').on('click', (e) => {
    e.preventDefault();
    e.stopImmediatePropagation();
    const cardId = $(e.target).closest('.js-card-dashboard').attr('data-cardid');
    const promiseReturn = $.post("/freelance/closeCard", {cardId: cardId});
    promiseReturn.then(function(val) {
        let v = JSON.parse(val);
        if (v.status === 'success') {
            $(e.target).closest('.js-card-dashboard').remove();
        }
    });
})