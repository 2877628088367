// Save payment supplier recap and leave

import { openPopin } from "../../generalFunctions";

$(document).on("click", '.js-saveRecap', function () {
    let expenseAccount = $('#compteDeCharges').val();
    let bankAccount = $('#compteBanqueText').val();
    let taxesAccount = $('#compteTva').val();
    let comptaTag = $('#comptaTag').val();
    let paymentId = $('.pageFournisseur').attr('data-paymentid');
    let supplierAccount = $('.js-compteFournisseur').text();

    let promiseReturn = $.post('/freelance/paiement_fournisseur/' + paymentId + '/save_and_leave', {
        expenseAccount: expenseAccount,
        bankAccount: bankAccount,
        taxesAccount: taxesAccount,
        comptaTag: comptaTag,
        supplierAccount: supplierAccount
    })

    promiseReturn.then(function (val) {
        let v = JSON.parse(val);
        if (v.status === "success") {
            history.back();
        }
        else {
            let message = v.message;
            $(".st-blocFormError2").removeClass("d-none").addClass("d-flex");
            $(".st-blocFormError2 .js-errorMsg").text(message)
        }
    })
});

$(".pageRecap .st-back-dashboard").on("click", function() {
    openPopin(".popin-confirmLeavePage");
});