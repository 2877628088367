$(document).on('click', '.js-kyc-validate', function () {
  $('.error-promo').addClass('d-none').html('');
  let companyId = $(this).attr('data-id');
  let promiseReturn = $.post('/freelance/admin/kyc/validate/'+companyId);
  promiseReturn.then(function (val) {
    let v = JSON.parse(val);
    if (v.status === "success") {
      window.location.reload();
    }
    else {
      let error = v.message;
      $('.error-promo').removeClass('d-none').html(error);
    }
  })
});
