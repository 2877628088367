import {translation} from "../generalFunctions";
class Popin {
    constructor(classGlobalContent, popinTitle, step = false) {
        
        this.steps;
        // Creation globalClassContent

        this.classGlobalContent = classGlobalContent;

        this.currentStep;
        
        let globalContent = $("<div>", {class: classGlobalContent});
        $(".popin").append(globalContent);

        // Add closeButton 
        if (step === true) {
            this.popinStructure(globalContent, "<div>", "popin-close js-active-sureuback");
        }
        else {
            this.popinStructure(globalContent, "<div>", "popin-close");
        }

        // Add Title
        this.popinStructure(globalContent, "<div>", "popin-title", popinTitle);

        // If popin have steps we create the breadCrum
        if (step === true) {
            this.popinStructure(globalContent, "<div>", "popin-step-header");
        }

        // Add container error msg
        this.popinStructure(globalContent, "<div>", "mt-2 mb-2 popin-step-error");

        // Add container for content 
        this.popinStructure(globalContent, "<div>", "popin-content");

        // Add ParentActionsButton
        this.popinStructure(globalContent, "<div>", "popin-actions");
    }

    initSteps(steps) {
        this.steps = steps;
    }

    displaySteps() {
        let getStep = this.steps;
        let i = 1;
        let j = 1;
        while(getStep) {

            // Adding title step
            if (getStep.title) {
                getStep.classTitle += i;
                let containerBreadCrum = getStep.parentContainer + " .popin-step-header";
                this.popinStructure(containerBreadCrum, "<div>", getStep.classTitle, getStep.title);
                i++;
            }
            
            // Adding content step
            getStep.contentId += j;
            let containerContent = getStep.parentContainer + " .popin-content";
            this.popinStructure(containerContent, "<div>", getStep.contentClass, getStep.content, "", "", getStep.contentId);

            // Adding button step
            let containerButtons = getStep.parentContainer + " .popin-actions";
            if (getStep.prev) {
                getStep.idPrevBtn += j;
                this.popinStructure(containerButtons, "<button>", getStep.classPrevBtn, translation("Retour"), "button", "", getStep.idPrevBtn);
            }

            getStep.idNextBtn += j;
            this.popinStructure(containerButtons, "<button>", getStep.classNextBtn, translation("Continuer"), "button", getStep.dataAttributes, getStep.idNextBtn);

            getStep.displayStep();

            // We call next step
            getStep = getStep.next;
            j++;
        }
    }

    deleteStep(stepDelete) {
        let getStep = this.steps;
        while(getStep !== stepDelete && getStep !== null) {
            getStep = getStep.next;
        }
        if (getStep !== null) {
            let nextStep = getStep.next;
            let prevStep = getStep.prev;
            if (getStep.title !== null) {
                $("." + getStep.classTitle).hide();
            }
            if (prevStep === null) {
                if (nextStep !== null) {
                    nextStep.prev = null; 
                    $("#" + nextStep.idPrevBtn).hide();
                }
            }
            else {
                if (nextStep !== null) {
                    // The prev of the next step becomes the prev of actual step
                    nextStep.prev = prevStep;
                    // the next of the prev step becomes the next of actual step
                    prevStep.next = nextStep;
                }
                else {
                    prevStep.next = null;
                }
            } 
        }
    }

    addStep(stepAdding) {
        let getStep = this.steps;
        if (stepAdding.prev !== null) {
            while(getStep !== null && stepAdding.prev !== getStep) {
                getStep = getStep.next;
            }
            if (getStep.next !== null && getStep.next !== stepAdding) {
                getStep.next.prev = stepAdding;
                getStep.next = stepAdding;
            }
            else if (getStep.next === null) {
                getStep.next = stepAdding;
            }
        }
        else {
            while(getStep !== null && stepAdding.next !== getStep) {
                getStep = getStep.next;
            }
            getStep.prev = stepAdding;
        }
    }

    setCurrentStep(step) {
        this.currentStep = step;
    }

    goNext(validAjax = true) {
        let step = this.currentStep;
        let nextStep = this.currentStep.next;

        step.isRead = true;
        $("." + step.classTitle).css("cursor", "pointer");

        if (step.isCallAjax && validAjax) {
            step.isCallAjax.call();
        }
        else if (nextStep === null) {
            let getClassSelector = "." + this.classGlobalContent.replace(" ", ".");
            $(getClassSelector).hide();
            step.showFinalStep();
        }
        else {
            if (!nextStep.isActive) {
                step.isCurrent = false;
                nextStep.next.isCurrent = true;
                step.displayStep();
                nextStep.next.displayStep();
                this.currentStep = this.currentStep.next.next;
                nextStep.next.isRead = true;
                $("." + nextStep.next.classTitle).css("cursor", "pointer");
            }
            else if (nextStep) {
                step.isCurrent = false;
                nextStep.isCurrent = true;
                step.displayStep();
                nextStep.displayStep();
                this.currentStep = this.currentStep.next;
                nextStep.isRead = true;
                $("." + nextStep.classTitle).css("cursor", "pointer");
            }
        }
    }

    goPrev() {
        let step = this.currentStep;
        let prevStep = this.currentStep.prev;

        if (!prevStep.isActive) {
            step.isCurrent = false;
            prevStep.prev.isCurrent = true;
            step.displayStep();
            prevStep.prev.displayStep();
            this.currentStep = this.currentStep.prev.prev;
        }
        else {
            step.isCurrent = false;
            prevStep.isCurrent = true;
            step.displayStep();
            prevStep.displayStep();
            this.currentStep = this.currentStep.prev;
        }
    }

    showingStep(headerClass) {
        let getStep = this.steps;
        while(getStep !== null && getStep.classTitle !== headerClass) {
            getStep = getStep.next;
        }
        if (getStep !== null && getStep.isRead) {
            this.currentStep.isCurrent = false;
            this.currentStep.displayStep();
            getStep.isCurrent = true;
            getStep.displayStep();
            this.currentStep = getStep;
        }
    }

    disableRead(id) {
        if (id) {
            let getStep = this.steps;
            let flag = false;
            while(getStep !== null) {
                if (getStep.contentId === id) {
                    flag = true;
                }
                if (flag && getStep.next !== null) {
                    getStep.next.isRead = false;
                    $("." + getStep.next.classTitle).css("cursor", "initial");
                }
                getStep = getStep.next;
            }
        }
    }

    popinStructure(parentElement, baliseElement, classElement, htmlElement = null, typeElement = null, arrayDataset = null, idElement = null) {
        // Creation of element and adding it in his parent
        let element = $(baliseElement, {
            class: classElement,
            html: htmlElement,
            type: typeElement,
            id: idElement
        });
        $(parentElement).append(element);

        // Multiple adding dataset on created element
        
        for (const data in arrayDataset) {
            $(element).attr(data, arrayDataset[data]);
        }
    }
}

export default Popin;