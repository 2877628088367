import { formatDateSeparators, translation } from '../../generalFunctions.js';

// Manage send email and link popin
export function manageCustomEmailPopin() {


    $("#js-demandNow").attr("checked", true);

    $("#js-demandNow").on("click", function() {
        if ($(".st-infoLink").attr("data-deleted") === "false") {
            $(".st-infoLink").removeClass("d-none").addClass("d-flex");
        }
        $(".st-infoSendLater").removeClass("d-flex").addClass("d-none");
        $(".js-blocCreatedLater").hide();
        $(".st-payment-link-popin").addClass("js-copy-payment-link-popin cpointer").removeClass("st-disabled-payment-link");
        $(".content-copy-black").removeClass("d-none");
        $(".content-copy-grey").addClass("d-none");
    });

    $("#js-demandLater").on("click", function() {
        if ($(".st-infoSendLater").attr("data-deleted") === "false") {
            $(".st-infoSendLater").removeClass("d-none").addClass("d-flex");
        }
        $(".st-infoLink").removeClass("d-flex").addClass("d-none");
        $(".js-blocCreatedLater").show();
        let dateValue = $(".js-createdLater").val();
        $(".js-getDateLater").text(" " + dateValue);
        $(".content-copy-black").addClass("d-none");
        $(".content-copy-grey").removeClass("d-none");
        $(".st-payment-link-popin").removeClass("js-copy-payment-link-popin cpointer").addClass("st-disabled-payment-link");
    });

    $(".st-crossInfosLink").on("click", function() {
        $(".st-infoLink").removeClass("d-flex").addClass("d-none").attr("data-deleted", "true");
    });

    $(".st-crossInfosLater").on("click", function() {
        $(".st-infoSendLater").removeClass("d-flex").addClass("d-none").attr("data-deleted", "true");
    });

    $(".js-createdLater").on("input change", function() {
        let dateValue = $(this).val();
        $(".js-getDateLater").text(" " + dateValue);
    });


    $(".st-payment-link-popin").on("mouseenter", function() {
        if ($("#js-demandLater").is(":checked")) {
            $(".st-hoverInfoDateLink").show();
        }
    });
    $(".st-payment-link-popin").on("mouseleave", function() {
        $(".st-hoverInfoDateLink").hide();
    });

    $("#js-mailCustomInput").on("click", function() {
        if (!$(this).is(":checked")) {
            $(".st-infoSendLater").removeClass("d-flex").addClass("d-none");
            $("#copyMailContainer").addClass("d-none");
        }
        else {
            $("#copyMailContainer").removeClass("d-none");
            if ($("#js-demandLater").is(":checked")) {
                if($(".st-infoSendLater").attr("data-deleted") === "false") {
                    $(".st-infoSendLater").removeClass("d-none").addClass("d-flex");

                }
            }
        }
    });
}

// EDIT PAYMENT ASKING DATE POPIN
$('.js-edit-paymentdate').on('click', (e) => {

    const manageError = (message) => {
        $('.popin-step-error').html(message);
        $('.popin-step-error').show();
        $('.loader-popin').fadeOut(200);
    }

    $('.popin-step-error').hide();
    $('.popin-step-error').html('');
    $('.popin-step-header').show();
    document.querySelector('.js-paymentbystep-editdate').dataset.idpaymentedit = e.target.dataset.idpayment;
    document.querySelector('.js-date-sendlater-edit').value = e.target.dataset.dateplaned;

    $('#payment_sendnow_edit').prop('checked', false);
    $('#payment_sendlater_edit').prop('checked', true);
    $('.if-payment-later-edit').slideDown();

    $(document).on('click', '#payment_sendlater_edit', function() {
        if ($('#payment_sendnow_edit').is(':checked')) {
            $('#payment_sendnow_edit').prop('checked', false);
            $('.if-payment-later-edit').slideDown();
        } else if (!$('#payment_sendnow_edit').is(':checked') && $('#payment_sendlater_edit').is(':checked')) {
            $('.if-payment-later-edit').slideDown();
        } else if (!$('#payment_sendlater_edit').is(':checked')) {
            $('.if-payment-later-edit').slideUp();
            $('#payment_sendlater_edit').prop('checked', false);
        }
    });

    $(document).on('click', '#payment_sendnow_edit', function() {
        if ($('#payment_sendlater_edit').is(':checked')) {
            $('#payment_sendlater_edit').prop('checked', false);
            $('.if-payment-later-edit').slideUp();
        }
    });

    $('.js-paymentbystep-editdate').on('click', (e) => {
        const projectToUpdateID = e.target.dataset.idproject;
        const paymentToUpdateID = e.target.dataset.idpaymentedit;
        let showSendLater = $('#payment_sendlater_edit').is(':checked');
        let showSendNow = $('#payment_sendnow_edit').is(':checked');
        let delayedInvoice = formatDateSeparators($('.js-date-sendlater-edit').val());
        const sendLaterDateObject = new Date(parseInt(delayedInvoice.split('-')[2]), parseInt(delayedInvoice.split('-')[1]) - 1, parseInt(delayedInvoice.split('-')[0]));  
        if (sendLaterDateObject <= new Date() && showSendLater) {
            if (!$('.error-sendlater').length) {
                $('.popin-step-error').append('<div class="error-sendlater">' + translation('date ultérieure') + '</div>');
                $('.popin-step-error').show();
            }
            return;
        }
        $(".loader-popin").fadeIn();
        if (showSendLater) {
            let promiseReturn = $.post("/freelance/projet/"+projectToUpdateID+"/paiement/"+paymentToUpdateID+"/delayed/modification", {delayedInvoice: delayedInvoice});
            promiseReturn.then(function(val) {
                let v = JSON.parse(val);
                if (v.status === 'failed') {
                    manageError(v.message);
                } else {
                    location.reload();
                }
            })
        } else if (showSendNow) {
            let promiseReturn = $.post("/freelance/projet/"+projectToUpdateID+"/paiement/"+paymentToUpdateID+"/envoyer_maintenant");
            promiseReturn.then(function(val) {
                let v = JSON.parse(val);
                if (v.status === 'failed') {
                    manageError(v.message);
                } else { 
                    location.reload();
                }
            })
        } else {
            manageError(translation('Veuillez sélectionner une option'));
        }    
    }) 
})

// We create a new Class MutationObserver that check the changing in the dom 
let observer = new MutationObserver(function(mutations) {
    if ($('.popin').is(":visible")) {
        $("html").css("overflow", "hidden");
    }
    else {
        $("html").css("overflow", "auto");
    }
});

// this variable check the changing on the popin class
let targetPopin = document.querySelector('.popin');
observer.observe(targetPopin, {
    attributes: true
});

$('.js-div-bankInfos').on('click', function() {
    if ($(this).text() === translation("Je ne trouve pas ma banque")) {
        $(this).text(translation("réaliser virement traditionnel"));
        $(".js-div-bankInfos-iban").css("display", "flex");
    }
    else {
        $(this).text(translation("Je ne trouve pas ma banque"));
        $(".js-div-bankInfos-iban").css("display", "none");
    }
});

$(document).on("click", '.js-seeBusiness', function () {
    window.location.href = '/freelance/mon_profil/mon_offre/nos_offres';
})
$(document).on('click', '.js-callBackUser', function () {
    let promiseReturn = $.get("/freelance/contact_support");
    promiseReturn.then(function (val) {
        window.location.href = '/freelance/mon_profil/mon_offre/nos_offres';
    })
})

$(".js-redirect-previousPage").on("click", function() {
    history.back();
});
