let translation_fr = {
    "% d’escompte</span> pour un règlement avant le ": "% d’escompte</span> pour un règlement avant le ",
    "3x sans frais": "3x sans frais",
    "12 derniers mois": "12 derniers mois",
    "1 000 € maximum par carte": "1 000 € maximum par carte",
    "20 000 € maximum par prélèvement": "20 000 € maximum par prélèvement",
    "a bien été ajouté": "a bien été ajouté",
    "a bien été envoyée": "a bien été envoyée",
    "À régler": "À régler",
    "À régler avant": "À régler avant le",
    "À signer": "À signer",
    "À valider": "À valider",
    "Approbation en attente": "Approbation en attente",
    "Aucun commentaire pour le moment...": "Aucun commentaire pour le moment...",
    "an": "an",
    "Abonnement": "Abonnement",
    "Afficher les entrées": "Afficher les entrées", 
    "Ajouter": "Ajouter",
    "Ajouter des paiements": "Ajouter des paiements",
    "annuel": "annuel",
    "Annuler": "Annuler",
    "Appliquer promo": "Appliquer promo",
    "Approbation demandée": "Approbation demandée",
    "Approbation refusée": "Approbation refusée",
    "Approbation validée": "Approbation validée",
    "Association": "Association",
    "Atouts Soan": "Atouts Soan",
    "Aucune approbation": "Vous n'avez aucune demande d'approbation en attente.",
    "Aucune correspondance": "Aucune correspondance",
    "Aucune demande": "Aucune demande en cours", //
    "Aucune demande2": "Aucune demande programmée en cours", //
    "Aucune donnée": "Aucune donnée",
    "Aucun élément": "Aucun élément ne correspond à votre recherche", //
    "Aucune facture en cours": "Aucune facture en cours de traitement ne correspond à votre recherche", //
    "Aucune facture réglée": "Aucune facture réglée ne correspond à votre recherche", //
    "Aucun favoris": "Malheureusement aucun dossier ne correspond à votre recherche dans vos favoris.", //
    "Aucune notification": "Retrouvez ici un aperçu de vos dernières activités sur Soan", //
    "aucun objectif": "Vous n'avez renseigné aucun objectif", //
    "Aucune opération en cours": "Aucune opération en cours ne correspond à votre recherche", //
    "Aucune opération réglée": "Aucune opération réglée ne correspond à votre recherche", //
    "Aucun paiement": "Aucun paiement en cours", //
    "Aucun paiement2": "Aucun paiement programmé en cours", //
    "Aucun recouvrement": "Aucun recouvrement en cours", //
    "Aucun résultat": "Nous ne sommes pas en mesure de trouver « <span class='js-dynamicSearchName'></span> » dans cette catégorie.", //
    "Aucune signature": "Aucune signature ou validation en cours", //
    "Aucune transaction": "Aucune transaction en cours", //
    "Autre": "Autre",
    "bénéficiaire majeur": "Le bénéficiaire effectif doit être majeur", //
    "bien programmés et sera envoyée le" : "a bien été programmée et sera envoyée le", //
    "Bonne nouvelle": "Bonne nouvelle ! <br> Vous n'avez pas de facture à régler.", //
    "Carte expirée": "La date d'expiration de la carte est dépassée",
    "Ce champs ne doit contenir que des chiffres": "Ce champs ne doit contenir que des chiffres",
    "Ces factures ne sont pas éligibles au 3x sans frais": "Ces factures ne sont pas éligibles au 3x sans frais. Sélectionnez un autre moyen de paiement.",
    "Cette facture n'est pas éligible au 3x sans frais": "Cette facture n'est pas éligible au 3x sans fraix. Sélectionnez un autre moyen de paiement.",
    "Changer": "Changer",
    "Chargement...": "Chargement...",
    "Cliquer pour copier !": "Cliquer pour copier !",
    "code invalide": "Le code renseigné est invalide.",
    "Code promo en attente": "Code promo en attente",
    "Code promo utilisé": "Code promo utilisé",
    "Compléter": "Compléter",
    "Compris": "Compris",
    "Comptable": "Comptable",
    "Confirmer": "Confirmer",
    "Continuer": "Continuer",
    "Copié !": "Copié !",
    "Créer une entreprise": "Créer une entreprise ou organisme", //
    "Création": "Création",
    "critères mot de passe": "Votre mot de passe doit comporter 8 caractères, 1 minuscule, 1 majuscule et 1 chiffre", //
    "Date de facturation inférieure": "La date de facturation doit être inférieure à la date d'échéance de la facture",
    "Date d'inscription": "Date d'inscription",
    "date ultérieure": "Veuillez choisir une date ultérieure", //
    "Demande de paiement": "Demande de paiement",
    "Dernière connexion": "Dernière connexion",
    "d’escompte avant le": "d’escompte </span> pour un règlement avant le", //
    "Dossier principal": "Dossier principal",
    "DSO moyen": "DSO moyen",
    "Échec": "Échec",
    "Échéance": "Échéance",
    "Échue depuis": "Échue depuis",
    "Éditer le texte": "Éditer le texte",
    "E-factures par an": "E-factures par an",
    "E-factures par mois": "E-factures par mois",
    "Email": "Email",
    "Emis le": "Emis le",
    "En attente": "En attente",
    "En cours de traitement...": "En cours de traitement...",
    "En cours de transfert": "En cours de transfert",
    "En cours depuis le": "En cours depuis le",
    "En savoir plus": "En savoir plus",
    "Encaissé": "Encaissé",
    "Enregistrer": "Enregistrer",
    "entreprise": "entreprise",
    "Entreprise": "Entreprise",
    "Envoi": "Envoi",
    "Erreur import facture" : "Une erreur est survenue à l'import de la facture",
    "Erreur lors de l'ajout du commentaire": "Une erreur est survenue lors de l'ajout du commentaire. Réessayez plus tard.",
    "Erreur survenue": "Une erreur est survenue.", //
    "Escompte": "Escompte",
    "Êtes-vous sûr ?": "Êtes-vous sûr ?",
    "Facturation": "Facturation",
    "Facture": "Facture",
    "Facture générée": "Facture générée",
    "Félicitation, vous avez reçu le virement en instantané": "Félicitation, vous avez reçu le virement en instantané. L'argent est déjà disponible sur votre compte bancaire.", //
    "Flux d'affaire cumulé par mois": "Flux d'affaire cumulé par mois",
    "Flux d'affaire processé cumulé": "Flux d'affaire processé cumulé",
    "Fondation": "Fondation",
    "Format de fichier non valide": "Format de fichier non valide",
    "Gérant": "Gérant",
    "Glissez deposez": 'Glissez</span> <span class="font-weight-bold">déposez</span> ou <span class="font-weight-bold">parcourez</span> vos documents<br /> pour ajouter des fichiers', //
    "HT / an soit": "HT / an soit",
    "HT / mois soit": "HT / mois soit",
    "Il semblerait que ce SIRET n’existe pas": "Il semblerait que ce SIRET n’existe pas",
    "Il semblerait que ce CIF/NIF n’existe pas": "Il semblerait que ce CIF/NIF n’existe pas",
    "Il semblerait que ce code ne soit pas valide.": "Il semblerait que ce code ne soit pas valide.",
    "Importé le":"Importé le :",
    "Information copiée": "Information copiée",
    "Infos": "Infos",
    "Infos manquantes": "Infos manquantes",
    "Je ne trouve pas ma banque": "Je ne trouve pas ma banque",
    "jour": "jour",
    "Jour": "Jour",
    "jours": "jours",
    "Jours": "Jours",
    "Le code promo a bien été ajouté !": "Le code promo a bien été ajouté !",
    "Le fichier doit être au format PDF": "Le fichier doit être au format PDF",
    "Les fichiers téléchargés": "Les fichiers téléchargés ne doivent pas dépasser 7M0", //
    "Le montant de votre facture": "Le montant de votre facture doit être supérieur à 1000 €", //
    "Le montant max 1 000 €": "Le montant maximum payable par carte est de 1 000 €.",
    "Le nombre de paiement a bien été ajouté !": "Le nombre de paiement a bien été ajouté !",
    "Le recouvrement de cette facture": "Le recouvrement de cette facture n'est pas accepté", //
    "Le représentant de l'entreprise": "Le représentant de l'entreprise doit être majeur", //
    "Lien copié": "Lien copié !",
    "Lien de paiement": "Lien de paiement",
    "logo jpg ou png": "Le logo de votre entreprise doit être au format .jpg ou .png", //
    "Marquer comme payé": "Marquer comme payé",
    "mensuel": "mensuel",
    "Membre": "Membre",
    "Modèle supprimé": "Votre modèle a bien été supprimé", //
    "Modifier": "Modifier",
    "mois": "mois",
    "mois restants": "mois restants",
    "Montant": "Montant",
    "Montant cumulé": "Montant cumulé",
    "Ne pas marquer comme payé": 'Ne pas "marquer comme payé"',
    "Nom": "Nom",
    "Nombre invalide": "Nombre invalide",
    "Nombre de demande de paiement cumulé": "Nombre de demande de paiement cumulé",
    "Nombre de demande par mois": "Nombre de demande par mois",
    "NON": "NON",
    "num facture un chiffre minimum": "Le numéro de votre facture doit comporter au minimum un chiffre", //
    "Numéro copié !": "Numéro copié !",
    "objet et message obligatoires" : "Un objet et un message sont obligatoires", //
    "Objectif de CA": "Objectif de CA",
    "organisme": "organisme",
    "OUI": "OUI",
    "par an": "par an",
    "par mois": "par mois",
    "Paiement": "Paiement",
    "paiements": "paiements",
    "Paiement programmé": "Paiement programmé",
    "Paiements restants": "Paiements restants",
    "Paiement de la facture": "Paiement de la facture",
    "Paiement sécurisé par virement bancaire": "Paiement sécurisé par virement bancaire",
    "Paiement supérieur à 20 000 €": "Pour les paiements supérieurs à 20 000€, seuls les virements sont possibles.",
    "Payer": "Payer",
    "Payer maintenant": "Payer maintenant",
    "Prédédent": "Prédédent",
    "Profil vérifié": "Profil vérifié",
    "Programmé": "Programmé",
    "Programmé le": "Programmé le",
    "Programmé pour le": "Programmé pour le",
    "Promo": "Promo",
    "Rappel": "Rappel",
    "réaliser virement traditionnel": "Vous devez réaliser un virement traditionnel depuis votre banque et marquer cette facture comme payée",
    "Recherche": "Recherche",
    "Recouvrement lancé le": "Recouvrement lancé le",
    "Recouvrir": "Recouvrir", 
    "Réglé": "Réglé",
    "Réglé le": "Réglé le",
    "Relance": "Relance",
    "Relancer": "Relancer",
    "Remplacer cette facture": "Remplacer cette facture",
    "Représentant légal": "Représentant légal",
    "Retard": "Retard",
    "Retour": "Retour",
    "S'agit-il de cette entreprise ?": "S'agit-il de cette entreprise ?",
    "Sélectionnez": "Sélectionnez",
    "Sélectionnez une seule facture": "Sélectionnez une seule facture <br> éligible au 3x sans frais pour <br> utiliser ce mode de paiement", //
    "Signer": "Signer",
    "Signature complète": "Si tu veux une signature complète, il faut remplir tous les champs", //
    "Signature en cours": "Signature en cours",
    "Signature : terminée": "Signature : terminée",
    "Si votre organisme n'a pas de Siret": "Si votre organisme n'a pas de Siret, laissez vide", //
    "soit": "soit",
    "Somme récupérée :": "Somme récupérée :",
    "Sortie": "Sortie",
    "Suivant": "Suivant",
    "Supprimer": "Supprimer",
    "sur": "sur",
    "synthèse": "synthèse",
    "Télécharger": "Télécharger",
    "Téléphone": "Téléphone",
    "Tous les champs": "Tous les champs doivent être renseignés", //
    "TTC": "TTC",
    "TTC / an": "TTC / an",
    "TTC / an business terminé": "TTC / an une fois votre abonnement Business terminé", //
    "TTC / an premium terminé": "TTC / an une fois votre abonnement Premium terminé", //
    "TTC / mois": "TTC / mois",
    "TTC / mois business terminé": "TTC / mois une fois votre abonnement Business terminé", //
    "TTC / mois premium terminé": "TTC / mois une fois votre abonnement Premium terminé", //
    "TTC doit être supérieur HT": "Le montant TTC doit être supérieur ou égal au montant HT", //
    "TVA": "TVA",
    "type": "type",
    "Un objet et un message sont obligatoires": "Un objet et un message sont obligatoires",
    "Une erreur est survenue" : "Une erreur est survenue.",
    "Valider": "Valider",
    "Validation en cours": "Validation en cours",
    "Validation : terminée": "Validation : terminée",
    "Vérifiez le numéro SIREN": "Vérifiez que le numéro SIREN de votre client ne soit pas le même que le votre.", //
    "Vérifiez votre lien hubspot": "Vérifiez votre lien hubspot",
    "Vérifiez dossiers archivés": "Souhaitez-vous vérifier dans vos <span class='text-dark st-text-decoration cpointer js-toClosedProjects'>dossiers archivés</span> ?", //
    "Veuillez accepter tous les champs": "Veuillez accepter tous les champs ci dessous", //
    "Veuillez ajouter un élément": "Veuillez ajouter un élément",
    "Veuillez ajouter un justificatif": "Veuillez ajouter un justificatif",
    "Veuillez ajouter votre facture": "Veuillez ajouter votre facture",
    "Veuillez choisir une option": "Veuillez choisir une option pour la date d'envoi", //
    "Veuillez cocher les champs obligatoires": "Veuillez cocher les champs obligatoires",
    "Veuillez entrez un numéro de carte valide.": "Veuillez entrez un numéro de carte valide.",
    "Veuillez remplir ce champ": "Veuillez remplir ce champ",
    "Veuillez remplir les champs": "Veuillez remplir les champs obligatoires suivants :", //
    "Veuillez renseigner les champs obligatoires": "Veuillez renseigner les champs obligatoires",
    "Veuillez renseigner tous les champs": "Veuillez renseigner tous les champs",
    "Veuillez renseigner une adresse mail valide": "Veuillez renseigner une adresse mail valide",
    "Veuillez renseigner une date": "Veuillez renseigner une date",
    "Veuillez renseigner un iban valide": "Veuillez renseigner un iban valide",
    "Veuillez sélectionner une option": "Veuillez sélectionner une option", 
    "Veuillez valider le SIREN": "Veuillez valider le SIREN de l'entreprise", //
    "Veuillez valider les cases à cocher": "Veuillez valider les cases à cocher", 
    "Vous devez être majeur": "Vous devez être majeur pour utiliser l'application", //
    "Vous ne pouvez pas lancer un recouvrement": "Vous ne pouvez pas lancer un recouvrement sur votre propre entreprise", //
    "Votre banque n'a pas accepté le virement instantané": "Votre banque n'a pas accepté le virement instantané. L'argent sera disponible dans 24h par virement normal.", //
    "Votre devis Euler Hermes": "Votre devis Euler Hermes",
    "Votre photo semble incorrecte": "Votre photo semble incorrecte",
    "Votre modèle": "Votre modèle",
    "Votre numéro de téléphone ne semble pas valide": "Votre numéro de téléphone ne semble pas valide",
    "Votre numéro ne semble pas être au bon format": "Votre numéro ne semble pas être au bon format",
    "Votre logo d'entreprise a bien été intégré.": "Votre logo d'entreprise a bien été intégré.",
    "Votre paiement a bien été marqué comme payé.": "Votre paiement a bien été marqué comme payé.",
    "Votre SIRET est trop court": "Votre SIRET est trop court",

    /* Mois */
    "Janvier": "Janvier",
    "Février": "Février", 
    "Mars": "Mars", 
    "Avril": "Avril",
    "Mai": "Mai",
    "Juin": "Juin",
    "Juillet": "Juillet",
    "Août": "Août",
    "Septembre": "Septembre",
    "Octobre": "Octobre",
    "Novembre": "Novembre",
    "Décembre": "Décembre",

    /* Préfixe mois */
    "jan": "Jan",
    "fev": "Fev",
    "mar": "Mar",
    "avr": "Avr",
    "mai": "Mai",
    "jui": "Jui",
    "juil": "Juil",
    "aou": "Aou",
    "sep": "Sep",
    "oct": "Oct",
    "nov": "Nov",
    "dec": "Dec",

    /* Préfixe jours de semaine */
    "Lun": "Lun",
    "Mar": "Mar",
    "Mer": "Mer",
    "Jeu": "Jeu",
    "Ven": "Ven",
    "Sam": "Sam",
    "Dim": "Dim",

    /* Mail personnalisable - Demande de paiement */
    "Facture n°": "Facture n°",
    "vous a envoyé une demande de paiement": "vous a envoyé une demande de paiement",

}

export default translation_fr;