/* 
    How work the front verifications ?

    - Step1 : Add the class of a check on the input to be checked (verif-empty for exemple)
    - Step2 : Add the class "verif-front" on the input to be checked
    - Step3 : Add "data-button" on the input with the class name of the button that sends the request
    - Step4 : Add "data-errorBloc" on the input with the class name of the errorBloc that shows the error message
    - Step5 : Add ".js-checkAllInputs" on the block surrounding the inputs to be checked
    - Step6 : Finally, add a change/input event at the end of your js file, that will check all inputs with the verif-front class
    
    exemple step6 ->
    
    $(".popin-updateSupplier .verif-front").on("input change", function() {
        checkAllInputs(".popin-updateSupplier .popin-content", ".js-request-updateSupplier");
    });
*/
import { translation } from "../generalFunctions";

export function checkAllInputs(container, button, fromExternalContainer = [], isChecked = true) {
    let emptyInputs = false;
    $(container + ".js-checkAllInputs .verif-front:visible").each(function() {
        if ($(this).val() === "" || $(this).hasClass("error")) {
            emptyInputs = true;
        }
    });

    fromExternalContainer.forEach(function(elm) {
        if ($(elm).val() === "" || $(elm).hasClass("error")) {
            emptyInputs = true;
        }
    });

    if (!emptyInputs && isChecked == true) {
        $(button).removeClass("st-no-btn-disabled");
    }
    else {
        $(button).addClass("st-no-btn-disabled");  
    }
}

export function verificationFront() {
    $('.onlyNumber').mask('00');
}

const validNumber = (input, blocError, disabledButton, comparisonClass = null) => {
    let correctValue = input.val().slice(0, -1);
    const isValidNumber = /^\d+([\,\.]\d+)?(?=$| )/.test(input.val());
    const blockMultipleDigitsAfterComma = /^\d+\,\d{3,}$/.test(input.val());
    const blockMultipleDigitsAfterPoint = /^\d+\.\d{3,}$/.test(input.val());

    if (!isValidNumber) {
        let isCommaExist = (correctValue.indexOf(",") > -1) ? true : false;
        let isPointExist = (correctValue.indexOf(".") > -1) ? true : false;
        if (isCommaExist || isPointExist) {
            input.val(correctValue);
        }
        if (input.val() === "") {
            input.addClass('error');
            let errorMsg = translation("Veuillez remplir ce champ");
            $(blocError).removeClass("d-none").addClass("d-flex");
            $(blocError).find(".js-errorMsg").text(errorMsg);
            $(disabledButton).addClass("st-no-btn-disabled");
        }
    }
    else if (blockMultipleDigitsAfterComma || blockMultipleDigitsAfterPoint) {
        input.val(correctValue);
    }
    else {
        $(blocError).removeClass("d-flex").addClass("d-none");
        input.removeClass('error');
        if (comparisonClass) {
            $(comparisonClass).removeClass("error");
        }
    }
}

$(".verif-validNumber").on("input change", function() {
    let disabledButton = $(this).attr("data-button");
    let blocError = $(this).attr("data-errorBloc");
    validNumber($(this), blocError, disabledButton);
});

$(".verif-ttc").on("input change", function() {
    let disabledButton = $(this).attr("data-button");
    let blocError = $(this).attr("data-errorBloc");
    let getClassHT = $(this).attr("data-classComparison");
    let getValTTC = $(this).val();
    let getValHT = $(getClassHT).val();
   
    validNumber($(this), blocError, disabledButton, getClassHT);

    if (Number(getValHT.replace(",", ".")) > Number(getValTTC.replace(",", ".")) && getValHT !== "") {
        $(this).addClass('error');
        let containerErrorMsg = $(blocError).find(".js-errorMsg");
        let errorMsg = translation("TTC doit être supérieur HT");
        $(blocError).removeClass("d-none").addClass("d-flex");
        $(containerErrorMsg).text(errorMsg);
        $(disabledButton).addClass("st-no-btn-disabled");
    }
});

$(".verif-ht").on("input change", function() {
    let disabledButton = $(this).attr("data-button");
    let blocError = $(this).attr("data-errorBloc");
    let getClassTTC = $(this).attr("data-classComparison");
    let getValTTC = $(getClassTTC).val();
    let getValHT = $(this).val();
    
    validNumber($(this), blocError, disabledButton, getClassTTC);

    if (Number(getValHT.replace(",", ".")) > Number(getValTTC.replace(",", ".")) && getValTTC !== "") {
        $(this).addClass('error');
        let errorMsg = translation("TTC doit être supérieur HT");
        $(blocError).removeClass("d-none").addClass("d-flex");
        $(blocError).find(".js-errorMsg").text(errorMsg);
        $(disabledButton).addClass("st-no-btn-disabled");
    }
});

$(".verif-atLeastOneNumber").on("input change", function() {
    let disabledButton = $(this).attr("data-button");
    let blocError = $(this).attr("data-errorBloc");

    if (!/\d/g.test($(this).val())) {
        $(this).addClass('error');
        let errorMsg = translation("num facture un chiffre minimum");
        $(blocError).removeClass("d-none").addClass("d-flex");
        $(blocError).find(".js-errorMsg").text(errorMsg);
        $(disabledButton).addClass("st-no-btn-disabled");
    }
    else {
        $(blocError).removeClass("d-flex").addClass("d-none");
        $(this).removeClass('error');
    }
});

$(".verif-mail").on("input change", function() {
    let disabledButton = $(this).attr("data-button");
    let blocError = $(this).attr("data-errorBloc");
    let value = $(this).val().trim();

    if ($(this).attr("data-email") === undefined) {
        if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
            $(this).addClass('error');
            let errorMsg = translation("Veuillez renseigner une adresse mail valide");
            $(blocError).removeClass("d-none").addClass("d-flex").attr("data-actif", "true");
            $(blocError).find(".js-errorMsg").text(errorMsg);
            $(disabledButton).addClass("st-no-btn-disabled");
        }
        else {
            $(blocError).removeClass("d-flex").addClass("d-none").attr("data-actif", "false");
            $(this).removeClass('error');
        }
    }
    else {
        $(blocError).removeClass("d-flex").addClass("d-none").attr("data-actif", "false");
        $(this).removeClass('error');
    }
});

$(".verif-empty").on("input change", function() {
    let disabledButton = $(this).attr("data-button");
    let blocError = $(this).attr("data-errorBloc");
    if ($(this).val() === "") {
        $(this).addClass("error");
        let errorMsg = translation("Veuillez remplir ce champ");
        $(blocError).removeClass("d-none").addClass("d-flex");
        $(blocError).find(".js-errorMsg").text(errorMsg);
        $(disabledButton).addClass("st-no-btn-disabled");
    }
    else {
        $(this).removeClass("error");
        $(blocError).removeClass("d-flex").addClass("d-none");
    }
});

$(".verif-trumbowyg").on("tbwchange", function() {
    let disabledButton = $(this).attr("data-button");
    let blocError = $(this).attr("data-errorBloc");
    if ($(this).val() === "") {
        $(this).addClass("error");
        $(".trumbowyg-editor").addClass("error");
        let errorMsg = translation("Veuillez remplir ce champ");
        $(blocError).removeClass("d-none").addClass("d-flex");
        $(blocError).find(".js-errorMsg").text(errorMsg);
        $(disabledButton).addClass("st-no-btn-disabled");
    }
    else {
        $(this).removeClass("error");
        $(".trumbowyg-editor").removeClass("error");
        $(blocError).removeClass("d-flex").addClass("d-none");
    }
});

$(".verif-siret").on("input change", function() {
    let getSiret = $(this).val();
    let validSiret = verifSiret(getSiret);
    let disabledButton = $(this).attr("data-button");
    let blocError = $(this).attr("data-errorBloc");
    if (!validSiret) {
        $(this).addClass("error");
        let errorMsg = translation("Il semblerait que ce SIRET n’existe pas");
        $(blocError).removeClass("d-none").addClass("d-flex");
        $(blocError).find(".js-errorMsg").text(errorMsg);
        $(disabledButton).addClass("st-no-btn-disabled");
    }
    else {
        $(blocError).removeClass("d-flex").addClass("d-none");
        $(this).removeClass('error');
    }
});

export const verifSiret = (siret) => {
    let siretFormat = siret.replaceAll(" ", "");
    if ((siretFormat.length != 14) || (isNaN(Number(siretFormat)))) {
        return false;
    }
    let sum = 0;
    let tmp;
    for (let i = 0; i < 14; i++) {
        if ((i % 2) === 0) {
            tmp = Number(siretFormat[i]) * 2;
            tmp = (tmp > 9 ? tmp - 9 : tmp);
        }
        else {
            tmp = Number(siretFormat[i]);
        }
        sum += tmp;
    }
    if ((sum % 10) == 0) {
        return true;
    }
    return false;
}

$(".verif-cif-nif").on("input change", function() {
    let validCifNif = verif_cif_nif($(this).val());
    let disabledButton = $(this).attr("data-button");
    let blocError = $(this).attr("data-errorBloc");
    if (!validCifNif) {
        $(this).addClass("error");
        let errorMsg = translation("Il semblerait que ce CIF/NIF n’existe pas");
        $(blocError).removeClass("d-none").addClass("d-flex");
        $(blocError).find(".js-errorMsg").text(errorMsg);
        $(disabledButton).addClass("st-no-btn-disabled");
    }
    else {
        $(blocError).removeClass("d-flex").addClass("d-none");
        $(this).removeClass('error');
    }
});

export const verif_cif_nif = (valueInput) => {  
    let value = valueInput.replaceAll(" ", "");
    var DNI_REGEX = /^(\d{8})([A-Z])$/;
    var CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
    var NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
    if (DNI_REGEX.test(value) || CIF_REGEX.test(value) || NIE_REGEX.test(value)) {
        return true;
    }
    else {
        return false;
    }
};

$(".verif-cardNumber").on("input change", function() {
    let val = $(this).val();
    let value = val.replace(/[^0-9]/g, '');
    let disabledButton = $(this).attr("data-button");
    let blocError = $(this).attr("data-errorBloc");
    if (!validCreditCard(value)) {
        $(this).addClass("error");
        let errorMsg = translation("Veuillez entrez un numéro de carte valide.");
        $(blocError).removeClass("d-none").addClass("d-flex");
        $(blocError).find(".js-errorMsg").text(errorMsg);
        $(disabledButton).addClass("st-no-btn-disabled");
    }
    else {
        $(blocError).removeClass("d-flex").addClass("d-none");
        $(this).removeClass("error");
    }
    $(this).val(value);
});

export const validCreditCard = (value) => {
    return /(^4[0-9]{12}(?:[0-9]{3})?$)|(^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$)|(3[47][0-9]{13})|(^3(?:0[0-5]|[68][0-9])[0-9]{11}$)|(^6(?:011|5[0-9]{2})[0-9]{12}$)|(^(?:2131|1800|35\d{3})\d{11}$)/.test(value);
}

$(".verif-iban").on("input change", function() {
    let regexIban = /^[a-zA-Z]{2}\d{2}\s*(\w{4}\s*){2,7}\w{1,4}\s*$/;
    let disabledButton = $(this).attr("data-button");
    let blocError = $(this).attr("data-errorBloc");
    if (!regexIban.test($(this).val())) {
        $(this).addClass("error");
        let errorMsg = translation("Veuillez renseigner un iban valide");
        $(blocError).removeClass("d-none").addClass("d-flex");
        $(blocError).find(".js-errorMsg").text(errorMsg);
        $(disabledButton).addClass("st-no-btn-disabled");
    }
    else {
        $(this).removeClass("error");
        $(blocError).removeClass("d-flex").addClass("d-none");
    }
});