$(".js-addExternalMember").on("click", function() {
    $(".blocAddExternalMember").toggle();
});

$(".js-cancelSharing").on("click", function() {
    $(".backdrop, .popin-wrap, .popin").hide();
});

$("#js-allMembersInput, .js-allMembersContent input").attr("checked", true);
$("#js-allMembersInput, .js-allMembersContent input").prop("checked", true);

$("#js-allMembersInput").on("click", function() {
    if ($(this).is(":checked")) {
        $(".js-allMembersContent input").attr("checked", true);
        $(".js-allMembersContent input").prop("checked", true);
        $(".js-submit-sharedwith").removeClass("st-no-btn-disabled");
    }
    else {
        $(".js-allMembersContent input").attr("checked", false);
        $(".js-allMembersContent input").prop("checked", false);
        $(".js-submit-sharedwith").addClass("st-no-btn-disabled");
    }
});

function checkedAllCheckbox() {
    $(".js-allMembersContent input").each(function() {
        $(this).on("click", function() {
            let flag = false;
            if (!$(this).is(":checked")) {
                $("#js-allMembersInput").attr("checked", false);
                $("#js-allMembersInput").prop("checked", false);
                $(".js-allMembersContent input").each(function() {
                    if ($(this).is(":checked")) {
                        flag = true;
                    }
                });
                if (!flag) {
                    $(".js-submit-sharedwith").addClass("st-no-btn-disabled");
                }
            }
            else {
                $(".js-submit-sharedwith").removeClass("st-no-btn-disabled");
            }
        });
    });
}

checkedAllCheckbox();


$(".js-allMembersContent label").each(function() {
    if ($(this).text().length > 24) {
        let oldText = $(this).text().substr(0, 24) + "...";
        $(this).text(oldText);
    }
});

if ($(".js-allMembersContent").find("div").length < 1) {
    $("#js-allMembersInput, .js-allMembers").hide();
    $(".js-blocAllMembers p").show();
    $(".js-submit-sharedwith").addClass("st-no-btn-disabled");
}

$(".externalMemberEmailInput").val("");
let i = 1;
$(".js-addMemberButton").on("click", function() {
    let getEmail = $(".externalMemberEmailInput").val();
    let divEmail = $("<div>", {class: "d-flex"});
    let checkboxEmail = $("<input>", {class: "invited", type: "checkbox", id: "email" + i});
    let labelEmail;
    if (getEmail.length > 24) {
        labelEmail = $("<label>", {text: getEmail.substr(0, 24) + "...", for: "email" + i});
    }
    else {
        labelEmail = $("<label>", {text: getEmail, for: "email" + i});
    }
    let flag = false;
    $(".js-allMembersContent input").each(function() {
        if ($(this).data("email") === getEmail) {
            flag = true;
        }
    });
    if (!flag) {
        $(divEmail).append(checkboxEmail, labelEmail);
        $(".js-allMembersContent").append(divEmail); 
        $(checkboxEmail).attr({"data-email": getEmail, "checked": true}).prop("checked", true);
        $(".externalMemberEmailInput").val("");
        $(".js-addMemberButton").addClass("st-no-btn-disabled");
        $(".errorMsgShareEmail").hide();
        $(".js-blocAllMembers p").hide();
        $(".js-blocAllMembers input, .js-blocAllMembers label").show();
        $(".js-submit-sharedwith").removeClass("st-no-btn-disabled");
        checkedAllCheckbox();
        i++;
    }
    else {
        $(".errorMsgShareEmail").show();
    }
});

$(document).on('click', '.js-submit-sharedwith', function() {
    $(this).addClass("st-no-btn-disabled");
    let idProject = $(this).data('idproject');
    let idFolder = $(this).data('idfolder');
    let idDocument = $(this).data('iddocument');
    let usersList = [];
    $(".popin-sharedwith .js-allMembersContent input").each(function() {
        if($(this).is(":checked")) {
            usersList.push($(this).data("email"));
        }
    });

    let promiseReturn = $.post("/freelance/projet/"+idProject+"/fichiers/dossier/"+idFolder+"/fichier/"+idDocument+"/partager_fichier", {
        shared: usersList
    });
    promiseReturn.then(function(val) {
        let v = JSON.parse(val);
        window.location.reload();
    });

    promiseReturn.catch(function(err) {
      window.location.reload();
    });
});

