import Muuri from 'muuri';
import {translation} from "../newScripts/generalFunctions";

let searchField;
let searchFieldValue;

let grid;
let gridFavourites;
let gridArchived;

if ($('.grid').length || $('.grid-favourites').length || $('.grid-archived').length) {
    const options = {
		layoutOnResize: false
	}
	
	grid = new Muuri('.grid', options);
    gridFavourites = new Muuri('.grid-favourites', options);
    gridArchived = new Muuri('.grid-archived', options);
  
    searchField = document.querySelector('.search-project');
    searchFieldValue = searchField.value.toLowerCase();
    $(document).on('keyup', '.search-project', function() {
      	let newSearch = searchField.value.toLowerCase();
      	if (searchFieldValue !== newSearch) {
        	searchFieldValue = newSearch;
        	searchProject();
			$('.js-dynamicSearchName').text(searchFieldValue);
      	}
    });

	const manageGridEmpty = (classGrid, projectClass, message, classList) => {
		let observerProjects = new MutationObserver(function(mutations) {
			let hiddenCardsNumber = 0;
			mutations.forEach(function() {
				$(projectClass).each(function() {
					if ($(this).css('display') != "none") hiddenCardsNumber += 1;
				})
			});  
			if (hiddenCardsNumber === 0) {
				$(classGrid).append(`<p class='${classList}'>${message}</p>`);
			} else {
				$(classGrid).children('p').remove();
			}

			$('.js-toClosedProjects').on('click', () => {
				$('.grid').hide();
				$('.grid-favourites').hide();
				$('.dash-project').hide();
				$('.dash-project-closed').show();
				document.querySelector(".js-select-mission").selectedIndex = 2;
			})
		});
		
		let targets = document.querySelectorAll(projectClass);
		targets.forEach((elem) => {
			observerProjects.observe(elem, { attributes : true, attributeFilter : ['style'] });
		})
	}
	manageGridEmpty('.grid-favourites', '.dash-project-favorite', translation('Aucun favoris'), 'st-margin-left-30px csecondary-3');
	manageGridEmpty('.grid', '.dash-project-folder', translation("Aucun résultat") + translation("Vérifiez dossiers archivés"), 'st-max-width-500px st-margin-left-30px csecondary-3');
	manageGridEmpty('.grid-archived', '.dash-project-closed', translation("Aucun résultat"), 'st-max-width-500px st-margin-left-30px csecondary-3');
}

export function searchProject() {
	[gridFavourites, grid, gridArchived].forEach((elem) => {
		elem.filter(function(item) {
			let element = item.getElement();
			if (element.getAttribute('data-target') != ".newproject" && element.getAttribute('data-title') != "title") {
				let isSearchMatch = !searchFieldValue ? true : (element.getAttribute('data-title') || '').toLowerCase().indexOf(searchFieldValue) > -1;
				if (isSearchMatch === false) {
					isSearchMatch = !searchFieldValue ? true : (element.getAttribute('data-name') || '').toLowerCase().indexOf(searchFieldValue) > -1;
				}
				return isSearchMatch;
			} else {
				  return true;
			}
		});
	})
}
  
/*Click on project*/
$(document).on('click', '.block-project', function(e) {
    let link = $(this).data('link');
    if (link)
    window.location.href = link;
});

$('.js-importProjectPhoto').on('click', (e) => {
	$('.js-submit-crop').attr('data-projectid', $(e.target).closest('.block-project').attr('data-id'));
})

$(".js-select-mission").val("inprogress");

const refreshGrids = () => {
	grid.refreshItems().layout();
	gridFavourites.refreshItems().layout();
	gridArchived.refreshItems().layout();
}

$(document).on('change', '.js-select-mission', function() {
    let val = $(this).val()
    if (val === 'closed') {
		//$('.grid').hide();
		$('.grid').show();
		$('.grid-favourites').hide();
      	$('.dash-project-closed').show();
		$('.dash-project').hide();
		$('.js-folderDashboard').hide();
		$('.js-folderUncomplete').show();
		$('.js-folderFavoriteDashboard').hide();
		refreshGrids();
    }
    else if (val === "favorites") {
		$('.grid').hide();
		$('.grid-favourites').show();
		$('.dash-project-closed').hide();
		$('.dash-project').show();
		$('.js-folderUncomplete').hide();
		$('.js-folderDashboard').hide();
		$('.js-folderFavoriteDashboard').show();
		searchProject();
		refreshGrids();	
    }
    else {
		$('.grid').show();
		$('.grid-favourites').show();
		$('.dash-project-closed').hide();
		$('.dash-project').show();
		$('.js-folderUncomplete').hide();
		$('.js-folderDashboard').show();
		$('.js-folderFavoriteDashboard').show();
		searchProject();
		refreshGrids();	
    }

	// if (val === 'closed') {
    //   	$('.dash-project-closed').show();
	// 	$('.dash-project').hide();
	// 	$('.js-folderDashboard').hide();
	// 	$('.js-folderFavoriteDashboard').hide();
	// 	refreshGrids();
    // }
    // else if (val === "favorites") {
	// 	$('.grid').hide();
	// 	$('.grid-favourites').show();
	// 	$('.dash-project-closed').hide();
	// 	$('.dash-project').show();
	// 	$('.js-folderDashboard').hide();
	// 	$('.js-folderFavoriteDashboard').show();
	// 	searchProject();
	// 	refreshGrids();	
    // }
    // else {
	// 	$('.grid').show();
	// 	$('.grid-favourites').show();
	// 	$('.dash-project-closed').hide();
	// 	$('.dash-project').show();
	// 	$('.js-folderDashboard').show();
	// 	$('.js-folderFavoriteDashboard').show();
	// 	searchProject();
	// 	refreshGrids();	
    // }
});