import $, {data} from "jquery";
import {searchProject} from '../../plugins/muuri.js';
import {numberDaysDelate, differenceDate, convertPrice, dateConversion, translation, delay} from "../generalFunctions";

$("#js-servicesSoan").on("change", function () {
});

$("#js-servicesSoan").val("ALL");
$("#js-sortBills").val("LAST");
$("#js-sortActions").val("ALL");
$(".js-search-dashboard").val("");

let arrayRowType = {
  "CREDITED_PAYMENT": 0,
  "DEBITED_PAYMENT": 0,
  "SUPPLIER_PAYMENT": 0,
  "DELAYED_INVOICE": 0,
  "DELAYED_PAYMENT": 0,
  "RECOVERY": 0,
  "DOCUMENT": 0,
  "EQUOTATION": 0
}

function addElementDashboard(displayType, usernameData, paymentNameData, infoSupData, priceData,
                             thDiscountDaysLimit, thDiscountableRate, thIsDiscountable, sortDate, srcImg, altImg,
                             statement, recoveryData, hrefRecoveryData, buttonData, classActionButton, srcData, classMarkAsPayed,
                             paymentId, projectId, dueDateData, labelMultipayment, i, paymentStatus, paymentMarkAsPayed, filename, status, domain) {

  let paymentPrice;
  let getPriceConverted;
  let getDiscountPrice;
  let classDiscountPrice;
  let classLabelDiscount;


  if (displayType === "CREDITED_PAYMENT") {
    getPriceConverted = "+ " + convertPrice(priceData);
    getDiscountPrice = discountPrice(priceData, thDiscountDaysLimit, thDiscountableRate, thIsDiscountable, sortDate, "+ ");
  } else if (displayType === "DEBITED_PAYMENT") {
    getPriceConverted = "- " + convertPrice(priceData);
    getDiscountPrice = discountPrice(priceData, thDiscountDaysLimit, thDiscountableRate, thIsDiscountable, sortDate, "- ");
  }  else if (displayType === "SUPPLIER_PAYMENT") {
    if (priceData === "-") {
      getPriceConverted = priceData;
    }
    else {
      getDiscountPrice = discountPrice(priceData, thDiscountDaysLimit, thDiscountableRate, thIsDiscountable, sortDate, "- ");
      getPriceConverted = "- " + convertPrice(priceData);
    }
  } else if (displayType === "RECOVERY") {
    getPriceConverted = "+ " + convertPrice(priceData);
    getDiscountPrice = discountPrice(priceData, thDiscountDaysLimit, thDiscountableRate, thIsDiscountable, sortDate, "+ ");
  } else if (displayType === "DELAYED_PAYMENT") {
    getPriceConverted = "- " + convertPrice(priceData);
    getDiscountPrice = discountPrice(priceData, thDiscountDaysLimit, thDiscountableRate, thIsDiscountable, sortDate, "- ");
  } else if (displayType === "DELAYED_INVOICE") {
    getPriceConverted = "+ " + convertPrice(priceData);
    getDiscountPrice = discountPrice(priceData, thDiscountDaysLimit, thDiscountableRate, thIsDiscountable, sortDate, "+ ");
  } else if (displayType === "DOCUMENT") {
    getPriceConverted = "- ";
    getDiscountPrice = "";
  } else {
    getPriceConverted = convertPrice(priceData);
  }

  if (getDiscountPrice === undefined) {
    classDiscountPrice = "d-none";
    classLabelDiscount = "d-none";
    paymentPrice = $("<p>", {
      text: getPriceConverted,
      class: "st-amountPrice"
    });
  } else {
    if (displayType === "DOCUMENT") {
      classLabelDiscount = "d-none";
    } else {
      classLabelDiscount = "st-escompteLabelCustom";
    }
    classDiscountPrice = "st-escompteDashboard";
    paymentPrice = $("<del>", {
      text: getPriceConverted,
      class: "st-amountPrice"
    });
  }

  // Creation Button Action with his data for popin "Payer"
  let link = $("<a>", {
    text: buttonData,
    class: classActionButton,
    href: srcData,
    id: "buttonAction" + i
  });

  let dateLimitDiscount = getDateLimitDiscount(sortDate, thDiscountDaysLimit);

  if (thDiscountableRate === null) {
    $(link).attr("data-discount", null);
    $(link).attr("data-discountlimit", null);
  } else {
    $(link).attr("data-discount", thDiscountableRate);
    $(link).attr("data-discountlimit", dateLimitDiscount);
  }
  if ($('.st-arrayDashboard').attr('data-isprofilcomplete') === '') {
    $(link).attr("data-target", ".popin-need-info");
  } else {
    $(link).attr("data-target", ".popin-payment-bank");
  }
  $(link).attr("data-amount", priceData);
  $(link).attr("data-paymentid", paymentId);
  $(link).attr("data-bills", paymentNameData);
  $(link).attr("data-projectid", projectId);
  $(link).attr("data-paymentduedate", dueDateData);

  // if we are not on MOBILE we construct DOM like it
  if ($(window).width() >= 768) {
    // One Row of Dashboard Array
    let rowDashboard = $("<div>", {
      class: "st-rowDashboard d-flex",
      id: "row"+paymentId
    });

    $(rowDashboard).data("displayType", displayType);

    // Column Paymentname
    let paymentName = $("<div>", {
      class: "st-userName",
      id: "st-userName" + i
    });

    let paymentNameP;
    let paymentNameLength = paymentNameData ? paymentNameData.length : 0;
    if (paymentNameLength > 27) {
      let newPaymentName = paymentNameData.substr(0, 26) + '...  ';
      paymentNameP = $("<p>", {
        html: newPaymentName + "<br> <span id='infoSupData" + i + "'>" + infoSupData + "</span>"
      });
    } else {
      paymentNameP = $("<p>", {
        html: (paymentNameData ? paymentNameData : "-") + "<br> <span id='infoSupData" + i + "'>" + infoSupData + "</span>"
      });
    }

    // Column username

    let userLength = usernameData.length;
    let username

    if (userLength > 25) {
      let newUsername = usernameData.substr(0, 24) + '...  ';
      username = $("<div>", {
        class: "st-paymentName",
        id: "st-paymentName" + i,
        html: "<p>" + newUsername + "</p>"
      });
    } else {
      username = $("<div>", {
        class: "st-paymentName",
        id: "st-paymentName" + i,
        html: "<p>" + usernameData + "</p>"
      });
    }

    // Column paymentPrice and icone state
    let paymentPriceIconState = $("<div>", {
      class: "st-paymentPrice d-flex",
      id: "st-paymentPrice" + i
    });

    let blocPaymentPrice = $("<div>");

    let paymentDiscountPrice = $("<p>", {
      html: getDiscountPrice,
      class: classDiscountPrice
    });

    let imgLabelEscompte = $("<div>", {
      html: "<p class='" + classLabelDiscount + "'>" + translation("Escompte") + "</p>",
      width: "130px"
    });

    let htmlStatement = "<div class='d-flex'><img id='iconStatement" + i + "' src='" + srcImg + "' alt='" + altImg + "'> <p id='statement" + i + "'>" + statement + "</p></div><div>" + labelMultipayment + "</div>";
    let statePayment = $("<div>", {
      class: "st-statePayment",
      html: htmlStatement
    });

    // Column paymentAction
    let paymentAction = $("<div>", {
      class: "st-paymentAction d-flex"
    });

    let recovery = $("<a>", {
      text: translation("Recouvrir"),
      class: recoveryData,
      id: "recovery" + i,
      href: hrefRecoveryData
    });

    // icone mark as payed
    let divMarkAsPayed = $("<div>", {
      class: classMarkAsPayed + " supplierPayment",
      id: "blocMarkAsPayed" + i
    });

    let divOperationAction = $("<div>", {
      class: "js-operationAction st-operationAction",
      id: "operationAction" + i
    })

    let point1 = $("<p>", {
      class: "st-points"
    });

    let point2 = $("<p>", {
      class: "st-points"
    });

    let point3 = $("<p>", {
      class: "st-points"
    });

    let pMarkAsPayedOrNot;
    let pCopyPaymentLink;

    if (paymentStatus !== "SUCCEEDED") {
      if (displayType === "SUPPLIER_PAYMENT") {
        if (statement !== "Infos manquantes") {
          pMarkAsPayedOrNot = $("<p>", {
            class: "st-pMarkAsPayed",
            text: translation("Marquer comme payé"),
            id: "markAsPayedSupplier" + i
          });
          pCopyPaymentLink = $("<p>", {
            class: "st-pCopyPaymentLink st-pMarkAsPayed",
            text: translation("Supprimer"),
            id: "deletePayment" + i
          });
        }
        else {
          pMarkAsPayedOrNot = $("<p>", {
            class: "st-pMarkAsPayed",
            text: translation("Supprimer"),
            id: "deletePayment" + i
          })
        }
      }
      else if (displayType === "EQUOTATION") {
        pMarkAsPayedOrNot = $("<p>", {
          class: "st-pMarkAsPayed",
          text: translation("Supprimer"),
          id: "deletePaymentDevis" + i
        })
      }
      else {
        pMarkAsPayedOrNot = $("<p>", {
          class: "st-pMarkAsPayed",
          text: translation("Marquer comme payé"),
          id: "markAsPayed" + i
        });
        pCopyPaymentLink = $("<p>", {
          class: "st-pCopyPaymentLink st-pMarkAsPayed",
          text: translation("Lien de paiement"),
          id: "paymentLink" + i
        });
      }
    }
    else if (paymentMarkAsPayed) {
      if (displayType === "SUPPLIER_PAYMENT") {
        if (statement !== "Infos manquantes") {
          pMarkAsPayedOrNot = $("<p>", {
            class: "st-pMarkAsPayed",
            text: translation("Ne pas marquer comme payé"),
            id: "markAsUnpayedSupplier" + i
          });
          pCopyPaymentLink = $("<p>", {
            class: "st-pCopyPaymentLink st-pCopyPaymentLinkSupplier st-pMarkAsPayed",
            text: translation("Supprimer"),
            id: "deletePayment" + i
          });
        }
        else {
          pMarkAsPayedOrNot = $("<p>", {
            class: "st-pMarkAsPayed",
            text: translation("Supprimer"),
            id: "deletePayment" + i
          })
        }
      }
      else {
        pMarkAsPayedOrNot = $("<p>", {
          class: "st-pMarkAsPayed",
          text: translation("Ne pas marquer comme payé"),
          id: "markAsUnpayed" + i
        });
      }
    }

    let linkForLogoGoToProject = $("<a>", {
      href: "/freelance/projet/" + projectId + "/paiement",
      class: "js-stopPropagation st-margin-right-10px st-cursor-pointer"
    });

    if (displayType === "SUPPLIER_PAYMENT") {
      linkForLogoGoToProject = $("<a>", {
        href: filename ,
        class: "js-stopPropagation st-margin-right-10px st-cursor-pointer",
        target: "_blank"
      })
    }

    if (displayType === "EQUOTATION") {
      linkForLogoGoToProject = $("<a>", {
        href: "http://" + domain + "/devis?step="+ (status === "DOING" || status === "DONE" ? "3":"2") +"&id=" + paymentId,
        class: "js-stopPropagation st-margin-right-10px st-cursor-pointer",
        target: "_blank",
      });
    }

    let logoPath = "/build/images/logo_gotoproject.png";
    if (displayType === "SUPPLIER_PAYMENT") {
      logoPath = "/build/images/icon-document.svg"
    }
    let logoGoToProject = $("<img>", {
      src: logoPath,
      class: "js-stopPropagation st-cursor-pointer",
      height: "25px",
      width: "25px"
    });


    // all append elements
    $(linkForLogoGoToProject).append(logoGoToProject);
    $(paymentName).append(paymentNameP);
    $(paymentName).append(linkForLogoGoToProject);
    $(rowDashboard).append(paymentName);

    $(rowDashboard).append(username);

    $(blocPaymentPrice).append(paymentPrice);
    $(blocPaymentPrice).append(paymentDiscountPrice);
    $(blocPaymentPrice).append(imgLabelEscompte);
    $(paymentPriceIconState).append(blocPaymentPrice);
    $(paymentPriceIconState).append(statePayment);
    $(rowDashboard).append(paymentPriceIconState);

    $(paymentAction).append(recovery);
    $(paymentAction).append(link);
    $(divMarkAsPayed).append(point1);
    $(divMarkAsPayed).append(point2);
    $(divMarkAsPayed).append(point3);
    $(divMarkAsPayed).append(divOperationAction);
    $(divOperationAction).append(pMarkAsPayedOrNot);
    $(divOperationAction).append(pCopyPaymentLink);
    $(paymentAction).append(divMarkAsPayed);
    $(rowDashboard).append(paymentAction);

    $(".js-arrayDashboard").append(rowDashboard);

    $(paymentAction).attr('data-project', projectId);
    $(paymentAction).attr('data-payment', paymentId);
    arrayRowType[displayType] += 1;
  }
  // Else we are on MOBILE
  else {
    let rowDashboard = $("<div>", {
      class: "st-rowDashboard d-flex",
      id: "row"+paymentId
    });

    $(rowDashboard).data("displayType", displayType);

    // BLOC TOP
    let blocTopDashboard = $("<div>", {
      class: "st-blocTopDashboard d-flex",
      id: "blocTopDashboard" + i
    });

    // BLOC TOP LEFT
    let divTopLeft = $("<div>", {
      class: "st-divTopLeft",
      id: "divTopLeft" + i
    });

    let paymentNameLength = paymentNameData ? paymentNameData.length : 0;
    let newPaymentName;
    if (paymentNameLength > 32) {
      newPaymentName = paymentNameData.substr(0, 31) + '...  ';
    } else {
      newPaymentName = paymentNameData ? paymentNameData : "-";
    }
    let paymentNameP = $("<p>", {
      text: newPaymentName
    });

    let usernameLength = usernameData.length;
    let newUsername;
    if (usernameLength > 25) {
      newUsername = usernameData.substr(0, 24) + '...  ';
    } else {
      newUsername = usernameData;
    }
    let username = $("<p>", {
      text: newUsername
    });

    // BLOC TOP RIGHT
    let divTopRight = $("<div>", {
      class: "st-divTopRight d-flex flex-column"
    });

    let paymentDiscountPrice = $("<p>", {
      html: getDiscountPrice,
      class: classDiscountPrice
    });

    let imgLabelEscompte = $("<div>", {
      html: "<p class='" + classLabelDiscount + "'>" + translation("Escompte") + "</p>",
    });

    let divMarkAsPayed;

    if (displayType === "SUPPLIER_PAYMENT") {
      divMarkAsPayed = $("<div>", {
        class: classMarkAsPayed + " supplierPayment",
        id: "blocMarkAsPayed" + paymentId
      });
    }
    else if (displayType === "EQUOTATION") {
      divMarkAsPayed = $("<div>", {
        class: classMarkAsPayed,
        id: "deleteDevis" + paymentId
      });
    }
    else {
      divMarkAsPayed = $("<div>", {
        class: classMarkAsPayed,
        id: "blocMarkAsPayed" + paymentId
      });
    }

    let divOperationAction = $("<div>", {
      class: "js-operationAction st-operationAction",
      id: "operationAction" + i
    })

    let point1 = $("<p>", {
      class: "st-points"
    });

    let point2 = $("<p>", {
      class: "st-points"
    });

    let point3 = $("<p>", {
      class: "st-points"
    });

    let pMarkAsPayedOrNot;
    let pCopyPaymentLink;

    if (paymentStatus !== "SUCCEEDED") {
      if (displayType === "SUPPLIER_PAYMENT") {
        if (statement !== "Infos manquantes") {
          pMarkAsPayedOrNot = $("<p>", {
            class: "st-pMarkAsPayed",
            text: translation("Marquer comme payé"),
            id: "markAsPayedSupplier" + i
          });
          pCopyPaymentLink = $("<p>", {
            class: "st-pCopyPaymentLink st-pMarkAsPayed",
            text: translation("Supprimer"),
            id: "deletePayment" + i
          });
        }
        else {
          pMarkAsPayedOrNot = $("<p>", {
            class: "st-pMarkAsPayed",
            text: translation("Supprimer"),
            id: "deletePayment" + i
          })
        }
      }
      else {
        pMarkAsPayedOrNot = $("<p>", {
          class: "st-pMarkAsPayed",
          text: translation("Marquer comme payé"),
          id: "markAsPayed" + i
        });
        pCopyPaymentLink = $("<p>", {
          class: "st-pCopyPaymentLink st-pMarkAsPayed",
          text: translation("Lien de paiement"),
          id: "paymentLink" + i
        });
      }
    }
    else if (paymentMarkAsPayed) {
      if (displayType === "SUPPLIER_PAYMENT") {
        if (statement !== "Infos manquantes") {
          pMarkAsPayedOrNot = $("<p>", {
            class: "st-pMarkAsPayed",
            text: translation("Ne pas marquer comme payé"),
            id: "markAsUnpayedSupplier" + i
          });
          pCopyPaymentLink = $("<p>", {
            class: "st-pCopyPaymentLink st-pCopyPaymentLinkSupplier st-pMarkAsPayed",
            text: translation("Supprimer"),
            id: "deletePayment" + i
          });
        }
        else {
          pMarkAsPayedOrNot = $("<p>", {
            class: "st-pMarkAsPayed",
            text: translation("Supprimer"),
            id: "deletePayment" + i
          })
        }
      }
      else {
        pMarkAsPayedOrNot = $("<p>", {
          class: "st-pMarkAsPayed",
          text: translation("Ne pas marquer comme payé"),
          id: "markAsUnpayed" + i
        });
      }
    }

    // BLOC BOTTOM
    let blocBottomDashboard = $("<div>", {
      class: "st-blocBottomDashboard d-flex",
    });

    // BLOC BOTTOM LEFT
    let divBottomLeft = $("<div>", {
      class: "st-divBottomLeft",
      id: "divBottomLeft" + i
    });

    let htmlStatement = "<div class='d-flex'><img id='iconStatement" + i + "' src='" + srcImg + "' alt='" + altImg + "'> <p id='statement" + i + "'>" + statement + "</p></div><div>" + labelMultipayment + "</div>";
    let statePayment = $("<div>", {
      class: "st-statePayment",
      html: htmlStatement
    });

    let linkForLogoGoToProject = $("<a>", {
      href: "/freelance/projet/" + projectId + "/paiement",
      class: "js-stopPropagation st-margin-right-10px st-cursor-pointer"
    });

    let logoGoToProject = $("<img>", {
      src: "/build/images/logo_gotoproject.png",
      class: "js-stopPropagation st-cursor-pointer",
      height: "25px",
      width: "25px"
    });

    // BLOC BOTTOM CENTER
    let divBottomCenter = $("<div>", {
      class: "st-divBottomCenter",
    });

    let recovery = $("<a>", {
      text: translation("Recouvrir"),
      class: recoveryData,
      id: "recovery" + i,
      href: hrefRecoveryData
    });

    // BLOC BOTTOM RIGHT
    let divBottomRight = $("<div>", {
      class: "st-divBottomRight",
    });

    $(linkForLogoGoToProject).append(logoGoToProject);
    $(divTopLeft).append(paymentNameP);
    $(divTopLeft).append(username);

    $(divMarkAsPayed).append(point1);
    $(divMarkAsPayed).append(point2);
    $(divMarkAsPayed).append(point3);
    $(divMarkAsPayed).append(divOperationAction);
    $(divOperationAction).append(pMarkAsPayedOrNot);
    $(divOperationAction).append(pCopyPaymentLink);
    $(rowDashboard).append(divMarkAsPayed);
    $(divTopRight).append(paymentPrice);
    $(divTopRight).append(paymentDiscountPrice);
    $(divTopRight).append(imgLabelEscompte);

    $(blocTopDashboard).append(divTopLeft);
    $(blocTopDashboard).append(divTopRight);
    $(rowDashboard).append(blocTopDashboard);


    $(divBottomLeft).append(linkForLogoGoToProject);
    $(divBottomLeft).append(statePayment);
    $(divBottomCenter).append(recovery);
    $(divBottomRight).append(link);

    $(blocBottomDashboard).append(divBottomLeft);
    $(blocBottomDashboard).append(divBottomCenter);
    $(blocBottomDashboard).append(divBottomRight);

    $(rowDashboard).append(blocBottomDashboard);
    $(".js-arrayDashboard").append(rowDashboard);

    arrayRowType[displayType] += 1;
  }

  $('.js-stopPropagation').on('click', (e) => {
    e.stopImmediatePropagation();
  })
  open();
}

let saveNumberDataEntries;
let dataRequest;
let flag;
let i = 0;

function getDataRequest(iMax, isSearch = false, getValueSortServices = $("#js-servicesSoan").val(),

	getValueSortBills = $("#js-sortBills").val(), getValueSortActions = $("#js-sortActions").val()) {

  //await functionToWait();
  let sortServicesData = getValueSortServices;
  let sortBillsData = getValueSortBills;
  let sortActionsData = getValueSortActions;
  let infoSupData = "";
  let recovery = "";
  let nameData;
  let iconState;
  let statePayment;
  let altState;
  let hrefRecovery;
  let textActionButton;
  let classActionButton;
  let urlActionButton;
  let divMarkAsPayed;
  let divOperationAction;
  let searchText = $(".js-search-dashboard").val();

  let promiseReturn = $.post("/freelance/find_data_home", {
    order: sortBillsData,
    filter: sortActionsData,
    displayType: sortServicesData,
    arrayRowType: arrayRowType,
    search: searchText
  });
  promiseReturn.then(function (val) {
    let v = JSON.parse(val);
    if (v.status === 'success') {
      let j = 0;
      dataRequest = v.data.length;
      saveNumberDataEntries = v.data;
      if (v.data.length > 0) {
        v.data.forEach(element => {
          let labelMultipayment = '';
          if (iMax > 0) {
            hrefRecovery = "/freelance/projet/" + element.projectId + "/recouvrements/nouveau?payment_id=" + element.id;
            if ($('.st-arrayDashboard').attr('data-isprofilcomplete') !== '') {
              if (element.link) {
                urlActionButton = "/freelance" + element.link;
              }
              else {

                urlActionButton = "/freelance/operations";
              }
            }

            if (element.name === null) {
              nameData = "- ";
            } else {
              nameData = element.name;
            }

            if (element.type === "DEBITED_PAYMENT") {
              textActionButton = translation("Payer");
              // urlActionButton = urlActionButton + "?paymentId=" + element.id + "&projectId=" + element.projectId;
              if (element.thIsMultiPayment === '1') {
                labelMultipayment = '<p class="st-3xLabelCustom">' + translation('3x sans frais') + '</p>';
              }
              if (element.paymentStatus === null) {

                if (differenceDate(element.dueDate) > 0) {
                  if (numberDaysDelate(element.dueDate) > 0) {
                    infoSupData = translation("Échue depuis") + " " + numberDaysDelate(element.dueDate) + " " + translation("jours");
                  } else {
                    infoSupData = translation("Échue depuis") + " " + numberDaysDelate(element.dueDate) + " " + translation("jour");
                  }
                  statePayment = translation("Retard");
                  iconState = "/build/images/pastille-retard.svg";
                  altState = "icone pastille retard";
                  recovery = "d-none";
                  divMarkAsPayed = "d-none";
                } else {
                  infoSupData = translation("Emis le") + " " + dateConversion(element.sortDate);
                  statePayment = translation("À régler");
                  iconState = "/build/images/pastille-en-attente.svg";
                  altState = "icone pastille en attente";
                  recovery = "d-none";
                  divMarkAsPayed = "d-none";
                }
                if ($('.st-arrayDashboard').attr('data-isprofilcomplete') === '') {
                  classActionButton = "actionButtonToPay js-openpopin";
                } else {
                  classActionButton = "actionButtonToPay js-openpopin-bank click" + element.id + " " + element.projectId;
                }
              } else {
                if (element.paymentStatus === "CREATED") {
                  infoSupData = translation("En cours depuis le") + " " + dateConversion(element.payedDate);
                  statePayment = translation("En cours de transfert");
                  iconState = "/build/images/pastille-en-attente.svg";
                  altState = "icone pastille en attente";
                  recovery = "d-none";
                  divMarkAsPayed = "d-none";
                } else if (element.paymentStatus === "SUCCEEDED") {
                  if (element.dueDate !== null) {
                    infoSupData = translation("Réglé le") + " " + dateConversion(element.payedDate);
                  }
                  statePayment = translation("Réglé");
                  iconState = "/build/images/icon_check-black.svg";
                  altState = "icone checked";
                  recovery = "d-none";
                  divMarkAsPayed = "d-none";
                } else if (element.paymentStatus === "FAILED") {
                  infoSupData = translation("Emis le") + " " + dateConversion(element.sortDate);
                  statePayment = translation("Échec");
                  iconState = "/build/images/pastille-echec.svg";
                  altState = "icone pastille échec";
                  recovery = "d-none";
                  divMarkAsPayed = "d-none";
                }
                if ($('.st-arrayDashboard').attr('data-isprofilcomplete') === '') {
                  classActionButton = "actionButtonToPay js-openpopin";
                } else {
                  classActionButton = "invisible";
                }
              }
            } else if (element.type === "CREDITED_PAYMENT") {
              infoSupData = translation("En cours depuis le") + " ";

              if (element.paymentStatus === null) {

                if (differenceDate(element.dueDate) > 0) {
                  if (numberDaysDelate(element.dueDate) > 0) {
                    infoSupData = translation("Échue depuis") + " " + numberDaysDelate(element.dueDate) + " " + translation("jours");
                  } else {
                    infoSupData = translation("Échue depuis") + " " + numberDaysDelate(element.dueDate) + " " + translation("jour");
                  }
                  statePayment = translation("Retard");
                  iconState = "/build/images/pastille-retard.svg";
                  altState = "icone pastille retard";
                  recovery = "st-showRecovery";
                  textActionButton = translation("Relancer");
                  classActionButton = "actionButtonToPay";
                  divMarkAsPayed = "st-divMarkAsPayed";
                } else {
                  infoSupData = translation("Emis le") + " " + dateConversion(element.sortDate);
                  statePayment = translation("En attente");
                  iconState = "/build/images/pastille-en-attente.svg";
                  altState = "icone pastille en attente";
                  recovery = "d-none";
                  textActionButton = translation("Rappel");
                  classActionButton = "actionButtonToPay";
                  divMarkAsPayed = "st-divMarkAsPayed";
                  urlActionButton = "/freelance" + element.link + "/" + element.id + "/relance";
                }
              } else {
                if (element.paymentStatus === "CREATED") {
                  infoSupData = translation("En cours depuis le") + " " + dateConversion(element.payedDate);
                  statePayment = translation("En cours de transfert");
                  iconState = "/build/images/pastille-en-attente.svg";
                  altState = "icone pastille en attente";
                  recovery = "d-none";
                  classActionButton = "invisible";
                  divMarkAsPayed = "st-divMarkAsPayed";
              
                } else if (element.paymentStatus === "SUCCEEDED") {
                  if (element.dueDate !== null) {
                    infoSupData = translation("Réglé le") + " " + dateConversion(element.payedDate);
                  }
                  statePayment = translation("Réglé");
                  iconState = "/build/images/icon_check-black.svg";
                  altState = "icone checked";
                  recovery = "d-none";
                  classActionButton = "invisible";
                  if (element.mark_as_payed) {
                    divMarkAsPayed = "st-divMarkAsPayed";
                  }
                  else {
                    divMarkAsPayed = "d-none";
                  }

                } else if (element.paymentStatus === "FAILED") {
                  infoSupData = translation("Emis le") + " " + dateConversion(element.sortDate);
                  statePayment = translation("Échec");
                  iconState = "/build/images/pastille-echec.svg";
                  altState = "icone pastille échec";
                  recovery = "d-none";
                  classActionButton = "invisible";
                  divMarkAsPayed = "st-divMarkAsPayed";
                }
              }
            } else if (element.type === "RECOVERY") {
              infoSupData = translation("Recouvrement lancé le") + " " + dateConversion(element.sortDate);
              statePayment = translation("Somme récupérée :") + " " + convertPrice(element.recoveredAmount);
              iconState = "/build/images/pastille-en-attente.svg";
              altState = "icone pastille en attente";
              classActionButton = "invisible";
              recovery = "d-none";
              divMarkAsPayed = "d-none";
            } else if (element.type === "DELAYED_PAYMENT") {
              infoSupData = translation("Programmé le") + " " + dateConversion(element.delayedPaymentDate);
              statePayment = translation("Programmé");
              textActionButton = translation("Modifier");
              classActionButton = "actionButtonToSign";
              recovery = "d-none";
              divMarkAsPayed = "d-none";
              iconState = "/build/images/pastille-en-attente.svg";
              altState = "icone pastille en attente";
            } else if (element.type === "DELAYED_INVOICE") {
              infoSupData = translation("Programmé le") + " " + dateConversion(element.delayedInvoiceDate);
              statePayment = translation("Programmé");
              textActionButton = translation("Modifier");
              classActionButton = "actionButtonToSign";
              recovery = "d-none";
              divMarkAsPayed = "d-none";
              iconState = "/build/images/pastille-en-attente.svg";
              altState = "icone pastille en attente";
            } else if (element.type === "DOCUMENT") {

              if (element.documentType === "toValidate" || element.documentType === "validate") {
                if (element.isValidated === "1") {
                  classActionButton = "invisible";
                  statePayment = translation("Validation : terminée");
                  iconState = "/build/images/icon_check-black.svg";
                  altState = "icone checked";
                  recovery = "d-none";
                  divMarkAsPayed = "d-none";
                } else {
                  if (element.documentType === "toValidate") {
                    textActionButton = translation("Valider");
                    classActionButton = "actionButtonToSign";
                    statePayment = translation("À valider");
                    iconState = "/build/images/pastille-en-attente.svg";
                    altState = "icone pastille en attente";
                    recovery = "d-none";
                    divMarkAsPayed = "d-none";
                  } else {
                    classActionButton = "invisible";
                    statePayment = translation("Validation en cours");
                    iconState = "/build/images/pastille-en-attente.svg";
                    altState = "icone pastille en attente";
                    recovery = "d-none";
                    divMarkAsPayed = "d-none";
                  }
                }
              } else if (element.documentType === "toSign" || element.documentType === "sign") {
                if (element.isSigned === "1") {
                  classActionButton = "invisible";
                  statePayment = translation("Signature : terminée");
                  iconState = "/build/images/icon_check-black.svg";
                  altState = "icone checked";
                  recovery = "d-none";
                  divMarkAsPayed = "d-none";
                } else {
                  if (element.documentType === "toSign") {
                    textActionButton = translation("Signer");
                    classActionButton = "actionButtonToSign";
                    statePayment = translation("À signer");
                    iconState = "/build/images/pastille-en-attente.svg";
                    altState = "icone pastille en attente";
                    recovery = "d-none";
                    divMarkAsPayed = "d-none";
                  } else {
                    classActionButton = "invisible";
                    statePayment = translation("Signature en cours");
                    iconState = "/build/images/pastille-en-attente.svg";
                    altState = "icone pastille en attente";
                    recovery = "d-none";
                    divMarkAsPayed = "d-none";
                  }
                }
              }
            }
            else if (element.type === "SUPPLIER_PAYMENT") {
              if (element.status === 'TODO') {
                classActionButton = "st-to-complete"
                textActionButton = translation("Compléter");
                statePayment = translation("Infos manquantes");
                iconState = "/build/images/pastille-rouge.svg";
                recovery = "d-none";
                altState = "icone d'info manquante";
                divMarkAsPayed = "d-block st-divMarkAsPayed supplierPayment"+j;
                if (element.dueDate) {
                  infoSupData = translation("À régler avant") + " " + dateConversion(element.dueDate);
                }
                else {
                  infoSupData = translation("Importé le") + " " + dateConversion(element.date);
                }
              }
              else if (element.status === 'DONE') {
                if (element.delayedPaymentDate) {
                  infoSupData = translation("Programmé le") + " " + dateConversion(element.delayedPaymentDate);
                }
                else {
                  infoSupData = translation("Réglé le") + " " + dateConversion(element.payedDate);
                }
                iconState = "/build/images/icon_check-black.svg";
                statePayment = translation("Réglé");
                classActionButton = "invisible";
                altState = "icone checked";
                recovery = "d-none";
                if (element.mark_as_payed) {
                  divMarkAsPayed = "st-divMarkAsPayed d-block supplierPayment"+j;
                } else {
                  divMarkAsPayed = "d-none";
                }
              }
              else if (element.status === 'DOING') {
                recovery = "d-none";
                divMarkAsPayed = "st-divMarkAsPayed d-block supplierPayment"+j;
                if (element.paymentStatus === 'FAILED') {
                  statePayment = translation("Échec");
                  iconState = "/build/images/pastille-rouge.svg";
                  infoSupData = translation("À régler avant") + " " + dateConversion(element.dueDate);
                  classActionButton = "actionButtonToPay";
                  textActionButton = translation("Payer");
                }
                else if (element.delayedPaymentDate) {
                  classActionButton = "actionButtonToPayNow";
                  textActionButton = translation("Payer maintenant");
                  statePayment = translation("Paiement programmé");
                  infoSupData = translation("Programmé pour le") + " " + dateConversion(element.delayedPaymentDate);
                  if (differenceDate(element.dueDate) > 0) {
                    iconState = "/build/images/pastille-rouge.svg";
                  }
                  else {
                    iconState = "/build/images/pastille-en-attente.svg";
                  }
                }
                else if (differenceDate(element.dueDate) > 0 && element.paymentStatus !== "FAILED") {
                  statePayment = translation("Retard");
                  iconState = "/build/images/pastille-rouge.svg";
                  infoSupData = translation("À régler avant") + " " + dateConversion(element.dueDate);
                  classActionButton = "actionButtonToPay";
                  textActionButton = translation("Payer");
                }
                else {
                  infoSupData = translation("À régler avant") + " " + dateConversion(element.dueDate);
                  classActionButton = "actionButtonToPay";
                  textActionButton = translation("Payer");
                  if (element.approvalStatus) {
                    if (element.approvalStatus === "CREATED") {
                      statePayment = translation("Approbation demandée")
                      iconState = "/build/images/pastille-grise.svg";
                    }
                    else if (element.approvalStatus === "DENIED") {
                      statePayment = translation("Approbation refusée")
                      iconState = "/build/images/pastille-rouge.svg";
                    }
                    else if (element.approvalStatus === "APPROVED") {
                      statePayment = translation("Approbation validée");
                      iconState = "/build/images/pastille-en-attente.svg";
                    } else {
                      statePayment = "-";
                      iconState = "/build/images/pastille-en-attente.svg";
                    }
                  }
                  else {
                    statePayment = translation("À régler");
                    iconState = "/build/images/pastille-verte.svg";
                  }
                  if (differenceDate(element.dueDate) > 0) {
                    if (numberDaysDelate(element.dueDate) > 0) {
                      infoSupData = translation("Échue depuis") + " " + numberDaysDelate(element.dueDate) + " " + translation("jours");
                    } else {
                      infoSupData = translation("Échue depuis") + " " + numberDaysDelate(element.dueDate) + " " + translation("jour");
                    }
                  }
                }
              }
            }
            else if (element.type === "EQUOTATION") {
              if (element.status === 'TODO') {
                classActionButton = "st-to-complete";
                textActionButton = translation("Compléter");
                statePayment = translation("Infos manquantes");
                iconState = "/build/images/pastille-rouge.svg";
                recovery = "d-none";
                altState = "icone d'info manquante";
                //divMarkAsPayed = "d-block st-divMarkAsPayed supplierPayment"+j;
                divMarkAsPayed = "d-none";
                infoSupData = translation("Devis créé le") + " " + dateConversion(element.date);
                urlActionButton = "http://" + v.domain + "/devis?step=2&id=" + element.id;
              }
              else if (element.status === 'DOING') {
                classActionButton = "st-to-complete";
                textActionButton = translation("Envoyer");
                statePayment = translation("Terminé");
                iconState = "/build/images/pastille-verte.svg";
                recovery = "d-none";
                altState = "icon pastille validée";
                //divMarkAsPayed = "d-block st-divMarkAsPayed supplierPayment"+j;
                divMarkAsPayed = "d-none";
                infoSupData = translation("Devis émis le") + " " + dateConversion(element.date);
                urlActionButton = "http://" + v.domain + "/devis?step=3&id=" + element.id;
              }
              else if (element.status === 'DONE') {
                classActionButton = "st-to-complete";
                textActionButton = translation("Renvoyer");
                statePayment = translation("Envoyé");
                iconState = "/build/images/pastille-verte.svg";
                recovery = "d-none";
                altState = "icon pastille validée";
                //divMarkAsPayed = "d-block st-divMarkAsPayed supplierPayment"+j;
                divMarkAsPayed = "d-none";
                infoSupData = translation("Devis envoyé le") + " " + dateConversion(element.date);
                urlActionButton = "http://" + v.domain + "/devis?step=3&id=" + element.id;
              }
            }

            addElementDashboard(element.type, nameData, element.title, infoSupData, element.value, element.thDiscountDaysLimit,
              element.thDiscountableRate, element.thIsDiscountable, element.sortDate, iconState,
              altState, statePayment, recovery, hrefRecovery, textActionButton, classActionButton, urlActionButton, divMarkAsPayed,
              element.id, element.projectId, element.dueDate, labelMultipayment, i, element.paymentStatus, element.mark_as_payed, element.filename, element.status, v.domain);

            if ($('.st-arrayDashboard').attr('data-isprofilcomplete') === '') {
              $("#buttonAction" + i).attr('data-target', '.popin-need-info');
            }
            $("#buttonAction" + i).data('receiverid', element.receiverId);
            if ($("#buttonAction" + i).hasClass('js-openpopin-bank')) {
              $("#buttonAction" + i).attr('data-ismultipayment', element.thIsMultiPayment);
            }

            if (element.type !== "DEBITED_PAYMENT") {
              $("#st-userName" + i + ", #st-paymentName" + i +
                ", #st-paymentPrice" + i + ", #blocTopDashboard" + i +
                ", #divBottomLeft" + i).css("cursor", "pointer").on("click", function (index) {
                return function () {
                  if (element.type !== "SUPPLIER_PAYMENT") {
                    window.location.href = $("#buttonAction" + index).attr("href");
                  } else {
                    if (element.paymentStatus === "SUCCEEDED") {
                      window.location.href = "/freelance/fournisseur/paiement/" + element.id + "/recap";
                    }
                    else {
                      window.location.href = $("#buttonAction" + index).attr("href");
                    }
                  }
                }
              }(i));
            } else {
              if (element.paymentStatus === null) {
                $("#st-userName" + i + ", #st-paymentName" + i +
                  ", #st-paymentPrice" + i + ", #blocTopDashboard" + i +
                  ", #divBottomLeft" + i).addClass("js-openshowbills cpointer")
                  .data("target", ".popin-show-bills")
                  .data("pdf", "/" + element.pdf)
                  .data("file", "/" + element.preview)
                  .data("paymentId", element.id);
                $(".redirectToPay").on("click", function (index) {
                  return function () {
                    $("#buttonAction" + index).trigger("click");
                    $(".popin-show-bills").hide();
                  }
                }(i));
              }
            }

            $("#blocMarkAsPayed" + i).on("click", function (index) {
              return function (e) {
                $(".js-operationAction").hide();
                $("#operationAction" + index).show();
                e.stopPropagation();
                $(window).on('click', function() {
                  $("#operationAction" + index).hide();
                })
                $('#blocMarkAsPayed').on('click', function() {
                  $("#operationAction" + index).hide();
                })
                if (element.type !== "SUPPLIER_PAYMENT") {
                  let promiseReturn = $.post("/freelance/generer_lien_paiement", {
                    paymentId: element.id
                  });
                  promiseReturn.then((val) => {
                    let v = JSON.parse(val);
                    if (v.status === "success") {
                      $('#paymentLink' + index).attr('data-url', v.url);
                    }
                  })
                }
              }
            }(i));

            $("#markAsPayed" + i).on("click", function (index) {
              return function () {
                let promiseReturn = $.get("/freelance/mark_as_payed/"+element.id);
                promiseReturn.then((val) => {
                  const v = JSON.parse(val);
                  if (v.status === "success") {
                    $("#infoSupData" + index).text(translation("Réglé le") + " " + dateConversion(element.payedDate));
                    $("#statement" + index).text(translation("Réglé"));
                    $("#iconStatement" + index).attr("src", "/build/images/icon_check-black.svg").attr("alt", "icone checked");
                    $("#buttonAction" + index + ", #blocMarkAsPayed" + index + ", #recovery" + index).css("display", "none");
                    $("#blocMarkAsPayed" + index).hide().removeClass('d-block');
                  } else {
                    window.location.reload();
                  }
                })
              }
            }(i));

            $("#markAsPayedSupplier" + i).on("click", function (index) {
              return function (e) {
                let promiseReturn = $.post("/freelance/paiement_fournisseur/"+ element.id +"/mark_as_payed");
                promiseReturn.then((val) => {
                  const v = JSON.parse(val);
                  if (v.status === "success") {
                    // $("#infoSupData" + index).text(translation("Réglé le") + " " + dateConversion(element.payedDate));
                    // $("#statement" + index).text(translation("Réglé"));
                    // $("#iconStatement" + index).attr("src", "/build/images/icon_check-black.svg").attr("alt", "icone checked");
                    // $("#buttonAction" + index + ", #blocMarkAsPayed" + index + ", #recovery" + index).css("display", "none");
                    // $("#blocMarkAsPayed" + index).hide().removeClass('d-block');
                    window.location.reload();
                  } else {
                    window.location.reload();
                  }
                })
              }
            }(i));

            $("#markAsUnpayed" + i).on("click", function (index) {
              return function () {
                let promiseReturn = $.get("/freelance/unmark_as_payed/"+element.id);
                promiseReturn.then((val) => {
                  const v = JSON.parse(val);
                  if (v.status === "success") {
                    $(".js-operationAction").hide();
                    $("#infoSupData" + index).text(translation("Emis le") + " " + dateConversion(element.sortDate));
                    $("#statement" + index).text(translation("En attente"));
                    $("#iconStatement" + index).attr("src", "/build/images/pastille-en-attente.svg").attr("alt", "icone waiting");
                    $("#buttonAction" + index).attr({"class": "actionButtonToPay", "href": "/freelance" + element.link + "/" + element.id + "/relance"}).text("Rappel");
                    $("#blocMarkAsPayed" + index).hide().removeClass('d-block');
                  } else {
                    window.location.reload();
                  }
                })
              }
            }(i));

            $("#markAsUnpayedSupplier" + i).on("click", function (index) {
              return function () {
                let promiseReturn = $.post("/freelance/unmark_as_payed_supplier", {
                  paymentId: element.id
                });
                promiseReturn.then((val) => {
                  const v = JSON.parse(val);
                  if (v.status === "success") {
                    // $(".js-operationAction").hide();
                    // $("#infoSupData" + index).text(translation("À régler avant") + " " + dateConversion(element.dueDate));
                    // $("#statement" + index).text(translation("À régler"));
                    // $("#iconStatement" + index).attr("src", "/build/images/pastille-en-attente.svg").attr("alt", "icone waiting");
                    // $("#buttonAction" + index).attr({"class": "actionButtonToPay", "href": "/freelance/fournisseur/paiement?payment="+element.id}).text("Payer");
                    // $("#blocMarkAsPayed" + index).hide().removeClass('d-block');
                    window.location.reload();
                  } else {
                    window.location.reload();
                  }
                })
              }
            }(i));

            $("#deletePayment" + i).on('click', function () {
              let promiseReturn = $.post("/freelance/paiement_fournisseur/"+element.id+"/cancel");
              promiseReturn.then(function (val) {
                let v = JSON.parse(val);
                if (v.status === "success") {
                  $("#row"+element.id).remove();
                }
              })
            })

            $('#paymentLink' + i).on('click', function () {
              let link = $(this).attr('data-url');
              let temp = $("<input>");
              $("body").append(temp);
              temp.val(link).trigger("select");
              document.execCommand("copy");
              temp.remove();
              $(this).html(translation('Lien copié'));
              setTimeout(() => {
                $(this).html(translation("Lien de paiement"));
                $('#operationAction' + i).hide();
              }, 2000);
            })

            // $(document).on("mouseup", function (index) {
            //   return function (e) {
            //     var container = $("#operactionAction" + index);
            //
            //     // if the target of the click isn't the container nor a descendant of the container
            //     if (!container.is(e.target) && container.has(e.target).length === 0) {
            //       container.hide();
            //     }
            //   }
            // }(i));

            $("#buttonAction" + i).on("click", function (index) {
              return function () {
                if ($("#buttonAction" + index).data("discount") === null) {
                  $(".bills-discount").css("display", "none");
                } else {
                  $(".bills-discount").css("display", "block");
                }
              }
            }(i));

            iMax--;
            i++;
          }
          j++
        });
      } else {

        let valueMessage;

        if (isSearch) {
          valueMessage = translation("Aucun élément");
        } else {
          if ($("#js-servicesSoan").val() === "ALL") {
            valueMessage = translation("Aucune transaction");
          } else if ($("#js-servicesSoan").val() === "CREDITED_PAYMENT") {
            valueMessage = translation("Aucune demande");
          } else if ($("#js-servicesSoan").val() === "DEBITED_PAYMENT") {
            valueMessage = translation("Aucun paiement");
          } else if ($("#js-servicesSoan").val() === "SUPPLIER_PAYMENT") {
            valueMessage = translation("Aucun paiement");
          } else if ($("#js-servicesSoan").val() === "RECOVERY") {
            valueMessage = translation("Aucun recouvrement");
          } else if ($("#js-servicesSoan").val() === "DELAYED_PAYMENT") {
            valueMessage = translation("Aucun paiement2");
          } else if ($("#js-servicesSoan").val() === "DELAYED_INVOICE") {
            valueMessage = translation("Aucune demande2");
          } else if ($("#js-servicesSoan").val() === "DOCUMENT") {
            valueMessage = translation("Aucune signature");
          }
        }
        let emptyMessage = $("<p>", {
          text: valueMessage,
          class: "st-emptyMessage"
        });
        $(".js-arrayDashboard").append(emptyMessage);
        flag = 1;
      }
    } else {
      window.location.reload();
    }
  });
}

function getDateLimitDiscount(date, days) {
  let getTime = new Date(date.toString().replace(" ", "T")).getTime();
  let limit = getTime + (days * 86400000);
  return dateConversion(limit);
}

function formatDate(date) {
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let year = date.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }
  return [year, month, day].join('-');
}

function discountPrice(priceData, thDiscountDaysLimit, thDiscountableRate, thIsDiscountable, sortDate, statusPrice) {
  let dateBills = new Date(sortDate.toString().replace(" ", "T"));
  let dateWithDiscount = dateBills.setDate(dateBills.getDate() + parseInt(thDiscountDaysLimit));

  if (thIsDiscountable === "1" || thIsDiscountable === "2") {
    if (differenceDate(dateWithDiscount) < 0) {
      let calculDiscount = (priceData * thDiscountableRate) / 100;
      let resultDiscount = priceData - calculDiscount;
      return statusPrice + convertPrice(resultDiscount);
    }
  }
}

// On loading at page we have this select values
let sortBillsDefault = "LAST";
let sortServicesDefault = "ALL";
let sortActionsDefault = "ALL";

if ($('.st-arrayDashboard').length > 0) {
  getDataRequest(4, "", sortServicesDefault, sortBillsDefault, sortActionsDefault);
}

// On change on (select 1, 2, 3)
$("#js-servicesSoan, #js-sortBills, #js-sortActions").on("change", function () {
  flag = 0;
  $(".js-reduceDashboard").hide();
  if ($(window).width() >= 768) {
    $(".st-showMore").hide();
  }
  $(".st-showMoreTop").show();
  $(".st-rowDashboard, .st-emptyMessage").remove();
  for (let row in arrayRowType) {
    arrayRowType[row] = 0;
  }
  getDataRequest(4);
});

// Event on search input with delay 
$(".js-search-dashboard").on("keyup change", delay(function () {

  $(".st-rowDashboard, .st-emptyMessage").remove();
  Object.keys(arrayRowType).forEach(v => arrayRowType[v] = 0);
  getDataRequest(4, true);

}, 300));


// Click button for Add entries in dashboard or delete entries in dashboard

$(".st-showMoreTop").on("click", function () {
  if (flag != 1) {
    $(this).hide();
    $(".js-reduceDashboard").show();
    $(".st-showMore").css("display", "block");
    getDataRequest(10);
  }

});

$(".st-showMore").on("click", function () {
  if (flag != 1) {
    if ($(window).width() <= 767) {
      $(".js-reduceDashboardMobile").show();
      $(".st-showMore").css({"text-align": "right", "margin-right": "25px", "background-position-x": "30px"});
    }
    getDataRequest(10);
  }
});

// reduce row button -> click = - 10 entries
$(".js-reduceDashboard, .js-reduceDashboardMobile").on("click", function () {
  let i = 0;
  flag = 0;
  while (i < 10 && $(".st-rowDashboard").length > 3) {
    let dataRow = $('.st-rowDashboard:last').data("displayType");
    arrayRowType[dataRow] -= 1;
    $('.st-rowDashboard:last, .st-emptyMessage').remove();
    i++;

    if ($(".st-rowDashboard").length === 3) {
      $(".js-reduceDashboard, .js-reduceDashboardMobile").hide();
      if ($(window).width() >= 768) {
        $(".st-showMore").hide();
      } else {
        $(".st-showMore").css({"text-align": "center", "margin-right": "auto", "background-position-x": "12px"});
      }
      $(".st-showMoreTop").show();
    }
  }

});

// Responsive Research Icon 

function showOrHideSearchInput(parentClick, childShowing) {
  $(parentClick).on("click", function () {
    if ($(childShowing).css("visibility") === "hidden") {
      $(childShowing).css("visibility", "visible").trigger("focus");
      $(".st-anchor-header").attr("style", "display: none !important");
    } else {
      $(childShowing).val("").css("visibility", "hidden").trigger('keyup');
      $(".st-anchor-header").attr("style", "display: block !important");
    }
  });
}

if ($(window).width() <= 767) {
  showOrHideSearchInput(".st-searchDashboard img", ".js-search-dashboard");
  showOrHideSearchInput(".new-project-actions img", ".search-project");
}

$(".search-project, .js-search-dashboard").on("blur", function () {
  $(".st-anchor-header").attr("style", "display: block !important");
});

$(".addFavorite").on("click", (e) => {
  e.preventDefault();
  let projectFolderId = $(e.target).data("projectfolderid");
  let promiseReturn = $.post("/freelance/add_favorite/" + projectFolderId, {
    isFavorite: 1
  });
  promiseReturn.then((val) => {
    window.location.reload();
    // const v = JSON.parse(val);
    // if (v.status === "success") {
    //   window.location.reload();
    //   //$(".js-select-mission").val("favorites");
    // } else {
    //   window.location.reload();
    // }
  })
});

$(".cancelHomeElement").on("click", function (e) {

  e.preventDefault();
  let homeElementId = $(this).attr("data-homeelementid");
  console.log(homeElementId);
  let promiseReturn = $.get("/freelance/home_element/cancel/" + homeElementId);
  promiseReturn.then((val) => {
      let context = $(this).attr("data-context");
      let locate;
      if (context == "supplier"){
         locate = "fournisseurs"
      }else{
         locate = "clients"
      }
      console.log(locate);
        window.location.href = "/freelance/"+locate;
  })
});

$(".removeHomeElement").on("click", function (e) {
  e.preventDefault();
  let homeElementId = $(this).attr("data-homeelementid");
  let promiseReturn = $.get("/freelance/home_element/remove/" + homeElementId);

  promiseReturn.then((val) => {
    let context = $(this).attr("data-context");
    let locate;
    if (context == "supplier"){
       locate = "fournisseurs"
    }else{
       locate = "clients"
    }
    console.log(locate);
      window.location.href = "/freelance/"+locate;
  })
});

$(".deleteFavorite").on("click", (e) => {
  e.preventDefault();
  let projectFolderId = $(e.target).data("projectfolderid");
  let promiseReturn = $.post("/freelance/add_favorite/" + projectFolderId, {
    isFavorite: 0
  });
  promiseReturn.then((val) => {
    const v = JSON.parse(val);
    if (v.status === "success") {
      window.location.reload();
    } else {
      window.location.reload();
    }
  })
});

$('.js-iconBell').on('click', function () {
  if ($('.st-blocNotificationBell').hasClass('unreadFromNavbar')) {
    let id = [];
    let infoNotRead = $('.not-read');
    if (infoNotRead.length > 0) {
      infoNotRead.each(function () {
        id.push($(this).attr('data-id'));
      })
      let promiseReturn = $.post(`/freelance/notification_read_from_navbar`, {
        infoId: id
      })
      promiseReturn.then(function (val) {
        let v = JSON.parse(val);
        if (v.status === 'success') {
          $('.st-blocNotificationBell').removeClass('unread');
          $('.st-blocNotificationBell').removeClass('unreadFromNavbar');
        }
      })
    }
  }
})


// MANAGE ACTIVE CLASS TOGGLE ON MENU ITEMS WHEN USER SCROLL
window.onscroll = onScroll;
const windowHeight = $(window).height();

function onScroll() {
  const removeActiveClass = (elements) => {
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove('active-menu-item-dashboard');
    }
  }
  let anchors = document.querySelectorAll('.menu-item-dashboard');
  let previousRefElement = '<h3 class="cpointer mb-0 st-margin-right-xs-60px menu-item-dashboard active-menu-item-dashboard font-weight-medium" data-target="#js-hp-anchor-synthesis">' + translation("synthèse") + '</h3>';
  for (let i = 0; i < anchors.length; ++i) {
    let currentRefElement = document.getElementById(anchors[i].dataset.target.replace('#', ''));
    let currentRefElementTop = currentRefElement.getBoundingClientRect().top - (windowHeight - 180);
    if (currentRefElementTop <= 0) {
      previousRefElement = anchors[i];
      if (i == anchors.length - 1) {
        removeActiveClass(anchors);
        anchors[i].classList.add("active-menu-item-dashboard");
      }
    } else {
      removeActiveClass(anchors);
      previousRefElement.classList.add("active-menu-item-dashboard");
      break;
    }
  }
}

// MANAGE SMOOTH SCROLL WHEN CLICK ON A MENU ITEM (ACT LIKE ANCHOR)
$('.menu-item-dashboard').on('click', (e) => {
  const target = $(e.target).data('target');
  $('html, body').animate({
    scrollTop: $(target).offset().top - 370
  }, 'slow');
})

// MANAGE CLICK ON MARK AS PAID ELEMENT ON PAYMENT PAGE
$('.js-divMarkAsPayed').on('click', (e) => {
  $(".js-operationAction").hide();
  let id = $(e.target).data('id');
  let newClass = ".js-pMarkAsPayed" + id;
  let newClassUnpayed = ".js-pMarkAsUnpayed" + id;
  let operationAction = $("#operationAction" + id);
  operationAction.show();
  $(window).on('click', function() {
    $(operationAction).hide();
  })
  e.stopPropagation();
  let promiseReturn = $.post("/freelance/generer_lien_paiement", {
    paymentId: id
  });
  promiseReturn.then((val) => {
    let v = JSON.parse(val);
    if (v.status === "success") {
      $('#paymentLink' + id).attr('data-url', v.url);
    }
  })
  $('#paymentLink' + id).on('click', function (e) {
    let link = $(this).attr('data-url');
    let temp = $("<input>");
    $("body").append(temp);
    temp.val(link).trigger("select");
    document.execCommand("copy");
    temp.remove();
    $(this).html(translation('Lien copié'));
    setTimeout(() => {
      $(this).html(translation("Lien de paiement"));
      $('#operationAction' + i).hide();
    }, 2000);
    e.stopPropagation();
  })
  $(newClass).on("click", function () {
    let promiseReturn = $.get("/freelance/mark_as_payed/"+id);
    promiseReturn.then((val) => {
      const v = JSON.parse(val);
      if (v.status === "success") {
        window.location.reload();
      }
    })
  });
  $(newClassUnpayed).on("click", function () {
    let promiseReturn = $.get("/freelance/unmark_as_payed/"+id);
    promiseReturn.then((val) => {
      const v = JSON.parse(val);
      if (v.status === "success") {
        window.location.reload();
      }
    })
  });
})
// DELETE DISCOUNTED NOTIFICATIONS
$('.js-deleteDiscountedNotification').on('click', (e) => {
  const discountId = $(e.target).attr('data-discountedid');
  let promiseReturn = $.post("/freelance/closeDiscountInfo", {discountId: discountId});
  promiseReturn.then((val) => {
    const v = JSON.parse(val);
    if (v.status === "success") {
      $(e.target).closest('.st-promotion-banner').remove();
    }
  })
})

if ($(window).width() <= 420) {
  $(".st-anchor-header div h3:nth-child(2)").text("flux");
  if ($("body").data("currentpage") === "app_freelance_freelance_index") {
    $(".st-headerHomeDashboard").css({"width": "75%", "margin-right": "10px"});
    $(".header-flex div").removeClass("st-width-32");
  }
}

if ($('.dash-project-favorite').length === 0) {
  $('.grid-favourites').closest('.dash-project').removeClass('st-padding-top-40px').removeClass('st-min-height-80px');
}
function open() {
  if (window.location.pathname === '/freelance/operations') {
    let vars = {};
    window.location.href.replace( location.hash, '' ).replace(/[?&]+([^=&]+)=?([^&]*)?/gi,
      function( m, key, value ) {
        vars[key] = value;
      });
    let projectIds = vars['projectIds'];
    let paymentIds = vars['paymentIds'];

    if (projectIds != null && paymentIds != null) {
      $('.click'+paymentIds).click();
      $('.backdrop').on('click', function () {
        window.location.href = '/freelance/operations';
      })
    }
  }
}

// Home Pages
$('.js-back-home').on('click', (e) => {
  window.location.href = '/freelance';
});
// CREATE PROJECT
$(document).on('click', '.js-create-project-popin', function () {
  if ($(this).hasClass('cpointer')) {
    $(this).removeClass('cpointer');
    let projectName = $('.name-project').val();
    let promiseReturn = $.post("/freelance/nouveau_projet", {projectName: projectName});
    promiseReturn.then(function (val) {
      let v = JSON.parse(val);
      if (v.status === 'success') {
        window.location.href = '/freelance/projet/' + v.projectId + '/paiement';
      } else {
        window.location.reload();
      }
      $("js-create-project-popin").addClass('cpointer');
    });
  }
});
