/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

//import bootstrap css
import "bootstrap/dist/css/bootstrap.min.css";

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
var $ = require("jquery");
global.$ = global.jQuery = $;
import dt from "datatables.net";
import "datatables.net-buttons";
import "slick-carousel";
import moment from "moment";
import localization from "moment/locale/fr";
moment().locale("fr", localization);
import swal from "sweetalert";
import "trumbowyg";
import "trumbowyg/dist/ui/trumbowyg.min.css";
import "simplebar";
import "simplebar/dist/simplebar.min.css";
import icons from "trumbowyg/dist/ui/icons.svg";
$.trumbowyg.svgPath = icons;
import "bootstrap-tour/build/js/bootstrap-tour-standalone.min.js";
import "jquery-mask-plugin";
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
import Dropzone from "dropzone";
import "web-animations-js";
import ClipboardJS from "clipboard";
import IntlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.min.css";
import "intl-tel-input/build/js/utils.js";
import { formatDate } from "fullcalendar";
import "simplebar";
import "simplebar/dist/simplebar.css";
import "particles.js";
import "regenerator-runtime/runtime";
var Papa = require("papaparse");
// import * as Sentry from "@sentry/browser";
// import { Integrations } from "@sentry/tracing";
import flatpickr from "flatpickr";
import { formatDateSeparators, hidePopin, handleErrorsAfterSubmit, isAdult, toggleLateralMenu, differenceDate, getValidFormatDate, convertStringToNumber, numberWithSpaces, checkvalidpassword, checkvalidpasswordDynamically, divideNumberByThree, dateConversion, numberWithSpacesWithComa, translation, convertPrice, openPopin } from "./newScripts/generalFunctions.js";
import { checkAllInputs, inputFrontValidation } from "./newScripts/frontendValidations";
import { data } from "jquery";
import { popinPaymentBystep, step1, step2, step3, step3bis1, step3bis2, step4, getTemplateStep3bis1 } from "./newScripts/classes/loadingClasses/loadingPopinClass.js";
import { paymentByStepEfacture, paymentByStepWithImport, sendPaymentEfacture, sendPaymentFacture, generatePaymentLink } from "./newScripts/components/forms/paymentEfacturePopinForm";
import PopinStep from "../js/newScripts/classes/PopinStep";
import translation_de from "./newScripts/translations/translation_de.js";
import { is } from "core-js/fn/object";

export function loadTrumbowygEditor() {
    $(".paymentmail").trumbowyg({
        btns: [["link"], ["strong", "em"], ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"], ["unorderedList", "orderedList"], ["fullscreen"]],
        semantic: false,
    });
}

export function openAddBank() {
    $(".js-open-addbank").on("click", (e) => {
        $(".popin-add-bank input").each(function () {
            this.value = "";
        });
        const previousTargetClass = "." + $(e.target).closest(".popin-wrap").attr("class").split(" ")[1];
        $(".js-submit-newbank").data("previoustarget", previousTargetClass);
        $(previousTargetClass).hide();
        $(".popin-add-bank").show();
        $(".js-newbank-back").on("click", () => {
            $(".popin-add-bank").hide();
            $(previousTargetClass).show();
        });
        $(".js-submit-newbank").attr("data-isdefault", "isDefault");
        if ($(e.target).hasClass("js-ifsuccess-gostep3-marker")) {
            $(".js-submit-newbank").addClass("js-ifsuccess-gostep3");
        }
        if ($(e.target).hasClass("js-efacture-add-bank-marker")) {
            $(".js-submit-newbank").addClass("js-efacture-add-bank");
        }
    });
}

export function submitNewBank() {
    $(document).on("click", ".js-submitNewbankDefault", function (e) {
        e.preventDefault();
        $(e.target).prop("disabled", true);
        $(".needbank-error").text("");
        //var submitUrl = $(e.target).data("route") || null;
        var submitMethod = "POST";
        var allInputs = $(e.target).closest(".bank-add-form").find("input, select").length ? $(e.target).closest(".bank-add-form").find("input, select") : $(e.target).closest(".popin-wrap").find(".bank-add-form input, .bank-add-form select");
        var formData = new FormData();
        allInputs.each(function (i, val) {
            if (val.name) {
                formData.append(val.name, val.value);
            }
        });

        formData.append("isDefault", 1);

        if (window.location.pathname === "/freelance/mon_profil/nouveau_compte_bancaire") {
            formData.append("needMessage", 1);
        }
        formData.append("isdefault", 1);
        $.ajax({
            url: "/freelance/mon_profil/nouveau_compte_bancaire_popin",
            data: formData,
            processData: false,
            contentType: false,
            type: submitMethod,
            success: function (data) {
                let v = JSON.parse(data);
                $(e.target).prop("disabled", false);
                if (v.status === "success") {
                    $(".popin-step-error").hide();
                    const previousTargetClass = $(e.target).data("previoustarget");
                    const currentTargetClass = $(e.target).data("currenttarget");
                    if ($(e.target).hasClass("js-ifsuccess-gostep3")) {
                        $(currentTargetClass).hide();
                        $(previousTargetClass).show();
                        $(".popin-step-content-3").addClass("active");
                        $(".popin-step-content-2").removeClass("active");
                        $(".js-paymentbystep-back-step2").addClass("back-facture");
                        $(".js-paymentbystep-sendfacture").addClass("js-send-payment-facture");
                        $(".step-header-2").removeClass("active");
                        $(".step-header-3").addClass("active");
                        $(".step-header-2").addClass("valid");
                        $(".popin-step-error").hide();
                        $(".popin-step-error").html("");
                        $(".menu-facture").find("input").removeClass("error");
                    } else if ($(e.target).hasClass("js-efacture-add-bank")) {
                        $(currentTargetClass).hide();
                        $(".popin-send-later").show();
                        $(".js-send-later-efacture-step-1").addClass("d-none");
                        $(".js-send-later-efacture-step-2").removeClass("d-none");
                    } else {
                        $(currentTargetClass).hide();
                        $(previousTargetClass).show();
                        $(".js-wayselected.selected .js-checkMarkWaySelected").addClass("d-none");
                        $(".js-wayselected.selected").removeClass("selected");
                        let newBank =
                            '<div class="bank bankselect js-wayselected selected js-showOrHidePaymentDelayed st-backgroundBank" data-bankid="' +
                            v.bankId +
                            '">' +
                            '<div class="w-100 d-flex justify-content-between align-items-center">' +
                            "<div>" +
                            '<span class="font-weight-bold">' +
                            v.bankThAccountName +
                            "</span>" +
                            "<br />" +
                            v.bankThOwnerName +
                            "<br />IBAN " +
                            v.bankThIban +
                            "</div>" +
                            "<div>" +
                            `<img src='/build/images/green_check.svg' width="20" height="20" class="js-checkMarkWaySelected" />` +
                            "</div>" +
                            "</div>" +
                            "</div>";
                        $(`${previousTargetClass} .bank-list`).prepend(newBank);
                        $(`${previousTargetClass} .js-payment-popin-select-account`).data("bankselectedid", v.bankId);
                        if (previousTargetClass === ".popin-payment-bank") {
                            $(".js-payment-bank").removeClass("st-no-btn-disabled");
                        }
                    }
                    if (window.location.pathname === "/freelance/mon_profil/nouveau_compte_bancaire") {
                        window.location.replace("/freelance/mon_profil/compte_bancaire");
                    }
                    popinPaymentBystep.goNext(false);
                    popinPaymentBystep.deleteStep(step3bis2);
                } else {
                    if (window.location.pathname === "/freelance/mon_profil/nouveau_compte_bancaire") {
                        $(".error-add-account").html(v.message).show();
                    } else {
                        $(".popin-step-error").text(v.message).show();
                    }
                }
            },
            error: function (err) {
                window.location.reload();
            },
        });
        e.stopImmediatePropagation();
    });
}

$(document).on("click", ".js-submit-newbank", function (e) {
    e.preventDefault();
    $(e.target).prop("disabled", true);
    $(".needbank-error").text("");
    var submitMethod = "POST";
    var allInputs = $(e.target).closest(".bank-add-form").find("input, select").length ? $(e.target).closest(".bank-add-form").find("input, select") : $(e.target).closest(".popin-wrap").find(".bank-add-form input, .bank-add-form select");
    var formData = new FormData();
    allInputs.each(function (i, val) {
        if (val.name) {
            formData.append(val.name, val.value);
        }
    });

    if ($(e.target).data("isdefault") === "isDefault") {
        formData.append("isDefault", 1);
    }

    if (window.location.pathname === "/freelance/mon_profil/nouveau_compte_bancaire") {
        formData.append("needMessage", 1);
    }

    formData.append("isdefault", 1);
    $.ajax({
        url: "/freelance/mon_profil/nouveau_compte_bancaire_popin",
        data: formData,
        processData: false,
        contentType: false,
        type: submitMethod,
        success: function (data) {
            let v = JSON.parse(data);
            $(e.target).prop("disabled", false);
            if (v.status === "success") {
                const previousTargetClass = $(e.target).data("previoustarget");
                const currentTargetClass = $(e.target).data("currenttarget");
                if ($(e.target).hasClass("js-ifsuccess-gostep3")) {
                    $(currentTargetClass).hide();
                    $(previousTargetClass).show();
                    $(".popin-step-content-3").addClass("active");
                    $(".popin-step-content-2").removeClass("active");
                    $(".js-paymentbystep-back-step2").addClass("back-facture");
                    $(".js-paymentbystep-sendfacture").addClass("js-send-payment-facture");
                    $(".step-header-2").removeClass("active");
                    $(".step-header-3").addClass("active");
                    $(".step-header-2").addClass("valid");
                    $(".popin-step-error").hide();
                    $(".popin-step-error").html("");
                    $(".menu-facture").find("input").removeClass("error");
                } else if ($(e.target).hasClass("js-efacture-add-bank")) {
                    $(currentTargetClass).hide();
                    $(".popin-send-later").show();
                    $(".js-send-later-efacture-step-1").addClass("d-none");
                    $(".js-send-later-efacture-step-2").removeClass("d-none");
                } else {
                    const amount = $(previousTargetClass).find(".iban-amount").first().text();
                    $(currentTargetClass).hide();
                    $(previousTargetClass).show();
                    $(".js-wayselected.selected .js-checkMarkWaySelected").addClass("d-none");
                    $(".js-wayselected.selected").removeClass("selected");
                    let newBank =
                        '<div class="bank bankselect js-wayselected selected js-showOrHidePaymentDelayed st-backgroundBank" data-bankid="' +
                        v.bankId +
                        '">' +
                        '<div class="w-100 d-flex justify-content-between align-items-center fs14">' +
                        "<div>" +
                        '<span class="font-weight-bold">' +
                        (v.bankThAccountName.length > 24 ? v.bankThAccountName.substr(0, 24) + "..." : v.bankThAccountName) +
                        "</span>" +
                        "<br />" +
                        (v.bankThOwnerName.length > 24 ? v.bankThOwnerName.substr(0, 24) + "..." : v.bankThOwnerName) +
                        "<br />IBAN " +
                        v.bankThIban +
                        "</div>" +
                        '<div class="d-flex">' +
                        `<img src='/build/images/green_check.svg' width="20" height="20" class="js-checkMarkWaySelected" />` +
                        "</div>" +
                        "</div>" +
                        "</div>";
                    $(`${previousTargetClass} .bank-list`).prepend(newBank);
                    $(`${previousTargetClass} .js-payment-popin-select-account`).data("bankselectedid", v.bankId);
                    if (previousTargetClass === ".popin-payment-bank") {
                        $(".js-payment-bank").removeClass("st-no-btn-disabled");
                    }
                }
                if (window.location.pathname === "/freelance/mon_profil/nouveau_compte_bancaire") {
                    window.location.replace("/freelance/mon_profil/compte_bancaire");
                }
            } else {
                if (window.location.pathname === "/freelance/mon_profil/nouveau_compte_bancaire") {
                    $(".error-add-account").html(v.message).show();
                } else {
                    $(".needbank-error").text(v.message).css("color", "#D9534F");
                }
            }
        },
        error: function (err) {
            window.location.reload();
        },
    });
    e.stopImmediatePropagation();
});
// Sentry.init({
//   dsn: 'https://ec65695e6c424496b6ebbf1f63463b06@o503890.ingest.sentry.io/5589627',
//   integrations: [
//     new Integrations.BrowserTracing(),
//   ],

//   tracesSampleRate: 1.0,
// });

/**
 * Init Audio
 */
var audio = null;

/**
 * get translate from twig
 */
var transDashboardTitle = $(".translatejs").data("transdashboardtitle");
var transProjectName = $(".translatejs").data("transprojectname");
var transStepcontent = $(".translatejs").data("transstepcontent");
var transActivestepcomment = $(".translatejs").data("transactivestepcomment");
var transCalendar = $(".translatejs").data("transcalendar");
var transMeeting = $(".translatejs").data("transmeeting");
var transCommunication = $(".translatejs").data("transcommunication");
var transActivity = $(".translatejs").data("transactivity");
var transDownload = $(".translatejs").data("transdownload");
var transDocHelp = $(".translatejs").data("transdochelp");
var transDocTicket = $(".translatejs").data("transdocticket");
var transPopinSignInfo = $(".translatejs").data("transpopinsigninfo");

Dropzone.autoDiscover = false;
$(function () {
    var asset = $("body").data("asseturl");
    var baseUrl = window.location.origin;
    var guideOn = $("body").data("guide");
    /*Get current user and current page */
    var currentpage = $("body").data("currentpage");
    var currentuser = $("body").data("currentuser");
    var currenttype = "freelance";
    /*hack add font bold to text dynamically*/
    if (currentpage == "user_meeting_index" || currentpage == "freelance_meeting_index") {
        $(".meeting-accept-content .form-check-label").each(function (k) {
            let text = $(this).text();
            let textTab = text.split("de");
            let finalText = textTab[0] + "<span class='font-weight-bold'>" + textTab[1] + "</span>";
            $(this).html(finalText);
        });
    }

    /*focus input on login screen*/
    if (currentpage === "login") $("#inputThEmail").focus();

    /* Notif Page Mes Projets Guide*/
    /* Lors de la création du 1er projet il faudrait créer une notification d'activité et une notification d'action Le guide s'ouvre en mode messie qui t'explique a quoi ca sert*/
    /* Faire les notifs en js full Fake disparition lors du click sur suivant */

    $(document).on("click", "slider-nav-right", function () {
        $(".current-slide").removeClass(".current-slide").next().addClass(".current-slide");
    });

    $(document).on("click", "slider-nav-left", function () {
        $(".current-slide").removeClass(".current-slide").prev().addClass(".current-slide");
    });

    $(document).on("click", ".toggle-expend-content", function () {
        var $toggleContent = $(this).parent(".toggle-title").next().next();
        if ($toggleContent.is(":visible")) $toggleContent.slideUp("slow");
        else $toggleContent.slideDown("slow");
    });

    //step
    $(document).on("click", ".next-step-link", function () {
        var requiredStep = [];
        requiredStep.push(document.querySelectorAll("input:invalid"));
        requiredStep.push(document.querySelectorAll("textarea:invalid"));
        requiredStep.push(document.querySelectorAll(".hiddeninput"));
        var tabrequired = [];
        var test = $.each(requiredStep, function (key, value) {
            $.each(value, function (k, val) {
                if ($(val).is(":visible")) tabrequired.push(val);
            });
        });
        if (tabrequired.length === 0) {
            if ($(this).hasClass("ignorestep")) {
                $(":input:visible").val("");
            }
            $(this).parents(".active").next().addClass("active");
            $(this).parents(".active").removeClass("active");
            $(".errors").html("").removeClass("active");
            $(".errors").hide();
        } else {
            $(".errors").show();
            $(".errors").html(translation("Veuillez remplir les champs" + " "));
            $.each(tabrequired, function (key, val) {
                $(".errors")
                    .addClass("active")
                    .append("<div class='error'>" + val.dataset.field + "</div>");
            });
            $(".soancontainer").animate({ scrollTop: $(".errors").offset().top }, 1000);
        }
    });

    $(document).on("click", ".js-save-before-next", function () {
        let that = $(this);
        if ($(".errors").children().length > 0) {
            $(".errors").show();
            $(".errors").append('<div class="">Veuillez corriger les erreurs</div>');
            $(".soancontainer").animate({ scrollTop: $(".errors").offset().top }, 1000);
        } else {
            let idEfacture = that.data("idefacture");
            let idProject = that.data("idproject");
            let promiseReturn = $.post("/freelance/projet/" + idProject + "/efacture/" + idEfacture + "/enregistrer");
            promiseReturn.then(function (val) {
                let v = JSON.parse(val);
                if (v.status === "failed") {
                    $(".errors").html(v.message);
                    $(".errors").show();
                    $(".soancontainer").animate({ scrollTop: $(".errors").offset().top }, 1000);
                } else {
                    var requiredStep = [];
                    requiredStep.push(document.querySelectorAll("input:invalid"));
                    requiredStep.push(document.querySelectorAll("textarea:invalid"));
                    requiredStep.push(document.querySelectorAll(".hiddeninput"));
                    var tabrequired = [];
                    var test = $.each(requiredStep, function (key, value) {
                        $.each(value, function (k, val) {
                            if ($(val).is(":visible")) tabrequired.push(val);
                        });
                    });
                    if (tabrequired.length === 0) {
                        if (that.hasClass("ignorestep")) {
                            $(":input:visible").val("");
                        }
                        that.parents(".active").next().addClass("active");
                        that.parents(".active").removeClass("active");
                        $(".errors").html("").removeClass("active");
                    } else {
                        $(".errors").html(translation("Veuillez remplir les champs" + " "));
                        $.each(tabrequired, function (key, val) {
                            $(".errors")
                                .addClass("active")
                                .append("<div class='error'>" + val.dataset.field + "</div>");
                        });
                        $(".soancontainer").animate({ scrollTop: $(".errors").offset().top }, 1000);
                    }
                }
            });

            promiseReturn.catch(function (err) {
                window.location.reload();
            });
        }
    });

    //netx link bills user needed
    $(document).on("click", ".next-step-link-billsuser", function () {
        let billsUser = $(".js-select-billsreceiver").val();
        if (billsUser) {
            var requiredStep = [];
            requiredStep.push(document.querySelectorAll("input:invalid"));
            requiredStep.push(document.querySelectorAll("textarea:invalid"));
            requiredStep.push(document.querySelectorAll(".hiddeninput"));
            var tabrequired = [];
            var test = $.each(requiredStep, function (key, value) {
                $.each(value, function (k, val) {
                    if ($(val).is(":visible")) tabrequired.push(val);
                });
            });
            if (tabrequired.length === 0) {
                if ($(this).hasClass("ignorestep")) {
                    $(":input:visible").val("");
                }
                $(this).parents(".active").next().addClass("active");
                $(this).parents(".active").removeClass("active");
                $(".errors").html("").removeClass("active");
            } else {
                $(".errors").html(translation("Veuillez remplir les champs" + " "));
                $.each(tabrequired, function (key, val) {
                    $(".errors")
                        .addClass("active")
                        .append("<div class='error'>" + val.dataset.field + "</div>");
                });
                $(".soancontainer").animate({ scrollTop: $(".errors").offset().top }, 1000);
            }
        } else {
            $(".errors").show();
            $(".errors").html("Veuillez Ajouter un destinataire");
            $(".soancontainer").animate({ scrollTop: $(".errors").offset().top }, 1000);
        }
    });

    //invitation client error show
    if ($(".step-content-2").find(".is-invalid").length !== 0) {
        $(".step-content-1").removeClass("active");
        $(".step-content-2").addClass("active");
    }

    //step dashboard
    $(document).on("click", ".js-step", function () {
        var active = $(this).data("tab");
        $(".js-step, .active-step-content").removeClass("active");
        $(active).addClass("active");
        $(this).addClass("active");
    });

    //input file name show
    $('input[type="file"]').change(function (e) {
        if (!$(this).hasClass("js-kycChooseFileInput") && !$(this).hasClass("js-addPicture")) {
            if (!$(this).hasClass("nofilename")) {
                if (e.target.files[0].size > 7340032) {
                    $(".errors").text("Les fichiers téléchargés ne doivent pas dépasser 7M0");
                    $(".errors").show();
                } else {
                    $(".errors").hide();
                    let fileName = e.target.files[0].name;
                    $(this).next(".custom-file-label").html(fileName);
                }
            }
        }
    });

    //create doc
    $(document).on("click", ".doc.create", function () {
        $(".popin.create-document").show();
    });

    //link doc
    $(document).on("click", ".doc-link", function () {
        var link = $(this).data("link");
        document.location.href = link;
    });

    function returnPopin(title, text, status) {
        var popin = `<div class="popin ${status}"><div class="popin-close"></div><div class="popin-title">${title}</div><div class="popin-content">${text}</div></div>`;
        $("body").append(popin);
    }

    var sqlDateTime = function (sqlDate) {
        //sqlDate in SQL DATETIME format ("yyyy-mm-dd hh:mm:ss.ms")
        var sqlDateArr1 = sqlDate.split("-");
        //format of sqlDateArr1[] = ['yyyy','mm','dd hh:mm:ms']
        var sYear = sqlDateArr1[0];
        var sMonth = (Number(sqlDateArr1[1]) - 1).toString();
        var sqlDateArr2 = sqlDateArr1[2].split(" ");
        //format of sqlDateArr2[] = ['dd', 'hh:mm:ss.ms']
        var sDay = sqlDateArr2[0];
        var sqlDateArr3 = sqlDateArr2[1].split(":");
        //format of sqlDateArr3[] = ['hh','mm','ss.ms']
        var sHour = sqlDateArr3[0];
        var sMinute = sqlDateArr3[1];
        //var sqlDateArr4 = sqlDateArr3[2].split(".");
        //format of sqlDateArr4[] = ['ss','ms']
        var sSecond = sqlDateArr3[2];
        //  var sMillisecond = sqlDateArr4[1];
        var d = new Date(sYear, sMonth, sDay, sHour, sMinute, sSecond);
        return (sHour < 10 ? "0" : "") + String(d.getHours()) + ":" + (sMinute < 10 ? "0" : "") + String(d.getMinutes()) + " le " + (sDay < 10 ? "0" : "") + String(d.getDate()) + "/" + (sMonth < 10 ? "0" : "") + String(d.getMonth() + 1) + "/" + String(d.getFullYear());
    };

    $(document).on("click", ".popin-action-close", function () {
        let reload = $(this).data("reload") || null;
        if (reload) {
            window.location.reload();
        }
        $(".backdrop").hide();
        $(".popin").hide();
        $(".popin-wrap").hide();
    });

    // A conserver ?

    // $(document).on("click", ".js-paymentPopin", function(e) {
    //   e.preventDefault();
    //   var ibanAmount = $(this).data("amount") || null;
    //   var billsName = $(this).data("bills") || null;
    //   var paymentId = $(this).data('paymentid') || null;
    //   var projectId = $(this).data('projectid') || null;
    //   var target = $(this).data('target') || null;
    //   let discount = $(this).data('discount') || null;
    //   let discountLimit = $(this).data('discountLimit') || null;
    //   let amountFormat =  new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(ibanAmount / 100.00);
    //   let discountAmount = (parseFloat(ibanAmount) - (parseFloat(ibanAmount) * (parseFloat(discount) / 100)));
    //   let discountAmountFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(discountAmount / 100.00);
    //   $('.iban-amount').html(amountFormat);
    //   $('.bills-name').html(billsName);
    //   if (discount) {
    //     $('.iban-amount').html('<del>'+amountFormat+'</del> <strong class="cprimary font-weight-bold">'+ discountAmountFormat+'</strong>');
    //     $('.bills-discount').html('<span class="cprimary font-weight-bold">'+discount + '% d’escompte</span> pour un règlement avant le ' + discountLimit);
    //   }
    //   //$('.js-payment-step1').data("route", "/freelance/mon_profil/ajout_informations_utilisateur");
    //   $('.js-payment-step2').data("route", "/freelance/mon_profil/nouveau_compte_bancaire_popin");
    //   $('.js-payment-step3').data("route", "/freelance/projet/"+projectId+"/paiement/"+paymentId+"/payer");
    //   $('.hidden-popin-data').val($(this).data("popin-data"));
    //   $(".backdrop").show();
    //   $(target).show();
    //   $(".popin").show();
    // });

    // $(document).on("click", ".js-payment-step1", function(e) {
    //   e.preventDefault();

    //   let url = "/freelance/mon_profil/completer_mes_informations/informations_utilisateur/manage";
    //   let submitMethod = 'POST';
    //   let allInputs = $(`.info-particular-formpayment :input`);
    //   let formData = new FormData();
    //   allInputs.each(function(i, val) {
    //     if (val.name) {
    //         formData.append(val.name, val.value);
    //     }
    //   });
    //   if (!isAdult($(".birthdate").val())) {
    //     if (!$('.error-step1').length) {
    //       $('.error-maxipopin').append(`<div class="error-step1">Vous devez être majeur pour pouvoir utiliser l'application</div>`);
    //       $('.popin').animate({ scrollTop: $(".error-step1").offset().top }, 300);
    //     }
    //   } else {
    //     $(".error-step1").remove();
    //     $.ajax({
    //       url: url,
    //       data: formData,
    //       processData: false,
    //       contentType: false,
    //       type: submitMethod,
    //       success: function(val){
    //         let v = JSON.parse(val);
    //         if (v.status === 'success') {
    //           if (v.route) {
    //             $('.company-step').addClass('show').removeClass('hide');
    //             $('.first-step').addClass('hide').removeClass('show');
    //           }
    //           else {
    //             $('.second-step').addClass('show').removeClass('hide');
    //             $('.first-step').addClass('hide').removeClass('show');
    //           }
    //           $('.error-maxipopin').empty();
    //         }
    //         else {
    //           if (v.message) {
    //             if (!$('.error-step1').length)
    //               $('.error-maxipopin').append('<div class="error-step1">'+v.message+'</div>');
    //           }
    //           else {
    //             window.location.reload();
    //           }
    //         }
    //       },
    //       error: function(err) {
    //         window.location.reload();
    //       }
    //     });
    //   }

    // });

    $(document).on("click", ".js-payment-step2", function (e) {
        e.preventDefault();
        var submitUrl = $(this).data("route") || null;
        var submitMethod = "POST";
        var allInputs = $(`.second-step :input`);
        var formData = new FormData();
        allInputs.each(function (i, val) {
            if (val.name) {
                formData.append(val.name, val.value);
            }
        });
        var request = new XMLHttpRequest();
        request.open(submitMethod, submitUrl);
        request.send(formData);
        request.onreadystatechange = function () {
            if (request.readyState == 4 && request.status == 200) {
                var responseData = JSON.parse(request.responseText);
                if (responseData.status === "ok") {
                    $(".second-step").removeClass("show").addClass("hide");
                    $(".third-step").addClass("show").removeClass("hide");
                    $(".js-payment-step3").attr("data-bankid", responseData.bankId);
                } else {
                    if (!$(".errorbank").length) $(".needbank").append("<div class='cerror mt-2 errorbank'>" + responseData.message + "</div>");
                }
            }
        };
    });

    $(document).on("click", ".js-payment-step3", function (e) {
        e.preventDefault();
        $(".loader-popin").fadeIn(200);
        $(".popin").css("overflow", "hidden");
        $(this).prop("disabled", true);
        $(".third-step").removeClass("show");
        var submitUrl = $(this).data("route") || null;
        var bankId = $(this).data("bankid") || null;
        if (submitUrl) window.location.href = submitUrl + "?bankId=" + bankId;
        else {
            $(".loader-popin").fadeOut(200);
            $(".popin").css("overflow", "auto");
            $(this).prop("disabled", false);
        }
    });

    $(document).on("keypress", ".popin :input:not(textarea):not(:submit)", function (event) {
        if (event.keyCode == 13) {
            event.preventDefault();
            event.stopImmediatePropagation();
            $(this).parents(".popin-wrap").find(".js-popin-submit").trigger("click");
        }
    });

    function setStepDate(stepNumber, already) {
        if (already) {
            var stepDateStart = $(".js-step-" + stepNumber + " .datestart").text();
            var stepDateEnd = $(".js-step-" + stepNumber + " .dateend").text();
            var stepStartSplit = stepDateStart.split("/");
            var dayLeadingZero = stepStartSplit[0].replace(/^0+/, "");
            var monthLeadingZero = stepStartSplit[1].replace(/^0+/, "");
            $(".editdate .date-start-day option").each(function () {
                if ($(this).val() === dayLeadingZero) {
                    $(".editdate .date-start-day option:selected").removeAttr("selected");
                    $(this).prop("selected", true);
                }
            });
            $(".editdate .date-start-month option").each(function () {
                if ($(this).val() === monthLeadingZero) {
                    $(".editdate .date-start-month option:selected").removeAttr("selected");
                    $(this).prop("selected", true);
                }
            });
            $(".editdate .date-start-year option").each(function () {
                if ($(this).val() === stepStartSplit[2]) {
                    $(".editdate .date-start-year option:selected").removeAttr("selected");
                    $(this).prop("selected", true);
                }
            });

            var stepEndSplit = stepDateEnd.split("/");
            $(".editdate .date-end-day option").each(function () {
                var dayLeadingZero = stepEndSplit[0].replace(/^0+/, "");
                if ($(this).val() === dayLeadingZero) {
                    $(".editdate .date-end-day option:selected").removeAttr("selected");
                    $(this).prop("selected", true);
                }
            });
            $(".editdate .date-end-month option").each(function () {
                var monthLeadingZero = stepEndSplit[1].replace(/^0+/, "");
                if ($(this).val() === monthLeadingZero) {
                    $(".editdate .date-end-month option:selected").removeAttr("selected");
                    $(this).prop("selected", true);
                }
            });
            $(".editdate .date-end-year option").each(function () {
                if ($(this).val() === stepEndSplit[2]) {
                    $(".editdate .date-end-year option:selected").removeAttr("selected");
                    $(this).prop("selected", true);
                }
            });
        } else {
            var stepPrevNumber = stepNumber - 1;
            var stepNextNumber = stepNumber + 1;
            var stepNextDateStart = $(".js-step-" + stepNextNumber + " .datestart").text();
            var stepPrevDateEnd = $(".js-step-" + stepPrevNumber + " .dateend").text();
            if (stepNextDateStart) {
                var stepNextStartSplit = stepNextDateStart.split("/");
                var dayLeadingZero = stepNextStartSplit[0].replace(/^0+/, "");
                var monthLeadingZero = stepNextStartSplit[1].replace(/^0+/, "");
                var day = parseInt(dayLeadingZero);
                var month = parseInt(monthLeadingZero);
                var year = parseInt(stepNextStartSplit[2]);

                $(".editdate .date-end-day option").each(function () {
                    if ($(this).val() == day) {
                        $(this).prop("selected", true);
                    }
                });
                $(".editdate .date-end-month option").each(function () {
                    if ($(this).val() == month) {
                        $(this).prop("selected", true);
                    }
                });
                $(".editdate .date-end-year option").each(function () {
                    if ($(this).val() == year) {
                        $(this).prop("selected", true);
                    }
                });
            } else {
                if (stepPrevDateEnd) {
                    var stepPrevEndSplit = stepPrevDateEnd.split("/");
                    var dayLeadingZero = stepPrevEndSplit[0].replace(/^0+/, "");
                    var monthLeadingZero = stepPrevEndSplit[1].replace(/^0+/, "");
                    var day = parseInt(dayLeadingZero);
                    var month = parseInt(monthLeadingZero);
                    var year = parseInt(stepPrevEndSplit[2]);
                    $(".editdate .date-end-day option").each(function () {
                        if ($(this).val() == day) {
                            $(this).prop("selected", true);
                        }
                    });
                    $(".editdate .date-end-month option").each(function () {
                        if ($(this).val() == month) {
                            $(this).prop("selected", true);
                        }
                    });
                    $(".editdate .date-end-year option").each(function () {
                        if ($(this).val() == year) {
                            $(this).prop("selected", true);
                        }
                    });
                }
            }
            if (stepPrevDateEnd) {
                var stepPrevEndSplit = stepPrevDateEnd.split("/");
                var dayLeadingZero = stepPrevEndSplit[0].replace(/^0+/, "");
                var monthLeadingZero = stepPrevEndSplit[1].replace(/^0+/, "");
                var day = parseInt(dayLeadingZero);
                var month = parseInt(monthLeadingZero);
                var year = parseInt(stepPrevEndSplit[2]);
                $(".editdate .date-start-day option").each(function () {
                    if ($(this).val() == day) {
                        $(this).prop("selected", true);
                    }
                });
                $(".editdate .date-start-month option").each(function () {
                    if ($(this).val() == month) {
                        $(this).prop("selected", true);
                    }
                });
                $(".editdate .date-start-year option").each(function () {
                    if ($(this).val() == year) {
                        $(this).prop("selected", true);
                    }
                });
            } else {
                if (stepNextDateStart) {
                    var stepNextStartSplit = stepNextDateStart.split("/");
                    var dayLeadingZero = stepNextStartSplit[0].replace(/^0+/, "");
                    var monthLeadingZero = stepNextStartSplit[1].replace(/^0+/, "");
                    var day = parseInt(dayLeadingZero);
                    var month = parseInt(monthLeadingZero);
                    var year = parseInt(stepNextStartSplit[2]);

                    $(".editdate .date-start-day option").each(function () {
                        if ($(this).val() == day) {
                            $(this).prop("selected", true);
                        }
                    });
                    $(".editdate .date-start-month option").each(function () {
                        if ($(this).val() == month) {
                            $(this).prop("selected", true);
                        }
                    });
                    $(".editdate .date-start-year option").each(function () {
                        if ($(this).val() == year) {
                            $(this).prop("selected", true);
                        }
                    });
                }
            }
        }
    }

    //show popin
    $(document).on("click", ".js-openpopin", function (e) {
        $(".popin-wrap:visible :input").first().focus();
        var title = $(this).data("title") || null;
        var text = $(this).data("text") || null;
        var target = $(this).data("target") || null;
        var fixed = $(this).data("fixed") || null;
        if (fixed) $(".backdrop").show();
        else $(".backdrop").show();
        var route = $(this).data("route") || null;
        var popinHeight = $(this).data("height") || null;
        if (popinHeight) $(".popin").css("height", popinHeight);
        $(".hidden-popin-data").val($(this).data("popin-data"));
        var step = $(this).data("step") || null;
        var stepChange = $(this).data("stepchange") || null;
        var docpdf = $(this).data("requiredpdf") || null;
        if (docpdf) {
            $(".input-max-filesize").html('Attention votre fichier ne doit pas dépasser 1Go ! <br /><span class="cerror">Attention, ce fichier est indiqué comme “à signer” donc il doit être au format PDF</span>');
        }
        var docname = $(this).data("docname") || null;
        if (docname) {
            $(".docname").html(docname);
        }
        if (step) {
            //for check date after select
            $(".hidden-step").attr("data-step", step);
            //for set date before open popin
            setStepDate(step, stepChange);
        }
        var invitation = $(this).data("invitationemail") || null;
        if (invitation) {
            $(".email-invitation").val(invitation);
        }
        var folderoldname = $(this).data("folderoldname") || null;
        if (folderoldname) {
            $("#newfoldername").val(folderoldname);
        }
        if (target) {
            $(target + " .js-popin-submit").attr("disabled", false);
            $(target).show();
            if (title) $(".popin").children(target).children(".popin-title").html(title);
            if (text) $(".popin").children(target).children(".popin-text").html(text);
            if (route) {
                $(target + " .js-popin-submit").data("route", route);
            }
        } else {
            $(".popin-wrap").show();
            if (title) $(".popin").find(".popin-title").html(title);
            if (text) $(".popin").find(".popin-text").html(text);
        }
        $(".popin").show();
        if (target === ".popin-needinfo") {
            $(document).on("click", ".backdrop", function () {
                $(".backdrop").hide();
                $(".backdrop-fix").hide();
                $(".backdropstepstart").hide();
                $(".popin").hide();
                $(".popin-wrap").hide();
                $(".popin-errors").hide();
                $(".popin-error").remove();
            });
        }
    });

    // A conserver ?

    // function checkPopinPhone(that) {
    //   var phone = $(".needphone-input").val();
    //     if (!phone || phone.length !== 10) {
    //       $(".popin-errors").show();
    //       $(".popin-errors").html("<div class='popin-error cerror mb-3'>Veuillez saisir un numéro de téléphone de 10 chiffres</div>");
    //       that.attr('disabled', false);
    //       $(".loader-popin").fadeOut(1000);
    //       $('.popin').css('overflow', 'auto');
    //       return false;
    //     }
    //     if (!$.isNumeric(phone))
    //     {
    //       $(".popin-errors").show();
    //       $(".popin-errors").html("<div class='popin-error cerror mb-3'>Veuillez saisir un numéro de téléphone de 10 chiffres</div>");
    //       that.attr('disabled', false);
    //       $(".loader-popin").fadeOut(1000);
    //       $('.popin').css('overflow', 'auto');
    //       return false;
    //     }
    //     return true;
    // };

    function checkPopinDate(that) {
        //Get hidden step number
        var step = $(".hidden-step").attr("data-step");
        var stepPrevNumber = parseInt(step) - 1;
        var stepNextNumber = parseInt(step) + 1;
        var stepNextDateStart = $(".js-step-" + stepNextNumber + " .datestart").text();
        stepNextDateStart = stepNextDateStart.split("/");

        var stepPrevDateEnd = $(".js-step-" + stepPrevNumber + " .dateend").text();
        stepPrevDateEnd = stepPrevDateEnd.split("/");

        var currentDateStart = $(".js-step-" + step + " .dateend").text();
        currentDateStart = currentDateStart.split("/");

        var currentDateEnd = $(".js-step-" + step + " .datestart").text();
        currentDateEnd = currentDateEnd.split("/");

        var currentDateStartDay = $(".date-start .date-start-day").val();
        var currentDateStartMonth = $(".date-start .date-start-month").val();
        var currentDateStartYear = $(".date-start .date-start-year").val();
        var currentDateEndDay = $(".date-end .date-end-day").val();
        var currentDateEndMonth = $(".date-end .date-end-month").val();
        var currentDateEndYear = $(".date-end .date-end-year").val();

        var currentDateStartTimestamp = moment([currentDateStartYear, currentDateStartMonth - 1, currentDateStartDay]).format("YYYYMMDD");
        currentDateStartTimestamp = parseInt(currentDateStartTimestamp);
        var currentDateEndTimestamp = moment([currentDateEndYear, currentDateEndMonth - 1, currentDateEndDay]).format("YYYYMMDD");
        currentDateEndTimestamp = parseInt(currentDateEndTimestamp);
        var stepNextStartTimestamp = moment([stepNextDateStart[2], stepNextDateStart[1] - 1, stepNextDateStart[0]]).format("YYYYMMDD");
        stepNextStartTimestamp = parseInt(stepNextStartTimestamp);
        var stepPrevEndTimestamp = moment([stepPrevDateEnd[2], stepPrevDateEnd[1] - 1, stepPrevDateEnd[0]]).format("YYYYMMDD");
        stepPrevEndTimestamp = parseInt(stepPrevEndTimestamp);

        if (currentDateStartTimestamp < stepPrevEndTimestamp) {
            $(".popin-errors").show();
            $(".popin-errors").html("<div class='popin-error cerror mb-3'>Attention, il semblerait que la date de début sélectionnée soit antérieure à la date de fin de la phase précédente ! <br />Mieux vaut faire les choses dans l’ordre : veuillez choisir une date de début postérieure à la phase précédente.</div>");
            that.attr("disabled", false);
            $(".loader-popin").fadeOut(1000);
            $(".popin").css("overflow", "auto");
            return false;
        } else if (currentDateEndTimestamp > stepNextStartTimestamp) {
            $(".popin-errors").show();
            $(".popin-errors").html("<div class='popin-error cerror mb-3'>Attention, il semblerait que la date de fin sélectionnée soit postérieure à la date de début de la phase suivante ! <br />Faisons les choses dans l’ordre : veuillez choisir une date de fin antérieure à la date de début de la phase suivante.</div>");
            that.attr("disabled", false);
            $(".loader-popin").fadeOut(1000);
            $(".popin").css("overflow", "auto");
            return false;
        } else if (currentDateStartTimestamp > currentDateEndTimestamp) {
            $(".popin-errors").show();
            $(".popin-errors").html("<div class='popin-error cerror mb-3'>Attention, il semblerait que la date de début sélectionnée soit postérieure à la date de fin !</div>");
            that.attr("disabled", false);
            $(".loader-popin").fadeOut(1000);
            $(".popin").css("overflow", "auto");
            return false;
        } else {
            $(".loader-popin").fadeOut(1000);
            $(".popin").css("overflow", "auto");
            return true;
        }
    }

    $(document).on("click", ".js-popin-submit", function () {
        $(".loader-popin").fadeIn(200);
        $(".popin").css("overflow", "hidden");
        $(this).attr("disabled", true);
        var currentTarget = $(this).data("currenttarget");
        switch (currentTarget) {
            case ".editdate":
                if (!checkPopinDate($(this))) return false;
        }
        var submitUrl = $(this).data("route") || null;
        var noReload = $(this).data("noreload") || null;
        var submitMethod = $(this).data("method") || "POST";
        var data = {};
        if (currentTarget) var allInputs = $(`${currentTarget} :input`);
        else var allInputs = $(`.popin:visible :input`);
        var formData = new FormData();
        allInputs.each(function (i, val) {
            if (val.name) {
                if (val.type === "file") {
                    formData.append(val.name, val.files[0]);
                } else if (val.type === "checkbox" || val.type === "radio") {
                    if (val.checked) {
                        formData.append(val.name, val.value);
                    }
                } else formData.append(val.name, val.value);
            }
        });
        if (submitUrl) {
            var request = new XMLHttpRequest();
            request.open(submitMethod, submitUrl);
            request.send(formData);
            request.onreadystatechange = function () {
                if (request.readyState == 4 && request.status == 200) {
                    var responseData = JSON.parse(request.responseText);
                    if (!noReload) location.reload();
                    else {
                        $(".loader-popin").fadeOut(1000);
                        $(".popin").css("overflow", "auto");
                        $(".backdrop").hide();
                        $(".backdrop-fix").hide();
                        $(".popin-wrap").hide();
                        $(".popin").hide();
                    }
                } else {
                    $(".loader-popin").fadeOut(1000);
                    $(".popin").css("overflow", "auto");
                    $(".backdrop").hide();
                    $(".backdrop-fix").hide();
                    $(".popin-wrap").hide();
                    $(".popin").hide();
                }
            };
        } else {
            $(this).attr("disabled", false);
            $(".loader-popin").fadeOut(1000);
            $(".popin").css("overflow", "auto");
        }
    });

    var clipboard = new ClipboardJS(".copy-link");

    clipboard.on("success", function (e) {
        e.clearSelection();
        $(".copy-link").text(translation("Lien Copié"));
        $(".copy-link").removeClass("btn-whitefilled-primary").addClass("btn-greenfilled-primary");
        setTimeout(textNormal, 3000);
    });

    function textNormal() {
        $(".copy-link").text("copier le lien");
        $(".copy-link").addClass("btn-whitefilled-primary").removeClass("btn-greenfilled-primary");
    }

    $(document).on("click", ".js-openpopin-withdata", function () {
        let url = $(this).data("getdata");
        let target = $(this).data("target");
        let projectId = $(this).data("projectid");
        var prom = $.get(url);
        if ($("#obscheckpayment2").length) $("#obscheckpayment2").prop("checked", false);
        if ($("#obscheckpayment").length) $("#obscheckpayment").prop("checked", false);
        var objectData = null;
        prom.then(function (data) {
            objectData = JSON.parse(data);
            if (objectData) {
                $(".obs-share-url").val(objectData.url);
                $(".idProjectObs").attr("data-projectid", projectId);
                $(".backdrop").show();
                $(target + " .js-popin-submit").attr("disabled", false);
                $(target).show();
                $(".popin").show();
            }
        });
        prom.catch(function (err) {
            window.location.reload();
        });
    });

    $(document).on("click", ".js-payment-submit", function () {
        $(".loader-popin").fadeIn(200);
        $(".popin").css("overflow", "hidden");
        $(this).attr("disabled", true);
        var currentTarget = $(this).data("currenttarget");
        var routeAfter = $(this).data("routeafter");
        var submitUrl = $(this).data("route") || null;
        var directUrl = $(this).data("directurl") || null;
        var submitMethod = "POST";
        var data = {};
        var allInputs = $(`${currentTarget} :input`);
        var formData = new FormData();
        allInputs.each(function (i, val) {
            if (val.name) {
                formData.append(val.name, val.value);
            }
        });
        if (directUrl && submitUrl) {
            window.location.href = submitUrl;
        } else if (submitUrl) {
            var request = new XMLHttpRequest();
            request.open(submitMethod, submitUrl);
            request.send(formData);
            request.onreadystatechange = function () {
                if (request.readyState == 4 && request.status == 200) {
                    var responseData = JSON.parse(request.responseText);
                    if (responseData.status === "ok") {
                        window.location.href = routeAfter;
                    } else {
                        $(this).attr("disabled", true);
                        if (responseData.iban) {
                            if (!$(".erroriban").length) {
                                $("#iban").addClass("error");
                                $("#iban").after("<div class='cerror mt-2 erroriban'>" + responseData.message + "</div>");
                            }
                        } else {
                            $(".erroriban").remove();
                            if (!$(".errorinfo").length) $(".needinfo").after("<div class='cerror mt-2 errorinfo'>" + responseData.message + "</div>");
                        }
                        $(".js-payment-submit").attr("disabled", false);
                        $(".loader-popin").fadeOut(1000);
                        $(".popin").css("overflow", "auto");
                    }
                } else {
                    $(".loader-popin").fadeOut(1000);
                    $(".popin").css("overflow", "auto");
                }
            };
        } else {
            $(this).attr("disabled", false);
            $(".loader-popin").fadeOut(1000);
            $(".popin").css("overflow", "auto");
        }
    });

    $(document).on("click", ".backdrop", function () {
        if ($("input.error").length) {
            $("input.error").removeClass("error");
        }
        if ($(".cerror.elmerror").length) $(".cerror.elmerror").remove();
        $(".backdrop").hide();
        $(".popin").hide();
        $(".popin-wrap").hide();
        $(".popin-errors").hide();
        $(".popin-error").remove();
        $(".st-blocPopinError").removeClass("d-flex").addClass("d-none");
        if ($(this).hasClass("reload")) {
            window.location.reload();
        }
    });

    $(document).on("click", ".popin-close, .st-cancelBtn", function () {
        $(".backdrop").hide();
        $(".backdrop-fix").hide();
        $(".backdropstepstart").hide();
        $(".popin").hide();
        $(".popin-wrap").hide();
        $(".popin-errors").hide();
        $(".popin-error").remove();
        $(".errorMsgOffline").hide();
        $(".st-blocPopinError").removeClass("d-flex").addClass("d-none");
    });

    $(document).on("click", ".popin-close-back-info", function () {
        window.location.href = "/freelance/mon_profil/portefeuille_en_ligne";
    });

    $(document).on("click", ".popin-close-reload", function () {
        window.location.reload();
    });

    $(document).on("click", ".eastereggs *", function (e) {
        e.preventDefault();
    });

    $(document).on("click", ".eastereggs", function () {
        if (audio) audio.pause();
        $(".eastereggs").remove();
    });

    $(document).on("click", ".js-popin-close", function () {
        $(".backdrop").hide();
        $(".backdrop-fix").hide();
        $(".popin-wrap").hide();
        $(".popin").hide();
        $(".popin-errors").hide();
        $(".popin-error").remove();
    });

    $(document).on("click", ".js-popin-close-reload", function () {
        window.location.reload();
    });

    $(document).on("click", ".js-popin-offer-redirect", function () {
        window.location.href = "/freelance/mon_profil/mon_offre";
    });

    $(document).on("click", ".js-dashboard-step-date", function () {
        var dateStart = $(".popin .editdate .date-start");
        var dateEnd = $(".popin .editdate .date-end");
        var dataid = $(this).data("idstep") || null;
        var route = $(this).data("route") || null;

        if (route && dataid) {
            var httpRequest = new XMLHttpRequest();
            httpRequest.open("POST", route);
            httpRequest.setRequestHeader("Content-Type", "application/json");
            var jsonMessage = {
                dateStart: dateStart,
                dateEnd: dateEnd,
                stepId: dataid,
            };
            httpRequest.send(jsonMessage);
            httpRequest.onreadystatechange = function () {
                if (httpRequest.readyState == 4 && httpRequest.status == 200) {
                    var responseData = JSON.parse(httpRequest.responseText);
                }
            };
        }
    });

    function addCprimary() {
        if ($("td.ui-datepicker-current-day").hasClass("cprimary-7")) {
            $("td.ui-datepicker-current-day").removeClass("cprimary-7");
        } else {
            $("td.ui-datepicker-current-day").addClass("cprimary-7");
        }
    }

    window.strencode = function (data) {
        return unescape(encodeURIComponent(JSON.stringify(data)));
    };

    window.strdecode = function (data) {
        return JSON.parse(decodeURIComponent(escape(data)));
    };

    // A conserver ?

    /*Calendar weeks */
    //   $('#calendar-weeks').datepicker({
    //     inline: true,
    //     firstDay: 1,
    //     prevText: 'Précédent',
    //     nextText: 'Suivant',
    //     currentText: 'Aujourd\'hui',
    //     monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    //     monthNamesShort: ['Janv.', 'Févr.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
    //     dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    //     dayNamesShort: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
    //     dayNamesMin: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    //     weekHeader: 'Sem.',
    //     dateFormat: 'yy-mm-dd',
    //     beforeShowDay: function(date, inst) {
    //       var result = [true, '', null];
    //       var matching = $.grep(dateObjSelected, function(event) {
    //           return event.valueOf() === date.valueOf();
    //       });

    //       if (matching.length) {
    //           result = [true, 'highlight', null];
    //       }
    //       return result;
    //     },
    //     onSelect: function(dateText, inst) {
    //       var counter = 0;
    //       var counterObj = -1;
    //       var dateAsString = dateText; //the first parameter of this function
    //       var dateAsObject = $(this).datepicker( 'getDate' ); //the getDate method

    //        $.each(dateObjSelected, function(k, val) {
    //         if (dateAsObject.toString() == val.toString())
    //         {

    //           counterObj = k;
    //           return false;
    //         }
    //       });
    //       if (counterObj  == -1)
    //       {
    //         dateObjSelected.push(dateAsObject);
    //       }
    //       else {
    //         dateObjSelected.splice(counterObj, 1);
    //       }
    //       counter = $.inArray(dateAsString, dateSelected);
    //       if (counter == -1)
    //       {
    //           dateSelected.push(dateAsString);
    //       } else {
    //           dateSelected.splice(counter, 1);
    //       }
    //    }
    // });

    var elmList = [];
    var dateSelected = [];
    var dateObjSelected = [];
    // A conserver ?

    /* Calendar rdv */
    //   $('#calendar-rdv').datepicker({
    //      maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    //      minDate: new Date(),
    //     inline: true,
    //     firstDay: 1,
    //     prevText: 'Précédent',
    //     nextText: 'Suivant',
    //     currentText: 'Aujourd\'hui',
    //     monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    //     monthNamesShort: ['Janv.', 'Févr.', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.'],
    //     dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    //     dayNamesShort: ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'],
    //     dayNamesMin: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    //     weekHeader: 'Sem.',
    //     dateFormat: 'yy-mm-dd',
    //     beforeShowDay: function(date, inst) {
    //       var result = [true, '', null];
    //       var matching = $.grep(dateObjSelected, function(event) {
    //           return event.valueOf() === date.valueOf();
    //       });

    //       if (matching.length) {
    //           result = [true, 'highlight', null];
    //       }
    //       return result;
    //     },
    //     onSelect: function(dateText, inst) {
    //       var counter = 0;
    //       var counterObj = -1;
    //       var dateAsString = dateText; //the first parameter of this function
    //       var dateAsObject = $(this).datepicker( 'getDate' ); //the getDate method

    //        $.each(dateObjSelected, function(k, val) {
    //         if (dateAsObject.toString() == val.toString())
    //         {

    //           counterObj = k;
    //           return false;
    //         }
    //       });
    //       if (counterObj  == -1)
    //       {
    //         dateObjSelected.push(dateAsObject);
    //       }
    //       else {
    //         dateObjSelected.splice(counterObj, 1);
    //       }
    //       counter = $.inArray(dateAsString, dateSelected);
    //       if (counter == -1)
    //       {
    //           dateSelected.push(dateAsString);
    //       } else {
    //           dateSelected.splice(counter, 1);
    //       }
    //    }
    // });

    if ($(".msg-flash").length) {
        var msgLength = $(".msg-flash *").text();
        setTimeout(function () {
            $(".msg-flash").html("").css("display", "none");
        }, 2000 + 50 * msgLength.length);
    }

    $(document).on("click", ".return-link", function () {
        $(this).parents(".active").prev().addClass("active");
        $(this).parents(".active").removeClass("active");
        $(".errors").removeClass("active");
        $(".errors").empty();
        $(".errors").hide();
    });

    $(document).on("click", ".return-link-step-7", function () {
        //unselect
        $("#im-responsable").prop("checked", false);
        $("#name-user").prop("checked", false);
        $("#accept-transmission").prop("checked", false);
        $(this).parents(".active").prev().addClass("active");
        $(this).parents(".active").removeClass("active");
        $(".errors").removeClass("active");
        $(".errors").empty();
        $(".errors").hide();
    });

    $(document).on("click", ".js-sort-az", function () {
        $(".js-az").trigger("click");
    });

    $(document).on("click", ".js-sort-first", function () {
        $(".js-first").trigger("click");
    });

    $(document).on("click", ".js-change-project-name", function () {
        $(".change-menu-project-name").show();
        $(".change-menu-project-name input").focus();
        $(this).hide();
    });

    $(document).on("click", ".js-accountReach", function (event) {
        event.preventDefault();
        let tmp = window.location.href;
        if (tmp.indexOf("mon_profil") == -1 && tmp.indexOf("admin") == -1) {
            sessionStorage.setItem("back", tmp);
        }
        window.location.href = $(this).attr("href");
    });

    $(document).on("click", ".js-accountBack", function (event) {
        event.preventDefault();
        var back = sessionStorage.getItem("back");
        if (!back) back = $(this).attr("href");
        window.location.href = back;
    });

    $(document).on("click", ".js-backurl", function () {
        event.preventDefault();
        let tmp = window.location.href;
        if (tmp.indexOf("mon_profil") == -1) {
            sessionStorage.setItem("back", tmp);
        }
        window.location.href = $(this).attr("href");
    });

    $(document).on("click", ".js-thAcceptNotification", function () {
        var checkboxValue = false;
        if ($(this).is(":checked")) checkboxValue = true;
        var promiseReturn = $.post("/freelance/mon_profil/notifications/accepteNotifications", { thAcceptNotification: checkboxValue });

        promiseReturn.then(function (val) {});

        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    $(window).resize(function () {
        feedback();
        /*Check slick*/
        if ($(".slicks").length) {
            if ($(window).width() < 1200) {
                $(".slicks").slick({
                    dots: true,
                    infinite: false,
                    slidesToShow: 1,
                    centerMode: false,
                    variableWidth: true,
                });
            } else {
                $(".slicks").slick("unslick");
            }
        }

        if ($(".slickswithoutarrow").length) {
            if ($(window).width() < 1200) {
                $(".slickswithoutarrow").slick({
                    dots: true,
                    infinite: false,
                    slidesToShow: 1,
                    centerMode: false,
                    variableWidth: true,
                });
            }
        }

        // A conserver ?

        //if page calendar
        // if ($("#soancalendarview").length) {
        //   if (window.matchMedia("(max-width: 991.98px)").matches) {
        //     buildCalendarPlan();
        //   }
        // }
    });

    if ($(window).width() < 790) {
        $(".home-container aside").slick({
            dots: true,
            speed: 300,
            arrows: false,
        });
    }

    if ($(".slicks").length) {
        if ($(window).width() < 1200) {
            $(".slicks").slick({
                dots: true,
                infinite: false,
                slidesToShow: 1,
                centerMode: false,
                variableWidth: true,
            });
        }
    }

    if ($(".slickswithoutarrow").length) {
        if ($(window).width() < 1200) {
            $(".slickswithoutarrow").slick({
                dots: true,
                infinite: false,
                slidesToShow: 1,
                centerMode: false,
                variableWidth: true,
                arrows: false,
            });
        }
    }

    $(document).on("click", ".js-openmenu", function () {
        toggleLateralMenu();
    });
    $(document).on("click", ".js-closemenu", function () {
        $(".vertical-menu").removeClass("mobileshow");
    });

    $.fn.serializeObject = function () {
        var o = {};
        var a = this.serializeArray();
        $.each(a, function () {
            if (o[this.name]) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || "");
            } else {
                o[this.name] = this.value || "";
            }
        });
        return o;
    };

    $.fn.dataTable.moment("DD/MM/YYYY");
    $("#mytable, #mytable1, #mytable2, #mytable3").DataTable({
        order: [],
        responsive: true,
        "columns:": [
            {
                // Responsive control column
                data: null,
                defaultContent: "",
                className: "control",
                orderable: false,
            },
            {
                // Checkbox select column
                data: null,
                defaultContent: "",
                className: "select-checkbox",
                orderable: false,
            },
            { data: "first_name" },
            { data: "last_name", className: "never" },
            { data: "position" },
            { data: "office" },
            { data: "start_date" },
            { data: "salary", render: $.fn.dataTable.render.number(",", ".", 0, "$") },
        ],
        language: {
            decimal: "",
            emptyTable: translation("Aucune donnée"),
            info: "Showing _START_ to _END_ of _TOTAL_ entries",
            infoEmpty: "Showing 0 to 0 of 0 entries",
            infoFiltered: "(filtered from _MAX_ total entries)",
            infoPostFix: "",
            thousands: ",",
            lengthMenu: translation("Afficher les entrées") + " _MENU_",
            loadingRecords: translation("Chargement..."),
            processing: translation("En cours de traitement..."),
            search: "",
            searchPlaceholder: translation("Recherche"),
            zeroRecords: translation("Aucune correspondance"),
            paginate: {
                first: "Premier",
                last: "Dernier",
                next: translation("Suivant"),
                previous: translation("Précédent"),
            },
            aria: {
                sortAscending: ": activate to sort column ascending",
                sortDescending: ": activate to sort column descending",
            },
        },
        fnDrawCallback: function () {
            if ($("#mytable_paginate span a").length < 2) {
                $("#mytable_paginate").hide();
            }
            if ($("#mytable1_paginate span a").length < 2) {
                $("#mytable1_paginate").hide();
            }
            if ($("#mytable2_paginate span a").length < 2) {
                $("#mytable2_paginate").hide();
            }
        },
    });

    $(document).on("change", "input[type='radio']:not(.pack-input)", function () {
        if ($(this).is(":checked")) {
            $(".form-check").removeClass("checked");
            $(this).parents(".form-check").addClass("checked");
        }
    });

    /*Select pack signature*/
    $(document).on("change", ".pack-input", function () {
        $(".pack-content").removeClass("active");
        if ($(this).is(":checked")) {
            let packName = $(this).prev().prev().html();
            let packQty = $(this).prev().html();
            let packPrice = $(this).next().next().html();

            $(".pack-changename").html(packName);
            $(".pack-changeqty").html(packQty);
            $(".pack-changeprice").html(packPrice);

            $(this).parent().addClass("active");
        }
    });

    /*Alert on specific link*/
    $(document).on("click", ".link-warning-before", function (e) {
        e.preventDefault();
        var url = $(this).attr("href");
        var text = $(this).data("text") || $(this).next(".link-warning-before-text").html();
        var cancel = $(this).data("cancel");
        var accept = $(this).data("accept");
        var title = $(this).data("title") || translation("Êtes-vous sûr ?");
        var elm = document.getElementsByClassName("link-warning-before-text");
        const wrapper = document.createElement("div");
        let swalWarning = $(this).data("warning") || false;
        wrapper.innerHTML = text;
        if ($(this).data("custom") && $(this).data("custom") !== "undefined") {
            swal({
                content: wrapper,
                buttons: [cancel, accept],
                dangerMode: swalWarning,
                className: "st-confirmDeleteContainer",
            }).then(function (isConfirm) {
                if (isConfirm) {
                    window.location.href = url;
                }
            });
        } else {
            swal({
                title: title,
                content: wrapper,
                icon: "warning",
                buttons: [cancel, accept],
                dangerMode: swalWarning,
            }).then(function (isConfirm) {
                if (isConfirm) {
                    window.location.href = url;
                }
            });
        }
    });

    /*Click on dashboard add comment text button*/
    $(document).on("click", ".add-comment-write", function () {
        $(this).parent().hide();
        var nbrEdit = $(this).data("nbredit");
        $(this).parents(".document-help").find(".comment-box").addClass("active");
        $(this).parents(".document-help").find(".comment-box").find(".comment-write").removeClass("comment-write").addClass("comment-send");
        $(this)
            .parents(".document-help")
            .find(".comment-box")
            .find(".comment-send")
            .html("<div class='btn btn-cancel-add-comment'>" + translation("Annuler") + "</div><div class='btn btn-send-comment' data-nbredit='" + nbrEdit + "'>" + translation("Enregistrer") + "</div>");
    });

    $(document).on("click", ".btn-cancel-add-comment", function () {
        $(".add-comment-write").parent().show();
        $(".comment-box.active .comment-send").html(translation("Éditer le texte"));
        $(".comment-box.active .comment-send").addClass("comment-write").removeClass("comment-send");
        $(".comment-box.active").removeClass("active");
    });

    /*Click on dashboard edit text button*/
    $(document).on("click", ".comment-write", function () {
        var nbrEdit = $(this).data("nbredit");
        $(this).parents(".comment-box").addClass("active");
        $(this).removeClass("comment-write").addClass("comment-send");
        $(this).html("<div class='btn btn-cancel-comment'>" + translation("Annuler") + "</div><div class='btn btn-send-comment' data-nbredit='" + nbrEdit + "'>" + translation("Enregistrer") + "</div>");
    });

    $(document).on("click", ".btn-cancel-comment", function () {
        $(".comment-box.active .comment-send").html(translation("Éditer le texte"));
        $(".comment-box.active .comment-send").addClass("comment-write").removeClass("comment-send");
        $(".comment-box.active").removeClass("active");
    });

    $(document).on("click", ".btn-send-comment", function () {
        var nbrEdit = $(this).data("nbredit");
        var textarea = $("#editme_" + nbrEdit).trumbowyg("html");
        var route = $("#sendcomment_" + nbrEdit).attr("action");
        var promiseReturn = $.post(route, {
            "change-text": textarea,
        });

        promiseReturn.then(function (data) {
            if (data) {
                window.location.reload();
            }
        });
    });

    // A conserver ?

    /*insert wysywyg on dashboard*/
    // $('#editme_1, #editme_2, #editme_3, #editme_4, #editme_5').trumbowyg({
    //   minimalLinks: true,
    //   defaultLinkTarget: '_blank',
    //   removeformatPasted: true,
    //   btns: [
    //     ['link'],
    //     ['strong', 'em'],
    //     ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
    //     ['unorderedList', 'orderedList'],
    //     ['fullscreen']
    // ]
    // });

    /*insert wysywyg on paiement*/
    $(".editmypayment").trumbowyg({
        btns: [["link"], ["strong", "em"], ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"], ["unorderedList", "orderedList"], ["fullscreen"]],
    });

    /*insert wysywyg on relaunch*/
    $(".editrelaunch").trumbowyg({
        btns: [["link"], ["strong", "em"], ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"], ["unorderedList", "orderedList"], ["fullscreen"]],
    });

    /*insert wysywyg on relaunch*/

    /*Create document step */
    if ($(".create-document").find(".is-invalid").length !== 0) {
        $(".js-doc-required").slideDown("slow");
        $(".js-doc-signdate").slideDown("slow");
        $(".js-doc-sign").slideDown("slow");
        $("#new_document_submit").show();
    }
    $(document).on("input", ".js-doc-file input", function () {
        $(".js-doc-required").slideDown("slow");
    });
    $(document).on("change", ".js-doc-required input", function () {
        var isPremium = $(".ispremium").data("isPremium");
        var type = $(this).attr("type");
        var inputs = $(this).parents(".js-doc-required").find('input[type="checkbox"]');
        $.each(inputs, function (k, val) {
            $(val).prop("checked", false);
        });
        if (type === "checkbox") {
            var inputChanged = $(this);
            inputChanged.prop("checked", true);
            $("#new_document_thFile").val("");
            $("#new_document_thFile").next(".custom-file-label").html("");
        }
        var file = $("#new_document_thFile").val();
        var ext = file.substring(file.lastIndexOf(".") + 1);
        if (ext == "pdf" || !file) {
            $(".js-doc-sign").slideDown("slow");
            $("#new_document_submit").show();
            $(".warning-upload").remove();
        } else {
            $(".js-doc-signdate").slideUp("slow");
            $(".js-doc-sign").slideUp("slow");
            $("#new_document_submit").show();
            //reset checkBox and select
            $("#new_document_thIsSignatureRequiredArchi").prop("checked", false);
            $("#new_document_thIsSignatureRequiredUser").prop("checked", false);
            $("#new_document_thSignatureDueDatetime_day").prop("selectedIndex", 0);
            $("#new_document_thSignatureDueDatetime_month").prop("selectedIndex", 0);
            $("#new_document_thSignatureDueDatetime_year").prop("selectedIndex", 0);
            if (!$(".warning-upload").length && isPremium) $(".js-doc-required").append('<div class="mt-3 warning-upload">Attention ! Pour que la signature électronique ait une valeur légale, il faut que le document signé soit au format PDF. Vous ne pouvez donc pas signer d’autres formats sur Soan <i class="italic">( on ne voudrait pas que vous gâchiez une précieuse signature )</i></div>');
        }
    });
    $(document).on("change", ".js-doc-sign input", function () {
        $(".js-doc-signdate").slideDown("slow");
    });

    $(document).on("click", ".day", function () {
        $(".calendar-popin").remove();
        var evenThatDay = $(this).attr("class").split(" ").pop();
        var event = $("." + evenThatDay).find(".bullet-event");
        var l = $("." + evenThatDay).find(".bullet-event").length;
        var data = $(this).attr("class").split(" ").pop();
        var date = moment.unix(data).format("DD/MM/YYYY");
        if (l > 0) {
            var ev = $(this).children(".bullet-event").contents();
            let popin = "<div class='backdrop-calendar'><div class='calendar-popin'><div class='calendar-popinlist'><div class='calendar-popinhead'>" + date + "</div>";
            $.each(event, function (k, val) {
                popin += val.innerHTML;
            });
            popin += "</div></div></div>";
            $("body").append(popin);
        }
    });

    $(document).on("click", ".backdrop-calendar", function () {
        $(".backdrop-calendar").remove();
    });

    function feedback() {
        var menuHeight = $(".vertical-menu").height() - 20;
        var menuInside = $(".vertical-menu > div:nth-child(2)").height();
        var menuFeedBack = $(".vertical-menu > .feedback").height();
        if (menuHeight < menuInside + menuFeedBack) {
            $(".feedback").css({ position: "static", transform: "none" });
        } else {
            $(".feedback").css({ position: "absolute", transform: "translate(-50%)" });
        }
    }

    feedback();

    /*password Check */
    checkvalidpasswordDynamically();
    $(document).on("input", ".js-validate-confirm", function () {
        checkvalidpassword();
    });

    $(document).on("click", ".show-iframe-signature", function () {
        $(".iframe-signature").addClass("act");
    });

    $(document).on("click", ".backdrop-fix", function () {
        $("#new_document_thIsSignatureRequiredArchi").prop("checked", false);
        if ($(".notsigned").length == 0) $("#new_document_thIsSignatureRequiredArchi").parent().parent().parent().before("<div class='notsigned cerror'>Attention, le document ne peut pas être coché comme à signer par vous si votre numéro de téléphone n'est pas indiqué. </div>");
        $(".popin").hide();
        $(".needphone").hide();
        $(".backdrop-fix").hide();
    });

    //remove loader
    $(".loader").fadeOut("1000");

    //birthdate start day - 18 years
    var $birthday = $("#mangopay_infos_thBirthday_year").find(":selected").text();
    if ($birthday === "1899") {
        var y = new Date();
        var years = y.getFullYear() - 18;
        $("#mangopay_infos_thBirthday_year").find(`option[value='${years}']`).attr("selected", true);
    }

    //Edition texte
    $(document).on("click", ".comment-write", function () {
        $(this).parents(".comment-box").addClass("editing");
    });

    //Autocomplétion search society
    $(document).on("input", ".autocomplete-legalname", function (e) {
        $(this).removeClass("input-error");
        $(".btn-outline-secondary").text(translation("Créer une entreprise")).addClass("createcompany").removeClass("inviteincompany");
        let val = $(this).val();
        let response = $.post("/freelance/mon_profil/find_company", { search: val });
        response.then(function (value) {
            $(".autocomplete-rslt").html(" ");
            $(".autocomplete-rslt").addClass("active");
            let objParsed = $.parseJSON(value);
            $("#company_infos_thBusinessName").removeAttr("data-id");
            $("#company_infos_thBusinessName").removeAttr("data-email");
            $("#company_infos_thBusinessName").removeAttr("data-society");
            $("#company_infos_thBusinessName").removeAttr("data-headquarteraddr");
            let tabRslt = objParsed.results;
            $(tabRslt).each(function (k, v) {
                let parsedResult = v;
                if (parsedResult.address && parsedResult.siret)
                    $(".autocomplete-rslt").append(
                        "<div class='autorslt' data-id='" +
                            parsedResult.id +
                            "' data-email='" +
                            parsedResult.email +
                            "' data-society='" +
                            parsedResult.name +
                            "' data-headquarteraddr='" +
                            parsedResult.address +
                            ", " +
                            parsedResult.postalCode +
                            " " +
                            parsedResult.city +
                            "'><div class='d-flex justify-content-between flex-column'><span>" +
                            parsedResult.name +
                            "</span> <span><span class='mr-2'>Siret : " +
                            parsedResult.siret +
                            " </span> <span>Adresse : " +
                            parsedResult.address +
                            "</span></span></div></div>"
                    );
                else if (!parsedResult.address && parsedResult.siret) {
                    $(".autocomplete-rslt").append("<div class='autorslt' data-id='" + parsedResult.id + "' data-email='" + parsedResult.email + "' data-society='" + parsedResult.name + "' data-headquarteraddr='" + parsedResult.address + ", " + parsedResult.postalCode + " " + parsedResult.city + "'><div class='d-flex justify-content-between flex-column'><span>" + parsedResult.name + "</span> <span><span class='mr-2'>Siret : " + parsedResult.siret + "</span></span></div></div>");
                } else if (parsedResult.address && !parsedResult.siret) {
                    $(".autocomplete-rslt").append("<div class='autorslt' data-id='" + parsedResult.id + "' data-email='" + parsedResult.email + "' data-society='" + parsedResult.name + "' data-headquarteraddr='" + parsedResult.address + ", " + parsedResult.postalCode + " " + parsedResult.city + "'><div class='d-flex justify-content-between flex-column'><span>" + parsedResult.name + "</span> <span><span>Adresse : " + parsedResult.address + "</span></span></div></div>");
                } else {
                    $(".autocomplete-rslt").append("<div class='autorslt' data-id='" + parsedResult.id + "' data-email='" + parsedResult.email + "' data-society='" + parsedResult.name + "' data-headquarteraddr='" + parsedResult.address + ", " + parsedResult.postalCode + " " + parsedResult.city + "'><div class='d-flex justify-content-between flex-column'><span>" + parsedResult.name + "</span> <span></span></div></div>");
                }
            });
        });
    });

    // A conserver ?

    //Select society
    $(document).on("click", ".autorslt", function (e) {
        let val = $(this).text();
        let city = $(this).data("society");
        let addr = $(this).data("headquarteraddr");
        let email = $(this).data("email");
        let id = $(this).data("id");
        $(".btn-outline-secondary").text("Continuer").addClass("inviteincompany").removeClass("createcompany");
        $("#company_infos_thBusinessName").attr("data-society", city);
        $("#company_infos_thBusinessName").attr("data-headquarteraddr", addr);
        $("#company_infos_thBusinessName").attr("data-email", email);
        $("#company_infos_thBusinessName").attr("data-id", id);
        $("#company_infos_thBusinessName").val(val);
        $(".autocomplete-rslt").html(" ");
        $(".autocomplete-rslt").removeClass("active");
    });

    //Click create company
    $(document).on("click", ".info-society-form .btn-outline-secondary.createcompany", function () {
        //Send info for create Company
        window.location.href = "/freelance/mon_profil/completer_mes_informations/creation_entreprise";
    });

    //click on createcompany in popin payment
    $(document).on("click", ".company-step .btn-outline-secondary.createcompany", function (e) {
        //Send info for create Company
        $(".company-select").hide();
        $(".popinpayment-company-block").show();
        $(".company-step1").show();
    });

    //Click create company
    $(document).on("click", ".btn-outline-secondary.inviteincompany", function () {
        //Send Create Company
        let id = $("#company_infos_thBusinessName").data("id");
        $(".loader").fadeIn();
        let promiseReturn = $.post("/freelance/mon_profil/add_company", { company: id });

        promiseReturn.then(function (val) {
            $(".loader").fadeOut("1000");
            $(".recap").html("");
            let v = JSON.parse(val);
            if (v.status === "success") {
                if ($(".popin-succes-addcompany").length) {
                    $(".popin-payment-noiban").hide();
                    $(".popin-payment-offer").hide();
                    $(".popin-succes-addcompany").show();
                } else {
                    window.location.href = "/freelance/mon_profil/informations";
                }
            } else {
                window.location.reload();
            }
        });

        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    //click on society not in list
    $(document).on("click", ".autocomplete-false", function (e) {
        $(".autocomplete-rslt").html(" ");
        $(".autocomplete-rslt").removeClass("active");
        $(".autocomplete-fail").addClass("active");
    });

    //click not my society in popin
    $(document).on("click", ".js-popin-notmysociety", function () {
        $(".autocomplete-rslt").html(" ");
        $(".autocomplete-rslt").removeClass("active");
        $(".popin").hide();
        $(".backdrop").hide();
        $(".popin .popin-mybusiness").hide();
        $(".autocomplete-fail").addClass("active");
    });

    $(document).on("click", ".autocomplete-submit", function () {
        let val = $("#company_infos_thBusinessName").val();
        if (val) {
            let society = $("#company_infos_thBusinessName").attr("data-society");
            let hq = $("#company_infos_thBusinessName").attr("data-headquarteraddr");
            let email = $("#company_infos_thBusinessName").attr("data-email");
            let id = $("#company_infos_thBusinessName").attr("data-id");
            if (id) {
                $(".hidden-company").val(id);
                $(".society-name").html(society);
                $(".society-hq").html(hq);
                $(".society-email").html(email);
                $(".popin").show();
                $(".backdrop").show();
                $(".popin-mybusiness").show();
            } else {
                $("body").append(
                    `<div class="backdrop-flash"></div><div class="popin-flash"><div class="popin-flash-wrap"><div class="popin-flash-close">x</div><div class="popin-title">Cette entreprise n'existe pas !</div><div class="popin-content">Nous ne retrouvons pas votre entreprise dans celles déjà enregistrées sur Soan. <br /> Peut-être n'existe-t-elle pas encore ? Vous pouvez facilement la créer en fermant cette fenêtre et en cliquant sur le bouton "Créer mon entreprise".</div><div class="popin-actions"><button type="button" class="btn btn-primary popin-flash-close">Ok</div></div></div></div>`
                );
            }
        } else {
            $("#company_infos_thBusinessName").attr("placeholder", "Indiquez le nom de votre entreprise ( ex : Dusse Design, Agence Numérobis & Co... )");
        }
    });

    $(document).on("click", ".popin-flash-close", function () {
        $(".backdrop-flash").hide();
        $(".popin-flash").hide();
    });

    $(document).on("click", ".backdrop-flash", function () {
        $(".backdrop-flash").hide();
        $(".popin-flash").hide();
    });

    //UBO popin
    $(document).on("click", ".js-open-ubo", function () {
        $(".popin-ubo").addClass("active");
        $("section.archi-modifinfo").addClass("hide");

        if ($(this).data("keepvalue")) {
            //keep value if exist
            let uboId = $(this).data("uboid");
            let firstName = $(this).data("firstname");
            let lastName = $(this).data("lastname");
            let address = $(this).data("address");
            let cp = $(this).data("cp");
            let city = $(this).data("city");
            let nationality = $(this).data("nationality");
            let birthday = $(this).data("birthday");
            let birthcity = $(this).data("birthcity");

            $("#new_ubo_thFirstName").attr("data-id", uboId);
            $("#new_ubo_thFirstName").val(firstName);
            $("#new_ubo_thLastName").val(lastName);
            $("#new_ubo_thAddress").val(address);
            $("#new_ubo_thCity").val(city);
            $("#new_ubo_thPostalCode").val(cp);
            $(".new_ubo_thCountry").val();
            $(".new_ubo_thNationality").val(nationality);
            $("#new_ubo_thBirthday").val(birthday);

            $(".new_ubo_thBirthCountry").val();
            $("#new_ubo_thBirthCity").val(birthcity);
        } else {
            let today = new Date();
            let monthNumber = today.getMonth() + 1;
            let monthString = monthNumber > 9 ? monthNumber.toString() : "0" + monthNumber.toString();
            $("#new_ubo_thFirstName").val("");
            $("#new_ubo_thLastName").val("");
            $("#new_ubo_thAddress").val("");
            $("#new_ubo_thCity").val("");
            $("#new_ubo_thPostalCode").val("");
            $("#new_ubo_thBirthday").val(`${today.getDate()}/${monthString}/${today.getFullYear() - 18}`);
            $("#new_ubo_thBirthCity").val("");
            $("#new_ubo_thBirthday_day option:selected").prop("selected", false);
            $("#new_ubo_thBirthday_month option:selected").prop("selected", false);
            $("#new_ubo_thBirthday_year option:selected").prop("selected", false);
        }
    });
    $(document).on("click", ".js-back-ubo", function () {
        $(".popin-ubo").removeClass("active");
        $(".archi-modifinfo.hide").removeClass("hide");
    });

    /*Popin after change step TDB*/
    $(document).on("click", ".next-step-start", function (e) {
        e.preventDefault();
        let href = $(this).attr("href");
        $(".popin").css("minHeight", "auto");
        $(".changestep .js-popin-changestepstart").attr("data-route", href);
        $("body").append(`<div class='backdropstepstart' data-href="${href}"></div>`);
        $(".changestep").show();
        $(".popin").show();
    });

    $(document).on("click", ".backdropstepstart", function () {
        $(".changestep").hide();
        $(".popin").hide();
        window.location.href = $(this).data("href");
    });

    $(document).on("click", ".js-popin-changestepstart", function () {
        $(".changestep").hide();
        $(".popin").hide();
        window.location.href = $(this).data("route");
    });

    // A conserver ?

    //Payment demand check amount
    // $(document).on('input', '#new_payment_thAmount, #new_payment_other_thAmount', function() {
    //   let val = $(this).val();
    //   val = val.trim();
    //   let rslt = /^\d+(\.\d+)?(?=$| )/.test(val);
    //   if (!rslt) {
    //     $(this).addClass('error');
    //     $('#new_payment_submit').prop('disabled', true);
    //     if ($('.amount-error-text.cerror').length <= 0)
    //       $(this).parent().after('<div class="amount-error-text cerror">Attention, le montant n’est pas valide. Veuillez utiliser uniquement des chiffres et un point comme séparateur pour les centimes.</div>');
    //   }
    //   else {
    //     $('.amount-error-text').remove();
    //     $(this).removeClass('error');
    //     //$(this).parents('.new-payment').find('.js-popin-submit').prop('disabled', false);
    //     $('#new_payment_submit').prop('disabled', false);
    //     $('.amount-error-text-max').remove();
    //   }
    // });

    //Email and notifications on choose weekly show select day
    $(document).on("input", "#change_notification_infos_thFrequency_1", function () {
        let prop = $(this).is(":checked") || null;
        if (prop) {
            $(".choiceday").show();
        } else {
            $(".choiceday").hide();
        }
    });

    $(document).on("submit", function () {
        $(".loader").fadeIn();
    });

    // A conserver ?

    // $(document).on('click', '.tooltip-sign', function(e) {
    //   e.preventDefault();
    //   e.stopImmediatePropagation();
    //   var popin = `<div class="popin" style="display: block;"><div class="popin-wrap" style="display: block"><div class="popin-close"></div><div class="popin-title">Signature électronique</div><div class="popin-content">${transPopinSignInfo}</div></div></div>`;
    //   $('body').append(popin);
    //   $('.backdrop').show();
    // });

    /**
     * Use mask plugin for iban and siret
     */
    $("#company_infos_thBankIBAN").mask("SSAA AAAA AAAA AAAA AAAA AAAA AAA", {
        placeholder: "____ ____ ____ ____ ____ ____ ___",
    });

    $("#iban").mask("SSAA AAAA AAAA AAAA AAAA AAAA AAA", {
        placeholder: "____ ____ ____ ____ ____ ____ ___",
    });

    $(".iban2").mask("SSAA AAAA AAAA AAAA AAAA AAAA AAA");

    $("#add_bank_user_thIban").mask("SSAA AAAA AAAA AAAA AAAA AAAA AAA", {
        placeholder: "____ ____ ____ ____ ____ ____ ___",
    });

    // $('#company_infos_thSiret').mask('000 000 000 00000', {
    //   placeholder: '___ ___ ___ _____'
    // });

    $(".siret").mask("000 000 000 00000", {
        placeholder: "___ ___ ___ _____",
    });

    $(".siret2").mask("000 000 000 00000");

    $(".siren").mask("000 000 000", {
        placeholder: "___ ___ ___",
    });

    $(".cif-nif").mask("A00 000 00A", {
        placeholder: "___ ___ ___",
    });

    $("#birthdaymask").mask("00/00/0000");

    $(".onlynumber").mask("0#");

    $(".onlychar").mask("V", {
        translation: {
            V: { pattern: /[A-Za-zÀ-ÖØ-öø-ÿ ]/, recursive: true },
        },
    });

    $("#change_infos_thFirstname").mask("V", {
        translation: {
            V: { pattern: /[A-Za-zÀ-ÖØ-öø-ÿ\- ]/, recursive: true },
        },
    });

    $("#change_infos_thLastname").mask("V", {
        translation: {
            V: { pattern: /[A-Za-zÀ-ÖØ-öø-ÿ\- ]/, recursive: true },
        },
    });

    $(document).on("input", ".need-min-amount", function () {
        let val = $(this).val();
        if (val) {
            if (parseFloat(val) < 1000) {
                $(".errors").text(translation("Le montant de votre facture"));
                $(".errors").show();
            } else {
                $(".errors").text("");
                $(".errors").hide();
            }
        } else {
            $(".errors").text("");
            $(".errors").hide();
        }
    });

    $(document).on("input", ".endwithnumber", function () {
        let value = $(this).val();
        if (!value.match(/\d$/g)) {
            if (!$(".bills-number-atend").length) $(this).parent().append('<div class="cerror bills-number-atend">Merci de terminer la numérotation par un chiffre</div>');
            $(".js-create-billsrecap").attr("disabled", true);
        } else {
            $(".bills-number-atend").remove();
            $(".js-create-billsrecap").attr("disabled", false);
        }
    });

    /**
     * Easter Eggs
     */

    $(document).on("input", ".search-input", function (e) {
        let val = $(this).val();
        if (val === "whatislove") {
            let popin = '<div class="eastereggs"><div class="whatislove"><img src="/build/images/wil.gif" /></div></div>';
            $("body").append(popin);
            audio = new Audio("/build/images/wil.mp3");
            audio.play();
        } else if (val === "wecantdothat") {
            let popin = '<div class="eastereggs"><div class="whatislove"><img src="/build/images/wcdt.gif" /></div></div>';
            $("body").append(popin);
        } else if (val === "dropthat") {
            audio = new Audio("/build/images/dropthat.mp3");
            audio.play();
            $("body").addClass("dropthat");
        }
    });

    /**
     * DropZone load after success
     */
    if ($("#dropzone").length) {
        let elm = $("#preview-template").html();

        var dropInstance = $("#dropzone").dropzone({
            url: $("#addfiles").attr("action"),
            addRemoveLinks: false,
            parallelUploads: 100,
            maxFilesize: 500,
            maxFiles: 100,
            uploadMultiple: true,
            timeout: 300000,
            autoProcessQueue: false,
            previewsContainer: ".filespreview",
            dictDefaultMessage: '<img src="/build/images/placeholder_doc.svg" height="60px" alt="upload" /><br /><span class="font-weight-bold">' + translation("Glissez deposez"),
            addedfile() {
                $(".popin-addfiles .popin-content").hide();
                $(".popin-addfiles .popin-content-second").show();
                $(".popin-addfiles .popin-actions").show();
                $(".popin-file-max").addClass("d-none");
            },
            sendingmultiple: function (file, xhr, formData) {
                var userArray = [];
                $(".autocomplete-result .proposal").each(function (index, elm) {
                    userArray.push($(elm).data("id"));
                });
                formData.append("user", userArray);
            },
            processingmultiple() {
                $(".popin-addfiles .progressbar").show();
                $(".popin-addfiles .popin-actions").hide();
            },
            totaluploadprogress(uploadProgress) {
                $(".popin-addfiles .progressbar-inner").css("width", uploadProgress + "%");
            },
            successmultiple() {
                $(".popin-addfiles .progressbar").hide();
                $(".popin-addfiles .popin-actions").show();
                $(".popin-content").show();
                $(".popin-content-second").hide();
                $(".popin-content-third").hide();
                $(".backdrop").hide();
                $(".popin").hide();
                $(".popin-wrap").hide();
                window.location.href = window.location.href + "?s=b";
            },
            init() {
                var submitButton = document.querySelector(".js-popin-addfiles");
                var wrapperThis = this;

                submitButton.addEventListener("click", function () {
                    wrapperThis.processQueue();
                });
            },
        });
    }

    $(".popin-close").on("click", function () {
        $(".popin-file-max").removeClass("d-none");
    });

    $(document).on("click", ".dropzone-payment-inside", function () {
        $(".file-facture").trigger("click");
    });

    $(document).on("click", ".dotmenu, .dotmenu-folder", function (e) {
        e.stopImmediatePropagation();
        e.preventDefault();
        $(this).next().addClass("open");
    });

    $(document).on("click", "body", function () {
        if ($(".menuhide.open").length) $(".menuhide").removeClass("open");
        $(".popin-file-max").removeClass("d-none");
    });

    $(document).on("click", ".js-folder", function (e) {
        if (!$(e.target).hasClass("nojs")) {
            let lnk = $(this).data("link");
            window.location.assign(location.protocol + "//" + window.location.hostname + lnk);
        }
    });

    /**
     * Download files on click
     */
    $(document).on("click", ".js-file", function (e) {
        if (!$(e.target).hasClass("nojs")) {
            let lnk = $(this).data("link");
            window.open(location.protocol + "//" + window.location.hostname + lnk, "_blank");
        }
    });

    // A conserver ?
    /**
     * Rename folder
     */
    // $(document).on('click', '.js-renamefolder', function(e) {
    //   e.stopImmediatePropagation();
    //   let lnk = $(this).data('link');
    //   var popin = `<div class="popin" style="display: block;">
    //   <div class="popin-wrap popin-renamefolder" style="display: block">
    //   <div class="popin-close"></div>
    //   <div class="popin-title">Changer le nom du dossier: </div>
    //   <div class="popin-content"><input type="text" maxlength="100" name="newFolderName"></div>
    //   <div class="popin-actions">
    // 		<button type="button" class="btn btn-primary js-popin-close w-auto" data-currentTarget=".popin-renamefolder">Ok</button>
    // 	</div>
    //   </div>
    //   </div>`;
    //   $('body').append(popin);
    //   $('.backdrop').show();
    // });

    /**
     * find number of days for selected month
     */
    // $(document).on('click', '.js-renamefolder', function(e) {
    //   e.stopImmediatePropagation();
    //   let lnk = $(this).data('link');
    //   var popin = `<div class="popin" style="display: block;">
    //   <div class="popin-wrap popin-renamefolder" style="display: block">
    //   <div class="popin-close"></div>
    //   <div class="popin-title">Changer le nom du dossier</div>
    //   <div class="popin-content"><input type="text" maxlength="100" name="newFolderName"></div>
    //   <div class="popin-actions">
    // 		<button type="button" class="btn btn-primary js-popin-close w-auto" data-currentTarget=".popin-renamefolder">Ok</button>
    // 	</div>
    //   </div>
    //   </div>`;
    //   $('body').append(popin);
    //   $('.backdrop').show();
    // });

    /**
     * Crop image
     */

    let result = document.querySelector(".result"),
        cropper = "";

    $(document).on("change", ".addPicture", function (e) {
        var extensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
        let valeur = $(this).val();
        if (!extensions.exec(valeur)) {
            $(".errors-picture").html("Format de fichier non valide");
            $(this).val("");
            $(".custom-file-label").html("");
            return false;
        } else if (e.target.files[0].size && e.target.files[0].size > 2097152) {
            $(".errors-picture").html("L'image téléchargée ne doit pas dépasser 2MO");
            $(this).val("");
            $(".custom-file-label").html("");
            return false;
        } else {
            $(".errors-picture").html("");
            if (e.target.files.length) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    if (e.target.result) {
                        let img = document.createElement("img");
                        img.id = "image";
                        img.src = e.target.result;
                        result.innerHTML = "";
                        result.appendChild(img);
                        cropper = new Cropper(img, {
                            aspectRatio: 1,
                            fillColor: "#ffffff",
                        });
                    }
                };
                reader.readAsDataURL(e.target.files[0]);
            }
        }
    });

    // save on click
    let fileLogoCompany;
    $(document).on("click", ".js-submit-crop", function (e) {
        e.preventDefault();
        let val = $(".addPicture").val();
        if (val.length <= 0) {
            $(".errors-picture").html(translation("Format de fichier non valide"));
            return false;
        }
        $(".loader-popin").fadeIn(200);
        $(".popin").css("overflow", "hidden");

        const changeDynamicallyImageLoaded = (divWhereInject, image) => {
            $(".js-avatarEmpty").addClass("d-none");
            let img = document.createElement("img");
            img.src = window.URL.createObjectURL(image);
            img.className = "avatar round-avatar mt-3 st-height-80px st-width-80px";
            img.onload = function () {
                window.URL.revokeObjectURL(this.src);
            };
            $(divWhereInject).children().remove();
            $(divWhereInject).append(img);
        };
        // get result to data uri
        // Upload cropped image to server if the browser supports `HTMLCanvasElement.toBlob`.
        // The default value for the second parameter of `toBlob` is 'image/png', change it if necessary.
        if (!HTMLCanvasElement.prototype.toBlob) {
            Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
                value: function (callback, type, quality) {
                    var canvas = this;
                    setTimeout(function () {
                        var binStr = atob(canvas.toDataURL(type, quality).split(",")[1]),
                            len = binStr.length,
                            arr = new Uint8Array(len);

                        for (var i = 0; i < len; i++) {
                            arr[i] = binStr.charCodeAt(i);
                        }

                        callback(new Blob([arr], { type: type || "image/png" }));
                    });
                },
            });
        }
        if (cropper.getCroppedCanvas()) {
            cropper.getCroppedCanvas().toBlob(
                (blob) => {
                    const formData = new FormData();
                    // Pass the image file name as the third parameter if necessary.
                    formData.append("addPicture", blob /*, 'example.png' */);
                    let route = $(this).data("route");
                    let personalInfosForm = $(".js-firstFormInfoParticular").length;
                    let companyInfosForm = $(".js-firstFormInfoCompany").length;
                    if ($(e.target).attr("data-projectid")) formData.append("projectId", $(e.target).attr("data-projectid"));
                    formData.append("old", true);
                    fileLogoCompany = $(".addPicture").prop("files")[0];
                    if (companyInfosForm) {
                        hidePopin();
                        changeDynamicallyImageLoaded(".js-logoCompany", fileLogoCompany);
                    } else {
                        $.ajax(route, {
                            method: "POST",
                            data: formData,
                            processData: false,
                            contentType: false,
                            success(val) {
                                let v = JSON.parse(val);
                                if (v.status === "success") {
                                    if (personalInfosForm) {
                                        hidePopin();
                                        changeDynamicallyImageLoaded(".js-avatar", fileLogoCompany);
                                    } else {
                                        location.reload();
                                    }
                                } else if (v.status === "failed") {
                                    if (v.message) {
                                        hidePopin();
                                        $(".msg-flash .alert").appendChild('<div class="container alert alert-success" role="alert">' + v.message + "</div>");
                                        setTimeout(function () {
                                            window.location.reload();
                                        }, 6000);
                                    } else {
                                        location.reload();
                                    }
                                } else {
                                    location.reload();
                                }
                            },
                            error() {
                                hidePopin();
                                $(".msg-flash .alert").appendChild('<div class="container alert alert-success" role="alert">' + translation("Votre photo semble incorrecte") + "</div>");
                                setTimeout(function () {
                                    window.location.reload();
                                }, 6000);
                            },
                        });
                    }
                },
                "image/jpeg",
                "20/100"
            );
        } else {
            hidePopin();
            $(".msg-flash").append('<div class="container alert alert-success" role="alert">' + translation("Votre photo semble incorrecte") + "</div>");
            setTimeout(function () {
                window.location.reload();
            }, 6000);
        }
    });

    $(document).on("click", ".close-activation *", function (e) {
        e.stopImmediatePropagation();
    });

    //
    $(document).on("change", "#company_infos_isLR", function (e) {
        if ($("#company_infos_isLR").is(":checked")) {
            let userFirstName = $(".userfirstname").data("firstname");
            let userLastName = $(".userlastname").data("lastname");
            let userCountry = $(".usercountry").data("country");
            let userNationality = $(".usernationality").data("nationality");
            let userEmail = $(".useremail").data("email");
            let userBirthday = $(".userbirthday").data("birthday");
            let splitUserBirthDay = userBirthday.split("/");
            let userAddr = $(".useraddr").data("addr");
            let userCity = $(".usercity").data("city");
            let userState = $(".userstate").data("state");
            let userPostalCode = $(".userpostalcode").data("postalcode");

            if (userFirstName) $("#company_infos_thLRFirstname").val(userFirstName);
            if (userLastName) $("#company_infos_thLRLastname").val(userLastName);
            if (userCountry) $("#company_infos_thLRAdress_thCountry").val(userCountry);
            if (userNationality) $("#company_infos_thLRNationality").val(userNationality);
            if (userEmail) $("#company_infos_thLREmail").val(userEmail);
            if (userBirthday) $("#company_infos_thLRBirthday_day").val(splitUserBirthDay[0]);
            if (userBirthday) $("#company_infos_thLRBirthday_month").val(splitUserBirthDay[1]);
            if (userBirthday) $("#company_infos_thLRBirthday_year").val(splitUserBirthDay[2]);
            if (userAddr) $("#company_infos_thLRAddress_thAddress").val(userAddr);
            if (userCity) $("#company_infos_thLRAddress_thCity").val(userCity);
            if (userState) $("#company_infos_thLRAddress_thState").val(userState);
            if (userPostalCode) $("#company_infos_thLRAddress_thPostalCode").val(userPostalCode);
        } else {
            $("#company_infos_thLRFirstname").val("");
            $("#company_infos_thLRLastname").val("");
            $("#company_infos_thLRAdress_thCountry").val("");
            $("#company_infos_thLRNationality").val("");
            $("#company_infos_thLREmail").val("");
            $("#company_infos_thLRBirthday_day").val("");
            $("#company_infos_thLRBirthday_month").val("");
            $("#company_infos_thLRBirthday_year").val("");
            $("#company_infos_thLRAddress_thAddress").val("");
            $("#company_infos_thLRAddress_thCity").val("");
            $("#company_infos_thLRAddress_thState").val("");
            $("#company_infos_thLRAddress_thPostalCode").val("");
        }
    });

    $(document).on("click", ".open-menu-dots", function (e) {
        e.stopImmediatePropagation();
        $(".menu-dots").hide();
        $(this).next().show();
    });

    $(document).on("click", ".menu-dots", function (e) {
        $(".menu-dots").hide();
        e.stopImmediatePropagation();
    });

    $(document).on("click", "body", function () {
        if ($(".menu-dots").length) $(".menu-dots").hide();
    });

    $(document).on("click", ".project-acceptance", function (e) {
        e.stopImmediatePropagation();
    });

    $(document).on("click", ".project-acceptance .btn", function (e) {
        e.stopImmediatePropagation();
    });

    //Show datepicker on modif payment date
    $("#calendar-payment").datepicker({
        inline: true,
        altField: "#input-payment",
        firstDay: 1,
        prevText: "Précédent",
        nextText: "Suivant",
        currentText: "Aujourd'hui",
        monthNames: [translation("Janvier"), translation("Février"), translation("Mars"), translation("Avril"), translation("Mai"), translation("Juin"), translation("Juillet"), translation("Août"), translation("Septembre"), translation("Octobre"), translation("Novembre"), translation("Décembre")],
        monthNamesShort: ["Janv.", "Févr.", "Mars", "Avril", "Mai", "Juin", "Juil.", "Août", "Sept.", "Oct.", "Nov.", "Déc."],
        dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
        dayNamesShort: ["Dim.", "Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam."],
        dayNamesMin: [translation("Dim"), translation("Lun"), translation("Mar"), translation("Mer"), translation("Jeu"), translation("Ven"), translation("Sam")],
        weekHeader: "Sem.",
        dateFormat: "dd-mm-yy",
        beforeShowDay: function (date) {
            let selectDate = $("#calendar-payment").data("date");
            let d = new Date(selectDate);
            d.setHours(0, 0, 0, 0);
            if (date.getTime() === d.getTime()) {
                return [true, "highlight-selectdate"];
            } else {
                return [true, ""];
            }
        },
    });

    function ellipsizeTextBox(id) {
        var el = document.getElementById(id);
        var wordArray = el.innerHTML.split(" ");
        while (el.scrollHeight > el.offsetHeight) {
            wordArray.pop();
            el.innerHTML = wordArray.join(" ") + "...";
        }
    }
    if ($("#usernametrunc").length) ellipsizeTextBox("usernametrunc");

    /*Close menu responsive */
    $(document).on("click", ".js-close-menu", function () {
        $(".vertical-menu").removeClass("mobileshow");
    });

    $(document).on("click", ".search-form", function () {
        if ($(".search-input.show").length) {
            $(".search-input").removeClass("show");
        } else {
            $(".search-input").addClass("show");
            $(".search-input").select();
        }
        if ($(window).width() <= 767) {
            if ($(".inputsearchmobile").is(":visible")) {
                $(".inputsearchmobile").css("display", "none");
            } else {
                $(".inputsearchmobile").css("display", "block");
            }
        }
    });

    if ($(".dash-progressbar-info").length) {
        let width = $(".dash-progressbar-info").outerWidth();
        let widthGlobal = $(".dash-progressbar-inside").outerWidth();
        if (widthGlobal > 580) {
            $(".dash-progressbar-info").addClass("right");
        } else if (widthGlobal < 150) {
            $(".dash-progressbar-info").addClass("left");
        } else {
            width = width / 2;
            $(".dash-progressbar-info").css({ right: "calc(0% - " + width + "px)" });
        }
    }

    // A conserver ?

    // if ($('#change_infos_user_thPhoneNumber').length) {
    //   let tel2 = document.querySelector('#change_infos_user_thPhoneNumber');
    //   let iti2 = IntlTelInput(tel2,  {
    //     autoPlaceholder: 'aggressive',
    //     preferredCountries: ['FR'],
    //     initialDialCode: true,
    //   });
    //   $(document).on('blur', '#change_infos_user_thPhoneNumber', function() {
    //     let isValid = iti2.isValidNumber();
    //     let countryData = iti2.getSelectedCountryData().dialCode;
    //     if (isValid) {
    //       let itiNumber = iti2.getNumber(intlTelInputUtils.numberFormat.E164);
    //       $(this).val(itiNumber);
    //       if ($('.errorphonenumber').length)
    //         $('.errorphonenumber').remove();
    //     } else {
    //       if (!$('.errorphonenumber').length)
    //         $(this).parent().after('<div class="cerror errorphonenumber">Votre numéro ne semble pas être au bon format</div>');
    //     }
    //   });
    // }

    const addFlagOnInputTypeTel = (Input) => {
        if ($(Input).length) {
            let tel2 = document.querySelector(Input);
            let iti2 = IntlTelInput(tel2, {
                autoPlaceholder: "aggressive",
                preferredCountries: ["FR"],
                initialDialCode: true,
            });
            let isValid = iti2.isValidNumber();
            if (isValid) {
                let itiNumber = iti2.getNumber(intlTelInputUtils.numberFormat.E164);
                $(Input).val(itiNumber);
            }
            $(document).on("input", Input, function () {
                let isValid = iti2.isValidNumber();
                let countryData = iti2.getSelectedCountryData().dialCode;
                if (isValid) {
                    let itiNumber = iti2.getNumber(intlTelInputUtils.numberFormat.E164);
                    $(this).val(itiNumber);
                    if ($(".errorphonenumber").length) {
                        $(".errorphonenumber").remove();
                    }
                } else {
                    if (!$(".errorphonenumber").length) {
                        $(this)
                            .parent()
                            .after('<div class="cerror errorphonenumber">' + translation("Votre numéro ne semble pas être au bon format") + "</div>");
                    }
                }
            });
        }
    };

    addFlagOnInputTypeTel("#userPhonenumber");
    addFlagOnInputTypeTel("#change_infos_thPhoneNumber");
    addFlagOnInputTypeTel("#add_infos_phoneNumber");
    addFlagOnInputTypeTel("#add_phonenumber");
    addFlagOnInputTypeTel("#phonenumber");

    $(document).on("change", "#obscheckpayment", function () {
        let isChecked = $(this).is(":checked");
        let idProject = $(".idProjectObs").data("projectid");
        let url = $(".obs-share-url").val();
        let promiseReturn = $.post("/" + currentuser + "/projet/" + idProject + "/ajout_paiement_observateur", { isChecked: isChecked, url: url });

        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    $(document).on("change", "#obscheckpayment2", function () {
        let isChecked = $(this).is(":checked");
        let idProject = $(".idProjectObs").data("projectid");
        let url = $(".obs-share-url").val();
        let promiseReturn = $.post("/" + currentuser + "/projet/" + idProject + "/ajout_paiement_observateur", { isChecked: isChecked, url: url });

        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    $(document).on("click", ".bankselect", function (e) {
        let mandatestatus = $(this).data("mandatestatus");
        if (!$(".st-Bloc3xMulti").is(":visible") && mandatestatus === "ACTIVE") {
            // $('.payment-delayed-content').show();
            $(".st-infoPayLayer").hide();
            $(".js-show-payafter-label").show();
        } else {
            // $('.payment-delayed-content').hide();
            $(".st-infoPayLayer").show();
            $(".js-show-payafter-label").hide();
        }
    });

    $(document).on("click", ".js-wayselected", function (e) {
        $(".js-wayselected.selected").find(".js-checkMarkWaySelected").addClass("d-none");
        $(".js-wayselected.selected").removeClass("selected");
        $(this).addClass("selected");
        $(this).find(".js-checkMarkWaySelected").removeClass("d-none");
    });

    $(document).on("click", ".js-premiumOffer", function () {
        let promiseReturn = $.post("/freelance/contact_support");

        promiseReturn.then(function (val) {
            let v = JSON.parse(val);
            if (v.status === "success") {
                window.location.reload();
            } else {
                window.location.reload();
            }
        });
    });

    $(document).on("click", ".js-libertychoice", function () {
        // window.location.href = '/freelance/mon_profil/mon_abonnement/choix/LIBERTY';

        let promiseReturn = $.post("/freelance/mon_profil/mon_abonnement/choix/LIBERTY");

        promiseReturn.then(function (data) {
            if (data) {
                window.location.href = "/freelance/mon_profil/mon_offre";
            }
        });

        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    $(document).on("click", ".js-back", function () {
        window.history.back();
    });

    function getIp() {
        return $.getJSON("https://api.ipify.org/?format=json", function (ret) {
            return ret.ip;
        });
    }

    $(document).on("click", ".searchcross", function (e) {
        $(".search-input").val("").trigger("focus");
        e.stopPropagation();
    });

    $(document).on("change", ".js-change-role-invitation", function () {
        let invitationId = $(this).data("invitationid");
        let idProject = $(this).data("projectid");
        let val = $(this).val();
        let promiseReturn = $.post("/" + currentuser + "/projet/" + idProject + "/invitation/" + invitationId + "/changement_role", { role: val });
        promiseReturn.then(function (data) {
            if (data) {
                window.location.reload();
            }
        });
    });

    $(document).on("change", ".js-change-role", function () {
        let memberId = $(this).data("memberid");
        let idProject = $(this).data("projectid");
        let val = $(this).val();
        let promiseReturn = $.post("/" + currentuser + "/projet/" + idProject + "/gestion_membre/membre/" + memberId + "/changement_role", { role: val });
        promiseReturn.then(function (data) {
            if (data) {
                window.location.reload();
            }
        });
        promiseReturn.catch(function () {
            $(".loader").fadeOut("1000");
            window.location.reload();
        });
    });

    $(document).on("click", ".js-send-invitation", function () {
        let val = $(".js-invitation-val").val().trim();
        let idProject = $(".js-invitation-val").data("projectid");
        let promiseReturn = $.post("/freelance/projet/" + idProject + "/nouvelle_invitation", { invitationEmail: val });
        $(".loader").fadeIn("1000");
        promiseReturn.then(function (data) {
            if (data) {
                window.location.reload();
            } else {
                $(".loader").fadeOut("100");
            }
        });
        promiseReturn.catch(function () {
            $(".loader").fadeOut("1000");
            window.location.reload();
        });
    });

    $(document).on("click", ".js-password-show", function () {
        $(this).toggleClass("openeye");
        if ($(this).parent().children("input").attr("type") == "password") $(this).parent().children("input").attr("type", "text");
        else $(this).parent().children("input").attr("type", "password");
    });

    //select replace autocomplete
    $(document).on("change", ".autocomplete-select", function () {
        let id = $(this).val();
        if (id != "-1") {
            let optionSelected = $(this).find("option:selected");
            let role = optionSelected.data("role");
            let img = optionSelected.data("img");
            let imgQuoted = img.replace(/"/g, "'");
            let name = optionSelected.data("name");
            let parentContainer = $(this).parents(".autocomplete-container");
            let content = "";
            if ($(this).hasClass("select-info")) content = "<div class='proposal-container'><div class='proposal-info' data-id='" + id + "'><div class='proposal-content'><div class='proposal-img'>" + imgQuoted + "</div><div class='proposal-name'>" + name + "</div><div class='proposal-delete autocomplete-select-renew'></div></div><div class='proposal-role'>" + role + "</div></div></div>";
            else content = "<div class='proposal-container'><div class='proposal' data-id='" + id + "'><div class='proposal-content'><div class='proposal-img'>" + imgQuoted + "</div><div class='proposal-name'>" + name + "</div><div class='proposal-delete autocomplete-select-renew'></div></div><div class='proposal-role'>" + role + "</div></div></div>";
            let container = parentContainer.find(".autocomplete-result");
            container.append(content);
            optionSelected.remove();
        }
    });

    // A conserver ?

    // $(document).on('input', '.autocomplete-input', function() {
    //   let parentContainer = $(this).parents('.autocomplete-container');
    //   parentContainer.find(".autocomplete-content").removeClass('open');
    //   let val = $(this).val();
    //   let id = $(this).data('id');
    //     let promiseReturn = $.post("/freelance/projet/"+id+"/autocomplete_user", {search: val});
    //     promiseReturn.then(function(d) {
    //       parentContainer.find(".autocomplete-proposition").html("");
    //       parentContainer.find(".autocomplete-content").addClass('open');
    //       let data = JSON.parse(d);
    //       if (data.results == 0) {
    //         parentContainer.find(".autocomplete-proposition").html("<div class='proposal-info-notselectable'>Aucun membre ne correspond</div>");
    //       }
    //       else {
    //         let dataResults = data.results;
    //         let idAlreadySelected = [];
    //         parentContainer.find('.autocomplete-result .proposal').each(function(index, elm) {
    //           idAlreadySelected.push($(elm).data('id'));
    //         });
    //       dataResults.forEach(function(v) {
    //         if ($.inArray( v.id, idAlreadySelected ) == -1)
    //           if (typeof userShared != 'undefined')
    //           {
    //             if ($.inArray( v.id, userShared ) == -1)
    //               parentContainer.find(".autocomplete-proposition").append("<div class='proposal-container'><div class='proposal' data-id='"+v.id+"'><div class='proposal-content'><div class='proposal-img'>"+v.img+"</div><div class='proposal-name'>"+v.name+"</div><div class='proposal-delete'></div></div><div class='proposal-role'>"+v.role+"</div></div></div>");
    //           }
    //           else {
    //             parentContainer.find(".autocomplete-proposition").append("<div class='proposal-container'><div class='proposal' data-id='"+v.id+"'><div class='proposal-content'><div class='proposal-img'>"+v.img+"</div><div class='proposal-name'>"+v.name+"</div><div class='proposal-delete'></div></div><div class='proposal-role'>"+v.role+"</div></div></div>");
    //           }
    //       });
    //     }
    //     });
    // });

    $(document).on("click", ".proposal-container", function () {
        let parentContainer = $(this).parents(".autocomplete-container");
        parentContainer.find(".autocomplete-input").val("");
        parentContainer.find(".autocomplete-proposition").html("");
        parentContainer.find(".autocomplete-content").removeClass("open");
        let $this = $(this);
        parentContainer.find(".autocomplete-result").append($this);
    });

    $(document).on("click", ".autocomplete-result .proposal-container", function () {
        if ($(this).find(".proposal-delete").length > 0) {
            //Refill select
            let id = $(this).find(".proposal").data("id");
            let img = $(this).find(".proposal-img").html();
            let name = $(this).find(".proposal-name").html();
            let role = $(this).find(".proposal-role").html();
            let content = "<option value='" + id + "' data-img='" + img + "' data-name='" + name + "' data-role='" + role + "'>" + name + "</option>";
            $(this).parents(".autocomplete-container").find(".autocomplete-select").append(content);
            $(this).remove();
        }
    });

    $(document).on("click", ".autocomplete-result .proposal-container-info", function () {
        if ($(this).find(".proposal-delete").length > 0) {
            let id = $(this).find(".proposal-info").data("id");
            let img = $(this).find(".proposal-img").html();
            let name = $(this).find(".proposal-name").html();
            let role = $(this).find(".proposal-role").html();
            let content = "<option value='" + id + "' data-img='" + img + "' data-name='" + name + "' data-role='" + role + "'>" + name + "</option>";
            $(this).parents(".autocomplete-container").find(".autocomplete-select").append(content);
            $(this).remove();
        }
    });

    $(document).on("click", ".already-proposal .proposal-container-already", function () {
        let idProject = $(this).data("idproject");
        let idFolder = $(this).data("idfolder");
        let idDocument = $(this).data("iddocument");
        let elm = $(this);
        let id = $(this).find(".proposal").data("id");
        if ($(this).find(".proposal-delete").length > 0) {
            let promiseReturn = $.post("/freelance/projet/" + idProject + "/fichiers/dossier/" + idFolder + "/fichier/" + idDocument + "/departager_fichier", { sharedId: id });
            promiseReturn.then(function (val) {
                let r = JSON.parse(val);
                if (r.status === "failed") {
                    window.location.reload();
                } else {
                    let id = elm.find(".proposal").data("id");
                    let img = elm.find(".proposal-img").html();
                    let name = elm.find(".proposal-name").html();
                    let role = elm.find(".proposal-role").html();
                    let content = "<option value='" + id + "' data-img='" + img + "' data-name='" + name + "' data-role='" + role + "'>" + name + "</option>";
                    //Refill select
                    elm.parents(".autocomplete-container").find(".autocomplete-select").append(content);
                    elm.hide();
                    elm.remove();
                }
            });

            promiseReturn.catch(function (err) {
                window.location.reload();
            });
        }
    });

    // $('#dropzone').bind('dragover drop', function(event) {
    //   event.stopPropagation();
    //   event.preventDefault();
    //   if (event.type == 'drop') {
    //
    //   }
    // });

    $(document).on("click", ".close-addfiles", function () {
        $(".popin-content").show();
        $(".popin-content-second").hide();
        $(".popin-content-third").hide();
        $(".autocomplete-content").removeClass("open");
    });

    $(document).on("click", ".js-open-addfiles", function (e) {
        e.preventDefault();
        $(".backdrop").show();
        $(".backdrop").addClass("close-addfiles");
        $(".popin-addfiles").show();
        $(".popin").show();
    });

    $(document).on("click", ".submit-document-validation", function (e) {
        $(e.target).addClass("st-no-btn-disabled");
        let idProject = $(this).data("idproject");
        let idFolder = $(this).data("idfolder");
        let idDocument = $(this).data("iddocument");
        let parentContainer = $(".popin-validated-doc");
        let usersId = [];
        $(".popin-validated-doc .autocomplete-result .proposal-info").each(function (index, elm) {
            usersId.push($(elm).data("id"));
        });

        let promiseReturn = $.post("/freelance/projet/" + idProject + "/fichiers/dossier/" + idFolder + "/fichier/" + idDocument + "/demande_validation", { validators: usersId });
        promiseReturn.then(function (val) {
            window.location.reload();
        });

        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    $(document).on("click", ".submit-document-signed", function () {
        $(this).addClass("st-no-btn-disabled");
        let idProject = $(this).data("idproject");
        let idFolder = $(this).data("idfolder");
        let idDocument = $(this).data("iddocument");
        let parentContainer = $(".popin-signed-doc");
        let usersId = [];
        $(".popin-signed-doc .autocomplete-result .proposal-info").each(function (index, elm) {
            usersId.push($(elm).data("id"));
        });

        let promiseReturn = $.post("/freelance/projet/" + idProject + "/fichiers/dossier/" + idFolder + "/fichier/" + idDocument + "/demande_signature", { signers: usersId });
        promiseReturn.then(function (val) {
            let v = JSON.parse(val);
            if (v.status !== "ok") {
                $(this).removeClass("st-no-btn-disabled");
            }
            window.location.reload();
        });

        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    if ($(".payment-amount-sender")) {
        let amountSender = $(".calc-payment-sender").data("amount");
        $(".payment-amount-sender").html(amountSender + " €");
        let amountReceiver = $(".calc-payment-receiver").data("amount");
        $(".payment-amount-receiver").html(amountReceiver + " €");
    }

    $.extend($.expr[":"], {
        containsi: function (elem, i, match, array) {
            return (elem.textContent || elem.innerText || "").toLowerCase().indexOf((match[3] || "").toLowerCase()) >= 0;
        },
    });

    $(document).on("input", ".js-search-payment", function () {
        let val = $(this).val();

        if (val) {
            $(".payment-list-block:not(:containsi(" + val + "))").addClass("hideme");
            $(".payment-list-block:containsi(" + val + ")").removeClass("hideme");
        } else {
            $(".payment-list-block").removeClass("hideme");
        }
        let amountSender = 0;
        $(".payment-send-list .payment-list-block:visible").each(function () {
            let amount = $(this).find(".payment-amount-number").html().replace(" €", "").replace(/,/g, ".").replace(/ /g, "");
            amountSender = parseFloat(amountSender) + parseFloat(amount);
        });
        amountSender = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(amountSender);
        $(".payment-amount-sender").html(amountSender);

        let amountReceiver = 0;
        $(".payment-receive-list .payment-list-block:visible").each(function () {
            let amount = $(this).find(".payment-amount-number").html().replace(" €", "").replace(/,/g, ".").replace(/ /g, "");
            amountReceiver = parseFloat(amountReceiver) + parseFloat(amount);
        });
        amountReceiver = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(amountReceiver);
        $(".payment-amount-receiver").html(amountReceiver);
    });

    $(document).on("change", ".js-user-payment", function () {
        let userId = $(this).val();
        if (userId) {
            let url = window.location.href;
            if (url.indexOf("?") > -1) {
                let newurl = new URL(url);
                newurl.searchParams.set("userPayment", userId);
                window.location.href = newurl.toString();
            } else {
                url += "?userPayment=" + userId;
                window.location.href = url;
            }
        } else {
            window.location.href = window.location.protocol + "//" + window.location.host + window.location.pathname;
        }
    });

    $(".js-invitation-val").keypress(function (e) {
        if (e.which == 13) {
            $(".js-send-invitation").trigger("click");
            return false;
        }
    });

    // A conserver ?

    // $(document).on('input', '.autocomplete-input-info', function() {
    //   let validateAutoComplete = $(this).parents('.document-validation');
    //   let parentContainer = $(this).parents('.autocomplete-container');
    //   parentContainer.find(".autocomplete-content").removeClass('open');
    //   let val = $(this).val();
    //   let id = $(this).data('id');
    //     let promiseReturn = $.post("/freelance/projet/"+id+"/autocomplete_user", {search: val, withHimSelf: 1});
    //     promiseReturn.then(function(d) {
    //       parentContainer.find(".autocomplete-proposition").html("");
    //       parentContainer.find(".autocomplete-content").addClass('open');
    //       let data = JSON.parse(d);
    //       if (data.results == 0) {
    //         parentContainer.find(".autocomplete-proposition").html("<div class='proposal-info-notselectable'>Aucun membre ne correspond</div>");
    //       }
    //       else {
    //         let dataResults = data.results;
    //         let idAlreadySelected = [];
    //         parentContainer.find('.autocomplete-result .proposal-info').each(function(index, elm) {
    //           idAlreadySelected.push($(elm).data('id'));
    //         });
    //       dataResults.forEach(function(v) {
    //         if ($.inArray( v.id, idAlreadySelected ) == -1) {
    //            if (v.infosOk || validateAutoComplete.length > 0) {
    //             parentContainer.find(".autocomplete-proposition").append("<div class='proposal-container-info'><div class='proposal-info' data-id='"+v.id+"'><div class='proposal-content'><div class='proposal-img'>"+v.img+"</div><div class='proposal-name'>"+v.name+"</div><div class='proposal-delete'></div></div><div class='proposal-role'>"+v.role+"</div></div></div>");
    //            }
    //            else {
    //             parentContainer.find(".autocomplete-proposition").append("<div class='proposal-container-info-notselectable'><div class='proposal-info-notselectable' data-id='"+v.id+"'><div class='notselectable'><div class='proposal-img'>"+v.img+"</div><div class='proposal-name'>"+v.name+"</div><div class='proposal-delete'></div></div><div class='noinfo'>Cet utilisateur n'a pas complété son profil</div></div></div>");
    //            }
    //         }
    //         });
    //     }
    //     });
    // });

    $(document).on("click", ".proposal-container-info", function () {
        let parentContainer = $(this).parents(".autocomplete-container");
        parentContainer.find(".autocomplete-input").val("");
        parentContainer.find(".autocomplete-proposition").html("");
        parentContainer.find(".autocomplete-content").removeClass("open");
        let $this = $(this);
        parentContainer.find(".autocomplete-result").append($this);
    });

    $(document).on("change", ".js-parse-payment", function () {
        let option = $(this).val();
        if (option == "amount-desc") {
            var result = $(".payment-receive-list .payment-list-block").sort(function (a, b) {
                var contentA = parseInt($(a).data("amount"));
                var contentB = parseInt($(b).data("amount"));
                return contentA < contentB ? -1 : contentA > contentB ? 1 : 0;
            });
            if (result.length > 0) {
                $(".payment-receive-list").html(result);
            }

            var result = $(".payment-send-list .payment-list-block").sort(function (a, b) {
                var contentA = parseInt($(a).data("amount"));
                var contentB = parseInt($(b).data("amount"));
                return contentA < contentB ? -1 : contentA > contentB ? 1 : 0;
            });
            if (result.length > 0) {
                $(".payment-send-list").html(result);
            }
        } else if (option == "amount-asc") {
            var result = $(".payment-receive-list .payment-list-block").sort(function (a, b) {
                var contentA = parseInt($(a).data("amount"));
                var contentB = parseInt($(b).data("amount"));
                return contentA > contentB ? -1 : contentA < contentB ? 1 : 0;
            });
            if (result.length > 0) {
                $(".payment-receive-list").html(result);
            }

            var result = $(".payment-send-list .payment-list-block").sort(function (a, b) {
                var contentA = parseInt($(a).data("amount"));
                var contentB = parseInt($(b).data("amount"));
                return contentA > contentB ? -1 : contentA < contentB ? 1 : 0;
            });
            if (result.length > 0) {
                $(".payment-send-list").html(result);
            }
        } else {
            var result = $(".payment-receive-list .payment-list-block").sort(function (a, b) {
                var contentA = parseInt($(a).data("date"));
                var contentB = parseInt($(b).data("date"));
                return contentA < contentB ? -1 : contentA > contentB ? 1 : 0;
            });
            if (result.length > 0) {
                $(".payment-receive-list").html(result);
            }

            var result = $(".payment-send-list .payment-list-block").sort(function (a, b) {
                var contentA = parseInt($(a).data("date"));
                var contentB = parseInt($(b).data("date"));
                return contentA < contentB ? -1 : contentA > contentB ? 1 : 0;
            });
            if (result.length > 0) {
                $(".payment-send-list").html(result);
            }
        }
    });

    $(document).on("click", ".submit-document-validatedsigned", function () {
        let idProject = $(this).data("idproject");
        let idFolder = $(this).data("idfolder");
        let idDocument = $(this).data("iddocument");
        let parentContainer = $(".popin-validatedsigned .document-signed .autocomplete-container");
        let usersSignId = [];
        parentContainer.find(".autocomplete-result .proposal-info").each(function (index, elm) {
            usersSignId.push($(elm).data("id"));
        });
        let parentContainerValid = $(".popin-validatedsigned .document-validation .autocomplete-container");
        let usersValidId = [];
        parentContainerValid.find(".autocomplete-result .proposal-info").each(function (index, elm) {
            usersValidId.push($(elm).data("id"));
        });

        let promiseReturn = $.post("/freelance/projet/" + idProject + "/fichiers/dossier/" + idFolder + "/fichier/" + idDocument + "/demande_signature_validation", { signers: usersSignId, validators: usersValidId });
        promiseReturn.then(function (val) {
            let v = JSON.parse(val);
            window.location.reload();
        });

        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    $(document).on("click", ".submit-document-secondpopin", function () {
        $(".first-popin").hide();
        $(".second-popin").show();
        $(".backdrop").addClass("hide-second-popin");
    });

    $(document).on("click", ".hide-second-popin", function () {
        $(".first-popin").show();
        $(".second-popin").hide();
        $(".backdrop").removeClass("hide-second-popin");
    });

    $(document).on("click", ".js-firstpopin", function () {
        $(".first-popin").show();
        $(".second-popin").hide();
        $(".backdrop").removeClass("hide-second-popin");
    });

    $(document).on("change", ".js-filter-file", function () {
        let option = $(this).val();
        if (option == "title") {
            var result = $(".container-bottom-line .doc-container").sort(function (a, b) {
                var contentA = $(a).data("title").toUpperCase();
                var contentB = $(b).data("title").toUpperCase();
                return contentA < contentB ? -1 : contentA > contentB ? 1 : 0;
            });

            $(".container-bottom-line").html(result);
        } else if (option == "addby") {
            var result = $(".container-bottom-line .doc-container").sort(function (a, b) {
                var contentA = $(a).data("addby").toUpperCase();
                var contentB = $(b).data("addby").toUpperCase();
                return contentA < contentB ? -1 : contentA > contentB ? 1 : 0;
            });

            $(".container-bottom-line").html(result);
        } else {
            var result = $(".container-bottom-line .doc-container").sort(function (a, b) {
                var contentA = $(a).data("statut").toUpperCase();
                var contentB = $(b).data("statut").toUpperCase();
                return contentA < contentB ? -1 : contentA > contentB ? 1 : 0;
            });

            $(".container-bottom-line").html(result);
        }
    });

    $(document).on("click", ".payment-list-receiver", function () {
        $(".payment-list-receiver").removeClass("notselected");
        $(".payment-list-sender").addClass("notselected");
        $(".payment-receive-list").show();
        $(".payment-send-list").hide();
    });

    $(document).on("click", ".payment-list-sender", function () {
        $(".payment-list-receiver").addClass("notselected");
        $(".payment-list-sender").removeClass("notselected");
        $(".payment-send-list").show();
        $(".payment-receive-list").hide();
    });

    //Show payment form
    $(document).on("click", ".js-open-new-paymentform", function () {
        $(".payment-choice-container").hide();
        $(".new-payment-form").show();
    });

    //gestion des step
    $(document).on("click", ".js-open-create-bills", function () {
        $(".payment-choice-container").hide();
        $(".new-payment-createbills").show();
    });

    //on change value calc
    $(document).on("input", ".elm-unit, .elm-ht, .elm-tva", function () {
        let unit = $(".elm-unit").val() || null;
        let ht = $(".elm-ht").val() || null;
        let tva = $(".elm-tva").val() || null;
        if (!unit) {
            unit = 1;
        }
        if (unit && ht) {
            let rsltHt = parseFloat(unit.replace(",", ".")) * parseFloat(ht.replace(",", "."));
            let rsltHtFormat = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(rsltHt);
            $(".totalht").text(rsltHtFormat);
            if (tva) {
                let rsltTva = (rsltHt * parseFloat(tva.replace(",", "."))) / 100;
                rsltTva += rsltHt;
                let rsltTvaFormat = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(rsltTva);
                $(".totalttc").text(rsltTvaFormat);
                $(".elmerrormaxamount").remove();
                $(".errors-popin").hide();
            } else {
                $(".totalttc").text("");
                $(".elmerrormaxamount").remove();
                $(".errors-popin").hide();
            }
        } else {
            $(".totalht").text("");
            $(".totalttc").text("");
            $(".elmerrormaxamount").remove();
            $(".errors-popin").hide();
        }
    });

    //Add element to bills
    $(document).on("click", ".js-add-billselement", function () {
        $(".errors-popin").html("");
        let title = $(".elm-title").val();
        let desc = $(".elm-desc").val();
        let unit = $(".elm-unit").val();
        let ht = $(".elm-ht").val();
        let tva = $(".elm-tva").val();
        let rslt = $(".totalttc").text();
        if (title && unit && ht && tva) {
            let idpopin = $(".popinelement-id").data("id");
            if (idpopin) {
                $(".idpopin-" + idpopin)
                    .find(".billselm-title")
                    .html(title);
                $(".idpopin-" + idpopin)
                    .find(".billselm-desc")
                    .html(desc);
                $(".idpopin-" + idpopin)
                    .find(".billselm-unit")
                    .html(unit);
                $(".idpopin-" + idpopin)
                    .find(".billselm-ht")
                    .html(ht);
                $(".idpopin-" + idpopin)
                    .find(".billselm-tva")
                    .html(tva);
                $(".idpopin-" + idpopin)
                    .find(".billselm-ttc")
                    .html(rslt);
            } else {
                let id = Math.floor(Math.random() * (100000000000 - 1)) + 1;
                let elm =
                    "<div class='js-createbills-elements idpopin-" +
                    id +
                    "'>" +
                    "<div class='d-flex justify-content-between'><div class='billselm-title'>" +
                    title +
                    "</div><div class='d-flex'><div class='js-edit-billselm' data-id='" +
                    id +
                    "'><img src='/build/images/icon_edit.svg' /></div><div class='js-delete-billselm'><img src='/build/images/icon_cross_blue.svg' /></div></div></div>" +
                    "<div class='billselm-desc'>" +
                    desc +
                    "</div>" +
                    "<div class='d-flex justify-content-between'><div><span class='billselm-unit'>" +
                    unit +
                    "</span><span class='ml-2 mr-2'>x</span><span class='billselm-ht'>" +
                    ht +
                    "</span><span class='ml-2 mr-2'>+</span>TVA (<span class='billselm-tva'>" +
                    tva +
                    "</span>%)</div><div class='font-weight-bold billselm-ttc'>" +
                    rslt +
                    "</div></div>" +
                    "</div>";
                if ($(".element-realise-container i").length) $(".element-realise-container *").remove();
                $(".element-realise-container").append(elm);
            }
            $("input.error").removeClass("error");
            $(".elmerror").remove();
            $(".popinelement-id").data("id", "");
            $(".popin-add-element").find("input").val("");
            $(".totalht").html("");
            $(".totalttc").html("");
            $(".backdrop").hide();
            $(".popin-wrap").hide();
            $(".popin").hide();
            $(".errors").hide();
            $(".errors").removeClass("active");
        }
    });

    $(document).on("click", ".popin-close-element", function () {
        $("input.error").removeClass("error");
        $(".popinelement-id").data("id", "");
        $(".cerror.elmerror").remove();
        $(".backdrop").hide();
        $(".backdrop-fix").hide();
        $(".backdropstepstart").hide();
        $(".popin").hide();
        $(".popin-wrap").hide();
        $(".popin-errors").hide();
        $(".popin-error").remove();
    });

    $(document).on("click", ".js-reset-elementpopin", function () {
        $(".popinelement-id").data("id", "");
        $(".popin-add-element").find("input").val("");
        $("input.error").removeClass("error");
        $(".cerror.elmerror").remove();
        $(".totalht").html("");
        $(".totalttc").html("");
        $(".backdrop").hide();
        $(".popin-wrap").hide();
        $(".popin").hide();
    });

    //delete element bills
    $(document).on("click", ".js-delete-billselm", function () {
        $(this).parents(".js-createbills-elements").remove();
        if ($(".element-realise-container").children().length <= 0) {
            let elm = "<i>Exemple : «&nbsp;Design d’un site&nbsp;» ou «&nbsp;Rédaction d’un article&nbsp;». Un élément correspond à une ligne de votre facture.</i><input type='text' required='required' data-field='Ajout d’un élément réalisé' disabled='disabled' class='hiddeninput'>";
            $(".element-realise-container").html(elm);
        }
    });

    //edit element bills
    $(document).on("click", ".js-edit-billselm", function () {
        let parents = $(this).parents(".js-createbills-elements");
        let title = parents.find(".billselm-title").html();
        let desc = parents.find(".billselm-desc").html();
        let unit = parents.find(".billselm-unit").html();
        let price = parents.find(".billselm-ht").html();
        let tva = parents.find(".billselm-tva").html();
        let ht = price;
        let ttc = parents.find(".billselm-ttc").html();
        let id = $(this).data("id");
        $(".popinelement-id").data("id", id);

        $(".elm-title").val(title);
        $(".elm-desc").val(desc);
        $(".elm-unit").val(unit);
        $(".elm-ht").val(price);
        $(".elm-tva").val(tva);
        $(".totalht").html(ht);
        $(".totalttc").html(ttc);
        $(".popin-wrap.popin-add-element").show();
        $(".backdrop").show();
        $(".popin").show();
    });

    $(document).on("click", ".js-edit-billselm-payment", function () {
        let parents = $(this).closest(".js-createbills-elements");
        let title = parents.find(".billselm-title").html();
        let date = parents.find(".billselm-date").html();
        let desc = parents.find(".billselm-desc").html();
        let unit = parents.find(".billselm-unit").html();
        let price = parents.find(".billselm-ht").html();
        let tva = parents.find(".billselm-tva").html();
        let rsltHt = parseFloat(unit.replace(",", ".")) * parseFloat(price.replace(",", "."));
        let rsltHtFormat = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(rsltHt);
        let ttc = parents.find(".billselm-ttc").html();
        let id = $(this).data("id");
        $(".popinelement-id").data("id", id);
        price = price.replace(",", ".");
        price = Math.round(price * 100) / 100;
        $(".add-elm").slideDown();
        $(".bills-elm-title").val(title);
        $(".bills-elm-daterea").val(date);
        $(".bills-elm-desc").val(desc);
        $(".bills-elm-unit").val(unit);
        $(".bills-elm-ht").val(price);
        $(".bills-elm-tva").val(tva);
        $(".elm-total-ht").text(rsltHtFormat);
        $(".elm-total-ttc").html(ttc);
        $(".js-btn-add-elm").html(translation("Modifier"));
    });

    //on select user
    $(document).on("input", ".js-createbills-user", function () {
        let companyName = $(".js-createbills-user option:selected").data("company-name");
        let companyAddr = $(".js-createbills-user option:selected").data("company-addr");
        $(".js-createbills-companyname").val(companyName);
        $(".js-createbills-companyaddress").val(companyAddr);
    });

    //get recp bills by Soan
    $(document).on("click", ".js-create-billsrecap", function () {
        var requiredStep = [];
        requiredStep.push(document.querySelectorAll("input:invalid"));
        requiredStep.push(document.querySelectorAll("textarea:invalid"));
        requiredStep.push(document.querySelectorAll(".hiddeninput"));
        var tabrequired = [];
        var test = $.each(requiredStep, function (key, value) {
            $.each(value, function (k, val) {
                if ($(val).is(":visible")) tabrequired.push(val);
            });
        });
        let that = $(this);
        if (tabrequired.length === 0) {
            let loader = '<div class="loader"><div class="loader-inside"><img src="/build/images/loading.svg" height="200" alt="loading"></div></div>';
            $(".recap").html(loader);
            let idProject = that.data("idproject");
            let billsSubject = $(".js-createbills-sujet").val();
            let billsDesc = $(".js-createbills-description").val();
            let billsCompanyName = $(".js-createbills-companyname").val();
            let billsCompanyAddress = $(".js-createbills-companyaddress").val();
            let billsElm = [];
            $(".js-createbills-elements").each(function (idx, elm) {
                let billsElmTitle = $(elm).find(".billselm-title").text();
                let billsElmDate = $(elm).find(".billselm-date").text();
                let billsElmDesc = $(elm).find(".billselm-desc").html();
                let billsElmUnit = $(elm).find(".billselm-unit").text().replace(",", ".");
                let billsElmHt = $(elm).find(".billselm-ht").text().replace(",", ".");
                let billsElmTva = $(elm).find(".billselm-tva").text().replace(",", ".");

                billsElm.push({ title: billsElmTitle, date: billsElmDate, desc: billsElmDesc, unit: billsElmUnit, price: billsElmHt, tva: billsElmTva });
            });
            let billsDate = formatDateSeparators($(".js-createbills-datesend").val());
            let billsDelay = $(".js-createbills-delay").val();
            let billsPenality = $(".js-createbills-penality").val();
            let billsDamage = $(".js-createbills-damage").val();
            let billsNumber = $(".js-createbills-numberbills").val();
            let discountRate = $(".js-createbills-escompte").val().replace(",", ".");
            let discountDaysLimit = $(".js-createbills-validity").val().replace(",", ".");
            let legalNotice = $(".mentions-elm").html();
            let isDiscountable = $(".js-show-escompte").is(":checked");
            let promiseReturn = $.post("/freelance/projet/" + idProject + "/efacture/nouvelle_facture/creation/recap", {
                companyName: billsCompanyName,
                companyAddress: billsCompanyAddress,
                billsSubject: billsSubject,
                billsDesc: billsDesc,
                billsElm: billsElm,
                billsDate: billsDate,
                billsDelay: billsDelay,
                billsPenality: billsPenality,
                billsDamage: billsDamage,
                billsNumber: billsNumber,
                discountRate: discountRate,
                discountDaysLimit: discountDaysLimit,
                legalNotice: legalNotice,
                isDiscountable: isDiscountable,
            });
            promiseReturn.then(function (val) {
                $(".recap").html("");
                let v = JSON.parse(val);
                if (v.status === "failed") {
                    $(".errors").html(v.message);
                    $(".errors").show();
                } else if (v.status === "success") {
                    let preview = '<div class="document-image"> <a target="_blank" href="' + v.pdf + '"><img src="' + v.preview + '"/></a></div>';
                    $(".recap").append(preview);
                    if ($(".btn-download").length) {
                        if (!$(".dl-link-appenned").length) $(".btn-download").append('<a target="_blank" class="cwhite dl-link-appenned" href="' + v.pdf + '" download>' + translation("Télécharger") + "</a>");
                    }
                    let idEfacture = v.efactureId;
                    $(".js-save-efacture").attr("data-idefacture", idEfacture);
                    $(".js-save-before-next").attr("data-idefacture", idEfacture);
                    $(".js-show-laststep").attr("data-idefacture", idEfacture);
                    $(".js-send-efacture").attr("data-idefacture", idEfacture);

                    if (that.hasClass("ignorestep")) {
                        $(":input:visible").val("");
                    }
                    that.parents(".active").next().addClass("active");
                    that.parents(".active").removeClass("active");
                    $(".errors").html("").removeClass("active");
                    $(".errors").hide();
                } else {
                    window.location.reload();
                }
            });

            promiseReturn.catch(function (err) {
                window.location.reload();
            });
        } else {
            $(".errors").show();
            $(".errors").html(translation("Veuillez remplir les champs") + " ");
            $.each(tabrequired, function (key, val) {
                $(".errors")
                    .addClass("active")
                    .append("<div class='error'>" + val.dataset.field + "</div>");
            });
            $(".soancontainer").animate({ scrollTop: $(".errors").offset().top }, 1000);
        }
    });

    //Save bills by Soan
    $(document).on("click", ".js-save-efacture", function () {
        if ($(".errors").children().length > 0) {
            $(".errors").append('<div class="">Veuillez corriger les erreurs pour sauvegarder votre efacture</div>');
        } else {
            let idEfacture = $(this).data("idefacture");
            let idProject = $(this).data("idproject");
            let promiseReturn = $.post("/freelance/projet/" + idProject + "/efacture/" + idEfacture + "/enregistrer");
            promiseReturn.then(function (val) {
                let v = JSON.parse(val);
                if (v.status === "failed") {
                    $(".errors").html(v.message);
                    $(".errors").show();
                } else {
                    window.location.href = v.url;
                }
            });

            promiseReturn.catch(function (err) {
                window.location.reload();
            });
        }
    });

    //Send create bill to client
    $(document).on("click", ".js-send-billstoclient", function () {
        let delayedInvoice = null;
        let errors = 0;
        if ($(".js-show-sendlater").is(":checked")) {
            let sendLater = formatDateSeparators($(".js-date-sendlater").val());
            const dateObjectSendLater = new Date(parseInt(sendLater.split("-")[2]), parseInt(sendLater.split("-")[1]) - 1, parseInt(sendLater.split("-")[0]));
            if (dateObjectSendLater <= new Date()) {
                if (!$(".error-sendlater").length) {
                    $(".errors").append('<div class="error-sendlater">' + translation("date ultérieure") + "</div>");
                    $(".errors").show();
                }
                errors = 1;
            } else {
                delayedInvoice = formatDateSeparators($(".js-date-sendlater").val());
                errors = 0;
            }
        }

        if ($(".errors").children().length > 0 || errors == 1) {
            $(".errors").append('<div class="">Veuillez corriger les erreurs pour envoyer votre efacture</div>');
        } else {
            let projectId = $(this).data("idproject");
            let efactureId = $(".js-save-efacture").data("idefacture");
            let billsUser = $(".js-createbills-user").val();
            let promiseReturn = $.post("/freelance/projet/" + projectId + "/nouveau_paiement/efacture/" + efactureId, { sender: billsUser, delayedInvoice: delayedInvoice });
            promiseReturn.then(function (val) {
                let v = JSON.parse(val);
                if (v.status === "failed") {
                    $(".errors").html(v.message);
                    $(".errors").show();
                } else {
                    window.location.href = v.url;
                }
            });

            promiseReturn.catch(function (err) {
                window.location.reload();
            });
        }
    });

    //send create bills by Soan
    $(document).on("click", ".js-send-createbills", function () {
        let idProject = $(this).data("idproject");
        let billsUser = $(".js-createbills-user").val();
        let billsSubject = $(".js-createbills-sujet").val();
        let billsDesc = $(".js-createbills-description").val();
        let billsCompanyName = $(".js-createbills-companyname").val();
        let billsCompanyAddress = $(".js-createbills-companyaddress").val();
        let billsElm = [];
        $(".js-createbills-elements").each(function (idx, elm) {
            let billsElmTitle = $(elm).find(".billselm-title").text();
            let billsElmDate = $(elm).find(".billselm-date").text();
            let billsElmDesc = $(elm).find(".billselm-desc").text();
            let billsElmUnit = $(elm).find(".billselm-unit").text().replace(",", ".");
            let billsElmHt = $(elm).find(".billselm-ht").text().replace(",", ".");
            let billsElmTva = $(elm).find(".billselm-tva").text().replace(",", ".");

            billsElm.push({ title: billsElmTitle, date: billsElmDate, desc: billsElmDesc, unit: billsElmUnit, price: billsElmHt, tva: billsElmTva });
        });
        let billsDate = formatDateSeparators($(".js-createbills-datesend").val());
        let billsDelay = $(".js-createbills-delay").val();
        let billsPenality = $(".js-createbills-penality").val();
        let billsDamage = $(".js-createbills-damage").val();
        let billsNumber = $(".js-createbills-numberbills").val();
        let promiseReturn = $.post("/freelance/projet/" + idProject + "/nouveau_paiement/nouvelle_facture/creation", { sender: billsUser, companyName: billsCompanyName, companyAddress: billsCompanyAddress, billsSubject: billsSubject, billsDesc: billsDesc, billsElm: billsElm, billsDate: billsDate, billsDelay: billsDelay, billsPenality: billsPenality, billsDamage: billsDamage, billsNumber: billsNumber });
        promiseReturn.then(function (val) {
            let v = JSON.parse(val);
            if (v.status === "failed") {
                $(".errors").html(v.message);
                $(".errors").show();
            } else {
                window.location.href = v.url;
            }
        });

        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    //Add facture
    $(document).on("click", ".js-add-efacture", function () {
        $(".show-add-efacture").show();
        $(".step-content-2").removeClass("active");
    });

    //ellipsis
    if ($(".dotdotdot").length) {
        $(".dotdotdot").dotdotdot({
            ellipsis: "... ",
            wrap: "letter",
            tolerance: 0,
        });
    }

    $('[name="new_payment"]').on("submit", function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        $(".backdrop").show();
        $(".popin-wrap.popin-sendbillsalert").show();
        $(".popin").show();
    });

    $(document).on("click", ".js-submitbills-afteralert", function () {
        $("[name='new_payment']").off("submit");
        $("[name='new_payment']").submit();
    });
    $(document).on("click", ".timeline-container", function () {
        $(".draft").removeClass("open");
        $(".timeline-round").removeClass("open");
        $(this).parent().addClass("open");
        $(this).next().find(".recall-mail-subject").focus();
    });

    //date mail
    $(document).on("input", ".input-day, .select-echeance", function () {
        let days = $(this).parents(".timeline-mail").find(".input-day").val();
        let when = $(this).parents(".timeline-mail").find(".select-echeance").val();
        let date = $(this).parents(".timeline-mail").find(".js-mail-date").data("maildate");

        if (days) days = parseInt(days);
        else days = 0;
        date = date.split("/");
        let result = new Date(parseInt(date[2], 10), parseInt(date[1], 10) - 1, parseInt(date[0], 10));
        if (when == "after") {
            result.setDate(result.getDate() + days);
        } else {
            result.setDate(result.getDate() - days);
        }

        $(this)
            .parents(".timeline-mail")
            .find(".js-mail-date")
            .html(("0" + result.getDate()).slice(-2) + "/" + ("0" + (result.getMonth() + 1)).slice(-2) + "/" + result.getFullYear());
    });

    //meme popin
    $(document).on("click", ".popin-meme .meme-img img, .addLogoMail", function () {
        let name = $(this).data("memename");
        let hiddenData = $(".hidden-popin-data").val();
        let memeName = $("#index-" + hiddenData).attr("data-memename");
        let n = null;
        if (memeName) n = memeName + "," + name;
        else n = name;
        $("#index-" + hiddenData).attr("data-memename", n);
        let urlImg = $(this).attr("src");
        $("#textarea-" + hiddenData)
            .find(".trumbowyg-editor")
            .append('<img src="' + urlImg + '" height="100" width="100" />');
        $(".backdrop").hide();
        $(".popin-wrap").hide();
        $(".popin").hide();
    });

    //send relaunch
    $(document).on("click", ".submit-mail", function (e) {
        $(e.target).addClass("st-no-btn-disabled");
        let idProject = $(this).data("idproject");
        let idPayment = $(this).data("idpayment");
        let idRecall = $(this).data("idrecall");
        let hiddenIndex = $(this).data("index");
        let parentElement = $(this).parents(".timeline-mail");
        let date = parentElement.find(".js-mail-date").html();
        let subject = parentElement.find(".recall-mail-subject").val();
        let message = parentElement.find(".trumbowyg-editor").html();
        let promiseReturn = $.post("/freelance/projet/" + idProject + "/paiement/" + idPayment + "/nouvelle_relance", { recall_id: idRecall, dateToSend: date, subject: subject, message: message });
        promiseReturn.then(function () {
            window.location.reload();
        });

        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    $(document).on("click", ".submit-draft-mail", function () {
        let parentElement = $(this).parents(".timeline-mail");
        let subject = parentElement.find(".recall-mail-subject").val();
        let message = parentElement.find(".trumbowyg-editor").html();
        if (subject && message) {
            let idProject = $(this).data("idproject");
            let idPayment = $(this).data("idpayment");
            let date = parentElement.find(".js-mail-date").html();
            let promiseReturn = $.post("/freelance/projet/" + idProject + "/paiement/" + idPayment + "/nouvelle_relance", { dateToSend: date, subject: subject, message: message });
            promiseReturn.then(function () {
                window.location.reload();
            });

            promiseReturn.catch(function (err) {
                window.location.reload();
            });
        } else {
            if (!message) {
                parentElement.find(".mail-msg").prepend("<div class='cerror'>" + translation("Veuillez remplir ce champ") + "</div>");
            }
            if (!subject) {
                parentElement.find(".mail-subject").before("<div class='cerror'>" + translation("Veuillez remplir ce champ") + "</div>");
            }
        }
    });

    //delete draft relaunch
    $(document).on("click", ".delete-draft-mail", function () {
        $(".draft").removeClass("open");
    });

    //Delete relaunch
    $(document).on("click", ".delete-mail", function () {
        let idProject = $(this).data("idproject");
        let idPayment = $(this).data("idpayment");
        let idRecall = $(this).data("idrecall");
        let promiseReturn = $.post("/freelance/projet/" + idProject + "/paiement/" + idPayment + "/supprimer_relance/" + idRecall);
        promiseReturn.then(function () {
            window.location.reload();
        });

        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    $(document).on("click", ".js-reinit-mail", function () {
        let parents = $(this).parents(".timeline-mail");
        let defaultTextValue = parents.find(".editrelaunch").data("defaultvalue");
        let defaultSubjectValue = parents.find(".recall-mail-subject").data("defaultvalue");
        parents.find(".editrelaunch").val("");
        parents.find(".trumbowyg-editor").html(defaultTextValue);
        parents.find(".recall-mail-subject").val(defaultSubjectValue);
        parents.find(".js-meme-choice").attr("data-memename", "");
        parents.find(".input-day").val("");
    });

    $(document).on("click", ".js-reinit-draft", function () {
        let parents = $(this).parents(".timeline-mail");
        let defaultTextValue = parents.find(".editrelaunch").data("defaultvalue");
        let defaultSubjectValue = parents.find(".recall-mail-subject").data("defaultvalue");
        parents.find(".editrelaunch").val("");
        parents.find(".trumbowyg-editor").html(defaultTextValue);
        parents.find(".recall-mail-subject").val(defaultSubjectValue);
        parents.find(".js-meme-choice").attr("data-memename", "");
        parents.find(".input-day").val("");
    });

    $(document).on("click", ".js-openpopinfuck", function () {
        $(".search-input").val("wecantdothat");
        $(".search-input").trigger("input");
    });

    $(document).on("click", ".js-openpopin-newuser", function () {
        let routeafter = $(this).data("routeafter");
        $(".js-popinnewuser-submit").data("routeafter", routeafter);
        $(".backdrop").show();
        $(".popin-needbillsuser").show();
        $(".popin").show();
    });

    $(document).on("click", ".js-popinnewuser-submit", function () {
        let idProject = $(this).data("idproject");
        let afterRoute = $(this).data("routeafter") || null;
        let email = $(".js-newbillsuser").val();
        let hasSociety = $(this).data("hassociety") || null;
        let promiseReturn = $.post("/freelance/projet/" + idProject + "/nouvelle_invitation", { invitationEmail: email });
        promiseReturn.then(function (val) {
            let v = JSON.parse(val);
            if (v.status === "success") {
                if (hasSociety) {
                    if (hasSociety === "false") {
                        $(".popin-needcompany").show();
                        $(".popin-needbillsuser").hide();
                    } else window.location.href = afterRoute;
                } else {
                    if (afterRoute) window.location.href = afterRoute;
                    else window.location.reload();
                }
            } else {
                window.location.reload();
            }
        });

        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    //close relaunch mobile
    $(document).on("click", ".close-cross", function () {
        $(this).parents(".timeline-round.open").removeClass("open");
        $(this).parents(".draft.open").removeClass("open");
    });

    if (window.location.search == "?s=b") {
        $(".soancontainer").animate(
            {
                scrollTop: $(".doc-container:last-child").offset().top,
            },
            2000
        );
    }

    //check tva max 100
    $(document).on("input", ".elm-tva", function () {
        let val = $(this).val();
        if (val) {
            if (parseFloat(val) > 100) {
                if (!$(".elmerrormaxtva").length) $(this).next().after('<div class="cerror elmerror elmerrormaxtva">Maximum 100%</div>');
                $(".js-add-billselement").attr("disabled", true);
            } else {
                $(".elmerrormaxtva").remove();
                $(".js-add-billselement").attr("disabled", false);
            }
        } else {
            $(".elmerrormaxtva").remove();
            $(".js-add-billselement").attr("disabled", false);
        }
    });

    $(document).on("input", ".js-createbills-escompte", function () {
        let val = $(this).val();
        if (val) {
            if (parseFloat(val) > 100) {
                if ($(".errormax").length <= 0) $(this).next().after('<div class="cerror elmerror errormax">Maximum 100%</div>');
                $(".js-create-billsrecap").attr("disabled", true);
            } else {
                $(".errormax").remove();
                if ($(".errormax").length <= 0 && $(".errormaxpenality").length <= 0) $(".js-create-billsrecap").attr("disabled", false);
            }
        } else {
            $(".errormax").remove();
            if ($(".errormax").length <= 0 && $(".errormaxpenality").length <= 0) $(".js-create-billsrecap").attr("disabled", false);
        }
    });

    $(document).on("input", ".js-createbills-penality", function () {
        let val = $(this).val();
        if (val) {
            if (parseFloat(val) > 100) {
                if ($(".errormaxpenality").length <= 0) $(this).next().after('<div class="cerror elmerror errormaxpenality">Maximum 100%</div>');
                $(".js-create-billsrecap").attr("disabled", true);
            } else {
                $(".errormaxpenality").remove();
                if ($(".errormaxpenality").length <= 0 && $(".errormax").length <= 0) $(".js-create-billsrecap").attr("disabled", false);
            }
        } else {
            $(".errormaxpenality").remove();
            if ($(".errormaxpenality").length <= 0 && $(".errormax").length <= 0) $(".js-create-billsrecap").attr("disabled", false);
        }
    });

    //Check if amount is more than 20 000
    $(document).on("click", ".js-check-amountbeforenext", function () {
        let billsElm = [];
        $(".js-createbills-elements").each(function (idx, elm) {
            let billsElmHt = $(elm).find(".billselm-ttc").text().replace(",", ".").replace(/\s/g, "").replace("€", "");
            billsElm.push({ price: billsElmHt });
        });
        let price = 0;
        $.each(billsElm, function (idx, elm) {
            price += parseFloat(elm.price);
        });
        if (price <= 500000) {
            var requiredStep = [];
            requiredStep.push(document.querySelectorAll("input:invalid"));
            requiredStep.push(document.querySelectorAll("textarea:invalid"));
            requiredStep.push(document.querySelectorAll(".hiddeninput"));
            var tabrequired = [];
            var test = $.each(requiredStep, function (key, value) {
                $.each(value, function (k, val) {
                    if ($(val).is(":visible")) tabrequired.push(val);
                });
            });
            if (tabrequired.length === 0) {
                if ($(this).hasClass("ignorestep")) {
                    $(":input:visible").val("");
                }
                $(this).parents(".active").next().addClass("active");
                $(this).parents(".active").removeClass("active");
                $(".errors").html("").removeClass("active");
            } else {
                $(".errors").html(translation("Veuillez remplir les champs" + " "));
                $.each(tabrequired, function (key, val) {
                    $(".errors")
                        .addClass("active")
                        .append("<div class='error'>" + val.dataset.field + "</div>");
                });
            }

            $(".warningmaxamount").remove();
        } else {
            if ($(".errormaxamount").length == 0) $(".errors").addClass("active").append("<div class='error errormaxamount'>Le montant maximum autorisé est de 500 000 €</div>");
        }
    });

    if ($("#particles-js").length) {
        particlesJS("particles-js", {
            particles: {
                number: { value: 80, density: { enable: true, value_area: 800 } },
                color: { value: "#ffffff" },
                shape: {
                    type: "circle",
                    stroke: { width: 0, color: "#000000" },
                    polygon: { nb_sides: 5 },
                    image: { src: "img/github.svg", width: 100, height: 100 },
                },
                opacity: {
                    value: 0.5,
                    random: false,
                    anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false },
                },
                size: {
                    value: 3,
                    random: true,
                    anim: { enable: false, speed: 40, size_min: 0.1, sync: false },
                },
                line_linked: {
                    enable: true,
                    distance: 150,
                    color: "#ffffff",
                    opacity: 0.4,
                    width: 1,
                },
                move: {
                    enable: true,
                    speed: 6,
                    direction: "none",
                    random: false,
                    straight: false,
                    out_mode: "out",
                    bounce: false,
                    attract: { enable: false, rotateX: 600, rotateY: 1200 },
                },
            },
            interactivity: {
                detect_on: "canvas",
                events: {
                    onhover: { enable: true, mode: "repulse" },
                    onclick: { enable: true, mode: "push" },
                    resize: true,
                },
                modes: {
                    grab: { distance: 400, line_linked: { opacity: 1 } },
                    bubble: { distance: 400, size: 40, duration: 2, opacity: 8, speed: 3 },
                    repulse: { distance: 200, duration: 0.4 },
                    push: { particles_nb: 4 },
                    remove: { particles_nb: 2 },
                },
            },
            retina_detect: true,
        });
    }

    $(".slick-offers").slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 1170,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    });

    //Popin info + bank offer
    $(document).on("click", ".js-paymentAboPopin", function (e) {
        e.preventDefault();
        var target = $(this).data("target") || null;
        let price = $(this).data("price") || null;
        $(".payment-price").html(price);
        let offerName = $(this).data("offername");
        let tag = $(this).data("tag");
        $(".offername").html(tag);
        $(".js-paymentAbo-step1").attr("data-route", "/freelance/mon_profil/ajout_informations_utilisateur");
        $(".js-paymentAbo-step2").attr("data-route", "/freelance/mon_profil/nouveau_compte_bancaire_popin");
        $(".js-paymentAbo-step3").attr("data-route", "/freelance/mon_profil/mon_abonnement/choix/" + tag);
        let img = $(this).data("img");
        $(".js-paymentAbo-step3").data("img", img);
        $(".hidden-popin-data").val($(this).data("popin-data"));
        $(".backdrop").show();
        $(target).show();
        $(".popin").show();
    });

    $(document).on("click", ".js-paymentAbo-step1", function (e) {
        e.preventDefault();
        var submitUrl = $(this).data("route") || null;
        var submitMethod = "POST";
        var allInputs = $(`.first-step :input`);
        var formData = new FormData();
        allInputs.each(function (i, val) {
            if (val.name) {
                formData.append(val.name, val.value);
            }
        });
        $.ajax({
            url: submitUrl,
            data: formData,
            processData: false,
            contentType: false,
            type: submitMethod,
            success: function (data) {
                let v = JSON.parse(data);
                if (v.status === "ok") {
                    $(".first-step").addClass("hide");
                    $(".second-step").addClass("show");
                } else {
                    if (!$(".errorinfo").length) $(".needinfo").append("<div class='cerror mt-2 errorinfo'>" + v.message + "</div>");
                }
            },
            error: function () {
                window.location.reload();
            },
        });
    });

    $(document).on("click", ".js-paymentAbo-step2", function (e) {
        e.preventDefault();
        var submitUrl = $(this).data("route") || null;
        var submitMethod = "POST";
        var allInputs = $(`.second-step :input`);
        var formData = new FormData();
        allInputs.each(function (i, val) {
            if (val.name) {
                formData.append(val.name, val.value);
            }
        });
        $.ajax({
            url: submitUrl,
            data: formData,
            processData: false,
            contentType: false,
            type: submitMethod,
            success: function (data) {
                let v = JSON.parse(data);
                if (v.status === "ok") {
                    $(".second-step").removeClass("show");
                    $(".third-step").addClass("show");
                    $(".js-paymentAbo-step3").attr("data-bankid", v.bankId);
                } else {
                    if (!$(".errorbank").length) $(".needbank").append("<div class='cerror mt-2 errorbank'>" + v.message + "</div>");
                }
            },
            error: function (err) {
                window.location.reload();
            },
        });
    });

    $(document).on("click", ".js-paymentAbo-step3", function (e) {
        e.preventDefault();
        $(".loader-popin").fadeIn(200);
        $(".popin").css("overflow", "hidden");
        $(this).prop("disabled", true);
        $(".third-step").removeClass("show");
        var submitUrl = $(this).data("route") || null;
        var bankId = $(this).data("bankid") || null;
        let subTag = $(this).data("subtag");
        $.ajax({
            url: submitUrl,
            data: { bankId: bankId, subTag: subTag },
            type: "POST",
            success: function (data) {
                let v = JSON.parse(data);
                if (v.status === "success") {
                    if (v.redirect) {
                        window.location.href = v.redirect;
                    }
                    let img = $(this).data("img");
                    $(".offer-finish-img").attr("src", img);
                    let offer = $(".offername").html();
                    $(".offer-finish-name").html(offer);

                    $(".popin-buy-offer").hide();
                    $(".popin-buy-offer-finish").show();
                    $(".backdrop").addClass("js-popin-offer-redirect");
                    $(".popin-close").addClass("js-popin-offer-redirect");
                } else {
                    window.location.reload();
                }
            },
            error: function (err) {
                window.location.reload();
            },
        });
    });

    // A conserver ?

    //Open popin buy offer
    // $(document).on('click', '.js-openpopin-buyoffer', function() {

    //   let choice = $(this).data('pchoice');

    //   $('.offer-payment-block').show();
    //   $('.js-popinbuyoffer-submit').html('payer');
    //   let title = $(this).data('title');
    //   $('.popin-buy-offer .popin-title').html(title);

    //   let img = $(this).data('img');
    //   $('.buy-offer-img').attr('src', img);

    //   let name = $(this).data('name');
    //   $('.buy-offer-name').html(name);

    //   let price = $(this).data('price');
    //   $('.buy-offer-price').html(price);
    //   let bills = $(this).data('bills');
    //   if (choice == 'month')
    //     $('.buy-offer-bills').html(bills);
    //   else {
    //     let billsAnnual = bills.replace(/mois/g, 'an');
    //     $('.buy-offer-bills').html(billsAnnual);
    //   }

    //   let offerTag = $(this).data('tag');
    //   $('.js-popinbuyoffer-submit').attr('data-offertag', offerTag);
    //   $('.js-offerchoice-payment').attr('data-offertag', offerTag);

    //   if (offerTag == 'FREE' || offerTag == 'LIBERTY') {
    //     $('.bank-list .selected').removeClass('selected');
    //     $('.offer-payment-block').hide();
    //     $('.js-popinbuyoffer-submit').html('Choisir cette offre');
    //     $(".offer-finish-paiement").html('');
    //   }

    //   $('.backdrop').show();
    //   $('.popin-buy-offer').show();
    //   $('.popin').show();
    // });

    //Send popin buy offer and show popin youhou
    $(document).on("click", ".js-popinbuyoffer-submit", function (e) {
        $(e.target).addClass("st-no-btn-disabled");
        let offerChoiceTag = $(this).data("offertag");
        let oldOfferTag = $(".js-offerchoice-payment").data("oldoffer");
        let offerSubTag = $(this).data("subtag");
        let oldOfferSubTag = $(".js-offerchoice-payment").data("oldoffersubtag");
        let subTag = $(this).data("subtag");
        let bankId = $(".bank-list .selected").data("bankid");
        if ((oldOfferTag == "business" && offerChoiceTag == "premium" && oldOfferSubTag == "YEARLY") || (oldOfferSubTag == "YEARLY" && offerSubTag == "MONTHLY" && oldOfferTag == offerChoiceTag)) {
            $(".popin-buy-offer").hide();
            $(".popin-changingOffer").show();
            $(".backdrop").addClass("js-changingOffer");
            $(".popin-close").addClass("js-changingOffer");
            $(e.target).removeClass("st-no-btn-disabled");
        } else {
            let promiseReturn = $.post("/freelance/mon_profil/mon_abonnement/choix/" + offerChoiceTag, { bankId: bankId, subTag: subTag });
            promiseReturn.then(function (val) {
                $(e.target).removeClass("st-no-btn-disabled");
                let v = JSON.parse(val);
                if (v.status === "success") {
                    if (v.redirect) {
                        window.location.href = v.redirect;
                    }
                    let img = $(".buy-offer-img").attr("src");
                    $(".offer-finish-img").attr("src", img);
                    let offer = $(".buy-offer-name").html();
                    $(".offer-finish-name").html(offer);

                    $(".popin-buy-offer").hide();
                    $(".popin-buy-offer-finish").show();
                    $(".backdrop").addClass("js-popin-offer-redirect");
                    $(".popin-close").addClass("js-popin-offer-redirect");
                } else {
                    window.location.reload();
                }
            });

            promiseReturn.catch(function (err) {
                window.location.reload();
            });
        }
    });

    $(".js-changingOffer").on("click", () => {
        let offerTag = $(".js-data-to-send").data("offertag");
        let offerSubTag = $(".js-data-to-send").data("subtag");
        window.location.href = "/freelance/mon_profil/subscription/need_support?offer=" + offerTag + "&subTag=" + offerSubTag;
    });

    $(document).on("change", ".js-checkbox-group", function () {
        let id = $(this).attr("id");
        let groupSplitted = id.split("_");
        let group = id.replace("_" + groupSplitted[3], "");
        if ($(this).is(":checked")) {
            $('[id^="' + group + '_"]').each(function (idx, elm) {
                $(elm).prop("checked", true);
            });
        } else {
            $('[id^="' + group + '_"]').each(function (idx, elm) {
                $(elm).prop("checked", false);
            });
        }
    });

    $(document).on("change", ".js-checkbox-group-child", function () {
        let id = $(this).attr("id");
        let groupSplitted = id.split("_");
        let group = id.replace("_" + groupSplitted[3], "");
        let max = $('[id^="' + group + '_"]').length;
        let i = 0;
        $('[id^="' + group + '_"]').each(function (idx, elm) {
            if ($(elm).is(":checked")) {
                i++;
            }
        });
        if (i == 0) {
            $("#" + group).prop("indeterminate", false);
            $("#" + group).prop("checked", false);
        } else if (i == max) {
            $("#" + group).prop("indeterminate", false);
            $("#" + group).prop("checked", true);
        } else {
            $("#" + group).prop("checked", false);
            $("#" + group).prop("indeterminate", true);
        }
    });

    // A conserver ?

    //Check if condition is check before submit to server
    // $(document).on('submit', '[name="archi_registration"]', function(e) {
    //   e.preventDefault();
    //   e.stopImmediatePropagation();
    //   if ($('#archi_registration_agreeTerms').is(':checked')) {
    //     $(this)[0].submit();
    //   }
    //   else {
    //     if (!$('.invalid-feedback').length) {
    //       let error = '<span class="invalid-feedback d-block"><span class="d-block"><span class="form-error-icon badge badge-danger text-uppercase">Erreur</span> <span class="form-error-message">Vous devez accepter les conditions générales d\'utilisations. maggle</span></span></span>';
    //       $('.form-check-label').append(error);
    //     }
    //     return false;
    //   }
    // });

    //check if condtition is checked before linedinConnect
    $(document).on("click", ".linkdinconnect", function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        if ($("#archi_registration_agreeTerms").length) {
            if ($("#archi_registration_agreeTerms").is(":checked")) {
                window.location.href = $(this).attr("href");
            } else {
                if (!$(".invalid-feedback").length) {
                    let error = '<span class="invalid-feedback d-block"><span class="d-block"><span class="form-error-icon badge badge-danger text-uppercase">Erreur</span> <span class="form-error-message">Vous devez accepter les conditions générales d\'utilisations.</span></span></span>';
                    $(".form-check-label").append(error);
                }
                return false;
            }
        } else {
            window.location.href = $(this).attr("href");
        }
    });

    $(document).on("click", ".js-transfer-to-bank", function () {
        $(".js-submit-newbank").addClass("transferring");
        $(".js-submit-newbank").removeClass("paying");
    });

    $(".js-payment-popin-add-other-account").on("click", () => {
        $(".js-payment-select-account").addClass("d-none");
        $(".js-payment-popin-add-account").removeClass("d-none");
    });

    $(".js-efacture-popin-add-other-account").on("click", () => {
        $(".js-send-later-efacture-step-1").addClass("d-none");
        $(".js-send-later-efacture-add-account").removeClass("d-none");
    });

    //Open modification account info
    $(document).on("click", ".info-btn-modify", function () {
        $(".info-modify-form").show();
        $(".info-listing").hide();
    });

    $(document).on("click", ".infouser-verification-send", function () {
        let pseudo = $(".pseudo").val();
        let firstname = $(".firstname").val();
        let lastname = $(".lastname").val();
        let birthDate = $(".birthdate").val();
        let phoneNumber = $(".phonenumber").val();
        let url = "/freelance/mon_profil/completer_mes_informations/informations_utilisateur/manage";
        let submitMethod = "POST";
        let allInputs = $(`.info-particular-form :input`);
        let formData = new FormData();
        let d;
        let m;
        let y;
        allInputs.each(function (i, val) {
            if (val.name) {
                if (val.name == "thBirthday") {
                    let birthdaySplited = val.value.split("/");
                    let birthDay = new Date(birthdaySplited[2], birthdaySplited[1], birthdaySplited[0]);
                    d = birthDay.getDate();
                    m = birthDay.getMonth();
                    y = birthDay.getFullYear();
                    formData.append(val.name, y + "-" + m + "-" + d);
                } else {
                    formData.append(val.name, val.value);
                }
            }
        });

        if (!isAdult(birthDate)) {
            $(".soancontainer-center").animate({ scrollTop: 0 }, 300);
            if (!$(".error-age-step1").length) {
                $(".error-maxipopin").append('<div class="error-age-step1">' + translation("Vous devez être majeur") + ".</div>");
            }
        } else {
            $(".error-age-step1").remove();
            $.ajax({
              url: url,
              data: formData,
              processData: false,
              contentType: false,
              type: submitMethod,
              success: function(val){
                let v = JSON.parse(val);
                if (v.status === 'success') {
                  if (v.route) {
                    window.location.href = v.route;
                  }
                  else {
                    window.location.href = '/freelance/mon_profil/informations';
                  }
                }
                else {
                  if (v.message) {
                    if (!$('.error-step1').length)
                      $('.error-maxipopin').append('<div class="error-step1">'+v.message+'</div>');
                  }
                  else {
                    window.location.reload();
                  }
                }
              },
              error: function(err) {
               window.location.reload();
              }
            });
        }
    });

    $(document).on("click", ".btn-send-company-step1", function (e) {
        e.preventDefault();
        $(".loader").fadeIn();
        let url = "/freelance/mon_profil/completer_mes_informations/informations_entreprise/step1";
        let submitMethod = "POST";
        //let allInputs = $(`.company-step1 :input`);
        let formData = new FormData();
        // allInputs.each(function(i, val) {
        //   if (val.name) {
        //       formData.append(val.name, val.value);
        //   }
        // });
        let select = $(".thstatus-name:visible").val();
        let country = $(".st-blocRadioButton-White input:checked").attr("data-country");
        formData.append("thStatus", select);
        formData.append("thCountry", country);

        $.ajax({
            url: url,
            data: formData,
            processData: false,
            contentType: false,
            type: submitMethod,
            success: function (val) {
                $(".loader").fadeOut(1000);
                let v = JSON.parse(val);
                if (v.status === "success") {
                    if (v.route) {
                        window.location.href = v.route;
                    } else {
                        $(".company-step1").hide();
                        $(".company-step2").addClass("active").show();
                        $(".step-company-1 .step-after").addClass("active");
                        $(".step-company-2 .step-circle").addClass("active");
                        $(".step-company-1 .step-circle").addClass("past");
                        $(".step-company-2").addClass("active");
                        $(".step-company-1").addClass("past");
                        let val = $(".thstatus-name").val();
                        let country = v.thCountry;
                        if (val === translation("Association") || val === translation("Fondation") || val === "ONG") {
                            let newRegex = new RegExp(translation("entreprise"), "g");
                            let companyStep2Organisme = $(".company-step2").html().replace(newRegex, translation("organisme"));
                            $(".company-step2").html(companyStep2Organisme);
                            let companyStep3Organisme = $(".company-step3").html().replace(newRegex, translation("organisme"));
                            $(".company-step3").html(companyStep3Organisme);
                            $(".company-input-siret").append("<div class='assosiret'>" + translation("Si votre organisme n'a pas de Siret") + "</div>");
                        }
                        $(".company-input-siret input").removeClass("d-block").addClass("d-none");
                        $(".company-input-siret label").removeClass("d-inline-block").addClass("d-none");
                        if (country) {
                            if (country === "ES") {
                                $(".company-input-siret .cif-nif").removeClass("d-none").addClass("d-block");
                                $(".st-cifNif").removeClass("d-none").addClass("d-inline-block");
                            } else {
                                $(".company-input-siret .st-siretInput").removeClass("d-none").addClass("d-block");
                                $(".st-siret").removeClass("d-none").addClass("d-inline-block");
                            }
                        }
                        $(".error-maxipopin").empty();
                    }
                } else {
                    $(".loader").fadeOut(1000);
                    if (v.message) {
                        if (!$(".error-company-step1").length) $(".error-maxipopin").append('<div class="error-company-step1">' + v.message + "</div>");
                    } else {
                        window.location.reload();
                    }
                }
            },
            error: function (err) {
                window.location.reload();
            },
        });
    });

    $(document).on("click", ".btn-sendback-company-step1", function (e) {
        e.preventDefault();
        $(".company-step2").removeClass("active").hide();
        $(".company-step1").addClass("active").show();
        $(".step-company-1 .step-circle").addClass("active");
        $(".step-company-1 .step-circle").removeClass("past");
        $(".step-company-2 .step-circle").removeClass("active");
        $(".step-company-1 .step-after").removeClass("active");
        $(".error-maxipopin").empty();
    });

    $(document).on("click", ".btn-sendback-company-step2", function (e) {
        e.preventDefault();
        $(".company-step3").removeClass("active").hide();
        $(".company-step2").addClass("active").show();
        $(".step-company-2 .step-circle").addClass("active");
        $(".step-company-2 .step-circle").removeClass("past");
        $(".step-company-3 .step-circle").removeClass("active");
        $(".step-company-2 .step-after").removeClass("active");
        $(".error-maxipopin").empty();
    });

    $(document).on("click", ".btn-send-company-step2", function (e) {
        e.preventDefault();
        let thSiret = $(".siret").val();
        if (thSiret.length < 14 && thSiret.length > 0) {
            $(".errors-siret").show();
            $(".errors-siret").html("<span class='cerror'>" + translation("Votre SIRET est trop court") + "</span>");
        } else {
            $(".loader").fadeIn();
            $(".errors-siret").hide();
            $(".errors-siret").html();
            let url = "/freelance/mon_profil/completer_mes_informations/informations_entreprise/step2";
            let logo = fileLogoCompany;
            let submitMethod = "POST";
            let allInputs = $(`.company-step2 :input:visible`);
            let formData = new FormData();
            allInputs.each(function (i, val) {
                if (val.name) {
                    formData.append(val.name, val.value);
                }
            });
            formData.append("logo", logo);
            $.ajax({
                url: url,
                data: formData,
                processData: false,
                contentType: false,
                type: submitMethod,
                success: function (val) {
                    $(".loader").fadeOut();
                    $(".soancontainer-center").animate({ scrollTop: 0 }, 300);
                    let v = JSON.parse(val);
                    if (v.status === "success") {
                        if (v.route) {
                            window.location.href = v.route;
                        } else {
                            $(".company-step2").removeClass("active").hide();
                            $(".company-step3").addClass("active").show();
                            $(".step-company-1 .step-after").addClass("active");
                            $(".step-company-2 .step-after").addClass("active");
                            $(".step-company-3 .step-circle").addClass("active");
                            $(".step-company-2").addClass("past");
                            $(".step-company-2 .step-circle").addClass("past");
                            $(".step-company-3").addClass("active");
                            $(".error-maxipopin").empty();
                        }
                    } else {
                        $(".loader").fadeOut(1000);
                        if (v.message) {
                            if (!$(".error-company-step1").length) {
                                $(".error-maxipopin").append('<div class="error-company-step1">' + v.message + "</div>");
                            }
                        } else {
                            window.location.reload();
                        }
                    }
                },
                error: function (err) {
                    window.location.reload();
                },
            });
        }
    });

    $(document).on("click", ".btn-send-company-step3", function (e) {
        e.preventDefault();
        // $(".loader").fadeIn();
        let url = "/freelance/mon_profil/completer_mes_informations/informations_entreprise/step3";
        let submitMethod = "POST";
        let allInputs = $(`.company-step3 :input`);
        let formData = new FormData();
        let itsInsidePopin = $(this).hasClass("inside-popin");
        allInputs.each(function (i, val) {
            if (val.name) {
                if (val.name == "thLRBirthday") {
                    formData.append(val.name, formatDateSeparators(val.value));
                } else formData.append(val.name, val.value);
            }
        });
        if (!isAdult($(".birthday").val())) {
            $(".soancontainer-center").animate({ scrollTop: 0 }, 300);
            if (!$(".error-age-step3").length) {
                $(".error-maxipopin").append('<div class="error-age-step3">' + translation("Le représentant de l'entreprise") + ".</div>");
            }
        } else {
            $(".error-age-step3").remove();
            $(".loader").fadeIn();
            $.ajax({
                url: url,
                data: formData,
                processData: false,
                contentType: false,
                type: submitMethod,
                success: function (val) {
                    let v = JSON.parse(val);
                    $(".loader").fadeOut(1000);
                    $(".soancontainer-center").animate({ scrollTop: 0 }, 300);
                    if (v.status === "success") {
                        if (v.route) {
                            window.location.href = v.route;
                        } else {
                            if (itsInsidePopin) {
                                $(".company-step").removeClass("show");
                                $(".second-step").addClass("show");
                            } else {
                                $(".backdrop").addClass("popin-close-back-info");
                                $(".backdrop").show();
                                $(".popin-company-created").show();
                                $(".popin").show();
                            }
                        }
                        $(".error-maxipopin").empty();
                    } else {
                        if (v.message) {
                            if (!$(".error-company-step3").length) $(".error-maxipopin").append('<div class="error-company-step3">' + v.message + "</div>");
                        } else {
                            window.location.reload();
                        }
                    }
                },
                error: function (err) {
                    window.location.reload();
                },
            });
        }
    });

    $(document).on("click", ".js-modify-company", function () {
        $(".companyinfos-infos").hide();
        $(".companyinfos-form").show();
    });

    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    async function progressBarPoayoutShow() {
        let progressBar = $(".progress-bar");
        for (let b = 1; b < 32; b += 2) {
            await sleep(2000);
            progressBar.attr("aria-valuenow", b);
            progressBar.css("width", b * 3 + "%");
        }
    }

    $(document).on("click", ".popin-action-close-payout", function () {
        window.location.reload();
    });

    //Send transfer to specific account
    $(document).on("click", ".js-popin-submit-transfer", function () {
        var submitUrl = $(this).data("route") || null;
        var bankId = $(".bankselect.selected").data("bankid");
        $(".popin-profil").hide();
        $(".waiting-payout").show();
        progressBarPoayoutShow();

        $.when(
            $.ajax({
                url: submitUrl,
                type: "POST",
                data: { bankId: bankId },
                success: function (val) {
                    let v = JSON.parse(val);
                    if (v.status === "success") {
                        if (v.payout.ModeApplied == "INSTANT_PAYMENT") {
                            $(".content-payout").html(translation("Félicitation, vous avez reçu le virement en instantané"));
                        } else {
                            $(".content-payout").html(translation("Votre banque n'a pas accepté le virement instantané"));
                        }
                    } else {
                        window.location.reload();
                    }
                    $(".popin-actions").html('<button type="button" class="btn btn-primary popin-action-close-payout w-auto">' + translation("Compris") + "</button>");
                },
            }),

            $.ajax({
                url: "/freelance/mon_profil/compte_bancaire/" + bankId + "/default",
                type: "POST",
                data: { bankId: bankId },
            })
        )
            .then(function () {})
            .catch(function (err) {
                window.location.reload();
            });
    });

    $(".js-show-payafter").on("click", function () {
        if ($(this).is(":checked")) {
            $(".payafter").show();
        } else {
            $(".payafter").hide();
        }
    });

    $(document).on("click", ".js-openstep-particular", function () {
        var promiseReturn = $.post("/freelance/mon_profil/completer_mes_informations/informations_utilisateur", { choice: "PER" });

        promiseReturn.then(function (val) {
            $(".zero-step").hide();
            $(".first-step").addClass("show");
        });

        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    $(document).on("click", ".js-openstep-company", function () {
        var promiseReturn = $.post("/freelance/mon_profil/completer_mes_informations/informations_utilisateur", { choice: "PRO" });

        promiseReturn.then(function (val) {
            $(".zero-step").hide();
            $(".first-step").addClass("show");
        });

        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    $(document).on("click", ".offer-type-choice", function () {
        $(".offer-type-choice").removeClass("active");
        $(this).addClass("active");
        let choice = $(this).data("choice");
        if (choice == "month") {
            $(".subscribe-btn").attr("data-pchoice", "month");
        } else {
            $(".subscribe-btn").attr("data-pchoice", "annual");
        }
    });

    function ourOffersOfferYearChoice() {
        $(".offer-month-choice").removeAttr("checked");
        $(".offer-payment-year").toggleClass("cprimary-7");
        if ($(".offer-payment-month").hasClass("cprimary-7")) $(".offer-payment-month").removeClass("cprimary-7");
        $(".js-show-year").show();
        $(".js-show-month").hide();
        $(".offer-every-year").show();
        // $('.offer-by').html('par an');
        // $(".offer-demand-payment-by-month").hide();
        // $(".offer-demand-payment-by-annual").show();
        $(".js-blue-color-dynamic-offer-month").removeClass("cprimary-7");
        $(".js-blue-color-dynamic-offer-annual").addClass("cprimary-7");
        $(".offer-efacture-by").html(translation("Factures par an"));
        let price = $(this).data("price");
        $(".js-offerchoice-payment").attr("data-offerprice", price);
        $(".js-needinfo-payment").attr("data-offerprice", price);
        $(".js-offerchoice-payment").attr("data-subtag", "YEARLY");
    }

    function ourOffersOfferMonthChoice() {
        $(".offer-year-choice").removeAttr("checked");
        $(".offer-payment-month").toggleClass("cprimary-7");
        if ($(".offer-payment-year").hasClass("cprimary-7")) $(".offer-payment-year").removeClass("cprimary-7");
        $(".js-show-year").hide();
        $(".js-show-month").show();
        $(".offer-every-year").hide();
        $(".offer-by").html(translation("par mois"));
        // $(".offer-demand-payment-by-month").show();
        // $(".offer-demand-payment-by-annual").hide();
        $(".js-blue-color-dynamic-offer-month").addClass("cprimary-7");
        $(".js-blue-color-dynamic-offer-annual").removeClass("cprimary-7");
        $(".offer-efacture-by").html(translation("Factures par mois"));
        let price = $(this).data("price");
        $(".js-offerchoice-payment").attr("data-offerprice", price);
        $(".js-needinfo-payment").attr("data-offerprice", price);
        $(".js-offerchoice-payment").attr("data-subtag", "MONTHLY");
    }

    if ($(".offer-year-choice").is(":checked")) {
        ourOffersOfferYearChoice();
    } else if ($(".offer-month-choice").is(":checked")) {
        ourOffersOfferMonthChoice();
    }

    $(document).on("change", ".offer-month-choice", function () {
        ourOffersOfferMonthChoice();
    });

    $(document).on("change", ".offer-year-choice", function () {
        ourOffersOfferYearChoice();
    });

    // A conserver ?

    // $(document).on('click', '.js-needinfo-payment', function() {
    //   $('.popin-payment-offer').show();
    //   $('.popin').show();
    //   $('.backdrop').show();
    //   let offer = $(this).attr('data-offername');
    //   $('.offername').html(offer);
    //   let offerPrice = $(this).attr('data-offerprice');
    //   $('.payment-price').html(offerPrice);
    //   let oldOffer = $(this).data('oldoffer');
    //   let oldOfferPrice = $(this).attr('data-oldofferprice');
    //   let finalPrice = 0;
    //   let offerSubTag = $(this).data('subtag');
    //   if (offer == 'premium' && oldOffer == 'business') {
    //     if (offerSubTag == 'MONTHLY') {
    //       finalPrice = parseFloat(offerPrice) - (parseFloat(oldOfferPrice) / 100);
    //       $('.offer-pay-now').html(finalPrice + " € HT (Abonnement Premium - Abonnement Business déjà facturé), puis");
    //     }
    //     else {
    //       if (oldOfferSubTag == 'YEARLY')
    //       {
    //         finalPrice = (parseFloat(offerPrice)) - ((parseFloat(oldOfferPrice)) / 100);
    //         $('.offer-pay-now').html(finalPrice + ' € HT (Abonnement Premium - Abonnement Business déjà facturé), puis');
    //       }
    //       else {
    //         finalPrice = (parseFloat(offerPrice) * 12) - (parseFloat(oldOfferPrice) / 100);
    //         $('.offer-pay-now').html(finalPrice+' € HT (Abonnement Premium - Abonnement Business déjà facturé), puis');
    //       }
    //     }
    //   }
    //   $('.js-paymentAbo-step3').attr('data-subtag', offerSubTag);
    //   $('.js-payment-step2').attr("data-route", "/freelance/mon_profil/nouveau_compte_bancaire_popin");
    //   $('.js-paymentAbo-step3').attr("data-route", "/freelance/mon_profil/mon_abonnement/choix/"+offer);
    // });

    $(document).on("click", ".js-acceptcompany-payment", function () {
        $(".popin-verification-sended").show();
        $(".popin").show();
        $(".backdrop").show();
    });

    $(document).on("click", ".js-offerchoice-payment", function () {
        $(".popin-buy-offer").show();
        $(".popin").show();
        $(".backdrop").show();
        let offer = $(this).data("offername");
        $(".buy-offer-name").html(offer);
        $(".offer-name").html(offer);
        $(".buy-offer-name").addClass("offer-" + offer);
        let offerSubTag = $(this).attr("data-subtag");
        let offerPrice = $(this).attr("data-offerprice");
        let offerPriceNumber = Number(offerPrice.replace(",", "."));
        let offerPriceWithTVA = convertPrice((offerPriceNumber + offerPriceNumber * 0.2) * 100);
        $(".popin-offer-priceTime").text(translation("par an"));
        // if ($('.offer-year-choice').is(':checked')) {
        //   offerPrice = $('.offer-year-choice').data('price');
        //   $(".popin-offer-priceTime").text(translation("par an"));
        // }
        // else {
        //   offerPrice = $('.offer-month-choice').data('price');
        //   $(".popin-offer-priceTime").text(translation("par mois"));
        // }
        if (parseInt(offerPrice) <= 0) {
            $(".offer-payment-block").hide();
        }
        $(".popin-offer-price").html(offerPrice);
        let oldOfferSubTag = $(this).data("oldoffersubtag");
        let oldOffer = $(this).data("oldoffer");
        let oldOfferPrice = $(this).data("oldofferprice");
        if (offer == "premium" && oldOffer == "business") {
            if (offerSubTag == "MONTHLY") {
                $(".offer-pay-now").text(offerPrice + " € " + translation("HT / mois soit") + " " + offerPriceWithTVA + " " + translation("TTC / mois business terminé"));
            } else {
                $(".offer-pay-now").text(offerPrice + " € " + translation("HT / an soit") + " " + offerPriceWithTVA + " " + translation("TTC / an business terminé"));
            }
        } else if (offer == "business" && oldOffer == "premium") {
            if (offerSubTag == "MONTHLY") {
                $(".offer-pay-now").text(offerPrice + " € " + translation("HT / mois soit") + " " + offerPriceWithTVA + " " + translation("TTC / mois premium terminé"));
            } else {
                $(".offer-pay-now").text(offerPrice + " € " + translation("HT / an soit") + " " + offerPriceWithTVA + " " + translation("TTC / an premium terminé"));
            }
        } else {
            if (offerSubTag == "MONTHLY") {
                $(".offer-pay-by").html(translation("mois"));
                $(".offer-pay-now").html(offerPrice + " € " + translation("HT / mois soit") + " " + offerPriceWithTVA + " " + translation("TTC / mois"));
                $(".offer-pay-byb").html(translation("mois"));
            } else {
                $(".offer-pay-by").html("année");
                $(".offer-pay-now").html(offerPrice + " € " + translation("HT / an soit") + " " + offerPriceWithTVA + " " + translation("TTC / an"));
                $(".offer-pay-byb").html(translation("an"));
            }
        }

        $(".buy-offer-price").addClass("offer-" + offer);
        $(".js-popinbuyoffer-submit").attr("data-offertag", offer);
        $(".js-popinbuyoffer-submit").attr("data-subtag", offerSubTag);
    });

    $(document).on("click", ".reset-return-info", function () {
        window.location.href = "/freelance/mon_profil/informations";
    });

    $(document).on("click", ".reset-return-company", function () {
        window.location.href = "/freelance/mon_profil/entreprise";
    });

    //Send info from popin base
    $(document).on("click", ".js-send-popinneedinfo", function (e) {
        e.preventDefault();
        let thSiret = $(".thsiret").val();
        if (thSiret.replace(/ /g, "").length < 14) {
            $(".errors-siret").show();
            $(".errors-siret").html("<span class='cerror'>" + translation("Votre SIRET est trop court") + "</span>");
        } else {
            let thCapital = $(".thcapital").val();
            let thBusinessTypeName = $(".thbusinesstypename").val();
            let route = $(this).data("route");
            let promiseReturn = $.post(route, { thSiret: thSiret, thCapital: thCapital, thBusinessTypeName: thBusinessTypeName });

            promiseReturn.then(function (val) {
                let v = JSON.parse(val);
                if (v.status === "success") {
                    $(".errors-siret").hide();
                    $(".errors-siret").html();
                    $(".popin").hide();
                    $(".backdrop").hide();
                    $(".popin-need-information").hide();
                    window.location.reload();
                } else {
                    $(".errors-siret").show();
                    $(".errors-siret").html(`<span class='cerror'>${v.message}</span>`);
                }
            });

            promiseReturn.catch(function (err) {
                window.location.reload();
            });
        }
    });

    $(document).on("click", ".js-demandpayment-needinfo", function (e) {
        e.preventDefault();
        $(".backdrop").show();
        $(".popin").show();
        $(".popin-need-information").show();
    });

    $(document).on("click", ".nodblclick", function (e) {
        e.preventDefault();

        if ($(this).hasClass("clicked")) {
            return false;
        } else {
            $(this).addClass("clicked");
            window.location.href = $(this).attr("href");
        }
    });

    $(document).on("click", ".js-open-mention", function () {
        let mentions = $(".mentions-elm").html();
        $(".popin-add-mentions .mentions-textarea").val(mentions);
        $(".popin").show();
        $(".backdrop").show();
        $(".popin-add-mentions").show();
    });

    $(document).on("click", ".js-popin-addmentions", function () {
        let mentions = $(".mentions-textarea").val();
        $(".mentions-elm").html(mentions);
        $(".backdrop").hide();
        $(".popin").hide();
        $(".popin-add-mentions").hide();
    });

    $(document).on("change", ".js-show-escompte", function () {
        $(".escompte-content").toggleClass("open");
    });

    $(document).on("click", ".js-send-efacture", function () {
        let showSendLater = $(".js-show-sendlater").is(":checked");
        let sendLater = formatDateSeparators($(".js-date-sendlater").val());
        const dateObjectSendLater = new Date(parseInt(sendLater.split("-")[2]), parseInt(sendLater.split("-")[1]) - 1, parseInt(sendLater.split("-")[0]));
        if (dateObjectSendLater <= new Date() && showSendLater) {
            if (!$(".error-sendlater").length) {
                $(".errors").append('<div class="error-sendlater">' + translation("date ultérieure") + "</div>");
                $(".errors").show();
            }
        } else {
            $(".loader").fadeIn();
            let formData = new FormData();
            let submitMethod = "POST";
            let delayedInvoice = formatDateSeparators($(".js-date-sendlater").val());
            //trouver efactureId
            let efactureId = $(this).data("idefacture") || $(".step-content-2 .js-select-bills").val();
            let projectId = $(this).data("projectid");
            let billsUser = $(".js-select-billsreceiver").val();
            if ($(".js-show-sendlater").is(":checked")) formData.append("delayedInvoice", delayedInvoice);
            formData.append("efactureId", efactureId);
            formData.append("projectId", projectId);
            formData.append("sender", billsUser);
            $.ajax({
                url: "/freelance/projet/" + projectId + "/nouveau_paiement/efacture/" + efactureId,
                data: formData,
                processData: false,
                contentType: false,
                type: submitMethod,
                success: function (val) {
                    let v = JSON.parse(val);
                    if (v.status === "success") {
                        window.location.href = v.url;
                    } else {
                        $(".loader").fadeOut("1000");
                        $(".errors").show();
                        $(".errors").html(v.message);
                    }
                },
                error: function (err) {
                    window.location.reload();
                },
            });
        }
    });

    $(document).on("click", ".js-show-laststep-facture", function () {
        let subject = $(".js-pay-subject").val();
        let amount = $(".js-pay-amount").val();
        if (subject && amount) {
            $(".show-add-efacture").hide();
            $(".show-laststep").show();
            $(".js-send-efacture").addClass("js-send-facture").removeClass("js-send-efacture");
            $(".remove-facture-link").addClass("js-return-addbills");
        } else {
            $(".errors").show();
            $(".errors").html(translation("Veuillez remplir les champs" + " "));
        }
    });

    $(document).on("click", ".js-send-facture", function () {
        let showSendLater = $(".js-show-sendlater").is(":checked");
        let sendLater = formatDateSeparators($(".js-date-sendlater").val());
        const dateObjectSendLater = new Date(parseInt(sendLater.split("-")[2]), parseInt(sendLater.split("-")[1]) - 1, parseInt(sendLater.split("-")[0]));
        if (dateObjectSendLater <= new Date() && showSendLater) {
            if (!$(".error-sendlater").length) {
                $(".errors").append('<div class="error-sendlater">' + translation("date ultérieure") + "</div>");
                $(".errors").show();
            }
        } else {
            $(".loader").fadeIn();
            let formData = new FormData();
            let submitMethod = "POST";
            let invoice = $(".js-pay-facture")[0].files;
            let dueDate = formatDateSeparators($(".js-pay-duedate").val());
            let projectId = $(this).data("projectid");
            let amount = $(".js-pay-amount").val();
            let subject = $(".js-pay-subject").val();
            let delayedInvoice = formatDateSeparators($(".js-date-sendlater").val());
            let sender = $(".js-select-billsreceiver").val();
            formData.append("bills", invoice[0]);
            if ($(".js-show-sendlater").is(":checked")) formData.append("delayedInvoice", delayedInvoice);
            formData.append("dueDate", dueDate);
            formData.append("amount", amount);
            formData.append("subject", subject);
            formData.append("projectId", projectId);
            formData.append("sender", sender);
            $.ajax({
                url: "/freelance/projet/" + projectId + "/nouveau_paiement/facture",
                data: formData,
                processData: false,
                contentType: false,
                type: submitMethod,
                success: function (val) {
                    let v = JSON.parse(val);
                    if (v.status === "success") {
                        window.location.href = v.url;
                    } else {
                        $(".loader").fadeOut("1000");
                        $(".errors").show();
                        $(".errors").html(v.message);
                    }
                },
                error: function (err) {
                    window.location.reload();
                },
            });
        }
    });

    $(document).on("click", ".remove-facture-link", function () {
        if ($(".js-send-facture").length) {
            $(".js-send-facture").addClass("js-send-efacture").removeClass("js-send-facture");
            $(".show-add-efacture").show();
            $(".step-content-2").removeClass("active");
            $(".step-content-3").hide();
        }
    });

    // A conserver ?

    //Autocomplete with Textarea for user
    // $(document).on('input', '.autocomplete-textarea', function() {
    //   let parentContainer = $(this).parents('.autocomplete-container');
    //   parentContainer.find(".autocomplete-content").removeClass('open');
    //   let val = $(this).val();
    //   let id = $(this).data('id');
    //     let promiseReturn = $.post("/freelance/projet/"+id+"/autocomplete_user", {search: val});
    //     promiseReturn.then(function(d) {
    //       parentContainer.find(".autocomplete-proposition").html("");
    //       parentContainer.find(".autocomplete-content").addClass('open');
    //       let data = JSON.parse(d);
    //       if (data.results == 0) {
    //         parentContainer.find(".autocomplete-proposition").html("<div class='proposal-info-notselectable'>Aucun membre ne correspond</div>");
    //       }
    //       else {
    //         let dataResults = data.results;
    //         let idAlreadySelected = [];
    //         parentContainer.find('.autocomplete-result .proposal').each(function(index, elm) {
    //           idAlreadySelected.push($(elm).data('id'));
    //         });
    //       dataResults.forEach(function(v) {
    //         if ($.inArray( v.id, idAlreadySelected ) == -1)
    //           if (typeof userShared != 'undefined')
    //           {
    //             if ($.inArray( v.id, userShared ) == -1)
    //               parentContainer.find(".autocomplete-proposition").append("<div class='proposal-container'><div class='proposal' data-id='"+v.id+"'><div class='proposal-content'><div class='proposal-img'>"+v.img+"</div><div class='proposal-name'>"+v.name+"</div><div class='proposal-delete'></div></div><div class='proposal-role'>"+v.role+"</div></div></div>");
    //           }
    //           else {
    //             parentContainer.find(".autocomplete-proposition").append("<div class='proposal-container'><div class='proposal' data-id='"+v.id+"'><div class='proposal-content'><div class='proposal-img'>"+v.img+"</div><div class='proposal-name'>"+v.name+"</div><div class='proposal-delete'></div></div><div class='proposal-role'>"+v.role+"</div></div></div>");
    //           }
    //       });
    //     }
    //     });
    // });

    $(document).on("click", ".js-popin-sendinfoforsigned", function () {
        let firstname = $(".js-infosigned-firstname").val() || null;
        let lastname = $(".js-infosigned-lastname").val() || null;
        let phonenumber = $(".js-infosigned-phonenumber").val() || null;
        let idProject = $(this).data("idproject");
        let idDocument = $(this).data("iddocument");
        let idFolder = $(this).data("idfolder");

        let promiseReturn = $.post("/freelance/projet/" + idProject + "/fichiers/dossier/" + idFolder + "/fichier/" + idDocument + "/signer", { firstname: firstname, lastname: lastname, phoneNumber: phonenumber });
        promiseReturn.then(function (val) {
            let v = JSON.parse(val);
            if (v.status === "failed") {
                $(".popin-signed .errors-signed").html(v.message);
                $(".popin-signed .errors-signed").show();
            } else {
                let iframe = '<iframe class="iframe-sign-reco" src="' + v.webUrlYousign + "/procedure/sign?members=" + v.member + '&signatureUi=/signature_uis/0bb82042-515f-4ee1-821a-8c66e55508de"></iframe>';
                $(".popin-signed").hide();
                $(".popin").hide();
                $(".backdrop").hide();
                $(".iframe-sign").addClass("show");
                $(".iframe-sign").html(iframe);
            }
        });

        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    // A conserver ?

    // $(document).on('click', '.js-show-toggle-usernotsoan', function() {
    //   if ($(this).text() === 'mon client est sur Soan') {
    //     $('.user-onsoan').show();
    //     $('.user-notonsoan').hide();
    //     $(this).text("mon client n'est pas sur Soan");
    //   }
    //   else {
    //     $('.user-onsoan').hide();
    //     $('.user-notonsoan').show();
    //     $(this).text('mon client est sur Soan');
    //   }
    // });

    //Autocomplete company
    $(document).on("input", ".autocomplete-company", function () {
        let name = $(this).val();
        if (name.length > 1) {
            let response = $.post("/freelance/mon_profil/find_company", { search: name });
            response.then(function (value) {
                $(".autocomplete-rslt").html(" ");
                $(".autocomplete-rslt").addClass("active");
                let objParsed = JSON.parse(value);
                let tabRslt = objParsed.results;
                $(tabRslt).each(function (k, v) {
                    let parsedResult = v;
                    if (parsedResult.address && parsedResult.siret)
                        $(".autocomplete-rslt").append(
                            "<div class='companyrslt' data-id='" +
                                parsedResult.id +
                                "' data-email='" +
                                parsedResult.email +
                                "' data-society='" +
                                parsedResult.name +
                                "' data-headquarteraddr='" +
                                parsedResult.address +
                                ", " +
                                parsedResult.postalCode +
                                " " +
                                parsedResult.city +
                                "'><div class='d-flex justify-content-between flex-column'><span>" +
                                parsedResult.name +
                                "</span> <span><span class='mr-2'>Siret : " +
                                parsedResult.siret +
                                " </span> <span>Adresse : " +
                                parsedResult.address +
                                "</span></span></div></div>"
                        );
                    else if (!parsedResult.address && parsedResult.siret) {
                        $(".autocomplete-rslt").append("<div class='companyrslt' data-id='" + parsedResult.id + "' data-email='" + parsedResult.email + "' data-society='" + parsedResult.name + "' data-headquarteraddr='" + parsedResult.address + ", " + parsedResult.postalCode + " " + parsedResult.city + "'><div class='d-flex justify-content-between flex-column'><span>" + parsedResult.name + "</span> <span><span class='mr-2'>Siret : " + parsedResult.siret + "</span></span></div></div>");
                    } else if (parsedResult.address && !parsedResult.siret) {
                        $(".autocomplete-rslt").append("<div class='companyrslt' data-id='" + parsedResult.id + "' data-email='" + parsedResult.email + "' data-society='" + parsedResult.name + "' data-headquarteraddr='" + parsedResult.address + ", " + parsedResult.postalCode + " " + parsedResult.city + "'><div class='d-flex justify-content-between flex-column'><span>" + parsedResult.name + "</span> <span><span>Adresse : " + parsedResult.address + "</span></span></div></div>");
                    } else {
                        $(".autocomplete-rslt").append("<div class='companyrslt' data-id='" + parsedResult.id + "' data-email='" + parsedResult.email + "' data-society='" + parsedResult.name + "' data-headquarteraddr='" + parsedResult.address + ", " + parsedResult.postalCode + " " + parsedResult.city + "'><div class='d-flex justify-content-between flex-column'><span>" + parsedResult.name + "</span> <span></span></div></div>");
                    }
                });
            });
        } else {
            $(".autocomplete-rslt").html(" ");
            $(".autocomplete-rslt").removeClass("active");
        }
    });

    $(document).on("click", ".companyrslt", function () {
        let addr = $(this).data("headquarteraddr");
        let name = $(this).data("society");

        $(".js-createbills-companyaddress").val(addr);
        $(".js-createbills-companyname").val(name);
        $(".autocomplete-rslt").html(" ");
        $(".autocomplete-rslt").removeClass("active");
    });

    $(document).on("click", ".js-create-efacture", function () {
        $(".show-create-efacture").show();
        $(".show-create-efacture .step-content-1").addClass("active");
        $(".step-content-2").removeClass("active");
    });

    $(document).on("click", ".js-show-laststep", function () {
        let idEfacture = $(this).data("idefacture");
        let idProject = $(this).data("idproject");
        let promiseReturn = $.post("/freelance/projet/" + idProject + "/efacture/" + idEfacture + "/enregistrer");
        promiseReturn.then(function (val) {
            let v = JSON.parse(val);
            if (v.status === "failed") {
                $(".errors").html(v.message);
                $(".errors").show();
            } else {
                $(".show-laststep").show();
                $(".show-create-efacture").hide();
                $(".remove-facture-link").addClass("js-return-addbills");
            }
        });

        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    $(document).on("click", ".return-choice-sender", function () {
        $(".show-create-efacture").hide();
        $(".show-create-efacture .step-content-1").removeClass("active");
        $(".step-content-2").addClass("active");
    });

    $(".folder").droppable({
        classes: {
            "ui-droppable-active": "ui-state-active",
            "ui-droppable-hover": "ui-state-hover",
        },
        accept: ".doc",
        drop: function (event, ui) {
            let that = $(this);
            let nextFolderId = that.data("nextfolderid");
            let fileId = ui.draggable.data("fileid");
            let folderId = ui.draggable.data("folderid");
            let projectId = that.data("projectid");
            let promiseReturn = $.post("/freelance/projet/" + projectId + "/fichiers/dossier/" + folderId + "/fichier/" + fileId + "/vers/" + nextFolderId);
            promiseReturn.then(function (val) {
                let v = JSON.parse(val);
                if (v.status === "success") {
                    ui.draggable.remove();
                    $(".errors").hide();
                    $(".errors").html("");
                    let nbrFile = that.find(".js-nbr-files").text();
                    that.find(".js-nbr-files").text(parseInt(nbrFile) + 1);
                } else {
                    window.location.reload();
                }
            });
        },
    });

    $(".doc").draggable({ revert: "invalid" });

    $(document).on("click", ".js-return-addbills", function () {
        $(".show-add-efacture").hide();
        $(".step-choice-bills").addClass("active");
        $(".show-laststep").removeClass("active");
        $(".show-laststep").hide();
        $(".errors").hide();
        $(".errors").html("");
    });

    $(document).on("click", ".js-open-nostep", function () {
        $(".recovery-step-content-1").removeClass("active");
        $(".recovery-step-content-nostep").show();
    });

    $(document).on("input", "#new_quotation", function () {
        let file = $(this)[0].files;
        $(".recovery-invoice-filename").text(file[0].name);
        $(".recovery-invoice").show();
        $(".recovery-step-content-2").removeClass("active");
        $(".recovery-step-content-21").addClass("active");
        $(".errors").hide();
        $(".errors").html("");
        let name = file[0].name;
        let n = name.replace(/[^a-zA-Z\d.]/, "_");
        $(".js-delete-file-recovery").attr("data-rmfile", n);
        let iRand = Math.round(new Date() / 1000);
        $(".js-delete-file-recovery").attr("data-id", iRand);
        let target = "";
        if (file) {
            let reader = new FileReader();
            reader.onload = function (e) {
                target = e.target.result;
            };
            reader.readAsDataURL(file[0]); // convert to base64 string
        }
        let fileList = "<div class='d-flex justify-content-between align-items-center mt-3 mb-3 elm-" + iRand + "'><div class='d-flex align-items-center'><img src='/build/images/mini_folder.svg' height='30' width='30' /><div class='mr-2 ml-2'>" + name + "</div></div><div data-name='" + name + "' data-test='test' class='js-gotostep-2'>x</div></div>";
        $(".js-quotationfile-list").append(fileList);
    });

    //Envoie du recouvrement
    $(document).on("click", ".js-send-quotation-step2", function () {
        let isChecked = $("#accept-nocontest").is(":checked");
        let isCheckedAccept = $("#accept-cond").is(":checked");
        if (isChecked && isCheckedAccept) {
            if ($(this).hasClass("active")) {
                let invoiceFile = $(".js-quotation-file")[0].files;
                let invoiceId = $(".js-quotation-fileid").attr("data-id") || null;
                let rmfile = $(".js-quotation-fileid").attr("data-rmfile") || null;
                let jobTitle = $(".js-quotation-function").val();
                let dueAmount = $(".js-quotation-amount").val();
                let dueDate = formatDateSeparators($(".js-quotation-duedate").val());
                let customerCountry = $(".js-quotation-country").val();
                let siren = $(".js-quotation-siren").val();
                let projectId = $(this).data("projectid");
                let companyName = $(".company-name").html();
                let companyAddress = $(".company-addr").html();
                let companyCountry = $(".company-country").data("country");

                $(".loader").fadeIn();
                let formData = new FormData();
                let submitMethod = "POST";
                if (invoiceId) {
                    formData.append("invoiceId", invoiceId);
                }
                if (invoiceFile != undefined) {
                    formData.append("invoiceFile", invoiceFile[0]);
                }
                formData.append("jobTitle", jobTitle);
                formData.append("dueAmount", dueAmount);
                formData.append("dueDate", dueDate);
                formData.append("customerCountry", customerCountry);
                formData.append("siren", siren);
                formData.append("companyName", companyName);
                formData.append("companyAddress", companyAddress);
                formData.append("companyCountry", companyCountry);
                if (rmfile) {
                    formData.append("rmfile", rmfile);
                }
                let quotationId = $(".js-quotation-send").data("quotationid");
                if (quotationId) {
                    formData.append("quotation_id", quotationId);
                }
                let paymentId = $(".js-quotation-send").data("paymentid");
                if (paymentId) {
                    formData.append("payment_id", paymentId);
                }

                $.ajax({
                    url: "/freelance/projet/" + projectId + "/recouvrements/nouveau/form",
                    data: formData,
                    processData: false,
                    contentType: false,
                    type: submitMethod,

                    success: function (val) {
                        let v = JSON.parse(val);
                        if (v.status === "success") {
                            $(".recovery-step-content-21 .js-send-quotation-step2").addClass("active");
                            $(".loader").fadeOut("1000");
                            let dueAmount = parseInt(v.dueAmount) / 100;
                            let totalAmount = parseInt(v.totalAmount) / 100;
                            let isAccepted = v.isAccepted;
                            if (isAccepted) {
                                $(".js-amount").text(dueAmount.toFixed(2) + " € ");
                                $(".js-fees").text(v.fees.toFixed(2) + " %");
                                $(".js-amounttotal").text(totalAmount.toFixed(2) + " €");
                                $(".js-quotation-isPossible").text(translation("Votre devis Euler Hermes"));
                                $(".js-quotation-send").attr("data-quotationid", v.quotationId);
                                $(".js-quotation-info-job").text(jobTitle);
                                $(".js-quotation-info-amountdue").text(dueAmount.toFixed(2) + " € ");
                                $(".js-quotation-info-date").text(dueDate);
                                $(".js-quotation-info-country").text(customerCountry);
                                $(".js-quotation-info-siren").text(siren);
                                $(".js-quotation-info-amountget").text(dueAmount.toFixed(2) + " € ");
                                $(".js-quotation-info-comission").text(v.fees.toFixed(2) + " %");
                                $(".js-quotation-info-total").text(totalAmount.toFixed(2) + " €");
                                $(".recovery-step-content-21").removeClass("active");
                                $(".recovery-step-content-3").addClass("active");
                                $(".errors").text("");
                                $(".errors").hide();
                            } else {
                                $(".errors").show();
                                $(".erros").html(translation("Le recouvrement de cette facture"));
                                $(".soancontainer-center").animate({ scrollTop: $(".errors").offset().top - 100 }, 1000);
                            }
                        } else {
                            $(".loader").fadeOut("1000");
                            $(".errors").show();
                            $(".errors").html(v.message);
                            $('[class^="recovery-step-content-"]').removeClass("active");
                            let step = v.step;
                            if (v.step == 0) window.location.href = "/freelance/projet/" + projectid + "/recouvrements";
                            $(".recovery-step-content-" + step).addClass("active");
                            $(".soancontainer-center").animate({ scrollTop: $(".errors").offset().top - 100 }, 1000);
                        }
                    },
                    error: function (err) {
                        window.location.reload();
                    },
                });
            } else {
                $(".errors").text(translation("Veuillez valider le SIREN"));
                $(".errors").show();
                $(".soancontainer-center").animate({ scrollTop: $(".errors").offset().top - 100 }, 1000);
            }
        } else {
            $(".errors").text(translation("Veuillez valider les cases à cocher"));
            $(".errors").show();
            $(".soancontainer-center").animate({ scrollTop: $(".errors").offset().top - 100 }, 1000);
        }
    });

    $(document).on("click", ".js-gotostep-5", function () {
        $('[class^="recovery-step-content-"]').removeClass("active");
        $(".recovery-step-content-5").addClass("active");
        let id = $(this).data("id");
        $(".js-remove-justifile[data-id=" + id + "]").trigger("click");
        $(".js-remove-thisfile[data-id=" + id + "]").trigger("click");
    });

    $(document).on("click", ".js-gotostep-2", function () {
        $('[class^="recovery-step-content-"]').removeClass("active");
        $(".recovery-step-content-2").addClass("active");
        $(".js-delete-file-recovery").trigger("click");
    });

    $(document).on("click", ".js-gotostep-21", function () {
        $('[class^="recovery-step-content-"]').removeClass("active");
        $(".recovery-step-content-21").addClass("active");
    });

    $(document).on("click", ".js-gotostep-3", function () {
        $('[class^="recovery-step-content-"]').removeClass("active");
        $(".recovery-step-content-3").addClass("active");
    });

    $(document).on("click", ".js-quotation-gotostep4", function () {
        var requiredStep = [];
        requiredStep.push(document.querySelectorAll("input:invalid"));
        requiredStep.push(document.querySelectorAll("textarea:invalid"));
        requiredStep.push(document.querySelectorAll(".hiddeninput"));
        var tabrequired = [];
        var test = $.each(requiredStep, function (key, value) {
            $.each(value, function (k, val) {
                if ($(val).is(":visible")) tabrequired.push(val);
            });
        });
        if (tabrequired.length === 0) {
            $(".errors").html("").removeClass("active");
            $(".errors").hide();
            $(this).parents(".active").next().addClass("active");
            $(this).parents(".active").removeClass("active");
        } else {
            $(".errors").show();
            $(".errors").html(translation("Veuillez remplir les champs" + " "));
            $.each(tabrequired, function (key, val) {
                $(".errors")
                    .addClass("active")
                    .append("<div class='error'>" + val.dataset.field + "</div>");
            });
            $(".soancontainer-center").animate({ scrollTop: $(".errors").offset().top - 100 }, 1000);
        }
    });

    $(document).on("click", ".js-quotation-gotostep6", function () {
        var requiredStep = [];
        requiredStep.push(document.querySelectorAll("input:invalid"));
        requiredStep.push(document.querySelectorAll("textarea:invalid"));
        requiredStep.push(document.querySelectorAll(".hiddeninput"));
        var tabrequired = [];
        var test = $.each(requiredStep, function (key, value) {
            $.each(value, function (k, val) {
                if ($(val).is(":visible")) tabrequired.push(val);
            });
        });
        if (tabrequired.length === 0) {
            $(".errors").html("").removeClass("active");
            $(".errors").hide();
            if ($(".js-quotation-additional-list").children().length > 0) {
                $(this).parents(".active").next().addClass("active");
                $(this).parents(".active").removeClass("active");
            } else {
                $(".errors").show();
                $(".errors").html(translation("Veuillez ajouter un justificatif"));
                $(".soancontainer-center").animate({ scrollTop: $(".errors").offset().top - 100 }, 1000);
            }
        } else {
            $(".errors").show();
            $(".errors").html(translation("Veuillez remplir les champs" + " "));
            $.each(tabrequired, function (key, val) {
                $(".errors")
                    .addClass("active")
                    .append("<div class='error'>" + val.dataset.field + "</div>");
            });
            $(".soancontainer-center").animate({ scrollTop: $(".errors").offset().top - 100 }, 1000);
        }
    });

    $(document).on("click", ".js-quotation-gostep5", function () {
        var requiredStep = [];
        requiredStep.push(document.querySelectorAll("input:invalid"));
        requiredStep.push(document.querySelectorAll("textarea:invalid"));
        requiredStep.push(document.querySelectorAll(".hiddeninput"));
        var tabrequired = [];
        var test = $.each(requiredStep, function (key, value) {
            $.each(value, function (k, val) {
                if ($(val).is(":visible")) tabrequired.push(val);
            });
        });
        if (tabrequired.length === 0) {
            if ($(this).hasClass("ignorestep")) {
                $(":input:visible").val("");
            }
            $(this).parents(".active").next().addClass("active");
            $(this).parents(".active").removeClass("active");
            $(".errors").html("").removeClass("active");
            $(".errors").hide();
            let salutation = $("input[name='salutation']:checked").val();
            let firstname = $(".js-quotation-firstname").val();
            let lastname = $(".js-quotation-lastname").val();
            let phonenumber = $(".js-quotation-phonenumber").val();
            let bank = $(".js-quotation-bank").val();
            let ref = $(".js-quotation-ref").val();
            let email = $(".js-quotation-businessemail").val();
            $(".js-quotation-info-firstname").text(firstname);
            $(".js-quotation-info-lastname").text(lastname);
            $(".js-quotation-info-name").text(firstname + " " + lastname);
            $(".js-quotation-info-phonenumber").text(phonenumber);
            $(".js-quotation-info-bank").text(bank);
            $(".js-quotation-info-ref").text(ref);
            $(".js-quotation-info-businessemail").text(email);
        } else {
            $(".errors").show();
            $(".errors").html(translation("Veuillez remplir les champs" + " "));
            $.each(tabrequired, function (key, val) {
                $(".errors")
                    .addClass("active")
                    .append("<div class='error'>" + val.dataset.field + "</div>");
            });
            $(".soancontainer-center").animate({ scrollTop: $(".errors").offset().top - 100 }, 1000);
        }
    });

    //on click supprimer errors
    //js-add-billselement

    $(document).on("click", ".js-quotation-gostep21", function () {
        $(".recovery-step-content-6").removeClass("active");
        $(".recovery-step-content-21").addClass("active");
    });

    $(document).on("click", ".js-quotation-gostep4", function () {
        $(".recovery-step-content-6").removeClass("active");
        $(".recovery-step-content-4 ").addClass("active");
    });

    $(document).on("click", ".next-folder", function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        $(this).find(".next-menu").removeClass("active");
        $(this).children(".next-menu").addClass("active");
        $(this).parent().children().removeClass("active");
        $(this).addClass("active");
        let id = $(this).data("id");
        $(".js-return-link-menu").attr("data-id", id);
        let txt = $(this).children(".next-menu").find(".folder-title").text();
        $(".js-return-link-name").text(txt);
        $(this).parent().next(".document-inline-container").hide();
    });

    $(document).on("click", ".folder-menu", function () {
        $(".folder-quotation").removeClass("active");
        $(this).parents(".folder-quotation").addClass("active");
        let txt = $(this).children(".folder-menu-inline").find(".folder-title").text();
        $(".js-return-link-name").text(txt);
        let id = $(this).data("id");
        $(".js-return-link-menu").attr("data-id", id);
        $(".folder-menu").removeClass("active");
        $(this).addClass("active");
    });

    $(document).on("click", ".js-return-link-menu", function () {
        let id = $(this).attr("data-id");
        let name = $(this).attr("data-name");
        if (id == 0) {
            $(".folder-quotation").addClass("active");
            $(".folder-menu").removeClass("active");
            $(".next-menu").removeClass("active");
            $(".folder-menu-inline > .folder-title").show();
            $(".js-return-link-name").text(translation("Dossier principal"));
            $(".next-folder").addClass("active");
            $(".folder-quotation").children(".document-inline-container").removeAttr("style");
        } else if (id > 0) {
            let i = parseInt(id) - 10;
            if (i > 0) {
                let elm = $(".level-" + i)
                    .children(".next-menu.active")
                    .children();
                let txt = elm.children(".folder-title").text();
                $(".js-return-link-name").text(txt);
                $(this).attr("data-id", i);
                $(".level-" + id).addClass("active");
                $(".level-" + id)
                    .children()
                    .removeClass("active");
                $(".level-" + id)
                    .children(".document-inline-container")
                    .removeAttr("style");
                $(".level-" + i)
                    .children(".document-inline-container")
                    .removeAttr("style");
            } else {
                let elm = $(".level-" + id).parent(".folder-menu.active");
                let txt = elm.children(".folder-menu-inline").find(".folder-title").text();
                $(".js-return-link-name").text(txt);
                $(".level-" + id).addClass("active");
                $(".level-" + id)
                    .children(".next-menu")
                    .removeClass("active");
                $(this).attr("data-id", 0);
                $(".folder-quotation").children(".document-inline-container").removeAttr("style");
            }
        }
    });

    $(document).on("click", ".document-inline", function () {
        if ($(".popin-add-doc").hasClass("unique-doc")) {
            $(".document-inline").removeClass("selected");
            $(this).toggleClass("selected");
        } else {
            $(this).toggleClass("selected");
        }
    });

    if ($(".recovery-step-content-3")) {
        let step = getUrlParameter("step");
        if (step === "3") {
            $(".recovery-step-content-1").removeClass("active");
            $(".recovery-step-content-3").addClass("active");
        } else if (step === "2") {
            $(".recovery-step-content-1").removeClass("active");
            $(".recovery-step-content-2").addClass("active");
        } else if (step === "21") {
            $(".recovery-step-content-1").removeClass("active");
            $(".recovery-step-content-21").addClass("active");
        } else if (step === "s") {
            $(".recovery-step-content-1").removeClass("active");
            $(".recovery-step-content-8").addClass("active");
            let projectid = $(".js-quotation-send").data("projectid");
            let quotationid = getUrlParameter("quotation_id");

            let promiseReturn = $.post("/freelance/projet/" + projectid + "/recouvrements/nouveau/validation/" + quotationid + "/refreshPricing");
            promiseReturn.then(function (val) {
                let v = JSON.parse(val);
                if (v.status === "success") {
                    let iframe = '<iframe class="iframe-sign-reco" src="' + v.webUrlYousign + "/procedure/sign?members=" + v.member + "&signatureUi=" + v.uis + '"></iframe>';
                    $(".recovery-step-content-1").removeClass("active");
                    $(".recovery-step-content-8").addClass("active");
                    $(".contract-container").html(iframe);
                    $(".successfees").text(v.successFees / 100);
                } else {
                    if (v.message) {
                        $(".errors").show();
                        $(".errors").html(v.message);
                        $('[class^="recovery-step-content-"]').removeClass("active");
                        let step = v.step;
                        if (v.step == 0) window.location.href = "/freelance/projet/" + projectid + "/recouvrements";
                        $(".recovery-step-content-" + step).addClass("active");
                        $(".soancontainer-center").animate({ scrollTop: $(".errors").offset().top - 100 }, 1000);
                    }
                }
            });

            promiseReturn.catch(function (err) {
                window.location.reload();
            });
        } else if (step === "4") {
            $(".recovery-step-content-1").removeClass("active");
            $(".recovery-step-content-4").addClass("active");
        }
        let paymentId = getUrlParameter("payment_id");
        if (paymentId) {
            $(".recovery-step-content-1").removeClass("active");
            $(".recovery-step-content-21").addClass("active");
            $(".js-quotation-send").data("paymentid", paymentId);
        }
        let quotationId = getUrlParameter("quotation_id");
        if (quotationId) {
            $(".js-quotation-send").data("quotationid", quotationId);
        }
    }

    $(document).on("click", ".js-popin-add-doc", function () {
        if ($(".document-inline.selected")) {
            if ($(".popin-add-doc").hasClass("unique-doc")) {
                let id = $(".document-inline.selected").attr("data-docid");
                let idprop = $(".document-inline.selected").prop("data-docid");
                let docid = $(".document-inline.selected").data("docid");
                let name = $(".document-inline.selected").data("docname");
                let target = "";
                $(".popin-add-doc").hide();
                $(".popin").hide();
                $(".backdrop").hide();
                $(".js-quotation-fileid").attr("data-id", docid);
                $(".recovery-invoice-filename").text(name);
                $(".js-delete-file-recovery").attr("data-rmfile", docid);
                $(".js-delete-file-recovery").attr("data-id", docid);
                $(".recovery-step-content-2").removeClass("active");
                $(".recovery-step-content-21").addClass("active");
                let docurl = $(".document-inline.selected").data("docurl");
                $(".document-quotation-link").attr("href", docurl);
                let fileList = "<div class='d-flex justify-content-between align-items-center mt-3 mb-3 elm-" + id + "'><div class='d-flex align-items-center'><a href='" + docurl + "'><img src='/build/images/mini_folder.svg' height='30' width='30' /></a><a href='" + docurl + "'><div class='mr-2 ml-2'>" + name + "</div></a></div><div data-name='" + name + "' class='js-gotostep-2'>x</div></div>";
                $(".js-quotationfile-list").append(fileList);
            } else {
                let id = $(".document-inline.selected").data("docid");
                $(".popin-add-doc").hide();
                $(".popin").hide();
                $(".backdrop").hide();
                let arr = $(".js-quotation-additionalsoanfiles").attr("data-id") || "";
                $(".document-inline.selected").each(function (idx, elm) {
                    arr = arr + "," + $(elm).data("docid");
                });
                $(".js-quotation-additionalsoanfiles").attr("data-id", arr);
                $(".document-inline.selected").each(function (idx, elm) {
                    let additionalFiles =
                        "<div class='d-flex justify-content-between align-items-center mt-3 mb-3'><div class='d-flex align-items-center'><a href='" + $(elm).data("docurl") + "'><img src='/build/images/mini_folder.svg' height='30' width='30' /></a><a href='" + $(elm).data("docurl") + "'><div class='mr-2 ml-2'>" + $(elm).data("docname") + "</div></a></div><div data-name='" + $(elm).data("docname") + "' data-id='" + $(elm).data("docid") + "' class='js-remove-justifile'>x</div></div>";
                    $(".js-quotation-additional-list").append(additionalFiles);
                    let fileList =
                        "<div class='d-flex justify-content-between align-items-center mt-3 mb-3 elm-" + id + "'><div class='d-flex align-items-center'><a href='" + $(elm).data("docurl") + "'><img src='/build/images/mini_folder.svg' height='30' width='30' /></a><a href='" + $(elm).data("docurl") + "'><div class='mr-2 ml-2'>" + $(elm).data("docname") + "</div></a></div><div data-name='" + $(elm).data("docname") + "' data-id='" + $(elm).data("docid") + "' class='js-gotostep-5'>x</div></div>";
                    $(".js-quotationfile-list").append(fileList);
                });
            }
        }
    });

    $(document).on("input", ".js-quotation-siren", function () {
        $(".js-send-quotation-step2").removeClass("active");
        let country = $(".js-quotation-country option:checked").val();
        let projectid = $(this).data("projectid");
        let siret = $(this).data("siret");
        let siretTrim = siret.toString().trim();
        let siren = $(this).val();
        if (siren.length == 11) {
            if (siren.trim() == siretTrim.substring(0, 9)) {
                $(".errors").show();
                $(".errors").html(translation("Vous ne pouvez pas lancer un recouvrement") + ".<br />" + translation("Vérifiez le numéro SIREN"));
                $(".soancontainer-center").animate({ scrollTop: $(".errors").offset().top - 100 }, 1000);
            } else {
                $(".loader").fadeIn();
                let promiseReturn = $.post("/freelance/projet/" + projectid + "/recouvrements/nouveau/form/siren", { customerCountry: country, siren: siren });
                promiseReturn.then(function (val) {
                    $(".loader").fadeOut();
                    let v = JSON.parse(val);
                    if (v.status === "success") {
                        let popin =
                            `<div class="popin removeonhide" style="display: block;">
          <div class="popin-wrap popin-siren" style="display: block">
          <div class="popin-close removeonclick"></div>
          <div class="popin-title">` +
                            translation("S'agit-il de cette entreprise ?") +
                            `</div>
          <div class="popin-content">
            <div class=""><span class="company-name">${v.company.results[0].company.legalData.companyName}</span><br /><br /><span class="company-addr">${v.company.results[0].company.address.streetName} ${v.company.results[0].company.address.town} ${v.company.results[0].company.address.postCodes[0].postCodeValue}</span><span class="company-country" data-country="${v.company.results[0].company.address.countryCode}"></span></div>
          </div>
          <div class="popin-actions">
            <button type="button" class="btn btn-primary js-popin-close w-auto js-validate-siren" data-currentTarget=".popin-siren">` +
                            translation("Confirmer") +
                            `</button>
          </div>
          </div>
          </div>`;
                        $("body").append(popin);
                        $(".backdrop").show();
                        if (!$(".infocompany").length) {
                            let info = `<div class="d-none infocompany"><span class="company-name">${v.company.results[0].company.legalData.companyName}</span><br /><br /><span class="company-addr">${v.company.results[0].company.address.streetName} ${v.company.results[0].company.address.town} ${v.company.results[0].company.address.postCodes[0].postCodeValue}</span><span class="company-country" data-country="${v.company.results[0].company.address.countryCode}"></span></div>`;
                            $("body").append(info);
                        } else {
                            $(".company-name").html(v.company.results[0].company.legalData.companyName);
                            $(".company-addr").html(v.company.results[0].company.address.streetName + " " + v.company.results[0].company.address.town + " " + v.company.results[0].company.address.postCodes[0].postCodeValue);
                            $(".company-country").attr("data-country", v.company.results[0].company.address.countryCode);
                        }
                    } else {
                        if (v.message) {
                            $(".errors").show();
                            $(".errors").html(v.message);
                            $(".soancontainer-center").animate({ scrollTop: $(".errors").offset().top - 100 }, 1000);
                        }
                    }
                });
            }
        } else {
            $(".recovery-step-content-21 .js-send-quotation-step2").removeClass("active");
        }
    });

    $(document).on("click", ".removeonclick", function () {
        $(".popin.removeonhide").remove();
    });

    $(document).on("click", ".js-validate-siren", function () {
        $(".js-send-quotation-step2").addClass("active");
        $(".popin.removeonhide").remove();
    });

    var arrayFiles = [];

    $(document).on("click", ".js-quotation-send", function () {
        let isChecked = $("#im-responsable").is(":checked");
        let isChecked3 = $("#accept-transmission").is(":checked");
        if (isChecked && isChecked3) {
            $(".errors").hide();
            $(".errors").html("");
            $(".loader").fadeIn();
            let projectid = $(this).data("projectid");
            let quotationid = $(this).data("quotationid");
            let salutation = $("input[name='salutation']:checked").val();
            let firstname = $(".js-quotation-firstname").val();
            let lastname = $(".js-quotation-lastname").val();
            let phoneNumber = $(".js-quotation-phonenumber").val();
            let businessEmail = $(".js-quotation-businessemail").val();
            let additionalFiles = arrayFiles;
            let additionalSoanfiles = $(".js-quotation-additionalsoanfiles").attr("data-id") || null;
            let rmfile = $(".js-quotation-additionalsoanfiles").attr("data-rmfile") || null;
            let data = new FormData();
            $(additionalFiles).each(function (i, elm) {
                data.append("additionalFiles[]", elm.file);
            });
            data.append("salutation", salutation);
            data.append("firstname", firstname);
            data.append("lastname", lastname);
            data.append("phoneNumber", phoneNumber);
            data.append("businessEmail", businessEmail);
            if (additionalSoanfiles && additionalSoanfiles != undefined) data.append("additionalSoanfiles", additionalSoanfiles);
            if (rmfile) data.append("rmfiles", rmfile);

            let submitMethod = "POST";
            $.ajax({
                url: "/freelance/projet/" + projectid + "/recouvrements/nouveau/validation/" + quotationid,
                data: data,
                processData: false,
                contentType: false,
                type: submitMethod,
                success: function (val) {
                    $(".loader").fadeOut();
                    let v = JSON.parse(val);
                    if (v.status === "success") {
                        let iframe = '<iframe class="iframe-sign-reco" src="' + v.webUrlYousign + "/procedure/sign?members=" + v.member + "&signatureUi=" + v.uis + '"></iframe>';
                        $(".recovery-step-content-7").removeClass("active");
                        $(".recovery-step-content-8").addClass("active");
                        $(".contract-container").html(iframe);
                        $(".successfees").text(v.successFees / 100);
                    } else {
                        $(".errors").show();
                        $(".errors").html(v.message);
                        $('[class^="recovery-step-content-"]').removeClass("active");
                        let step = v.step;
                        if (v.step == 0) window.location.href = "/freelance/projet/" + projectid + "/recouvrements";
                        $(".recovery-step-content-" + step).addClass("active");
                        $(".soancontainer-center").animate({ scrollTop: $(".errors").offset().top - 100 }, 1000);
                    }
                },
                error: function (err) {
                    window.location.reload();
                },
            });
        } else {
            $(".errors").show();
            $(".errors").html(translation("Veuillez accepter tous les champs"));
            $(".soancontainer-center").animate({ scrollTop: $(".errors").offset().top - 100 }, 1000);
        }
    });

    $(document).on("input", ".js-quotation-justif-file", function () {
        let files = $(this)[0].files;
        let iRand = Math.round(new Date() / 1000);
        arrayFiles.push({ id: iRand, file: files[0] });
        $(".js-quotation-additionalfiles").val(arrayFiles);
        let additionalFiles = "<div class='d-flex justify-content-between align-items-center mt-3 mb-3'><div class='d-flex align-items-center'><img src='/build/images/mini_folder.svg' height='30' width='30' /><div class='mr-2 ml-2'>" + files[0].name + "</div></div><div data-name='" + files[0].name + "' data-id='" + iRand + "' class='js-remove-justifile'>x</div></div>";
        $(".js-quotation-additional-list").append(additionalFiles);
        $(".errors").hide();
        $(".errors").html("");
        var target = "";
        if (files) {
            let reader = new FileReader();
            reader.onload = function (e) {
                target = e.target.result;
            };
            reader.readAsDataURL(files[0]); // convert to base64 string
        }

        let fileList = "<div class='d-flex justify-content-between align-items-center mt-3 mb-3 elm-" + iRand + "'><div class='d-flex align-items-center'><img src='/build/images/mini_folder.svg' height='30' width='30' /><div class='mr-2 ml-2'>" + files[0].name + "</div></div><div data-name='" + files[0].name + "' data-id='" + iRand + "' class='js-gotostep-5'>x</div></div>";
        $(".js-quotationfile-list").append(fileList);
    });

    $(document).on("click", ".js-remove-justifile", function () {
        let id = $(this).data("id");
        $(this).parent().remove();
        arrayFiles = $.grep(arrayFiles, function (e) {
            return e.id != id;
        });
        $(".js-quotation-additionalfiles").val(arrayFiles);
        let arrayofrmid = $(".js-quotation-additionalsoanfiles").attr("data-rmfile") || "";
        let name = $(this).data("name");
        let n = name.replace(/[^a-zA-Z\d.]/, "_");
        arrayofrmid = arrayofrmid + "," + n;
        if (arrayofrmid.charAt(0) == ",") arrayofrmid = arrayofrmid.substring(1);
        $(".js-quotation-additionalsoanfiles").attr("data-rmfile", arrayofrmid);
        $(".js-quotationfile-list .elm-" + id).remove();

        //add
        let arrayofid = $(".js-quotation-additionalsoanfiles").attr("data-id");
        if (arrayofid) {
            arrayofid = arrayofid.replace(id, "");
            arrayofid = arrayofid.replace(",,", ",");
            if (arrayofid.charAt(0) == ",") arrayofid = arrayofid.substring(1);
            $(".js-quotation-additionalsoanfiles").attr("data-id", arrayofid);
        }
    });

    $(document).on("click", ".js-remove-thisfile", function () {
        let id = $(this).data("id");
        let name = $(this).data("name");
        let rmfile = $(this).data("rmfile");
        $(this).parent().remove();
        let idArray = $(".js-quotation-additionalsoanfiles").attr("data-id");
        let arrayofid = $(".js-quotation-additionalsoanfiles").attr("data-id");
        let arrayofrmid = $(".js-quotation-additionalsoanfiles").attr("data-rmfile") || "";
        arrayofid = arrayofid.replace(id, "");
        arrayofid = arrayofid.replace(",,", ",");
        if (arrayofid.charAt(0) == ",") arrayofid = arrayofid.substring(1);
        $(".js-quotation-additionalsoanfiles").attr("data-id", arrayofid);
        $(".js-quotationfile-list .elm-" + id).remove();

        // if (rmfile) {
        let n = name.replace(/[^a-zA-Z\d.]/, "_");
        arrayofrmid = arrayofrmid + "," + n;
        if (arrayofrmid.charAt(0) == ",") arrayofrmid = arrayofrmid.substring(1);
        $(".js-quotation-additionalsoanfiles").attr("data-rmfile", arrayofrmid);
        // }
    });

    $(document).on("click", ".js-delete-file-recovery", function () {
        $(".recovery-invoice").hide();
        $(".recovery-step-content-2").addClass("active");
        $(".recovery-step-content-21").removeClass("active");
        $("#new_quotation").val("");
        $(".custom-file-label").html("Facture depuis mon<br />ordinateur maggle");
        $(".js-quotation-fileid").attr("data-id", "");
        let rmfile = $(this).attr("data-rmfile");
        if (rmfile) $(".js-quotation-fileid").attr("data-rmfile", rmfile);
        let id = $(this).data("id");
        $(".js-quotationfile-list .elm-" + id).remove();
    });

    //openpopin add doc
    $(document).on("click", ".js-open-add-doc", function () {
        $(".popin-add-doc").show();
        $(".backdrop").show();
        $(".popin").show();
        $(".popin-add-doc").addClass("unique-doc");
        $(".document-inline").removeClass("active");
        $(".document-inline").removeClass("selected");
        $(".document-inline-container").removeAttr("style");

        $(".folder-quotation").addClass("active");
        $(".folder-menu").removeClass("active");
        $(".next-menu").removeClass("active");
        $(".folder-menu-inline > .folder-title").show();
        $(".js-return-link-name").text(translation("Dossier principal"));
        $(".next-folder").addClass("active");
        $(".folder-quotation").children(".document-inline-container").removeAttr("style");
    });

    $(document).on("click", ".js-open-addmultidoc", function () {
        $(".popin-add-doc").show();
        $(".backdrop").show();
        $(".popin").show();
        $(".popin-add-doc").removeClass("unique-doc");
        $(".document-inline").removeClass("active");
        $(".document-inline").removeClass("selected");
        $(".document-inline-container").removeAttr("style");

        $(".folder-quotation").addClass("active");
        $(".folder-menu").removeClass("active");
        $(".next-menu").removeClass("active");
        $(".folder-menu-inline > .folder-title").show();
        $(".js-return-link-name").text(translation("Dossier principal"));
        $(".next-folder").addClass("active");
        $(".folder-quotation").children(".document-inline-container").removeAttr("style");
    });

    if ($(".js-quotation-showmsg").length > 0) {
        let msg = getUrlParameter("message");
        if (msg) {
            let msgDecode = decodeURI(msg);
            $(".msg-flash").append('<div class="container alert alert-success" role="alert">' + msgDecode + "</div>");
            setTimeout(function () {
                $(".msg-flash").html("");
            }, 5000);
        }
        //Check the status of quotation every 30sec
        if ($(".quotation-in-progress").length > 0) {
            let quotationId = $(".quotation-in-progress").attr("data-id");
            if (quotationId) {
                setTimeout(checkStatus, 5000, quotationId, 8);
            }
        }
    }

    $(document).on("click", ".trigger-file-quotation", function () {
        $(".js-quotation-file").trigger("click");
    });

    $(document).on("click", ".trigger-file-quotation-justif", function () {
        $(".js-quotation-justif-file").trigger("click");
    });

    // A conserver ?

    // $(document).on('click', '.next-step-link-check-bills', function() {
    //   let bills = $('.js-select-bills option:selected');
    //   if (bills.length > 0) {
    //     var requiredStep = [];
    //     requiredStep.push(document.querySelectorAll('input:invalid'));
    //     requiredStep.push(document.querySelectorAll('textarea:invalid'));
    //     requiredStep.push(document.querySelectorAll('.hiddeninput'));
    //     var tabrequired = [];
    //     var test = $.each(requiredStep, function(key, value) {
    //       $.each(value, function(k, val) {
    //         if ($(val).is(':visible'))
    //         tabrequired.push(val);
    //       });

    //     });
    //     if (tabrequired.length === 0)
    //     {
    //       if ($(this).hasClass("ignorestep"))
    //         {
    //           $(':input:visible').val('');
    //         }
    //       $(this).parents('.active').next().addClass('active');
    //       $(this).parents('.active').removeClass('active');
    //       $(".errors").html("").removeClass('active');
    //       $('.errors').hide();
    //     } else {
    //       $('.errors').show();
    //       $(".errors").html(translation("Veuillez remplir les champs" + " "));
    //       $.each(tabrequired, function(key, val) {
    //         $(".errors").addClass("active").append("<div class='error'>"+val.dataset.field+"</div>");
    //       });
    //       $('.soancontainer').animate({ scrollTop: $(".errors").offset().top }, 1000);
    //     }
    //     $('.efacture-error').remove();
    //   }
    //   else {
    //     $(".errors").addClass("active").append("<div class='error efacture-error'>Veuillez sélectionner une facture ou une e-facture</div>");
    //     $('.soancontainer').animate({ scrollTop: $(".errors").offset().top }, 1000);
    //   }
    // });

    $(document).on("click", ".js-edit-paymentsubject", function () {
        $(this).parent().next(".payment-subject-modif").addClass("active");
        $(this).parent(".payment-subject-display").addClass("d-none");
    });

    $(document).on("click", ".js-change-payment-subject", function () {
        $(this).addClass("st-no-btn-disabled");
        let projectId = $(this).attr("data-projectid");
        let paymentId = $(this).attr("data-paymentid");
        let that = $(this);
        let subject = that.prev(".payment-subject-input").val();
        let promiseReturn = $.post("/freelance/projet/" + projectId + "/paiement/" + paymentId + "/changement_sujet", { subject: subject });
        promiseReturn.then((val) => {
            let v = JSON.parse(val);
            if (v.status === "success") {
                $(".payment-subject-modif").removeClass("active");
                $(".payment-subject-display").removeClass("d-none");
                if (subject.length > 24) {
                    that.parent(".payment-subject-modif")
                        .prev(".payment-subject-display")
                        .children(".subject-payment")
                        .html(subject.substr(0, 24) + "...");
                } else {
                    that.parent(".payment-subject-modif").prev(".payment-subject-display").children(".subject-payment").html(subject);
                }
                $(this).removeClass("st-no-btn-disabled");
            } else {
                if (v.message) {
                    $(".errors").show();
                    $(".errors").html(v.message);
                    $(".soancontainer-center").animate({ scrollTop: $(".errors").offset().top - 100 }, 1000);
                    $(this).removeClass("st-no-btn-disabled");
                }
            }
        });

        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    $(document).on("click", ".js-openaccordeon", function () {
        let target = $(this).attr("data-target");
        $(target).slideToggle();
    });

    $(document).on("click", "#company_infos_submit.sireterror", function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        $(".soancontainer").animate({ scrollTop: $(".errors-siret").offset().top - 100 }, 1000);
    });

    $(document).on("blur", "#company_infos_thSiret", function () {
        let thSiret = $(this).val();
        if (thSiret.length < 14 && thSiret.length > 0 && $(this).hasClass(".siret")) {
            $(".errors-siret").show();
            $(".errors-siret").html("<span class='cerror'>" + translation("Votre SIRET est trop court") + "</span>");
            $("#company_infos_submit").addClass("sireterror");
        } else {
            $("#company_infos_submit").removeClass("sireterror");
        }
    });

    $(document).on("click", ".js-send-search-user", function () {
        let val = $(".js-search-user").val();
        let start = $(".js-search-start").val();
        let end = $(".js-search-end").val();
        let yopmail = $("#no-yopmail").is(":checked");
        let removed = $("#no-removed-user").is(":checked");
        let promiseReturn = $.post("/freelance/admin/showuser_stats_request", { search: val, start: start, end: end, yopmail: yopmail, removed: removed });
        promiseReturn.then(function (val) {
            let v = JSON.parse(val);
            if (v.status === "success") {
                let dataSet = v.data;
                if (dataSet) {
                    let getIdData;
                    $("#table-info-stats").DataTable({
                        scrollX: true,
                        destroy: true,
                        data: dataSet,
                        columns: [
                            {
                                title: "inscription",
                                data: "subscriptionDate",
                                defaultContent: " ",
                            },
                            {
                                title: translation("Email"),
                                data: "email",
                                defaultContent: " ",
                            },
                            {
                                title: translation("Nom"),
                                data: "name",
                                defaultContent: " ",
                            },
                            {
                                title: translation("Téléphone"),
                                data: "phoneNumber",
                                defaultContent: " ",
                            },
                            {
                                title: translation("Entreprise"),
                                data: "businessName",
                                defaultContent: "-",
                            },
                            {
                                title: translation("Abonnement"),
                                data: "offer",
                                defaultContent: "Free trial",
                            },
                            {
                                title: "Free Trial",
                                data: "id",
                                defaultContent: " ",
                            },
                            {
                                title: translation("Promo"),
                                data: "id",
                                defaultContent: " ",
                            },
                            {
                                title: translation("Infos"),
                                data: "id",
                                defaultContent: " ",
                            },
                            {
                                title: "se connecter",
                                data: "email",
                                defaultContent: " ",
                            },
                            {
                                title: "White List Prélèvement",
                                data: "isWhiteListed",
                                defaultContent: " ",
                            },
                        ],
                        buttons: ["copy", "excel", "pdf"],
                        columnDefs: [
                            {
                                // The `data` parameter refers to the data for the cell (defined by the
                                // `data` option, which defaults to the column being worked with, in
                                // this case `data: 6`.
                                render: function (data, type, row) {
                                    //return "<span class='js-promo-add-userId js-openpopin' data-id='" + data + "' data-target='.add-user-nb-payment'>" + translation("Ajouter des paiements") + "</span>"
                                    getIdData = data;
                                    return "<span class='js-promo-add-userId js-openpopin' data-id='" + data + "' data-target='.add-user-nb-days'>Ajouter des jours</span>";
                                },
                                targets: 6,
                            },
                            {
                                render: function (data, type, row) {
                                    return "<span class='js-promo-add-userId js-openpopin' data-id='" + data + "' data-target='.add-user-promo'>" + translation("Appliquer promo") + "</span>";
                                },
                                targets: 7,
                            },
                            {
                                render: function (data, type, row) {
                                    return "<span class='js-show-userinfos js-openpopin' data-id='" + data + "' data-target='.infos-user-content'>" + translation("En savoir plus") + "</span>";
                                },
                                targets: 8,
                            },
                            {
                                render: function (data, type, row) {
                                    return "<span class='js-connect-as cpointer' data-email='" + data + "'>connexion</span>";
                                },
                                targets: 9,
                            },
                            {
                                render: function (data,type, row) {
                                    return "<span class='js-white-list cpointer' data-id='" + getIdData + "'>"+ data +"</span>";
                                },
                                targets: 10,
                            },
                        ],
                        language: {
                            decimal: "",
                            emptyTable: translation("Aucune donnée"),
                            info: "_START_ à _END_ sur _TOTAL_ entrées",
                            infoEmpty: "0 à 0 sur 0 entrée",
                            infoFiltered: "(filtered from _MAX_ total entries)",
                            infoPostFix: "",
                            thousands: ",",
                            lengthMenu: translation("Afficher les entrées") + " _MENU_",
                            loadingRecords: translation("Chargement..."),
                            processing: translation("En cours de traitement..."),
                            search: "",
                            searchPlaceholder: translation("Recherche"),
                            zeroRecords: translation("Aucune correspondance"),
                            paginate: {
                                first: "Premier",
                                last: "Dernier",
                                next: translation("Suivant"),
                                previous: translation("Prédédent"),
                            },
                            aria: {
                                sortAscending: ": activate to sort column ascending",
                                sortDescending: ": activate to sort column descending",
                            },
                        },
                    });
                }
            } else {
                if (v.message) {
                    $(".errors").show();
                    $(".errors").html(v.message);
                    $(".soancontainer").animate({ scrollTop: $(".errors").offset().top - 100 }, 1000);
                }
            }
        });

        promiseReturn.catch(function (err) {
            //window.location.reload();
        });
    });

    $(document).on("click", ".js-connect-as", function () {
        window.location.href = "/freelance/admin/showuser_stats/connect_as?email=" + $(this).attr("data-email");
    });

    $(document).on("click", ".js-white-list", function () {
        let id =  $(this).attr("data-id");
        let field = $(this);
        let promiseReturn = $.post("/freelance/admin/showuser_stats/whiteList", { id: id })
        promiseReturn.then(function (val) {
            let v = JSON.parse(val);
            if (v.status === "success") {
                if (field.text() == "vrai") {
                    field.text("faux");
                }
                else {
                    field.text("vrai")
                }
            }
        });
    });

    $(document).on("click", ".js-show-option-searchuser", function () {
        $(".js-option-searchuser").slideToggle();
    });

    function numberWithSpaces(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    $(document).on("click", ".js-show-userinfos", function () {
        let id = $(this).data("id");
        let promiseReturn = $.post("/freelance/admin/showuser_stats_request_more", { id: id });
        promiseReturn.then(function (val) {
            let v = JSON.parse(val);
            if (v.status === "success") {
                let colorOffer;
                if (v.data[0].offer === "Liberté") {
                    colorOffer = "st-libertyOfferDashboard";
                } else if (v.data[0].offer === "Business") {
                    colorOffer = "st-businessOfferDashboard";
                } else {
                    colorOffer = "st-premiumOfferDashboard";
                }
                var subscritpionDate = new Date(v.data[0].subscriptionDate.toString().replace(" ", "T"));
                var connectionDate = v.data[0].lastConnection ? new Date(v.data[0].lastConnection.toString().replace(" ", "T")) : subscritpionDate;
                var htmlSubDate = String(subscritpionDate.getDate()).padStart(2, "0") + "/" + String(subscritpionDate.getMonth() + 1).padStart(2, "0") + "/" + subscritpionDate.getFullYear();
                var nextDebit = v.data[0].nextDebit ? new Date(v.data[0].nextDebit.toString().replace(" ", "T")) : subscritpionDate;
                var htmlCoDate = String(connectionDate.getDate()).padStart(2, "0") + "/" + String(connectionDate.getMonth() + 1).padStart(2, "0") + "/" + connectionDate.getFullYear();
                var htmlNextDebit = String(nextDebit.getDate()).padStart(2, "0") + "/" + String(nextDebit.getMonth() + 1).padStart(2, "0") + "/" + nextDebit.getFullYear();
                let html = "<h3>" + v.data[0].name + ' "<i>' + v.data[0].usedName + '</i>" (' + v.data[0].id + ")" + "</h3>";
                html += "<p><b>" + translation("Email") + ": </b>" + v.data[0].email + "</p>";
                html += "<p><b>" + translation("Téléphone") + ": </b>" + v.data[0].phoneNumber + "</p>";
                html += "<p><b>" + translation("Date d'inscription") + ": </b>" + htmlSubDate + "</p>";
                html += "<p><b>" + "Date de renouvellement" + ": </b>" + htmlNextDebit + "</p>";
                html += "<p><b>" + translation("Dernière connexion") + ": </b>" + htmlCoDate + "</p>";
                html += "<p><b>" + translation("Abonnement") + ": </b>" + "<span class='" + colorOffer + "'>" + (v.data[0].offer ? v.data[0].offer + " " + (v.data[0].frequence == "YEARLY" ? translation("annuel") : translation("mensuel")) : "free trial ") + "</span></p>";
                html += (v.data[0].usedPromo ? "<p><b>" + translation("Code promo utilisé") + ": </b>" + v.data[0].usedPromo : "") + "</p>";
                html += (v.data[0].businessName ? "<p><b>" + translation("Entreprise") + ": </b>" + v.data[0].businessName + "</p>" + "<p><b>" + translation("type") + ": </b>" + v.data[0].businessTypeName + "</p>" + "<p><b>" + translation("Représentant légal") + ": </b>" + v.data[0].lrName : "") + "</p>";
                html += "<p><b>" + translation("Profil vérifié") + ": </b>" + (v.data[0].isActive == "1" ? translation("OUI") : translation("NON")) + "</p>";
                html += "<p><b>" + translation("Nombre de demande de paiement cumulé") + ": </b>" + v.data[0].nbPayment + "</p>";
                if (v.data[0].nbPaymentDso > 0) {
                    html += "<p><b>" + translation("DSO moyen") + ": </b>" + numberWithSpaces(v.data[0].dso) + " " + translation("jours") + " (" + translation("sur") + " " + numberWithSpaces(v.data[0].nbPaymentDso) + " " + translation("paiements") + ")</p>";
                }
                html += "<br /><br /><div class='st-blocTable'>";

                $(".js-infos-user-content").html(html);
                createTableOfLastsDate(v.data[1]);
                //createTableOfLastsDate(v.data[2]);
            } else {
                if (v.message) {
                    $(".errors").show();
                    $(".errors").html(v.message);
                    $(".soancontainer").animate({ scrollTop: $(".errors").offset().top - 100 }, 1000);
                }
            }
        });
        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    // Function which create a table of 12 lasts months with resume user's activity
    function createTableOfLastsDate(datas) {
        let today = new Date();
        let year = today.getFullYear();
        let month = today.getMonth() + 1;
        let monthConverted = "0" + month;
        let arrayLastMonths = [];
        if (month < 10) {
            arrayLastMonths.push(year + "-" + monthConverted);
        } else {
            arrayLastMonths.push(year + "-" + month);
        }
        let table = $("<table>");
        let tr1 = $("<tr>", { class: "js-tr1" });
        $(tr1).append("<td class='st-firstTable'>" + "12 derniers mois") + "</td>";
        let tr2 = $("<tr>", { class: "js-tr2" });
        $(tr2).append("<td class='st-firstTable'>" + "Nombre de demande par mois") + "</td>";
        let tr3 = $("<tr>", { class: "js-tr3" });
        $(tr3).append("<td class='st-firstTable'>" + "Flux d'affaire cumulé par mois") + "</td>";
        let tr4 = $("<tr>", { class: "js-tr4" });
        $(tr4).append("<td class='st-firstTable'>" + "Paiement fournisseur cumulé") + "</td>";
        let tr5 = $("<tr>", { class: "js-tr5" });
        $(tr5).append("<td class='st-firstTable'>" + "Nombre Paiement fournisseur") + "</td>";

        // We fill a array with 13 values
        for (let i = 0; i < 12; i++) {
            if (month > 1) {
                month += -1;
                if (month < 10) {
                    monthConverted = "0" + month;
                    arrayLastMonths.unshift(year + "-" + monthConverted);
                } else {
                    arrayLastMonths.unshift(year + "-" + month);
                }
            } else {
                month = 12;
                year += -1;
                arrayLastMonths.unshift(year + "-" + month);
            }
        }

        // We iterate this array and fill td with values of data
        for (let element of arrayLastMonths) {
            console.log(element);
            $(tr1).append(createStatsLine(element, element));
            $(tr2).append(createStatsLine(element in datas && "nbMonthlyPayment" in datas[element] ? datas[element]["nbMonthlyPayment"] : null, "0"));
            $(tr3).append(createStatsLine(element in datas && "sumMonthlyPayment" in datas[element] ? Math.round(datas[element]["sumMonthlyPayment"]) / 100 + " €" : null, "0 €"));
            $(tr4).append(createStatsLine(element in datas && "nbMonthlySupplierPayment" in datas[element] ? datas[element]["nbMonthlySupplierPayment"] : null, "0"));
            $(tr5).append(createStatsLine(element in datas && "sumMonthlySupplierPayment" in datas[element] ? Math.round(datas[element]["sumMonthlySupplierPayment"]) / 100 + " €" : null, "0 €"));
            // $(tr2).append(createStatsLine(datas[element]['nbMonthlyPayment'] ?? null, "0"));
            // $(tr3).append(createStatsLine('sumMonthlyPayment' in datas[element] ? ((Math.round(datas[element]['sumMonthlyPayment']) / 100) + " €") : null, "0 €"));
            // $(tr4).append(createStatsLine(datas[element]['nbMonthlySupplierPayment'] ?? null, "0"));
            // $(tr5).append(createStatsLine('sumMonthlySupplierPayment' in datas[element] ? ((Math.round(datas[element]['sumMonthlySupplierPayment']) / 100) + " €") : null, "0 €"));
        }

        $(table).append(tr1, tr2, tr3, tr4, tr5);
        $(".st-blocTable").append(table);
    }

    function createStatsLine(c1, def) {
        let td1 = $("<td>");
        console.log(c1);
        if (c1) {
            td1.text(c1).addClass("st-activityColor");
        } else {
            td1.text(def);
        }
        return td1;
    }

    $(document).on("click", ".js-promo-add-userId", function () {
        let id = $(this).data("id");
        $(".js-btn-popin-promo").data("idUser", id);
        $(".js-btn-popin-nb-payment").data("idUser", id);
        $(".js-btn-popin-nb-days").data("idUser", id);
    });

    $(document).on("click", ".js-btn-popin-promo", function () {
        let idUser = $(this).data("idUser");
        let idPromo = $(".popin-select-promo").val();
        let promiseReturn = $.post("/freelance/admin/add_user_promo", { idUser: idUser, idPromo: idPromo });
        promiseReturn.then(function (val) {
            let v = JSON.parse(val);
            if (v.status === "success") {
                let valueSelect = $(".popin-select-promo").val();
                let tagCodePromo = $("#select-promo" + valueSelect).data("thtag");
                let getSubscriptionPromo = $("#js-changingSubscription" + idUser).text();
                let newSubscription = getSubscriptionPromo.split(" ")[0];

                $("#js-changingSubscription" + idUser).text(newSubscription + " (" + tagCodePromo + ")");
                $(".popin, .add-user-promo, .backdrop").hide();
                $(".msg-flash").text(translation("Le code promo a bien été ajouté !")).addClass("container alert alert-success").css("color", "white");
                setTimeout(function () {
                    $(".msg-flash").text("").css("color", "initial").removeClass("container alert alert-success");
                }, 6000);
            } else {
                if (v.message) {
                    $(".errors").show();
                    $(".errors").html(v.message);
                    $(".soancontainer").animate({ scrollTop: $(".errors").offset().top - 100 }, 1000);
                }
            }
        });
        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    $(document).on("click", ".js-btn-popin-liberty", function () {
        let idUser = $('.js-btn-popin-promo').data("idUser");
        let promiseReturn = $.post("/freelance/admin/closeSubscription", { idUser: idUser });
        promiseReturn.then(function (val) {
            let v = JSON.parse(val);
            $(".popin, .add-user-promo, .backdrop").hide();
            if (v.status === "success") {
           //     $(".popin, .add-user-promo, .backdrop").hide();
                $(".msg-flash").text(translation("Utilisateur passé en liberté.")).addClass("container alert alert-success").css("color", "white");
                setTimeout(function () {
                    $(".msg-flash").text("").css("color", "initial").removeClass("container alert alert-success");
                }, 6000);
            } else {
                if (v.message) {
                    $(".errors").show();
                    $(".errors").html(v.message);
                    $(".soancontainer").animate({ scrollTop: $(".errors").offset().top - 100 }, 1000);
                }
            }
        });
        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    $(document).on("click", ".js-btn-popin-nb-payment", function () {
        let idUser = $(this).data("idUser");
        let nbPayment = $(".popin-select-nb-payment").val();
        let promiseReturn = $.post("/freelance/admin/add_more_payment", { id: idUser, nbPayment: nbPayment });
        promiseReturn.then(function (val) {
            let v = JSON.parse(val);
            if (v.status === "success") {
                let valueSelect = parseInt($(".popin-select-nb-payment").val());
                let dataNumberPayment = $("#js-changingNumberPayment" + idUser).text();
                let value1 = dataNumberPayment.split("/")[0];
                let value2 = dataNumberPayment.split("/")[1];
                let newValueNumberPayment = parseInt(value1) + valueSelect;

                $("#js-changingNumberPayment" + idUser).text(newValueNumberPayment + "/" + value2);
                $(".popin, .add-user-nb-payment, .backdrop").hide();
                $(".msg-flash").text(translation("Le nombre de paiement a bien été ajouté !")).addClass("container alert alert-success").css("color", "white");
                setTimeout(function () {
                    $(".msg-flash").text("").css("color", "initial").removeClass("container alert alert-success");
                }, 6000);
            } else {
                if (v.message) {
                    $(".errors").show();
                    $(".errors").html(v.message);
                    $(".soancontainer").animate({ scrollTop: $(".errors").offset().top - 100 }, 1000);
                }
            }
        });
        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    $(document).on("click", ".js-btn-popin-nb-days", function () {
        let idUser = $(this).data("idUser");
        let nbDays = $(".popin-select-nb-days").val();
        let promiseReturn = $.post("/freelance/admin/add_more_days", { id: idUser, nbDays: nbDays });
        promiseReturn.then(function (val) {
            let v = JSON.parse(val);
            if (v.status === "success") {
                let valueSelect = parseInt($(".popin-select-nb-days").val());
                let dataNumberDays = $("#js-changingNumberDays" + idUser).text();
                let value1 = dataNumberDays.split("/")[0];
                let value2 = dataNumberDays.split("/")[1];
                let newValueNumberDays = parseInt(value1) + valueSelect;

                $("#js-changingNumberDays" + idUser).text(newValueNumberDays + "/" + value2);
                $(".popin, .add-user-nb-days, .backdrop").hide();
                $(".msg-flash").text(translation("Le nombre de jours a bien été ajouté !")).addClass("container alert alert-success").css("color", "white");
                setTimeout(function () {
                    $(".msg-flash").text("").css("color", "initial").removeClass("container alert alert-success");
                }, 6000);
            } else {
                if (v.message) {
                    $(".errors").show();
                    $(".errors").html(v.message);
                    $(".soancontainer").animate({ scrollTop: $(".errors").offset().top - 100 }, 1000);
                }
            }
        });
        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    $(document).on("click", ".js-btn-popin-sub", function () {
        let idUser = $(this).data("id-user");
        let sub = $(".popin-select-sub").val();
        let subTag = $(".popin-select-subTag").val();
        let bankId = $(".popin-select-bank").val();
        let promiseReturn = $.post("/freelance/mon_profil/user/" + idUser + "/apply_subscription", { sub: sub, subTag: subTag, bankId: bankId });
        promiseReturn.then(function (val) {
            let v = JSON.parse(val);
            if (v.status === "success") {
                window.location.reload();
            } else {
                if (v.message) {
                    window.location.reload();
                }
            }
        });
    });

    $(document).on("click", ".js-create-sub-promo", function () {
        let tag = $(".promo-tag").val();
        let monthlyPrice = $(".promo-monthly-price").val();
        let yearlyPrice = $(".promo-yearly-price").val();
        let promiseReturn = $.post("/freelance/mon_profil/create_sub_promo", { tag: tag, monthlyPrice: monthlyPrice, yearlyPrice: yearlyPrice });
        promiseReturn.then(function (val) {
            let v = JSON.parse(val);
            if (v.status === "success") {
                window.location.reload();
            } else {
                if (v.message) {
                    $(".errors").show();
                    $(".errors").html(v.message);
                    $(".soancontainer").animate({ scrollTop: $(".errors").offset().top - 100 }, 1000);
                }
            }
        });
        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    $(document).on("click", ".company-to-pay", function () {
        $(".content-to-pay").show();
        $(".content-payed").hide();
        $(".company-to-pay").addClass("active");
        $(".company-payed").removeClass("active");
    });

    $(document).on("click", ".company-payed", function () {
        $(".content-to-pay").hide();
        $(".content-payed").show();
        $(".company-to-pay").removeClass("active");
        $(".company-payed").addClass("active");
    });

    function getDataBillOffline(itemParent) {
        let item = itemParent;
        let price = $(".js-total").text();
        $(".js-to-facility").data("price", price);
        let oldPrice = item.find(".js-oldprice").html() || null;
        let name = item.find(".js-name").html();
        $(".js-recap-name").html(name);
        let reference = item.find(".js-name").data("reference");
    }

    $(function () {
        getDataBillOffline($(".js-payment-data:nth-child(1)"));
        $(document).on("change", '[name="item"]', function () {
            getDataBillOffline($(this).parents(".content-item"));
        });
    });

    // Sur chaque div on ajoute des events clics qui vont simuler le click sur le bouton radio

    $(".js-payment-data").each(function (i, item) {
        let getIdParent = "#" + $(item).attr("id");
        $(getIdParent + " .st-infosPaymentOffline div, " + getIdParent + " .st-atoutSoan, " + getIdParent + " .st-blocPriceOffline").on("click", function () {
            let getIdInput = $(getIdParent + " div:nth-child(1) input").attr("id");
            $('.js-payment-data div:nth-child(1) .d-flex div label[for="' + getIdInput + '"]').trigger("click");
        });
    });

    var clipboard = new ClipboardJS(".copyonclick");

    clipboard.on("success", function (e) {
        e.clearSelection();
        $("popin-payment .msg").text(translation("Information copiée"));
        $("popin-payment .msg").fadeIn(1000);
        setTimeout(popupInPopin, 3000);
    });

    function popupInPopin() {
        $(".popin-payment .msg").fadeOut(1000);
    }

    $(document).on("click", ".js-to-facility", function () {
        $(".popin").show();
        $(".backdrop").show();
        let target = $(this).data("target");
        let reference = $(this).data("reference");
        $(target).show();
        let price = $(this).data("price");
        $(".js-facility-price").html(price);
        $(".js-facility-reference").html(reference);
    });

    // A conserver ?

    // $('.ctc-price').on('click', function() {
    //     let temp = $("<input>");
    //     $("body").append(temp);
    //     let price = $('#price').text();
    //     price = price.slice(0, -1);
    //     temp.val(price).select();
    //     document.execCommand("copy");
    //     $('.copy-price').html(translation('Copié !'));
    //     setTimeout( function() {
    //         $('.copy-price').html(translation('Cliquer pour copier !'));
    //     }, 2000)
    //     temp.remove();
    // });

    // $('.ctc-iban').on('click', function() {
    //   let temp = $("<input>");
    //   $("body").append(temp);
    //   temp.val($('.value-iban').text()).select();
    //   document.execCommand("copy");
    //   $('.copy-iban').html(translation('Copié !'));
    //     setTimeout( function() {
    //         $('.copy-iban').html(translation('Cliquer pour copier !'));
    //     }, 2000)
    //   temp.remove();
    // });

    // $('.ctc-bic').on('click', function() {
    //   let temp = $("<input>");
    //   $("body").append(temp);
    //   temp.val($('#bic').text()).select();
    //   document.execCommand("copy");
    //   $('.copy-bic').html(translation('Copié !'));
    //   setTimeout( function() {
    //       $('.copy-bic').html(translation('Cliquer pour copier !'));
    //   }, 2000)
    //   temp.remove();
    // })
    // $('.ctc-reference').on('click', function() {
    //   let temp = $("<input>");
    //   $("body").append(temp);
    //   temp.val($('#reference').text()).select();
    //   document.execCommand("copy");
    //   $('.copy-reference').html(translation('Copié !'));
    //   setTimeout( function() {
    //       $('.copy-reference').html(translation('Cliquer pour copier !'));
    //   }, 2000)
    //   temp.remove();
    // });

    $(document).on("click", ".js-openshowbills", function () {
        let pdf = $(this).data("pdf");
        window.open(pdf, "_url");
    });

    $(document).on("click", ".js-showcompanyinfos", function () {
        $(".popin").show();
        $(".backdrop").show();
        let target = $(this).data("target");
        $(target).show();
    });
    /**
     * Popin payment v4
     */

    $(document).on("click", ".project-item", function () {
        let id = $(this).data("id");
        let name = $(this).find(".project-name").text();
        $(".js-paymentbystep-sendfacture").data("idproject", id);
        $(".js-autocomplete-project").val(name);
        $(".js-autocomplete-project").attr("data-id", id);
        $(".js-paymentbystep-sendfacture").data("idproject", id);
        $(".autocomplete-dist-project").html("");
        let promiseReturn = $.get(`/freelance/projet/${id}/autocomplete_home`);
        promiseReturn.then(function (val) {
            let v = JSON.parse(val);
            if (v.status === "success") {
                $(".js-autocomplete-dest").data("senders-list", JSON.stringify(v.results));
            }
            $(".next-step-popin-content-step1").css("display", "block");
            $(".js-create-project").css("display", "none");
        });
    });

    $(document).on("click", ".dest-item", function () {
        let id = $(this).data("id");
        let email = $(this).find(".dest-email").text();
        let name = $(this).find(".dest-name").text();
        if (name) {
            $(".js-autocomplete-dest").val(name);
        } else {
            $(".js-autocomplete-dest").val(email);
        }

        $(".js-autocomplete-dest").attr("data-id", "");
        if (id) $(".js-autocomplete-dest").attr("data-id", id);

        let val = $(this).attr("data-info");
        if (val) {
            let v = JSON.parse(val);
            if (v.companyName) {
                $(".bills-society-name").val(v.companyName);
                $(".my-bills-companyname").val(v.companyName);
            }
            if (v.companyAddress) {
                $(".bills-society-addr").val(v.companyAddress);
            }
            if (v.companyPC) {
                $(".bills-society-cp").val(v.companyPC);
            }
            if (v.companyCity) {
                $(".bills-society-city").val(v.companyCity);
            }
            if (v.companyCountry) {
                $(".bills-society-country").val(v.companyCountry);
            }
        }
        $(".autocomplete-dist").html("");
    });

    $(document).on("click", ".proposal", function () {
        let parentContainer = $(this).parents(".autocomplete-container-payment");
        let name = $(this).data("name");
        parentContainer.find(".js-autocomplete-dest").val(name);
        parentContainer.find(".autocomplete-proposition-payment").html("");
        parentContainer.find(".autocomplete-content-payment").removeClass("open");
    });

    $(document).on("click", ".proposal", function () {
        let parentContainer = $(this).parents(".autocomplete-container-payment-project");
        let name = $(this).data("name");
        parentContainer.find(".js-autocomplete-project").val(name);
        parentContainer.find(".autocomplete-proposition-payment-project").html("");
        parentContainer.find(".autocomplete-content-payment-project").removeClass("open");
    });

    $(document).on("click", ".js-paymentbystep-back", function () {
        $(".popin").hide();
        $(".popin-wrap").hide();
        $(".backdrop").removeClass("js-active-sureuback");
        $(".backdrop").hide();
        $(".step-header-1").removeClass("valid");
    });

    $(document).on("click", ".step-menu-facture", function () {
        $(this).addClass("active");
        $(".step-menu-efacture").removeClass("active");
        $(".menu-facture").addClass("active");
        $(".menu-efacture").removeClass("active");
        $(".js-error-message").text("");
        $("#" + step2.idNextBtn).attr("data-importbill", "true");
        step3.isCallAjax = generatePaymentLink;
        $("#" + step3.idNextBtn).addClass("js-paymentbystep-facture-gostep3");
        $("#" + step4.idNextBtn).addClass("js-send-payment-facture");
        $("#" + step4.idNextBtn).removeClass("js-send-payment-efacture");
        step4.isCallAjax = sendPaymentFacture;
        popinPaymentBystep.deleteStep(step3bis1);
        const inputs = $(".menu-facture").find("input.input-front-validation");
        const button = $(".js-submit-efacture");
        checkAllInputs(inputs, button, "whithoutRedOutline");
        step3bis1.isActive = false;
    });

    $(document).on("click", ".step-menu-efacture", function () {
        $(this).addClass("active");
        $(".step-menu-facture").removeClass("active");
        $(".menu-efacture").addClass("active");
        $(".menu-facture").removeClass("active");
        $(".js-error-message").text("");
        $("#" + step2.idNextBtn).removeAttr("data-importbill");
        step3.isCallAjax = paymentByStepEfacture;
        $("#" + step3.idNextBtn).addClass("js-paymentbystep-efacture-gostep3");
        $("#" + step4.idNextBtn).removeClass("js-send-payment-facture");
        $("#" + step4.idNextBtn).addClass("js-send-payment-efacture");
        step4.isCallAjax = sendPaymentEfacture;
        popinPaymentBystep.addStep(step3bis1);
        const inputs = $(".menu-efacture").find("input.input-front-validation");
        const button = $(".js-submit-efacture");
        checkAllInputs(inputs, button, "whithoutRedOutline");
        step3bis1.isActive = true;
    });

    $(document).on("click", ".accordeon-head", function () {
        let that = $(this);
        if (that.hasClass("active")) {
            that.next(".accordeon-content").slideUp();
            that.removeClass("active");
        } else {
            that.next(".accordeon-content").slideDown();
            that.addClass("active");
        }
    });

    $(document).on("change", "#payment_billsaddr", function () {
        let isChecked = $(this).is(":checked");
        if (isChecked) {
            $(".if-bills-addr").slideUp();
        } else {
            $(".if-bills-addr").slideDown();
        }
    });

    $(document).on("change", "#payment_billsdiscount", function () {
        let isChecked = $(this).is(":checked");
        if (isChecked) {
            $(".if-discount").slideDown();
            handleErrorsAfterSubmit(["discount-percent", "discount-days"]);
        } else {
            $(".if-discount").slideUp();
        }
    });

    $(document).on("click", ".js-openpopin-payment-bystep", function () {
        $(".popin").show();
        $(".backdrop").show();
        $(".backdrop").addClass("js-active-sureuback");
        let target = $(this).data("target");
        $(target).show();
    });

    $(document).on("click", ".js-openpopin-efacture", function () {
        $(".popin").show();
        $(".backdrop").show();
        $(".backdrop").addClass("js-active-sureuback");
        let target = $(this).data("target");
        $(target).show();
    });

    $(document).on("click", ".js-active-sureuback", function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        if ($(".popin-sureuback").is(":visible")) {
        } else {
            $(".popin-sureuback").show();
            $(".popin").show();
            $(".backdrop").show();
            $(".popin-payment-bystep").hide();
            $(".popin-edit-payment-date").hide();
        }
    });

    $(document).on("click", ".js-return-payment-bystep", function () {
        $(".popin-sureuback").hide();
        $(".popin-payment-bystep").show();
    });

    $(document).on("click", ".js-return-popin-efacture", function () {
        $(".popin-sureuback").hide();
        $(".popin-efacture").show();
    });

    $(document).on("click", "js-return-efacture", function () {
        $(".popin-sureuback").hide();
        $(".popin-create-efacture").show();
    });

    $(document).on("click", ".js-close-payment-bystep", function () {
        if (window.location.pathname === "/freelance/demande_de_paiement") {
            window.location.href = "/freelance";
        } else {
            window.location.reload();
        }
    });

    $(document).on("click", ".js-show-add-elm", function () {
        const today = new Date();
        let monthNumber = today.getMonth() + 1;
        let monthString = monthNumber > 9 ? monthNumber : "0" + monthNumber.toString();
        $(".popinelement-id").data("id", "");
        $(".bills-elm-title").val("");
        $(".bills-elm-daterea").val(`${today.getDate()}/${monthString}/${today.getFullYear()}`);
        $(".bills-elm-desc").val("");
        $(".bills-elm-unit").val("");
        $(".bills-elm-ht").val("");
        $(".bills-elm-tva").val("");
        $(".elm-total-ttc").text("");
        $(".elm-total-ht").text("");
        $(".add-elm").slideDown();
        $(".elmerror").remove();
        $("input.error").removeClass("error");
        $(".elm-empty").hide();
        $(".js-btn-add-elm").html(translation("Ajouter")).addClass("st-no-btn-disabled");
        inputFrontValidation();
    });

    $(document).on("click", ".js-btn-del-elm", function () {
        $(".bills-elm-title").val("");
        $(".bills-elm-daterea").val("");
        $(".bills-elm-desc").val("");
        $(".bills-elm-unit").val("");
        $(".bills-elm-ht").val("");
        $(".bills-elm-tva").val("");
        $(".elm-total-ttc").val("");
        $(".elm-total-ht").val("");
        $(".add-elm").slideUp();
        if ($(".elm-list").children().length <= 0) $(".elm-empty").show();
    });

    //on change value calc
    $(document).on("input", ".bills-elm-unit, .bills-elm-ht, .bills-elm-tva, .bills-elm-discount, .bills-elm-irpf", function () {
        let unit = $(".bills-elm-unit").val() || null;
        let ht = $(".bills-elm-ht").val() || null;
        let tva = $(".bills-elm-tva").val() || null;
        let discount = $(".bills-elm-discount").val() || null;
        let irpf = $(".bills-elm-irpf").val() || null;

        if (ht && tva) {
            let rsltHt;
            if (!unit) {
                rsltHt = parseFloat(ht.replace(",", "."));
            } else {
                rsltHt = parseFloat(unit.replace(",", ".")) * parseFloat(ht.replace(",", "."));
            }
            if (discount && parseFloat(discount.replace(",", ".")) <= rsltHt) {
                rsltHt -= parseFloat(discount.replace(",", "."));
            } else if (discount && parseFloat(discount.replace(",", ".")) > rsltHt) {
                $(".bills-elm-discount").val(rsltHt);
                rsltHt = 0;
            }
            if (irpf) {
                rsltHt = (1 - irpf / 100) * rsltHt;
            }
            let rsltHtFormat = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(rsltHt);
            $(".elm-total-ht").text(rsltHtFormat);

            let rsltTva = (rsltHt * parseFloat(tva.replace(",", "."))) / 100;
            rsltTva += rsltHt;
            let rsltTvaFormat;

            rsltTvaFormat = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(rsltTva);

            $(".elm-total-ttc").text(rsltTvaFormat);
            $(".elmerrormaxamount").remove();
            $(".popin-step-error").hide();
        } else {
            $(".elm-total-ht").text("");
            $(".elm-total-ttc").text("");
            $(".elmerrormaxamount").remove();
            $(".popin-step-error").hide();
        }
    });

    //delete element bills
    $(document).on("click", ".js-delete-bills-elm", function () {
        $(this).parents(".js-createbills-elements").remove();
        if ($(".elm-list").children().length <= 0) {
            $(".elm-empty").show();
        }
        let discountRate = $(".discount-percent").val();
        let amount = document.getElementsByClassName("billselm-ttc");
        let billsAmount = 0;
        $(amount).each(function (id, elm) {
            let txt = $(elm).text();
            let am = txt.replace("€", "");
            let amo = am.replace(/\s/g, "");
            billsAmount += parseFloat(amo);
        });
        let val = (parseFloat(billsAmount) * discountRate) / 100;
        $(".discount-total").val(val);
        const inputs = $(".menu-efacture").find(".input-front-validation");
        const button = $(".js-paymentbystep-efacture-gostep3");
        checkAllInputs(inputs, button);
    });

    //duplicate element bills
    $(document).on("click", ".js-duplicate-billselm", function () {
        let parents = $(this).parents(".js-createbills-elements");
        let title = parents.find(".billselm-title").html();
        let date = parents.find(".billselm-date").html();
        let description = parents.find(".billselm-desc").html();
        let unit = parents.find(".billselm-unit").html();
        let price = parents.find(".billselm-ht").html();
        let tva = parents.find(".billselm-tva").html();
        let ht = price;
        let ttc = parents.find(".billselm-ttc").html();
        $(".popinelement-id").data("id", "");
        let id = Math.floor(Math.random() * (100000000000 - 1)) + 1;
        let elm =
            "<div class='js-createbills-elements idpopin-" +
            id +
            "'>" +
            "<div class='d-flex justify-content-between align-items-center'><div class='billselm-title'>" +
            title +
            "</div><div class='d-flex '><span class='billselm-ttc'>" +
            ttc +
            "</span> " +
            translation("TTC") +
            "</div><div class='js-duplicate-billselm cpointer'><img src='/build/images/copypaste.svg' alt=''></div><div class='js-edit-billselm-payment ml-3 cpointer' data-id='" +
            id +
            "'><img src='/build/images/icon_edit_gris.svg' /></div><div class='js-delete-bills-elm ml-3 cpointer'><img src='/build/images/icon_cross_blue.svg' /></div></div>" +
            "<div class='billselm-date d-none'>" +
            date +
            "</div><div class='billselm-desc d-none'>" +
            description +
            "</div>" +
            "<div class='d-none'><div><span class='billselm-unit'>" +
            unit +
            "</span><span class='ml-2 mr-2'>x</span><span class='billselm-ht'>" +
            ht +
            "</span><span class='ml-2 mr-2'>+</span>" +
            translation("TVA") +
            " (<span class='billselm-tva'>" +
            tva +
            "</span>%)</div></div>" +
            "</div>";

        if ($(".elm-empty .noelm").length) $(".elm-empty").hide();
        $(".elm-list").append(elm);

        let discountRate = $(".discount-percent").val();
        let amount = document.getElementsByClassName("billselm-ttc");
        let billsAmount = 0;
        $(amount).each(function (id, elm) {
            let txt = $(elm).text();
            let am = txt.replace("€", "");
            let amo = am.replace(/\s/g, "");
            billsAmount += parseFloat(amo);
        });
        let val = (parseFloat(billsAmount) * discountRate) / 100;
        $(".discount-total").val(val);
    });

    //edit element bills
    $(document).on("click", ".js-edit-bills-elm", function () {
        let parents = $(this).parents(".js-createbills-elements");
        let title = parents.find(".billselm-title").html();
        let date = parents.find(".billselm-date").html();
        let desc = parents.find(".billselm-desc").text();
        let unit = parents.find(".billselm-unit").html();
        let price = parents.find(".billselm-ht").html();
        let tva = parents.find(".billselm-tva").html();
        let ht = parseInt(unit) * parseInt(price);
        let ttc = parents.find(".billselm-ttc").html();
        let id = $(this).data("id");
        $(".popinelement-id").data("id", id);
        $(".add-elm").slideDown();
        $(".bills-elm-title").val(title);
        $(".bills-elm-daterea").val(date);
        $(".bills-elm-desc").val(desc);
        $(".bills-elm-unit").val(unit);
        $(".bills-elm-ht").val(price);
        $(".bills-elm-tva").val(tva);
        $(".elm-total-ht").html(ht);
        $(".elm-total-ttc").html(ttc);
    });

    $(document).on("click", ".js-paymentbystep-sendlater", function () {
        $(".popin-step-content-3").addClass("active");
        $(".popin-step-content-2").removeClass("active");
        $(".js-paymentbystep-back-step2").addClass("back-efacture-payment");
        $(".popin-step-error").hide();
        $(".popin-step-error").html("");
        $(".step-header-2").removeClass("active");
        $(".step-header-3").addClass("active");
        $(".js-paymentbystep-sendfacture").addClass("js-send-payment-efacture");
        $(".step-header-2").addClass("valid");
    });
    $(document).on("change", "#payment_billsdiscounted", function () {
        if ($(this).is(":checked")) {
            $(".if-discount-facture").slideDown();
            handleErrorsAfterSubmit([".my-bills-discountrate", ".my-bills-discountlimit"]);
        } else {
            $(".if-discount-facture").slideUp();
        }
    });

    $(document).on("click", ".js-paymentbystep-back-step3", function () {
        $(".recap-content").hide();
        $(".create-bills").show();
    });

    $(document).on("click", ".back-facture", function () {
        $(".popin-step-content-2").addClass("active");
        $(".popin-step-content-3").removeClass("active");
        $(".js-paymentbystep-back-step2").removeClass("back-facture");
        $(".js-paymentbystep-sendfacture").removeClass("js-send-payment-facture");
        $(".step-menu-facture").addClass("active");
        $(".step-menu-efacture").removeClass("active");
        $(".menu-facture").addClass("active");
        $(".menu-efacture").removeClass("active");
        $(".step-header-2").removeClass("valid");
    });

    $(document).on("click", ".back-efacture-payment", function () {
        $(".popin-step-content-2").addClass("active");
        $(".popin-step-content-3").removeClass("active");
        $(".js-disabled").addClass("js-paymentbystep-efacture-gostep3");
        $(".js-disabled").css("cursor", "pointer");
        $(".js-paymentbystep-back-step2").removeClass("back-efacture-payment");
        $(".js-paymentbystep-sendfacture").removeClass("js-send-payment-efacture");
        $(".step-menu-efacture").addClass("active");
        $(".step-menu-facture").removeClass("active");
        $(".menu-efacture").addClass("active");
        $(".menu-facture").removeClass("active");
        $(".step-header-2").removeClass("valid");
        $(".step-header-2").addClass("active");
        $(".step-header-3").removeClass("active");
        $(".recap-content").hide();
        $(".create-bills").show();
    });

    $(document).on("click", ".back-efacture", function () {
        $(".recap-content").hide();
        $(".popin-content").show();
    });

    $(".bills-date-later").focus(function () {
        let errors = $(".popin-step-error");
        if (errors) {
            errors.hide();
            errors.html("");
        }
    });
    $(document).on("click", ".js-input-calendar-logo", function () {
        let errors = $(".popin-step-error");
        if (errors) {
            errors.hide();
            errors.html("");
        }
    });

    $(document).on("click", ".backdrop", function () {
        if ($(".popin-step-content-4").hasClass("active")) {
            window.location.reload();
        }
        $(".js-submit-newbank").removeClass("paying");
        $(".js-submit-newbank").removeClass("transferring");
    });

    $(document).on("click", ".js-show-popin-final", function () {
        $(".popin-error-sendlater").hide();
        $(".popin-error-sendlater").children("div:first").remove();
        $(".popin").animate({ scrollTop: "0px" }, 300);
        let delayedInvoice = null;
        const emailBody = $(".trumbowyg-editor").html();
        const emailObject = $(".js-payment-mail-object").val();
        let div = document.createElement("div");
        div.innerHTML = emailBody;
        let contentWhitoutTag = div.textContent || div.innerText || "";
        if (!$(".js-autocomplete-dest").val() || !$(".bills-subject").val()) {
            $(".popin-error-sendlater").append("<div class='cerror'>" + translation("Veuillez renseigner les champs obligatoires") + "</div>");
            $(".popin-error-sendlater").show();
        } else if (contentWhitoutTag.trim() === "" || !emailObject) {
            $(".popin-error-sendlater").append("<div class='cerror'>" + translation("Un objet et un message sont obligatoires") + "</div>");
            $(".popin-error-sendlater").show();
        } else if (!$("#payment_sendnow").is(":checked") && !$("#payment_sendlater").is(":checked")) {
            $(".popin-error-sendlater").append("<div class='cerror'>" + translation("Veuillez choisir une option") + "/div>");
            $(".popin-error-sendlater").show();
        } else if ($(".js-show-sendlater").is(":checked")) {
            let sendLater = formatDateSeparators($(".js-date-sendlater").val());
            const dateObjectSendLater = new Date(parseInt(sendLater.split("-")[2]), parseInt(sendLater.split("-")[1]) - 1, parseInt(sendLater.split("-")[0]));
            if (dateObjectSendLater <= new Date()) {
                $(".popin-error-sendlater").append('<div class="cerror">' + translation("date ultérieure") + "</div>");
                $(".popin-error-sendlater").show();
            } else {
                delayedInvoice = formatDateSeparators($(".js-date-sendlater").val());
            }
        } else {
            $(".loader-popin").fadeIn(200);
            let projectId = $(this).data("projectid");
            let billsUser = $(".js-autocomplete-dest").data("id") || $(".js-autocomplete-dest").val();
            let billsSubject = $(".bills-subject").val();
            let efactureId = $(this).attr("data-idefacture");
            let promiseReturn = $.post("/freelance/projet/" + projectId + "/nouveau_paiement/efacture/" + efactureId, { emailObject: emailObject, emailBody: emailBody, sender: billsUser, delayedInvoice: delayedInvoice, subject: billsSubject });
            promiseReturn.then(function (val) {
                let v = JSON.parse(val);
                $(".loader-popin").fadeOut(200);
                if (v.status === "failed") {
                    $(".popin-error-sendlater").append(`<div class="cerror">${v.message}</div>`);
                    $(".popin-error-sendlater").show();
                } else {
                    $(".popin-send-later").hide();
                    $(".popin-send-relaunch").show();
                    $(".popin-send-relaunch .js-bills-relaunch").data("paymentid", v.paymentId);
                }
            });
            promiseReturn.catch(function (err) {
                window.location.reload();
            });
        }
    });

    $(document).on("click", ".js-bills-relaunch", function () {
        $(".loader-popin").fadeIn(200);
        $(".popin").css("overflow", "hidden");
        let paymentId = $(this).data("paymentid");
        let projectId = $(this).data("projectid");
        let beforeDeadline = 1;
        let afterDeadline = 3;
        let promiseReturn = $.post("/freelance/projet/" + projectId + "/paiement/" + paymentId + "/creation_relances", { beforeDeadline: beforeDeadline, afterDeadline: afterDeadline });
        promiseReturn.then(function (val) {
            let v = JSON.parse(val);
            if (v.status === "failed") {
                $(".popin-step-error").html(v.message);
                $(".popin-step-error").show();
                $(".loader-popin").fadeOut(200);
                $(".popin").css("overflow", "auto");
            } else {
                window.location.href = v.url;
            }
        });

        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    $(document).on("click", ".step-header-2", function () {
        if ($(".step-header-1").hasClass("valid")) {
            $(".js-disabled").addClass("js-paymentbystep-efacture-gostep3");
            $(".js-disabled").css("cursor", "pointer");
            $("[class^=popin-step-content-]").removeClass("active");
            $(".popin-step-content-2").addClass("active");
            $('[class^="step-header-"]').removeClass("active");
            $(this).addClass("active");
        }
    });

    $(document).on("mouseenter", ".js-tooltips-element", function () {
        $(this).next(".js-tooltips-content").show();
        $(this).next(".js-tooltips-content-top").show();
    });

    $(document).on("mouseleave", ".js-tooltips-element", function () {
        $(this).next(".js-tooltips-content").hide();
        $(this).next(".js-tooltips-content-top").hide();
    });

    $(document).on("input blur", ".my-bills-discountrate, .my-bills-amount", function () {
        let discountRate = $(".my-bills-discountrate").val();
        let billsAmount = $(".my-bills-amount").val();
        let val = (parseFloat(billsAmount) * discountRate) / 100;
        val = Math.round(val * 100) / 100;
        $(".my-bills-discount-total").val(val);
    });

    $(document).on("input", ".discount-percent", function () {
        let discountRate = $(".discount-percent").val();
        let amount = document.getElementsByClassName("billselm-ttc");
        let billsAmount = 0;
        $(amount).each(function (id, elm) {
            let txt = $(elm).text();
            let am = txt.replace("€", "");
            let amo = am.replace(/\s/g, "");
            billsAmount += parseFloat(amo);
        });
        let val = (parseFloat(billsAmount) * discountRate) / 100;
        $(".discount-total").val(val);
    });

    $(document).on("keyup", ".discount-percent", function () {
        if ($(".discount-percent").val() > 100) {
            $(".discount-percent").val(100);
        }
        let discountRate = $(".discount-percent").val();
        let amount = document.getElementsByClassName("billselm-ttc");
        let billsAmount = 0;
        $(amount).each(function (id, elm) {
            let txt = $(elm).text();
            let am = txt.replace("€", "");
            let amo = am.replace(/\s/g, "");
            billsAmount += parseFloat(amo);
        });
        let val = (parseFloat(billsAmount) * discountRate) / 100;
        $(".discount-total").val(val);
    });

    $(document).on("keyup", ".my-bills-discountrate", function () {
        if ($(".my-bills-discountrate").val() > 100) {
            $(".my-bills-discountrate").val(100);
        }
    });
    $(document).on("keyup", ".bills-penality", function () {
        if ($(".bills-penality").val() > 100) {
            $(".bills-penality").val(100);
        }
    });

    $(document).on("click", ".js-tooltips-element", function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
    });

    $(document).on("click", ".js-btn-create-efacture", function (e) {
        $(e.target).addClass("st-no-btn-disabled");
        let idProject = $(this).data("idproject");
        let idEfacture = $(".js-btn-create-preview").data("idefacture");

        let promiseReturn = $.post("/freelance/projet/" + idProject + "/efacture/" + idEfacture + "/enregistrer");
        promiseReturn.then(function (val) {
            $(e.target).addClass("st-no-btn-disabled");
            let v = JSON.parse(val);
            if (v.status === "failed") {
                $(".popin-step-error").html(v.message);
                $(".popin-step-error").show();
                $(".popin-content").animate({ scrollTop: $(".popin-step-error").offset().top }, 1000);
            } else {
                $(".dl-new-bills").attr("href", v.pdf);
                $(".js-show-popin-final").attr("data-idefacture", idEfacture);
                $(".popin-bills-saved").show();
                $(".popin-payment-bystep").hide();
                $(".recap-content").hide();
                $(".backdrop").removeClass("js-active-sureuback");
                $(".popin-close").addClass("popin-close-reload");
                $(".backdrop").on("click", function () {
                    window.location.reload();
                });
            }
        });

        promiseReturn.catch(function (err) {
            window.location.reload();
        });
    });

    const manageDueDate = (classDate, classDelay) => {
        const day = $(classDate).val().split("/")[0];
        const month = $(classDate).val().split("/")[1];
        const year = $(classDate).val().split("/")[2];
        const datePaymentEdited = new Date(year, month, day);
        datePaymentEdited.setDate(datePaymentEdited.getDate() + parseInt($(classDelay).val()));
        $("#js-payment-mail-date").text(`${datePaymentEdited.getDate()}/${("0" + datePaymentEdited.getMonth()).slice(-2)}/${datePaymentEdited.getFullYear()}`);
    };

    $(document).on("click", ".js-show-popin-sendlater", function () {
        let sender = $(".js-createbills-user").val();
        $(".popin-send-later").show();
        $(".popin-bills-saved").hide();
        manageDueDate(".bills-date-send", ".bills-delay");
        let elementsSum = [];
        document.querySelectorAll(".billselm-ttc").forEach((elem) => {
            elementsSum.push(parseFloat(elem.innerText.replace("€", "").replace(/\s/, "").replace(",", ".")));
        });
        const totalSum = Number(elementsSum.reduce((a, b) => a + b, 0)).toFixed(2);

        $("#js-payment-mail-sum").text(numberWithSpaces(totalSum));
    });

    $(document).on("click", ".js-show-popin-sendlater-from-preview", function (e) {
        $(".popin-error-sendlater").hide();
        $(".popin-error-sendlater").html("");
        $(".js-autocomplete-dest").val("");
        $(".bills-subject").val("");
        let idEfacture = $(this).attr("data-idefacture");
        $(".js-show-popin-final").attr("data-idefacture", idEfacture);
        let sum = $(e.target).attr("data-amount") / 100;
        $("#js-payment-mail-sum").text(sum);
    });

    if ($(".js-select-onload").length) {
        let elm = $(".js-select-onload").first();
        elm.trigger("click");
    }

    $(document).on("click", ".js-test-banks", function () {
        let resultContainer = $(".result-container");
        let resultContent = $(".result-content");
        let resultNumberOk = $(".test-banks-result-ok");
        let resultNumberKo = $(".test-banks-result-ko");
        let promiseReturn = $.post("/freelance/admin/test_banks_find");
        promiseReturn.then(function (val) {
            let v = JSON.parse(val);
            if (v.status === "success") {
                v.banks.forEach(function (fv) {
                    let promiseReturnBank = $.post("/freelance/admin/test_banks_result", { bankId: fv.id });
                    promiseReturnBank.then(function (bval) {
                        let bv = JSON.parse(bval);
                        if (bv.status === "success") {
                            let line = "<div class='line cprimary-7'><span class='line-name'>Banque: " + fv.name + "</span> Status : <span class='line-email'>OK</span> <span class='line-password'>Erreur : - </span></div>";
                            resultContent.append(line);
                            resultNumberOk.html((parseInt(resultNumberOk.html()) + 1).toString());
                        } else {
                            let line = "<div class='line cerror'><span class='line-name'>Banque: " + fv.name + "</span> Status : <span class='line-email'>KO</span> <span class='line-password'>Erreur : " + bv.msg + "</span></div>";
                            resultContent.append(line);
                            resultNumberKo.html((parseInt(resultNumberKo.html()) + 1).toString());
                        }
                    });
                });
            } else {
                window.location.reload();
            }
        });
    });
});

var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split("&"),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split("=");

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
};

var stepped = 0,
    rowCount = 0,
    errorCount = 0,
    firstError,
    start,
    end;
$(function () {
    var config = buildConfig();
    $(document).on("change", "#csv", function () {
        $("#csv").parse({
            config: config,
            before: function (file, inputElem) {
                start = now();
            },
            error: function (err, file) {
                firstError = firstError || err;
                errorCount++;
            },
            complete: function () {
                end = now();
                $(".js-export-usercsv").show();
            },
        });
    });
});

function buildConfig() {
    return {
        delimiter: ",",
        header: false,
        dynamicTyping: false,
        skipEmptyLines: true,
        preview: 0,
        step: stepFn,
        worker: false,
        complete: completeFn,
        error: errorFn,
        download: true,
    };
}

function stepFn(results, parser) {
    stepped++;
    if (results) {
        if (results.data) {
            rowCount += results.data.length;
            parser.pause();
            let promiseReturn = $.post("/freelance/admin/import_account_line", { email: results.data[0], lastname: results.data[1], firstname: results.data[2], login: results.data[3], phone: results.data[4] });
            promiseReturn.then(function (val) {
                let v = JSON.parse(val);
                if (v.status === "success") {
                    let line = "<div class='line'>Nom : <span class='line-name'>" + results.data[3] + "</span> email : <span class='line-email'>" + results.data[0] + "</span> Password : <span class='line-password'>" + v.password + "</span></div>";
                    $(".result-content").append(line);
                } else {
                    let line = "<div class='line cerror'><span class='line-name'>Une erreur est survenue pour => Email: " + results.data[0] + "</span> <span class='line-email'>email : " + results.data[2] + "</span> <span class='line-password'>Erreur : " + v.msg + "</span></div>";
                    $(".result-content").append(line);
                }
                $(".result-container").scrollTop(50000);
                parser.resume();
            });

            promiseReturn.catch(function (err) {
                parser.resume();
            });
        }
        if (results.errors) {
            errorCount += results.errors.length;
            firstError = firstError || results.errors[0];
        }
    }
}

function completeFn(results) {
    end = now();
    if (results && results.errors) {
        if (results.errors) {
            errorCount = results.errors.length;
            firstError = results.errors[0];
        }
        if (results.data && results.data.length > 0) rowCount = results.data.length;
    }
    downloadCSVImport();
}

function errorFn(err, file) {
    end = now();
}

function now() {
    return typeof window.performance !== "undefined" ? window.performance.now() : 0;
}

function downloadCSVImport() {
    let content = $(".result-content .line");
    let csvToData = [];
    $.each(content, function (k, val) {
        csvToData.push([$(val).find(".line-name").text(), $(val).find(".line-email").text(), $(val).find(".line-password").text()]);
    });

    let csv = Papa.unparse(csvToData);
    var universalBOM = "\uFEFF";
    var csvData = new Blob([universalBOM + csv], { type: "text/csv;charset=utf-8;" });
    //IE11 & Edge
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(csvData, "export.csv");
    } else {
        //In FF link must be added to DOM to be clicked
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(csvData);
        link.setAttribute("download", "export.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

function checkStatus(quotationId, i) {
    let b = i + 8;
    $(".progress-bar").attr("aria-valuenow", b);
    $(".progress-bar").css("width", b + "%");
    let projectId = $(".js-quotation-showmsg").attr("data-projectid");
    let route = "/freelance/projet/" + projectId + "/recouvrement/" + quotationId + "/check_status";
    let promiseReturn = $.post(route);

    promiseReturn.then(function (val) {
        let v = JSON.parse(val);
        if (v.status === "success") {
            window.location.href = "/freelance/projet/" + projectId + "/recouvrements";
        } else {
            if (v.message) {
                $(".errors").show();
                $(".errors").html(v.message);
            } else {
                setTimeout(checkStatus, 5000, quotationId, b);
            }
        }
    });

    promiseReturn.catch(function (err) {
        window.location.reload();
    });
}

var path = window.location.href;
let regexMyOffer = RegExp("mon_offre");

if (regexMyOffer.test(path)) {
    $(".subscription-nav").addClass("active");
}

$(".js-openpopin-bank").each(function (i, obj) {
    $(obj).on("click", function () {
        if ($(obj).data("discount") === undefined) {
            $(".bills-discount").css("display", "none");
        } else {
            $(".bills-discount").css("display", "block");
        }
    });
});

//Manage global search bar on project
$(".js-searchIcon").on("click", (e) => {
    $(".inputsearchmobile").toggle();
    let searchInput = $(".input-search-cross .search-input");
    if (searchInput.hasClass("show")) {
        searchInput.removeClass("show").trigger("blur");
    } else {
        searchInput.addClass("show").trigger("focus");
    }
    e.stopPropagation();
});

$(".input-search-cross .search-input").on("click", (e) => {
    e.stopPropagation();
});

// Click outside the input, mask this input
$(window).on("click", () => {
    if ($(".inputsearchmobile").is(":visible")) {
        $(".input-search-cross .search-input").removeClass("show").val("");
        $(".inputsearchmobile").toggle();
    }
    //$(".errorMsgOffline").hide();
});
