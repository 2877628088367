import {
    openPopin, scrollBottomElem, hideSelectContentWithOutsideClic, translation, currentPage,
    differenceDate, dateConversion, numberDaysDelate, convertPrice, delay, scrollHeightToValue,
    createThumbnailFromPDF, getRealHeightElement, getValidFormatDate, closePopin, getUrlParams
} from "../../generalFunctions";
import {checkAllInputs, verif_cif_nif, verifSiret} from "../../components/verificationFront";
import $ from "jquery";

// $(".js-addClient").on("click", () => {
//     openPopin(".popin-updateClient");
//     let companyName = $(".client-companyName").val();
//     let companyMail = $(".client-companyMail").val();
//     let taxId = $(".client-companySiret:visible").val();
//     if (companyName !== "" && companyMail !== "" && taxId !== "") {
//         $(".js-request-updateClient").removeClass("st-no-btn-disabled");
//     }
//     let getEmail = $(".st-destEmail").val();
//     $(".client-companyMail").val(getEmail);
//     if (window.location.pathname === "/freelance/clients") {
//         $('.js-request-updateClient').attr('data-redirect', 'true');
//     }
// });

// if (window.location.pathname === "/freelance/clients") {
//     if (getUrlParams(window.location.href)["home"] === 'true') {
//         setTimeout(() => {
//             $('.js-addClient').trigger('click');
//         }, 1000);
//     }
// }

let clientId = $(".clientPage").attr("data-clientid");


$(".js-popinDelete").on("click", function() {
    let homeElementId = $(this).attr("data-homeelementid");
    let homeIsCanceled = $(this).attr('data-iscanceled');
    $('.js-removeClient').attr("data-homeelementid", homeElementId);
    $('.js-cancelClient').attr("data-homeelementid", homeElementId);

    let isSupplier = false;

    if (homeIsCanceled) {
        $('.popin-deleteClient').addClass('st-isCanceled');
    }
    else {
        $('.popin-deleteClient').removeClass('st-isCanceled');
    }

    openPopin(".popin-deleteClient");
});
// Show and Hide filters 
$(".js-show-filterOperations").on("click", function() {
    $(this).hide();
    $(".js-hide-filterOperations").show();
    $(".st-filterOperations").css("display", "flex");
});

$(".js-hide-filterOperations").on("click", function() {
    $(this).hide();
    $(".js-show-filterOperations").show();
    $(".st-filterOperations").css("display", "none");
});

$(".js-show-filterBills").on("click", function() {
    $(this).hide();
    $(".js-hide-filterBills").show();
    $(".st-filterBills").css("display", "flex");
});

$(".js-hide-filterBills").on("click", function() {
    $(this).hide();
    $(".js-show-filterBills").show();
    $(".st-filterBills").css("display", "none");
});


// // Popin upload bills
// $(".js-openPopin-uploadFiles").on("click", () => {
//     openPopin(".popin-uploadFiles");
// });

// if (window.location.pathname !== "/freelance/client/paiement") {
//     $(".js-containerUploadFiles").on('drag dragend dragover dragenter dragleave drop', function(e) {
//         e.preventDefault();
//         e.stopPropagation();
//     }).on("drag drop", function(e){
//         ocr(e, e.originalEvent.dataTransfer.files);
//     }).on("click", function(){
//         $(".js-uploadFile").trigger("click");
//     });

//     $(".js-request-uploadFiles").on("click", function() {
//         $(".js-uploadFile").trigger("click");
//     });

//     $(".js-uploadFile").on("change", function(e) {
//         ocr(e, e.target.files);
//     });
// }


// Popin download bills
$(".js-openPopin-downloadAllBills").on("click", () => {
    openPopin(".popin-downloadAllBills");
    $(".js-request-downloadBills").removeClass("st-no-btn-disabled");
});

$(".js-show-selectBills").on("click", function(e) {
    $(".js-selectBills").toggle();
    hideSelectContentWithOutsideClic(e, ".js-selectBills");
});

$(".js-selectBills p").on("click", function() {
    let value = $(this).attr("data-value");
    let content = $(this).text();
    $(".js-selectBills").hide();
    $(".js-selectBills p").css("color", "#02130E");
    $(this).css("color", "#ABB0AF");
    $(".js-show-selectBills p").text(content).attr("data-value", value);
});

$(".js-request-downloadBills").on("click", function() {
    $(this).addClass("st-no-btn-disabled");

    let downloadBillType = $(".js-download-billType").attr("data-value");
    let downloadFromDate = $(".js-download-fromDate").val();
    let downloadToDate = $(".js-download-toDate").val();

    let sendRequest = $.post("/freelance/client/"+clientId+"/download_all_invoices", {
        downloadBillType: downloadBillType,
        downloadFromDate: downloadFromDate,
        downloadToDate: downloadToDate
    });

    sendRequest.then(val => {
        let v = JSON.parse(val);
        if (v.status === "success") {
            window.location.href = v.file;
            closePopin();
        }
        else {
            $(this).removeClass("st-no-btn-disabled");
            if (v.message) {
                $(".st-blocPopinError").removeClass("d-none").addClass("d-flex");
                $(".js-errorMsg").text(v.message);
            }
        }
    });
});

$(".js-openPopin-updateClient").on("click", function() {
    window.location.href = "http://"+$(this).attr('data-domain')+"/dashboard?popin=client&id="+$(this).attr('data-clientid');
});

// Popin download documents
$(".js-openPopin-downloadAllDocs").on("click", () => {
    openPopin(".popin-downloadAllDocs");
});


$(".js-redirect-addClient").on("click", function() {
    let domain = $(this).attr('data-domain');
    window.location.href = "http://"+domain+"/dashboard?popin=client";
});

$(".js-show-selectDocs").on("click", function(e) {
    $(".js-selectDocs").toggle();
    hideSelectContentWithOutsideClic(e, ".js-selectDocs");
});

$(".js-selectDocs p").on("click", function() {
    let value = $(this).attr("data-value");
    let content = $(this).text();
    $(".js-selectDocs").hide();
    $(".js-selectDocs p").css("color", "#02130E");
    $(this).css("color", "#ABB0AF");
    $(".js-show-selectDocs p").text(content).attr("data-value", value);
});

$(".js-request-downloadDocs").on("click", function() {
    $(this).addClass("st-no-btn-disabled");

    let downloadDocType = $(".js-download-docType").attr("data-value");

    let sendRequest = $.post("/route", {
        downloadDocType: downloadDocType,
    });

    sendRequest.then(val => {
        let v = JSON.parse(val);
        if (v.status === "success") {
        }
        else {
            $(this).removeClass("st-no-btn-disabled");
            if (v.message) {
                $(".st-blocPopinError").removeClass("d-none").addClass("d-flex");
                $(".js-errorMsg").text(v.message);
            }
        }
    });
});

// Popin create folder
$(".js-openPopin-addFolderClient").on("click", () => {
    openPopin(".popin-addFolderClient");
});

$(".js-request-addFolderClient").on("click", function() {
    $(this).addClass("st-no-btn-disabled");

    let downloadDocType = $(".client-folderName").val("data-value");

    let sendRequest = $.post("/route", {
        downloadDocType: downloadDocType,
    });

    sendRequest.then(val => {
        let v = JSON.parse(val);
        if (v.status === "success") {
        }
        else {
            $(this).removeClass("st-no-btn-disabled");
            if (v.message) {
                $(".st-blocPopinError").removeClass("d-none").addClass("d-flex");
                $(".js-errorMsg").text(v.message);
            }
        }
    });
});

// Changement price Disbursement 
$(".st-blocDisbursement .st-iconSelectDate").on("click", () => {
    $(".js-changePriceDisbursement").trigger("click");
});

if (currentPage === "freelance_client") {
    changeDisbursementPrice(".js-changePriceDisbursement");
}

$(".js-changePriceDisbursement").on("change input", function() {
    changeDisbursementPrice(this);
});

function changeDisbursementPrice(thisElement) {
    let oldValue = $(thisElement).attr("data-oldvalue");
    let value = $(thisElement).val();

    if (oldValue !== value) {
        let sendRequest;
        if (value === "") {
            sendRequest = $.post("/freelance/client/" + clientId + "/sum_payment");
        }
        else {
            sendRequest = $.post("/freelance/client/" + clientId + "/sum_payment", {
                fromDate: value,
            });
        }

        sendRequest.then(val => {
            let v = JSON.parse(val);
            if (v.status === "success") {
                let allPayed = v.allPayed ? convertPrice(v.allPayed) : "0,00 €";
                let toBePayed = v.toBePayed ? convertPrice(v.toBePayed) : "0,00 €";
                $(".st-totalDisbursement").text(allPayed);
                $(".st-disbursement").text(toBePayed);
                if (typeof v.fromDate === 'undefined' || v.fromDate <= 0) {
                    $(".js-changePriceDisbursement").val(dateConversion(null));
                }
                else {
                    $(".js-changePriceDisbursement").val(v.fromDate);
                }
            }
            else {
                $(".js-changePriceDisbursement").val(dateConversion(null));
                if (v.message) {
                }
            }
        });
    }
    $(thisElement).attr("data-oldvalue", value);
}

// Manage waiting and success operations
$(".js-sortOperationsClient").val("LAST");
$(".js-searchOperations").val("");

let arrayRowTypeWait = {
    "EQUOTATION": 0,
    "CREDITED_PAYMENT": 0,
    "DEBITED_PAYMENT": 0,
    "SUPPLIER_PAYMENT": 0,
    "DELAYED_INVOICE": 0,
    "DELAYED_PAYMENT": 0,
    "RECOVERY": 0,
    "DOCUMENT": 0
}

if (currentPage === "freelance_client") {
    clientPaymentRequest(3, "waiting");
    //clientPaymentRequest(3, "success", true);

    // let sendRequest = $.post("/freelance/client/" + clientId + "/count_payment");
    // sendRequest.then(val => {
    //     let v = JSON.parse(val);
    //     if (v.status === "success") {
    //         $(".st-totalWaitingOperations, .st-totalWaitingBills").text(v.countToBePayed);
    //         $(".st-totalSuccessOperations, .st-totalSuccessBills").text(v.countAllPayed);
    //     }
    //     else {
    //     }
    // });
}

$(".js-sortOperationsClient").on("change", function() {
    $(".js-container-waitingOperations div").remove();
    for (let rowWait in arrayRowTypeWait) {
        arrayRowTypeWait[rowWait] = 0;
    }
    clientPaymentRequest(3, "waiting", false, $(this).val());
});

$(".js-searchOperations").on("keyup change", delay(function () {
    $(".js-container-waitingOperations div").remove();
    for (let rowWait in arrayRowTypeWait) {
        arrayRowTypeWait[rowWait] = 0;
    }
    clientPaymentRequest(3, "waiting", false, $(".js-sortOperationsClient").val(), $(this).val());
}, 300));

$(".js-showMoreWaitOpeClient").on("click", function() {
    clientPaymentRequest(10, "waiting", true);
    if ($(".js-reduceWaitOpe").not(":visible")) {
        $(".js-reduceWaitOpe").show();
    }
});

$(".js-reduceWaitOpe").on("click", function() {
    reduceElements(".js-container-waitingOperations .st-rowClientPayment", 
    ".js-container-waitingOperations .st-rowClientPayment:last", this, ".js-showMoreWaitingOpe", arrayRowTypeWait, 82);
});

function clientPaymentRequest(iMax, statusCustom, loadEvents = false, orderOperation = $(".js-sortOperationsClient").val(), 
    searchText = $(".js-searchOperations").val(), filterOperation = "ALL", typeOperation = "EQUOTATION") {

    let arrayRowType;
    arrayRowType = arrayRowTypeWait;

    let sendRequest = $.post("/freelance/find_data_home", {
        order: orderOperation,
        filter: filterOperation,
        displayType: typeOperation,
        arrayRowType: arrayRowType,
        search: searchText,
        clientId: clientId
    });

    sendRequest.then(val => {
        let v = JSON.parse(val);
        if (v.status === "success") {
            let allDatas = v.data;
            if (allDatas.length > 0) {
                $(".js-container-waitingOperations .st-emptySearch").remove();
                allDatas.forEach(data => {
                    if (iMax > 0) {
                        addDynamicElement(data.id, data.title, data.status, data.date, data.dueDate, data.delayedPaymentDate, data.payedDate, data.paymentStatus, data.mark_as_payed, data.approvalStatus, data.value, statusCustom, data.id, data.link, data.filename, v.domain);
                        arrayRowTypeWait[data.type] += 1;
                        iMax--;
                    }
                });
                if (allDatas.length < iMax) {
                    $(".js-showMoreWaitOpeClient").hide();
                }
                else if (allDatas.length > 3) {
                    $(".js-showMoreWaitOpeClient").show();
                }
                $(".st-emptyOperations").hide();
                $(".st-bloc-waitingOperations, .js-show-filterOperations").show();
                $(".st-bloc-waitingOperations").addClass("d-flex");
            }
            else {
                let isSearch = $(".js-searchOperations").val() !== "";
                
                if (isSearch) {
                    $(".js-container-waitingOperations p").remove();
                    let emptySearchMessageWaiting = $("<p>", {
                        class: "st-emptySearch",
                        text: translation("Aucune opération")
                    });
                    $(".js-container-waitingOperations").append(emptySearchMessageWaiting);
                    $(".js-showMoreWaitOpeClient").hide();
                    $(".st-bloc-waitingOperations, .js-show-filterOperations, .js-hide-filterOperations, .st-filterOperations").show();
                    $(".st-bloc-waitingOperations").addClass("d-flex");
                }
            }

            // Once elements are created, we run the events
            if (loadEvents) {
                $(".js-show-actionsClientPayment").on("click", function(e) {
                    $(".st-actionsClientPayment").hide();
                    let actionsClient = $(this).find(".st-actionsClientPayment");
                    if(actionsClient.is(":visible")) {
                        actionsClient.hide();
                    }
                    else {
                        actionsClient.show();
                    }
                    hideSelectContentWithOutsideClic(e, actionsClient);
                });
                $(".js-markAsPayed").on("click", function() {
                    let route = $(this).attr("data-route");

                    let sendRequest2 = $.post(route);

                    sendRequest2.then(val => {
                        v = JSON.parse(val);
                        if (v.status) {
                            window.location.reload();//CHECKCA
                        }
                        else {
                            window.location.reload();
                        }
                    });
                });
                $(".js-unmarkAsPayed").on("click", function() {
                    let route = $(this).attr("data-route");
                    let clientPaymentId = $(this).attr("data-clientpayment-id");
                    let sendRequest3 = $.post(route, {
                        paymentId: clientPaymentId
                    });

                    sendRequest3.then(val => {
                        v = JSON.parse(val);
                        if (v.status) {
                            window.location.reload();
                        }
                        else {
                            window.location.reload();
                        }
                    });
                });
                $(".js-deleteClientPayment").on("click", function() {
                    let clientPaymentId = $(this).attr("data-clientpayment-id");
                    let sendRequest4 = $.post("/freelance/paiement_client/" + clientPaymentId + "/cancel", {
                        isFlash: "isFlash"
                    });
                    sendRequest4.then(val => {
                        v = JSON.parse(val);
                        if (v.status) {
                            window.location.reload();
                        }
                        else {
                            window.location.reload();
                        }
                    });
                });
                $(".st-clientPaymentBloc2, .st-clientPaymentBloc3, .st-clientPaymentBloc4").on("click", function() {
                    let routeAction = $(this).attr("data-route");
                    document.location.href = routeAction;
                });
                $(".st-clientPaymentBloc1").on("click", function() {
                    let pdf = $(this).attr("data-pdf");
                    window.open(pdf, '_blank');
                });
                
                // if ($(".js-container-waitingOperations div").length === 0 && $(".js-container-successOperations div").length === 0 && $(".js-searchOperations").val() === "") {
                //     $(".st-emptyOperations").show();
                //     $(".st-bloc-waitingOperations, .st-bloc-successOperations, .js-show-filterOperations, .js-hide-filterOperations, .st-filterOperations").hide();
                // }
            }
        }
        else {
        }
    });
}

function addDynamicElement(data_id, data_name, data_status, data_date, data_dueDate, data_delayedPaymentDate, data_payedDate, data_paymentStatus, data_markAsPayed, data_approvalStatus, data_price, data_statusCustom, data_clientPaymentId, data_link, data_filename, domain) {
    let nameData, classActionButton, textActionButton, statePayment, iconState, altState, divMarkAsPayed, infoSupData, price, classActionMarkAsPayed, routeMarkAsPayed, contentMarkAsPayed, classActionDelete, routeAction;

    if (data_name === null || data_name === "") {
        nameData = "-";
    } else {
        nameData = data_name;
    }
    if (data_price === "-" || data_price === null) {
        price = "";
    }
    else {
        price = data_price;
    }
    if (data_status === 'TODO') {
        classActionButton = "st-to-complete";
        textActionButton = translation("Compléter");
        statePayment = translation("Infos manquantes");
        iconState = "/build/images/pastille-rouge.svg";
        altState = "icone d'info manquante";
        //divMarkAsPayed = "d-block st-divMarkAsPayed supplierPayment"+j;
        divMarkAsPayed = "d-none";
        infoSupData = translation("Devis créé le") + " " + dateConversion(data_date);
        routeAction = "http://" + domain + "/devis?step=2&id=" + data_id;
    }
    else if (data_status === 'DONE') {
        classActionButton = "st-to-complete";
        textActionButton = translation("Renvoyer");
        statePayment = translation("Envoyé");
        iconState = "/build/images/pastille-verte.svg";
        altState = "icon pastille validée";
        //divMarkAsPayed = "d-block st-divMarkAsPayed supplierPayment"+j;
        divMarkAsPayed = "d-none";
        infoSupData = translation("Devis envoyé le") + " " + dateConversion(data_date);
        routeAction = "http://" + domain + "/devis?step=3&id=" + data_id;
    }
    else if (data_status === 'DOING') {
        classActionButton = "st-to-complete";
        textActionButton = translation("Envoyer");
        statePayment = translation("Terminé");
        iconState = "/build/images/pastille-verte.svg";
        altState = "icon pastille validée";
        //divMarkAsPayed = "d-block st-divMarkAsPayed supplierPayment"+j;
        divMarkAsPayed = "d-none";
        infoSupData = translation("Devis émis le") + " " + dateConversion(data_date);
        routeAction = "http://" + domain + "/devis?step=3&id=" + data_id;
    }
    let containerOperations = `
    <div class="d-flex st-rowClientPayment">
        <div class="st-clientPaymentBloc1" data-pdf="${data_filename}">
            <img src="/build/images/icon-document.svg" alt="icone document" width="24px" height="24px">
        </div>
        <div class="st-clientPaymentBloc2" data-route="${routeAction}">
            <p class="fs14 fw600 mb0">${nameData}</p>
            <p class="fs12 mb0">${infoSupData}</p>
        </div>
        <div class="st-clientPaymentBloc3" data-route="${routeAction}">
            <p class="fs14 fw600 mb0">- ${convertPrice(price)}</p>
        </div>
        <div class="d-flex st-clientPaymentBloc4" data-route="${routeAction}">
            <img src="${iconState}" alt="${altState}" width="12px" height="12px">
            <p class="fs14 mb0">${statePayment}</p>
        </div>
        <div class="st-clientPaymentBloc5">
            <a class="fs14 fw600 ${classActionButton}" href="${routeAction}">${textActionButton}</a>
        </div>
        <div class="st-clientPaymentBloc6 js-show-actionsClientPayment ${divMarkAsPayed}">
            <p class="st-points"></p>
            <p class="st-points"></p>
            <p class="st-points"></p>
            <div class="st-actionsClientPayment">
                <p class="${classActionMarkAsPayed}" data-route="${routeMarkAsPayed}" data-clientpayment-id=${data_clientPaymentId}>${contentMarkAsPayed}</p>
                <p class="js-deleteClientPayment ${classActionDelete}" data-clientpayment-id=${data_clientPaymentId}>${translation("Supprimer")}</p>
            </div>
        </div>
    </div>
    `;

    if (data_statusCustom === "success") {
        $(".js-container-successOperations").append(containerOperations);
    }
    else if (data_statusCustom === "waiting") {
        $(".js-container-waitingOperations").append(containerOperations);
    }
}

$(".js-redirect-create-edevis").on("click", function() {
    let domain = $(this).attr('data-domain');
    let id = $(this).attr('data-id');
    window.location.href = "http://"+domain+"/devis?clientid="+id;
});

$(".st-back-clients").on("click", function() {
    window.location.href = "/freelance/clients";
});

// Methods and Refacto
const reduceElements = (rowContainer, lastRow, reduceElt, showMoreElt, arrayRowType, isScroll = false, iMax = 10, lengthRowDefault = 3) => {
    let i = 0;
    while (i < iMax && $(rowContainer).length > lengthRowDefault) {
        arrayRowType["EQUOTATION"] -= 1;
        $(lastRow).remove();
        i++;

        if ($(rowContainer).length === lengthRowDefault) {
            $(reduceElt).hide();
            $(showMoreElt).show();
        }
    }
    if (isScroll !== false) {
        // isScroll represent the height (82px for exemple) of a row
        scrollHeightToValue(i * isScroll);
    }
}

// Verifications front client
$(".popin-updateClient .verif-front").on("input change", function() {
    checkAllInputs(".popin-updateClient .popin-content", ".js-request-updateClient");
});

$(".popin-addFolderClient .verif-front").on("input change", function() {
    checkAllInputs(".popin-addFolderClient .popin-content", ".js-request-addFolderClient");
});

$(".popin-downloadAllBills .verif-front").on("input change", function() {
    checkAllInputs(".popin-downloadAllBills .popin-content", ".js-request-downloadBills");
});

