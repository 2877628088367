// BASIC USAGE :
// 1 - ADD CLASS 'input-front-validation' on input
// 2 - ADD ATTRIBUTE 'data-errormsg' TO SAME INPUT
// 3 - ADD ATTRIBUTE 'data-attributes=' + PARAMETERS NEEDED FOR handleInput function TO SAME INPUT
// 4 - ADD CLASS 'js-handleInputsValidation' ON DIV CONTAINING EVERY INPUTS THAT WE WANT TO VALIDATE
import IntlTelInput from 'intl-tel-input';
import { translation } from '../newScripts/generalFunctions';

export function inputFrontValidation() {
    $('.input-front-validation').on('DOMSubtreeModified DOMNodeInserted DOMNodeRemoved input blur paste change tbwchange tbwblur tbwpaste', (e) => {
        if ($(e.target).attr('data-attributes')) {
            let attributesArray = $(e.target).attr('data-attributes').split(',');
            handleInput(e, ...attributesArray);
        } else {
            handleInput(e); 
        }
    });
}
  
inputFrontValidation();
  
// HANDLEINPUT MANAGE ERROR CLASS AND DATA ERRORMSG 
const handleInput = (e, buttonClass, displayError = null, file = null, htTtcComparison = null, trumbowyg = null, errorMessageLocation = '.js-error-message', inputsArea = '.js-handleInputsValidation', negatif = null, mail = null, password = null, atLeastOneNumber = null) => {
    let errorMsg = null;
    // CHECK IF TTC AMOUT >= HT AMOUNT
    if (htTtcComparison === 'htTtcComparison') {
        if (parseInt($(e.target).closest(inputsArea).find('.js-valid-ht').val()) > parseInt($(e.target).closest(inputsArea).find('.js-valid-ttc').val())) {
            $(e.target).closest(inputsArea).find('.js-valid-ht').addClass('error');
            $(e.target).closest(inputsArea).find('.js-valid-ttc').addClass('error');
            errorMsg = translation("TTC doit être supérieur HT");
        }
        else if (parseInt($(e.target).closest(inputsArea).find('.js-valid-ht').val()) <= 0) {
            $(e.target).closest(inputsArea).find('.js-valid-ht').addClass('error');
            errorMsg = "Le prix ne peut pas être inférieur ou égal à zéro";
        }
        else if (parseInt($(e.target).closest(inputsArea).find('.js-valid-ttc').val()) <= 0) {
            $(e.target).closest(inputsArea).find('.js-valid-ttc').addClass('error');
            errorMsg = "Le prix ne peut pas être inférieur ou égal à zéro";
        }
        else {
            $(e.target).closest(inputsArea).find('.js-valid-ht').removeClass('error');
            $(e.target).closest(inputsArea).find('.js-valid-ttc').removeClass('error');
        }
    }
    // CHECK IF AMOUNTMASK IS TO WELL FORMAT
    if ($(e.target).hasClass('amountmask')) {
        const result = /^\d+([\,\.]\d+)?(?=$| )/.test($(e.target).val());
        if (!result) {
            $(e.target).addClass('error');
            errorMsg = translation("Nombre invalide");
        }
    }
    // ELM-LIST FOR EFACTURE
    if ($(e.target).hasClass('elm-list')) {
        if ($(e.target).find('.js-createbills-elements').length < 1) {
            errorMsg = translation("Veuillez ajouter un élément");
            $('.elm-empty').addClass('error');
        }
    }
    // CHECK IF FILE IS LOADED WHEN REQUIRED
    if (file === 'file') {
        if ($('.drop-file').length < 1) {
            errorMsg = translation("Veuillez ajouter votre facture");
        }
    }
    // CHECK IF TRUMBOWYG EDITOR IS EMPTY WHEN REQUIRED (MAIL PERSONNALISATION)
    if (trumbowyg === 'trumbowyg') {
        if (!$(e.target).closest('.trumbowyg').children('.trumbowyg-editor').text()) {
            $(e.target).closest('.trumbowyg').children('.trumbowyg-editor').addClass('error');
            errorMsg = translation("Veuillez renseigner tous les champs");
        }
    }

    if (atLeastOneNumber === 'atLeastOneNumber') {
        if (!/\d/g.test(e.target.value) ) {
            $(e.target).addClass('error');
            errorMsg = translation("num facture un chiffre minimum");
        }
    }

    if (mail === 'mail') {
        if ($(e.target).attr("data-id") === undefined) {
            if (!/(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test($(e.target).val())) {
                $(e.target).addClass('error');
                errorMsg = translation("Veuillez renseigner une adresse mail valide");
            }
        }
    }

    if (password === 'password') {
        const passwordRegex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$");
        if (!passwordRegex.test($(e.target).val())) {
            $(e.target).addClass('error');
            errorMsg = translation("critères mot de passe");
        }
    }
    // CHECK IF INPUT IS EMPTY WHEN REQUIRED
    if ($(e.target).prop('required') && !$.trim($(e.target).val())) {
        if (file === null || file === 'null') {
            $(e.target).addClass('error');
            errorMsg = translation("Veuillez renseigner tous les champs");        
        }
    } 

    if (($(e.target).attr('type') === 'checkbox') && (!$(e.target).is(":checked"))) {
        errorMsg = translation("Veuillez cocher les champs obligatoires");
    }

    if ($(e.target).attr('type') === 'tel') {
        let iti = window.intlTelInputGlobals.getInstance(e.target);
        if (iti !== undefined) {
            let isValid = iti.isValidNumber();
            if (!isValid) {
                $(e.target).addClass('error');
                errorMsg = translation("Votre numéro de téléphone ne semble pas valide");
            }
        }
    }

    if (errorMsg === null) {
        if (trumbowyg === 'trumbowyg') {
            $(e.target).closest('.trumbowyg').children('.trumbowyg-editor').removeClass('error');
            $(e.target).closest('.trumbowyg').children('.trumbowyg-textarea').removeClass('error');
        } else {
            $(e.target).closest('.elm-empty').length > 0 ? $('.elm-empty').removeClass('error') : $(e.target).removeClass('error');
        }
    }

    setTimeout(() => {
        $(e.target).attr('data-errormsg', errorMsg);
    }, 0)
    // DISPLAY ERROR MESSAGE IF ASKED
    handleInputs(e.target, displayError, inputsArea, errorMessageLocation, buttonClass, file);
}
    
    // HANDLEINPUTS CHECK ALL INPUTS AND MANAGE ERROR MESSAGES + BTN DISABLED 
const handleInputs = (target, displayError, classInputsArea, classErrorMessageLocation, buttonClass) => {
    $(classErrorMessageLocation).text('');
    let errors = 0;

    $(target).closest(classInputsArea).find('.input-front-validation').each((_, input) => {
        setTimeout(() => {
            if ($(input).hasClass('error')) {
                if (!$(input).hasClass('trumbowyg-textarea') && (displayError === 'displayError')) {
                    $(classErrorMessageLocation).text($(input).attr('data-errormsg')).show();
                }
                errors += 1;
            }
        }, 0)
        if (($(input).attr('type') === 'checkbox') && (!$(input).is(":checked"))) {
            errors += 1;
        }
        if (!$(input).val() && $(input).prop('required') && !$(input).hasClass('trumbowyg-textarea')) {
            errors += 1;
        }
        if ($(input).attr('data-attributes').search( 'file' ) !== -1) {
            if ($(target).closest(classInputsArea).find('.drop-file').length < 1) {
                errors += 1;
            }
        }
        if ($(input).hasClass('elm-list')) {
            if ($(input).find('.js-createbills-elements').length < 1) {
                errors += 1;
            }
        }
    })

    if ($(target).hasClass('elm-list')) {
        if ($(target).length < 1) {
            errors += 1;
        }
    }

    setTimeout(() => {
        if (errors === 0) {
            $(buttonClass).removeClass('st-no-btn-disabled');
        } else {
            $(buttonClass).addClass('st-no-btn-disabled');
        }
    }, 0)
}
  
  // HANDLE ALL INPUT AND DISABLE BUTTON IN PARAMETERS
  // 'whithoutRedOutline' HAS TO BE ADDED AS PARAMETER IF JUST BUTTON DISABLE NEEDED
  export const checkAllInputs = (inputs, button, whithoutRedOutline = null) => {
      setTimeout(() => {
          let errors = 0;
          $(inputs).each((_, elem) => {
              let errorMsg = null;
              let arrayOfAttributes = $(elem).attr('data-attributes').split(',');
              // CHECK IF AMOUNTMASK IS TO WELL FORMAT
              if ($(elem).hasClass('amountmask')) {
                  const result = /^\d+([\,\.]\d+)?(?=$| )/.test($(elem).val());
                  if (!result) {
                      $(elem).addClass('error');
                      errorMsg = translation("Nombre invalide");
                  }
              }
              // CHECK IF FILE IS LOADED WHEN REQUIRED
              if ((jQuery.inArray("file", arrayOfAttributes) !== -1) && $('.drop-file').length < 1) {
                  $(elem).addClass('error');
                  errorMsg = translation("Veuillez ajouter votre facture");
              } 
              // CHECK IF TRUMBOWYG EDITOR IS EMPTY WHEN REQUIRED (MAIL PERSONNALISATIONS)
              if (jQuery.inArray("trumbowyg", arrayOfAttributes) !== -1) {
                  if (!$(elem).closest('.trumbowyg').children('.trumbowyg-editor').text()) {
                      $(elem).closest('.trumbowyg').children('.trumbowyg-editor').addClass('error');
                      errorMsg = translation("Veuillez renseigner tous les champs");
                  }
              }        
              // CHECK IF TTC AMOUT >= HT AMOUNT
              if (jQuery.inArray("htTtcComparison", arrayOfAttributes) !== -1) {
                  if (parseInt($('.js-valid-ht').val()) > parseInt($('.js-valid-ttc').val())) {
                      $(elem).addClass('error');
                      errorMsg = translation("TTC doit être supérieur HT");
                  }
              }
              // CHECK IF INPUT IS EMPTY WHEN REQUIRED
              if ((jQuery.inArray("trumbowyg", arrayOfAttributes) === -1) && (jQuery.inArray("file", arrayOfAttributes) === -1)) {
                  if ($(elem).prop('required') && !$.trim($(elem).val())) {
                      $(elem).addClass('error');
                      errorMsg = translation("Veuillez renseigner tous les champs");
                  }     
              }  
              if (jQuery.inArray("mail", arrayOfAttributes) !== -1) {
                if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test($(elem).val())) {
                  $(elem).addClass('error');
                  errorMsg = translation("Veuillez renseigner une adresse mail valide");
                }
              }
          
              if (jQuery.inArray("password", arrayOfAttributes) !== -1) {
                const passwordRegex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$");
                if (!passwordRegex.test($(elem).val())) {
                  $(elem).addClass('error');
                  errorMsg = translation("critères mot de passe");
                }
              }
              if (($(elem).attr('type') === 'checkbox') && (!$(elem).is(":checked"))) {
                  errorMsg = translation("Veuillez cocher les champs obligatoires");
              }
              if (($(elem).hasClass('elm-list')) && ($(elem).find('.js-createbills-elements').length < 1)) {
                errorMsg = translation("Veuillez ajouter un élément");
              }
              if (errorMsg === null) {
                  if (jQuery.inArray("trumbowyg", arrayOfAttributes) !== -1) {
                      $(elem).closest('.trumbowyg').children('.trumbowyg-editor').removeClass('error');
                      $(elem).closest('.trumbowyg').children('.trumbowyg-textarea').removeClass('error');
                  } else {
                      $(elem).removeClass('error');
                  }
              } else {
                  errors += 1;
                  $(elem).attr('data-errormsg', errorMsg);
              }
          })
          if (errors > 0) {
              $(button).addClass('st-no-btn-disabled');
              if (whithoutRedOutline === 'whithoutRedOutline') {
                  $(inputs).removeClass('error');
              }
          } else {
            $(button).removeClass('st-no-btn-disabled');
          }
      }, 0)
  }
  