countdownManager = {
	targetTime: new Date($('.countdown').data('date')),
	displayElement: {
		min:  null,
		sec:  null
	},
	
	init: function(){
		this.displayElement.min  = $('#countdown_min');
		this.displayElement.sec  = $('#countdown_sec');
		
		this.tick();
		window.setInterval("countdownManager.tick();", 1000);
	},
	
	tick: function(){
		var timeNow  = new Date();
		if( timeNow > this.targetTime ){
			window.location = "/freelance";
		}
		var diff = this.dateDiff(timeNow, this.targetTime);

		this.displayElement.min.text(  diff.min  );
		this.displayElement.sec.text(  diff.sec  );
	},
	
	dateDiff: function(date1, date2){
		var diff = {};
		var tmp = date2 - date1;

		tmp = Math.floor(tmp/1000);
		diff.sec = tmp % 60;
		tmp = Math.floor((tmp-diff.sec)/60);
		diff.min = tmp % 60;

		return diff;
	}
};

$(function(){
	countdownManager.init();
});