$(document).on('click', '.js-blacklist-sup', function () {
  $('.error-promo').addClass('d-none').html('');
  let userId = $(this).attr('data-id');
  let promiseReturn = $.post('/freelance/admin/blacklist/sup/'+userId);
  promiseReturn.then(function (val) {
    let v = JSON.parse(val);
    if (v.status === "success") {
      window.location.reload();
    }
    else {
      let error = v.message;
      $('.error-promo').removeClass('d-none').html(error);
    }
  })
})

$(document).on('click', '.js-blacklist-add', function () {
  $('.error-promo').addClass('d-none').html('');
  let userId = $('.js-blacklist-add');
  let promiseReturn = $.post('/freelance/admin/blacklist/add', {"email": $('#email').val()});
  promiseReturn.then(function (val) {
    let v = JSON.parse(val);
    if (v.status === "success") {
      window.location.reload();
    }
    else {
      let error = v.message;
      $('.error-promo').removeClass('d-none').html(error);
    }
  })
})