import $, { data } from "jquery";
import { checkAllInputs } from "../../components/verificationFront";
import {
    translation,
    dateConversion,
    getValidFormatDate,
    openPopin,
    closePopin,
    getUrlParams
} from "../../generalFunctions";

let locale = $("body").attr("data-currentlang");

const ocr = (e, files, invoiceId = undefined, embedEltId = undefined, flag = false) => {
    $('.loader-upload').fadeIn()

    e.preventDefault();

    let data = new FormData();
    let invoiceNumber;
    let totalHt;
    let totalTtc;
    let currency;
    let due_date;
    let invoiceDate;

    if (flag == true) {
        data.append("document", files, files.name);
    }
    else {
        data.append("document", files[0], files[0].name);
    }

    if (files.length > 0 || files) {
        $.ajax({
            type: "POST",
            beforeSend: function (request) {
                request.setRequestHeader("Authorization", "Token 47145103e698afbbb1133701cbed0e17")
            },
            url: "https://api.mindee.net/v1/products/mindee/invoices/v2/predict",
            data: data,
            processData: false,
            contentType: false,
            success: function (val) {
                let document = val.document.inference.prediction;

                invoiceNumber = document.invoice_number.value ? document.invoice_number.value : null;
                totalHt = document.total_excl.value ? document.total_excl.value : null ;
                totalTtc = document.total_incl.value ? document.total_incl.value : null;
                currency = document.locale.currency ? document.locale.currency : null;
                due_date = document.due_date.value ? document.due_date.value : null;
                invoiceDate = document.date.value ? document.date.value : null;

                $('.invoice-number').val(invoiceNumber);
                $('.infos-totalHT').val(totalHt);
                $('.infos-totalTTC').val(totalTtc);
                $('.js-selectDevise').val(currency);
                $('.invoice-sent-date').val(dateConversion(invoiceDate));
                $('.invoice-due-date').val(dateConversion(due_date));

                let valueTTC = $(".st-blocFormFacture .verif-ttc").val().replace(",", ".");
                let valueHT = $('.st-blocFormFacture .verif-ht').val().replace(",", ".");

                if (valueHT !== "" && valueTTC !== "") {
                    let calculTVA = (Number(valueTTC) - Number(valueHT)).toLocaleString();
                    $(".st-supplierTVA span").text(calculTVA);
                }
                if (!flag) {
                    multiUploads(files, invoiceId, embedEltId)
                }
            }
        });
    }
    else {
        $('.st-blocFormErrorImport').removeClass('d-none').addClass('d-flex');
        $('.js-errorMsg').html(translation("Erreur import facture"));
        $('.loader-upload').fadeOut();
    }
}

closePopin();

$(".supplierPaymentPage .st-back-dashboard").on("click", function() {
    if ($(this).attr("data-bill") === "true") {
        openPopin(".popin-confirmLeaveSupplierForm");
    }
    else {
        window.location.href = "/freelance/fournisseurs";
    }
});

// delete supplier payment
$(".js-deleteInvoice").on("click", function() {
    $(this).addClass("st-no-btn-disabled");
    let supplierPayment_id = getSupplierPaymentId();
    // $(".js-blocImportBills embed").each(function() {
    //     if ($(this).is(":visible")) {
    //         supplierPayment_id = $(this).attr("data-paymentid");
    //     }
    // });
    let promiseReturn = $.post("/freelance/paiement_fournisseur/" + supplierPayment_id + "/cancel", {
        nbInvoices: (total-1)
    });

    promiseReturn.then(val => {
        let v = JSON.parse(val);
        if (v.status === 'success') {
            window.location.href = "/freelance/operations";
        }
        else {
            $(this).removeClass("st-no-btn-disabled");
        }
    });
});

// Save supplier payment and quit
$(".js-confirmLeaveSupplierForm").on("click", function() {
    $(this).addClass("st-no-btn-disabled");
    if ($(this).attr("data-stepTwo") === "true") {
        let iban;
        if ($(".js-bankIban").val() !== "") {
            iban = $(".js-bankIban").val();
        }
        else {
            iban = $(".js-bankIban-later").val();
        }
        saveAndQuit(iban, $(this));
    }
    else {
        let invoice_number = $(".invoice-number").val();
        let invoice_date = $(".invoice-sent-date").val().replaceAll("/", "-");
        let due_date = $(".invoice-due-date").val().replaceAll("/", "-");
        let amount_without_taxes = $(".infos-totalHT").val();
        let amount_with_taxes = $(".infos-totalTTC").val();
        let currency = $(".js-selectDevise").val();
        let supplier = $(".st-destEmail").val().trim();
        let supplierPayment_id = getSupplierPaymentId();
        let isValidated;

        if ($(".supplierPaymentPage").attr("data-payment-validated") === "true") {
            isValidated = 1;
        }
        else {
            isValidated = 0;
        }

        if (supplierPayment_id) {
            let promiseReturn = $.post("/freelance/supplier_payment/"+supplierPayment_id+"/create", {
                supplier: supplier,
                invoice_number: invoice_number,
                invoice_date: invoice_date,
                due_date: due_date,
                amount_without_taxes: amount_without_taxes,
                amount_with_taxes: amount_with_taxes,
                currency: currency,
                isValidated: isValidated,
                nbInvoices: total
            });
        
            promiseReturn.then(val => {
                let v = JSON.parse(val);
                if (v.status === 'success') {
                    window.location.href = "/freelance/operations";
                }
                else {
                    $(this).removeClass("st-no-btn-disabled");
                }
            });
        }
        else {
            window.location.href = "/freelance/operations";
        }
    }
});

// Showing block according to data supplier, supplier payment and approvals

let dataPayment = $(".supplierPaymentPage").attr("data-payment-validated");
let dataSupplier = $(".supplierPaymentPage").attr("data-supplier");
let dataApproval = $(".invoice-approval").attr("data-approval");
let dataPdf = $(".supplierPaymentPage").attr("data-pdf");
let fileName = $(".supplierPaymentPage").attr("data-filename");
let paymentId = $(".supplierPaymentPage").attr("data-paymentid");

$(window).on('load', function (e) {
    if (dataPdf !== ""  && dataPdf !== undefined) {
        let container = $("<div>", {
            class: "importContainer",
            id: "invoice-1"
        });
        let embedElt = $("<embed>", {
            src: dataPdf,
            type: "application/pdf",
            width: "100%",
            height: "600px",
            id: "document1",
            class: "importedDoc",
        });
        let deleteElt = $("<p>", {
            class: "deleteDoc cpointer fs14",
            id: "deleteDoc1",
            text: translation("Remplacer cette facture"),
        });

        if (window.location.pathname === '/freelance/fournisseur/paiement') {
            const getFileBlob = function (url, cb) {
                var xhr = new XMLHttpRequest();
                xhr.open("GET", url);
                xhr.responseType = "blob";
                xhr.addEventListener('load', function() {
                    cb(xhr.response);
                });
                xhr.send();
            };

            const blobToFile = function (blob, name) {
                blob.lastModifiedDate = new Date();
                blob.name = name;
                return blob;
            };

            const getFileObject = function(filePathOrUrl, cb) {
                getFileBlob(filePathOrUrl, function (blob) {
                    cb(blobToFile(blob, fileName));
                });
            };

            getFileObject(dataPdf, function (fileObject) {
                let file = new File([fileObject], fileName);
                ocr(e, file, undefined, undefined, true);
            });
        }


        $(embedElt).attr("data-paymentid", paymentId);
        let dataApproval = $(".invoice-approval").attr("data-approval");
        $(deleteElt).attr("data-file-id", paymentId).attr("data-target", (dataApproval === "true" ? ".popin-replaceInvoiceAppro" : ".popin-replaceInvoice"));
        $(container).append(embedElt);
        if (window.location.pathname.includes('/freelance/fournisseur/paiement') && !(window.location.pathname.includes('/recap'))) {
            $(container).append(deleteElt);
        }

        $(".js-blocImportBills").append(container);
        $(".st-blocImportFacture").hide();
        $(".js-blocImportBills, #invoice-1").show();

        if ($(window).width() <= 575) {
            if (dataPayment !== "true") {
                $(".st-footerMobile button").hide();
                $(".js-nextAfterImportBill").show();
            }
            else {
                $(".js-blocImportBills").hide();
                $(".st-blocNumberBills").removeClass("d-flex").addClass("d-none");
            }
        }

        $(".js-blocImportBills .importContainer").each(function() {
            let idEmbedElt = $(this).find(".importedDoc").attr("id");
            let idDeleteDoc = $(this).find(".deleteDoc").attr("id");
            $("#" + idDeleteDoc).on("click", function() {
                let popinName = $(this).attr("data-target");
                openPopin(popinName);
                $(".js-replaceInvoice, .js-replaceInvoiceAppro").on("click",() => {
                    let fileId = $(this).attr("data-file-id");
                    $(".js-uploadFile").removeAttr("multiple").attr("data-file-id", fileId).attr("data-embed-elt", idEmbedElt);
                    $(".js-uploadFile").trigger("click");
                });
            });
        });
    }
})


if (dataPayment === "true") {
    $(".invoice-infos, .js-formContact, .js-changeSupplier").hide();
    $(".js-blocContact, .invoice-approval, .st-blocPayment").show();
    $(".js-supplierPayment").attr("data-actif", "true").addClass("cpointer active");
    $(".js-supplierInfos").removeClass("active");
    $(".js-confirmLeaveSupplierForm").attr("data-stepTwo", "true");
    let getPriceTTC = $(".infos-totalTTC").val().replace(",", ".");
    $(".js-priceTTC").text(" " + Number(getPriceTTC).toLocaleString() + " €");
    if ($(window).width() <= 575) {
        $(".st-footerMobile button").hide();
        $(".st-footerMobile .st-saveQuit1, .st-footerMobile .js-toPayNow, .st-blocFormFacture").show();
    }
}

let tags;
let plans;
if (window.location.pathname.includes("/freelance/fournisseur/paiement") || window.location.pathname.includes("/freelance/fournisseur/fiche") || window.location.pathname === "/freelance/fournisseurs") {
    let promiseReturn = $.get('/freelance/get_tag');
    promiseReturn.then(function (val) {
        let v = JSON.parse(val);
        tags = v.tags;
        if (v.status === "success") {
            if (tags.length > 0) {
                tags.forEach(function (element) {
                    let name = element.tag;
                    let id = element.id;

                    let tagP = $("<p>", {
                        class: "tagP tagP" +id
                    });

                    let tag = $("<span>", {
                        text: name,
                        class: "js-tagAuto tag"+id
                    });

                    let deleteTagBlock = $("<span>", {
                        class: "st-deleteTagBlock"
                    });

                    $(tagP).append(tag);
                    $(tagP).append(deleteTagBlock);

                    $(tag).attr({
                        "data-id": id
                    });
                    $(deleteTagBlock).attr({
                        "data-id": id
                    });

                    $(tagP).hide();
                    $('.js-autocompleteTag').append(tagP);

                    $(tag).on('click', function () {
                        let input = $('.js-inputEtiquette');
                        $('.js-autocompleteTag').hide();
                        $('.js-selectedTag').removeClass('d-none').html(name + "<span class='st-removeFromInput'></span>");
                        input.attr('data-id', id);
                        input.trigger("input");
                        input.attr('disabled', true)
                        input.val(name)
                    })
                });
            }
            $('.js-autocompleteTag').append('<p class="js-tagAuto js-addTag"><img class="st-margin-right-5px" src="/build/images/addIcone_black.svg" width="14px" height="14px" alt="+">Ajouter "<span class="js-newTag"></span>"</p>')
        }
        else {
            $(".st-blocFormError1").removeClass("d-none").addClass("d-flex");
            $(".st-blocFormError1 .js-errorMsg").text(translation("Une erreur est survenue"));
        }
    })

    let promiseReturn2 = $.get('/freelance/get_plan');
    promiseReturn2.then(function (val) {
        let v = JSON.parse(val);
        if (v.status === "success") {
            plans = v.datas;
            plans.forEach(function (element) {
                $('.st-blockAccountingPlan').append("<div class='fs14 d-flex st-planElement' data-tag='"+ element.tag +"'><p class='st-planReference mb-0'>"+ element.reference +"</p><p class='st-planText mb-0'>"+ element.text +"</p></p>")
                let reference = element.reference;
                let text = element.text;
                let id = element.id;

                let input = $("<p>", {
                    class: "js-planAuto plan"+id,
                    text: reference + ' - ' + text
                });
                $(input).attr({
                    "data-id": id
                });

                $(input).on('click', function () {

                })
                $(input).hide();
                $('.js-autocompleteChargeAccount, .js-autocompleteBankAccount, .js-autocompleteChargeAccount, .js-autocompleteTaxAccount').append(input)
            });
            $('.js-autocompleteChargeAccount, .js-autocompleteBankAccount, .js-autocompleteChargeAccount, .js-autocompleteTaxAccount').append('<p class="js-planAuto js-addPlan"><img class="st-margin-right-5px" src="/build/images/addIcone_black.svg" width="14px" height="14px" alt="+">Ajouter "<span class="js-newPlan"></span>"</p>')
        }
        else {
            $(".st-blocFormError1").removeClass("d-none").addClass("d-flex");
            $(".st-blocFormError1 .js-errorMsg").text(translation("Une erreur est survenue"));
        }
    })
}
//Autocomplete des étiquettes
$('.js-inputEtiquette').on('input change', function () {
    let value = $(this).val();
    let addTag = $(".js-addTag");
    if (tags.length > 0) {
        let i = 0;
        tags.forEach(function (element) {
            let id = element.id;
            let tag = $(".tagP" + id);
            if (element.tag.toLowerCase().includes(value.toLowerCase()) && value.length > 0 && i < 10) {
                tag.addClass('d-flex');
                i++;
            }
            else {
                tag.removeClass('d-flex');
                tag.hide();
            }
        });
    }
    $('.js-autocompleteTag').show();
    addTag.show();
    $(document).on('click', function () {
        $('.js-autocompleteTag').hide();
    });

    if (value.length == 0) {
        $('.js-autocompleteTag').hide();
    }
    $('.js-newTag').html(value);
});

//Autocomplete des comptes de charges, banque et TVA pour la partie plan comptable.
$('#compteDeCharges, #compteBanqueText, #compteTva').on('input change', function () {
    $('.js-planAuto').attr('data-input', $(this).attr('id'));
    $('.js-addPlan').attr('data-input', $(this).attr('id'));
    let value = $(this).val();
    let blockToShow = $($(this).attr('data-block'));
    let i = 0;
    const regexp = new RegExp('^'+value);
    let idInput = $(this).attr('id');
    plans.forEach(function (element) {
        let id = element.id;
        let plan = $(".plan"+id)
        if ((element.text.toLowerCase().includes(value.toLowerCase()) || regexp.test(element.reference.toLowerCase())) && value.length > 0 && i < 10) {
            if ((idInput === "compteDeCharges" && element.reference.charAt(0) >= '6')
            || (idInput === "compteBanqueText" && element.reference.charAt(0) == '5')
            || (idInput === "compteTva" && element.reference.charAt(0) == '4')) {
                plan.show();
                i++;
            }
        }
        else {
            plan.hide();
        }
        blockToShow.show();
        $(document).on("click", function () {
            blockToShow.hide();
        });

    })
    if (value.length === 0) {
        blockToShow.hide();
    }
    $('.js-newPlan').html(value);
})

$(document).on('click', '.js-planAuto', function() {
    if (!$(this).hasClass('js-addPlan')) {
        $('#'+$(this).attr('data-input')).val($(this).text())
    }
})

$(document).on('click', '.st-addPlan', function () {
    $('.js-formAddPlan').show();
    $('.addPLanIcon').hide();
    $(this).addClass("st-formVisible");
})
$(document).on('click', '.js-addPlan', function () {
    openPopin('.popin-accountingPlan');
    $('.st-addPlan').trigger('click');
    $('.js-savePlan').attr({
        'data-flag': "true",
        'data-input': $(this).attr('data-input')
    });
})

$(document).on('input', '#planRef, #planText', function () {
    let reference = $('#planRef').val();
    let text = $('#planText').val();
    $(".popin-accountingPlan .st-blocPopinError").addClass("d-none").removeClass("d-flex");
    $(".popin-accountingPlan .js-errorMsg").text('');

    if (reference.length > 0 && text.length > 0) {
        $('.st-savePlan').removeClass('st-no-btn-disabled');
    }
    else {
        $('.st-savePlan').addClass('st-no-btn-disabled');
    }
})

$(document).on('click', '.js-savePlan', function () {
    $(this).addClass('st-no-btn-disabled');
    let reference = $("#planRef");
    let text = $("#planText");

    let promiseReturn = $.post('/freelance/add_to_plan', {
        reference: reference.val(),
        text: text.val()
    });

    promiseReturn.then(function (val) {
        let v = JSON.parse(val);
        if (v.status === "success") {
            let id = v.id;
            let tag = v.tag

            let input = $("<p>", {
                class: "js-planAuto plan"+id,
                text: reference.val() + ' - ' + text.val()
            });
            $(input).attr({
                "data-id": id
            });
            $('.js-addPlan').remove();
            $('.js-autocompleteChargeAccount, .js-autocompleteBankAccount, .js-autocompleteChargeAccount, .js-autocompleteTaxAccount').append(input)
            $('.js-autocompleteChargeAccount, .js-autocompleteBankAccount, .js-autocompleteChargeAccount, .js-autocompleteTaxAccount').append('<p class="js-planAuto js-addPlan"><img class="st-margin-right-5px" src="/build/images/addIcone_black.svg" width="14px" height="14px" alt="+">Ajouter "<span class="js-newPlan"></span>"</p>')

            let button = $('.js-savePlan');
            if (button.attr('data-flag') === "true") {
                closePopin('.popin-accountingPlan');
                $('#'+button.attr('data-input')).val(reference.val() + ' - ' + text.val())
            }
            plans.push({
                id: id,
                reference: reference.val(),
                tag: tag,
                text: text.val()
            });
            $('.st-addPlan').removeClass("st-formVisible");
            $('.addPLanIcon').show();
            $('.js-formAddPlan').hide();
            $(".st-blockPopinSuccess").removeClass("d-none").addClass("d-flex");
            $(".js-successMsg").text('Votre compte personnalisé a bien été intégré dans la liste');
            let newPlan = 
                `<div class="fs14 d-flex st-planElement" data-tag="FIELD">
                    <p class="st-planReference mb-0">${reference.val()}</p>
                    <p class="st-planText mb-0">${text.val()}</p>
                </div>`;
            let blockAccounting = $(".st-blockAccountingPlan");
            blockAccounting.append(newPlan).animate({scrollTop: blockAccounting[0].scrollHeight}, 1000);
            reference.val('');
            text.val('');
        }
        else {
            $(this).addClass('st-no-btn-disabled');
            $(".st-blocPopinError").removeClass("d-none").addClass("d-flex");
            $(".js-errorMsg").text(v.message);
        }
    })
});

$(document).on('click', '.st-crossPopinSuccess', function () {
    $('.st-blockPopinSuccess').removeClass('d-flex').addClass('d-none');
})

$(document).on('click', '.st-removeFromInput', function () {
    let input = $('.js-inputEtiquette');
    $('.js-selectedTag').html('').addClass('d-none');
    input.attr('disabled', false);
    input.attr('data-id', '');
    input.val('');
})

$(document).on('click', ".st-deleteTagBlock", function () {
    let id = $(this).attr("data-id");
    let promiseReturn = $.post('/freelance/delete_tag', {
        tag: id
    });

    promiseReturn.then(function (val) {
        let v = JSON.parse(val);
        if (v.status === "success") {
            $('.tagP'+id).remove();
        }
        else {
            let message = v.message;
            $(".st-blocFormError1").removeClass("d-none").addClass("d-flex");
            $(".js-errorMsg").text(message);
        }
    })
})

$(document).on("click", ".js-openAccountingPlan", function() {
    openPopin('.popin-accountingPlan');
})

$(document).on("click",'.js-addTag', function () {
    let inputVal = $(this).parent(".js-autocompleteTag").parent('.form-group').children('.js-inputEtiquette').val();
    let input = $(this).parent(".js-autocompleteTag").parent('.form-group').children('.js-inputEtiquette');
    if (inputVal.length > 0) {
        let promiseReturn = $.post('/freelance/create_tag', {
            tag: inputVal
        })
        promiseReturn.then(function (val) {
            let v = JSON.parse(val);
            let id = v.newTag['id'];
            let date = v.newTag['date'];
            if (v.status === "success") {
                let tagP = $("<p>", {
                    class: "tagP tagP"+id
                });

                let newTag = $("<span>", {
                    text: inputVal,
                    class: "js-tagAuto tag"+id
                });

                let deleteTagBlock = $("<span>", {
                    class: "st-deleteTagBlock"
                });
                $(tagP).append(newTag);
                $(tagP).append(deleteTagBlock);

                $(newTag).attr({
                    "data-id": id,
                })
                $(deleteTagBlock).attr({
                    "data-id": id
                });

                $('.js-addTag').remove();
                $('.js-autocompleteTag').append(tagP);
                $('.js-autocompleteTag').append('<p class="js-tagAuto js-addTag"><img class="st-margin-right-5px" src="/build/images/addIcone_black.svg" width="14px" height="14px" alt="+">Ajouter "<span class="js-newTag"></span>"</p>')
                $('.js-selectedTag').removeClass('d-none').html(inputVal + "<span class='st-removeFromInput'></span>");
                $('.js-autocompleteTag').hide();
                $('#comptaTag').attr('disabled', true);
                input.attr('disabled', true);
                tags.push({
                    tag: inputVal,
                    id: id,
                    createdAt: date
                });
                tagP.hide();
                $(tagP).on('click', function () {
                    let input = $('.js-inputEtiquette');
                    let name = $(this).find(".js-tagAuto").text();
                    $('.js-autocompleteTag').hide();
                    $('.js-selectedTag').removeClass('d-none').html(name + "<span class='st-removeFromInput'></span>");
                    input.attr('data-id', id);
                    input.trigger("input");
                    input.attr('disabled', true);
                    input.val(name);
                })
            }
            else {
                let message = v.message;
                $(".st-blocFormError1").removeClass("d-none").addClass("d-flex");
                $(".js-errorMsg").text(message);
            }
        })
    }
})

if (dataSupplier === "true") {
    $(".js-formContact").hide();
    $(".js-blocContact").show();
}

if (dataApproval === "true") {
    $(".st-blocSendEmail").removeClass("d-none").addClass("d-flex");
    $(".st-infoApproval, .st-formApproval").removeClass("d-flex").addClass("d-none");
    $(".st-sendApproval").show();
    if ($(window).width() <= 575) {
        $(".st-blocPayNow").css("margin-top", "-530px");
        $(".st-blocPayLater").css("margin-top", "-540px");
        $(".st-blocRadio1").css("margin-bottom", "340px");
    }
}

$(".js-supplierInfos").on("click", function() {
    $(this).addClass("active");
    $(".js-supplierPayment").removeClass("active");
    $(".invoice-approval, .st-blocPayment, .st-footerMobile button").hide();
    $(".js-changeSupplier, .invoice-infos, .js-showImportBill, .st-footerMobile .js-step1Supplier").show();
    if ($(".js-blocContact").attr("data-actif") === "true") {
        $(".js-blocContact").show();
    }
    else {
        $(".js-blocContact").hide();
        $(".js-formContact").show();
    }
});

$(".js-supplierPayment").on("click", function() {
    if ($(this).attr("data-actif") === "true") {
        $(this).addClass("active");
        $(".js-supplierInfos").removeClass("active");
        $(".js-blocContact, .invoice-approval, .st-blocPayment").show();
        $(".js-formContact, .js-changeSupplier, .invoice-infos").hide();
        $(".st-footerMobile button").hide();
        if ($("#js-payNow").is(":checked")) {
            $(".st-footerMobile .st-saveQuit1, .st-footerMobile .js-toPayNow").show();
        }
        else if ($("#js-payLater").is(":checked")) {
            $(".st-footerMobile .st-saveQuit2, .st-footerMobile .js-toPayLater").show();
        }
        else if ($("#js-alreadyPay").is(":checked")) {
            $(".st-footerMobile .st-saveQuit3, .st-footerMobile .js-toAlreadyPay").show();
        }
    }
});

$(".js-changeSupplier").on("click", () => {
    let validSupplier = $(".supplierPaymentPage").attr("data-payment-validated") === "true";
    let validApproval = $(".invoice-approval").attr("data-approval") === "true";
    if (validSupplier && validApproval) {
        openPopin(".popin-confirmModifyInfos");
    }
    else {
        $(".js-blocContact, .js-autocompleteSupplier").hide();
        $(".js-formContact").show();
        $(".js-blocContact").attr("data-actif", "false");
    }
});

// if (window.location.pathname === ("/freelance/fournisseur/paiement")) {
//     $(".js-request-updateSupplier").on("click", function() {
//         $(this).addClass("st-no-btn-disabled");
//         let supplierCompanyMail = $(".supplier-mail").val().trim();
//         let supplierCompanyName = $(".supplier-companyName").val();
//         let supplierCompanySiret = $(".supplier-siret").val();
//         let supplierCompanyIban = $(".supplier-iban").val();
//         let supplierContactFirstname = $(".supplier-name").val();
//         let supplierContactLastname = $(".supplier-lastname").val();
//         let supplierContactMail = $(".supplier-contactMail").val();
//         let supplierContactStatus = $(".supplier-contactStatus").attr("data-value");
//         let supplierComptaAccount = $(".supplier-comptaAccount").val();
//         let supplierComptaTags = $(".supplier-contactFirstname").val();
//         let supplierComptaDescription = $(".supplier-comptaDescription").val();
//
//         let promiseReturn = $.post("/freelance/supplier/create", {
//             supplierCompanyName: supplierCompanyName,
//             supplierCompanyMail: supplierCompanyMail,
//             supplierCompanySiret: supplierCompanySiret,
//             supplierCompanyIban: supplierCompanyIban,
//             supplierContactFirstname: supplierContactFirstname,
//             supplierContactLastname: supplierContactLastname,
//             supplierContactMail: supplierContactMail,
//             supplierContactStatus: supplierContactStatus,
//             supplierComptaAccount: supplierComptaAccount,
//             supplierComptaTags: supplierComptaTags,
//             supplierComptaDescription: supplierComptaDescription
//         });
//
//         promiseReturn.then(function(val) {
//             let v = JSON.parse(val);
//             if (v.status === 'success') {
//                 $('.popin').hide();
//                 $('.backdrop').hide();
//                 $(".js-contactCompany").text(supplierCompanyName);
//                 $(".js-contactSiret").text(supplierCompanySiret);
//                 $(".js-bankIban, .js-bankIban-later").val((iban === "") ? "" : supplierCompanyIban);
//                 $(".js-contact-supplier").text(((name === "") ? "-" : name) + " " + ((supplierContactLastname === "") ? "-" : supplierContactLastname.toUpperCase() ));
//                 $(".js-contactMail").text(supplierCompanyMail);
//                 $(".st-destEmail").val(supplierCompanyMail).removeClass("error");
//                 $(".js-blocContact").show().attr("data-actif", "true");
//                 $(".js-formContact").hide();
//                 $(".st-blocFormError1").removeClass("d-flex").addClass("d-none");
//                 $(".st-blocSendEmail").removeClass("d-none").addClass("d-flex");
//                 $('.js-compteFournisseur').html(v.id + ' - ' + supplierCompanyName);
//                 checkAllInputs(".invoice-infos", ".js-step1Supplier", [".st-destEmail"], true);
//             }
//             else {
//                 if (v.message.length > 0) {
//                     $(".popin-accountingPlan .st-blocPopinError").removeClass("d-none").addClass("d-flex");
//                     $(".popin-accountingPlan .js-errorMsg").text(v.message);
//                 }
//             }
//         });
//     });
// }


$(".js-selectDevise").val("EUR");

$(".js-selectDevise").on("change", function() {
    let getValue = $(this).val();
    (getValue !== "EUR") ? $(".st-supplierTVA").hide() : $(".st-supplierTVA").show();

    $(this).find("option").each(function() {
        if (getValue === $(this).val()) {
            let getSymbole = $(this).attr("data-symbole");
            $(".infos-totalHT, .infos-totalTTC").attr("placeholder", "Montant en " + getSymbole);
        }
    });
});

$(".st-blocImportFacture").on('drag dragend dragover dragenter dragleave drop', function(e) {
    e.preventDefault();
    e.stopPropagation();
}).on("drag drop", function(e){
    ocr(e, e.originalEvent.dataTransfer.files)
}).on("click", function(){
    $(".js-uploadFile").trigger("click");
});

$(".js-uploadFile").on("change", function(e) {
    let invoiceId = $(this).attr("data-file-id");
    let embedEltId = $(this).attr("data-embed-elt");
    ocr(e, e.target.files, invoiceId, embedEltId)
});

$(".js-importBillMobile").on("click", function() {
    $(".js-uploadFile").trigger("click");
});

let arrayInvoiceId = [];
let total;

const multiUploads = (fileNames, invoiceId = undefined, embedEltId = undefined) => {

    let datas = new FormData();

    fileNames.forEach(function(image) {
        let fileName = image.name;
        if (fileName.split(".")[fileName.split(".").length - 1] === "pdf" || fileName.split(".")[fileName.split(".").length - 1] === "png" || fileName.split(".")[fileName.split(".").length - 1] === "jpeg" || fileName.split(".")[fileName.split(".").length - 1] === "jpg" ) {
            datas.append("invoices[]", image);
            if (invoiceId !== undefined) {
                datas.append("id", invoiceId);
            }
            else {
                datas.append("id", null);
            }
        }
    });

    let promiseReturn = $.ajax({
        url: '/freelance/upload_invoice',
        data: datas,
        type: 'POST',
        contentType: false,
        processData: false,
    });

    promiseReturn.then(function(val) {
        let v = JSON.parse(val);
        if (v.status === "success") {
            $('.loader-upload').fadeOut()
            total = 0;
            closePopin();
            v.files.forEach((file, i) => {

                if (invoiceId !== undefined) {
                    let promiseReturnReplaceInvoice = $.post("/freelance/paiement_fournisseur/" + invoiceId + "/replace_invoice");
                    promiseReturnReplaceInvoice.then(function(val) {
                        let v = JSON.parse(val);
                        if (v.status === "success") {
                            $(".st-blocPayment, .st-sendApproval, .js-blocContact, .invoice-approval").hide();
                            $(".st-infoApproval, .st-formApproval").removeClass("d-flex").addClass("d-none");
                            $(".js-formContact, .invoice-infos").show();
                            $(".js-supplierPayment").removeClass("active cpointer").attr("data-actif", "false");
                            $(".js-supplierInfos").addClass("active");
                            $(".supplierPaymentPage").attr("data-payment-validated", "false");
                            $(".invoice-approval").attr("data-approval", "false");
                        }
                        else {
                        }
                    });
                }
        
                // We delete previous pdf if already upload
                if (embedEltId !== undefined) {
                    $("#" + embedEltId).attr("src", file.path);
                }
                else {
                    let container = $("<div>", {
                        class: "importContainer", 
                        id: "invoice-" + (i+1)
                    });
                    let embedElt = $("<embed>", {
                        src: file.path,
                        type: "application/pdf",
                        width: "100%",
                        height: "600px",
                        id: "document" + (i+1),
                        class: "importedDoc",
                    });
                    let deleteElt = $("<p>", {
                        class: "deleteDoc cpointer fs14",
                        id: "deleteDoc" + (i+1),
                        text: translation("Remplacer cette facture"),
                    });
                    $(embedElt).attr("data-paymentid", file.id);
                    let dataApproval = $(".invoice-approval").attr("data-approval");
                    $(deleteElt).attr("data-file-id", file.id).attr("data-target", (dataApproval === "true" ? ".popin-replaceInvoiceAppro" : ".popin-replaceInvoice"));
                    $(container).append(embedElt);
                    if (window.location.pathname.includes('/freelance/fournisseur/paiement') && !(window.location.pathname.includes('/recap'))) {
                        $(container).append(deleteElt);
                        $(".js-blocImportBills").append(container);
                    }
                    arrayInvoiceId.push("invoice-" + (i+1));
                }
                total++;
            });
            if (embedEltId === undefined) {
                $(".js-numberBills .js-totalBills").text(total);
                $(".st-blocImportFacture, .importContainer").hide();
                $(".js-blocImportBills, #invoice-1").show();
                $(".st-back-dashboard").attr("data-bill", "true");
                if (total > 1) {
                    $(".js-numberBills").show();
                    $(".st-numberInvoices").text(translation("vos") + " " + total + " " +  translation("factures importées"));
                    if (locale === "es") {
                        $(".msg-flash").html("<span>Las " + total + " facturas se han importado correctamente." + "</span>").addClass("container alert alert-success").css("color", "white").show();
                    }
                    else {
                        $(".msg-flash").html("<span>Les "+ total + " factures ont bien été importées.</span>").addClass("container alert alert-success").css("color", "white").show();
                    }
                    setTimeout(function(){ $(".msg-flash").text("").css("color", "initial").removeClass("container alert alert-success").hide() }, 6000);
                }
            }

            $(".js-blocImportBills .importContainer").each(function() {
                let idEmbedElt = $(this).find(".importedDoc").attr("id");
                let idDeleteDoc = $(this).find(".deleteDoc").attr("id");
                $("#" + idDeleteDoc).on("click", function() {
                    let popinName = $(this).attr("data-target");
                    openPopin(popinName);
                    $(".js-replaceInvoice, .js-replaceInvoiceAppro").on("click",(e) => {
                        let fileId = $(this).attr("data-file-id");
                        $(".js-uploadFile").removeAttr("multiple").attr("data-file-id", fileId).attr("data-embed-elt", idEmbedElt);
                        $(".js-uploadFile").trigger("click");
                        e.stopImmediatePropagation();
                    });
                });
            });
            checkAllInputs(".invoice-infos", ".js-step1Supplier", [".st-destEmail"], true);
            $(".st-blocFormErrorImport").removeClass("d-flex").addClass("d-none");

            if ($(window).width() <= 575) {
                $(".js-importBillMobile").hide();
                $(".js-nextAfterImportBill").css("display", "block");
            }
        }
        else {
            let message = v.message;
            $('.st-blocFormErrorImport').html(message).removeClass("d-none").addClass("d-flex");
        }
    });
}

// Manage button Responsive 
$(".js-nextAfterImportBill").on("click", function() {
    $(this).hide();
    $(".js-blocImportBills, .st-titleBillSupplier, .js-numberBills").hide();
    $(".st-blocFormFacture").show();
    $(".st-footerMobile .js-step1Supplier, .js-showImportBill").show();
});

$(".js-showImportBill").on("click", function() {
    $(this).hide();
    $(".js-blocImportBills, .js-nextAfterImportBill, .st-titleBillSupplier, .js-numberBills").show();
    $(".st-blocFormFacture, .st-footerMobile .js-step1Supplier").hide();
    $(".st-blocFormErrorImport").removeClass("d-flex").addClass("d-none");
});

// calcul and showing TVA 
$(".st-blocFormFacture .verif-ttc").on("input change", function() {
    let valueHT = $(".st-blocFormFacture .verif-ht").val().replace(",", ".");
    let valueTTC = $(this).val().replace(",", ".");

    if (valueHT !== "" && valueTTC !== "") {
        let calculTVA = (Number(valueTTC) - Number(valueHT)).toLocaleString();
        $(".st-supplierTVA span").text(calculTVA);
    }
});

$(".st-blocFormFacture .verif-ht").on("input change", function() {
    let valueTTC = $(".st-blocFormFacture .verif-ttc").val().replace(",", ".");
    let valueHT = $(this).val().replace(",", ".");

    if (valueHT !== "" && valueTTC !== "") {
        let calculTVA = (Number(valueTTC) - Number(valueHT)).toLocaleString();
        $(".st-supplierTVA span").text(calculTVA);
    }
});

// Manage autocomplete

let suppliers;

if (window.location.pathname === "/freelance/fournisseur/paiement") {
    $('.loader').fadeIn();
    let promiseReturn = $.get("/freelance/supplier/autocomplete");

    promiseReturn.then(val => {
        let v = JSON.parse(val);
        if (v.status === "success") {
            suppliers = v.suppliers;
            suppliers.forEach(function(element) {
                let firstname = element.firstname ? element.firstname : "-";
                let lastname = element.lastname ? element.lastname : "-";
                let contentText;
                if (!element.firstname && !element.lastname) {
                    contentText = element.email;
                }
                else {
                    contentText = firstname + " " + lastname.toUpperCase();
                }
                
                let supplier = $("<p>", {
                    text: contentText,
                    id: "supplierAuto" + element.id,
                    class: "js-supplierAuto"
                });
                $(supplier).attr({
                    "data-email": element.email,
                    "data-business-name": element.business_name,
                    "data-siret": element.siret,
                    "data-firstname": firstname,
                    "data-lastname": lastname
                });
                $(supplier).on("click", function(e) {
                    e.stopPropagation();
                    $(".js-contactCompany, .js-contactSiret, .js-contactName, .js-contactLastname, .js-contactMail").text("");
                    $(".js-contactCompany").text(element.business_name);
                    $(".js-contactSiret").text(element.siret);
                    // $(".js-contactName").text((firstname === "") ? "-" : firstname);
                    $(".js-contact-supplier").text(((firstname === "") ? "-" : firstname) + " " + ((lastname === "") ? "-" : lastname.toUpperCase() ));
                    $(".js-contactMail").text(element.email);
                    $(".st-destEmail").val(element.email).removeClass("error");
                    $(".js-bankIban, .js-bankIban-later").val((element.iban === "") ? "" : element.iban);
                    $(".js-blocContact").show().attr("data-actif", "true");
                    $(".st-blocFormError1").removeClass("d-flex").addClass("d-none");
                    $(".js-formContact").hide();
                    if (element.business_name !== null) {
                        $('.js-compteFournisseur').html(element.id + ' - ' + element.business_name);
                    }
                    else {
                        $('.js-compteFournisseur').html(element.id);
                    }
                    checkAllInputs(".invoice-infos", ".js-step1Supplier", [".st-destEmail"], ($(".js-blocImportBills").html() !== ""));
                });
                $(supplier).hide();
                $(".js-autocompleteSupplier").append(supplier);

                $(window).on("click", function() {
                    $(".js-autocompleteSupplier").hide();
                });
            });
            if (getUrlParams(window.location.href)['fournisseur']) {
                let id = getUrlParams(window.location.href)['fournisseur'];
                $('#supplierAuto'+id).trigger('click');
                $('.loader').fadeOut();
            }
        }
        else {
            if (v.message) {
                console.log(v.message);
            }
        }
    });
}

$(".st-destEmail").on("input change", function(e) {
    let value = $(this).val();
    suppliers.forEach(function(element) {
        $('#'+"supplierAuto" + element.id).hide();
    });
    $(".js-autocompleteSupplier").show();
    const filteredSuppliers = suppliers.filter(
        s => 
            s.email !== null && s.email.toLowerCase().includes(value.toLowerCase()) ||
            s.firstname !== null && s.firstname.toLowerCase().includes(value.toLowerCase()) ||
            s.lastname !== null && s.lastname.toLowerCase().includes(value.toLowerCase())
    );
    filteredSuppliers.forEach(element => {
        $('#'+"supplierAuto" + element.id).show();
    });
});

$(".st-bankNotFound").on("click", function() {
    $(".st-bankNotFound").hide();
    $(".st-virementClassic").show();
});

// step2

$(".st-crossInfos").on("click", function() {
    $(".st-infoApproval, .st-infoCodePromo").removeClass("d-flex").addClass("d-none").attr("data-deleted", "true");
});

$(".js-downApproval").on("click", function() {
    $(this).hide();
    $(".js-upApproval").show();
    if ($(".invoice-approval").attr("data-approval") === "true") {
        $(".st-sendApproval").show();
    }
    else {
        $(".st-formApproval").removeClass("d-none").addClass("d-flex");
        if ($(".st-infoApproval").attr("data-deleted") === "false") {
            $(".st-infoApproval").removeClass("d-none").addClass("d-flex");
        }
        if ($(".st-blocFormError2").attr("data-actif") === "true") {
            $(".st-blocFormError2").removeClass("d-none").addClass("d-flex");
        }
    }
});
$(".js-upApproval").on("click", function() {
    $(this).hide();
    $(".js-downApproval").show();
    $(".st-formApproval, .st-infoApproval, .st-blocFormError2").removeClass("d-flex").addClass("d-none");
    $(".st-sendApproval").hide();
});

$('.js-downCompta').on('click', function () {
    $(this).hide();
    $('.js-upCompta').show();
    $(".invoiceCompta").slideDown();
});
$('.js-upCompta').on('click', function () {
    $(this).hide();
    $('.js-downCompta').show();
    $(".invoiceCompta").slideUp();
});

$('.js-downResume').on('click', function () {
    $(this).hide();
    $('.js-upResume').show();
    $(".invoice-resume").slideDown();
});
$('.js-upResume').on('click', function () {
    $(this).hide();
    $('.js-downResume').show();
    $(".invoice-resume").slideUp();
});

$(".js-step1Supplier").on("click", function() {
    $(this).addClass("st-no-btn-disabled");
    let invoice_number = $(".invoice-number").val();
    let invoice_date = $(".invoice-sent-date").val().replaceAll("/", "-");
    let due_date = $(".invoice-due-date").val().replaceAll("/", "-");
    let amount_without_taxes = $(".infos-totalHT").val();
    let amount_with_taxes = $(".infos-totalTTC").val();
    let currency = $(".js-selectDevise").val();
    let supplier = $(".st-destEmail").val().trim();
    let supplierPayment_id = getSupplierPaymentId();
    let expenseAccount = $('#compteDeCharges').val();
    let bankAccount = $('#compteBanqueText').val();
    let taxesAccount = $('#compteTva').val();
    let comptaTag = $('#comptaTag').val();
    let supplierAccount = $('.js-compteFournisseur').text();

    if ((new Date(getValidFormatDate(invoice_date, '-'))).getTime() <= (new Date(getValidFormatDate(due_date, '-'))).getTime()) {

        if (supplierPayment_id !== undefined) {
            let promiseReturn = $.post("/freelance/supplier_payment/"+supplierPayment_id+"/create", {
                supplier: supplier,
                invoice_number: invoice_number,
                invoice_date: invoice_date,
                due_date: due_date,
                amount_without_taxes: amount_without_taxes,
                amount_with_taxes: amount_with_taxes,
                currency: currency,
                isValidated: 1,
                expenseAccount: expenseAccount,
                bankAccount: bankAccount,
                taxesAccount: taxesAccount,
                supplierAccount: supplierAccount,
                comptaTag: comptaTag
            });

            promiseReturn.then(function(val) {
                let v = JSON.parse(val);
                if (v.status === 'success') {
                    $(".js-contactMail").text(supplier);
                    $(".js-blocContact, .invoice-approval, .st-blocPayment").show();
                    $(".js-formContact, .js-changeSupplier, .invoice-infos").hide();
                    let getPriceTTC = $(".infos-totalTTC").val().replace(",", ".");
                    $(".js-priceTTC").text(" " + Number(getPriceTTC).toLocaleString() + " €");
                    $(".js-supplierPayment").attr("data-actif", "true").addClass("cpointer active");
                    $(".js-supplierInfos").removeClass("active");
                    $(".supplierPaymentPage").attr("data-payment-validated", "true");
                    $(".js-toAlreadyPay").removeClass("st-no-btn-disabled");
                    $(".js-confirmLeaveSupplierForm").attr("data-stepTwo", "true");

                    if ($(window).width() <= 575) {
                        $(".st-footerMobile .js-step1Supplier, .js-showImportBill").hide();
                        $(".st-footerMobile .st-saveQuit1, .st-footerMobile .js-toPayNow").show();
                        $("body").scrollTop(0);
                    }
                }
                else {
                    $(".js-step1Supplier").removeClass("st-no-btn-disabled");
                    if (v.message.length > 0) {
                        $(".st-blocFormError1").removeClass("d-none").addClass("d-flex");
                        $(".st-blocFormError1 .js-errorMsg").text(v.message);
                    }
                }
            });
        }
        else {
            $(".st-blocFormErrorImport").removeClass("d-none").addClass("d-flex");
        }
    } else {
        $(".st-blocFormError2").removeClass("d-none").addClass("d-flex");
        $(".st-blocFormError2 .js-errorMsg").text(translation("Date de facturation inférieure"));
    }

});

$(".js-send-approval-request").on("click", function() {
    $(this).addClass("st-no-btn-disabled");
    let mail = $(".st-destApprovalEmail").val();
    let dueDate = $(".approval-due-date").val().replaceAll("/", "-");
    let supplierPayment_id = getSupplierPaymentId();

    let promiseReturn = $.post("/freelance/supplier_payment/"+supplierPayment_id+"/approval/create", {
        approval_email: mail,
        due_date: dueDate
    });

    promiseReturn.then(function(val) {
        let v = JSON.parse(val);
        if (v.status === 'success') {
            $(".st-formApproval, .st-infoApproval, .st-blocGlobalFakeLink").removeClass("d-flex").addClass("d-none");
            $(".st-blocLink").removeClass("d-none").addClass("d-flex");
            $(".st-sendApproval").show();
            $(".st-approvalMail").text(mail);
            $(".st-linkAppro").text(v.link);
            $(".invoice-approval").attr("data-approval", "true");
            $(".deleteDoc").attr("data-target", ".popin-replaceInvoiceAppro");
            $(".st-approvalStatus").text(translation("Approbation en attente")).attr("data-status", "CREATED").attr("data-approvalid", v.id);
            $(".st-iconeAppro").attr("src", "/build/images/waiting.svg");
            $(".st-approvalComment").text(translation("Aucun commentaire pour le moment..."));
            $(".st-blocGlobalFakeLink").hide();
        }
        else {
            $(this).removeClass("st-no-btn-disabled");
            if (v.message.length > 0) {
                $(".st-blocFormError2").removeClass("d-none").addClass("d-flex");
                $(".st-blocFormError2 .js-errorMsg").text(v.message);
            }
        }
    });
});

$(".st-relanceAppro").on("click", function() {
    $(".st-sendApproval").hide();
    $(".st-formApproval").removeClass("d-none").addClass("d-flex");
    if ($(".st-infoApproval").attr("data-deleted") === "false") {
        $(".st-infoApproval").removeClass("d-none").addClass("d-flex");
    }
});


if (window.location.pathname === "/freelance/fournisseur/paiement") {
    let promiseReturnBanksLater = $.post('/bridge/find_all_banks', {
        hasScheduledPayments: 1
    });
    promiseReturnBanksLater.then(function(val) {
        let v = JSON.parse(val);
        if (v.status === 'success') {
            for (let i=0; i < v.banks.length; i++) {
                $('.supplierPaymentPage .select-bank-later').append('<option value="'+ v.datas[i].id +'"> '+ v.datas[i].name +' </option>')
            }
        }
    });
}

$("#js-payNow").attr("checked", true);
$("#js-payNow").prop("checked", true);

$("#js-payNow").on("click", function() {
    $(".st-blocRadio2, .st-blocRadio3").css("background-color", "transparent");
    $(".st-blocRadio1").css("background-color", "#F8F8F8");
    $(".st-blocPayNow").show();
    $(".st-blocPayLater, .st-blocAlreadyPay").hide();
    $(".js-payWaitingApprobation, .js-payRefusApprobation").attr("data-target", ".paySupplierNow");
    if ($(window).width() <= 575) {
        $(".st-footerMobile .js-toPayLater, .st-footerMobile .st-saveQuit2, .st-footerMobile .st-saveQuit3, .st-footerMobile .js-toAlreadyPay").hide();
        $(".st-footerMobile .st-saveQuit1, .st-footerMobile .js-toPayNow").show();
        if (dataApproval === "true") {
            $(".st-blocRadio1").css("margin-bottom", "360px");
        }
        else {
            $(".st-blocRadio1").css("margin-bottom", "300px");
        }
        $(".st-blocRadio2").css("margin-bottom", "30px");
        $(".st-blocRadio3").css("margin-bottom", "100px");
    }
});

$("#js-payLater").on("click", function() {
    $(".st-blocRadio1, .st-blocRadio3").css("background-color", "transparent");
    $(".st-blocRadio2").css("background-color", "#F8F8F8");
    $(".st-blocPayNow, .st-blocAlreadyPay").hide();
    $(".st-blocPayLater").show();
    // if ($(".st-infoPayLater").attr("data-deleted") === "false") {
    //     $(".st-infoPayLater").removeClass("d-none").addClass("d-flex");
    // }
    $(".js-payWaitingApprobation, .js-payRefusApprobation").attr("data-target", ".paySupplierLater");
    if ($(window).width() <= 575) {
        $(".st-footerMobile .st-saveQuit1, .st-footerMobile .js-toPayNow, .st-footerMobile .st-saveQuit3, .st-footerMobile .js-toAlreadyPay").hide();
        $(".st-footerMobile .st-saveQuit2, .st-footerMobile .js-toPayLater").show();
        if (dataApproval === "true") {
            $(".st-blocRadio2").css("margin-bottom", "420px");
        }
        else {
            $(".st-blocRadio2").css("margin-bottom", "400px");
        }
        $(".st-blocRadio1").css("margin-bottom", "30px");
        $(".st-blocRadio3").css("margin-bottom", "100px");
    }
});

$("#js-alreadyPay").on("click", function() {
    $(".st-blocRadio1, .st-blocRadio2").css("background-color", "transparent");
    $(".st-blocRadio3").css("background-color", "#F8F8F8");
    $(".st-blocPayNow, .st-blocPayLater").hide();
    $(".st-blocAlreadyPay").show();
    if ($(window).width() <= 575) {
        $(".st-footerMobile .st-saveQuit1, .st-footerMobile .js-toPayNow, .st-footerMobile .st-saveQuit2, .st-footerMobile .js-toPayLater").hide();
        $(".st-footerMobile .st-saveQuit3, .st-footerMobile .js-toAlreadyPay").show();
        $(".st-blocRadio1").css("margin-bottom", "30px");
        $(".st-blocRadio2").css("margin-bottom", "30px");
        $(".st-blocRadio3").css("margin-bottom", "0px");
    }
});

$(".js-toPayNow").on("click", function() {
    let approvalStatus = $(".st-approvalStatus").attr("data-status");
    if ($(this).attr("data-blocked") === "true") {
        openPopin(".popin-additionnalPayment");
    }
    else if (approvalStatus === "CREATED") {
        openPopin(".popin-payWaitingApprobation");
    }
    else if (approvalStatus === "DENIED") {
        openPopin(".popin-payRefusApprobation");
    }
    else {
        $(".paySupplierNow").trigger("click");
        $(this).addClass("st-no-btn-disabled");
    }
});

// Call bridge to pay now 
$(".paySupplierNow").on("click", function(e) {
    $(".js-toPayNow, .js-payWaitingApprobation, .js-payRefusApprobation").addClass(".st-no-btn-disabled");
    let input = $('.js-type-bank');
    let bankId = input.attr('data-id');
    let bankOwner = $(".js-bankOwner").val();
    let country = input.attr('data-country');
    let bankName = input.val();
    let iban = $(".js-bankIban").val();
    let supplierPayment_id = getSupplierPaymentId();

    let sentEmailAppro = 0;
    if($("#js-sendEmailAppro").is(":checked")) {
        sentEmailAppro = 1;
    }

    let route = "/virement";

    if (country === "ES") {
        route = "/virement_exthand"
    }

    let promiseReturn = $.post("/freelance/paiement_fournisseur/" + supplierPayment_id + route, {
        bankId: bankId,
        bankName: bankName,
        bankOwner: bankOwner,
        iban: iban,
        sentEmailAppro: sentEmailAppro
    });
    
    promiseReturn.then(function(val) {
        let v = JSON.parse(val);
        if (v.status === 'success') {
            window.location.href = v.url;
        } else {
            $(".js-toPayNow, .js-payWaitingApprobation, .js-payRefusApprobation").removeClass("st-no-btn-disabled");
            if (v.message) {
                let approvalStatus = $(".st-approvalStatus").attr("data-status");

                if (approvalStatus === "CREATED" || approvalStatus === "DENIED") {
                    $(".st-blocPopinError").removeClass("d-none").addClass("d-flex");
                    $(".st-blocPopinError .js-errorMsg").text(v.message);
                }
                else {
                    $(".st-blocFormError3").removeClass("d-none").addClass("d-flex");
                    $(".st-blocFormError3 .js-errorMsg").text(v.message);
                }
            }
        }
    });
});

$(".js-toPayLater").on("click", function() {
    let approvalStatus = $(".st-approvalStatus").attr("data-status");

    if ($(this).attr("data-blocked") === "true") {
        openPopin(".popin-additionnalPayment");
    }
    else if (approvalStatus === "CREATED") {
        openPopin(".popin-payWaitingApprobation");
    }
    else if (approvalStatus === "DENIED") {
        openPopin(".popin-payRefusApprobation");
    }
    else {
        $(".paySupplierLater").trigger("click");
        $(this).addClass("st-no-btn-disabled");
    }
});

// Call bridge to pay later
$(".paySupplierLater").on("click", function() {
    $(".js-toPayLater, .js-payWaitingApprobation, .js-payRefusApprobation").addClass(".st-no-btn-disabled");
    let bankId = $('.st-blocPayLater .select-bank-later').val();
    let bankOwner = $(".js-bankOwner-later").val();
    let iban = $(".js-bankIban-later").val();
    let delayedDate = $(".invoice-sent-later").val();
    let supplierPayment_id = getSupplierPaymentId();

    let sentEmailAppro = 0;
    if($("#js-sendEmailApproLater").is(":checked")) {
        sentEmailAppro = 1;
    }

    let promiseReturn = $.post("/freelance/paiement_fournisseur/" + supplierPayment_id + "/virement", {
        bankId: bankId, 
        bankOwner: bankOwner,
        iban: iban,
        sentEmailAppro: sentEmailAppro,
        delayedPaymentDate: delayedDate
    });
    
    promiseReturn.then(function(val) {
        let v = JSON.parse(val);
        if (v.status === 'success') {
            window.location.href = v.url;
        } else {
            $(".js-toPayLater, .js-payWaitingApprobation, .js-payRefusApprobation").removeClass("st-no-btn-disabled");
            if (v.message) {
                let approvalStatus = $(".st-approvalStatus").attr("data-status");

                if (approvalStatus === "CREATED" || approvalStatus === "DENIED") {
                    $(".st-blocPopinError").removeClass("d-none").addClass("d-flex");
                    $(".st-blocPopinError .js-errorMsg").text(v.message);
                }
                else {
                    $(".st-blocFormError3").removeClass("d-none").addClass("d-flex");
                    $(".st-blocFormError3 .js-errorMsg").text(v.message);
                }
            }
        }
    });
});

$(".js-payWaitingApprobation, .js-payRefusApprobation").on("click", function() {
    let dataTarget = $(this).attr("data-target");
    $(dataTarget).trigger("click");
});

// Mark as payed 
$(".js-toAlreadyPay").on("click", function() {
    $(this).addClass("st-no-btn-disabled");
    let supplierPayment_id;
    let invoiceIdBloc;
    if ($(window).width() <= 575) {
        if ($(".js-blocImportBills embed").length > 0) {
            supplierPayment_id = $(".js-blocImportBills embed").first().attr("data-paymentid");
            invoiceIdBloc = $(".js-blocImportBills .importContainer").first().attr("id");
        }
    }
    else {
        $(".js-blocImportBills .importContainer").each(function() {
            if ($(this).is(":visible")) {
                supplierPayment_id = $(this).find("embed").attr("data-paymentid");
                invoiceIdBloc = $(this).attr("id");
            }
        });
    }
    
    let isFlash = 0;
    if(($(".js-blocImportBills .importContainer").length -1) === 0) {
        isFlash = 1;
    }

    let promiseReturn = $.post("/freelance/paiement_fournisseur/" + supplierPayment_id + "/mark_as_payed", {
        isFlash: isFlash
    });

    promiseReturn.then(val => {
        let v = JSON.parse(val);
        if (v.status === 'success') {
            $("#" + invoiceIdBloc).remove();
            arrayInvoiceId.shift();
            let nextInvoice = arrayInvoiceId[0];
            $("#" + nextInvoice).show();

            if ($(".js-blocImportBills .importContainer").length > 0) {
                $(".st-blocPayment, .st-sendApproval, .js-blocContact, .invoice-approval").hide();
                $(".st-blocPayment input, .st-formApproval input, .invoice-infos input, .st-destEmail").val("");
                $(".st-infoApproval, .st-formApproval").removeClass("d-flex").addClass("d-none");
                $(".js-formContact, .invoice-infos").show();
                let dateNow = $(".invoice-sent-date").attr("data-date");
                let date7Days = $(".approval-due-date").attr("data-date");
                let date1month = $(".invoice-due-date").attr("data-date");
                $(".invoice-sent-date, .invoice-sent-later").val(dateNow);
                $(".approval-due-date").val(date7Days);
                $(".invoice-due-date").val(date1month);
                let oldValue = $(".js-eachBills").text();
                $(".js-eachBills").text(Number(oldValue) + 1);
                $(".js-supplierPayment").removeClass("active cpointer").attr("data-actif", "false");
                $(".js-blocContact").attr("data-actif", "false");
                $(".js-supplierInfos").addClass("active");
                $(".msg-flash").html("<span>"+ translation("Votre paiement a bien été marqué comme payé.") + "</span>").addClass("container alert alert-success").css("color", "white").show();
                setTimeout(function(){ $(".msg-flash").text("").css("color", "initial").removeClass("container alert alert-success").hide() }, 6000);
                if ($(window).width() <= 575) {
                    $(".st-footerMobile button").hide();
                    $(".js-showImportBill").trigger("click");
                }
            }
            else {
                $(".js-numberBills, .js-blocImportBills").hide();
                $(".st-blocImportFacture").show();
                window.location.href = "/freelance/operations";
            }
        }
        else {
            $(this).removeClass("st-no-btn-disabled");
        }
    });
});

// Detect any change in the page and show confirm popin

$(".js-formContact input, .invoice-infos input, .invoice-infos select").on("input paste change focus", function() {
    let validSupplier = $(".supplierPaymentPage").attr("data-payment-validated") === "true";
    let validApproval = $(".invoice-approval").attr("data-approval") === "true";
    if (validSupplier && validApproval) {
        $(".flatpickr-calendar").css("display", "none");
        $(this).trigger("blur");
        openPopin(".popin-confirmModifyInfos");
    }
});

$(".js-formContact input, .invoice-infos input, .invoice-infos select").on("input change", function() {
    if ($(".supplierPaymentPage").attr("data-payment-validated") === "true") {
        $(".js-supplierPayment").removeClass("cpointer").attr("data-actif", "false");
    }
});


$(".js-confirmModifyInfos").on("click", function() {
    $(this).addClass("st-no-btn-disabled");
    let supplierPayment_id = getSupplierPaymentId();
    let approvalId = $(".st-approvalStatus").attr("data-approvalid");

    let promiseReturn = $.post("/freelance/paiement_fournisseur/" + supplierPayment_id + "/approval/" + approvalId + "/denie");

    promiseReturn.then(val => {
        let v = JSON.parse(val);
        if (v.status === 'success') {
            $(".supplierPaymentPage").attr("data-payment-validated", "false");
            $(".js-supplierPayment").removeClass("active cpointer").attr("data-actif", "false");
            $(".flatpickr-calendar").css("display", "block");
            closePopin();
        }
        else {
            $(this).removeClass("st-no-btn-disabled");
        }
    });
});

$(".js-cancelModifyInfos").on("click", function() {
    closePopin();
});

$(".st-saveQuit1").on("click", function() {
    $(this).addClass("st-no-btn-disabled");
    let iban = $(".js-bankIban").val();
    saveAndQuit(iban, $(this));
});

$(".st-saveQuit2").on("click", function() {
    $(this).addClass("st-no-btn-disabled");
    let iban = $(".js-bankIban-later").val();
    saveAndQuit(iban, $(this));
});

$(".st-saveQuit3").on("click", function() {
    $(this).addClass("st-no-btn-disabled");
    let iban;
    if ($(".js-bankIban").val() !== "") {
        iban = $(".js-bankIban").val();
    }
    else {
        iban = $(".js-bankIban-later").val();
    }
    saveAndQuit(iban, $(this));
});

if ($(window).width() <= 575) {

    const cssPayNow = (visibility) => {
        if (dataApproval === "true") {
            if ($(".st-blocPayNow .st-blocFormError3").is(visibility)) {
                $(".st-blocRadio1").css("margin-bottom", "400px");
                $(".st-blocPayNow").css("margin-top", "-590px");
            }
            else {
                $(".st-blocRadio1").css("margin-bottom", "340px");
                $(".st-blocPayNow").css("margin-top", "-530px");
            }
        }
        else {
            if ($(".st-blocPayNow .st-blocFormError3").is(visibility)) {
                $(".st-blocRadio1").css("margin-bottom", "360px");
                $(".st-blocPayNow").css("margin-top", "-550px");
            }
            else {
                $(".st-blocRadio1").css("margin-bottom", "300px");
                $(".st-blocPayNow").css("margin-top", "-490px");
            }
        }
    }

    const cssPayLater = (visibility) => {
        if (dataApproval === "true") {
            if ($(".st-blocPayLater .st-blocFormError3").is(visibility)) {
                $(".st-blocRadio2").css("margin-bottom", "480px");
                $(".st-blocPayLater").css("margin-top", "-600px");
            }
            else {
                $(".st-blocRadio2").css("margin-bottom", "420px");
                $(".st-blocPayLater").css("margin-top", "-540px");
            }
        }
        else {
            if ($(".st-blocPayLater .st-blocFormError3").is(visibility)) {
                $(".st-blocRadio2").css("margin-bottom", "460px");
                $(".st-blocPayLater").css("margin-top", "-580px");
            }
            else {
                $(".st-blocRadio2").css("margin-bottom", "400px");
                $(".st-blocPayLater").css("margin-top", "-520px");
            }
        }
    }

    $(".st-blocPayNow input").on("input change", function() {
        cssPayNow(":visible");
    });
    
    $(".st-blocPayLater input").on("input change", function() {
        cssPayLater(":visible");
    });
    
    $(".st-blocPayNow .st-blocFormError3 .st-crossPopinError").on("click", function() {
        cssPayNow(":hidden");
    });

    $(".st-blocPayLater .st-blocFormError3 .st-crossPopinError").on("click", function() {
        cssPayLater(":hidden");
    });
}

const saveAndQuit = (iban, button) => {
    let supplierPayment_id = getSupplierPaymentId();
    
    let promiseReturn = $.post("/freelance/paiement_fournisseur/" + supplierPayment_id + "/save_and_leave", {
        nbInvoices: total,
        iban: iban
    });

    promiseReturn.then(val => {
        let v = JSON.parse(val);
        if (v.status === 'success') {
            window.location.href = "/freelance/operations";
        }
        else {
            button.removeClass("st-no-btn-disabled");
        }
    });
}

const getSupplierPaymentId = () => {
    if ($(window).width() <= 575) {
        if ($(".js-blocImportBills embed").length > 0) {
            return $(".js-blocImportBills embed").first().attr("data-paymentid");
        }
    }
    else {
        let result;
        $(".js-blocImportBills embed").each(function() {
            if ($(this).is(":visible")) {
                result = $(this).attr("data-paymentid");
            }
        });
        return result;
    }
}

// Verifications front supplierPayment
if ($("body").attr("data-currentpage") === "freelance_supplier_payment") {
    checkAllInputs(".invoice-infos", ".js-step1Supplier", [".st-destEmail"]);
}

$(".invoice-infos .verif-front, .st-destEmail").on("input change focus select", function() {
    checkAllInputs(".invoice-infos", ".js-step1Supplier", [".st-destEmail"]);
});

$(".popin-addSupplier .verif-front").on("input change", function() {
    checkAllInputs(".popin-addSupplier .popin-content", ".js-addSupplierRequest");
});

$(".invoice-approval .verif-front").on("input change", function() {
    checkAllInputs(".invoice-approval", ".js-send-approval-request");
});

$(".st-blocPayNow .verif-front").on("input change", function() {
    checkAllInputs(".st-blocPayNow", ".js-toPayNow");
});

$(".st-blocPayLater .verif-front").on("input change", function() {
    checkAllInputs(".st-blocPayLater", ".js-toPayLater");
});

