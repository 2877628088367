import Popin from "../Popin";
import PopinStep from "../PopinStep";
import {inputFrontValidation} from "../../frontendValidations";
import {sendPaymentFacture, generatePaymentLink, copyLink} from "../../components/forms/paymentEfacturePopinForm";
import {submitNewBank, openAddBank, loadTrumbowygEditor} from "../../../common";
import {setBankToDefault} from "../../components/isDefaultBankAccount";
import {translation, numberWithSpacesWithComa} from "../../generalFunctions";
import {projectPayment} from "../../pages/projectPayment";
import {flatpickrPlugin} from "../../../plugins/flatpickr";
import $ from "jquery";
import {dragAndDrop} from "../../components/forms/paymentEfacturePopinForm";
import {manageCustomEmailPopin} from "../../components/popins/popin";
import {verificationFront} from "../../components/verificationFront";

export let popinPaymentBystep;
export let step1, step2, step3, step3bis1, step3bis2, step4;
export let getTemplateStep3bis1 = $("#paymentByStep-stepBillPrevisu").html();

// Getting route with project id if we aren't on home and load autocomplete of users
let getIdProject;
let getRoute = window.location.pathname.match(/\d/g);
let hasbank;

if (getRoute === null) {
    getIdProject = "";
}
else {
    getIdProject = getRoute.join("");
}

function autocompleteUsers() {
    $(".js-bills-relaunch").attr("data-idproject", getIdProject);
    if ($("body").attr("data-currentpage") === "freelance_payment_index") {
        let promiseReturn = $.get(`/freelance/projet/${getIdProject}/autocomplete_home`);
        promiseReturn.then(function(val) {
            let v = JSON.parse(val);
            if (v.status === 'success') {
                $('.js-autocomplete-dest').data('senders-list', JSON.stringify(v.results));
            }
        });
    }
}

async function loadPopins() {

    // We create alls step of popin payment
    
    popinPaymentBystep = new Popin("popin-wrap popin-payment-bystep", translation("Demande de paiement"), true);

    step1 = new PopinStep(".popin-payment-bystep", translation("Infos"), "step-header-", null, null, "", true, "", true, true);
    let getTemplateStep1 = $("#paymentByStep-step1").html();
    step1.setContent(getTemplateStep1, "js-blocPopinStep", "js-blocPopinStep");
    step1.setButtons("js-stepActionPrev", "js-stepActionPrev", "js-paymentbystep-gostep2 btn btn-primary w-auto st-no-btn-disabled js-stepActionNext", "js-stepActionNext");

    step4 = new PopinStep(".popin-payment-bystep", translation("Création"), "step-header-", step1, null, sendPaymentFacture, "", "#paymentByStep-step5");
    let getTemplateStep4 = $("#paymentByStep-step4").html();
    step4.setContent(getTemplateStep4, "js-blocPopinStep", "js-blocPopinStep");
    step4.setButtons("font-weight-bold cprimary-7 mr-3 js-stepActionPrev", "js-stepActionPrev", "btn btn-primary w-auto js-send-payment-facture js-stepActionNext", "js-stepActionNext", {"data-idproject": getIdProject});

    let titleStep3 = translation("Atouts Soan") + " <img src='/build/images/shooting-star.png' alt='icone shooting star' width='22' height='28' />";
    step3 = new PopinStep(".popin-payment-bystep", titleStep3, "step-header-", step1, step4, generatePaymentLink);
    let getTemplateStep3 = $("#paymentByStep-step3").html();
    step3.setContent(getTemplateStep3, "js-blocPopinStep", "js-blocPopinStep");
    step3.setButtons("font-weight-bold cprimary-7 mr-3 js-stepActionPrev", "js-stepActionPrev", "btn btn-primary w-auto st-no-btn-disabled js-getIdProject js-stepActionNext", "js-stepActionNext", {"data-idproject": getIdProject});

    step2 = new PopinStep(".popin-payment-bystep", translation("Facture"), "step-header-", step1, step3);
    let getTemplateStep2 = $("#paymentByStep-step2").html();
    step2.setContent(getTemplateStep2, "js-blocPopinStep", "js-blocPopinStep");
    step2.setButtons("font-weight-bold cprimary-7 mr-3 js-stepActionPrev", "js-stepActionPrev", "js-paymentbystep-facture-gostep3 js-submit-efacture js-paymentbystep-sendlater st-no-btn-disabled js-getTotalTTC js-disabled st-no-btn-disabled btn btn-primary w-auto js-stepActionNext", "js-stepActionNext", {"data-idproject": getIdProject, "data-importbill": "true"});
    
    step3bis1 = new PopinStep(".popin-payment-bystep", null, "step-header-3", step3, step4, null, "", "", false);
    step3bis1.setContent(getTemplateStep3bis1, "js-blocPopinStep", "js-blocPopinStep");
    step3bis1.setButtons("font-weight-bold cprimary-7 mr-3 js-stepActionPrev", "js-stepActionPrev", "btn btn-primary w-auto js-stepActionNext", "js-stepActionNext");

    if (hasbank === "nobank") {
        step3bis2 = new PopinStep(".popin-payment-bystep", null, "step-header-3", step3bis1, step4, submitNewBank);
        let getTemplateStep3bis2 = $("#paymentByStep-selectBank").html();
        step3bis2.setContent(getTemplateStep3bis2, "js-blocPopinStep", "js-blocPopinStep");
        step3bis2.setButtons("font-weight-bold cprimary-7 mr-3 js-stepActionPrev", "js-stepActionPrev", "btn btn-primary w-auto js-submitNewbankDefault js-stepActionNext", "js-stepActionNext", {"data-isdefault": "isDefault"});
    }
    else if (hasbank === "nodefault") {
        step3bis2 = new PopinStep(".popin-payment-bystep", null, "step-header-3", step3bis1, step4, setBankToDefault);
        let getTemplateStep3bis2 = $("#paymentByStep-selectBank").html();
        step3bis2.setContent(getTemplateStep3bis2, "js-blocPopinStep", "js-blocPopinStep");
        step3bis2.setButtons("font-weight-bold cprimary-7 mr-3 js-stepActionPrev", "js-stepActionPrev", "btn btn-primary w-auto js-paymentPopinSelectAccount js-stepActionNext", "js-stepActionNext", {"data-bankselectedid": "{{banks[0].id}}"});
    }

    // We delete duplicates step
    $("#paymentByStep-step1, #paymentByStep-step2, #paymentByStep-step3bis, #paymentByStep-step3, #paymentByStep-stepBillPrevisu, #paymentByStep-selectBank, #paymentByStep-step4").remove();

    popinPaymentBystep.initSteps(step1);
    popinPaymentBystep.displaySteps();
    popinPaymentBystep.setCurrentStep(step1);

    $(".js-stepActionPrev").on("click", () => {
        popinPaymentBystep.goPrev();
    });

    $(".js-stepActionNext").on("click", () => {
        popinPaymentBystep.goNext();
    });

    $(".popin-step-header div").each(function() {
        let getClassHeader = $(this).attr("class").replace("active", "").trim();
        $("." + getClassHeader).on("click", function() {
            popinPaymentBystep.showingStep(getClassHeader);
        });
    });

    $(".popin-payment-bystep .popin-content div").each(function() {
        $(this).on("DOMSubtreeModified DOMNodeInserted DOMNodeRemoved input blur paste change", function() {
            popinPaymentBystep.disableRead($(this).attr("id"));
        });
    });
};

// We load in first our popins then we load the input front validation

// loadPopins().then(() => {
//     inputFrontValidation();
// });
