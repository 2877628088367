import {checkAllInputs} from "../components/verificationFront";
import { srcLang, convertPrice } from "../generalFunctions";

$(".js-addPromo").on("click", function() {
    $(".st-blocAddPromo").removeClass("d-none").addClass("d-flex");
    $(this).hide();
});

$(".st-promoAdding").on("click", function() {
    $(this).addClass("st-no-btn-disabled");
    let textCodePromo = $(".st-blocAddPromo input").val();

    let promiseReturn = $.post("/freelance/mon_profil/add_promo", {
        promo: textCodePromo
    });

    promiseReturn.then(val => {
        let v = JSON.parse(val);
        if (v.status === 'success') {
            $(".st-infoCodePromo").removeClass("d-none").addClass("d-flex");
            $(".st-blocAddPromo").removeClass("d-flex").addClass("d-none");
            $(".st-divBlocCodePromoAdding").show();
            $(".st-promoMsg").text(v.message);
            $(".st-codePromoAdding").text(textCodePromo);
            if (v.nbPayin) {
                let priceHTDivided, priceTTCDivided;
                if (v.price) {
                    priceHTDivided = ((v.price / v.nbPayin) / 100).toFixed(2);
                    priceTTCDivided = (((v.price + (v.price * 0.2)) / v.nbPayin) / 100).toFixed(2);
                    if (srcLang === "es") {
                        $(".buy-offer-bills").text("Se te cargarán " + v.nbPayin + " veces con " + Number(priceHTDivided).toLocaleString() + " € sin IVA " + Number(priceTTCDivided).toLocaleString() + " € con IVA / domiciliación bancaria.");
                    }
                    else {
                        $(".buy-offer-bills").text("Vous serez prélevé " + v.nbPayin + " fois de " + Number(priceHTDivided).toLocaleString() + " € HT soit " + Number(priceTTCDivided).toLocaleString() + " € TTC / prélèvement.");
                    }
                    $(".popin-offer-price").text((v.price / 100).toLocaleString());
                }
            }
            else {
                if (v.price) {
                    let priceTTC = (v.price + (v.price * 0.2)) / 100;
                    if (srcLang === "es") {
                        $(".buy-offer-bills").text("Se te cobrará " + (v.price / 100).toLocaleString() + " € sin IVA, es decir, " + priceTTC.toLocaleString() + " € con IVA al año");
                    }
                    else {
                        $(".buy-offer-bills").text("Vous serez prélevé de " + (v.price / 100).toLocaleString() + " € HT / an soit " + priceTTC.toLocaleString() + " € TTC / an");
                    }
                    $(".popin-offer-price").text((v.price / 100).toLocaleString());
                }
            }
            $(".st-blocFormError1").removeClass("d-flex").addClass("d-none");
        }
        else {
            $(".st-blocFormError1").removeClass("d-none").addClass("d-flex");
            $(".st-blocFormError1 .js-errorMsg").text(v.message);
            $(this).removeClass("st-no-btn-disabled");
        }
    });
});

$(".js-deleteCodePromo").on("click", function() {
    let promiseReturn = $.get("/freelance/mon_profil/remove_promo");

    promiseReturn.then(val => {
        let v = JSON.parse(val);
        if (v.status === 'success') {
            let priceHT = $(".js-offerchoice-payment").attr("data-offerprice").replace(",", ".");
            let priceTTC = (Number(priceHT) + (Number(priceHT) * 0.2)).toFixed(2);
            if (srcLang === "es") {
                $(".buy-offer-bills").text("Se te cobrará " + convertPrice(priceHT * 100) + " sin IVA / año equivalente a " + convertPrice(priceTTC * 100) + " con IVA / año");
            } else {
                $(".buy-offer-bills").text("Vous serez prélevé de " + convertPrice(priceHT * 100) + " HT / an soit " + convertPrice(priceTTC * 100) + " TTC / an");
            }
            $(".popin-offer-price").text(priceHT.replace(".", ","));
            $(".st-promoAdding").removeClass("st-no-btn-disabled");
            $(".st-infoCodePromo").removeClass("d-flex").addClass("d-none");
            $(".st-blocAddPromo").removeClass("d-none").addClass("d-flex");
            $(".st-divBlocCodePromoAdding").hide();
            $(".st-codePromoAdding").text("");
            $(".st-blocFormError1").removeClass("d-flex").addClass("d-none");
        }
        else {
            if (v.message) {
                $(".st-blocFormError1").removeClass("d-none").addClass("d-flex");
            }
        }
    });
});

$(".popin-buy-offer .verif-front").on("input change", function() {
    checkAllInputs(".popin-buy-offer .st-blocAddPromo", ".st-promoAdding");
});