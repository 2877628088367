class PopinStep {
    constructor(parentContainer, title = null, classTitle = null, prev = null, next = null, isCallAjax = null, isCurrent = false, finalStep = null, isActive = true, isRead = false) {
        this.prev;
        this.next;
        this.title = title;
        this.classTitle = classTitle;
        this.content;
        this.contentClass;
        this.contentId;
        this.valueBtn;
        this.classPrevBtn;
        this.classNextBtn;
        this.idPrevBtn;
        this.idNextBtn;
        this.isCurrent = isCurrent;
        this.parentContainer = parentContainer;
        this.dataAttributes;
        this.isCallAjax = isCallAjax;
        this.finalStep = finalStep;
        this.isActive = isActive;
        this.isRead = isRead;
        this.changingPosition(prev, next);
    }

    // That method show or hide actual step according to the value of isCurrent
    displayStep() {
        if(this.isCurrent) {
            $("#" + this.contentId).show();
            $("#" + this.idPrevBtn).show();
            $("#" + this.idNextBtn).show();
            $("." + this.classTitle).addClass("active");
        }
        else {
            $("#" + this.contentId).hide();
            $("#" + this.idPrevBtn).hide();
            $("#" + this.idNextBtn).hide();
            $("." + this.classTitle).removeClass("active");
        }
    }

    changingPosition(prev = null, next = null) {
        this.prev = prev;
        this.next = next;

        // if prev step exist then we defined his next step to our actual step
        if (prev) {
            prev.next = this;
        }

        // if next step exist then we defined his prev step to our actual step
        if (next){
            next.prev = this;
        }
    }

    setContent(content, contentClass, contentId) {
        this.content = content;
        this.contentClass = contentClass;
        this.contentId = contentId;
    }

    setButtons(classPrevBtn, idPrevBtn, classNextBtn, idNextBtn, dataAttributes) {
        this.classPrevBtn = classPrevBtn;
        this.idPrevBtn = idPrevBtn;
        this.classNextBtn = classNextBtn;
        this.idNextBtn = idNextBtn;
        this.dataAttributes = dataAttributes;
    }

    showFinalStep() {
        $(this.finalStep).show();
    }
}

export default PopinStep;