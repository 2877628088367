let translation_de = {
    "12 derniers mois": "",
    "a bien été ajouté": "", 
    "a bien été envoyée": "",
    "À régler": "",
    "À signer": "",
    "À valider": "",
    "an": "",
    "Abonnement": "",
    "Afficher les entrées": "", 
    "Ajouter": "",
    "Ajouter des paiements": "",
    "annuel": "",
    "Annuler": "",
    "Appliquer promo": "",
    "Association": "",
    "Atouts Soan": "",
    "Aucune correspondance": "",
    "Aucune demande": "", //
    "Aucune demande2": "", //
    "Aucune donnée": "",
    "Aucun élément": "", //
    "Aucune notification": "", //
    "aucun objectif": "", //
    "Aucun paiement": "", //
    "Aucun paiement2": "", //
    "Aucun recouvrement": "", //
    "Aucune signature": "", //
    "Aucune transaction": "", //
    "bénéficiaire majeur": "", //
    "bien programmés et sera envoyée le" : "", //
    "Bonne nouvelle": "", //
    "Changer": "",
    "Chargement...": "",
    "Cliquer pour copier !": "",
    "Code promo en attente": "",
    "Code promo utilisé": "",
    "Confirmer": "",
    "Compris": "",
    "Continuer": "",
    "Copié !": "",
    "Créer une entreprise": "", //
    "critères mot de passe": "", //
    "Date d'inscription": "",
    "date ultérieure": "", //
    "Demande de paiement": "",
    "Dernière connexion": "",
    "d’escompte avant le": "", //
    "Dossier principal": "",
    "DSO moyen": "",
    "Échec": "",
    "Échéance": "",
    "Échue depuis": "",
    "Éditer le texte": "",
    "Email": "",
    "Emis le": "",
    "En attente": "",
    "En cours de traitement...": "",
    "En cours de transfert": "",
    "En cours depuis le": "",
    "En savoir plus": "",
    "Enregistrer": "",
    "entreprise": "",
    "Entreprise": "",
    "Envoi": "",
    "Erreur survenue": "", //
    "Escompte": "", 
    "Êtes-vous sûr ?": "",
    "Facture": "",
    "Facture générée": "",
    "Félicitation, vous avez reçu le virement en instantané": "", //
    "Flux d'affaire cumulé par mois": "",
    "Flux d'affaire processé cumulé": "",
    "Fondation": "",
    "Format de fichier non valide": "",
    "HT / an soit": "",
    "HT / mois soit": "",
    "Information copiée": "",
    "Infos": "",
    "Je ne trouve pas ma banque": "",
    "jour": "",
    "Jour": "",
    "jours": "",
    "Jours": "",
    "Le code promo a bien été ajouté !": "",
    "Le fichier doit être au format PDF": "",
    "Les fichiers téléchargés": "", //
    "Le montant de votre facture": "", //
    "Le nombre de paiement a bien été ajouté !": "",
    "Le recouvrement de cette facture": "", //
    "Le représentant de l'entreprise": "", //
    "logo jpg ou png": "", //
    "Marquer comme payé": "",
    "mensuel": "",
    "Modèle supprimé": "", //
    "Modifier": "",
    "mois": "",
    "mois restants": "",
    "Nom": "",
    "Nombre invalide": "",
    "Nombre de demande de paiement cumulé": "",
    "Nombre de demande par mois": "",
    "NON": "",
    "num facture un chiffre minimum": "", //
    "objet et message obligatoires" : "", //
    "organisme": "",
    "OUI": "",
    "par an": "",
    "par mois": "",
    "Paiement": "",
    "paiements": "",
    "Paiements restants": "",
    "Payer": "",
    "Prédédent": "",
    "Profil vérifié": "",
    "Programmé": "",
    "Programmé le": "",
    "Promo": "",
    "Rappel": "",
    "réaliser virement traditionnel": "", //
    "Recherche": "",
    "Recouvrement lancé le": "",
    "Recouvrir": "",
    "Réglé": "",
    "Réglé le": "",
    "Relance": "",
    "Relancer": "",
    "Représentant légal": "",
    "Retard": "",
    "Retour": "",
    "Sélectionnez": "",
    "S'agit-il de cette entreprise ?": "",
    "Signer": "",
    "Signature complète": "", //
    "Signature en cours": "",
    "Signature : terminée": "",
    "Si votre organisme n'a pas de Siret": "", //
    "soit": "",
    "Somme récupérée :": "",
    "Suivant": "",
    "sur": "",
    "synthèse": "",
    "Télécharger": "",
    "Téléphone": "",
    "Tous les champs": "", //
    "TTC": "",
    "TTC / an": "",
    "TTC / an business terminé": "", //
    "TTC / an premium terminé": "", //
    "TTC / mois": "",
    "TTC / mois business terminé": "", //
    "TTC / mois premium terminé": "", //
    "TTC doit être supérieur HT": "", //
    "TVA": "",
    "type": "",
    "Un objet et un message sont obligatoires": "",
    "Valider": "",
    "Validation en cours": "",
    "Validation : terminée": "",
    "Vérifiez le numéro SIREN": "", //
    "Vérifiez votre lien hubspot": "",
    "Veuillez accepter tous les champs": "", //
    "Veuillez ajouter un élément": "",
    "Veuillez ajouter un justificatif": "",
    "Veuillez ajouter votre facture": "",
    "Veuillez choisir une option": "", //
    "Veuillez cocher les champs obligatoires": "",
    "Veuillez remplir ce champ": "",
    "Veuillez remplir les champs": "", //
    "Veuillez renseigner les champs obligatoires": "",
    "Veuillez renseigner tous les champs": "",
    "Veuillez renseigner une adresse mail valide": "",
    "Veuillez renseigner une date": "",
    "Veuillez sélectionner une option": "", 
    "Veuillez valider le SIREN": "", //
    "Veuillez valider les cases à cocher": "",
    "Vous devez être majeur": "", //
    "Vous ne pouvez pas lancer un recouvrement": "", //
    "Votre banque n'a pas accepté le virement instantané": "", //
    "Votre devis Euler Hermes": "",
    "Votre photo semble incorrecte": "",
    "Votre modèle": "",
    "Votre numéro de téléphone ne semble pas valide": "",
    "Votre numéro ne semble pas être au bon format": "",
    "Votre SIRET est trop court": "",

    /* Mois */
    "Janvier": "",
    "Février": "", 
    "Mars": "", 
    "Avril": "",
    "Mai": "",
    "Juin": "",
    "Juillet": "",
    "Août": "",
    "Septembre": "",
    "Octobre": "",
    "Novembre": "",
    "Décembre": "",

    /* Préfixe jours de semaine */
    "Lun": "",
    "Mar": "",
    "Mer": "",
    "Jeu": "",
    "Ven": "",
    "Sam": "",
    "Dim": "",
}

export default translation_de;