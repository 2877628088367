import {numberDaysDelate, differenceDate, translation } from "../generalFunctions";
import { checkAllInputs } from "../components/verificationFront";
import translation_fr from "../translations/translation_fr";

$(function() {
    window.addingLogoCompany = function (id) {
        if (id === "Draft") {
            $("#js-openFileAddingLogoDraft").trigger('click');
        }
        else {
            $("#js-openFileAddingLogo" + id).trigger('click');
        }
    }
    window.handleClicCheckboxLogo = function (id) {
        $("#addLogoMailLabel" + id).on("click", function() {
            let inputSubjectMail = $("#timeline-mail" + id).find(".recall-mail-subject").val();
            let inputContentMail = $("#timeline-mail" + id).find(".trumbowyg-editor").text();
            if (inputSubjectMail !== "" && inputContentMail !== "") {
                $("#timeline-mail" + id).find(".submit-mail").removeClass("st-no-btn-disabled");
            }
            else {
                $("#timeline-mail" + id).find(".submit-mail").addClass("st-no-btn-disabled");
            }
        });
    }
    let getDueDate = $(".timeline-relaunch").data("duedate");
    let getNbrDays = numberDaysDelate(getDueDate);
    $(".js-delateDays").text(getNbrDays + " " + translation("jours"));

    let getTimeDueDate = differenceDate(getDueDate);
    let getDaysDueDate = Math.floor(getTimeDueDate / 86400000);
    let setPositiveDays = Math.abs(getDaysDueDate);
    if (setPositiveDays > 1) {
        $(".js-getNbDays").text(setPositiveDays + " " + translation("jours"));
    }
    else {
        $(".js-getNbDays").text(setPositiveDays + " " + translation("jour"));
    }

    if ($(window).width() > 1199) {
        if ($(".timeline-relaunch .timeline-round").length > 2) {
            $(".timeline-relaunch > .timeline-round:eq(1)").addClass("open");
        }
    }
    else {
        $(".timeline-container, .draft-open").on("click", function(){
            $(".st-blocModels").show();
        });
    }

    if ($(".timeline-relaunch .timeline-round").length > 2) {
        $(".st-emptyRelaunch").hide();
    }
    else {
        $(".st-emptyRelaunch").show();
    }

    if ($(window).width() <= 767) {
        $(".timeline-container, .draft-open").on("click", function(){
            $(".view-full .body-content .soancontainer").css("overflow-y", "hidden");
        });
        if($(".timeline-relaunch").has(".if-logo-personalization").length > 0) {
            $(".timeline-mail .st-blocAddingLogoMail").css("height", "100px");
            $(".timeline-mail .st-blocAddingLogoMail label").css("margin-top", "15px");
            $(".timeline-mail .st-blocAddingLogoMail div:nth-child(2)").css("margin-top", "-4px");
            $(".timeline-mail .st-personalization-load-zone").css({"margin-left": "-35px", "width": "240px", "margin-top": "45px"});
        }
    }

    $('.close-cross').on('click', function() {
        $(this).parents('.timeline-round.open').removeClass('open');
        $(this).parents('.draft.open').removeClass('open');
        $(".timeline-mail").hide();
        if ($(window).width() <= 1199) {
            $(".st-blocModels").hide();
        }
        if ($(window).width() <= 767) {
            $(".view-full .body-content .soancontainer").css("overflow-y", "auto");
        }
    });

    let divImgMail = $("<p>", {class: "js-logoCompanyMail"});
    let imgMail = $("<img>", {
        src: $(".timeline-relaunch").data("logocompany"),
        alt: "logoCompany",
        height: "80px",
        width: "80px",
        class: "js-logoCompanyMail"
    });
    $(divImgMail).append(imgMail);

    $("#modelSelect").val("0");
    $(".titleMail").val("");
    let regexImg2 = /<img(.*class=['\"](.*)js-logoCompanyMail(.*)['\"].*)*>/;

    $(".timeline-relaunch input[type=checkbox]").attr("checked", true).prop("checked", true);

    $(".timeline-relaunch > .timeline-round").each(function() {
        let divImgMail = $("<p>", {class: "js-logoCompanyMail"});
        let imgMail = $("<img>", {
            src: $(".timeline-relaunch").data("logocompany"),
            alt: "logoCompany",
            height: "80px",
            width: "80px",
            class: "js-logoCompanyMail"
        });
        $(divImgMail).append(imgMail);

        // Updating different color about type of mail
        let getTitle = $(this).find(".timeline-title").text();
        if (getTitle.includes(translation("Rappel"))) {
            $(this).find(".timeline-picto").css("border", "2px solid #10BC91");
        }
        else if (getTitle.includes(translation("Relance"))) {
            $(this).find(".timeline-picto").css("border", "2px solid #FD216B");
        }
        else {
            $(this).find(".timeline-picto").css("border", "2px solid #FCD000");
        }

        // Charging dataMail in textarea and Manage adding or delete Logo
        let getIdLabel = "#" + $(this).find(".st-blocAddingLogoMail label").attr("id");
        let getIdInput = "#" + $(this).find(".st-blocAddingLogoMail input").attr("id");
        let pluginContain = $(this).find(".trumbowyg-editor").html();
        let dataSubject = $(this).find(".mail-subject .recall-mail-subject").data("defaultvalue");
        let dataTextarea = $(this).find(".mail-msg .editrelaunch").data("defaultvalue");
        $(this).find(".trumbowyg-editor").html(dataTextarea);
        $(this).find(".recall-mail-subject").val(dataSubject);

        $(this).find(".timeline-container").on("click", () => {
            $(".st-divLisDropdownModel .img-checkbox").removeClass("d-none");
            $(".st-divLisDropdownModel .img-checkbox-checked").addClass("d-none");
            if ($(window).width() <= 1199) {
                $(".close-cross").show();
            }
            $(".timeline-mail").hide();
            $(this).find(".timeline-mail").show();
            $(this).find(".trumbowyg-editor").html(dataTextarea);
            $(this).find(".mail-subject .recall-mail-subject").val(dataSubject);
            let newPluginContainLoaded = $(this).find(".trumbowyg-editor").html();
            let divImgMail2 = $("<p>", {class: "js-logoCompanyMail"});
            let imgMail2 = $("<img>", {
                src: $(".timeline-relaunch").data("logocompany"),
                alt: "logoCompany",
                height: "80px",
                width: "80px",
                class: "js-logoCompanyMail"
            });
            $(divImgMail2).append(imgMail2);

            if ($(getIdInput).is(":checked") && regexImg2.test(newPluginContainLoaded) === false) {
                if ($(".timeline-relaunch").has(".if-logo-personalization").length < 1) {
                    $(this).find(".trumbowyg-editor").append(divImgMail2);
                }
            }
            else if (!$(getIdInput).is(":checked") && regexImg2.test(newPluginContainLoaded)) {
                $(this).find(".js-logoCompanyMail").remove();
            }
        });
        let pluginContainOnLoadingPage = $(this).find(".trumbowyg-editor").html();
        if (regexImg2.test(pluginContainOnLoadingPage)) {
            $(this).find(".js-logoCompanyMail").remove();
            if ($(".timeline-relaunch").data("logocompany") !== undefined) {
                $(this).find(".trumbowyg-editor").append(divImgMail);
            }
        }
        else {
            if ($(".timeline-relaunch").data("logocompany") !== undefined) {
                $(this).find(".trumbowyg-editor").append(divImgMail); 
            }
        }

        $(getIdLabel).on("click", () => {
            setTimeout(() => {
                if ($(getIdInput).is(":checked") && regexImg2.test(pluginContain) === false) {
                    if ($(imgMail).attr("src") === undefined) {
                        $(imgMail).attr("src", $(".timeline-relaunch").data("logocompany"));
                    }
                    $(this).find(".if-logo-personalization").show();

                    if ($(".timeline-relaunch").has(".if-logo-personalization").length < 1) {
                        $(divImgMail).append(imgMail);
                        $(this).find(".trumbowyg-editor").append(divImgMail);
                    }
                }
                else {
                    $(this).find(".js-logoCompanyMail").remove();
                    if ($(".timeline-relaunch").data("logocompany") === undefined) {
                        $(this).find(".if-logo-personalization").hide();
                    }
                    else {
                        $(".timeline-relaunch .if-logo-personalization").remove();
                    }
                }
            }, 0);
        });

        // Manage ErrorMsg on Numbers of days before dueDate on Mails
        let getNumberDays = $(this).find(".input-day").val();
        showingErrorMsgDays($(this).find(".js-errorMsgDays"), $(this).find(".input-day"), getNumberDays, setPositiveDays, $(this).find(".mail-actions button:nth-child(2)"), $(this).find(".select-echeance"), "submit-mail");

        $(this).find(".select-echeance, .input-day").on("change", () => {
            let getNumberDaysUpdate = $(this).find(".input-day").val();
            showingErrorMsgDays($(this).find(".js-errorMsgDays"), $(this).find(".input-day"), getNumberDaysUpdate, setPositiveDays, $(this).find(".mail-actions button:nth-child(2)"), $(this).find(".select-echeance"), "submit-mail");
        });
    }); 

    $(".js-showAddingModel").on("click", function() {
        $(".st-addingModel").css("display", "flex");
    });
    $(".js-showAddingModelDraft").on("click", function() {
        $(".st-addingModelDraft").css("display", "flex");
    });
    function htmlEntities(str) {
        return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, "&#39;");
    }
    // Adding model for Mail
    const addingModel = (parents, element) => {
        let parentElement = $(element).parents(parents);
        let name = parentElement.find(".titleMail").val();
        let object = parentElement.find('.recall-mail-subject').val();
        let contentMessage = parentElement.find('.trumbowyg-editor').html();
        let meme = parentElement.find('.js-meme-choice').data('memename');
        let msgError = parentElement.find(".js-msgErrorModel");
        
        let promiseReturn = $.post("/freelance/recall/add_model", {
            name: name,
            subject: object,
            message: contentMessage,
        });

        promiseReturn.then(function(val) {
            let v = JSON.parse(val);
            if (v.status === "success" || v.status === "ok") {
                msgError.hide();
                $(".msg-flash").html("<span>"+ translation("Votre modèle") + " <em>" + name + "</em> " + translation("a bien été ajouté") + ".</span>").addClass("container alert alert-success").css("color", "white").show();
                setTimeout(function(){ $(".msg-flash").text("").css("color", "initial").removeClass("container alert alert-success").hide() }, 6000);
                let newModelDiv = `
                <div class='d-flex align-items-center justify-content-between st-divLisDropdownModel js-liModelSelect' id='model${htmlEntities(v.modelId)}' data-value='${htmlEntities(v.modelId)}' data-modelobject='${htmlEntities(object)}' data-modelmessage='${htmlEntities(contentMessage)}' >
                    <div class='d-flex align-items-center justify-content-between'>
                        <img src='/build/images/radio_button.svg' alt="radio_button" class='img-checkbox st-margin-right-7px' />
                        <img src='/build/images/radio_button_checked.svg' id="${v.modelId}" alt="radio_button" class='d-none img-checkbox-checked st-margin-right-7px' />
                        <p class='mb-0 st-font-size-13px'>${name}</p>
                    </div>
                    <div>
                    </div>
                </div>`
                $(".js-dropdownModelSelect").append(newModelDiv);
                $(".st-addingModel").hide();
            }
            else {
                msgError.text(v.message).show();
            }
        });
    
        promiseReturn.catch(function(err) {
            window.location.reload();
        });
    }

    $(".js-addModel").on("click", function() {
        addingModel(".timeline-mail", this);
    });
    $(".js-addModelDraft").on("click", function() {
        addingModel(".draft", this);
    });

    // Charging dynamically of models on mail 
    $('.js-divModelSelected').on('click', () => {
        if (!$('.timeline-round.open').hasClass('js-disabled')) {
            $(".js-liModelSelect").on("click", (e) => {
                $('.img-checkbox-checked').closest('.js-liModelSelect').find('.img-checkbox').removeClass('d-none');
                $('.img-checkbox-checked').addClass('d-none');
                $(e.target).closest('.js-liModelSelect').find('.img-checkbox').addClass('d-none');
                $(e.target).closest('.js-liModelSelect').find('.img-checkbox-checked').removeClass('d-none');
                $('.js-dropdownModelSelect').addClass('d-none');
                let modelId = $(e.target).closest('.js-liModelSelect').attr('data-value');
                let modelObject = $("#model" + modelId).attr("data-modelobject");
                let modelMessage = $("#model" + modelId).attr("data-modelmessage");
                $(".recall-mail-subject").val(modelObject);
                $(".trumbowyg-editor").html(modelMessage);
                let getEditorContent = ".timeline-round.open .trumbowyg-editor";
                let getCheckboxState = ".timeline-round.open .st-blocAddingLogoMail input";
                if (!regexImg2.test($(getEditorContent).html()) && $(getCheckboxState).is(":checked")) {
                    $(getEditorContent).append(divImgMail);
                }
            });
        }
    })

    // Adding logo Company in mail Draft
    let pluginContainDraft = $(".draft .trumbowyg-editor").html();

    $('.draft-open').on('click', function() {
        $('.st-emptyRelaunch').html('');
        $('.draft').addClass('open');
        $('.timeline-round').removeClass('open');
        $('.draft .recall-mail-subject').focus();
        $(".timeline-mail").hide();
        $(".draft .timeline-mail").show();
        $(".draft .trumbowyg-editor").html("");
        $(".draft .recall-mail-subject").val("");
        let pluginContainDraftV2 = $(".draft .trumbowyg-editor").html();
        if ($("#addLogoMailDraft").is(":checked")) {
            if (regexImg2.test(pluginContainDraftV2)) {
                $(".draft .js-logoCompanyMail").remove();
                if ($(".timeline-relaunch").data("logocompany") !== undefined) {
                    $(divImgMail).append(imgMail);
                    $(".draft .trumbowyg-editor").append(divImgMail);
                }
            }
            else {
                if ($(".timeline-relaunch").data("logocompany") !== undefined) {
                    $(".draft .trumbowyg-editor").append(divImgMail);
                }
            }
        }
        else {
            $(".draft .js-logoCompanyMail").remove();
        }
    });

    $("#addLogoMailDraftLabel").on("click", function() {
        setTimeout(function() {
            if ($("#addLogoMailDraft").is(":checked") && regexImg2.test(pluginContainDraft) === false) {
                if ($(imgMail).attr("src") === undefined) {
                    $(imgMail).attr("src", $(".timeline-relaunch").data("logocompany"));
                }
                $(".draft .if-logo-personalization").show();
                if ($(".timeline-relaunch").has(".if-logo-personalization").length < 1) {
                    $(divImgMail).append(imgMail);
                    $(".draft .trumbowyg-editor").append(divImgMail);
                }
            }
            else {
                $(".draft .js-logoCompanyMail").remove();
                if ($(".timeline-relaunch").data("logocompany") === undefined) {
                    $(".draft .if-logo-personalization").hide();
                }
                else {
                    $(".timeline-relaunch .if-logo-personalization").remove();
                }
            }
        }, 0);
    });

    // Manage Error Gestion of Number days before dueDate on Mail Draft
    let inputDayValueDraft = $(".draft .input-day").val();
    showingErrorMsgDays(".draft .js-errorMsgDays", ".draft .input-day", inputDayValueDraft, setPositiveDays, ".draft .mail-actions button:nth-child(2)", ".draft .select-echeance", "submit-draft-mail");

    $(".draft .select-echeance, .draft .input-day").on("change", function() {
        let getNumberDaysUpdate = $(".draft .input-day").val();
        showingErrorMsgDays(".draft .js-errorMsgDays", ".draft .input-day", getNumberDaysUpdate, setPositiveDays, ".draft .mail-actions button:nth-child(2)", ".draft .select-echeance", "submit-draft-mail");
    });

    function showingErrorMsgDays(errorMsg, input, daysInput, daysTodayDate, button, selectButton, submitButton) {
        if ($(selectButton).val() === "before") {
            if (daysInput >= daysTodayDate) {
                $(errorMsg).show();
                $(button).addClass("js-disabledButton").removeClass(submitButton);
                $(input).addClass("st-errorInput");
            }
            else {
                $(errorMsg).hide();
                $(button).addClass(submitButton).removeClass("js-disabledButton");
                $(input).removeClass("st-errorInput");
            }
        }
        else {
            $(errorMsg).hide();
            $(button).addClass(submitButton).removeClass("js-disabledButton");
            $(input).removeClass("st-errorInput");
        }
    }

    $(".timeline-relaunch .timeline-round").each(function() {
        if ($(this).hasClass("open") && $(this).find(".timeline-title").text() === translation("Échéance")) {
            $(this).removeClass("open");
            $(this).next().addClass("open");
        }
    });

    if ($('.soancontainer').hasClass('billManagementPage')) {
        let paymentId = $('.dataPaymentLink').attr('data-payment');
        let promiseReturn = $.post("/freelance/generer_lien_paiement", {
            paymentId: paymentId
        });
        promiseReturn.then((val) => {
            let v = JSON.parse(val);
            if (v.status === "success") {
                $('.js-payment-link').html(v.url);
            }
        })
    }
});

// MODEL DROPDOWN SHOW AND HIDE + DELETE MODEL FEATURE
$('.js-divModelSelected').on('click', (e) => {
    e.stopPropagation();
    $('.js-dropdownModelSelect').toggleClass('d-none');
    $(document).on('click', () => {
        $('.js-dropdownModelSelect').addClass('d-none');
    });
    $('.js-deleteModel').on('click', (e) => {
        e.stopImmediatePropagation();
        $('.js-dropdownModelSelect').addClass('d-none');
        const modelId = $(e.target).closest('.js-liModelSelect').attr('data-value');
        let promiseReturn = $.post("/freelance/recall/remove_model", { modelId: modelId });
        promiseReturn.then(function(val) {
            let v = JSON.parse(val);
            if (v.status === "success" || v.status === "ok") {
                $(e.target).closest('.js-liModelSelect').remove();
                $(".msg-flash").html("<span>" + translation("Modèle supprimé") + ".</span>").addClass("container alert alert-success").css("color", "white").show();
                setTimeout(function(){ $(".msg-flash").text("").css("color", "initial").removeClass("container alert alert-success").hide() }, 6000);
            } else {
                $(".msg-flash").html(`<span>${v.message}</span>`).addClass("container alert alert-danger").css("color", "white").show();
                setTimeout(function(){ $(".msg-flash").text("").css("color", "initial").removeClass("container alert alert-danger").hide() }, 6000);
            }
        })
        promiseReturn.catch(function(err) {
            window.location.reload();
        })
    })
})

//COPY PAYMENT LINK
$(".dataPaymentLink").on("click", () => {
    let elem = $(".js-payment-link");
    let icon = $('.js-iconCopy');
    let link = elem.text();
    let temp = $("<input>");
    $("body").append(temp);
    temp.val(link).trigger("select");
    document.execCommand("copy");
    temp.remove();
    elem.html(translation('Lien copié'));
    icon.hide();
    setTimeout(() => {
        elem.html(link);
        icon.show();
    }, 2000);
});

$(".timeline-mail .verif-front").on("input change tbwchange", function() {
    checkAllInputs(".timeline-mail", ".submit-mail");
});

$(".draft .verif-front").on("input change tbwchange", function() {
    checkAllInputs(".draft", ".submit-draft-mail");
});
