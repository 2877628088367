let vars = {};
window.location.href.replace(location.hash, '').replace(/[?&]+([^=&]+)=?([^&]*)?/gi,
  function (m, key, value) {
    vars[key] = value;
  });
let fromHome = vars['home'];

if (fromHome == 'true') {
  setTimeout(() => {
    $('.add-card').click();
  }, 500);
}
