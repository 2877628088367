// MANAGE CLICKS ON PLUS BUTTON
$('.js-plusButton').on('click', (e) => {
    $('.js-plusButton').addClass('d-none');
    $('.js-plusButtonCheck').removeClass('d-none');
    $('.js-shortcuts-submenu').removeClass('d-none');
    e.stopPropagation();

    $(document).on('click', (e) => { 
        $('.js-shortcuts-submenu').addClass('d-none');
        $('.js-plusButton').removeClass('d-none');
        $('.js-plusButtonCheck').addClass('d-none');
    });
})

$('.js-plusButtonCheck').on('click', (e) => {   
    $('.js-plusButton').removeClass('d-none');
    $('.js-plusButtonCheck').addClass('d-none');
    $('.js-shortcuts-submenu').addClass('d-none');
    e.stopPropagation();

    $(document).on('click', (e) => {
        $('.js-plusButtonCheck').addClass('d-none');
    });
})

// MANAGE POSITION OF PLUS BUTTON DEPENDING ON SCREENS SIZE
if ($(window).width() < 1355) {
    $('.st-plusButton-submenu-container').css("right", `20px`)
} else {
    $('.st-plusButton-submenu-container').css("right", `${(($(window).width() - 1160) / 2)  - 90}px`);
}

$(window).on('resize', () => {
    if ($(window).width() < 1355) {
        $('.st-plusButton-submenu-container').css("right", `20px`)
    } else {
        $('.st-plusButton-submenu-container').css("right", `${(($(window).width() - 1160) / 2) - 90}px`);
    }
});