import {
    openPopin, scrollBottomElem, hideSelectContentWithOutsideClic, translation, currentPage,
    differenceDate, dateConversion, numberDaysDelate, convertPrice, delay, scrollHeightToValue,
    createThumbnailFromPDF, getRealHeightElement, getValidFormatDate, closePopin, getUrlParams
} from "../../generalFunctions";
import {checkAllInputs, verif_cif_nif, verifSiret} from "../../components/verificationFront";
import { all } from "core-js/fn/promise";
import {flatpickrPlugin} from "../../../plugins/flatpickr";
import $ from "jquery";

const ocr = (e, files, invoiceId = undefined, embedEltId = undefined, flag = false) => {
    $('.loader-upload').fadeIn()

    e.preventDefault();

    let data = new FormData();
    let invoiceNumber;
    let totalHt;
    let totalTtc;
    let currency;
    let due_date;
    let invoiceDate;

    files.forEach(function (file) {
        data.append("document", file, file.name);

        $.ajax({
            type: "POST",
            beforeSend: function (request) {
                request.setRequestHeader("Authorization", "Token 47145103e698afbbb1133701cbed0e17")
            },
            url: "https://api.mindee.net/v1/products/mindee/invoices/v2/predict",
            data: data,
            processData: false,
            contentType: false,
            success: function (val) {
                let document = val.document.inference.prediction;

                invoiceNumber = document.invoice_number.value ? document.invoice_number.value : null;
                totalHt = document.total_excl.value ? document.total_excl.value : null;
                totalTtc = document.total_incl.value ? document.total_incl.value : null;
                currency = document.locale.currency ? document.locale.currency : null;
                due_date = document.due_date.value ? document.due_date.value : null;
                invoiceDate = document.date.value ? document.date.value : null;

                uploadFiles(file, totalHt, totalTtc, currency, due_date, invoiceDate, invoiceNumber);
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {

            }
        });
    });
}

$(".js-popinDeleteSupplier").on("click", function() {
    let homeElementId = $(this).attr("data-homeelementid");
    let homeIsCanceled = $(this).attr('data-iscanceled');
    $('.js-removeSupplier').attr("data-homeelementid", homeElementId);
    $('.js-cancelSupplier').attr("data-homeelementid", homeElementId);
    let isSupplier = true;

    if (homeIsCanceled) {
        $('.popin-deleteSupplier').addClass('st-isCanceled');
    }
    else {
        $('.popin-deleteSupplier').removeClass('st-isCanceled');
    }

    openPopin(".popin-deleteSupplier");
});


$(".js-addSupplier").on("click", () => {
    openPopin(".popin-updateSupplier");
    let companyName = $(".supplier-companyName").val();
    let companyMail = $(".supplier-companyMail").val();
    let taxId = $(".supplier-companySiret:visible").val();
    if (companyName !== "" && companyMail !== "" && taxId !== "") {
        $(".js-request-updateSupplier").removeClass("st-no-btn-disabled");
    }
    let getEmail = $(".st-destEmail").val();
    $(".supplier-companyMail").val(getEmail);
    if (window.location.pathname === "/freelance/fournisseurs") {
        $('.js-request-updateSupplier').attr('data-redirect', 'true');
    }
});

if (window.location.pathname === "/freelance/fournisseurs") {
    if (getUrlParams(window.location.href)["home"] === 'true') {
        setTimeout(() => {
            $('.js-addSupplier').trigger('click');
        }, 1000);
    }
}

let supplierId = $(".supplierPage").attr("data-supplierid");

// Show and Hide filters
$(".js-show-filterOperations").on("click", function() {
    $(this).hide();
    $(".js-hide-filterOperations").show();
    $(".st-filterOperations").css("display", "flex");
});

$(".js-hide-filterOperations").on("click", function() {
    $(this).hide();
    $(".js-show-filterOperations").show();
    $(".st-filterOperations").css("display", "none");
});

$(".js-show-filterBills").on("click", function() {
    $(this).hide();
    $(".js-hide-filterBills").show();
    $(".st-filterBills").css("display", "flex");
});

$(".js-hide-filterBills").on("click", function() {
    $(this).hide();
    $(".js-show-filterBills").show();
    $(".st-filterBills").css("display", "none");
});

    // Managing Popin

// Popin updateSupplier

if (currentPage === "freelance_supplier") {
    let valueFiscalId = $(".st-blocFiscalId").attr("data-fiscalid");
    if (valueFiscalId !== "undefined" && verif_cif_nif(valueFiscalId)) {
        $(".st-blocFiscalId .siret").hide().attr("data-active", "false");
        $(".st-blocFiscalId .cif-nif").show().val(valueFiscalId).attr("data-active", "true");
        $(".supplier-taxId").attr("data-value", "CIF/NIF").text("CIF / NIF");
        $(".js-selectFiscalId p").css("color", "#02130E");
        $(".js-selectFiscalId p:nth-child(2)").css("color", "#ABB0AF");
    }
    else if (verifSiret(valueFiscalId)) {
        $(".st-blocFiscalId .siret").val(valueFiscalId);
    }
    else {
        $(".st-blocFiscalId .siret").hide().attr("data-active", "false");
        $(".st-blocFiscalId .verif-empty").show().val(valueFiscalId).attr("data-active", "true");
        $(".supplier-taxId").attr("data-value", "OTHER").text("Autre");
        $(".js-selectFiscalId p").css("color", "#02130E");
        $(".js-selectFiscalId p:nth-child(3)").css("color", "#ABB0AF");
    }
}

$(".js-openPopin-updateSupplier").on("click", function() {
    window.location.href = "https://"+$(this).attr('data-domain')+"/dashboard?popin=fournisseur&id="+$(this).attr('data-supplierid');

    // openPopin(".popin-updateSupplier");
    // $('.js-request-updateSupplier').attr('data-redirect', 'false');
    // let companyName = $(".supplier-companyName").val();
    // let companyMail = $(".supplier-companyMail").val();
    // let taxId = $(".supplier-companySiret:visible").val();

    // let firstname = $(".st-blocContact").attr("data-firstname");
    // let lastname = $(".st-blocContact").attr("data-lastname");
    // let mail = $(".st-blocContact").attr("data-mail");
    // let status = $(".st-blocContact").attr("data-status");
    // if (firstname !== "" || lastname !== "" || mail !== "" || status !== "") {
    //     $(".st-blocContact").show();
    //     $(".js-show-blocContact, .js-hideBlocContact").hide();
    // }
    // if (companyName !== "" && companyMail !== "" && taxId !== "") {
    //     $(".js-request-updateSupplier").removeClass("st-no-btn-disabled");
    // }
    // if (status !== "") {
    //     let statusName;
    //     if (status === "ACCOUNTANT") {
    //         statusName = translation("Comptable");
    //     }
    //     else if (status === "MANAGER") {
    //         statusName = translation("Gérant");
    //     }
    //     else if (status === "MEMBER") {
    //         statusName = translation("Membre");
    //     }
    //     else {
    //         statusName = translation("Autre");
    //     }
    //     $(".supplier-contactStatus").attr("data-value", status).text(statusName);
    // }
});
$(".js-redirect-addSupplier").on("click", function() {
    window.location.href = "https://"+$(this).attr('data-domain')+"/dashboard?popin=fournisseur";
    //openPopin(".popin-updateSupplier");
    // $('.supplier-companyName').val('');
    // $('.supplier-companyMail').val('');
    // $('.supplier-companySiret').val('');
    // $('.supplier-companyIban').val('');
    // $('.supplier-contactFirstname').val('');
    // $('.supplier-contactLastname').val('');
    // $('.supplier-contactcontactMail').val('');
    // $('.supplier-comptaAccount').val('');
    // $('.supplier-comptaTags').val('');
    //$('.js-request-updateSupplier').attr('data-redirect', 'true');
});
$('.supplier-companySiret').on('input change', function () {
   $('.supplier-companySiret').attr('data-active', 'false');
   $(this).attr('data-active', 'true');
});

$(".js-show-blocContactCompany").on("click", function() {
    $(".st-blocContactCompany").show();
    $(".st-blocCompta").hide();
    $(this).css({"color": "#02130E", "cursor": "initial"}).addClass("active");
    $(".js-show-blocCompta").css({"color": "#ABB0AF", "cursor": "pointer"}).removeClass("active");
});

$(".js-show-blocCompta").on("click", function() {
    $(".st-blocCompta").show();
    $(".st-blocContactCompany").hide();
    $(this).css({"color": "#02130E", "cursor": "initial"}).addClass("active");
    $(".js-show-blocContactCompany").css({"color": "#ABB0AF", "cursor": "pointer"}).removeClass("active");
});

$(".js-show-blocContact").on("click", function() {
    $(this).hide();
    $(".st-blocContact").show();
});

$(".js-hideBlocContact").on("click", function() {
    $(".st-blocContact").hide();
    $(".js-show-blocContact").show();
});

$(".js-show-selectStatus").on("click", function(e) {
    if ($(".js-selectStatus").is(":visible")) {
        $(".popin-updateSupplier .popin-actions").removeClass("mb20");
    }
    else {
        $(".popin-updateSupplier .popin-actions").addClass("mb20");
    }
    scrollBottomElem(".popin");
    $(".js-selectStatus").toggle();
    e.stopPropagation();
    $(window).on("click", function() {
        $(".popin-updateSupplier .popin-actions").removeClass("mb20");
        $(".js-selectStatus").hide();
    });
});

$(".js-selectStatus p").on("click", function() {
    let value = $(this).attr("data-value");
    let content = $(this).text();
    $(".js-selectStatus").hide();
    $(".js-selectStatus p").css("color", "#02130E");
    $(this).css("color", "#ABB0AF");
    $(".js-show-selectStatus p").text(content).attr("data-value", value);
});

$(".js-show-selectFiscalId").on("click", function(e) {
    $(".js-selectFiscalId").toggle();
    hideSelectContentWithOutsideClic(e, ".js-selectFiscalId");
});

$(".js-selectFiscalId p").on("click", function() {
    let value = $(this).attr("data-value");
    let content = $(this).text();
    $(".js-selectFiscalId").hide();
    $(".js-selectFiscalId p").css("color", "#02130E");
    $(this).css("color", "#ABB0AF");
    $(".js-show-selectFiscalId p").text(content).attr("data-value", value);
    $(".st-blocFiscalId input").hide();
    if (value === "SIRET") {
        $(".st-blocFiscalId .siret").show();
    }
    else if (value === "CIF/NIF") {
        $(".st-blocFiscalId .cif-nif").show();
    }
    else {
        $(".st-blocFiscalId .verif-empty").show();
    }
});

$(".js-request-updateSupplier").on("click", function() {
    $(this).addClass("st-no-btn-disabled");

    let supplierCompanyName = $(".supplier-companyName").val();
    let supplierCompanyMail = $(".supplier-companyMail").val();
    let supplierCompanySiret = $(".supplier-companySiret[data-active='true']").val();
    let supplierCompanyIban = $(".supplier-companyIban").val();
    let supplierContactFirstname = $(".supplier-contactFirstname").val();
    let supplierContactLastname = $(".supplier-contactLastname").val();
    let supplierContactMail = $(".supplier-contactMail").val();
    let supplierContactStatus = $(".supplier-contactStatus").attr("data-value");
    let supplierComptaAccount = $(".supplier-comptaAccount").val();
    let supplierComptaTags = [$(".supplier-comptaTags").val()];
    let supplierComptaDescription = $(".supplier-comptaDescription").val();
    let supplierId = null;
     if ($('.js-request-updateSupplier').attr('data-redirect') === "false" && $('.js-request-updateSupplier').attr('data-supplierid') != '' ) {
         supplierId  = $('.js-request-updateSupplier').attr('data-supplierid');
     }

    let sendRequest = $.post("/freelance/fournisseur/creer", {
        supplierCompanyName: supplierCompanyName,
        supplierCompanyMail: supplierCompanyMail,
        supplierCompanySiret: supplierCompanySiret,
        supplierCompanyIban: supplierCompanyIban,
        supplierContactFirstname: supplierContactFirstname,
        supplierContactLastname: supplierContactLastname,
        supplierContactMail: supplierContactMail,
        supplierContactStatus: supplierContactStatus,
        supplierComptaAccount: supplierComptaAccount,
        supplierComptaTags: supplierComptaTags,
        supplierComptaDescription: supplierComptaDescription,
        supplierId: supplierId,
        oldForm: true
    });

    sendRequest.then(val => {
        let v = JSON.parse(val);
        if (v.status === 'success') {
            let id = v.supplierId;
            if ($('.js-request-updateSupplier').attr('data-redirect') === "true") {
                window.location.href = "/freelance/fournisseur/fiche/"+id;
            }
            else if (window.location.pathname === '/freelance/fournisseur/paiement') {
                $(".js-contactCompany, .js-contactSiret, .js-contactName, .js-contactLastname, .js-contactMail").text("");
                $(".js-contactCompany").text(supplierCompanyName);
                $(".js-contactSiret").text(supplierCompanySiret);
                // $(".js-contactName").text((firstname === "") ? "-" : firstname);
                $(".js-contact-supplier").text(((supplierContactFirstname === "") ? "-" : supplierContactFirstname) + " " + ((supplierContactLastname === "") ? "-" : supplierContactLastname.toUpperCase() ));
                $(".js-contactMail").text(supplierCompanyMail);
                $(".st-destEmail").val(supplierCompanyMail).removeClass("error");
                $(".js-bankIban, .js-bankIban-later").val((supplierCompanyIban === "") ? "" : supplierCompanyIban);
                $(".js-blocContact").show().attr("data-actif", "true");
                $(".st-blocFormError1").removeClass("d-flex").addClass("d-none");
                $(".js-formContact").hide();
                if (supplierCompanyName !== null) {
                    $('.js-compteFournisseur').html(id + ' - ' + supplierCompanyName);
                }
                else {
                    $('.js-compteFournisseur').html(id);
                }
                closePopin('.popin-updateSupplier')
            }
            else {
                window.location.reload();
            }
        }
        else {
            $(this).removeClass("st-no-btn-disabled");
            if (v.message) {
                $(".st-blocPopinError").removeClass("d-none").addClass("d-flex");
                $(".js-errorMsg").text(v.message);
            }
        }
    });
});

// Popin upload bills
$(".js-openPopin-uploadFiles").on("click", () => {
    openPopin(".popin-uploadFiles");
});

if (window.location.pathname !== "/freelance/fournisseur/paiement") {
    $(".js-containerUploadFiles").on('drag dragend dragover dragenter dragleave drop', function(e) {
        e.preventDefault();
        e.stopPropagation();
    }).on("drag drop", function(e){
        ocr(e, e.originalEvent.dataTransfer.files);
    }).on("click", function(){
        $(".js-uploadFile").trigger("click");
    });

    $(".js-request-uploadFiles").on("click", function() {
        $(".js-uploadFile").trigger("click");
    });

    $(".js-uploadFile").on("change", function(e) {
        ocr(e, e.target.files);
    });
}

const uploadFiles = (file, totalHt, totalTtc, currency, due_date, invoiceDate, invoiceNumber) => {
    let datas = new FormData();

    // files.forEach(function(image) {
    let fileName = file.name;
    if (fileName.split(".")[fileName.split(".").length - 1] === "pdf" || fileName.split(".")[fileName.split(".").length - 1] === "png" || fileName.split(".")[fileName.split(".").length - 1] === "jpeg" || fileName.split(".")[fileName.split(".").length - 1] === "jpg" ) {
        datas.append("invoices[]", file);
        datas.append("id", null);
    }
    // });
    datas.append("supplierId", supplierId);
    datas.append("totalHt", totalHt);
    datas.append("totalTtc", totalTtc);
    datas.append("currency", currency);
    datas.append("due_date", due_date);
    datas.append("invoiceDate", invoiceDate);
    datas.append("invoiceNumber", invoiceNumber);

    let sendRequest = $.ajax({
        url: '/freelance/upload_invoice',
        data: datas,
        type: 'POST',
        contentType: false,
        processData: false,
    });

    sendRequest.then(val => {
        let v = JSON.parse(val);
        if (v.status === 'success') {
            window.location.reload();
        }
        else {
            $(this).removeClass("st-no-btn-disabled");
            if (v.message) {
                $('.loader-popin').hide();
                $(".st-blocPopinError").removeClass("d-none").addClass("d-flex");
                $(".js-errorMsg").text(v.message);
            }
        }
    });
}

// Popin download bills
$(".js-openPopin-downloadAllBills").on("click", () => {
    openPopin(".popin-downloadAllBills");
    $(".js-request-downloadBills").removeClass("st-no-btn-disabled");
});

$(".js-show-selectBills").on("click", function(e) {
    $(".js-selectBills").toggle();
    hideSelectContentWithOutsideClic(e, ".js-selectBills");
});

$(".js-selectBills p").on("click", function() {
    let value = $(this).attr("data-value");
    let content = $(this).text();
    $(".js-selectBills").hide();
    $(".js-selectBills p").css("color", "#02130E");
    $(this).css("color", "#ABB0AF");
    $(".js-show-selectBills p").text(content).attr("data-value", value);
});

$(".js-request-downloadBills").on("click", function() {
    $(this).addClass("st-no-btn-disabled");

    let downloadBillType = $(".js-download-billType").attr("data-value");
    let downloadFromDate = $(".js-download-fromDate").val();
    let downloadToDate = $(".js-download-toDate").val();

    let sendRequest = $.post("/freelance/fournisseur/"+supplierId+"/download_all_invoices", {
        downloadBillType: downloadBillType,
        downloadFromDate: downloadFromDate,
        downloadToDate: downloadToDate
    });

    sendRequest.then(val => {
        let v = JSON.parse(val);
        if (v.status === "success") {
            window.location.href = v.file;
            closePopin();
        }
        else {
            $(this).removeClass("st-no-btn-disabled");
            if (v.message) {
                $(".st-blocPopinError").removeClass("d-none").addClass("d-flex");
                $(".js-errorMsg").text(v.message);
            }
        }
    });
});

// Popin download documents
$(".js-openPopin-downloadAllDocs").on("click", () => {
    openPopin(".popin-downloadAllDocs");
});

$(".js-show-selectDocs").on("click", function(e) {
    $(".js-selectDocs").toggle();
    hideSelectContentWithOutsideClic(e, ".js-selectDocs");
});

$(".js-selectDocs p").on("click", function() {
    let value = $(this).attr("data-value");
    let content = $(this).text();
    $(".js-selectDocs").hide();
    $(".js-selectDocs p").css("color", "#02130E");
    $(this).css("color", "#ABB0AF");
    $(".js-show-selectDocs p").text(content).attr("data-value", value);
});

$(".js-request-downloadDocs").on("click", function() {
    $(this).addClass("st-no-btn-disabled");

    let downloadDocType = $(".js-download-docType").attr("data-value");

    let sendRequest = $.post("/route", {
        downloadDocType: downloadDocType,
    });

    sendRequest.then(val => {
        let v = JSON.parse(val);
        if (v.status === "success") {
        }
        else {
            $(this).removeClass("st-no-btn-disabled");
            if (v.message) {
                $(".st-blocPopinError").removeClass("d-none").addClass("d-flex");
                $(".js-errorMsg").text(v.message);
            }
        }
    });
});

// Popin create folder
$(".js-openPopin-addFolderSupplier").on("click", () => {
    openPopin(".popin-addFolderSupplier");
});

$(".js-request-addFolderSupplier").on("click", function() {
    $(this).addClass("st-no-btn-disabled");

    let downloadDocType = $(".supplier-folderName").val("data-value");

    let sendRequest = $.post("/route", {
        downloadDocType: downloadDocType,
    });

    sendRequest.then(val => {
        let v = JSON.parse(val);
        if (v.status === "success") {
        }
        else {
            $(this).removeClass("st-no-btn-disabled");
            if (v.message) {
                $(".st-blocPopinError").removeClass("d-none").addClass("d-flex");
                $(".js-errorMsg").text(v.message);
            }
        }
    });
});

// Changement price Disbursement
$(".st-blocDisbursement .st-iconSelectDate").on("click", () => {
    $(".js-changePriceDisbursement").trigger("click");
});

if (currentPage === "freelance_supplier") {
    changeDisbursementPrice(".js-changePriceDisbursement");
}

$(".js-changePriceDisbursement").on("change input", function() {
    changeDisbursementPrice(this);
});

function changeDisbursementPrice(thisElement) {
    let oldValue = $(thisElement).attr("data-oldvalue");
    let value = $(thisElement).val();

    if (oldValue !== value) {
        let sendRequest;
        if (value === "") {
            sendRequest = $.post("/freelance/fournisseur/" + supplierId + "/sum_payment");
        }
        else {
            sendRequest = $.post("/freelance/fournisseur/" + supplierId + "/sum_payment", {
                fromDate: value,
            });
        }

        sendRequest.then(val => {
            let v = JSON.parse(val);
            if (v.status === "success") {
                let allPayed = v.allPayed ? convertPrice(v.allPayed) : "0,00 €";
                let toBePayed = v.toBePayed ? convertPrice(v.toBePayed) : "0,00 €";
                $(".st-totalDisbursement").text(allPayed);
                $(".st-disbursement").text(toBePayed);
                if (typeof v.fromDate === 'undefined' || v.fromDate <= 0) {
                    $(".js-changePriceDisbursement").val(dateConversion(null));
                }
                else {
                    $(".js-changePriceDisbursement").val(v.fromDate);
                }
            }
            else {
                $(".js-changePriceDisbursement").val(dateConversion(null));
                if (v.message) {
                }
            }
        });
    }
    $(thisElement).attr("data-oldvalue", value);
}

// Manage waiting and success operations
$(".js-sortOperations").val("LAST");
$(".js-searchOperations").val("");

let arrayRowTypeWait = {
    "EQUOTATION": 0,
    "CREDITED_PAYMENT": 0,
    "DEBITED_PAYMENT": 0,
    "SUPPLIER_PAYMENT": 0,
    "DELAYED_INVOICE": 0,
    "DELAYED_PAYMENT": 0,
    "RECOVERY": 0,
    "DOCUMENT": 0
}

let arrayRowTypeSuccess = {
    "EQUOTATION": 0,
    "CREDITED_PAYMENT": 0,
    "DEBITED_PAYMENT": 0,
    "SUPPLIER_PAYMENT": 0,
    "DELAYED_INVOICE": 0,
    "DELAYED_PAYMENT": 0,
    "RECOVERY": 0,
    "DOCUMENT": 0
}

if (currentPage === "freelance_supplier") {
    supplierPaymentRequest(3, "waiting", 1);
    supplierPaymentRequest(3, "success", "done", true);

    let sendRequest = $.post("/freelance/fournisseur/" + supplierId + "/count_payment");
    sendRequest.then(val => {
        let v = JSON.parse(val);
        if (v.status === "success") {
            $(".st-totalWaitingOperations, .st-totalWaitingBills").text(v.countToBePayed);
            $(".st-totalSuccessOperations, .st-totalSuccessBills").text(v.countAllPayed);
        }
        else {
        }
    });
}

$(".js-sortOperations").on("change", function() {
    $(".js-container-waitingOperations div, .js-container-successOperations div").remove();
    for (let rowWait in arrayRowTypeWait) {
        arrayRowTypeWait[rowWait] = 0;
    }
    for (let rowSuccess in arrayRowTypeSuccess) {
        arrayRowTypeSuccess[rowSuccess] = 0;
    }
    supplierPaymentRequest(3, "waiting", 1, false, $(this).val());
    supplierPaymentRequest(3, "success", "done", true, $(this).val());
});

$(".js-searchOperations").on("keyup change", delay(function () {
    $(".js-container-waitingOperations div, .js-container-successOperations div").remove();
    for (let rowWait in arrayRowTypeWait) {
        arrayRowTypeWait[rowWait] = 0;
    }
    for (let rowSuccess in arrayRowTypeSuccess) {
        arrayRowTypeSuccess[rowSuccess] = 0;
    }
    supplierPaymentRequest(3, "waiting", 1, false, $(".js-sortOperations").val(), $(this).val());
    supplierPaymentRequest(3, "success", "done", true, $(".js-sortOperations").val(), $(this).val());

}, 300));

$(".js-showMoreWaitOpe").on("click", function() {
    supplierPaymentRequest(10, "waiting", 1, true);
    if ($(".js-reduceWaitOpe").not(":visible")) {
        $(".js-reduceWaitOpe").show();
    }
});

$(".js-showMoreSuccessOpe").on("click", function() {
    supplierPaymentRequest(10, "success", "done", true);
    if ($(".js-reduceSuccessOpe").not(":visible")) {
        $(".js-reduceSuccessOpe").show();
    }
});

$(".js-reduceWaitOpe").on("click", function() {
    reduceElements(".js-container-waitingOperations .st-rowSupplierPayment",
    ".js-container-waitingOperations .st-rowSupplierPayment:last", this, ".js-showMoreWaitOpe", arrayRowTypeWait, 82);
});

$(".js-reduceSuccessOpe").on("click", function() {
    reduceElements(".js-container-successOperations .st-rowSupplierPayment",
    ".js-container-successOperations .st-rowSupplierPayment:last", this, ".js-showMoreSuccessOpe", arrayRowTypeSuccess, 82);
});

function supplierPaymentRequest(iMax, statusCustom, supplierPaymentState, loadEvents = false, orderOperation = $(".js-sortOperations").val(),
    searchText = $(".js-searchOperations").val(), filterOperation = "ALL", typeOperation = "SUPPLIER_PAYMENT") {

    let arrayRowType;
    if (statusCustom === "waiting") {
        arrayRowType = arrayRowTypeWait;
    }
    else if (statusCustom === "success") {
        arrayRowType = arrayRowTypeSuccess;
    }

    let sendRequest = $.post("/freelance/find_data_home", {
        order: orderOperation,
        filter: filterOperation,
        displayType: typeOperation,
        arrayRowType: arrayRowType,
        search: searchText,
        supplierPaymentState: supplierPaymentState,
        supplierId: supplierId
    });

    sendRequest.then(val => {
        let v = JSON.parse(val);
        if (v.status === "success") {
            let allDatas = v.data;
            if (allDatas.length > 0) {
                $(".js-container-waitingOperations .st-emptySearch, .js-container-successOperations .st-emptySearch").remove();
                allDatas.forEach(data => {
                    if (iMax > 0) {
                        addDynamicElement(data.title, data.status, data.date, data.dueDate, data.delayedPaymentDate, data.payedDate, data.paymentStatus, data.mark_as_payed, data.approvalStatus, data.value, statusCustom, data.id, data.link, data.filename);

                        if (statusCustom === "waiting") {
                            arrayRowTypeWait["SUPPLIER_PAYMENT"] += 1;
                        }
                        else if (statusCustom === "success") {
                            arrayRowTypeSuccess["SUPPLIER_PAYMENT"] += 1;
                        }

                        iMax--;
                    }
                });
                if (allDatas.length < iMax) {
                    if (statusCustom === "waiting") {
                        $(".js-showMoreWaitOpe").hide();
                    }
                    else if (statusCustom === "success"){
                        $(".js-showMoreSuccessOpe").hide();
                    }
                }
                else if (allDatas.length > 3) {
                    if (statusCustom === "waiting") {
                        $(".js-showMoreWaitOpe").show();
                    }
                    else if (statusCustom === "success"){
                        $(".js-showMoreSuccessOpe").show();
                    }
                }
                $(".st-emptyOperations").hide();
                $(".st-bloc-waitingOperations, .st-bloc-successOperations, .js-show-filterOperations").show();
                $(".st-bloc-waitingOperations, .st-bloc-successOperations").addClass("d-flex");
            }
            else {
                let isSearch = $(".js-searchOperations").val() !== "";

                if (isSearch) {
                    if (statusCustom === "waiting") {
                        $(".js-container-waitingOperations p").remove();
                        let emptySearchMessageWaiting = $("<p>", {
                            class: "st-emptySearch",
                            text: translation("Aucune opération en cours")
                        });
                        $(".js-container-waitingOperations").append(emptySearchMessageWaiting);
                        $(".js-showMoreWaitOpe").hide();
                    }
                    else if (statusCustom === "success") {
                        $(".js-container-successOperations p").remove();
                        let emptySearchMessageSuccess = $("<p>", {
                            class: "st-emptySearch",
                            text: translation("Aucune opération réglée")
                        });
                        $(".js-container-successOperations").append(emptySearchMessageSuccess);
                        $(".js-showMoreSuccessOpe").hide();
                    }
                    $(".st-bloc-waitingOperations, .st-bloc-successOperations, .js-show-filterOperations, .js-hide-filterOperations, .st-filterOperations").show();
                    $(".st-bloc-waitingOperations, .st-bloc-successOperations").addClass("d-flex");
                }
            }

            // Once elements are created, we run the events
            if (loadEvents) {
                $(".js-show-actionsSupplierPayment").on("click", function(e) {
                    $(".st-actionsSupplierPayment").hide();
                    let actionsSupplier = $(this).find(".st-actionsSupplierPayment");
                    if(actionsSupplier.is(":visible")) {
                        actionsSupplier.hide();
                    }
                    else {
                        actionsSupplier.show();
                    }
                    hideSelectContentWithOutsideClic(e, actionsSupplier);
                });
                $(".js-markAsPayed").on("click", function() {
                    let route = $(this).attr("data-route");

                    let sendRequest2 = $.post(route);

                    sendRequest2.then(val => {
                        v = JSON.parse(val);
                        if (v.status) {
                            window.location.reload();//CHECKCA
                        }
                        else {
                            window.location.reload();
                        }
                    });
                });
                $(".js-unmarkAsPayed").on("click", function() {
                    let route = $(this).attr("data-route");
                    let supplierPaymentId = $(this).attr("data-supplierpayment-id");
                    let sendRequest3 = $.post(route, {
                        paymentId: supplierPaymentId
                    });

                    sendRequest3.then(val => {
                        v = JSON.parse(val);
                        if (v.status) {
                            window.location.reload();
                        }
                        else {
                            window.location.reload();
                        }
                    });
                });
                $(".js-deleteSupplierPayment").on("click", function() {
                    let supplierPaymentId = $(this).attr("data-supplierpayment-id");
                    let sendRequest4 = $.post("/freelance/paiement_fournisseur/" + supplierPaymentId + "/cancel", {
                        isFlash: "isFlash"
                    });
                    sendRequest4.then(val => {
                        v = JSON.parse(val);
                        if (v.status) {
                            window.location.reload();
                        }
                        else {
                            window.location.reload();
                        }
                    });
                });
                $(".st-supplierPaymentBloc2, .st-supplierPaymentBloc3, .st-supplierPaymentBloc4").on("click", function() {
                    let routeAction = $(this).attr("data-route");
                    document.location.href = routeAction;
                });
                $(".st-supplierPaymentBloc1").on("click", function() {
                    let pdf = $(this).attr("data-pdf");
                    window.open(pdf, '_blank');
                });

                // if ($(".js-container-waitingOperations div").length === 0 && $(".js-container-successOperations div").length === 0 && $(".js-searchOperations").val() === "") {
                //     $(".st-emptyOperations").show();
                //     $(".st-bloc-waitingOperations, .st-bloc-successOperations, .js-show-filterOperations, .js-hide-filterOperations, .st-filterOperations").hide();
                // }
            }
        }
        else {
        }
    });
}

// $("#js-routeBuilder").on("click", function() {
//     let routeAction = currentPage !== "freelance_supplier" ? "/freelance" + data_link : data_link;
//     this.href = routeAction;
// })

function addDynamicElement(data_name, data_status, data_date, data_dueDate, data_delayedPaymentDate, data_payedDate, data_paymentStatus, data_markAsPayed, data_approvalStatus, data_price, data_statusCustom, data_supplierPaymentId, data_link, data_filename) {
    let nameData, classActionButton, textActionButton, statePayment, iconState, altState, divMarkAsPayed, infoSupData, price, classActionMarkAsPayed, routeMarkAsPayed, contentMarkAsPayed, classActionDelete, routeAction;

    console.log(currentPage);
    if (data_name === null || data_name === "") {
        nameData = "-";
    } else {
        nameData = data_name;
    }
    if (data_price === "-" || data_price === null) {
        price = "";
    }
    else {
        price = data_price;
    }
    if (data_statusCustom === "waiting") {
        routeAction = currentPage !== "freelance_supplier" ? "/freelance" + data_link : data_link;
    }
    else {
        routeAction = currentPage !== "freelance_supplier" ? "/freelance/fournisseur/paiement/" + data_supplierPaymentId + "/recap" : "/fournisseur/paiement/" + data_supplierPaymentId + "/recap";
    }
    if (data_status === 'TODO') {
        divMarkAsPayed = "d-block";
        classActionMarkAsPayed = "js-markAsPayed d-none";
        routeMarkAsPayed = "/freelance/paiement_fournisseur/" + data_supplierPaymentId + "/mark_as_payed";
        contentMarkAsPayed = translation("Marquer comme payé");
        classActionDelete = "st-alone-delete";
        classActionButton = "st-to-complete"
        textActionButton = translation("Compléter");
        statePayment = translation("Infos manquantes");
        iconState = "/build/images/pastille-rouge.svg";
        altState = "icone d'info manquante";
        if (data_dueDate) {
            infoSupData = translation("À régler avant") + " " + dateConversion(data_dueDate);
        }
        else {
            infoSupData = translation("Importé le") + " " + dateConversion(data_date);
        }
    }
    else if (data_status === 'DONE') {
        classActionMarkAsPayed = "js-unmarkAsPayed";
        routeMarkAsPayed = "/freelance/unmark_as_payed_supplier";
        contentMarkAsPayed = translation("Ne pas marquer comme payé");
        classActionDelete = "st-unmark-delete";
        if (data_delayedPaymentDate) {
            infoSupData = translation("Programmé le") + " " + dateConversion(data_delayedPaymentDate);
        }
        else {
            infoSupData = translation("Réglé le") + " " + dateConversion(data_payedDate);
        }
        iconState = "/build/images/icon_check-black.svg";
        statePayment = translation("Réglé");
        classActionButton = "invisible";
        altState = "icone checked";
        if (data_markAsPayed) {
            divMarkAsPayed = "d-block";
        } else {
            divMarkAsPayed = "d-none";
        }
    }
    else if (data_status === 'DOING') {
        divMarkAsPayed = "d-block";
        classActionMarkAsPayed = "js-markAsPayed";
        routeMarkAsPayed = "/freelance/paiement_fournisseur/" + data_supplierPaymentId + "/mark_as_payed";
        contentMarkAsPayed = translation("Marquer comme payé");
        classActionDelete = "st-mark-delete";
        if (data_paymentStatus === 'FAILED') {
            statePayment = translation("Échec");
            iconState = "/build/images/pastille-rouge.svg";
            infoSupData = translation("À régler avant") + " " + dateConversion(data_dueDate);
            classActionButton = "st-toPay";
            textActionButton = translation("Payer");
        }
        else if (data_delayedPaymentDate) {
            classActionButton = "st-programmedPayment";
            textActionButton = translation("Payer maintenant");
            statePayment = translation("Paiement programmé");
            infoSupData = translation("Programmé pour le") + " " + dateConversion(data_delayedPaymentDate);
            if (differenceDate(data_dueDate) > 0) {
                iconState = "/build/images/pastille-rouge.svg";
            }
            else {
                iconState = "/build/images/pastille-en-attente.svg";
            }
        }
        else if (differenceDate(data_dueDate) > 0 && data_paymentStatus !== "FAILED") {
            statePayment = translation("Retard");
            iconState = "/build/images/pastille-rouge.svg";
            infoSupData = translation("À régler avant") + " " + dateConversion(data_dueDate);
            classActionButton = "st-toPay";
            textActionButton = translation("Payer");
        }
        else {
            infoSupData = translation("À régler avant") + " " + dateConversion(data_dueDate);
            classActionButton = "st-toPay";
            textActionButton = translation("Payer");
            if (data_approvalStatus) {
                if (data_approvalStatus === "CREATED") {
                    statePayment = translation("Approbation demandée")
                    iconState = "/build/images/pastille-grise.svg";
                }
                else if (data_approvalStatus === "DENIED") {
                    statePayment = translation("Approbation refusée")
                    iconState = "/build/images/pastille-rouge.svg";
                }
                else if (data_approvalStatus === "APPROVED") {
                    statePayment = translation("Approbation validée");
                    iconState = "/build/images/pastille-en-attente.svg";
                } else {
                    statePayment = "-";
                    iconState = "/build/images/pastille-en-attente.svg";
                }
            }
            else {
                statePayment = translation("À régler");
                iconState = "/build/images/pastille-verte.svg";
            }
            if (differenceDate(data_dueDate) > 0) {
                if (numberDaysDelate(data_dueDate) > 0) {
                    infoSupData = translation("Échue depuis") + " " + numberDaysDelate(data_dueDate) + " " + translation("jours");
                } else {
                    infoSupData = translation("Échue depuis") + " " + numberDaysDelate(data_dueDate) + " " + translation("jour");
                }
            }
        }
    }
    let containerOperations = `
    <div class="d-flex st-rowSupplierPayment">
        <div class="st-supplierPaymentBloc1" data-pdf="${data_filename}">
            <img src="/build/images/icon-document.svg" alt="icone document" width="24px" height="24px">
        </div>
        <div class="st-supplierPaymentBloc2" data-route="${routeAction}">
            <p class="fs14 fw600 mb0">${nameData}</p>
            <p class="fs12 mb0">${infoSupData}</p>
        </div>
        <div class="st-supplierPaymentBloc3" data-route="${routeAction}">
            <p class="fs14 fw600 mb0">- ${convertPrice(price)}</p>
        </div>
        <div class="d-flex st-supplierPaymentBloc4" data-route="${routeAction}">
            <img src="${iconState}" alt="${altState}" width="12px" height="12px">
            <p class="fs14 mb0">${statePayment}</p>
        </div>
        <div class="st-supplierPaymentBloc5">
            <a class="fs14 fw600 ${classActionButton} " href="${currentPage !== "freelance_supplier" ? "/freelance" + data_link : data_link}">${textActionButton}</a>
        </div>
        <div class="st-supplierPaymentBloc6 js-show-actionsSupplierPayment ${divMarkAsPayed}">
            <p class="st-points"></p>
            <p class="st-points"></p>
            <p class="st-points"></p>
            <div class="st-actionsSupplierPayment">
                <p class="${classActionMarkAsPayed}" data-route="${routeMarkAsPayed}" data-supplierpayment-id=${data_supplierPaymentId}>${contentMarkAsPayed}</p>
                <p class="js-deleteSupplierPayment ${classActionDelete}" data-supplierpayment-id=${data_supplierPaymentId}>${translation("Supprimer")}</p>
            </div>
        </div>
    </div>
    `;

    if (data_statusCustom === "success") {
        $(".js-container-successOperations").append(containerOperations);
    }
    else if (data_statusCustom === "waiting") {
        $(".js-container-waitingOperations").append(containerOperations);
    }
}


// Manage waiting and success Bills
let arrayRowTypeBillWait = {
    "EQUOTATION": 0,
    "CREDITED_PAYMENT": 0,
    "DEBITED_PAYMENT": 0,
    "SUPPLIER_PAYMENT": 0,
    "DELAYED_INVOICE": 0,
    "DELAYED_PAYMENT": 0,
    "RECOVERY": 0,
    "DOCUMENT": 0
}

let arrayRowTypeBillSuccess = {
    "EQUOTATION": 0,
    "CREDITED_PAYMENT": 0,
    "DEBITED_PAYMENT": 0,
    "SUPPLIER_PAYMENT": 0,
    "DELAYED_INVOICE": 0,
    "DELAYED_PAYMENT": 0,
    "RECOVERY": 0,
    "DOCUMENT": 0
}

if (currentPage === "freelance_supplier") {
    supplierPaymentBillRequest(6, "waiting", 1);
    supplierPaymentBillRequest(6, "success", "done");
}

$(".js-searchBills").on("keyup change", delay(function () {
    $(".js-container-waitingBills div, .js-container-successBills div").remove();
    for (let rowWait in arrayRowTypeBillWait) {
        arrayRowTypeBillWait[rowWait] = 0;
    }
    for (let rowSuccess in arrayRowTypeBillSuccess) {
        arrayRowTypeBillSuccess[rowSuccess] = 0;
    }
    supplierPaymentBillRequest(6, "waiting", 1);
    supplierPaymentBillRequest(6, "success", "done");

}, 300));

$(".js-showMoreWaitBills").on("click", function() {
    supplierPaymentBillRequest(6, "waiting", 1);
    if ($(".js-reduceWaitBills").not(":visible")) {
        $(".js-reduceWaitBills").show();
    }
});

$(".js-showMoreSuccessBills").on("click", function() {
    supplierPaymentBillRequest(6, "success", "done");
    if ($(".js-reduceSuccessBills").not(":visible")) {
        $(".js-reduceSuccessBills").show();
    }
});

$(".js-reduceWaitBills").on("click", function() {
    let numberElementInRow = 6;
    let realHeight = getRealHeightElement(".st-containerBill");
    let heightRow = realHeight / numberElementInRow;
    reduceElements(".js-container-waitingBills .st-containerBill",
    ".js-container-waitingBills .st-containerBill:last", this, ".js-showMoreWaitBills", arrayRowTypeBillWait, heightRow, 6, 6);
});

$(".js-reduceSuccessBills").on("click", function() {
    let numberElementInRow = 6;
    let realHeight = getRealHeightElement(".st-containerBill");
    let heightRow = realHeight / numberElementInRow;
    reduceElements(".js-container-successBills .st-containerBill",
    ".js-container-successBills .st-containerBill:last", this, ".js-showMoreSuccessBills", arrayRowTypeBillSuccess, heightRow, 6, 6);
});

function supplierPaymentBillRequest(iMax, statusCustom, supplierPaymentState, fromDate = null, toDate = null, searchText = $(".js-searchBills").val(),
    filterOperation = "ALL", typeOperation = "SUPPLIER_PAYMENT") {

    let arrayRowTypeBill;
    if (statusCustom === "waiting") {
        arrayRowTypeBill = arrayRowTypeBillWait;
    }
    else if (statusCustom === "success") {
        arrayRowTypeBill = arrayRowTypeBillSuccess;
    }

    let sendRequest = $.post("/freelance/find_data_home", {
        order: "LAST",
        filter: filterOperation,
        displayType: typeOperation,
        arrayRowType: arrayRowTypeBill,
        search: searchText,
        supplierPaymentState: supplierPaymentState,
        supplierId: supplierId,
        fromDate: fromDate,
        toDate: toDate
    });

    sendRequest.then(val => {
        let v = JSON.parse(val);
        if (v.status === "success") {
            let allDatas = v.data;
            if (allDatas.length > 0) {
                $(".js-container-waitingBills .st-emptySearch, .js-container-successBills .st-emptySearch, .st-emptyBills").remove();
                allDatas.forEach(data => {
                    if (iMax > 0) {
                        addDynamicBillElement(data.title, data.filename, statusCustom);

                        if (statusCustom === "waiting") {
                            arrayRowTypeBillWait["SUPPLIER_PAYMENT"] += 1;
                        }
                        else if (statusCustom === "success") {
                            arrayRowTypeBillSuccess["SUPPLIER_PAYMENT"] += 1;
                        }

                        iMax--;
                    }
                });
                if (allDatas.length < iMax) {
                    if (statusCustom === "waiting") {
                        $(".js-showMoreWaitBills").hide();
                    }
                    else if (statusCustom === "success"){
                        $(".js-showMoreSuccessBills").hide();
                    }
                }
                else if (allDatas.length > 6) {
                    if (statusCustom === "waiting") {
                        $(".js-showMoreWaitBills").show();
                    }
                    else if (statusCustom === "success"){
                        $(".js-showMoreSuccessBills").show();
                    }
                }
            }
            else {
                let isSearch = $(".js-searchBills").val() !== "";

                if (isSearch) {
                    if (statusCustom === "waiting") {
                        $(".js-container-waitingBills p").remove();
                        let emptySearchMessageWaiting = $("<p>", {
                            class: "st-emptySearch",
                            text: translation("Aucune facture en cours")
                        });
                        $(".js-container-waitingBills").append(emptySearchMessageWaiting);
                        $(".js-showMoreWaitBills").hide();
                    }
                    else if (statusCustom === "success") {
                        $(".js-container-successBills p").remove();
                        let emptySearchMessageSuccess = $("<p>", {
                            class: "st-emptySearch",
                            text: translation("Aucune facture réglée")
                        });
                        $(".js-container-successBills").append(emptySearchMessageSuccess);
                        $(".js-showMoreSuccessBills").hide();
                    }
                }
                else {
                    if ($(".js-container-waitingBills div").length === 0 && $(".js-container-successBills div").length === 0) {
                        $(".st-emptyBills").show();
                        $(".st-doneInvoices, .st-inProgressInvoices").removeClass("d-flex");
                        $(".st-bloc-waitingBills, .st-bloc-successBills, .js-show-filterBills, .js-hide-filterBills, .st-filterBills, .st-doneInvoices, .st-inProgressInvoices").hide();
                    }
                }
            }
        }
        else {
        }
    });
}

function addDynamicBillElement(data_name, data_pdf, data_statusCustom) {

    let thumbnail= createThumbnailFromPDF(data_pdf);

    let containerBill = `
    <div class="st-containerBill">
        <div class="js-show-preview-bills mt10"> <a target="_blank" href="${data_pdf}"><img src="${thumbnail}" width="64px" height="90px" alt="preview bill" /></a></div>
        <p class="fs14 fw600 mt10">${data_name}</p>
    </div>
    `;

    if (data_statusCustom === "success") {
        $(".js-container-successBills").append(containerBill);
    }
    else if (data_statusCustom === "waiting") {
        $(".js-container-waitingBills").append(containerBill);
    }
}

// Manage filter date of bills
$(".js-changeFromDateBill, .js-changeToDateBill").on("change", function() {
    let valueFromDate = getValidFormatDate($(".js-changeFromDateBill").val(), "/");
    let valueToDate = getValidFormatDate($(".js-changeToDateBill").val(), "/");
    let timeFromDate = new Date(valueFromDate).getTime();
    let timeToDate = new Date(valueToDate).getTime();
    if (timeFromDate > timeToDate) {
        $(".st-blocFormError1").removeClass("d-none").addClass("d-flex");
    }
    else {
        $(".st-blocFormError1").removeClass("d-flex").addClass("d-none");
        supplierPaymentBillRequest(6, "waiting", 1, new Date(valueFromDate), new Date(valueToDate));
        supplierPaymentBillRequest(6, "success", "done", new Date(valueFromDate), new Date(valueToDate));
    }
});

// redirect supplier payment from supplier
$(".js-redirect-supplierPayment").on("click", function() {
    let id = $(this).attr('data-id');
    window.location.href = "/fournisseur/paiement?fournisseur="+id;
});

$(".st-back-suppliers").on("click", function() {
    window.location.href = "/freelance/fournisseurs";
});

// Methods and Refacto
const reduceElements = (rowContainer, lastRow, reduceElt, showMoreElt, arrayRowType, isScroll = false, iMax = 10, lengthRowDefault = 3) => {
    let i = 0;
    while (i < iMax && $(rowContainer).length > lengthRowDefault) {
        arrayRowType["SUPPLIER_PAYMENT"] -= 1;
        $(lastRow).remove();
        i++;
        
        if ($(rowContainer).length === lengthRowDefault) {
            $(reduceElt).hide();
            $(showMoreElt).show();
        }
    }
    if (isScroll !== false) {
        // isScroll represent the height (82px for exemple) of a row
        scrollHeightToValue(i * isScroll);
    }
}

// Verifications front supplier
$(".popin-updateSupplier .verif-front").on("input change", function() {
    checkAllInputs(".popin-updateSupplier .popin-content", ".js-request-updateSupplier");
});

$(".popin-addFolderSupplier .verif-front").on("input change", function() {
    checkAllInputs(".popin-addFolderSupplier .popin-content", ".js-request-addFolderSupplier");
});

$(".popin-downloadAllBills .verif-front").on("input change", function() {
    checkAllInputs(".popin-downloadAllBills .popin-content", ".js-request-downloadBills");
});

