import { openPopin } from "../generalFunctions";
import ApexCharts from 'apexcharts';

jQuery( function() {
  $.fn.dataTable.moment('DD/MM/YYYY');
  $('#mytable4').DataTable({
    "order": [],
    'responsive': true,
    'columns:': [
      {   // Responsive control column
          data: null,
          defaultContent: '',
          className: 'control',
          orderable: false
      },
      {   // Checkbox select column
          data: null,
          defaultContent: '',
          className: 'select-checkbox',
          orderable: false
      },
      { data: "first_name" },
      { data: "last_name", className: 'never' },
      { data: "position" },
      { data: "office" },
      { data: "start_date" },
      { data: "salary", render: $.fn.dataTable.render.number( ',', '.', 0, '$' ) }
    ],
    "language": {
        "decimal":        "",
        "emptyTable":     "Aucune donnée",
        "info":           "Showing _START_ to _END_ of _TOTAL_ entries",
        "infoEmpty":      "Showing 0 to 0 of 0 entries",
        "infoFiltered":   "(filtered from _MAX_ total entries)",
        "infoPostFix":    "",
        "thousands":      ",",
        "lengthMenu":     "Afficher les entrées" + " _MENU_",
        "loadingRecords": "Chargement...",
        "processing":     "En cours de traitement...",
        "search":         "",
        "searchPlaceholder": "Recherche",
        "zeroRecords":    "Aucune correspondance",
        "paginate": {
            "first":      "Premier",
            "last":       "Dernier",
            "next":       "Suivant",
            "previous":   "Précédent"
        },
        "aria": {
            "sortAscending":  ": activate to sort column ascending",
            "sortDescending": ": activate to sort column descending"
        }
    },
    "fnDrawCallback": function() {
      if ($('#mytable_paginate span a').length < 2) {
          $('#mytable_paginate').hide();
      }
      if ($('#mytable1_paginate span a').length < 2) {
        $('#mytable1_paginate').hide();
      }
      if ($('#mytable2_paginate span a').length < 2) {
        $("#mytable2_paginate").hide();
      }
    }
  });
});

$(document).on('click', '.js-payment-details', function () {
  openPopin(".infos-payment-content");
  let paymentId = $(this).attr('data-id');
  let promiseReturn = $.post('/freelance/admin/payments/payins', {paymentId: paymentId});
  promiseReturn.then(function (val) {
    let v = JSON.parse(val);
    if (v.status === "success") {
      let array = v.dataPayment[0];
      var htmlPayedDate = array['payedDate'];
      let html = "<h3>" + array['subject']+" ("+paymentId+")" + "</h3>";
      html += "<p><b>" + "Payeur : </b>"+array['sender'] + "</p>";
      html += "<p><b>" + "Payé : </b>"+array['receiver'] + "</p>";
      html += "<p><b>" + "Montant : </b>"+(array['amount'])/100.00 + " €</p>";
      html += "<p><b>" + "Date d'envoi : </b>"+array['sentDate']+ "</p>";
      if (htmlPayedDate != "") {
        html += "<p><b>" + "Payé le : </b>"+htmlPayedDate+ "</p>";
      }
      html += "<p><b>" + "Statut : </b>"+array['status'] + "</p>";
      html += "<p><b>" + "Paiement programmé : </b>"+array['delayedPayment'] + "</p>";
      html += "<p><b>" + "Envoi programmé : </b>"+array['delayedInvoice'] + "</p>";
      let discount = array['discount'];
      if (discount == 2) {
        discount = "utilisé";
      }
      else if (discount == 1) {
        discount = "proposé";
      }
      else {
        discount = "néant";
      }
      let multiPayment = array['multiPayment'];
      if (multiPayment == 2) {
        multiPayment = "utilisé";
      }
      else if (multiPayment == 1) {
        multiPayment = "proposé";
      }
      else {
        multiPayment = "néant";
      }
      html += "<p><b>" + "Escompte : </b>"+discount+ "</p>";
      html += "<p><b>" + "3x sans frais : </b>"+multiPayment+ "</p>";
      html += "<p><b>" + "Marqué comme payé : </b>"+(array['markedAsPaid'] == 1 ? "oui" : "non") + "</p>";
      html += "<p><b>" + "Méthode de paiement : </b>"+array['usedMethod'] + "</p>";
      let payins = '<table class="promoTable table table-striped" style="width:100%"><thead><tr"><th style="color: black;">Montant</th><th style="color: black;">Mangopay ID</th><th style="color: black;">Statut</th><th style="color: black;">Payé le</th><th style="color: black;">Echéhance le</th></tr></thead><tbody>';
      v.dataPayin.forEach((elem) => {
        payins += "<tr>";
        payins += '<td height="15">'+(elem['amount'])/100.00+" €</td>";
        payins += '<td height="15">'+elem['payinId']+"</td>";
        payins += '<td height="15">'+elem['status']+"</td>";
        payins += '<td height="15">'+elem['payedDate']+"</td>";
        payins += '<td height="15">'+elem['plannedDate']+"</td>";
        payins += "</tr>";
      });
      payins += "</tbody></table>";
      html += "<p>"+payins+"</p>";
    
      $(".js-infos-payment-content").html(html);
    }
    else {
      let error = v.message;
      $('.error-promo').removeClass('d-none').html(error);
    }
  });  
});

$(document).on('click', '.js-search-data', function () {
  $('.error-promo').addClass('d-none').html('');
  let email = $('#email').val();
  window.location.href = '/freelance/admin/payments/user?email='+email;
  // let promiseReturn = $.post('/freelance/admin/payments/user', {email: email});
  // promiseReturn.then(function (val) {
  //   let v = JSON.parse(val);
  //   if (v.status === "success") {
  //     let table = "";
  //     v.data.forEach((elem) => {
  //       table += createLine(elem);
  //     });
  //     $('.array_body').html(table);
  //   }
  //   else {
  //     let error = v.message;
  //     $('.error-promo').removeClass('d-none').html(error);
  //   }
  // });
});

function createLine(array) {
  let line = "<tr>";

  line += '<td height="15">'+array['subject']+'</td>';
  line += '<td height="15">'+array['sender']+'</td>';
  line += '<td height="15">'+array['receiver']+'</td>';
  line += '<td height="15">'+array['sentDate']+'</td>';
  line += '<td height="15">'+array['payedDate']+'</td>';
  line += '<td height="15"><span class="btn btn-unfilled-primary js-payment-details" data-id="'+array['id']+'">Détails</span></td>';

  line += "</tr>";
  return line;
}

$(document).on('click', '.js-show-list', function () {
  $(".bloc-payments").show();
  $(".bloc-graph").hide();
});

$(document).on('click', '.js-get-payment-stats', function () {
  $(".bloc-payments").hide();
  $(".bloc-graph").show();
  let promiseReturn = $.get('/freelance/admin/paymentStats/graph');
  promiseReturn.then(function (val) {
    let v = JSON.parse(val);
    let dataArray = [];
    let statusArray = [];
    Object.entries(v.methods[0]).forEach(([key, value]) => {
      dataArray.push(parseInt(value));
    });
    Object.entries(v.status[0]).forEach(([key, value]) => {
      statusArray.push(parseInt(value));
    });
    var optionsMethod = {
      series: dataArray,
      labels: ['CB', 'SEPA', 'Bridge'],
      chart: {
        type: 'pie',
        height: 200
      },
    };
    var optionsStatus = {
      series: statusArray,
      labels: ['FAILED', 'SUCCEEDED', 'CREATED'],
      chart: {
        type: 'pie',
        height: 200
      },
    };

    var chartMethod = new ApexCharts(document.querySelector("#chart-method"), optionsMethod);
    var chartStatus = new ApexCharts(document.querySelector("#chart-status"), optionsStatus);
    chartMethod.render();
    chartStatus.render();

    $("#count-first-mandate").text(v.firstMandates[0]['nb']);
  })
});
