let translation_es = {
    "% d’escompte</span> pour un règlement avant le ": "% de descuento</span> para un pago antes del",
    "3x sans frais": "3 plazos sin intereses",
    "12 derniers mois": "Últimos 12 meses",
    "1 000 € maximum par carte": "1 000 € como máximo por tarjeta de crédito",
    "20 000 € maximum par prélèvement": "20 000 € máximo por domiciliación",
    "a bien été ajouté": "se ha añadido correctamente",
    "a bien été envoyée": "enviado",
    "À régler": "A pagar",
    "À régler avant": "A pagar antes el",
    "À signer": "A firmar",
    "À valider": "A validar",
    "Approbation en attente": "Aprobación pendiente",
    "Aucun commentaire pour le moment...": "Sin comentarios por el momento...",
    "an": "año",
    "Abonnement": "Suscripción",
    "Afficher les entrées": "Visualizar las entradas",
    "Ajouter": "Añadir",
    "Ajouter des paiements": "Añadir pagos",
    "annuel": "anual",
    "Annuler": "Cancelar",
    "Appliquer promo": "Aplicar promoción",
    "Approbation demandée": "Aprobación pedida",
    "Approbation refusée": "Aprobación rechazada",
    "Approbation validée": "Aprobación validada",
    "Association": "Asociación",
    "Atouts Soan": "Ventajas Soan",
    "Aucune approbation": "No tiene ninguna pedida de aprobación pendiente",
    "Aucune correspondance": "No hay correspondencia",
    "Aucune demande": "No hay solicitudes pendientes",
    "Aucune demande2": "Ninguna solicitud programada pendiente",
    "Aucune donnée": "No hay datos disponibles",
    "Aucun élément": "Ningún elemento corresponde a tu búsqueda",
    "Aucune facture en cours": "No hay facturas en curso que coincidan con tu búsqueda", //
    "Aucune facture réglée": "No hay facturas pagadas que coincidan con tu búsqueda", //
    "Aucun favoris": "Lamentablemente, no hay carpetas en tus \"favoritos que coincidan\" con tu búsqueda.", //
    "Aucune notification": "Aquí encontrarás un resumen de tus últimas actividades en Soan",
    "aucun objectif": "no has proporcionado ningún objetivo",
    "Aucune opération en cours": "No hay transacciones en curso que coincidan con tu búsqueda", //
    "Aucune opération réglée": "No hay transacciones pagadas que coincidan con tu búsqueda", //
    "Aucun paiement": "Ningún pago pendiente",
    "Aucun paiement2": "Ningún pago programado pendiente",
    "Aucun recouvrement": "Ningún cobro de deuda pendiente",
    "Aucun résultat": "No podemos encontrar « <span class='js-dynamicSearchName'></span> » en esta categoría.", //
    "Aucune signature": "Ninguna firma o validación en curso",
    "Aucune transaction": "No hay más transacciones",
    "Autre": "Otros",
    "bénéficiaire majeur": "el beneficiario efectivo debe ser mayor de edad",
    "bien programmés et sera envoyée le": "se ha programado y se enviará el",
    "Bonne nouvelle": "¡Buenas noticias! <br> No tienes facturas pendiente de pago.",
    "Carte expirée": "La fecha de caducidad de la tarjeta ha pasado",
    "Ce champs ne doit contenir que des chiffres": "Este campo solo debe contener números",
    "Ces factures ne sont pas éligibles au 3x sans frais": "Selecciona una sola factura <br> elegible para el pago en 3 plazos sin intereses para <br> utilizar este método de pago",
    "Cette facture n'est pas éligible au 3x sans frais": "Selecciona una sola factura <br> elegible para el pago en 3 plazos sin intereses para <br> utilizar este método de pago",
    "Changer": "Cambiar",
    "Chargement...": "Cargando...",
    "Cliquer pour copier !": "¡Clicar para copiar!",
    "code invalide": "El código introducido no es válido.",
    "Code promo en attente": "Código promocional pendiente",
    "Code promo utilisé": "Código promocional utilizado",
    "Compléter": "Completar",
    "Compris": "Entendido",
    "Confirmer": "Confirmar",
    "Comptable": "Gestor",
    "Continuer": "Continuar",
    "Copié !": "¡Copiado!",
    "Créer une entreprise": "Crear una empresa u organismo",
    "Création": "Creación",
    "critères mot de passe": "tu contraseña debe tener 8 caracteres 1 minúscula 1 mayúscula y 1 número",
    "Date de facturation inférieure": "La fecha de facturación debe ser anterior a la fecha de vencimiento de la factura.",
    "Date d'inscription": "Fecha de registro",
    "date ultérieure": "elije una fecha posterior",
    "Demande de paiement": "Solicitud de pago",
    "Dernière connexion": "Última conexión",
    "Devis créé le": "Presupuesto creado el",
    "Devis émis le": "Presupuesto emitido el",
    "Devis envoyé le": "Presupuesto enviado el",
    "d’escompte avant le": "de descuento </span> por un pago antes del",
    "Dossier principal": "Carpeta principal",
    "DSO moyen": "DSO medio",
    "Échec": "Fallido",
    "Échéance": "Vencimiento",
    "Échue depuis": "Vencida desde",
    "Éditer le texte": "Editar el texto",
    "Factures par an": "Facturas por año",
    "Factures par mois": "Facturas por mes",
    "Envoyer": "Enviar",
    "Envoyé": "Enviado",
    "Email": "Correo electrónico",
    "Emis le": "Emitido el",
    "En attente": "Pendiente",
    "En cours de traitement...": "En curso de tratamiento...",
    "En cours de transfert": "En curso de transferencia",
    "En cours depuis le": "En curso desde el",
    "En savoir plus": "Saber más",
    "Encaissé": "Cobrado",
    "Enregistrer": "Registrar",
    "entreprise": "empresa",
    "Entreprise": "Empresa",
    "Envoi": "Envío",
    "Erreur import facture" : "Ha ocurrido un error al importar la factura",
    "Erreur lors de l'ajout du commentaire": "Se ha producido un error al añadir el comentario. Por favor, inténtelo más tarde.",
    "Erreur survenue": "Se ha producido un error",
    "Escompte": "Descuento",
    "Êtes-vous sûr ?": "¿Estás seguroa?",
    "Facturation": "Facturado",
    "Facture": "Factura",
    "Facture générée": "Factura generada",
    "Félicitation, vous avez reçu le virement en instantané": "¡Felicidades! Has recibido la transferencia al momento El dinero ya está disponible en tu cuenta bancaria.",
    "Flux d'affaire cumulé par mois": "Flujo de negocio acumulado por mes",
    "Flux d'affaire processé cumulé": "Flujo de negocio procesado acumulado",
    "Fondation": "Fundación",
    "Format de fichier non valide": "Formato de archivo no valido",
    "Gérant": "Gerente",
    "Glissez deposez": 'Arrastra y suelta o explora tus documentos para añadir archivos', //
    "HT / an soit": "Sin IVA año o",
    "HT / mois soit": "Sin IVA mes o",
    "Il semblerait que ce SIRET n’existe pas": "Parece que este CIF/NIF no existe",
    "Il semblerait que ce CIF/NIF n’existe pas": "Parece que este CIF/NIF no existe",
    "Il semblerait que ce code ne soit pas valide.": "Parece que este código ne es válido.",
    "Importé le":"Importada el :",
    "Information copiée": "Información copiada",
    "Infos": "Info",
    "Infos manquantes": "Datos incompletos",
    "Je ne trouve pas ma banque": "No encuentro mi banco",
    "jour": "día",
    "Jour": "Día",
    "jours": "días",
    "Jours": "Días",
    "Le code promo a bien été ajouté !": "¡Se ha añadido el código promocional!",
    "Le fichier doit être au format PDF": "El fichero debe estar en formato PDF",
    "Les fichiers téléchargés": "Los ficheros descargados no deben superar los 7 M0",
    "Le montant de votre facture": "El importe de tu factura debe ser superior a 1 500 €",
    "Le montant max 1 000 €": "El importe máximo por tarjeta es de 1 000 €",
    "Le nombre de paiement a bien été ajouté !": "¡Se ha bien añadido el número de pagos!",
    "Le recouvrement de cette facture": "No se acepta el cobro de esta factura",
    "Le représentant de l'entreprise": "El representante de la empresa debe ser mayor de edad",
    "Lien copié": "¡Enlace copiado !",
    "Lien de paiement": "Enlace de pago",
    "logo jpg ou png": "el logotipo de su empresa debe estar en formato .jpg o .png",
    "Marquer comme payé": "Marcar como pagado",
    "mensuel": "mensual",
    "Membre": "Miembro",
    "Modèle supprimé": "Tu plantilla ha sido eliminada",
    "Modifier": "Modificar",
    "mois": "meses",
    "mois restants": "meses restantes",
    "Montant": "Importe",
    "Montant cumulé": "Importe acumulado",
    "Ne pas marquer comme payé": 'No "marcar como pagado"',
    "Nom": "Nombre",
    "Nombre invalide": "Número inválido",
    "Nombre de demande de paiement cumulées": "Número de solicitudes de pago acumuladas",
    "Nombre de demande par mois": "Número de solicitudes por mes",
    "NON": "NO",
    "num facture un chiffre minimum": "el número de tu factura debe incluir al menos un número",
    "Numéro copié !": "Numéro copié !",
    "objet et message obligatoires": "un asunto y un mensaje son obligatorios",
    "Objectif de CA": "Objetivo de volumen de negocios",
    "organisme": "organismo",
    "OUI": "SÍ",
    "par an": "por año",
    "par mois": "por mes",
    "Paiement": "Pago",
    "paiements": "pagos",
    "Paiement programmé": "Pago programado",
    "Paiements restants": "Pagos pendientes",
    "Paiement de la facture": "Pago de la factura",
    "Paiement sécurisé par virement bancaire": "Pago seguro por transferencia bancaria",
    "Paiement supérieur à 20 000 €": "Los pagos superiores a 20 000 €, solo pueden hacerse por transferencia bancaria.",
    "Payer": "Pagar",
    "Payer maintenant": "Pagar ahora",
    "Précédent": "Anterior",
    "Profil vérifié": "Perfil verificado",
    "Programmé": "Programado",
    "Programmé le": "Programado el",
    "Programmé pour le": "Programado el",
    "Promo": "Promo",
    "Rappel": "Recordar",
    "réaliser virement traditionnel": "tienes que hacer una transferencia normal... ¡Ánimos!",
    "Recherche": "Búsqueda",
    "Recouvrement lancé le": "Procedimiento de cobro de la deuda iniciado  el",
    "Recouvrir": "Recubrir",
    "Réglé": "Pagado",
    "Réglé le": "Pagado el",
    "Relance": "Aviso",
    "Relancer": "Recordar",
    "Remplacer cette facture": "Reemplazar esta factura",
    "Représentant légal": "Representante legal",
    "Retard": "Retraso",
    "Retour": "Volver",
    "Renvoyer": "Devolver",
    "S'agit-il de cette entreprise ?": "¿ Se trata de esta empresa ?",
    "Sélectionnez": "Seleccionar",
    "Sélectionnez une seule facture": "Selecciona una sola factura <br> elegible para el pago en 3 plazos sin intereses para <br> utilizar este método de pago", //
    "Signer": "Firmar",
    "Signature complète": "Si quieres una firma completa hay que llenar todos los campos",
    "Signature en cours": "Firma en proceso",
    "Signature : terminée": "Firma finalizada",
    "Si votre organisme n'a pas de Siret": "Si tu organismo no tiene un NIF/CIF dejalo vacío",
    "soit": "o",
    "Somme récupérée :": "Suma recuperada",
    "Sortie": "Desembolsado",
    "Suivant": "Siguiente",
    "Supprimer": "Eliminar",
    "sur": "sobre",
    "synthèse": "síntesis",
    "Télécharger": "Descargar",
    "Téléphone": "Teléfono",
    "Terminé": "Completado",
    "Tous les champs": "Todos los campos deben ser completados",
    "TTC": "Con IVA",
    "TTC / an": "Con IVA/año",
    "TTC / an business terminé": "Con IVA / año una vez tu suscripción Business terminada",
    "TTC / an premium terminé": "Con IVA / año una vez tu suscripción Premium terminada",
    "TTC / mois": "Con IVA / mes",
    "TTC / mois business terminé": "Con IVA / mes una vez tu suscripción Business terminada",
    "TTC / mois premium terminé": "Con IVA / mes una vez tu suscripción Premium terminada",
    "TTC doit être supérieur HT": "El total con IVA debe ser igual o superior al subtotal sin IVA",
    "TVA": "IVA",
    "type": "tipo",
    "Un objet et un message sont obligatoires": "Es obligatorio poner el asunto y un mensaje",
    "Une erreur est survenue" : "Se ha producido un error.",
    "Valider": "Validar",
    "Validation en cours": "Validación en curso",
    "terminée": "validación terminada",
    "Vérifiez le numéro SIREN": "Comprueba que el número de indentificación de tu cliente no es el mismo que el tuyo",
    "Vérifiez votre lien hubspot": "Comprueba tu vínculo HubSpot",
    "Vérifiez dossiers archivés": "¿Deseas consultar tus <span class='text-dark st-text-decoration cpointer js-toClosedProjects'>carpetas archivadas</span> ?", //
    "Veuillez accepter tous les champs": "Acepta todos los campos siguientes",
    "Veuillez ajouter un élément": "Añade un concepto",
    "Veuillez ajouter un justificatif": "Añade un justificante",
    "Veuillez ajouter votre facture": "Añade tu factura",
    "Veuillez choisir une option": "Elije una opción para la fecha de envío",
    "Veuillez cocher les champs obligatoires": "Marca los campos obligatorios",
    "Veuillez entrez un numéro de carte valide.": "Por favor, introduce un número de tarjeta válido.",
    "Veuillez remplir ce champ": "Rellena este campo",
    "Veuillez remplir les champs": "Completa los siguientes campos obligatorios",
    "Veuillez renseigner les champs obligatoires": "Completa los campos obligatorios",
    "Veuillez renseigner tous les champs": "Completa todos los campos",
    "Veuillez renseigner une adresse mail valide": "Introduce un correo electrónico válido",
    "Veuillez renseigner une date": "Indica una fecha",
    "Veuillez renseigner un iban valide": "Por favor ingresa un IBAN válido",
    "Veuillez sélectionner une option": "Selecciona una opción",
    "Veuillez valider le SIREN": "Valida el CIF  NIF de la empresa",
    "Veuillez valider les cases à cocher": "Valida las casillas que deben marcarse",
    "Vous devez être majeur": "Debes ser mayor de edad para usar la aplicación",
    "Vous ne pouvez pas lancer un recouvrement": "No puedes empezar un proceso de cobro de deuda en tu propia empresa",
    "Votre banque n'a pas accepté le virement instantané": "Tu banco no ha aceptado la transferencia instantánea. El dinero estará disponible dentro de las 24 horas por transferencia normal.",
    "Votre devis Euler Hermes": "Tu presupuesto Euler Hermes",
    "Votre photo semble incorrecte": "Tu foto parece incorrecta",
    "Votre modèle": "Tu modelo",
    "Votre numéro de téléphone ne semble pas valide": "Tu número de teléfono no parece válido",
    "Votre numéro ne semble pas être au bon format": "Tu número no parece estar en el formato adecuado",
    "Votre logo d'entreprise a bien été intégré.": "Se ha integrado el logotipo de tu empresa",
    "Votre paiement a bien été marqué comme payé.": "Tu pago ha sido marcado como pagado.",
    "Votre SIRET est trop court": "Tu NIF  CIF es demasiado corto",

    /* Mois */
    "Janvier": "Enero",
    "Février": "Febrero",
    "Mars": "Marzo",
    "Avril": "Abril",
    "Mai": "Mayo",
    "Juin": "Junio",
    "Juillet": "Julio",
    "Août": "Agosto",
    "Septembre": "Septiembre",
    "Octobre": "Octubre",
    "Novembre": "Noviembre",
    "Décembre": "Diciembre",

    /* Préfixe mois */
    "jan": "Ene",
    "fev": "Feb",
    "mar": "Mar",
    "avr": "Abr",
    "mai": "May",
    "jui": "Jun",
    "juil": "Jul",
    "aou": "Ago",
    "sep": "Sep",
    "oct": "Oct",
    "nov": "Nov",
    "dec": "Dic",

    /* Préfixe jours de semaine */
    "Lun": "Lun",
    "Mar": "Mar",
    "Mer": "Mie",
    "Jeu": "Jue",
    "Ven": "Vie",
    "Sam": "Sab",
    "Dim": "Dom",

}
export default translation_es;