import {checkAllInputs} from "../verificationFront";
import {
    hidePopin,
    getValidFormatDate,
    getAndSetIban,
    differenceDate,
    divideNumberByThree,
    numberWithSpacesWithComa,
    translation,
    getCardBrand
} from "../../generalFunctions";
import $ from "jquery";
// $('.js-multipayment-tab').on('click', (e) => {
//     $('.st-multipayment-tab-selected').removeClass('st-multipayment-tab-selected');
//     $(e.target).addClass('st-multipayment-tab-selected');

//     const unselectSelected = () => {
//         $('.selected').find('.js-checkMarkWaySelected').addClass('d-none');
//         $('.selected').removeClass('selected');
//     }

//     const selectFirstBank = (target) => {
//         $(target).closest('.js-withMultipayment').find('.bank').first().addClass('selected').find('.js-checkMarkWaySelected').removeClass('d-none');
//     }

//     const selectOneClic = () => {
//         $('.one-clic-payment').addClass('selected').find('.js-checkMarkWaySelected').removeClass('d-none');
//     }

//     // TABS
//     if ($(e.target).attr('id') === 'js-multipayment-tab') {
//         $('.js-multipayment-content').removeClass('d-none');
//         $('.js-oneclic-content').addClass('d-none');
//         unselectSelected();
//         selectFirstBank(e.target);
//         if (!$('.js-multipayment-content .selected').length) {
//             $('.js-payment-bank').addClass('st-no-btn-disabled');
//         } else {
//             $('.js-payment-bank').removeClass('st-no-btn-disabled');
//         }
//     } else {
//         $('.js-multipayment-content').addClass('d-none');
//         $('.js-oneclic-content').removeClass('d-none');
//         $('.payment-delayed-content').hide();
//         unselectSelected(); 
//         selectOneClic();  
//         if (!$('.js-oneclic-content .selected').length) {
//             $('.js-payment-bank').addClass('st-no-btn-disabled');
//         } else {
//             $('.js-payment-bank').removeClass('st-no-btn-disabled');
//         } 
//     }
// })

$("#virementMenu").on("click", function() {
    $(".st-paymentMenu").removeClass("active");
    $(".st-paymentMenu img, .st-bankingCard-title, .st-debiting-title, .st-blocDebitingPayment, .st-blocBankingCard").hide();
    $("#virementMenu img, .st-virement-title, .st-blocVirementPayment").show();
    $(this).addClass("active");
    $(".st-blocFormError1").removeClass("d-flex").addClass("d-none");
});

$(document).on('click', '.js-addingBankingCard', function (e) {
    e.preventDefault();
    $('.loader-popin').fadeIn();
    let cardNumber = $('.js-cardNumber').val();
    let cardExpirationDate = $('.js-expirationDate').val().replace("/", "");
    let cardCvx = $(".js-crypto").val();
    let cardType = getCardBrand(cardNumber);

    let promiseReturn = $.post("/freelance/create_card", {
        'cardType': cardType
    })

    promiseReturn.then(function(val) {
        let v = JSON.parse(val);
        if (v.status === "success") {
            let cardId = v.id;
            let promiseReturn2 = $.post(v.url, {
                "accessKeyRef": v.AccessKey,
                "data": v.PreregistrationData,
                "cardNumber": cardNumber,
                "cardExpirationDate": cardExpirationDate,
                "cardCvx": cardCvx
            });
            promiseReturn2.then(function (val) {
                let data = val.substring(5);
                let promiseReturn3 = $.post('/freelance/update_card', {
                    "cardId": cardId,
                    "data": data
                })
                promiseReturn3.then(function (val) {
                    let w = JSON.parse(val);
                    if (w.status === "success") {
                        $(".popin-payment-bank").css("display", "block");
                        $(".popin-addBankingCard").css("display", "none");
                        $('.js-cardNumber').val('');
                        $('.js-expirationDate').val('');
                        $('.js-crypto').val('');
                        getUserCards();
                        $('.loader-popin').fadeOut();
                    }
                })
            })
        }
    });
})

$("#bankingCardMenu").on("click", function() {
    if ($(this).attr("data-actif") === "true" && !$(this).hasClass('active')) {
        $('.loader-popin').fadeIn();
        $(".st-paymentMenu").removeClass("active");
        $(".st-paymentMenu img, .st-virement-title, .st-debiting-title, .st-blocVirementPayment, .st-blocDebitingPayment").hide();
        $("#bankingCardMenu img, .st-bankingCard-title, .st-blocBankingCard").show();
        $(this).addClass("active");
        $(".st-blocFormError1").removeClass("d-flex").addClass("d-none");

        getUserCards()
    }
});

function getUserCards() {
    let cardList = $('.js-card-list');
    cardList.html('');
    let promiseReturn = $.get('/freelance/user_cards');
    promiseReturn.then(function (val) {
        let v = JSON.parse(val);
        if (v.status === "success") {
            if (v.cards.length > 0) {
                for (let i = 0; i < v.cards.length; i++) {
                    let card = v.cards[i];
                    if (card.Active === true) {
                        let imgSrc;
                        let text;
                        if (card.CardProvider === "MASTERCARD") {
                            imgSrc = "/build/images/mastercard.svg";
                            text = "Mastercard - ";
                        }
                        else if (card.CardProvider === "VISA") {
                            imgSrc = "/build/images/visa.svg";
                            text = "Visa - ";
                        }
                        else if (card.CardProvider === "AMEX") {
                            imgSrc = "/build/images/aexpress.svg";
                            text = "American Express - ";
                        }
                        else {
                            imgSrc = "/build/images/cb.png";
                            text = "Carte Bancaire - ";
                        }

                        if (i === 0) {
                            cardList.append("<div class='st-bankCard js-bankCard d-flex card-selected' data-type='"+card.CardType+"' data-card='"+ card.Id+"'> <p class='m-0'><img src='" + imgSrc + "' alt='logo de la banque' class='select-card-logo' width='38px'>" + text + (card.Alias).substring((card.Alias).length - 4) + "</p> <img src='/build/images/green_check.svg' class='check-card-selected' width='24px' height='24px' alt='check'></div>")
                        }
                        else {
                            cardList.append("<div class='st-bankCard d-flex js-bankCard' data-type='"+card.CardType+"' data-card='"+ card.Id+"' > <p class='m-0'><img src='" + imgSrc + "' alt='logo de la banque' class='select-card-logo' width='38px'>" + text + (card.Alias).substring((card.Alias).length - 4) + "</p><img src='/build/images/green_check.svg' class='check-card-selected' width='24px' height='24px' alt='check'></div>")
                        }
                    }
                }
            }
            $('.loader-popin').fadeOut();
        }
        else {
            let error = v.message;
            $('.loader-popin').fadeOut();
            $('.js-payWithBankingCard').removeClass('st-no-btn-disabled');
            $(".st-blocFormError1").addClass("d-flex").removeClass("d-none");
            $('.js-errorMsg').html(error);
        }
    });
}

$(document).on("click", ".js-bankCard", function() {
    $('.js-bankCard').removeClass('card-selected');
    $(this).addClass('card-selected');
})

$(document).on('click', '.js-payWithBankingCard', function () {
    $('.loader-popin').fadeIn();
    let card = $('.js-bankCard.card-selected');
    $(this).addClass('st-no-btn-disabled')
    let idPayment = $(this).attr('data-payment');
    let idProject = $(this).attr('data-project');
    let cardId = card.attr('data-card');
    let cardType = card.attr('data-type');

    let javaEnabled = navigator.javaEnabled();
    let language = navigator.language;
    let javascriptEnabled = true;
    let colorDepth = screen.colorDepth;
    let screenHeight = screen.height;
    let screenWidth = screen.width;
    let timeZoneOffset = new Date().getTimezoneOffset();

    let promiseReturn = $.post('/freelance/payin_card', {
        "redirectUrl": window.location.href,
        "payment": idPayment,
        "project": idProject,
        "cardId": cardId,
        "cardType": cardType,
        'javaEnabled': javaEnabled,
        'javascriptEnabled': javascriptEnabled,
        'colorDepth': colorDepth,
        'screenHeight': screenHeight,
        'screenWidth': screenWidth,
        'timeZoneOffset': timeZoneOffset,
        'language' : language,
    });

    promiseReturn.then(function (val) {
        let v = JSON.parse(val);
        if (v.status === 'success') {
            window.location.href = v.redirectUrl;
        }
        else {
            let error = v.message;
            $('.loader-popin').fadeOut();
            $('.js-payWithBankingCard').removeClass('st-no-btn-disabled');
            $(".st-blocFormError1").addClass("d-flex").removeClass("d-none");
            $('.js-errorMsg').html(error);
        }
    })
})

$("#debitingMenu").on("click", function() {
    if ($(this).attr("data-actif") === "true") {
        $(".st-paymentMenu").removeClass("active");
        $(".st-paymentMenu img, .st-virement-title, .st-bankingCard-title, .st-blocVirementPayment, .st-blocBankingCard").hide();
        $("#debitingMenu img, .st-debiting-title, .st-blocDebitingPayment").show();
        $(this).addClass("active");
        let mandateActif = false;
        $(".bank-list .bank").each(function() {
            if ($(this).hasClass("selected") && $(this).attr("data-mandatestatus") === "ACTIVE") {
                mandateActif = true;
            }
        });
        if (mandateActif) {
            // $(".payment-delayed-content").show();
            $('.st-infoPayLayer').hide();
            $('.js-show-payafter-label').show();
        }
        $(".st-blocFormError1").removeClass("d-flex").addClass("d-none");
    }
});

$(".st-showBloc3x").on("click", function() {
    $(this).removeClass("d-flex").addClass("d-none");
    $(".st-hideBloc3x").removeClass("d-none").addClass("d-flex");
    $(".st-Bloc3xMulti, .st-textIn3x").show();
    $(".payment-delayed-content").hide();
});

$(".st-hideBloc3x").on("click", function() {
    $(this).removeClass("d-flex").addClass("d-none");
    $(".st-showBloc3x").removeClass("d-none").addClass("d-flex");
    $(".st-Bloc3xMulti, .st-textIn3x").hide();
    let mandateActif = false;
    $(".bank-list .bank").each(function() {
        if ($(this).hasClass("selected") && $(this).attr("data-mandatestatus") === "ACTIVE") {
            mandateActif = true;
        }
    });
    if (mandateActif) {
        $(".payment-delayed-content").show();
    }
});

$(".st-showBloc3x").on("mouseover", function() {
    $(".st-showBloc3x p").show().animate({"width": "86px"}, 300);
});

$(".st-showBloc3x").on("mouseleave", function() {
    $(".st-showBloc3x p").animate({"width": "0"}, 300, function() {
        $(this).hide();
    });
});

$(".st-hideBloc3x").on("mouseenter", function() {
    $(".st-hideBloc3x p").show().animate({"width": "106px"}, 300);
});

$(".st-hideBloc3x").on("mouseleave", function() {
    $(".st-hideBloc3x p").animate({"width": "0"}, 300, function() {
        $(this).hide();
    });
});

$(".js-open-addBankingCard").on("click", function() {
    $(".popin-payment-bank").hide();
    $(".popin-addBankingCard").show();
    $(".js-backButton").attr("data-previouspopin", ".popin-payment-bank");
});

$(".js-backButton").on("click", function() {
    let actualPopin = $(this).attr("data-actualpopin");
    let previousPopin = $(this).attr("data-previouspopin");
    if (previousPopin !== "") {
        $(actualPopin).hide();
        $(previousPopin).show();
    }
    else {
        hidePopin();
    }
});

const addBankingCardCTA = () => {
    $(".st-blocMenuPayment").append('<p class="st-errorBankingCard fs12">' + translation('Le montant max 1 000 €') +'</p>');
}

const addDebitingCTA = () => {
    $(".st-blocMenuPayment").append('<p class="st-errorDebiting fs12">' + translation('Paiement supérieur à 20 000 €') +'</p>');
}

$(document).on('mouseover', ".st-infoPayLayer", function () {
    $('.tooltip-mandate').show();
})
$(document).on('mouseleave', ".st-infoPayLayer", function () {
    $('.tooltip-mandate').hide();
})
$(document).on('click', '.js-openpopin-bank', function(e) {

    let pathname = window.location.pathname;
    $('.popin').animate({scrollTop: '0px'}, 300);
    e.preventDefault();
    setTimeout(() => {
      if ($('.js-wayselected:visible').length) {
        $('.popin-payment-bank').find('.selected').find('.js-checkMarkWaySelected').addClass('d-none');
        $('.popin-payment-bank').find('.selected').removeClass('selected');
        $('.js-wayselected:visible').first().find('.js-checkMarkWaySelected').removeClass('d-none');
        $('.js-wayselected:visible').first().addClass('selected');
      }  
    //   if ($('.selected:visible').length) {
    //     $('.js-payment-bank').removeClass('st-no-btn-disabled');
    //   } else {
    //     $('.js-payment-bank').addClass('st-no-btn-disabled');
    //   }
    }, 0)

    $(".js-addbankform").addClass('d-none');
    $(".js-bankConnexion").addClass('d-none');
    $('.js-backToWaySelection').addClass('d-none');

    $(".one-clic-payment .js-checkMarkWaySelected").removeClass("d-none");
    $(".js-backPopinBank").removeClass("d-none");
    $(".js-chooseWayToPay").removeClass('d-none');

    // $('.call-bridge').addClass('js-payment-bank');
    // $('.call-bridge').removeClass('call-bridge');
    
    $('.payafter, .st-textIn3x, .st-blocBankingCard').hide();
    $('.js-show-payafter').prop( "checked", false);
    let d = new Date;
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    let year = String(d.getFullYear());
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    $('.delayed-date').val(day+"/"+month+"/"+year);

    var ibanAmount = $(this).data("amount") || null;
    var userId = $(this).data("receiverid") || null;
    var billsName = $(this).data("bills") || null;
    var dataPaymentId = $(this).parent('.st-paymentAction').data('payment') || null;
    var dataProjectId = $(this).parent('.st-paymentAction').data('project') || null;
    var paymentId = $(this).data('paymentid') || null;
    var projectId = $(this).data('projectid') || null;
    var target = $(this).data('target') || null;
    var dueDate = $(this).data('paymentduedate') || null;
    let discount = $(this).attr('data-discount') || null;
    let discountLimit = $(this).attr('data-discountlimit') || null;
    let discountLimitFormated = getValidFormatDate(discountLimit, '/');
    $(".st-contentOneClicPayment, st-errorMsgWarningVirement").hide();
    $(".st-msgCarbone").show();
    let discountAmount = (parseFloat(ibanAmount) - (parseFloat(ibanAmount) * (parseFloat(discount) / 100)));

    let discountAmountFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(discountAmount / 100.00);
    let amountFormat =  new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(ibanAmount / 100.00);
    // $(".js-amountFinalPayment").text("");
    // $(".js-amountFinalPayment").text(amountFormat);
    if ($(e.target).hasClass('actionButtonToPay')) {
      let receiverId = $('.actionButtonToPay').data('receiverid');
      getAndSetIban(receiverId, ".js-setIban", ibanAmount);
    }
    else {
      getAndSetIban(userId, ".js-setIban", ibanAmount);
    }
    if (pathname === '/freelance/') {
      $('.js-payment-bank, .call-bridge, .js-payWithBankingCard').attr('data-project', dataProjectId);
      $('.js-payment-bank, .call-bridge, .js-payWithBankingCard').attr('data-payment', dataPaymentId);
    }
    else {
      $('.js-payment-bank, .call-bridge, .js-payWithBankingCard').attr('data-project', projectId);
      $('.js-payment-bank, .call-bridge, .js-payWithBankingCard').attr('data-payment', paymentId);
    }
    $('.iban-amount, .st-payment-bank span:nth-child(2)').html(amountFormat);
    $('.bills-name').html(billsName);
    if(pathname === '/freelance/') {
      $('.js-submit-newbank').addClass('paying');
      $('.js-submit-newbank').removeClass('transferring');
      $('.js-payment-bank').data("route", "/freelance/projet/"+projectId+"/paiement/"+paymentId+"/payer");
    } else {
      $('.js-payment-bank').data("route", "/freelance/projet/"+projectId+"/paiement/"+paymentId+"/payer");
    }
    $('.hidden-popin-data').val($(this).data("popin-data"));
    if (discountLimit !== null) {
      let discountLimitFormated = getValidFormatDate(discountLimit, "/");
      if (discount && differenceDate(discountLimitFormated) < 0) {
        $('.iban-amount').html('<del>'+amountFormat+'</del> <strong class="cprimary-7 font-weight-bold">'+ discountAmountFormat+'</strong>');
        $(".st-payment-bank span:nth-child(2)").html(discountAmountFormat);
        $('.bills-discount').html('<span class="cprimary-7 font-weight-bold">'+discount + translation('% d’escompte</span> pour un règlement avant le ') + discountLimit);
        //$(".js-amountFinalPayment").text(discountAmountFormat);
      }
      else {
        //$(".js-amountFinalPayment").text(amountFormat);
      }
    }
    if (new Date(dueDate) < new Date()) {
        $('.delayed-date').prop('max', dueDate);
        $('.st-infoPayLayer').show();
        $('.js-show-payafter-label').hide();
      // $('.payment-delayed-content').hide();
    }
    let mandate_status = $('.bankselect.selected').data('mandatestatus');
    if (mandate_status != "ACTIVE") {
        $('.st-infoPayLayer').show();
        $('.js-show-payafter-label').hide();
        // $('.payment-delayed-content').hide();
    }
    $(".backdrop").show();
    if (parseInt(ibanAmount)/100 <= 20000) {
      $(target).show();
    } else {
      $('.popin-payment-bank').show();
    }
    $(".popin").show();

    if ((parseInt(ibanAmount)/100 > 1000 || (discountAmount/100) > 1000)) {
        $("#bankingCardMenu").attr("data-actif", "false").css("cursor", "initial");
        if ($(".st-blocMenuPayment").has(".st-errorBankingCard").length === 0) {
            addBankingCardCTA();
        }
        $("#bankingCardMenu").on("mouseenter", function() {
            $(".st-errorBankingCard").show();
        }).on("mouseleave", function() {
            $(".st-errorBankingCard").hide();
        });
    }
    else {
        $("#bankingCardMenu").attr("data-actif", "true").css("cursor", "pointer");
        $(".st-errorBankingCard").remove();
    }

    const multipayment = $(this).attr('data-ismultipayment');

    $(".st-paymentMenu").removeClass("active");
    $(".st-paymentMenu img, .st-virement-title, .st-bankingCard-title, .st-debiting-title, .st-blocDebitingPayment, .st-blocVirementPayment").hide();

    if (multipayment === "1") {
        $('.js-withMultipayment').removeClass('d-none');
        $('.js-withoutMultipayment').addClass('d-none');
        $("#debitingMenu").addClass("active");
        $("#debitingMenu img, .st-debiting-title, .st-blocDebitingPayment, .st-textIn3x").show();
        if (parseInt(ibanAmount)/100 > 60000 || (discountAmount/100) > 60000) {
            $("#debitingMenu").attr("data-actif", "false").css("cursor", "initial");
            if ($(".st-blocMenuPayment").has(".st-errorDebiting").length === 0) {
                addDebitingCTA();
            }
            $("#debitingMenu").on("mouseenter", function() {
                $(".st-errorDebiting").show();
            }).on("mouseleave", function() {
                $(".st-errorDebiting").hide();
            });

            // Transform message of banking card
            $("#bankingCardMenu").on("mouseenter", function() {
                $(".st-errorDebiting").show();
            }).on("mouseleave", function() {
                $(".st-errorDebiting").hide();
            });
        }
        else if (parseInt(ibanAmount)/100 > 20000 || (discountAmount/100) > 20000) {
            $(".st-showBloc3x, .st-hideBloc3x").removeClass("d-flex").addClass("d-none");
            $(".st-errorDebiting").remove();
            $("#debitingMenu").attr("data-actif", "true").css("cursor", "pointer");
        }
        else {
            $("#debitingMenu").attr("data-actif", "true").css("cursor", "pointer");
            $(".st-errorDebiting").remove();
            $(".st-hideBloc3x").removeClass("d-none").addClass("d-flex");
        }
        if (discount && differenceDate(discountLimitFormated) < 0) {
            const difference = (ibanAmount / 100.00) - (discountAmount / 100.00);
            divideNumberByThree(ibanAmount / 100.00, '.js-multipayment-popin-1', '.js-multipayment-popin-2', '.js-multipayment-popin-3');
            $('.js-withdiscount').removeClass('d-none');
            $('.js-withoutdiscount').addClass('d-none');
            $('.js-multipayment-popin-1-discounted').text(numberWithSpacesWithComa((((ibanAmount / 100.00)/3) - difference)) + ' €');
        } else {
            divideNumberByThree(ibanAmount / 100.00, '.js-multipayment-popin-1', '.js-multipayment-popin-2', '.js-multipayment-popin-3');
            $('.js-withdiscount').addClass('d-none');
            $('.js-withoutdiscount').removeClass('d-none');
        }
    } else {
        $('.js-withMultipayment').addClass('d-none');
        $('.js-withoutMultipayment').removeClass('d-none'); 
        $("#virementMenu").addClass("active");
        $("#virementMenu img, .st-virement-title, .st-blocVirementPayment").show();
        if (parseInt(ibanAmount)/100 > 20000 || (discountAmount/100) > 20000) {
            // $('.js-notOneClickOnly').addClass('d-none');
            // $('.js-oneClickOnly').removeClass('d-none');
            $("#debitingMenu").attr("data-actif", "false").css("cursor", "initial");
            if ($(".st-blocMenuPayment").has(".st-errorDebiting").length === 0) {
                addDebitingCTA();
            }
            $("#debitingMenu").on("mouseenter", function() {
                $(".st-errorDebiting").show();
            }).on("mouseleave", function() {
                $(".st-errorDebiting").hide();
            });
            // Transform message of banking card
            $("#bankingCardMenu").on("mouseenter", function() {
                $(".st-errorDebiting").show();
            }).on("mouseleave", function() {
                $(".st-errorDebiting").hide();
            });
            $(".st-errorBankingCard").remove();
        } else {
            // $('.js-oneClickOnly').addClass('d-none');
            // $('.js-notOneClickOnly').removeClass('d-none');
            $("#debitingMenu").attr("data-actif", "true").css("cursor", "pointer");
            $(".st-errorDebiting").remove();
        } 
    }
    if (window.location.pathname === '/freelance/operations') {
      let vars = {};
      window.location.href.replace(location.hash, '').replace(/[?&]+([^=&]+)=?([^&]*)?/gi,
        function (m, key, value) {
          vars[key] = value;
        });
      let projectIds = vars['projectIds'];
      let paymentIds = vars['paymentIds'];

      if (projectIds != null && paymentIds != null) {
        setTimeout(() => {
          $('.bankselect').removeClass('selected');
          $('.bankselect .js-checkMarkWaySelected').addClass('d-none');
          $('.one-clic-payment').css('cursor', 'pointer');
        }, 1000)
      }
    }
});

$(".st-blocVirementPayment .verif-front").on("input change", function() {
    checkAllInputs(".st-blocVirementPayment .popin-content", ".call-bridge");
});

$(".popin-add-bank .verif-front").on("input change", function() {
    checkAllInputs(".popin-add-bank .popin-content", ".js-submit-newbank");
});

$(".popin-addBankingCard .verif-front").on("input change", function() {
    checkAllInputs(".popin-addBankingCard .popin-content", ".js-addingBankingCard");
});