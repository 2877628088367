/* flatpickr plugin for calendars */
import { parseInt } from "core-js/fn/number";
import { French } from "flatpickr/dist/l10n/fr.js";
import { Spanish } from "flatpickr/dist/l10n/es.js";

export function flatpickrPlugin() {
  const currentPage = $("body").data("currentpage");
  let locale = $("body").attr("data-currentlang");
  if (locale === "es") {
    locale = Spanish;
  }
  else {
    locale = French;
  }

  const isLeapYear = (year) => {
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0)
  }
  setTimeout(() => {
    if (currentPage === "freelance_account_verify_userInfos" ||
                      "freelance_payment_index" ||
                      "freelance_account_subscription_offers_offer" ||
                      "freelance_account_create_company" ||
                      "efacture_page" ||
                      "adm_showuser_stats" ||
                      "efacture_create" ||
                      "freelance_new_payment" ||
                      "app_freelance_quotations_new") {
      const datePickers = document.querySelectorAll(".js-datepicker");
      datePickers.forEach((datepicker) => {
        
        let maxDate;

        if ($(datepicker).attr("data-maxdate")) {
          maxDate = $(datepicker).attr("data-maxdate");
        }
        else {
          maxDate = "";
        }
        
        const calendar = datepicker.flatpickr({
          dateFormat: "d/m/Y",
          maxDate: maxDate,
          wrap: true,
          allowInput: true,
          disableMobile: "true",
          "locale": locale
        });

        // Make the calendar fixed on scroll
        document.addEventListener("scroll", function() {
          calendar._positionCalendar();
        }, true);
      })

      // Add '/' after day and month and check validity of day, month, year. Character by character.
      const dates = document.querySelectorAll('.js-datepicker input');
      dates.forEach((date) => {
        $(date).on('keyup keydown', function(evt){
          if(evt.which !== 8) { 
            const numChars = $(date).val().length;

            if (numChars === 1) {
              let thisVal = $(date).val();
              if (parseInt(thisVal) > 3) {
                thisVal += "/"; 
                $(date).val(thisVal);
              }
            }

            if (numChars === 2) {
              let thisVal = $(date).val();
              let dayEnter = thisVal.split("/")[0];
              if (!thisVal.includes("/")) {
                if (parseInt(dayEnter) > 31) {
                  $(date).val("31/");
                } else {
                  thisVal += "/" ;
                  $(date).val(thisVal);         
                }
              }
            }

            if (numChars === 3) {
              let thisVal = $(date).val();
              if (parseInt(thisVal.split("/").slice(-1)[0]) > 1) {
                thisVal += "/" ;
                $(date).val(thisVal);  
              } 
            }

            if (numChars === 4) {
              let thisVal = $(date).val();
              let dayEnter = thisVal.split("/")[0];
              let monthEnter = thisVal.split("/").slice(-1)[0];        
              if (parseInt(thisVal.split("/").slice(-1)[0]) > 1) {
                if ((parseInt(dayEnter) === 31) && 
                    (parseInt(monthEnter) === 4 || 
                    parseInt(monthEnter) === 6 ||
                    parseInt(monthEnter) === 9 ||
                    parseInt(monthEnter) === 11)
                ) {
                  $(date).val("30/" + monthEnter + "/");
                } else if ((parseInt(dayEnter) > 29) && (parseInt(monthEnter) === 2)) {
                  $(date).val("29/" + monthEnter + "/");
                } else if (parseInt(monthEnter) > 12) {
                  $(date).val(thisVal.replace(monthEnter, "12") + "/");
                } else {
                  thisVal += "/" ;
                  $(date).val(thisVal); 
                }   
              }
            }

            if (numChars === 5) {
              let thisVal = $(date).val();
              let dayEnter = thisVal.split("/")[0];
              let monthEnter = thisVal.split("/").slice(-1)[0]; 
              if ((parseInt(dayEnter) === 31) && ((parseInt(monthEnter) === 4) || 
                                                  (parseInt(monthEnter) === 6) || 
                                                  (parseInt(monthEnter) === 9) || 
                                                  (parseInt(monthEnter) === 11))
              ) {
                $(date).val("30/" + monthEnter + "/");
              } else if ((parseInt(dayEnter) > 29) && (parseInt(monthEnter) === 2)) {
                $(date).val("29/" + monthEnter + "/");
              } else if (thisVal.split("/").length > 2) {
                return;
              } else if (parseInt(monthEnter) > 12) {
                $(date).val(dayEnter + "/12/");
              } else {
                thisVal += "/" ;
                $(date).val(thisVal); 
              }   
            }

            if (numChars === 9 || 10) {
              let thisVal = $(date).val();
              let dayEnter = thisVal.split("/")[0];
              let monthEnter = thisVal.split("/").slice(-2)[0]; 
              let yearEnter = thisVal.split("/").slice(-1)[0];
              if ((parseInt(monthEnter) === 2) && 
                  (yearEnter.length === 4) &&
                  (parseInt(dayEnter) === 29) &&
                  (!isLeapYear(parseInt(yearEnter)))) {
                $(date).val("28/02/" + yearEnter); 
              }
            }
          }

          // Allowed only digits and "/"
          if (/[\d\/]/.test(window.event.key)) {
            return true;
          } else if (window.event.key === "Backspace") {
            return true;
          } else {
            return false;
          }
        })
      });
    }
  }, 0);
}

